import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { APIService } from "../Components/Apis/APIServices";
import Dialogue from "../Components/Common/Dialogue";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Input from "../Components/Inputs";
import * as template from "../templates/service.request.json";

import { dateWithFormate } from "../Utility/common";

import MyAccountMenu from "../Components/Common/MyAccountMenu";
import config from "../Components/Apis/config";

import images from "../Utility/Images";
import GetInTouch from "../Components/Common/GetInTouch";


const EditMyRequest = (props) => {
  const history = useHistory();
  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
  })
  const [pageData, setpageData] = useState(
    props.location?.state?.pageData ? {
      ...props.location?.state?.pageData,
      service_date : props.location?.state?.pageData?.service_date
                      .split("-")
                      .reverse()
                      .join("-")
    } : {}
  );

  const [homeAddress, setHomeAddress] = useState(true)

  const [templateState, setTemplateState] = useState({});
  const [addDetail, setAddDetail] = useState({});
  const [slots, setSlots] = useState([]);
  
  const [imagesUploaded, setImagesUploaded] = useState([]); 
  const [imagesToSend, setImagesToSend] = useState([]);

  const [loader, setLoader] = useState(false)


  useEffect(() => { 

   if(pageData?.address_1 === pageData?.user?.address_1 
    && pageData?.address_2 === pageData?.user?.address_2
    && pageData?.city === pageData?.user?.city
    && pageData?.state === pageData?.user?.state
    && pageData?.country === pageData?.user?.country
    ){
      setHomeAddress(true)
    }else{
      setHomeAddress(false)
    }

  },[pageData])

  // console.log("EditMyRequest", pageData);

  useEffect(() => { 
    APIService.fetchAllTimeSlot()
      .then((res) => setSlots(res.data.data))
      .catch((err) => { });
    
    if (template.additionalDetails[[pageData?.category?.id]]) {
      setTemplateState(template.additionalDetails[[pageData?.category?.id]]);
      // console.log(template.additionalDetails[serviceCat]);
    } else if (template.additionalDetails[[pageData?.category?.id] + ":" + "any"]) {
      setTemplateState(
        template.additionalDetails[[pageData?.category?.id] + ":" + "any"]
      );
      // setTemplateState({});
      // setAddDetail({})
    } else {
      setTemplateState({});
      setAddDetail({});
    }

    if (template.additionalDetails[pageData?.category?.id + ":" + pageData?.sub_category?.id]) {
      setTemplateState(template.additionalDetails[pageData?.category?.id + ":" + pageData?.sub_category?.id]);
      // console.log(template.additionalDetails[pageData?.category?.id + ":" + pageData?.sub_category?.id]);
    } else if (template.additionalDetails[pageData?.category?.id + ":" + "any"]) {
      // setTemplateState(template.additionalDetails[pageData?.category?.id+ ":"+ "any"]);
      // setTemplateState({});
      // setAddDetail({})
    } else {
      setTemplateState({});
      setAddDetail({});
    }
  }, [])
  
  useEffect(() => { 

    let addtionalDetail = pageData.addtionalDetail

    addtionalDetail = {...addtionalDetail, ...addDetail}

    setpageData((prev) => ({
      ...prev,
      addtionalDetail
    }));
  },[addDetail])

  const updateRequest = (e) => {
    e.preventDefault();
    // console.log("preventDefault", pageData);

    const dataToSend = {
      service_date: pageData?.service_date,
      time_slot: pageData?.time_slot,
      title: pageData.title,
      description: pageData.description,
      ...addDetail,
      images: imagesToSend,
    };
    // console.log("preventDefault", dataToSend);
    setLoader(true)
    APIService.editRequest(dataToSend, pageData.id)
      .then((data) => {
        if (data.status === 200) {
        setLoader(false)
        setDialogueState({
          type : 'Success',
          show:true,
          message : `Your service request ${pageData.id} has been successfully updated.`,
          link : 'my-request'
        })
      }else{
        setLoader(false)
        setDialogueState({
          type : 'Error',
          show:true,
          message : (data.response && data.response.data && data.response.data.message) || (data.data && data.data.message) || ""
        })
      }
        // if (data.status === 200) {
          
        //   // history.goBack();
        // }
      })
      .catch((e) => {
        setLoader(false)
        setDialogueState({
        type : 'Error',
        show:true,
        message : (e.response && e.response.data && e.response.data.message) || ""
      })
    });
      
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setpageData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }



    const handleImageChange = (ele) => {
      var _validFileExtensions = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
    
      // console.log("aaaaaaaaaaaaaaaaaaaaaa", ele)
      // let value = imagesUploaded
      // let photoObj = {};
      // let value = ele.target.value;

      if (ele.target.files[0].size > 5242880) {
        // console.log("......../////////", ele.target.files[0].size);
        setDialogueState({
          type: "Error",
          show: true,
          message: "File size exceeds 5MB",
        });
        return;
      } else if(_validFileExtensions.includes(ele.target.files[0].type)) {
        let reader = new FileReader();
        reader.onload = (e) => {
          //setProfilePhoto(e.target.result);
        };
        reader.readAsDataURL(ele.target.files[0]);
        // value = ele.target.files[0];

        setImagesUploaded([
          ...imagesUploaded,
          URL.createObjectURL(ele.target.files[0]),
        ]);

        setImagesToSend([...imagesToSend, ele.target.files[0]]);
      }else{
        setDialogueState({
          type: "Error",
          show: true,
          message: "Invalid file selected",
        });
        return;
      }

      // setImagesUploaded(value)
    };
  
  
  const handleDeleteImage = (index,flow,imageId) => {
    if(flow==='imagesUploaded'){
      let currentImages = imagesUploaded;
      let dataToDelete = imagesToSend;
      dataToDelete.splice(index, 1);
      setImagesToSend([...dataToDelete]);
      currentImages.splice(index, 1);
      setImagesUploaded([...currentImages]);
    }else{
      APIService.deleteRequestFile(imageId).then(res=>{
        setpageData({
          ...pageData,
          files : pageData.files.filter(file=>file.id!==imageId)
        })
      })
      .catch((err)=>{})
    }
    
  };

  // console.log("rrrrrrrrrrrrrrrrr", pageData)


  return (
    <>
      <Header />
      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src="../images/clip-bg.svg"
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  Edit <span> Requests </span>{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="db-menu-block">
                <ul>
                  <li>
                    <a href="my-request.html" className="active-db">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/request-icon.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> My Requests </h4>
                    </a>
                  </li>
                  <li>
                    <a href="my-profile.html">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/my-profile.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> My Profile </h4>
                    </a>
                  </li>
                  <li>
                    <a href="change-profile-type.html">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/change-profile-icon.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> Change Profile Type </h4>
                    </a>
                  </li>
                  <li>
                    <a href="my-chat.html">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/chat-icon.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> My Chats </h4>
                    </a>
                  </li>
                  <li>
                    <a href="my-transactions.html">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/transaction-icon.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> My Transactions </h4>
                    </a>
                  </li>
                  <li>
                    <a href="my-feedbacks.html">
                      <div className="dbm-icon">
                        <img
                          src="../images/dashboard/feedback-icon.svg"
                          className="img-fluid"
                          title=""
                          alt=""
                        />
                      </div>
                      <h4> My Feedbacks </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>

      <section className="createMyRequest-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmr-form-block">
                <div className="form-group group-12">
                  <label> Service Address </label>
                  <div className="radio-block">
                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        name="serviceAdd"
                        id="homeAddress"
                        checked={homeAddress}
                        disabled
                      />
                      <label className="radio-label-control" for="homeAddress">
                        {" "}
                        Home Address{" "}
                      </label>
                    </div>

                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        name="serviceAdd"
                        id="otherAddress"
                        disabled
                        checked={!homeAddress}
                      />
                      <label className="radio-label-control" for="otherAddress">
                        {" "}
                        Other Address{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group fg-place group-6 group-xs-12">
                  <div className="placeholder">
                    <label>Address Line 1 </label>
                    <span className="star">*</span>
                  </div>
                  <input
                    type="text"
                    className="holer-control form-control"
                    value={pageData.address_1 || "N/A"}
                    name="address_1"
                    // onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>

                <div className="form-group group-6 group-xs-12">
                  <input
                    type="text"
                    placeholder="Address Line 2"
                    className="form-control"
                    value={pageData.address_2 || "N/A"}
                    name="address_2"
                    // onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>

                <div className="form-group group-3 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    {/* <option>
                      {" "}
                      Country <span style="color: red;">*</span>
                    </option>
                    <option selected> USA </option> */}
                    <option selected>
                      {" "}
                      {pageData?.cityGet?.countries?.name || "N/A"}{" "}
                    </option>
                  </select>
                </div>

                <div className="form-group group-3 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    <option selected>
                      {" "}
                      {pageData?.cityGet?.states?.name || "N/A"}{" "}
                    </option>
                  </select>
                </div>

                <div className="form-group group-3 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    <option selected>
                      {" "}
                      {pageData?.cityGet?.name || "N/A"}{" "}
                    </option>
                  </select>
                </div>

                <div className="form-group fg-place group-3 group-md-6 group-xs-12">
                  {/* <div className="placeholder">
                    <label for=""> Zip Code </label>
                    <span className="star">*</span>
                  </div> */}
                  <input
                    type="text"
                    className="holer-control form-control"
                    value={pageData.zip_code}
                    name="zip_code"
                    // onChange={(e) => handleChange(e)}
                    placeholder="Zip Code *"
                    disabled
                  />
                </div>

                <div className="form-group group-title group-12 mb-0">
                  <h4>Expected Service Date and Time</h4>
                </div>

                <div
                  className="form-group date1 group-6 group-xs-12 datepicker-calendar fg-place"
                  id="datepicker10"
                  data-target-input="nearest"
                >
                  <div className="placeholder">
                    <label for=""> Date </label>
                    <span className="star">*</span>
                  </div>
                  <input
                    type="date"
                    className="holer-control form-control"
                    // data-target="#datepicker10"
                    // data-toggle="datetimepicker"
                    value={
                      pageData?.service_date
                      // .split("-")
                      // .reverse()
                      // .join("-")
                    }
                    name="service_date"
                    onChange={(e) => handleChange(e)}
                    // minimum="2021-9-25"
                    min={getTodaysDate()}
                    // max="2018-12-31"
                  />
                </div>

                <div className="form-group group-6 group-xs-12">
                  <select
                    className="form-control"
                    name="time_slot"
                    value={pageData?.time_slot || ""}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Time *</option>
                    {slots.map((slot) => (
                      <option value={slot.id} key={slot.id}>
                        {dateWithFormate(slot.start_time, "hA", "HH:mm:ss")} -{" "}
                        {dateWithFormate(slot.end_time, "hA", "HH:mm:ss")}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group group-4 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    <option selected>
                      {pageData?.category?.category_name}
                    </option>
                  </select>
                </div>
                <div className="form-group group-4 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    <option selected>
                      {pageData?.sub_category?.sub_category_name}
                    </option>
                  </select>
                </div>
                <div className="form-group group-4 group-md-6 group-xs-12">
                  <select className="form-control" disabled="true">
                    <option selected>{pageData?.serviceType?.name}</option>
                  </select>
                </div>

                <div className="form-group group-title group-12 mb-0 ">
                  <h4> Request Title </h4>
                </div>

                <div className="form-group group-12">
                  <div className="textarea-group">
                    <textarea
                      className="form-control resize-none"
                      placeholder="Provide high level summary of your request *"
                      rows="4"
                      value={pageData.title || ""}
                      name="title"
                      onChange={(e) => handleChange(e)}
                      maxLength="100"
                    />
                    {/* {" "}
                      Lorem ipsum dolor sit amet, consectetur enean suscipit
                      massa eget dolor elementum dignissim.
                    </textarea> */}
                    <span className="textarea-limit">
                      {" "}
                      {100 - (pageData?.title?.length || 0)} Characters Limit{" "}
                    </span>
                  </div>
                </div>

                <div className="form-group group-title group-12 mb-0">
                  <h4> Request Description </h4>
                </div>

                <div className="form-group group-12">
                  <div className="textarea-group">
                    <textarea
                      className="form-control resize-none"
                      placeholder="Provide a brief description of the request and all the relevant details to get the most accurate quote *"
                      rows="4"
                      value={pageData.description || ""}
                      name="description"
                      onChange={(e) => handleChange(e)}
                      maxLength="500"
                    />
                    {/* {" "}
                      Lorem ipsum dolor sit amet, consectetur enean suscipit
                      massa eget dolor elementum dignissim facilisi Integer
                      varius hendrerit nibh.
                    </textarea> */}
                    <span className="textarea-limit">
                      {" "}
                      {500 - (pageData?.description?.length || 0)} Characters
                      Limit{" "}
                    </span>
                  </div>
                  <span className="textNote">
                    {" "}
                    “Please do not share your personal/contact details such as
                    email id, contact number, address etc.”{" "}
                  </span>
                </div>

                <div className="form-group group-title group-12 mb-0">
                  <h4> Additional Details </h4>
                  <div className="d-flex flex-wrap">
                    {Object.keys(templateState).map((k) => (
                      <Input
                        keyValue={k}
                        label={templateState[k].label}
                        type={templateState[k].type}
                        value={templateState[k].value}
                        state={pageData?.addtionalDetail}
                        setState={setAddDetail}
                      />
                    ))}
                  </div>
                </div>
                {/*
                <div className="profileUp-inner d-flex flex-wrap">
                  {imagesUploaded.length !== 0
                    ? imagesUploaded.map((item, index) => (
                        <div className="upload-here" key={index}>
                          <img src={item} className="img-fluid" alt="" />
                        </div>
                      ))
                    : null}
                </div>
                {imagesUploaded.length < 10 ? (
                  <div className="cus-fileUpload-box form-group group-12">
                    <div className="cus-fileUpload-group">
                      <input
                        type="file"
                        className="cus-fileUpload-control d-none"
                        id="driverDL-input"
                        onChange={handleImageChange}
                      />
                      <label
                        for="driverDL-input"
                        className="mb-0 cus-fileUpload-label"
                      >
                        <div className="profileUp-inner">
                          <div className="upload-here">
                            <img
                              src={images.imagesIcon}
                              className="img-fluid"
                              alt=""
                            />
                          </div>

                          <div className="upload-content">
                            <h5>
                              {" "}
                              Upload Photos/Videos{" "}
                              <span style={{ color: "red" }}>*</span>
                            </h5>
                            <span> Images 5MB Max and Videos 20MB Max </span>
                            <span> (Max 10 Uploads) </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : null} */}
                <div className="form-group group-title group-12 mb-0">
                  <div
                    className="profileUp-inner d-flex flex-wrap"
                    style={{ overflow: "hidden" }}
                  >
                    {pageData?.files?.length > 0
                      ? pageData?.files?.map((item, index) => (
                          <div
                            className="upload-here"
                            style={{
                              position: "relative",
                              margin: "25px 10px 40px 0px",
                            }}
                            key={index}
                          >
                            <img
                              src={config?.endpoints?.image_path + item.file}
                              className="img-fluid"
                              alt=""
                              style={{ position: "absolute" }}
                            />
                            <i
                              className="fa fa-trash fa-fw text-black"
                              style={{
                                position: "absolute",
                                cursor: "pointer",
                                bottom: "0",
                                right: "0",
                                color: "orange",
                              }}
                              onClick={() =>
                                handleDeleteImage(index, "files", item.id)
                              }
                            ></i>
                          </div>
                        ))
                      : null}

                    {imagesUploaded.length > 0
                      ? imagesUploaded.map((item, index) => (
                          <div
                            className="upload-here"
                            style={{
                              position: "relative",
                              margin: "25px 10px 40px 0px",
                            }}
                            key={index}
                          >
                            <img
                              src={item}
                              className="img-fluid"
                              alt=""
                              style={{ position: "absolute" }}
                            />
                            <i
                              className="fa fa-trash fa-fw text-black"
                              style={{
                                position: "absolute",
                                cursor: "pointer",
                                bottom: "0",
                                right: "0",
                                color: "orange",
                              }}
                              onClick={() =>
                                handleDeleteImage(index, "imagesUploaded")
                              }
                            ></i>
                          </div>
                        ))
                      : null}
                  </div>

                  {imagesUploaded.length < 5 - pageData?.files?.length ? (
                    <div className="cus-fileUpload-box form-group group-12 mt-3">
                      <div className="cus-fileUpload-group">
                        <input
                          type="file"
                          className="cus-fileUpload-control d-none"
                          id="driverDL-input"
                          onChange={handleImageChange}
                        />
                        <label
                          for="driverDL-input"
                          className="mb-0 cus-fileUpload-label"
                        >
                          <div className="profileUp-inner">
                            <div className="upload-here">
                              <img
                                src={images.imagesIcon}
                                className="img-fluid"
                                alt=""
                              />
                            </div>

                            <div className="upload-content">
                              <h5>
                                {" "}
                                Upload Photos{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </h5>
                              <span> Images 5MB Max </span>
                              <span> (Max 5 Uploads) </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="form-submit text-center group-12">
                  <button
                    type="button"
                    className="btn btn-sm btn-black "
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-red ml-4"
                    onClick={(e) => updateRequest(e)}
                    disabled={loader}
                  >
                    {" "}
                    {loader ? "Processing ..." : "Save" }{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Footer />
    </>
  );
};

export default EditMyRequest;
