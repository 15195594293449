import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthUserContext from '../../../Auth/AuthUser/AuthUserContext';
import images from '../../../Utility/Images';
import { APIService } from '../../Apis/APIServices';
import config from '../../Apis/config';

const UserProfile = () => {
    const authUser = useContext(AuthUserContext)
    return (
        <>
            <li className="nav-item user-btn-item d-none d-xl-inline-block">
                <div className="user-menu dropdown">
                    <button className="dropdown-toggle btn user-btn" type="button" id="userMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="userBtn-icon"> <Image src={config.endpoints.image_path+authUser?.profile_photo||images.ProfilePicture} className="img-fluid"
                            alt="" /> </div>
                        <span className="user-name"> Welcome, {authUser && authUser.first_name} <i className="fal fa-chevron-down"></i> </span>
                    </button>

                    <div className="dropdown-menu" aria-labelledby="userMenuButton">
                        <Link className="dropdown-item" to="/my-request"> My Dashboard  <Image src={images.dashboard} className="img-fluid" alt="" /></Link>
                        <Link className="dropdown-item" to="/login" onClick={() => APIService.logout()}> Sign Out <Image src={images.signoutIcon} className="img-fluid" alt="" /> </Link>

                    </div>
                </div>
            </li>
        </>
    );
}

export default UserProfile;
