import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import images from "../../Utility/Images";
import { APIService } from "../Apis/APIServices";

const Modals = (props) => {
  const [adState, setAdState] = useState({
    address_type: 1,
    status: 1,
    address_1: "",
    address_2:"",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [err, setErr] = useState({});

  const [slots, setSlots] = useState([])
  
  const [zip, setZip] = useState("");

  const [cancelRequestReasonList, setCancelRequestReasonList] = useState([]);
  const [cnclReason, setcnclReason] = useState("");
  const [cancelRequestId, setCancelRequestId] = useState("");
  const [cancelConfirm,setCancelConfirm] = useState("");
  const onCancelReasonChange = (value) => {
    setcnclReason(value);
  };
  const onCancelRequest = () => {
    APIService.cancelRequest(cnclReason, props.requestID, cancelRequestId)
      .then((res) => {
        if (res.status === 200) {

          // console.log("error mesage is ", res)
         
          //alert("Request cancelled successfully");
          // setcnclReason("")
          // cancelRequestId("")
          window.location.reload()
        }
      })
      .catch((e) => console.log("error mesage iserror mesage is", e));
    
  };

  useEffect(() => { 
    APIService.srCancelReason()
      .then((res) => {
        if (res.status === 200) {
          setCancelRequestReasonList(res.data || []);
        }
      })
      .catch((e) => console.log("error", e));
  },[])

  useEffect(() => {
    APIService.fetchCountry()
      .then((res) => setCountries((res && res.data && res.data.data) || []))
      .catch((err) => setErr(err));
  }, [adState.country]);

  useEffect(() => {
      APIService.fetchAllTimeSlot()
      .then(res=>setSlots((res && res.data && res.data.data) || []))
      .catch(err=>setErr(err))
  },[]);

  const handleCountry = (e) => {
    handleChange(e);
    APIService.fetchState({ country_id: e.target.value })
      .then((res) => setStates((res && res.data && res.data.data.sort((a, b) => a.name.localeCompare(b.name))) || [] ))
      .catch((err) => setErr(err));
  };
  const handleState = (e) => {
    handleChange(e);
    APIService.fetchCity({ state_id: e.target.value })
      .then((res) => setCities((res && res.data && res.data.data) || []))
      .catch((err) => setErr(err));
  };

  const handleChange = ({ target: { value, name } }) => {
    
 setAdState((prevState) => ({
   ...prevState,
   [name]: value,
 }));
   
   
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const dataToSend = { ...adState, zip_code : zip};
    APIService.addOtherAddress(dataToSend).then(
      (res) => (
        window.location.href = "/create-my-request"
        // null
      )
    );
  };
  const handleConfirmCancel = ()=>{
    APIService.cancelRequestStatus(props.requestID)
    .then(resp=>{
      if(resp.status === 200)
        setCancelConfirm(resp.data.message)
    })
    .catch((err)=>{

    })
  }

  const handleCancel = () =>{
    setCancelRequestId("")
    setcnclReason("")
    setCancelConfirm("")
  }

  // console.log("eeeeeeeeeeeeeeeeeee", props.requestID)
  return (
    <>
      <div className="to-top-container">
        <a href={void 0} id="return-to-top">
          <i className="fal fa-chevron-up"></i>
        </a>
      </div>

      <div
        className="scale_cus_modal notifyCongrats-wrap"
        id="notification-modal"
      >
        <div className="scale_modal_body">
          <div className="modal_inner">
            {/* <!-- <div className="scale_modal-close">
	        		<button type="button" className="slide-close" data-dismiss="modal" aria-label="Close"> <i className="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
            <div className="scm_header">
              <div className="notifiy-check">
                <img
                  src={images.checkIcon}
                  className="d-block m-auto img-fluid"
                  alt=""
                />
              </div>
              <h2 className="scm_head-title"> Congratulations! </h2>
            </div>
            <div className="scm_body">
              <div className="notify-modal-body">
                <p>
                  {" "}
                  You have successfully marked the Service Request ID 25632153
                  as complete.{" "}
                </p>
                <p>
                  {" "}
                  Please accept the completion of the request and provide
                  feedback for your service provider.{" "}
                </p>
              </div>

              <div className="scm_footer justify-content-center">
                <a
                  href="customer-panel/accept-completion.html"
                  className="btn btn-red"
                >
                  {" "}
                  Accept Completion{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="scale_cus_modal videoModal-box" id="voiceVideo-modal">
        <div className="scale_modal_body">
          <div className="modal_inner">
            <div className="videoModal-body">
              <button
                type="button"
                className="close d-sm-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item voiceVideo-block"
                  src="https://www.youtube.com/embed/26VaP3uccTs?rel=0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="to-top-container">
        <a href="javascript:" id="return-to-top">
          <i class="fal fa-chevron-up"></i>
        </a>
      </div>

      <div class="scale_cus_modal notifyCongrats-wrap" id="notification-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <div class="notifiy-check">
                <img
                  src="../images/content/check.svg"
                  class="d-block m-auto img-fluid"
                  alt=""
                />
              </div>
              <h2 class="scm_head-title"> Congratulations! </h2>
            </div>
            <div class="scm_body">
              <div class="notify-modal-body">
                <p>
                  {" "}
                  You have successfully marked the Service Request ID 25632153
                  as complete.{" "}
                </p>
                <p>
                  {" "}
                  Please accept the completion of the request and provide
                  feedback for your service provider.{" "}
                </p>
              </div>

              <div class="scm_footer justify-content-center full-scm_footer">
                <a href="accept-completion.html" class="btn btn-red">
                  {" "}
                  Accept Completion{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scale_cus_modal cancelRequest-wrap" id="cancel-request-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Cancel Request </h2>
            </div>
            <div class="scm_body">
              <form>
                <div class="form-group">
                  <label> Select Reason for Cancellation </label>
                  <select
                    class="form-control"
                    name="cnclReason"
                    value={cancelRequestId}
                    onChange={(e) => setCancelRequestId(e.target.value)}
                  >
                    <option disabled="true" selected value="">
                      {" "}
                      Select a cancellation Reason *
                    </option>
                    {cancelRequestReasonList.map((item, index) => (
                      <option value={item.id} key={item.id + index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div class="form-group">
                  <div class="textarea-group">
                    <textarea
                      class="form-control resize-none"
                      rows="6"
                      placeholder="Please provide details for cancellation reason. *"
                      name="reason"
                      value={cnclReason}
                      onChange={(e) => onCancelReasonChange(e.target.value)}
                      maxLength={50}
                    ></textarea>
                    <span class="textarea-limit">
                      {" "}
                      {50 - cnclReason.length} Characters Limit{" "}
                    </span>
                  </div>
                </div>

                <div class="scm_footer">
                  <button
                    type="button"
                    class="btn btn-black"
                    data-dismiss="modal"
                    onClick={handleCancel}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    type="button"
                    class="btn btn-red"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#confirm-cancel-modal"
                    onClick={handleConfirmCancel}
                    disabled={
                      cancelRequestId === "" || cnclReason === "" ? true : false
                    }
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="scale_cus_modal confirmCancel-wrap" id="confirm-cancel-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Confirm Cancellation </h2>
            </div>
            <div class="scm_body">
              <div class="confirmCancel">
                <p>{cancelConfirm || "Checking cancel status, Please wait..."}</p>
              </div>
              {
                !cancelConfirm.includes("has been closed.") ? <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                  onClick={handleCancel}
                >
                  {" "}
                  No{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-red"
                  onClick={() => onCancelRequest()}
                  data-dismiss="modal"
                >
                  {" "}
                  Yes{" "}
                </button>
              </div> : 
              <div class="scm_footer d-flex justify-content-center">
              <button
              type="button"
              class="btn btn-black"
              data-dismiss="modal"
              onClick={() => setcnclReason("")
                            
            }
            >
              {" "}
              OK{" "}
            </button>
            </div>
              }
              
            </div>
          </div>
        </div>
      </div>

      {/* <div
        class="scale_cus_modal disputeRequest-wrap"
        id="dispute-request-modal"
      >
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Dispute Request </h2>
            </div>
            <div class="scm_body">
              <form>
                <div class="form-group">
                  <label> Select Reason for Dispute Request </label>
                  <select class="form-control">
                    <option> Select a Dispute Reason *</option>
                    <option> Select a Dispute Reason </option>
                    <option> Select a Dispute Reason </option>
                  </select>
                </div>

                <div class="form-group">
                  <div class="textarea-group">
                    <textarea
                      class="form-control resize-none"
                      rows="6"
                      placeholder="Provide Dispute Details *"
                    ></textarea>
                    <span class="textarea-limit"> 1000 Characters Limit </span>
                  </div>
                </div>

                <div class="scm_footer">
                  <button
                    type="button"
                    class="btn btn-black"
                    data-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    type="button"
                    class="btn btn-red"
                    data-dismiss="modal"
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
        class="scale_cus_modal warrantyRequest-wrap"
        id="warranty-request-modal"
      >
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Warranty Request </h2>
            </div>
            <div class="scm_body">
              <form>
                <div class="form-group">
                  <label> Provide the Warranty Details </label>
                  <div class="textarea-group">
                    <textarea
                      class="form-control resize-none"
                      rows="6"
                      placeholder="Provide the Warranty Reason with Issue Details *"
                    ></textarea>
                    <span class="textarea-limit"> 1000 Characters Limit </span>
                  </div>
                </div>

                <div class="scm_footer">
                  <button
                    type="button"
                    class="btn btn-black"
                    data-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    type="button"
                    class="btn btn-red"
                    data-dismiss="modal"
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <div class="to-top-container">
        <a href="javascript:" id="return-to-top">
          <i class="fal fa-chevron-up"></i>
        </a>
      </div>

      <div class="scale_cus_modal notifyCongrats-wrap" id="notification-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <div class="notifiy-check">
                <img
                  src="images/content/check.svg"
                  class="d-block m-auto img-fluid"
                  alt=""
                />
              </div>
              <h2 class="scm_head-title"> Congratulations! </h2>
            </div>
            <div class="scm_body">
              <div class="notify-modal-body">
                <p>
                  {" "}
                  You have successfully marked the Service Request ID 25632153
                  as complete.{" "}
                </p>
                <p>
                  {" "}
                  Please accept the completion of the request and provide
                  feedback for your service provider.{" "}
                </p>
              </div>

              <div class="scm_footer justify-content-center">
                <a
                  href="customer-panel/accept-completion.html"
                  class="btn btn-red"
                >
                  {" "}
                  Accept Completion{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scale_cus_modal addnewaddress-wrap" id="addNewAddress-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Add New Address </h2>
            </div>
            <div class="scm_body">
              <form onSubmit={(e)=>handleSubmit(e)}>
              <div>
                <div class="row">
                  <div class="form-group fg-place group-12">
                    {/* <div
                      className={`placeholder ${
                        adState.address_1 ? "d-none" : "d-block"
                      }`}
                    >
                      <label for=""> Address Line 1 </label>
                      <span className="star text-danger">*</span>
                        
                    </div> */}
                    <input
                      type="text"
                      class="holer-control form-control"
                      value={adState.address_1}
                      name="address_1"
                      onChange={handleChange}
                      placeholder="Address Line 1*"
                      required
                    />
                  </div>

                  <div class="form-group fg-place group-12">
                    <div
                      className={`placeholder ${
                        adState.address_2 ? "d-none" : "d-block"
                      }`}
                    >
                      <label for=""> Address Line 2 </label>
                    </div>
                    <input
                      type="text"
                      class="holer-control form-control"
                      value={adState.address_2}
                      name="address_2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group fg-place group-6 group-md-12 group-xs-12">
                    <select
                      className="form-control"
                      name="country"
                      onChange={handleCountry}
                      required
                    >
                      <option value=""> Country*</option>
                      {countries.map((res) => (
                        <option
                          key={res.id}
                          value={res.id}
                          selected={state.country == res.id ? true : false}
                        >
                          {" "}
                          {res.name}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group fg-place group-6 group-md-12 group-xs-12">
                    <select
                      className="form-control"
                      value={adState.state}
                      name="state"
                      onChange={handleState}
                      required
                    >
                      <option disabled={true} selected={true} value="">
                        {" "}
                        State*
                      </option>
                      {states.map((res) => (
                        <option key={res.id} value={res.id}>
                          {" "}
                          {res.name}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group fg-place group-6 group-md-12 group-xs-12 ">
                    <select
                      className="form-control"
                      name="city"
                      onChange={handleChange}
                      required
                    >
                      <option disabled={true} selected={true} value="">
                        {" "}
                        City*
                      </option>
                      {cities.map((res) => (
                        <option key={res.id} value={res.id}>
                          {" "}
                          {res.name}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div class="ardCat-widget">
                    <a href="" id="addComment-modal">
                      <img
                        src="../images/comment-icon.svg"
                        class="img-fluid"
                        alt=""
                      />
                      <span> comment </span>
                    </a>
                  </div> */}
                  <div className="form-group fg-place group-6 group-md-12 group-xs-12 ">
                    {/* <div
                      className={`placeholder ${
                        adState.zip_code !== "" ? "d-none" : "d-block"
                      }`}
                    >
                      <label for=""> Zip Code </label>
                      <span class="star">*</span>
                    </div>
                    <input
                      type="text"
                      class="holer-control form-control"
                      name="zip_code"
                      value={adState.zip_code}
                      onChange={handleChange}
                      maxLength="10"
                      minLength="5"
                      pattern="[0-9-]*"
                      title="Zip code must contain numbers only"
                    /> */}

                     {/* <div
                          className={`placeholder ${
                            zip ? "d-none" : "d-block"
                          }`}
                        >
                          <label for="zip"> Zip Code </label>
                          <span className="star text-danger">*</span>
                        </div> */}
                        <input
                          type="text"
                          value={zip}
                          onChange={(e) => {
                            let value = e.target.value;

                            if (zip.length === 4 && value.length === 5) {
                              value += "-";
                            } else if (value.length === 6) {
                              value = value.replace("-", "");
                            }

                            setZip(value);
                          }}
                          className="holer-control form-control"
                          maxLength="10"
                          minLength="5"
                          pattern="[0-9-]*"
                          title="Zip code must contain numbers only"
                          placeholder="Zip Code*"
                          required
                        />
                      
                  </div>
                </div>
                <div class="scm_footer full-scm_footer">
                  <button
                    type="submit"
                    // onClick={handleSubmit}
                    class="btn btn-red"
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="scale_cus_modal addnewaddress-wrap" id="non-loggedIn-user">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <p class="scm_head-title">
                {" "}
                You need to login to create a request{" "}
              </p>
            </div>
            <div class="scm_body">
              <div class="scm_footer full-scm_footer">
                <Link to="/login"> SignIn</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modals;
