import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import images from "../../Utility/Images";
import { Scrollbars } from "react-custom-scrollbars";
import { APIService } from "../../Components/Apis/APIServices";
import { data, post } from "jquery";
import { render } from "@testing-library/react";
import Modals from "../../Components/Modals/Modals";
import DisputeModal from "../../Components/Modals/DisputeModal";
import WarrantyModal from "../../Components/Modals/WarrantyModal";
import { dateWithFormate } from '../../Utility/common';
import config from "../../Components/Apis/config";

import Dialogue from '../../Components/Common/Dialogue';

import * as template from "../../templates/service.request.json";

const CustomerRequest = (props) => {
  const [token, setToken] = useState("");
  const [processing, setProcessing] = useState({
    one: false,
    two: false,
    three: false,
  });
  const [anotherVisitReason, setAnotherVisitReason] = useState([
    "Need more time to complete the work",
    "Material not available",
    "Need more materials",
    "Bad weather",
    "Customer is not ready",
    "Other",
  ]);
  const [serviceData, setServiceData] = useState([]);
  const [pageData, setPageData] = useState([]);

  const [requestId, setRequestId] = useState("");
  const [cancelRequest, setCancelRequest] = useState({});

  const [activeData, setActiveData] = useState("");
  const [activeService, setActiveService] = useState([]);

  const [closeData, setCloseData] = useState("");
  const [closeService, setCloseService] = useState([]);

  const [filterData, setFilterData] = useState("");

  const globalArray = [];

  const [filterCity, setFilterCity] = useState("city");
  const [filterState, setFilterState] = useState("state");
  const [filterRequestID, setFilterRequestID] = useState("");
  const [serviceCat, setServiceCat] = useState("");
  const [serviceSubCat, setServiceSubCats] = useState("");

  const [serviceDate, setServiceDateEndRange] = useState("");
  const [submitDate, setSubmitDate] = useState("");

  const [filterSrs, setFilterSrs] = useState([]);

  const [filterUI, setfilterUI] = useState(false);
  const [filterStatus, setStatusChange] = useState("");

  const [staticServiceData, setStaticServiceData]= useState([]);
  const [staticActiveService, setStaticActiveService]= useState([]);
  const [staticCloseService, setStaticCloseService]= useState([]);

  const [activeTab, setActiveTab] = useState("ongoing");
  
  const [reload, setReload] = useState(false)

  const [ongoingCardShow, setOngoingCardShow] = useState(false)

  /////////////////////////////////////

    ///////////////////////////////////

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [err, setErr] = useState({});
    const [serviceCats, setServiceCats] = useState("");
    const [category, setCategory] = useState([]);
  
   
    const [cat, setCat] = useState("");
    const [subCategory, setSubCategory] = useState([]);
    const [subCat, setSubCat] = useState("");
  
    const [duration, setDuration] = useState("");
    const [submittedDate, setServiceDateStartRange] = useState("");

    const [filterResultShow, setFilterResultShow] = useState(false)

    const [dialogueState,setDialogueState] = useState({
      type : '',
      show : false,
      message : '',
      link:""
  })
  
    const handleApplyFilter = () => {
      if(activeTab === "ongoing"){
        _fetchOngoingData();
      }else if(activeTab === "active"){
        _fetchActiveData();
      }else if(activeTab === "closed"){
        _fetchClosedData();
      }
    //   setfilterUI(false);
   
  
   
  
    };


    console.log("asdwqeqweqw", props, activeTab)

    useEffect(()=>{
      if(props.activeTab){
        setActiveTab(props.activeTab)

        if(props.activeTab === "ongoing" && props.requestDetails){
          setPageData(props.requestDetails)
        }else if(props.activeTab === "active" && props.requestDetails){
          setActiveData(props.requestDetails)
        }else if(props.activeTab === "closed" && props.requestDetails){
          setCloseData(props.requestDetails)
        }

        // function isJsonString(str) {
        //   try {
        //     JSON.parse(str);
        //   } catch (e) {
        //     return false;
        //   }
        //   return true;
        // }

        // if(sessionStorage.getItem("cus_req") !== null && sessionStorage.getItem("cus_req") !== undefined){
        //   if(isJsonString(sessionStorage.getItem("cus_req"))){
        //     // setAllCategories(JSON.parse(sessionStorage.getItem("cus_req")) || [])
        //     const data = JSON.parse(sessionStorage.getItem("cus_req"))

        //     setActiveTab(data.activeTab)
        //     if(data.activeTab === "ongoing" && data.data){
        //       setPageData(data.data)
        //     }else if(data.activeTab === "active" && data.data){
        //       setActiveData(data.data)
        //     }else if(data.activeTab === "closed" && data.data){
        //       setCloseData(data.data)
        //     }
        //   }
        // }

      }
    },[props])


    // useEffect(()=>{
    //   function isJsonString(str) {
    //     try {
    //       JSON.parse(str);
    //     } catch (e) {
    //       return false;
    //     }
    //     return true;
    //   }

    //   if(sessionStorage.getItem("cus_req") !== null && sessionStorage.getItem("cus_req") !== undefined){
    //     if(isJsonString(sessionStorage.getItem("cus_req"))){
    //       // setAllCategories(JSON.parse(sessionStorage.getItem("cus_req")) || [])
    //       const item = JSON.parse(sessionStorage.getItem("cus_req"))
          

    //       // console.log("dasdxcxzczcxzc", item)

    //       if(item.activeTab === "ongoing"){
    //         if(serviceData.filter((items)=>items.id === item.data.id)){
    //           setPageData(item.data || serviceData[0])
    //           setActiveTab(item.activeTab)
    //           return 
    //         }
              
    //       }

    //       if(item.activeTab === "active"){
    //         if(activeService.filter((items)=>items.id === item.data.id)){
    //         setActiveData(item.data || activeService[0])
    //         setActiveTab(item.activeTab)
    //         return 
    //         }
    //       }

    //       if(item.activeTab === "closed"){
    //         if(closeService.filter((items)=>items.id === item.data.id)){
    //         setCloseData(item.data || closeService[0])
    //         setActiveTab(item.activeTab)
    //         return 
    //         }
    //       }

    //     }
    //   }


    // },[processing])
  
 


  const _filterCategoryRequestData = (data, value) => {
    return data.filter(item => {
          return Object.keys(item).some(key =>
                    item.category && item.category.category_name &&  item.category.category_name.toLowerCase().includes(value.toLowerCase()) || 
                    item.serviceType && item.serviceType.name && item.serviceType.name.toLowerCase().includes(value.toLowerCase()) ||  
                    item.sub_category && item.sub_category.sub_category_name && item.sub_category.sub_category_name.toLowerCase().includes(value.toLowerCase())   
                )
              }); 
  }

  const _filterLocationRequestData = (data, value) => {
    return data.filter(item => {
          return Object.keys(item).some(key =>
                    item.zip_code &&  item.zip_code.toLowerCase().includes(value.toLowerCase()) || 
                    item.cityGet && item.cityGet.name && item.cityGet.name.toLowerCase().includes(value.toLowerCase())
                )
              }); 
  }


  const handleSearchChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      if(name === "searchService") {

          if(activeTab === "ongoing") {
            let ongoingFilterData = _filterCategoryRequestData(staticServiceData, value);
            setServiceData(ongoingFilterData);
            setPageData(ongoingFilterData[0] || []);
          }
          
          if(activeTab === "active") {
            let activeFilterData  = _filterCategoryRequestData(staticActiveService, value);
            setActiveService(activeFilterData);
            setActiveData(activeFilterData[0] || []);
          }
          
          if(activeTab === "closed") {
            let closeFilterData   = _filterCategoryRequestData(staticCloseService, value);
            setCloseService(closeFilterData);
            setCloseData(closeFilterData[0] || []);
          }          
          
      }

      if(name === "searchLocation") {
          if(activeTab === "ongoing") {
            let ongoingFilterData = _filterLocationRequestData(staticServiceData, value);
            setServiceData(ongoingFilterData);
            setPageData(ongoingFilterData[0] || []);
          }
          
          if(activeTab === "active") {
            let activeFilterData  = _filterLocationRequestData(staticActiveService, value);
            setActiveService(activeFilterData);
            setActiveData(activeFilterData[0] || []);
          }
          
          if(activeTab === "closed") {
            let closeFilterData   = _filterLocationRequestData(staticCloseService, value);
            setCloseService(closeFilterData);
            setCloseData(closeFilterData[0] || []);
          }          
      }

    }else {
          setActiveService( staticActiveService );
          setCloseService( staticCloseService );
          setServiceData( staticServiceData );
    }


  }

  const [disputeReason,setDisputeReason] = useState([])
  useEffect(() =>{
    
    APIService.disputeReason()
        .then((res) => {
            // console.log("close resssssssssss", res.data);
            setDisputeReason(res.data)
           
        })
        .catch((e) => console.log("error", e));

    APIService.fetchAllCategory()
        .then((res) => {
          setServiceCats(res.data.data)
          setCategory(res.data.data)
        })
        .catch((err) => setErr(err));

    APIService.fetchCountry()
        .then((res) => setCountries(res.data.data))
        .catch((err) => setErr(err));
  },[])

  useEffect(() => {
    let token1 = localStorage.getItem("_tk");
    setToken(token1);
    

    callApis()
    

   
    


    setFilterData(globalArray);
  }, [reload, activeTab]);

  const callApis = ()=>{
    
    if(activeTab === "ongoing"){
      _fetchOngoingData()
    }else if(activeTab === "active"){
      _fetchActiveData()
    }else if(activeTab === "closed"){
      _fetchClosedData()
    }
    
    // setInterval(() => {
    //   callApis()
    // }, 30 * 1000);
    
  }

 

  useEffect(()=>{
    const interval = setInterval(() => {
      callApis()
     
    }, 30 * 1000);

    return () =>{
      clearInterval(interval)
    }
  },[activeTab, pageData, closeData, activeData])

 

  const _fetchOngoingData = ()=>{
    APIService.fetchOnGoing({
      country,
      state,
      city,
      filterRequestID,
      cat,
      subCat,
      filterStatus,
      submittedDate,
      serviceDate,
      duration,
      currentPageNo1
    })
    .then((res) => {
      // console.log("ongoing----- 67", pageData);
      setServiceData(res.data?.data || []);
      // setPageData(res.data?.data[0] || {});

      if(pageData.id){
        setPageData(res.data?.data.filter((item)=> item.id === pageData.id)[0] || {});
      }else{
        setPageData(res.data?.data[0] || {});
      }

      setStaticServiceData(res.data?.data || []);

      setTotalPageNo1(Math.ceil(res.data.total/10) || 1)


      if(
        // country === "" &&
        state === "" &&
        city === "" &&
        filterRequestID === "" &&
        cat === "" &&
        subCat === "" &&
        filterStatus === "" &&
        submittedDate === "" &&
        serviceDate === "" &&
        duration === "" 
      ){
        setFilterResultShow(false)
      }else{
        setFilterResultShow(true)
      }

      // if (
      //   props === undefined ||
      //   props?.requestId === null ||
      //   props?.requestId === undefined
      // ) {
        
      // } else if(res.data.status.length > 0){
        
      //   let topData = res.data.status
      //     .sort((a, b) => b.id - a.id)
      //     .filter((item) => item.id !== parseInt(props.requestId));

      //   let selectedId = res.data.status
      //     .sort((a, b) => b.id - a.id)
      //     .filter((item) => item.id === parseInt(props.requestId));
      //   // console.log("................//////////////", topData)
      //   topData.unshift(...selectedId);

      //   setServiceData(topData);

      //   setPageData(...selectedId);

      
      // }

      setProcessing((prev) => { 
        return {
          ...prev, one: true
        }
      } );

      setfilterUI(false);
 
    })
    .catch((e) => {
     setProcessing((prev) => { 
        return {
          ...prev, one: true
        }
      } );
      
      // setDialogueState({
      //   type : 'Error',
      //   show:true,
      //   message : (e && e.response && e.response.data && e.response.data.message) || "",
      //   // link : '/register'
      // })
    }); 
  }

  const _fetchActiveData = ()=>{
    APIService.fetchActive({
      country,
      state,
      city,
      filterRequestID,
      cat,
      subCat,
      filterStatus,
      submittedDate,
      serviceDate,
      duration,
      currentPageNo2
    })
    .then((res) => {
      // console.log("active res", res.data.status);
      setActiveService(res.data.data || []);
      // setActiveData(res.data.data[0] || []);

      if(activeData.id){
        setActiveData(res.data?.data.filter((item)=> item.id === activeData.id)[0] || {});
      }else{
        setActiveData(res.data?.data[0] || {});
      }

      setStaticActiveService(res.data.data || []);

      setTotalPageNo2(Math.ceil(res.data.total/10) || 1)
      
      setProcessing((prev) => {
        return {
          ...prev,
          two: true,
        };
      });
      setfilterUI(false);

      if(
        // country === "" &&
        state === "" &&
        city === "" &&
        filterRequestID === "" &&
        cat === "" &&
        subCat === "" &&
        filterStatus === "" &&
        submittedDate === "" &&
        serviceDate === "" &&
        duration === "" 
      ){
        setFilterResultShow(false)
      }else{
        setFilterResultShow(true)
      }
    })
    .catch((e) => {
      setProcessing((prev) => { 
        return {
          ...prev, two: true
        }
      } );
      
      // setDialogueState({
      //   type : 'Error',
      //   show:true,
      //   message : (e && e.response && e.response.data && e.response.data.message) || "",
      //   // link : '/register'
      // })
    });
      
    }

  const _fetchClosedData = () =>{
    
    APIService.fetchClose({
      country,
      state,
      city,
      filterRequestID,
      cat,
      subCat,
      filterStatus,
      submittedDate,
      serviceDate,
      duration,
      currentPageNo3
    })
      .then((res) => {
        // console.log("close res", res.data.data);
        setCloseService(res.data.data || []);
        // setCloseData(res.data.data[0] || []);

        if(closeData.id){
          setCloseData(res.data?.data.filter((item)=> item.id === closeData.id)[0] || {});
        }else{
          setCloseData(res.data?.data[0] || {});
        }

        setStaticCloseService(res.data.data || []);

        setTotalPageNo3(Math.ceil(res.data.total/10) || 1)
        
        setProcessing((prev) => {
          return {
            ...prev,
            three: true,
          };
        });
        setfilterUI(false);
        if(
          // country === "" &&
          state === "" &&
          city === "" &&
          filterRequestID === "" &&
          cat === "" &&
          subCat === "" &&
          filterStatus === "" &&
          submittedDate === "" &&
          serviceDate === "" &&
          duration === "" 
        ){
          setFilterResultShow(false)
        }else{
          setFilterResultShow(true)
        }
      })
      .catch((e) => {
        setProcessing((prev) => { 
          return {
            ...prev, three: true
          }
        } );
        
        // setDialogueState({
        //   type : 'Error',
        //   show:true,
        //   message : (e && e.response && e.response.data && e.response.data.message) || "",
        //   // link : '/register'
        // })
      });
      
  }

  document.body.classList.add("dark-header");
  const handleDataChange = (e) => {
    // let topData = serviceData.filter((item) => item !== e)
    
    // topData.unshift(e)

    // setServiceData(topData)
      

    setPageData(e)
  }

  const handleActiveChange = (e) => {

    // let topData = activeService.filter((item) => item !== e)
    // console.log("................//////////////", topData)
    // topData.unshift(e)
    // setActiveData(topData)
    
    setActiveData(e);
  }
  const handleCloseChange = (e) => {
    
    // let topData = closeService.filter((item) => item !== e);
    // console.log("................//////////////", topData)
    // topData.unshift(e);
    // setCloseService(topData);
    
    setCloseData(e);
  }

  useEffect(() => { 
    window.scrollTo({
      top: 150,
      left: 0,
      behavior: "smooth",
    });
  // },[pageData, activeData, closeData])
},[])

  const onCancel = (id,data) => {
    // console.log("ok");
    // setReload((prev) => { return !prev })
    setRequestId(id);
    setCancelRequest(data)
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#FF8805",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderButtons = (data) => {
    switch (data.sr_status) {
      case 1:
        return (
          <div>
            <Link
              to={{
                pathname: "/edit-my-request",
                state: { pageData: data },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              Edit Request
            </Link>
            <a
              href={void 0}
              data-toggle="modal"
              data-target="#cancel-request-modal"
              className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
              onClick={() => onCancel(data.id,data)}
            >
              Cancel Request
            </a>
          </div>
        );
      case 2:
        return null;
      case 3:
        return (
          <div>
            <Link
              to={{
                pathname: "/edit-my-request",
                state: { pageData: data },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              Edit Request
            </Link>
            <a
              href={void 0}
              data-toggle="modal"
              data-target="#cancel-request-modal"
              className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
              onClick={() => onCancel(data.id,data)}
            >
              {" "}
              Cancel Request{" "}
            </a>
          </div>
        );
      case 4:
        return (
          <div>
            {/* <Link
              to={{
                pathname: "/edit-my-request",
                state: { pageData: data },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              {" "}
              Edit Request{" "}
            </Link> */}
            <a
              href={void 0}
              data-toggle="modal"
              data-target="#cancel-request-modal"
              className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
              onClick={() => onCancel(data.id,data)}
            >
              {" "}
              Cancel Request{" "}
            </a>
          </div>
        );
      // case 5:
      //   return (
      //     <div>
      //       <Link
      //         to={{
      //           pathname: "/edit-my-request",
      //           state: { pageData: data },
      //         }}
      //         className="btn btn-red btn-md ardCat-submitQ-btn"
      //       >
      //         Edit Request
      //       </Link>
      //     </div>
      //   );
      case 6:
        return (
          <div>
            <Link
              className="btn btn-red btn-md ardCat-submitQ-btn"
              to={{
                pathname: "/accept-completion",
                state: { ...data, activeTab: activeTab },
              }}
            >
              Accept Completion
            </Link>
          </div>
        );
      case 7:
        return (
          <div>
            <Link
              to={{
                pathname: "/accept-completion",
                state: { ...data, activeTab: activeTab },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              Accept Completion
            </Link>
          </div>
        );
      case 8:
        return (
          <div>
            <Link
              to={{
                pathname: "/accept-completion",
                state: { ...data, activeTab: activeTab },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              {" "}
              Accept Completion{" "}
            </Link>
          </div>
        );
      case 9:
        return (
          <div>
            <div
              data-toggle="modal"
              data-target="#warranty-request-modal"
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              Warranty Request
            </div>
            <div
              data-toggle="modal"
              data-target="#dispute-request-modal"
              className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
            >
              Dispute Request
            </div>
          </div>
        );
      case 10:
        return (
          <div>
            <Link
              to={{
                pathname: "/accept-completion",
                state: { ...data, activeTab: activeTab },
              }}
              className="btn btn-red btn-md ardCat-submitQ-btn"
            >
              Accept Completion
            </Link>
          </div>
        );
      case 13:
        return (
          <div
            data-toggle="modal"
            data-target="#warranty-request-modal"
            className="btn btn-red btn-md ardCat-submitQ-btn"
          >
            Warranty Request
          </div>
        );
      default:
        return null;
    }
  };

  const additionalDetails = (data) => { 
    // console.log(
    //   "///////////////// template",
    //   // template.default.additionalDetails["1:4"]
    //   data
    // );

    if (data?.category_id === 1 && data?.sub_category_id === 4) {
      return (
        <>
          <div className="catPoint-item">
            <span> Make </span>
            <span> {data?.addtionalDetail?.appliance_make || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Model </span>
            <span> {data?.addtionalDetail?.appliance_model || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Year </span>
            <span> 
              {/* {data?.addtionalDetail?.appliance_year || "--"}  */}
              {
                template?.default?.additionalDetails["1:4"]
                  ?.appliance_year.value[
                  data?.addtionalDetail?.appliance_year - 1
                ]
                ||
                "--"
              }
            </span>
          </div>
        </>
      );
    } else if (data?.category_id === 3) {
      return (
        <>
          <div className="catPoint-item">
            <span> Phone Consultation </span>
            <span>
              {" "}
              {/* {data?.addtionalDetail?.phone_consultation || "--"}{" "} */}
              {
                template?.default?.additionalDetails["3:any"]
                  ?.phone_consultation.value[
                  data?.addtionalDetail?.phone_consultation - 1
                ]
                ||
                "--"
              }
            </span>
          </div>
        </>
      );
    } else if (data?.category_id === 4 || data?.category_id === 5) {
      return (
        <>
          <div className="catPoint-item">
            <span> Type Of House </span>
            <span>
              {/* {data?.addtionalDetail?.type_of_house_id || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.type_of_house_id
                .value[data?.addtionalDetail?.type_of_house_id - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Beds </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_bed || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_bed.value[
                data?.addtionalDetail?.no_of_bed - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Bath </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_bath || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_bath.value[
                data?.addtionalDetail?.no_of_bath - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Level </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_level || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_level.value[
                data?.addtionalDetail?.no_of_level - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Flooring Type </span>
            <span>
              {/* {data?.addtionalDetail?.flooring_type || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.flooring_type
                .value[data?.addtionalDetail?.flooring_type - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Sqft </span>
            <span> {data?.addtionalDetail?.sqft || "--"} </span>
          </div>
        </>
      );
    } else if (data?.category_id === 6) {
      return (
        <>
          <div className="catPoint-item">
            <span> Auto Type </span>
            <span>
              {/* {data?.addtionalDetail?.auto_type || "--"} */}
              {template?.default?.additionalDetails["6:any"]?.auto_type.value[
                data?.addtionalDetail?.auto_type - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Make </span>
            <span> {parseInt(data?.addtionalDetail?.auto_make) === 0 ? "--" : data?.addtionalDetail?.auto_make} </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Model </span>
            <span> {parseInt(data?.addtionalDetail?.auto_model) === 0 ? "--" : data?.addtionalDetail?.auto_model} </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Year </span>
            <span>
              {/* {data?.addtionalDetail?.auto_year || "--"} */}
              {template?.default?.additionalDetails["6:any"]?.auto_year.value[
                data?.addtionalDetail?.auto_year - 1
              ] || "--"}
            </span>
          </div>
        </>
      );
    } else if (data?.category_id === 7) {
      return (
        <>
          <div className="catPoint-item">
            <span> Food Delivery Type </span>
            <span>
              {/* {data?.addtionalDetail?.food_delivery_type || "--"} */}
              {template?.default?.additionalDetails["7:any"]?.food_delivery_type
                .value[data?.addtionalDetail?.food_delivery_type - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. of People </span>
            <span> {data?.addtionalDetail?.no_Of_People || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Spicy Level </span>
            <span>
              {/* {data?.addtionalDetail?.spicyLevel || "--"} */}
              {template?.default?.additionalDetails["7:any"]?.spicy_Level.value[
                data?.addtionalDetail?.spicy_Level - 1
              ] || "--"}
            </span>
          </div>
        </>
      );
    } else { 
      return null
    }

  }

  const getExpiryDate = (closeData) =>{

    let expiryDateQuote = closeData?.quote && closeData?.quote.filter((item)=>item.quote_status === 3)

    // console.log("rrrrrrrrrrrrrrr", expiryDateQuote)

return(
    expiryDateQuote && expiryDateQuote[0]?.warranty_expiry_date ? (
      <>
      
      {dateWithFormate(expiryDateQuote[0]?.warranty_expiry_date.split("T")[0].split("-").reverse().join("-"))} 
      <br /> 
      {dateWithFormate(
        expiryDateQuote[0]?.warranty_expiry_date.split("T")[1].split(",")[0],
        "hh:mm:ss A",
        "HH:mm:ss"
      )}
      </>
      )
      : "--"
    )
  }

  /////////////////////////////////////////////////////////////////////////

// const handleCountry = (e) => {
//   setCountry(e.target.value);
//   APIService.fetchState({ country_id: e.target.value })
//     .then((res) => setStates(res.data.data))
//     .catch((err) => console.log(err));
// };

useEffect(()=>{
  APIService.fetchState({ country_id: "233" })
  .then((res) => setStates(res.data.data.sort((a, b) => a.name.localeCompare(b.name))))
  .catch((err) => console.log(err));

},[])

const handleState = (e) => {
  setState(e.target.value);
  APIService.fetchCity({ state_id: e.target.value })
    .then((res) => setCities(res.data.data))
    .catch((err) => console.log(err));
};

const handleCategory = (e) => {
  // console.log("yogesh",e.target.value)
  setCat(e.target.value);
  setSubCategory((category.filter((sc) => sc.id == e.target.value)[0] && category.filter((sc) => sc.id == e.target.value)[0].subcategories) || []);
  setSubCat("")
};

const handleDuration=(val) =>{
  setDuration(val)
  }



  //////////////////////////pagination
    const useDidMountEffect = (func, deps) => {
      const didMount = useRef(false);
  
      useEffect(() => {
          if (didMount.current) func();
          else didMount.current = true;
      }, deps);
  }

  
  const [currentPageNo1, setCurrentPageNo1] = useState(1)
    const [totalPageNo1, setTotalPageNo1 ] = useState(1)

    
  const [currentPageNo2, setCurrentPageNo2] = useState(1)
  const [totalPageNo2, setTotalPageNo2 ] = useState(1)



  
  const [currentPageNo3, setCurrentPageNo3] = useState(1)
    const [totalPageNo3, setTotalPageNo3 ] = useState(1)





  const takeToPrevious1 = () => { 
    setCurrentPageNo1(currentPageNo1 - 1)
   
  }

  const takeToNext1 = () => { 
    setCurrentPageNo1(currentPageNo1 + 1)
  }

  useDidMountEffect(()=>{
    _fetchOngoingData()
  },[currentPageNo1])
 

  const takeToPrevious2 = () => { 
    setCurrentPageNo2(currentPageNo2 - 1)
   
  }

  const takeToNext2 = () => { 
    setCurrentPageNo2(currentPageNo2 + 1)
  }

  useDidMountEffect(()=>{
    _fetchActiveData()
  },[currentPageNo2])
 

  const takeToPrevious3 = () => { 
    setCurrentPageNo3(currentPageNo3 - 1)
   
  }

  const takeToNext3 = () => { 
    setCurrentPageNo3(currentPageNo3 + 1)
  }

  useDidMountEffect(()=>{
    _fetchClosedData()
  },[currentPageNo3])
 




  // console.log("dddddddddddddddddd", closeData)



  /////////////////////////////// date compare in filter

  const [endDateMin, setEndDateMin] = useState("")
  const [startDateMax, setStartDateMax] = useState("")

  useEffect(()=>{
    setEndDateMin(submittedDate)
  },[submittedDate])

  useEffect(()=>{
    setStartDateMax(serviceDate)
  },[serviceDate])

//   const swipe =()=> {
//     var largeImage = document.getElementById('largeImage');
//     largeImage.style.display = 'block';
//     largeImage.style.width=200+"px";
//     largeImage.style.height=200+"px";
//     var url=largeImage.getAttribute('src');
//     window.open(url,'Image','width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
//  }

// console.log("xzczxczxcxzczxc", pageData, activeData, closeData)

const getConfirmedQuote = (quotes)=>{
  const quote = quotes.filter((item) => item.quote_status === 3)
  // console.log("rerererererer", quotes, quote)
  if(quote){
    return(quote[0])
  }else{
    return ({
      additional_labor_amount:"",
      additional_material_amount:"",
      additional_amount_comments:""
    })
  }
 
}


  return (
    <>
             <Dialogue state={dialogueState} setState={setDialogueState} />
      <div className="tab-content myRequest-tab-content">
        <div className="tab-pane active" id="gh-customer-tab" role="tabpanel">
          <div className={`filter-status-section ${ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="request-fs-block">
                    <div className="status-tabnav">
                      {filterResultShow ?
                      
                      <button className="btn btn-red" onClick={()=> window.location.reload()}>Clear Filter</button>
                       
                      :
                      <ul className="nav nav-tabs" role="tablist">
                        <li>
                          {" "}
                          <a
                            className={`${activeTab === "ongoing"? "active" : ""}`}
                            data-toggle="tab"
                            href="#status-ongoing-tab"
                            role="tab"
                            onClick={() => {
                              setActiveTab("ongoing")
                              // sessionStorage.setItem("cus_req", JSON.stringify({activeTab: "ongoing"}))
                            }}
                          >
                            {" "}
                            Ongoing{" "}
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                          className={`${activeTab === "active"? "active" : ""}`}
                            data-toggle="tab"
                            href="#status-active-tab"
                            role="tab"
                            onClick={() => setActiveTab("active")}
                          >
                            {" "}
                            Active{" "}
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                          className={`${activeTab === "closed"? "active" : ""}`}
                            data-toggle="tab"
                            href="#status-closed-tab"
                            role="tab"
                            onClick={() => setActiveTab("closed")}
                          >
                            {" "}
                            Closed{" "}
                          </a>
                        </li>
                      </ul> 
                      }
                    </div>

                    <div className="requestFilter-block">
                      <div className="search-city-form">
                        <div className="sc-form-group serviceSearch-input-group">
                          <i className="fal fa-search"></i>
                          <input
                            type="text"
                            placeholder="Search for a Service"
                            className="form-control"
                            onChange={handleSearchChange}
                            name="searchService"
                          />
                        </div>
                        <div className="sc-form-group">
                          <input
                            type="text"
                            className="form-control zipCity-input"
                            placeholder="Enter Zipcode or City"
                            onChange={handleSearchChange}
                            name="searchLocation"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="moreFilter-btn btn btn-orange"
                        id="catFilter-btn"
                        onClick={() => setfilterUI(!filterUI)}
                      >
                        <img
                          src={images.filterIcon}
                          className="img-fluid"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {filterUI ? (
            <section className="catOpen-filter-section open">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cat-open-filter">
                  <div className="cof-inner">
                    <div className="cof-close-block">
                      <button
                        className="cofClose-btn"
                        type="button"
                        onClick={() => setfilterUI(false)}
                      >
                        {" "}
                        <i className="fal fa-times"></i>
                      </button>
                    </div>

                    <form>
                      <div className="row align-items-end">
                        {/* <div className="form-group col-xl-4 col-md-6">
                          <label> Location </label>
                          <select
                            value={filterCity}
                            className="form-control"
                            onChange={(e) => setFilterCity(e.target.value)}
                          >
                            <option value="">City</option>
                            {filterData &&
                              filterData.map((sc) => {
                                console.log("sc.cityGet.name", sc.cityGet.name);
                                return (
                                  <option value={sc.cityGet.name}>
                                    {sc.cityGet.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <select
                            value={filterState}
                            className="form-control"
                            onChange={(e) => setFilterState(e.target.value)}
                          >
                            <option value="">State</option>
                            {filterData &&
                              filterData.map((sc) => {
                                return (
                                  <option value={sc.cityGet.state_id}>
                                    {sc.cityGet.states.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div> */}

<div className="form-group col-md-4">
                        <label> Country </label>
                        <select className="form-control" 
                        name='country'
                        // onChange={handleCountry}
                        value="233"
                        disabled
                        >
                        <option disabled={true} selected={true} value="">  Country </option>
                        {countries.map((res) =>
                            <option key={res.id} value={res.id} selected={state.country == res.id ? true : false}> {res.name} </option>
                        )}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> State </label>
                        <select className="form-control"
                        name="state" 
                        onChange={handleState}
                        value={state}
                        >
                        <option disabled={true} selected={true} value=""> State </option>
                        {states.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> City </label>
                        <select className="form-control"
                        name="city"
                        onChange={(e)=>{
                          setCity(e.target.value)
                        }}
                        value={city}
                        >
                        <option disabled={true} selected={true} value=""> City </option>
                        {cities.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                        </select>
                      </div>


                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request ID </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter the Request ID "
                            value={filterRequestID}
                            onChange={(e) => setFilterRequestID(e.target.value)}
                          />
                        </div>

                        
                        <div className="form-group col-xl-4 col-md-6">
                          <label> Category </label>
                          <select
                            value={cat}
                            className="form-control"
                            onChange={handleCategory}
                          >
                            <option value="">
                              Select the service category
                            </option>
                            {category.map((sc) => (
                              <option value={sc.id}>{sc.category_name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                        <label> Sub Category </label>
                          <select
                            value={subCat}
                            className="form-control"
  
                            onChange={(e)=>setSubCat(e.target.value)}
                          >
                            <option value="">
                              Select the service sub-category
                            </option>
                            {subCategory.map((sc) => (
                              <option value={sc.id}>
                                {sc.sub_category_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request Status </label>
                          <select
                            className="form-control"
                            onChange={(e) => setStatusChange(e.target.value)}
                            value={filterStatus}
                          >
                            <option selected value="">
                              {" "}
                              Select the request status{" "}
                            </option>
                            <option value="1"> Submitted </option>
                            <option value="2"> Cancelled </option>
                            <option value="3"> Quote Received </option>
                            <option value="4"> Confirmed </option>
                            <option value="5"> Started </option>
                            <option value="6"> Completed </option>
                            <option value="7"> Pending </option>

                            <option value="8"> Past Due </option>
                            <option value="9"> Closed </option>
                            <option value="10"> Revisit </option>
                            <option value="11"> Warranty </option>
                            <option value="12"> Dispute </option>
                            <option value="13"> Warranty Closed </option>
                            <option value="14"> Dispute Closed </option>
                            <option value="15"> Disabled </option>
                          </select>
                        </div>

                        <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker00"
                          data-target-input="nearest"
                        >
                          <label for=""> Service Date : Start Range </label>
                          <input
                            type="date"
                            onChange={(e) => setServiceDateStartRange(e.target.value)}
                            className="form-control"
                            data-target="#datepicker00"
                            data-toggle="datetimepicker"
                            placeholder="Submitted Date"
                            value={submittedDate}
                            max={startDateMax}
                          />
                        </div>

                        <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker1300"
                          data-target-input="nearest"
                        >
                          <label for=""> Service Date : End Range </label>
                          <input
                            type="date"
                            onChange={(e) => setServiceDateEndRange(e.target.value)}
                            className="form-control"
                            data-target="#datepicker1300"
                            data-toggle="datetimepicker"
                            placeholder="Service Date"
                            value={serviceDate}
                            min={endDateMin}
                          />
                        </div>

                        <div className="form-group col-xl-4 col-12 mb-0 mb-md-2">
                          <label> Duration </label>
                          <div className="radio-block">
                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration30"
                                value="0-30"
                                // checked={e.target.checked}
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "0-30"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration30"
                              >
                                {" "}
                                0-30 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                value="31-90"
                                id="duration90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "31-90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90"
                              >
                                {" "}
                                31-90 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration90above"
                                value="90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90above"
                              >
                                {
                                `> 90 Days`}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="form-group col-12 mt-3 mb-0">
                          <div className="cof-submit-group text-center">
                          <button
                            type="button"
                            className="btn btn-black btn-sm"
                            onClick={() => {
                              setCountry("")
                              setState("")
                              setCity("")
                              setFilterRequestID("")
                              setCat("")
                              setSubCat("")
                              setStatusChange("")
                              setServiceDateStartRange("")
                              setServiceDateEndRange("")
                              setDuration("")
                              setSubCategory([])
                            }}
                          >
                            {" "}
                            Clear{" "}
                          </button>
                            <button
                              type="button"
                              className="btn btn-black btn-sm ml-3"
                              onClick={() => setfilterUI(false)}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-red btn-sm ml-3 mt-2 mt-md-0"
                              onClick={handleApplyFilter}
                            >
                              {" "}
                              Apply{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </section>
          ) : null}

          <div className={`tab-content myRequest-tab-content padding-top ardCat-tab-wrap ${ongoingCardShow ? "ardCat-show": ""}`}>
            <div
              className={`tab-pane ${activeTab === "ongoing" ? "active" : ""}`}
              id="status-ongoing-tab"
              role="tabpanel"
            >
              <div className="requestDetail-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-4 ardCat-col-box">
                      <div className="ardCat-list mCustomScrollbar">
                        <Scrollbars renderThumbVertical={renderThumb}>
                          {serviceData.length !== 0
                            ? serviceData.map((data, i) => {
                                // console.log("serviceData----", data);
                                // globalArray.push(data);
                                return (
                                  <div
                                    className="ard-cat-item hnc-item service-cat-item"
                                    style={{ width: "96%" }}
                                    onClick={() => {
                                      handleDataChange(data)
                                      setOngoingCardShow(true)
                                      sessionStorage.setItem("cus_req", JSON.stringify({activeTab: "ongoing", data: data}))
                                    }}
                                  >
                                    <a
                                      href={void 0}
                                      className={`service-cat-inner myRq-link ${
                                        data.id === pageData.id
                                          ? "open-request"
                                          : ""
                                      }`}
                                      data-rel="ongoingTab-1"
                                    >
                                      <div className="sc-body">
                                        <div className="scb-bottom">
                                          <span className="sc-name f-24">
                                            {data.title ? data.title : "N/A"}
                                          </span>
                                          <div className="service-status d-flex justify-content-between">
                                            {data?.srstatus?.name ===
                                            "Submitted"
                                              ? `${data?.srstatus?.name}`
                                              : `${data?.quote_count || ""}
                                            ${data?.srstatus?.name}`}
                                            <span>SR ID: {data?.id}</span>
                                          </div>
                                          <span>
                                            {data.address_1
                                              ? data.address_1
                                              : "N/A"}
                                            
                                            {data.address_2
                                              ? `, ${data.address_2}`
                                              : ""}
                                            {/* <br className="d-none d-md-block" /> */}
                                            <br />
                                            {dateWithFormate(
                                              data?.service_date
                                            )}
                                            &nbsp; (
                                            {dateWithFormate(
                                              data?.timeGet?.start_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}{" "}
                                            -{" "}
                                            {dateWithFormate(
                                              data?.timeGet?.end_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}
                                            )
                                          </span>
                                        </div>
                                      </div> 
                                        {/* <div className="sc-body">
                                    <div className="scb-top">
                                      <span className="service-type">
                                        {data?.quote_count} Quotes
                                      </span>
                                      <span>SR ID: {data?.id}</span>
                                    </div>
                                    <div className="scb-bottom">
                                      <span className="sc-name f-24">
                                        {" "}
                                        {data.title ? data.title.length > 25 ? `${data.title.substring(0, 25)}...` : data.title   : "N/A"}{" "}
                                      </span>

                                      <span>
                                        {data?.address_1}, {data?.address_2}
                                        <br className="d-none d-md-block" />
                                        {dateWithFormate(data?.service_date)}
                                        &nbsp; (
                                        {dateWithFormate(
                                          data?.timeGet?.start_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}{" "}
                                        -{" "}
                                        {dateWithFormate(
                                          data?.timeGet?.end_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}
                                        )
                                      </span>
                                    </div>
                                  </div> */}
                                    </a>
                                  </div>
                                );
                              })
                            : processing.one
                            ? "No records found."
                            : "loading ..."}
                        </Scrollbars>
                        <div className="e4p-left">
                  <ul className="pagination d-flex justify-content-center">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo1 === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo1 !== 1) {
                            takeToPrevious1();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo1} of {totalPageNo1}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo1 === currentPageNo1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo1 !== currentPageNo1) {
                            takeToNext1();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                      </div>
                    </div>

                    <div className="col-xl-8 ardCat-content-wrap">
                      <div
                        id="ongoingTab-1"
                        className="myRq-tab-content current-request"
                      >
                        <div className="ardCat-detail-right">
                          <div className="ardCatD-head align-items-end">
                            <div className="ardCat-back-block d-block d-xl-none">
                              <button type="button" className="ardCat-back-btn" 
                               onClick={()=>{setOngoingCardShow(false) 
                               }} >
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
                            </div>
                            <div className="left">
                              <h2>
                                {" "}
                                Request ID :{" "}
                                {pageData?.id ? pageData?.id : "N/A"}{" "}
                              </h2>
                              <span>
                                {pageData?.srstatus?.name === "Submitted"
                                  ? `${pageData?.srstatus?.name}`
                                  : `${pageData?.quote_count || ""}
                                            ${pageData?.srstatus?.name || ""}`}
                              </span>
                            </div>
                            <div className="right">
                              {pageData?.sr_status === 3 || pageData?.sr_status === 1  ? (
                                <div className="rvq-block">
                                  <Link
                                    to={{
                                      pathname:
                                        "/quotes-details/" + pageData.id,
                                      state: { pageData: pageData, activeTab: activeTab },
                                    }}
                                    className="red-text-btn"
                                  >
                                    {" "}
                                    <i className="fal fa-usd-circle"></i> View
                                    Quotes
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="ardCatD-body">
                            <div className="ardCatD-cat-block h-100">
                              <div className="servicecat-item">
                                <h5 className="font-weight-bold">
                                  Service Category
                                </h5>
                                {/* <span className="scl"> Service Category </span>
                                <span className="scv">
                                  {" "}
                                  {pageData?.category?.category_name}{" "}
                                </span> */}
                                <i>
                                  {pageData?.category?.category_name?.substring(
                                    0,
                                    25
                                  )}
                                </i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl">
                                  {" "}
                                  Service Sub-Category{" "}
                                </span>
                                <span className="scv">
                                  {" "}
                                  {
                                    pageData?.sub_category?.sub_category_name
                                  }{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Sub-Category
                                </h5>
                                <i>
                                  {pageData?.sub_category?.sub_category_name?.substring(
                                    0,
                                    25
                                  )}
                                </i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl"> Service Type </span>
                                <span className="scv">
                                  {" "}
                                  {pageData?.serviceType?.name}{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Type
                                </h5>
                                <i>
                                  {pageData?.serviceType?.name?.substring(
                                    0,
                                    25
                                  )}
                                </i>
                              </div>
                            </div>

                            <div className="ardCatD-inner-body">
                              <div className="ardCatD-location-time">
                                <div className="ardCatD-lt-inner">
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.locationBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Location{" "}
                                      </span>
                                      <span className="itv">
                                        {pageData?.address_1 ? `${pageData?.address_1}, ` : ""}
                                        {/* {pageData?.address_2},{" "} */}
                                        {pageData?.address_2 ? `${pageData?.address_2}, ` : ""}
                                        
                                        {pageData?.cityGet?.name
                                          ? `${pageData?.cityGet?.name}, `
                                          : ""}
                                        {pageData?.cityGet?.states?.name
                                          ? `${pageData?.cityGet?.states?.name}, `
                                          : ""}{" "}
                                        
                                        
                                        {pageData?.cityGet?.countries?.name
                                          ? `${pageData?.cityGet?.countries?.name} `
                                          : ""}
                                          {pageData?.zip_code ? `- ${pageData?.zip_code}` : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.calendarBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Date and Time{" "}
                                      </span>
                                      <span className="itv">
                                        {dateWithFormate(
                                          pageData?.service_date
                                        )}
                                        &nbsp; (
                                        {dateWithFormate(
                                          pageData?.timeGet?.start_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}{" "}
                                        -{" "}
                                        {dateWithFormate(
                                          pageData?.timeGet?.end_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}
                                        )
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="ardCatD-it-action text-right">
                                  {pageData.status
                                    ? renderButtons(pageData)
                                    : null}
                                </div>
                              </div>
                              <div className="ardCatD-right-space">
                                <div className="ardCatD-content">
                                  <h3> Request Title </h3>
                                  <p>{pageData ? pageData?.title : null}</p>
                                </div>

                                <div className="ardCatD-content">
                                  <h3> Request Description </h3>
                                  <p>
                                    {pageData ? pageData?.description : null}
                                  </p>
                                </div>
                                <div className="ardCatD-point-list">
                                  {additionalDetails(pageData)}
                                </div>

                                <div className="ardCatD-media-block">
                                  <h3> Media </h3>
                                  <div className="catD-media-list">
                                    {pageData &&
                                      pageData.files &&
                                      pageData.files.map((item, index) => (
                                        <div
                                          className="catDM-item"
                                          key={item.file + index}
                                          style={{cursor: "pointer"}}
                                        >
                                          <img
                                            src={
                                              config.endpoints.image_path +
                                              item?.file
                                            }
                                            className="img-fluid"
                                            alt=""
                                            // onClick={()=>swipe()}
                                            onClick={()=>window.open(config.endpoints.image_path +
                                              item?.file)}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`tab-pane ${activeTab === "active" ? "active" : ""}`} id="status-active-tab" role="tabpanel">
              <div className="requestDetail-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-4 ardCat-col-box">
                      <div className="ardCat-list mCustomScrollbar">
                        <Scrollbars renderThumbVertical={renderThumb}>
                          {activeService.length !== 0
                            ? activeService.map((data, i) => {
                                // globalArray.push(data);
                                return (
                                  <div
                                    className="ard-cat-item hnc-item service-cat-item"
                                    style={{ width: "96%" }}
                                    onClick={() => {
                                      handleActiveChange(data)
                                      setOngoingCardShow(true)
                                      sessionStorage.setItem("cus_req", JSON.stringify({activeTab: "active", data: data}))
                                    }}
                                      
                                  >
                                    <a
                                      href={void 0}
                                      className={`service-cat-inner myRq-link ${
                                        data.id === activeData.id
                                          ? "open-request"
                                          : ""
                                      }`}
                                      data-rel="activeTab-1"
                                    >
                                      <div className="sc-body">
                                        <div className="scb-bottom">
                                          <span className="sc-name f-24">
                                            {data.title ? data.title : "N/A"}
                                          </span>
                                          <div className="d-flex justify-content-between">
                                          <div className="service-status">
                                            {data?.srstatus?.name}
                                          </div>
                                          <span>SR ID: {data?.id}</span>
                                          </div>
                                          <span>
                                            {data.address_1
                                              ? data.address_1
                                              : "N/A"}
                                            
                                            {data.address_2
                                              ? `, ${data.address_2}`
                                              : ""}
                                            {/* <br className="d-none d-md-block" /> */}
                                            <br />
                                            {dateWithFormate(
                                              data?.service_date
                                            )}
                                            &nbsp; (
                                            {dateWithFormate(
                                              data?.timeGet?.start_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}{" "}
                                            -{" "}
                                            {dateWithFormate(
                                              data?.timeGet?.end_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })
                            : processing.two
                            ? "No records found."
                            : "loading ..."}
                        </Scrollbars>

                        <div className="e4p-left">
                  <ul className="pagination d-flex justify-content-center">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo2 === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo2 !== 1) {
                            takeToPrevious2();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo2} of {totalPageNo2}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo2 === currentPageNo2 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo2 !== currentPageNo2) {
                            takeToNext2();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                      </div>
                    </div>

                    <div className="col-xl-8 ardCat-content-wrap">
                      <div
                        id="activeTab-1"
                        className="myRq-tab-content current-request"
                      >
                        <div className="ardCat-detail-right">
                          <div className="ardCatD-head align-items-end">
                            <div className="ardCat-back-block d-block d-xl-none">
                              <button type="button" className="ardCat-back-btn" onClick={()=>setOngoingCardShow(false)}>
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
                            </div>
                            <div className="left">
                              <h2>
                                {" "}
                                Request ID :{" "}
                                {activeData.id ? activeData.id : "N/A"}{" "}
                              </h2>
                              <span>{activeData?.srstatus?.name || ""}</span>
                            </div>
                            <div className="right">
                              {[4, 5, 6, 7, 8, 10].includes(
                                activeData.sr_status
                              ) ? (
                                <div className="rvq-block">
                                  <Link
                                    to={{
                                      pathname:
                                        "/quotes-details/" + activeData.id,
                                      state: { pageData: activeData, activeTab: activeTab },
                                    }}
                                    className="red-text-btn"
                                  >
                                    {" "}
                                    <i className="fal fa-usd-circle"></i> View
                                    Quotes
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="ardCatD-body">
                            <div className="ardCatD-cat-block">
                              <div className="servicecat-item">
                                {/* <span className="scl"> Service Category </span>
                                <span className="scv">
                                  {" "}
                                  {activeData?.category?.category_name}{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Category
                                </h5>
                                <i>{activeData?.category?.category_name}</i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl">
                                  {" "}
                                  Service Sub-Category{" "}
                                </span>
                                <span className="scv">
                                  {" "}
                                  {
                                    activeData?.sub_category?.sub_category_name
                                  }{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Sub-Category
                                </h5>
                                <i>
                                  {activeData?.sub_category?.sub_category_name}
                                </i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl"> Service Type </span>
                                <span className="scv">
                                  {" "}
                                  {activeData?.serviceType?.name}{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Type
                                </h5>
                                <i>{activeData?.serviceType?.name}</i>
                              </div>
                            </div>

                            <div className="ardCatD-inner-body">
                              <div className="ardCatD-location-time">
                                <div className="ardCatD-lt-inner">
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.locationBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Location{" "}
                                      </span>
                                      <span className="itv">
                                      {activeData?.address_1 ? `${activeData?.address_1}, ` : ""}
                                        {/* {activeData?.address_2},{" "} */}
                                        {activeData?.address_2 ? `${activeData?.address_2}, ` : ""}
                                        
                                        {activeData
                                          ? `${activeData?.cityGet?.name}, `
                                          : ""}
                                        {activeData?.cityGet
                                          ? `${activeData?.cityGet?.states?.name}, `
                                          : ""}{" "}
                                        
                                        {activeData?.cityGet
                                          ? `${activeData?.cityGet?.countries?.name} `
                                          : ""}
                                          {activeData ? `- ${activeData?.zip_code}` : ""}
                                        
                                      </span>
                                    </div>
                                  </div>
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.calendarBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Date and Time{" "}
                                      </span>
                                      <span className="itv">
                                        {dateWithFormate(
                                          activeData?.service_date
                                        )}
                                        &nbsp; (
                                        {dateWithFormate(
                                          activeData?.timeGet?.start_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}{" "}
                                        -{" "}
                                        {dateWithFormate(
                                          activeData?.timeGet?.end_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}
                                        )
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="ardCatD-it-action text-right">
                                  <>
                                    {activeData.status
                                      ? renderButtons(activeData)
                                      : null}
                                  </>
                                </div>
                              </div>
                              <div className="ardCatD-right-space">
                                <div className="ardCatD-content">
                                  <h3> Request Title </h3>
                                  <p>{activeData ? activeData?.title : null}</p>
                                </div>

                                <div className="ardCatD-content">
                                  <h3> Request Description </h3>
                                  <p>
                                    {activeData
                                      ? activeData?.description
                                      : null}
                                  </p>
                                </div>
                                <div className="ardCatD-point-list">
                                  {additionalDetails(activeData)}
                                </div>
                                
                                {activeData && activeData?.srstatus && (activeData?.srstatus.id === 6 || activeData?.srstatus.id === 7) ?
                           <>
                            <div className="ardCatD-content">
                              <h3> Quote Price </h3>
                              <p>$ {activeData && activeData?.paymentCalculation && activeData?.paymentCalculation?.cust_total_quote_amount  ? (activeData?.paymentCalculation?.cust_total_quote_amount ) : "0"}</p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Labour Amount </h3>
                              <p> 
                                {/* {activeData && activeData?.quote && activeData?.quote[0] && activeData?.quote[0] ? (activeData?.quote[0].additional_labor_amount) : "--"} */}
                                $ {activeData && activeData?.quote ? getConfirmedQuote(activeData?.quote).additional_labor_amount : ""}
                              </p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Material Amount </h3>
                              <p>
                                {/* {activeData && activeData?.quote && activeData?.quote[0] && activeData?.quote[0] ? (activeData?.quote[0].additional_material_amount) : "--"} */}
                                $ {activeData && activeData?.quote ? getConfirmedQuote(activeData?.quote).additional_material_amount : ""}
                                </p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Amount Comments </h3>
                              <p>
                                {/* {activeData && activeData?.quote && activeData?.quote[0] && activeData?.quote[0] ? (activeData?.quote[0].additional_amount_comments) : "--"} */}
                                {activeData && activeData?.quote ? getConfirmedQuote(activeData?.quote).additional_amount_comments : ""}
                                </p>
                            </div>
                           </>
                            :
                            null
                          }

                                <div className="ardCatD-media-block">
                                  <h3> Media </h3>
                                  <div className="catD-media-list">
                                    {activeData &&
                                      activeData.files &&
                                      activeData.files.map((item, index) => (
                                        <div
                                          className="catDM-item"
                                          key={item.file + index}
                                          style={{cursor:"pointer"}}
                                        >
                                          <img
                                            // src={config.image_path + item?.file}
                                            src={
                                              config.endpoints.image_path +
                                              item?.file
                                            }
                                            className="img-fluid"
                                            alt=""
                                            onClick={()=>window.open(config.endpoints.image_path +
                                              item?.file)}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              {activeData.sr_status === 5 ||
                              activeData.sr_status === 10 ? (
                                <div className="ardCat-widget">
                                  <Link to={{
                                      pathname:
                                        "/track",
                                      state: { activeData: activeData, user: "customer" },
                                    }}>
                                    {" "}
                                    <img
                                      src={images.locationBgWhiteIcon}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              ) : null}

{activeData.sr_status === 10 ? (
                             
                             (activeData && activeData?.anothervisit) ?
                               <>
                             <div className="ardCatD-content">
                             <h3> Revisit Reason </h3>
                             {/* <p>{activeData && activeData?.anothervisit && activeData?.anothervisit?.another_visit_reason ? (activeData?.anothervisit?.another_visit_reason) : "--"}</p> */}
                             {anotherVisitReason.length > 0 && (anotherVisitReason[(parseInt(activeData && activeData?.anothervisit && activeData?.anothervisit?.another_visit_reason) - 1)] !== undefined || anotherVisitReason[(parseInt(activeData && activeData?.anothervisit && activeData?.anothervisit?.another_visit_reason) - 1)] !== null) ? 
                          <p>{anotherVisitReason[(parseInt(activeData && activeData?.anothervisit && activeData?.anothervisit?.another_visit_reason) - 1)] || "--"}</p>
                        
                        :
                        null
                        }
                           </div>
                           <div className="ardCatD-content">
                             <h3> Revisit Comment </h3>
                             <p>{activeData && activeData?.anothervisit && activeData?.anothervisit?.visit_details ? (activeData?.anothervisit?.visit_details) : "--"}</p>
                           </div>
                           </>
                           :
                           null
                           ) : null}

{[6, 7, 8, 9, 11, 12, 13, 14].includes(
                                  activeData.sr_status
                                  ) ? 
                                  <div className="ardCatD-point-list">
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span> Submitted Date </span>
                                    <span> {activeData?.submitted_date ? (
                                      <>
                                      {dateWithFormate(activeData?.submitted_date.split("T")[0].split("-").reverse().join("-"))} 
                                      <br /> 
                                      {dateWithFormate(
                                        activeData?.submitted_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Completion Date </span>
                                    {/* <span> {activeData?.completed_date || "--"} </span> */}
                                    <span> {activeData?.completed_date ? (
                                      <>
                                      {dateWithFormate(activeData?.completed_date.split("T")[0].split("-").reverse().join("-"))} 
                                      <br /> 
                                      {dateWithFormate(
                                        activeData?.completed_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Closed Date </span>
                                    {/* <span> {activeData?.closed_date || "--"} </span> */}
                                    <span> {activeData?.closed_date ? (
                                      <>
                                      {dateWithFormate(activeData?.closed_date.split("T")[0].split("-").reverse().join("-"))} 
                                      <br /> 
                                      {dateWithFormate(
                                        activeData?.closed_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Warranty Expiry Date </span>
                                    <span> 
                                      {/* {(activeData?.quote && activeData?.quote[0]?.warranty_expiry_date) || "--"}  */}
                                       {activeData?.quote && activeData?.quote[0]?.warranty_expiry_date ? (
                                      <>
                                      {dateWithFormate(activeData?.quote[0]?.warranty_expiry_date.split("T")[0].split("-").reverse().join("-"))} 
                                      <br /> 
                                      {dateWithFormate(
                                        activeData?.quote[0]?.warranty_expiry_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} 
                                      </span>
                                  </div>
                                  </div>
                                  :
                                  null
                                }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`tab-pane ${activeTab === "closed" ? "active" : ""}`} id="status-closed-tab" role="tabpanel">
              <div className="requestDetail-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-4 ardCat-col-box">
                      <div className="ardCat-list mCustomScrollbar">
                        <Scrollbars renderThumbVertical={renderThumb}>
                          {closeService.length !== 0
                            ? closeService.map((data, i) => {
                                // globalArray.push(data);
                                return (
                                  <div
                                    className="ard-cat-item hnc-item service-cat-item"
                                    style={{ width: "96%" }}
                                    onClick={() => {handleCloseChange(data)
                                      setOngoingCardShow(true)
                                      sessionStorage.setItem("cus_req", JSON.stringify({activeTab: "closed", data: data}))
                                    }}
                                  >
                                    <a
                                      href={void 0}
                                      className={`service-cat-inner myRq-link ${
                                        data.id === closeData.id
                                          ? "open-request"
                                          : ""
                                      }`}
                                      data-rel="closedTab-1"
                                    >
                                      <div className="sc-body">
                                        <div className="scb-bottom">
                                          <span className="sc-name f-24">
                                            {data.title ? data.title : "N/A"}
                                          </span>
                                          <div className="d-flex justify-content-between">
                                          <div className="service-status">
                                            {data?.srstatus?.name}
                                          </div>
                                          <span>SR ID: {data?.id}</span>
                                          </div>
                                          <span>
                                            {data.address_1
                                              ? data.address_1
                                              : "N/A"}
                                          
                                            {data.address_2
                                              ? `, ${data.address_2}`
                                              : ""}
                                            {/* <br className="d-none d-md-block" /> */}
                                            <br />
                                            {dateWithFormate(
                                              data?.service_date
                                            )}
                                            &nbsp; (
                                            {dateWithFormate(
                                              data?.timeGet?.start_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}{" "}
                                            -{" "}
                                            {dateWithFormate(
                                              data?.timeGet?.end_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })
                            : processing.three
                            ? "No records found."
                            : "loading ..."}
                        </Scrollbars>

                        <div className="e4p-left">
                  <ul className="pagination d-flex justify-content-center">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo3 === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo3 !== 1) {
                            takeToPrevious3();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo3} of {totalPageNo3}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo3 === currentPageNo3 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo3 !== currentPageNo3) {
                            takeToNext3();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                      </div>
                    </div>

                    <div className="col-xl-8 ardCat-content-wrap">
                      <div
                        id="closedTab-1"
                        className="myRq-tab-content current-request"
                      >
                        <div className="ardCat-detail-right">
                          <div className="ardCatD-head align-items-end">
                            <div className="ardCat-back-block d-block d-xl-none">
                              <button type="button" className="ardCat-back-btn" onClick={()=>setOngoingCardShow(false)}>
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
                            </div>
                            <div className="left">
                              <h2>
                                {" "}
                                Request ID :{" "}
                                {closeData.id ? closeData.id : "N/A"}{" "}
                              </h2>
                              <span>{closeData?.srstatus?.name || ""}</span>
                            </div>
                            <div className="right">
                              {[2, 9, 11, 12, 13, 14, 15].includes(
                                closeData.sr_status
                              ) ? (
                                <div className="rvq-block">
                                  <Link
                                    to={{
                                      pathname:
                                        "/quotes-details/" + closeData.id,
                                      state: { pageData: closeData, activeTab: activeTab },
                                    }}
                                    className="red-text-btn"
                                  >
                                    {" "}
                                    <i className="fal fa-usd-circle"></i> View
                                    Quotes
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="ardCatD-body">
                            <div className="ardCatD-cat-block">
                              <div className="servicecat-item">
                                {/* <span className="scl"> Service Category </span>
                                <span className="scv">
                                  {" "}
                                  {closeData?.category?.category_name}{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Category
                                </h5>
                                <i>{closeData?.category?.category_name}</i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl">
                                  {" "}
                                  Service Sub-Category{" "}
                                </span>
                                <span className="scv">
                                  {" "}
                                  {
                                    closeData?.sub_category?.sub_category_name
                                  }{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Sub-Category
                                </h5>
                                <i>
                                  {closeData?.sub_category?.sub_category_name}
                                </i>
                              </div>
                              <div className="servicecat-item">
                                {/* <span className="scl"> Service Type </span>
                                <span className="scv">
                                  {" "}
                                  {closeData?.serviceType?.name}{" "}
                                </span> */}
                                <h5 className="font-weight-bold">
                                  Service Type
                                </h5>
                                <i>{closeData?.serviceType?.name}</i>
                              </div>
                            </div>

                            <div className="ardCatD-inner-body">
                              <div className="ardCatD-location-time">
                                <div className="ardCatD-lt-inner">
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.locationBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Location{" "}
                                      </span>
                                      <span className="itv">
                                      {closeData?.address_1 ? `${closeData?.address_1}, ` : ""}
                                        {/* {closeData?.address_2},{" "} */}
                                        {closeData?.address_2 ? `${closeData?.address_2}, ` : ""}
                                        
                                        {closeData
                                          ? `${closeData?.cityGet?.name}, `
                                          : ""}
                                        {closeData?.cityGet
                                          ? `${closeData?.cityGet?.states?.name}, `
                                          : ""}{" "}
                                        
                                        {closeData?.cityGet
                                          ? `${closeData?.cityGet?.countries?.name} `
                                          : ""}
                                          {closeData ? `- ${closeData?.zip_code}` : ""}
                                        
                                      </span>
                                    </div>
                                  </div>
                                  <div className="catD-lt-item">
                                    <div className="icon">
                                      <img
                                        src={images.calendarBigIcon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="it-content">
                                      <span className="itl">
                                        {" "}
                                        Service Date and Time{" "}
                                      </span>
                                      <span className="itv">
                                        {dateWithFormate(
                                          closeData?.service_date
                                        )}
                                        &nbsp; (
                                        {dateWithFormate(
                                          closeData?.timeGet?.start_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}{" "}
                                        -{" "}
                                        {dateWithFormate(
                                          closeData?.timeGet?.end_time,
                                          "hA",
                                          "HH:mm:ss"
                                        )}
                                        )
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="ardCatD-it-action text-right">
                                  {closeData.status
                                    ? renderButtons(closeData)
                                    : null}
                                </div>
                              </div>
                              <div className="ardCatD-right-space">
                                <div className="ardCatD-content">
                                  <h3> Request Title </h3>
                                  <p>{closeData ? closeData?.title : null}</p>
                                </div>

                                <div className="ardCatD-content">
                                  <h3> Request Description </h3>
                                  <p>
                                    {closeData ? closeData?.description : null}
                                  </p>
                                </div>
                                <div className="ardCatD-point-list">
                                  {additionalDetails(closeData)}
                                </div>

                                
                                <div className="ardCatD-media-block">
                                  <h3> Media </h3>
                                  <div className="catD-media-list">
                                    {closeData &&
                                      closeData.files &&
                                      closeData.files.map((item, index) => (
                                        <div
                                          className="catDM-item"
                                          key={item.file + index}
                                          style={{cursor:"pointer"}}
                                        >
                                          <img
                                            // src={config.image_path + item?.file}
                                            src={
                                              config.endpoints.image_path +
                                              item?.file
                                            }
                                            className="img-fluid"
                                            alt=""
                                            onClick={()=>window.open(config.endpoints.image_path +
                                              item?.file)}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                {closeData?.sr_status === 2 ? (
                                  // <div className="ardCatD-inner-body">
                                    <div className="ardCatD-location-time">
                                      <div className="ardCatD-lt-inner">
                                        <div className="catD-lt-item">
                                          <div className="it-content">
                                            <span className="itl">
                                              {" "}
                                              Cancellation Reason{" "}
                                            </span>
                                            <span className="itv">
                                              {closeData?.cancelReason?.name ||
                                                "--"}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="catD-lt-item">
                                          <div className="it-content">
                                            <span className="itl">
                                              {" "}
                                              Cancellation Details
                                            </span>
                                            <span className="itv">
                                              {closeData?.sr_cancellation_comments ||
                                                "--"}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  // </div>
                                ) : null}
 {closeData?.sr_status === 15 ? (
   <>
<div class="ardCatD-content">
															<h3> Disabled Reason </h3>
															<p> {closeData?.disable_comments || ""}  </p>
														</div>

														<div class="ardCatD-content">
															<h3> Disabled Details </h3>
															<p>{closeData?.customerNoShow?.no_show_reason || ""}  </p>
														</div>
                            </>
                            ) : null}

{closeData?.sr_status === 12 ? (
<div class="request-dispute-content">
															<h2 class="rdc-title"> Dispute </h2>
															<div class="rdc-date">
																<div class="rdcd-left">
																	<div class="ardCatD-content">
																		<h3> Dispute Creation Date </h3>
																		{/* <p> Tue, November 24, 2020 </p> */}
                                    <p> {dateWithFormate(closeData && closeData.dispute && closeData.dispute.created_at.split(" ")[0].split("-").reverse().join("-"))} </p>
                                  </div>
																</div>
															</div>

															<div class="ardCatD-content">
																<h3> Dispute Reason </h3>
																{/* <p> Lorem ipsum dolor sit amet </p> */}
                                {/* <p>{closeData && closeData.dispute && closeData.dispute.dispute_reason}</p> */}
                                <p>
                         {disputeReason.length > 0 && (disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)] !== undefined || disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)] !== null) ? 
                           disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)].name || "--"
                         
                         :
                         null
                         }
                          
                         </p>
                              </div>

															<div class="ardCatD-content">
																<h3> Dispute Details </h3>
																{/* <p> Lorem ipsum dolor sit amet, consectetur enean suscipit massa eget dolor elementum dignissim facilisi Integer varius hendrerit nibh.  </p> */}
                                <p>{closeData && closeData.dispute && closeData.dispute.dispute_details}</p>
														
                              </div>
														</div>

                            ) : null}


{closeData?.sr_status === 14 ? (
<div class="request-dispute-content">
															<h2 class="rdc-title"> Dispute </h2>
															<div class="rdc-date">
																<div class="rdcd-left">
																	<div class="ardCatD-content">
																		<h3> Dispute Creation Date </h3>
																		<p> {
                                    closeData && closeData.dispute && closeData.dispute.created_at ?
                                    dateWithFormate(closeData && closeData.dispute && closeData.dispute.created_at.split(" ")[0].split("-").reverse().join("-"))
                                  :
                                  ""
                                  } </p>
																	</div>
																</div>
																<div class="rdcd-right">
																	<div class="ardCatD-content">
																		<h3> Dispute Closure Date </h3>
																		<p> {dateWithFormate(closeData && closeData.dispute && closeData.dispute.dispute_closure_date.split("T")[0].split("-").reverse().join("-"))} </p>
																	</div>
																</div>
															</div>

															<div class="ardCatD-content">
																<h3> Dispute Reason </h3>
																<p> 
                                {disputeReason.length > 0 && (disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)] !== undefined || disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)] !== null) ? 
                           disputeReason[(parseInt(closeData && closeData.dispute && closeData.dispute.dispute_reason) - 1)].name || "--"
                         
                         :
                         null
                         }    
                               </p>
															</div>

															<div class="ardCatD-content">
																<h3> Dispute Details </h3>
																<p> {(closeData && closeData.dispute && closeData.dispute.dispute_details) || ""}  </p>
															</div>

															<div class="ardCatD-content">
																<h3> Dispute Closure Comments </h3>
																<p> {(closeData && closeData.dispute && closeData.dispute.closure_comments) || ""} </p>
															</div>
														</div>
                            ) : null}


{closeData?.sr_status === 13 || closeData?.sr_status === 11 ? (
  <div class="warrant-req-content">
															<h2 class="rdc-title"> Warranty </h2>
															<div class="table-responsive">
																<table class="wrc-table table">
																	<thead class="wrc-head">
																		<tr class="wrc-row">
																			<th class="wrc-data"> Creation Date  </th>
																			<th class="wrc-data"> Closure Date  </th>
																			<th class="wrc-data"> Details  </th>
																			<th class="wrc-data"> Closure Comments  </th>
																		</tr>
																	</thead>

																	<tbody class="wrc-body">
                                  {closeData && closeData.warranty && closeData.warranty.slice(0).reverse().map((item, index)=>(
                                      
                                      <tr className="wrc-row" key={index}>
                                      <td className="wrc-data"> {(item.created_at && dateWithFormate(item.created_at.split(" ")[0].split("-").reverse().join("-"))) || ""}   </td>
                                      <td class="wrc-data">{(item.warranty_closure_date && dateWithFormate(item.warranty_closure_date.split("T")[0].split("-").reverse().join("-"))) || ""} </td>
																			<td className="wrc-data"> {item.details || ""} </td>
                                      <td class="wrc-data"> {item?.closure_comments || ""} </td>
																		
                                    </tr>	
                                      ))}
																	
																	</tbody>
																</table>
															</div>
														</div>
                            ) : null}
                             {closeData && closeData?.srstatus && closeData?.srstatus.id === 9 ?
                           <>
                            <div className="ardCatD-content">
                              <h3> Quote Price </h3>
                              <p>$ {closeData && closeData?.paymentCalculation && closeData?.paymentCalculation?.cust_total_quote_amount  ? (closeData?.paymentCalculation?.cust_total_quote_amount ) : "0"}</p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Labour Amount </h3>
                              <p>
                                {/* {closeData && closeData?.quote && closeData?.quote[0] && closeData?.quote[0] ? (closeData?.quote[0].additional_labor_amount) : "--"} */}
                                $ {closeData && closeData?.quote ? getConfirmedQuote(closeData?.quote).additional_labor_amount : ""}
                              </p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Material Amount </h3>
                              <p>
                                {/* {closeData && closeData?.quote && closeData?.quote[0] && closeData?.quote[0] ? (closeData?.quote[0].additional_material_amount) : "--"} */}
                                $ {closeData && closeData?.quote ? getConfirmedQuote(closeData?.quote).additional_material_amount : ""}
                              </p>
                            </div>
                            <div className="ardCatD-content">
                              <h3> Additional Amount Comments </h3>
                              <p>
                                {/* {closeData && closeData?.quote && closeData?.quote[0] && closeData?.quote[0] ? (closeData?.quote[0].additional_amount_comments) : "--"} */}
                                {closeData && closeData?.quote ? getConfirmedQuote(closeData?.quote).additional_amount_comments : ""}
                              </p>
                            </div>
                           </>
                            :
                            null
                          }

{[6, 7, 8, 9, 11, 12, 13, 14].includes(
                                  closeData.sr_status
                                  ) ? 
                                  <div className="ardCatD-point-list">
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span> Submitted Date </span>
                                    <span> {closeData?.submitted_date ? (
                                      <>
                                      {dateWithFormate(closeData?.submitted_date.split("T")[0].split("-").reverse().join("-"))}
                                      <br /> 
                                      {dateWithFormate(
                                        closeData?.submitted_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Completion Date </span>
                                    {/* <span> {closeData?.completed_date || "--"} </span> */}
                                    <span> {closeData?.completed_date ? (
                                      <>
                                      {dateWithFormate(closeData?.completed_date.split("T")[0].split("-").reverse().join("-"))} 
                                      <br /> 
                                      {dateWithFormate(
                                        closeData?.completed_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Closed Date </span>
                                    {/* <span> {closeData?.closed_date || "--"} </span> */}
                                    <span> {closeData?.closed_date ? (
                                      <>
                                      {dateWithFormate(closeData?.closed_date.split("T")[0].split("-").reverse().join("-"))}
                                      <br /> 
                                      {dateWithFormate(
                                        closeData?.closed_date.split("T")[1].split(",")[0],
                                        "hh:mm:ss A",
                                        "HH:mm:ss"
                                      )}
                                      </>
                                      )
                                      : "--"} </span>
                                  </div>
                                  <div className="catPoint-item" style={{width:"50%"}}>
                                    <span>  Warranty Expiry Date </span>
                                    <span> 
                                      {/* {(closeData?.quote && closeData?.quote[0]?.warranty_expiry_date) || "--"}  */}
                                      {/* {closeData?.quote && closeData?.quote && closeData?.quote.map((item, index) =>{
                                        if(item.quote_status === 3){
                                          dateWithFormate(item?.quote?.warranty_expiry_date.split("T")[0].split("-").reverse().join("-")) &nbsp; 
                                          dateWithFormate(
                                            item?.quote?.warranty_expiry_date.split("T")[1].split(",")[0],
                                            "hA",
                                            "HH:mm:ss"
                                          )
                                        }
                                      })
                                    } */}

                                    {getExpiryDate(closeData)}
                                      
                                      
                                    
                                      </span>
                                  </div>
                                  </div>
                                  :
                                  null
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tab-pane" id="gh-servicePro-tab" role="tabpanel">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="blankService-tab-go">
                  <p>
                    {" "}
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>

                  <Link to="//register" className="btn btn-red">
                    {" "}
                    Signup as service provider{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Modals cancelRequest={cancelRequest} requestID={requestId} />
      <DisputeModal closeData={closeData} />
      <WarrantyModal closeData={closeData} />
    </>
  );
};

export default CustomerRequest;
