import React, { useState, useEffect } from "react";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import { APIService } from "../Components/Apis/APIServices";
import { Link, Redirect, useHistory } from "react-router-dom";
import { formatSizeUnits } from "../Utility/Utility";
import Auth from "../Auth/Auth";
import { dateWithFormate } from "../Utility/common";
import Dialogue from '../Components/Common/Dialogue';
import GetInTouch from "../Components/Common/GetInTouch";

export default function UpdateQoute(props) {
  const userData = Auth.getUserDetails();
  const requestData = props?.location?.state?.quoteData;
  const history = useHistory();
  // console.log("requestData",requestData)
  const [quoteState, setQuoteState] = useState(requestData);
  const [quoteUpdate, setQuoteUpdate] = useState({
    // quote_service_date: new Date(requestData?.quote_service_date),
    quote_service_date: (requestData?.quote_service_date.split("T") && requestData?.quote_service_date.split("T")[0]) || "",
    time_slot: requestData?.time_slot,
    labor_quote_amount: requestData?.labor_quote_amount,
    services_included: requestData?.services_included,
    material_included_flag: requestData?.material_included_flag,
    material_quote_amount: requestData?.material_quote_amount,
    additional_comments: requestData?.additional_comments,
    warranty_days: requestData?.warranty_days
  });

  const [loader, setLoader] = useState(false)

  // console.log("wwwwwww", requestData)

  const [uploadObj, setUploadObj] = useState({});
  const [slots, setSlots] = useState([]);
  const [bankAcs, setBankAcs] = useState([]);
  const [err, setErr] = useState({});

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // if (e.target.files) {
    //   uploadImg(e);
    // } else {
      // setQuoteState((prevState) => ({
      //   ...prevState,
      //   [name]: value,
      // }));
      setQuoteUpdate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    // }
  };

  // const uploadImg = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (e.target.files[0].size > 5242880) {
  //     alert("5 MB Max size of the file");
  //   } else {
  //     setUploadObj({
  //       name: e.target.files[0].name,
  //       size: e.target.files[0].size,
  //     });
  //     setQuoteState((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //     setQuoteUpdate((prevState) => ({
  //       ...prevState,
  //       images: e.target.files,
  //     }));
  //   }
  // };

  useEffect(() => {
    APIService.fetchAllTimeSlot()
      .then((res) => setSlots(res.data.data))
      .catch((err) => setErr(err));
  }, []);

  const handleRadio = (name, value) => {
    // setQuoteState((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    setQuoteUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    if (userData) {
      if (userData.role_type === 1) {
        // alert("Please login/register as service provider to update a quote.");
        setDialogueState({
          type: "Error",
          show: true,
          message: `Please login/register as service provider to update a quote.`,
        });
      } else {
        if (quoteState && quoteState.provider_id === userData.id) {
          if (
              parseFloat(quoteState.labor_quote_amount) >
              parseFloat(quoteUpdate.labor_quote_amount) ||
              (quoteUpdate.material_included_flag === 1 && (parseFloat(quoteState.material_quote_amount) >
              parseFloat(quoteUpdate.material_quote_amount)) )
          ) {
            setDialogueState({
              type: "Warning",
              show: true,
              message: `Unfortunately, you cannot lower the quote amount. Please contact support@ezunction.com.`,
            });
          } else {
            setLoader(true)
            APIService.updateQoute(quoteUpdate, quoteState.id)
              .then((res) => {
                // console.log("res---", res);
                if(res.status === 200){
                  setLoader(false)
                  setDialogueState({
                    type: "Success",
                    show: true,
                    message: `Your quote ${quoteState.id} for service request ${quoteState.request_id} has been successfully updated.`,
                    back: history.goBack,
                  });
              }else{
                setLoader(false)
              }
              })
              .catch((err) => {
                setLoader(false)
                setErr(err)
              });
          }
        } else {
          // alert("You can't update quote.");
          setDialogueState({
            type: "Error",
            show: true,
            message: `You can't update quote.`,
          });
        }
      }
    } else {
      setDialogueState({
        type : 'Warning',
        show:true,
        message : `Login First.`
      })
    }
  };

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
  })

  console.log(
    ".................../////////////////// req",
    quoteState,
    userData
  );

  return (
    <div>
      <Header />
      <section class="gray-hero-section banner-b-50">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="inner-caption wow fadeInUp text-center">
                <h1 class="mb-0">
                  {" "}
                  Update <span> Quote </span>{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="createMyRequest-section">
        <form onSubmit={(e)=>handleSubmit(e)}>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="cmr-form-block">
                  <div class="form-group group-title group-12 mb-0">
                    <h4> Available Service Date and Time </h4>
                  </div>

                  <div
                    className="form-group date1 group-6 group-xs-12 datepicker-calendar fg-place"
                    id="datepicker10"
                    data-target-input="nearest"
                  >
                    <div className="placeholder">
                      <label for=""> Date </label>
                      <span className="star">*</span>
                    </div>
                    <input
                      type="date"
                      onChange={handleChange}
                      name="quote_service_date"
                      value={new Date(quoteUpdate.quote_service_date)
                        .toISOString()
                        .substring(0, 10)}
                      className="holer-control form-control"
                      data-target="#datepicker10"
                      data-toggle="datetimepicker"
                    />
                  </div>

                  <div className="form-group group-6 group-xs-12">
                    <select
                      className="form-control"
                      value={quoteUpdate.time_slot}
                      name="time_slot"
                      onChange={handleChange}
                    >
                      {/* <option> Select Time <span style={{color: 'red'}}>*</span></option> */}
                      <option value="">Select Time *</option>
                      {slots.map((slot) => (
                        <option value={slot.id}>
                          {dateWithFormate(slot.start_time, "hA", "HH:mm:ss")} -{" "}
                          {dateWithFormate(slot.end_time, "hA", "HH:mm:ss")}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="form-group group-title group-12 mb-0">
                    <h4> Labor Quote Amount </h4>
                  </div>
                  <div class="form-group fg-place group-6 group-xs-12">
                    {/* <div
                      className={`placeholder ${
                        quoteUpdate.labor_quote_amount ? "d-none" : "d-block"
                      }`}
                    >
                      <label for="labor_quote_amount">
                        {" "}
                        Labor Quote Amount ${" "}
                      </label>
                      <span className="star text-danger">*</span>
                    </div> */}

                    <input
                      type="text"
                      class="holer-control form-control"
                      onChange={handleChange}
                      name="labor_quote_amount"
                      value={quoteUpdate.labor_quote_amount}
                      // placeholder="Labor Quote Amount $ *"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                      title="Please enter numeric value"
                      maxLength="7"
                        placeholder='$0.00'
                    />
                  </div>

                  <div class="form-group group-title group-12 mb-0">
                    <h4> Services Included </h4>
                  </div>

                  <div class="form-group group-12">
                    <div class="textarea-group">
                      <textarea
                        class="form-control resize-none"
                        placeholder="Provide a brief description of the services that you offer in this quote*"
                        rows="4"
                        onChange={handleChange}
                        value={quoteUpdate.services_included}
                        name="services_included"
                        maxLength="300"
                      ></textarea>
                      <span class="textarea-limit"> {300 - quoteUpdate.services_included.length} Characters Limit </span>
                
                    </div>
                    <span class="textNote">
                      {" "}
                      “Please do not share your personal/contact details such as
                      email id, contact number, address etc.”{" "}
                    </span>
                  </div>

                  <div class="form-group group-12">
                      <label> Warranty </label>
                      <div className="group-6 group-xs-12">
                        <div>
                        <select
                        className="form-control"
                        value={quoteUpdate.warranty_days}
                        name="warranty_days"
                        onChange={handleChange}
                        required
                      >
                        {/* <option> Select Time <span style={{color: 'red'}}>*</span></option> */}
                        <option value="" disabled>Select Warranty Days *</option>
                        
                          <option value="0">
                           0
                          </option>
                          <option value="30">
                           30
                          </option>
                          <option value="45">
                           45
                          </option>
                          <option value="60">
                           60
                          </option>
                          <option value="90">
                           90
                          </option>
                       
                      </select>
                        </div>
                      </div>
                    </div>


                  <div class="form-group group-12">
                    <label> Material Included </label>
                    <div class="radio-block">
                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="material_included_flag"
                          checked={
                            quoteUpdate.material_included_flag === 1
                              ? true
                              : false
                          }
                          id="ppc-yes"
                        />
                        <label
                          className="radio-label-control"
                          onClick={() =>
                            handleRadio("material_included_flag", 1)
                          }
                          for="ppc-yes"
                        >
                          {" "}
                          Yes{" "}
                        </label>
                      </div>

                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="material_included_flag"
                          checked={
                            quoteUpdate.material_included_flag === 0
                              ? true
                              : false
                          }
                          id="ppc-yes"
                        />
                        <label
                          className="radio-label-control"
                          onClick={() =>{
                            handleRadio("material_included_flag", 0);
                            setQuoteUpdate((prev) => {
                              return {
                                ...prev,
                                material_quote_amount: 0,
                              };
                            });
                          }
                        }
                          for="ppc-yes"
                        >
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group group-title group-12 mb-0">
                    <h4> Material Quote Amount</h4>
                  </div>
                  <div class="form-group fg-place group-6 group-xs-12">
                    {/* <div
                      className={`placeholder ${
                        quoteUpdate.material_quote_amount ? "d-none" : "d-block"
                      }`}
                    >
                      <label for="material_quote_amount">
                        {" "}
                        Material Quote Amount ${" "}
                      </label>
                      <span className="star text-danger">*</span>
                    </div> */}
                    <input
                      type="text"
                      class="holer-control form-control"
                      onChange={handleChange}
                      name="material_quote_amount"
                      value={quoteUpdate.material_quote_amount}
                      // placeholder="Material Quote Amount $ *"
                      disabled={quoteUpdate.material_included_flag === 0}
                      // placeholder="Material Quote Amount $"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                      title="Please enter numeric value"
                      maxLength="7"
                      placeholder='$0.00'
                      />
                  </div>

                  <div class="form-group group-title group-12 mb-0">
                    <h4> Additional Comments </h4>
                  </div>
                  <div class="form-group group-12">
                    <div class="textarea-group">
                      <textarea
                        class="form-control resize-none"
                        placeholder="Provide additional comments, if any"
                        rows="4"
                        onChange={handleChange}
                        name="additional_comments"
                        value={quoteUpdate.additional_comments}
                        maxLength="100"
                      ></textarea>
                       <span class="textarea-limit"> {100 - (quoteUpdate.additional_comments?.length || 0)} Characters Limit </span>
                
                    </div>
                  </div>
                  {/* <div class="cus-fileUpload-box form-group group-12">
                  <div class="cus-fileUpload-group">
                    <input
                      type="file"
                      class="cus-fileUpload-control d-none"
                      id="driverDL-input"
                      onChange={handleChange}
                      name="images"
                      multiple
                    />
                    <label
                      for="driverDL-input"
                      class="mb-0 cus-fileUpload-label"
                    >
                      <div class="profileUp-inner">
                        <div class="upload-here">
                          <img
                            src="../images/image-icon.svg"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="upload-content">
                          <h5> Upload Photos/Videos </h5>
                          <span>
                            {" "}
                            {uploadObj && uploadObj.name
                              ? uploadObj.name
                              : "No file selected yet!"}{" "}
                          </span>
                          <span>
                            {uploadObj && uploadObj.size
                              ? formatSizeUnits(uploadObj.size)
                              : 0}{" "}
                            - 5 MB Max Size{" "}
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div> */}

                  <div class="form-submit text-center group-12">
                    <button type="button" class="btn btn-md btn-black mr-3" onClick={()=>history.goBack()}>
                      Cancel
                    </button>
                    <button
                      class="btn btn-md btn-red"
                      type="submit"
                      disabled={loader}
                      // onClick={handleSubmit}
                    >
                      {" "}
                      {loader ? "Processing ..." : "Confirm" }{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      {/* <section class="getInTouch-section">
        <div class="container">
          <div class="col-12">
            <div class="getInTouch-box">
              <div class="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div class="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i class="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i class="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i class="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i class="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i class="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i class="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Footer />
    </div>
  );
}
