
import React,{ useState,useEffect } from 'react';
import { APIService } from "../Components/Apis/APIServices";
import { useHistory, Link, useParams } from "react-router-dom";
import Auth from '../Auth/Auth';

import Header from "../Components/Common/Header";
import MyAccountMenu from "../Components/Common/MyAccountMenu";
import images from "../Utility/Images";
import Dialogue from '../Components/Common/Dialogue';
import Footer from "../Components/Common/Footer";

import Plaid from '../Components/Common/Plaid'
import GetInTouch from '../Components/Common/GetInTouch';

const MarkCompletion = (props) => {

	const userDetail = Auth.getUserDetails();
	const { id : requestId } = useParams();
  const requestData = {
    user_id: props?.location?.state?.pageData,
    formData: props?.location?.state?.formData,
  };
	const [formData, setFormData] = useState({
    polite: false,
    responsive: false,
    availability: false,
    clarity_work: false,
    rating: 0,
    services_included: "",
    additional_amount_comments: "",
    feedback_comments: "",

    // form pre data
    labor_quote_amount: requestData?.formData?.labor_quote_amount || "",
    services_included: requestData?.formData?.services_included || "",
    material_included_flag: requestData?.formData?.material_included_flag || 0,
    material_quote_amount: requestData?.formData?.material_quote_amount || "",
    additional_labor_amount:
      requestData?.formData?.additional_labor_amount || "",
    additional_material_amount:
      requestData?.formData?.additional_material_amount || "",
    additional_amount_comments:
      requestData?.formData?.additional_amount_comments || "",
    payout_method_id: requestData?.formData?.payout_method_id || "",
    feedback_comments: requestData?.formData?.feedback_comments || "",
  });
	const history = useHistory();
	const ratingArr = [1,2,3,4,5]
	const [rating,setRating] = useState(3)
	const [bankAcs,setBankAcs] = useState([])
  const [err,setErr] = useState({})

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
  })

  ////////////////////////////   plaid object
  const [token, setToken] = useState(null);
  const [bankToken, setBankToken] = useState("")
  const [plaidShow, setPlaidShow] = useState(false)
/////////////////////////////////////////


const [loader, setLoader] = useState(false)

	const handleChange = (ele) => {
		let value = ele.target.value;
    // console.log("hjkjsadasd", value, ele.target.checked, ele.target.name )
  
		// if(ele.target.checked){
		// 	setFormData( prev => {
		// 		return { ...prev, 
    //       // [ele.target.name]: value === "true" || value === true ? false : true
    //       [ele.target.name]:  ele.target.checked === false ? false : true
    //     }
		// 	});
		// }
		// else{
			setFormData( prev => {
				return { ...prev, [ele.target.name]:value}
			});	
		// }

		
	}

  const handleCheckValue = (ele)=>{
    // let value = ele.target.value;
    // console.log("hjkjsadasd", value, ele.target.checked, ele.target.name )
    	setFormData( prev => {
				return { ...prev, 
          // [ele.target.name]: value === "true" || value === true ? false : true
          [ele.target.name]:  ele.target.checked 
        }
			});
  }

	const [bankState,setBankState] = useState({
		"bank_name": "",
		"account_name": "",
		"account_number": "",
		"routing_number": "",
		"account_type": 1,
		"account_nick": "",
		"isprimary": 0
	})
	const handleBankChange = ({target:{value,name}})=>{
		console.log(value,name)
		setBankState(prevState=>({
			...prevState,
			[name]:value
		}))
	}

	const handleBankRadio = (name,value)=>{
		setBankState(prevState=>({
			...prevState,
			[name]:value
		}))
	}

	const handleBankSubmit = ()=>{

    // let filterNickList = bankAcs.filter( (data) => data.account_nick === bankState.account_nick);
    // let filterList = bankAcs.filter( (data) => data.isprimary === 1);

    // if (filterNickList.length) {
    //   alert("Account nick name should be unique.")
    // } else if (bankState.isprimary === 1 && filterList.length) {
    //   alert("Can't have multiple primary bank account.");
    // } else {
		
		// APIService.addBankAccount(bankState).then(res=>{
		// 	APIService.fetchBankAccount().then(res=>{
		// 		setBankAcs(res.data.data)

    //     setBankState({
    //       "bank_name": "",
    //       "account_name": "",
    //       "account_number": "",
    //       "routing_number": "",
    //       "account_type": 1,
    //       "account_nick": "",
    //       "isprimary": 0
    //     })
		// 		// window.location.href = '/mark-completion/'+requestId
		// 		//<Redirect to="quotes-submit"/>
		// 		//window.$('#addNewBank-modal').model('hide')
    //     document.getElementById("addNewBank-modal").click()
		// 	}).catch(err=>setErr(err))
		// }).catch(err=>setErr(err))
	// }

  setBankAcs((prev)=>{
    return([...prev, {
      account_name: bankState.account_name,
      account_number: bankState.account_number,
      routing_number: bankState.routing_number,
      account_nick: bankState.account_nick,
      account_type: 2,
      isprimary: bankState.isprimary,
      bank_name: bankState.bank_name,
    }])
  })

      // setBankAccountShow(false);
    document.getElementById("addNewBank-modal").click()
        setBankState({
      bank_name: "",
      account_name: "",
      account_number: "",
      routing_number: "",
      account_type: 2,
      account_nick: "",
      isprimary: 0,
    });

	}
	const handleSubmit = (e) => {
		e.preventDefault();

		if (requestId && userDetail.id ) {
			if(userDetail.role_type === 2 || userDetail.role_type === 3) {
        setLoader(true)
				APIService.addMarkCompletion({
					...formData, 
					"request_id" : requestId, 
					"provider_id": userDetail.id,
					"user_id":requestData.user_id,
					"rating" : rating,

          polite: formData ? 1 : 0,
    responsive: formData ? 1 : 0,
    availability: formData ? 1 : 0,
    clarity_work: formData ? 1 : 0,

          // account_holder_name: (bankAcs.filter((i)=> i.account_number === formData.account_number)[0] && bankAcs.filter((i)=> i.account_number === formData.account_number)[0].account_name) || "",
          //   routing_number: (bankAcs.filter((i)=> i.account_number === formData.account_number)[0] && bankAcs.filter((i)=> i.account_number === formData.account_number)[0].routing_number) || "",
          //   account_number: (bankAcs.filter((i)=> i.account_number === formData.account_number)[0] && bankAcs.filter((i)=> i.account_number === formData.account_number)[0].account_number) || "",
          //   payment_id: (bankAcs.filter((i)=> i.account_number === formData.account_number)[0] && bankAcs.filter((i)=> i.account_number === formData.account_number)[0].id) || "",
          payment_id: formData.account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? "" : 1,
          bank_token: formData.account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? formData.account_number : ""
          
				})
				.then((res) => {
          setLoader(false)
					if (res.status === 200){
            
            setDialogueState({
							type : 'Success',
							show:true,
							message : `You have successfully marked the service request ${requestId} as complete. Please request the customer to accept completion for the payments to be released.`,
							back : history.goBack
						  })
          }else{
            setDialogueState({
              type : 'Error',
              show:true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
          } 
				})
				.catch((e) => {
          setLoader(false)
          setDialogueState({
          type : 'Error',
          show:true,
          message : (e.response && e.response.data && e.response.data.message) || ""
          })});
			}else 
      setDialogueState({
				type : 'Warning',
				show:true,
				message :`Can't mark completion. Please login as a Service Provider.`
			  }) 
		}else 
    setDialogueState({
			type : 'Warning',
			show:true,
			message :`Please login first.`
		  })

	}

	useEffect(()=>{
    APIService.fetchBankAccount()
    .then(res=>{
      const bankToken = JSON.parse(localStorage.getItem("bankToken"))

      if(bankToken){

        
        setBankAcs((prev)=>{
          return [...res.data.data, bankToken]
        })
      }else{
        setBankAcs(res.data.data)
      }
      

    })
    .catch(err=>setErr(err))      
  }, [])

  	const handleRadio = (name, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        "material_quote_amount" : value ? prevState.material_quote_amount : 0
      }));
    };


    //////////////////////////////////
    const createLinkToken = () => {
     
      APIService.tokenLink()
      .then((res)=>{
        if(res.status === 200){
          // console.log("token is ",res)
          setToken(res.data.token)
          localStorage.setItem("markState", JSON.stringify({...formData, request_id: requestId, rating}))
          
        }
      })
      .catch((err) =>{})
  
    };

    useEffect(()=>{
      const markState = JSON.parse(localStorage.getItem("markState"))

    
      
      if(markState && markState.request_id === requestId){
        setFormData(markState)
        setRating(markState.rating)
      }
        
    },[])

    useEffect(()=>{
      return() =>{
        localStorage.removeItem("markState")
        localStorage.removeItem("bankToken")
      }
      
  
      
    },[])
  
    useEffect(()=>{
      if(token)
        setPlaidShow(true)
    },[token])
  
  // console.log("hjkjsadasd", formData.polite )

  const [tick, setTick] = useState(0);
  const handleTick = (val) => {
    setTick(val);
  };
  
	return (
    <>
    {plaidShow ? <Plaid linkToken={token} 
        sendBankToken={(token)=> {
          // console.log("aasdkasdkasdjasndja",token)
          setToken("")
          setBankToken(token)
          }}
          onCancel = {()=>{
            setPlaidShow(false)
            setToken("")
            // document.getElementsByTagName("body")[0].style.backgroundColor = "red";
            // document.getElementsByTagName("body")[0].style.overflow = "scroll";
            // document.getElementsByTagName("body")[0].style.overflowX = "scroll";
            // document.getElementsByTagName("body")[0].style.overflowY = "scroll";
          }}
          /> : null}
      <Header />
      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  Mark <span> Completion </span>{" "}
                </h1>
                <div className="req-text-gh">
                  {" "}
                  Request ID :{" "}
                  <Link
                    // to="/my-request"
                    to={{
                      pathname: "/my-request",
                      state: {
                        requestId: requestId,
                      },
                    }}
                    className="orange-text"
                  >
                    {requestId || ""}
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>
      <section className="createMyRequest-section">
        <div className="container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-12">
                <div className="cmr-form-block mt-0">
                  <div className="form-group group-title group-12 mb-0">
                    <h4> Labor Quote Amount </h4>
                  </div>
                  <div className="form-group fg-place group-6 group-xs-12">
                    <div className="placeholder">
                      <label for=""> Labor Quote Amount $ </label>
                      <span className="star">*</span>
                    </div>
                    <input
                      type="number"
                      className="holer-control form-control"
                      readonly
                      onChange={handleChange}
                      name="labor_quote_amount"
                      placeholder="Enter Amount"
                      value={formData?.labor_quote_amount}
                      disabled
                    />
                  </div>

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Services Included </h4>
                  </div>

                  <div className="form-group group-12">
                    <div className="textarea-group">
                      <textarea
                        className="form-control resize-none"
                        placeholder="Provide a brief description of the services that you offer in this quote*"
                        rows="4"
                        readonly
                        onChange={handleChange}
                        name="services_included"
                        value={formData?.services_included}
                        maxLength={500}
                        disabled
                      ></textarea>
                      <span class="textarea-limit">
                        {" "}
                        {500 - formData?.services_included?.length} Characters
                        Limit{" "}
                      </span>
                    </div>
                    <span className="textNote">
                      {" "}
                      “Please do not share your personal/contact details such as
                      email id, contact number, address etc.”{" "}
                    </span>
                  </div>

                  {/* <div className="form-group group-12">
                    <label> Material Included </label>
                    <div className="radio-block">
                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="materialIncluded"
                          id="mi-yes"
                          onChange={handleChange}
                          value={formData?.material_included}
                          name="material_included"
                        />
                        <label className="radio-label-control" for="mi-yes">
                          {" "}
                          Yes{" "}
                        </label>
                      </div>

                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="materialIncluded"
                          id="mi-no"
                          name="material_included"
                          value={formData?.material_included}
                          onChange={handleChange}
                        />
                        <label className="radio-label-control" for="mi-no">
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                  </div> */}

                  <div class="form-group group-12">
                    <label> Material Included </label>
                    <div class="radio-block">
                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="material_included_flag"
                          checked={
                            formData?.material_included_flag === 1
                              ? true
                              : false
                          }
                          id="ppc-yes"
                          // readOnly
                        />
                        <label
                          className="radio-label-control"
                          // onClick={() =>
                          //   handleRadio("material_included_flag", 1)
                          // }
                          for="ppc-yes"
                        >
                          {" "}
                          Yes{" "}
                        </label>
                      </div>

                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="material_included_flag"
                          checked={
                            formData?.material_included_flag === 0
                              ? true
                              : false
                          }
                          id="ppc-no"
                          // readOnly
                        />
                        <label
                          className="radio-label-control"
                          // onClick={() =>
                          //   handleRadio("material_included_flag", 0)
                          // }
                          for="ppc-no"
                        >
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group fg-place group-6 group-xs-12">
                    <label for="material_quote_amount">
                      {" "}
                      Material Quote Amount ${" "}
                    </label>
                    <div className="placeholder">
                      <span className="star">*</span>
                    </div>
                    <input
                      type="number"
                      className="holer-control form-control"
                      readonly
                      onChange={handleChange}
                      name="material_quote_amount"
                      id="material_quote_amount"
                      placeholder="Enter Amount"
                      value={formData?.material_quote_amount || ""}
                      disabled
                    />
                  </div>

                  <div className="d-md-flex group-12 mb-md-3">
                    <div className="form-group group-title group-6 group-xs-12 mb-md-0">
                      <h4> Additional Labor Amount </h4>
                      <div className="fg-place">
                        <div className="placeholder">
                          <label> Additional Labor Amount $ </label>
                        </div>
                        <input
                          type="text"
                          className="holer-control form-control"
                          onChange={handleChange}
                          name="additional_labor_amount"
                          // placeholder="Enter Amount"
                          value={formData?.additional_labor_amount || ""}
                          // pattern="[+-]?([0-9]*[.])?[0-9]+"
                          pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                        title="Please enter numeric value greater than or equal to $0.00"
                        maxLength="7"
                        placeholder='$0.00'
                        
                        />
                      </div>
                    </div>

                    <div className="form-group group-title group-6 group-xs-12 mb-md-0">
                      <h4> Additional Material Amount </h4>
                      <div className="fg-place">
                        <div className="placeholder">
                          <label> Additional Material Amount $ </label>
                        </div>
                        <input
                          type="text"
                          className="holer-control form-control"
                          onChange={handleChange}
                          name="additional_material_amount"
                          // placeholder="Enter Amount"
                          value={formData?.additional_material_amount || ""}
                          // pattern="[+-]?([0-9]*[.])?[0-9]+"
                          pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                        title="Please enter numeric value greater than or equal to $0.00"
                        maxLength="7"
                        placeholder='$0.00'
                        
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group group-12">
                    <label> Additonal Comments </label>
                    <div className="textarea-group">
                      <textarea
                        className="form-control resize-none"
                        placeholder="Provide comments explaining the additional amount"
                        rows="4"
                        onChange={handleChange}
                        name="additional_amount_comments"
                        maxLength={200}
                        value={formData?.additional_amount_comments || ""}
                      ></textarea>
                      <span class="textarea-limit">
                        {" "}
                        {200 -
                          formData?.additional_amount_comments?.length}{" "}
                        Characters Limit{" "}
                      </span>
                    </div>
                  </div>

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Payout Method <small><i>( *Bank account MUST be a checking account. )</i></small></h4>
                    
                  </div>

                  <div class="otherAddress-option">
                    <div class="form-group fg-place group-6 group-xs-12">
                      <select
                        class="form-control"
                        name="account_number"
                        required
                        onChange={handleChange}
                        value={formData?.account_number || ""}
                      >
                        <option value="" disabled>
                          {" "}
                          Select Checking Bank Account *{" "}
                        </option>
                        {bankAcs.map((ba) => (
                          <option value={ba.id}> {ba.account_number} </option>
                        ))}
                      </select>
                    </div>

                    <div class="form-group group-6 group-xs-12">
                      <div class="addNewAddress-request">
                        <button
                          type="button"
                          className="btn text-btn d-flex justify-content-center w-100"
                            // data-toggle="modal"
                          // data-target="#addNewBank-modal"
                          onClick={() => createLinkToken()}
                        >
                          {" "}
                          <i class="fal fa-plus-circle"></i> Add New Bank
                          Account{" "}
                        </button>
                        <i 
                          className="w-100 d-flex justify-content-center"
                          style={{
                            fontSize: "16px",
                            // paddingLeft: "35px",
                            // margin: "0",
                            color: "#9BA4B4",
                            textAlign: "left"
                          }}>* New bank will be your default bank and will be used <br className="d-none d-md-block"/> for this and all your other open service request(s).</i>
                          </div>
                    </div>
                  </div>


{/* <div className="form-group form-checkbox">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                        />
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                      </div>
                    </div> */}

<div className='d-flex mt-5 mb-5'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                        </div>

                  <div className="form-group group-title group-6 group-md-12 mb-0">
                    <h4> Rate Customer </h4>

                    <div className="rsp-star-block">
                      <div className="star-box">
                        {ratingArr.map((rate) => {
                          if (rate <= rating) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate)}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate)}
                              ></i>
                            );
                          }
                        })}
                      </div>

                      <span className="orsp-text"> {rating}/5 </span>
                    </div>
                  </div>

                  <div className="form-group acc-group-list w-100 mc-rate-sp-group">
                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="polite"
                          // value={0}
                          // checked={formData.polite ? 1 : 0 }
                          checked={formData.polite}
                          onChange={handleCheckValue}
                          name="polite"
                        />
                        <label className="custom-check-label" for="polite">
                          {" "}
                          Polite{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="responsive"
                          // value={0}
                          checked={formData.responsive}
                          onChange={handleCheckValue}
                          name="responsive"
                        />
                        <label className="custom-check-label" for="responsive">
                          {" "}
                          Responsive{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="availability"
                          // value={0}
                          checked={formData.availability}
                          name="availability"
                          onChange={handleCheckValue}
                        />
                        <label
                          className="custom-check-label"
                          for="availability"
                        >
                          {" "}
                          Availability{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="clarity_work"
                          // value={0}
                          checked={formData.clarity_work}
                          name="clarity_work"
                          onChange={handleCheckValue}
                        />
                        <label
                          className="custom-check-label"
                          for="clarity_work"
                        >
                          {" "}
                          Clarity of Work{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group group-12">
                    <div className="textarea-group">
                      <textarea
                        className="form-control resize-none"
                        placeholder="Enter feedback comments"
                        rows="4"
                        onChange={handleChange}
                        name="feedback_comments"
                        maxLength={100}
                        value={formData?.feedback_comments || ""}
                      ></textarea>
                      <span class="textarea-limit">
                        {" "}
                        {100 - formData?.feedback_comments?.length} Characters
                        Limit{" "}
                      </span>
                    </div>
                  </div>

                  <div className="form-submit text-center group-12">
                    <button type="submit" className="btn btn-md btn-red" disabled={tick === 0 || loader}>
                      {" "}
                      {loader ? "Processing ..." : "Submit" }{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* <section class="getInTouch-section">
          <div class="container">
            <div class="col-12">
              <div class="getInTouch-box">
                <div class="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div class="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i class="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i class="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i class="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i class="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i class="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />

        <Footer />
        <div class="scale_cus_modal addnewaddress-wrap" id="addNewBank-modal">
          <div class="scale_modal_body scale_modal_lg">
            <div class="modal_inner">
              {/* <!-- <div class="scale_modal-close">
	        		<button type="button" class="slide-close" data-dismiss="modal" aria-label="Close"> <i class="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
              <div class="scm_header">
                <h2 class="scm_head-title"> Add Bank Account </h2>
              </div>
              <div class="scm_body">
                <div>
                  <div class="row">
                    

                    <div class="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_name ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Name on the Account </label>
                        <span class="star">*</span>
                      </div>
                      <input
                        type="text"
                        class="holer-control form-control"
                        value={bankState.account_name}
                        name="account_name"
                        onChange={handleBankChange}
                      />
                    </div>

                    <div class="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Account Number </label>
                        <span class="star">*</span>
                      </div>
                      <input
                        type="text"
                        class="holer-control form-control"
                        value={bankState.account_number}
                        name="account_number"
                        onChange={handleBankChange}
                      />
                    </div>
                    <div class="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.routing_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Routing Number </label>
                        <span class="star">*</span>
                      </div>
                      <input
                        type="text"
                        class="holer-control form-control"
                        value={bankState.routing_number}
                        name="routing_number"
                        onChange={handleBankChange}
                      />
                    </div>


              
                  </div>
                  {/* <div class="scm_footer justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-red btn-md"
                      onClick={handleBankSubmit}
                    >
                      {" "}
                      Submit{" "}
                    </button>
                  </div> */}
                  <i className='text-danger'>Your bank account must be a checking account.</i>{" "}
                    
                    <div class="form-group group-12 primary-radio-group mt-1">
                            
                        <h6 style={{textAlign:"center"}}> This bank will now be your default bank and will be used for this and your all other open service request(s). Do you wish to continue?
                     </h6>
                    </div>
                    <div class="scm_footer justify-content-center">
                    <button
                        type="button"
                        class="btn btn-black btn-md mr-3"
                       onClick={()=>{
                        // setBankAccountShow(false);
                        document.getElementById("addNewBank-modal").click()
                       }}
                        id="btnCancel"
                        // data-dismiss="modal"
                      >
                        {" "}
                        No{" "}
                      </button>
                      <button
                        type="button"
                        class="btn btn-red btn-md"
                        disabled={
                          // !bankState.bank_name ||
                          !bankState.account_name ||
                          !bankState.account_number ||
                          !bankState.routing_number 
                          // !bankState.account_nick
                        }
                        id="btnSave"
                        onClick={handleBankSubmit}
                        
                        // data-dismiss="modal"
                      >
                        {" "}
                        Yes{" "}
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialogue state={dialogueState} setState={setDialogueState} />
      </section>
    </>
  );
}


export default MarkCompletion;