import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PeopleVoiceSlider from './PeopleVoiceSlider';

const PeopleVoiceSection = () => {

    const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")
    const [animated2Name, setAnimated2Name] = useState("none")

	useEffect(()=>{
		var target = document.querySelector('.people-voice-section');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target.getBoundingClientRect().bottom) {
			// console.log('I have been reached');
			setHideOrShow("visible")
			setAnimatedName("fadeInUP")
            setAnimated2Name("zoomIn")
		  }
		})
	},[])

    return (
        <>
            <section className="people-voice-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center wow fadeInUP animated hinge" style={{visibility: hideOrShow, animationName: animatedName}}>
                            <div className="section-title">
                                <h2> People's <span className="text-orange font-weight-bold"> Voice </span></h2>
                            </div>
                            <div className="section-des">
                                <p>eZunction platform is trending across the user community! Our <br className="d-none d-lg-block" /> highly satisfied and delighted users are sharing their experiences. </p>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="people-voice-slider wow zoomIn animated hinge" style={{visibility: hideOrShow, animationName: animated2Name}}>
                                <PeopleVoiceSlider />
                            </div>

                            <div className="happening-more text-center">
                                <Link to="/peoples-voice" className="btn btn-red btn-sm"> See All </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PeopleVoiceSection;
