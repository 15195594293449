import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import images from '../../Utility/Images';

import { APIService } from "../../Components/Apis/APIServices";
import config from "../../Components/Apis/config";

// import VideoPlayer from 'react-video-js-player';


const AdvertiseSection = () => {
    const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")
    const [ advBanner, setAdvBanner] = useState([])

	useEffect(()=>{
		var target = document.querySelector('.adv-gallery-section');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target.getBoundingClientRect().top) {
			// console.log('I have been reached');
			setHideOrShow("visible")
			setAnimatedName("zoomIn")
		  }
		})

        APIService.advBanner()
        .then((response) => {
          if (response.status === 200) {
            // console.log("rerwerewqqweqweqwe", response)
            setAdvBanner(response.data.contents || [])
          }
        })
        .catch((error) => console.log(error));
	},[])

    return (
        <>
            <section className="adv-gallery-section pad-tb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="adv-gallery-list  wow zoomIn animated" style={{visibility: hideOrShow, animationName: animatedName}}>
                                <div className="adv-gallery-left">
                                    <div className="adv-gallery-col h-100">
                                        {/* <Link to="/#" className="adv-gallImg-block"> */}
                                            <img 
                                            //  src={images.adv2}
                                            src={config.endpoints.image_path + advBanner[0]?.image} 
                                            className="img-w-100 h-100 img-fluid" 
                                            alt="" 
                                            style={{borderRadius: "10px"}}
                                            // onClick={()=>window.open(images.adv1)}
                                            onClick={()=>window.open(config.endpoints.image_path + advBanner[0]?.image)}
                                                />
 {
                    advBanner[0]?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(advBanner[0]?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }

                                        {/* </Link> */}
                                    </div>
                                </div>

                                <div className="adv-gallery-right">
                                    <div className="adv-gallery-col flex-gallery">
                                        <Link to="/#" className="adv-gallImg-block position-relative w-100 h-100">
                                            <img 
                                            // src={images.adv2} 
                                            src={config.endpoints.image_path + advBanner[1]?.image}
                                            className="img-fluid h-100 w-100" alt="" 
                                            // onClick={()=>window.open(images.adv2)}
                                            onClick={()=>window.open(config.endpoints.image_path + advBanner[1]?.image)}

                                            />

 {
                    advBanner[1]?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(advBanner[1]?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  } 
                                        </Link>
                                        <Link to="/#" className="adv-gallImg-block w-100 position-relative ml-2">
                                            {/* <img src={images.adv3} className="img-fluid" alt="" /> */}
                                            {/* <video className="adv-gallery-video" width="400px" height="200px" loop muted autoPlay controls style={{objectFit:"cover"}}>
                    <source src={images.startEarningNow} type="video/mp4"/>
                   
                    Your browser does not support HTML5 videos
                </video> */}

{/* <VideoPlayer
                    controls={true}
                    src={advBanner[2]?.video_src}
                    poster={config.endpoints.image_path + advBanner[2]?.image}
                    width="720"
                    height="420"
                    // onReady={this.onPlayerReady.bind(this)}
                    // onPlay={this.onVideoPlay.bind(this)}
                    // onPause={this.onVideoPause.bind(this)}
                    // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    // onSeeking={this.onVideoSeeking.bind(this)}
                    // onSeeked={this.onVideoSeeked.bind(this)}
                    // onEnd={this.onVideoEnd.bind(this)}
                /> */}

<div className="voice-img h-100">
                        {/* <img src={images.adv3} className="img-fluid h-100" alt="" /> */}
                        <img 
                                            // src={images.adv2} 
                                            src={config.endpoints.image_path + advBanner[2]?.image}
                                            className="img-fluid h-100 w-100" alt="" 
                                            // onClick={()=>window.open(images.adv2)}
                                            onClick={()=>window.open(config.endpoints.image_path + advBanner[2]?.image)}
                                            />

{
                    advBanner[2]?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(advBanner[2]?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }
                       
                    </div>
                                        </Link>
                                    </div>

                                    <div className="adv-gallery-col mt-30">
                                        <Link to="/#" className="adv-gallImg-block">
                                            {/* <img src={images.adv4} className="img-fluid w-100" alt="" onClick={()=>window.open(images.adv4)} /> */}
                                            <img 
                                            // src={images.adv2} 
                                            src={config.endpoints.image_path + advBanner[3]?.image}
                                            className="img-fluid h-100 w-100" alt="" 
                                            // onClick={()=>window.open(images.adv2)}
                                            onClick={()=>window.open(config.endpoints.image_path + advBanner[3]?.image)}
                                            />

{
                    advBanner[3]?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(advBanner[3]?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }
                                        
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdvertiseSection;
