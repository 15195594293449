import Error from "../Components/Common/Error";
import CreateMyRequest from "../Pages/CreateMyRequest";
import Home from "../Pages/Home";
import HowItWorks from "../Pages/HowItWorks";
import Login from "../Pages/Login";
import Notifications from "../Pages/Notifications";
import Registration from "../Pages/Registration";
import StartEarning from "../Pages/StartEarning";
import AllRequest from "../Pages/AllRequest";
import PeopleVoice from "../Pages/PeopleVoice";
import AboutUs from "../Pages/AboutUs";
import RequestDetails from "../Pages/RequestDetails";
import ContactUs from "../Pages/ContactUs";
import MyRequest from "../Pages/MyRequest/MyRequest";
import MyProfile from "../Pages/MyProfile";
import ChangeProfileType from "../Pages/ChangeProfileType";
import MyChat from "../Pages/MyChat";
import MyTransactions from "../Pages/MyTransaction/MyTransactions";
import MyFeedback from "../Pages/myFeedback/MyFeedback";
import ResetPassword from "../Components/ResetPassword";
import ServiceCategories from "../Pages/ServiceCategories";
import ServiceProviderDetails from "../Pages/ServiceProviderDetails";
import RegisterSuccessful from "../Pages/RegisterSuccessful";
import QuotesSubmit from "../Pages/QuotesSubmit";
import QuotesDetailsClose from "../Pages/QuotesDetailsClose";
import EditMyRequest from "../Pages/EditMyRequest";
import ServiceProvider from "../Pages/MyRequest/ServiceProviderRequest";
import MarkCompletion from "../Pages/MarkCompletion";
import AcceptCompletion from "../Pages/AcceptCompletion";
import QuotesDetailsSP from "../Pages/QuotesDetailsSP";
import UpdateQoute from "../Pages/UpdateQoute";
import TrackServiceProvider from "../Pages/TrackServiceProvider";
import TermsConditions from "../Pages/TermsConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import ActivateAccount from "../Pages/ActivateAccount";
import Otp from "../Components/Otp";
import CreateNewPassword from "../Components/CreateNewPassword";
import PasswordUpdated from "../Pages/PasswordUpdated";

import CustomerDetails from "../Pages/CustomerDetails";

import invoice from '../Pages/invoice'
import spInvoice from '../Pages/spInvoice'
import Faq from '../Pages/Faq'
import ServiceProviderGuide from '../Pages/ServiceProviderGuide'
// import InsuranceCoverage from '../Pages/InsuranceCoverage'




import Auth from "../Auth/Auth";

import Feedback from '../Pages/Feedback'


const authData = Auth.getUserDetails();

export default [
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/register",
    exact: true,
    component: Registration,
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/otp-sent",
    exact: true,
    component: Otp,
  },
  {
    path: "/create-new-password",
    exact: true,
    component: CreateNewPassword,
  },
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/how-it-work",
    exact: true,
    component: HowItWorks,
  },
  {
    path: "/start-earning",
    exact: true,
    component: StartEarning,
  },
  {
    path: "/create-my-request",
    exact: true,
    component: CreateMyRequest,
  },
  {
    path: "/notifications",
    exact: true,
    component: Notifications,
  },
  {
    path: "/all-requests",
    exact: true,
    component: AllRequest,
  },
  {
    path: "/service-categories",
    exact: true,
    component: ServiceCategories,
  },
  {
    path: "/service-provider-details/:id",
    exact: true,
    component: ServiceProviderDetails,
  },
  {
    path: "/peoples-voice",
    exact: true,
    component: PeopleVoice,
  },
  {
    path: "/about-us",
    exact: true,
    component: AboutUs,
  },
  {
    path: "/contact-us",
    exact: true,
    component: ContactUs,
  },

  {
    path: "/request-detail/:id",
    exact: true,
    component: RequestDetails,
  },

  {
    path: "/customer-details/:id",
    exact: true,
    component: CustomerDetails,
  },
  {
    path: "/my-profile",
    exact: true,
    component: MyProfile,
  },
  {
    path: "/update-quote",
    exact: true,
    component: UpdateQoute,
  },

  // Start Customer Panel Routes
  {
    path: "/my-request",
    exact: true,
    component: MyRequest,
  },

  {
    path: "/change-profile-type",
    exact: true,
    component: ChangeProfileType,
  },
  {
    path: "/my-chat",
    exact: true,
    component: MyChat,
  },
  {
    path: "/my-transactions",
    exact: true,
    component: MyTransactions,
  },

  {
    path: "/my-feedbacks",
    exact: true,
    component: MyFeedback,
  },
  {
    path: "/ezunction-feedback",
    exact: true,
    component: Feedback,
  },
  {
    path: "/quotes-details/:id",
    exact: true,
    component: QuotesDetailsClose,
  },
  {
    path: "/edit-my-request",
    exact: true,
    component: EditMyRequest,
  },
  {
    path: "/service-provider",
    exact: true,
    component: ServiceProvider,
  },
  // End Customer Panel Routes

  // Start Not Found Page Error
  {
    path: "/register-successful",
    exact: true,
    component: RegisterSuccessful,
  },
  {
    path: "/quotes-submit",
    exact: true,
    component: QuotesSubmit,
  },
  {
    path: "/mark-completion/:id",
    exact: true,
    component: MarkCompletion,
  },
  {
    path: "/accept-completion",
    exact: true,
    component: AcceptCompletion,
  },
  {
    path: "/service-provider/quote-details/:id",
    exact: true,
    component: QuotesDetailsSP,
  },
  {
    path: "/track",
    component: TrackServiceProvider,
  },
  {
    path: "/term-conditions",
    component: TermsConditions,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/activate-account/:iv/:ed",
    exact: true,
    component: ActivateAccount,
  },
  {
    path: "/password-updated",
    exact: true,
    component: PasswordUpdated,
  },
  {
    path: "/Service_Provider_Guide",
    exact: true,
    component: ServiceProviderGuide,
  },
  
  // authData && authData?.role_type ?
  // {
  //   path: "/insurance-coverage",
  //   exact: true,
  //   component: InsuranceCoverage,
  // }
  // :
  // {
  //   exact: true,
  //   component: Error,
  // }
  // ,
  /// invoices

  {
    path: "/customer_invoice",
    exact: true,
    component: invoice,
  },

  {
    path: "/sp_invoice",
    exact: true,
    component: spInvoice,
  },
  authData && authData?.role_type ?
  {
    path: "/faq",
    exact: true,
    component: Faq,
  }
  :
  {
    exact: true,
    component: Error,
  }
  ,
  // authData && authData?.role_type === 2 || authData && authData?.role_type === 3 ?
  {
    path: "/Service_Provider_Guide",
    exact: true,
    component: ServiceProviderGuide,
  },
  // :
  // {
  //   exact: true,
  //   component: Error,
  // }
  // ,

  /* ---------------------------------------------- */
  {
    exact: true,
    component: Error,
  },
  // End Not Found Page Error
];
