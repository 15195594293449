import React, { useEffect, useState } from "react";

import config from "../../Components/Apis/config";
import Rating from '../Rating'

import { dateWithFormate } from '../../Utility/common';

import { APIService } from "../../Components/Apis/APIServices";




const CustomerRequest = (props) => {

  const [completeFilterData, setCompleteFilterData] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(21);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPageNo, setTotalPageNo] = useState(1);

  // const [customerFeedback, setCustomerFeedback] = useState([]);

  
  
  // useEffect(() => {    
  //     getData()
  // }, []);

  useEffect(()=>{
    getData()
  },[currentPageNo, totalPageNo, numberOfRecords])

  const getData = ()=>{
    APIService.getCustomerFeedback({limit: currentPageNo, offset: numberOfRecords})
        .then((response) => {
          // console.log("hhhhhhhhhhhhhhgggg", response.data)
          if(response.status === 200) {
          setCompleteFilterData(response.data.data.length ? response.data.data :[])

            // setTotalCount(res.data.total)
            setTotalPageNo(Math.ceil(response.data.total/numberOfRecords) || 1)
          }
      
        })
        .catch((error) => console.log(error));
     
  }

  // const takeToPrevious = () => {
  //   setCurrentPageNo(currentPageNo - 1);
  //   // console.log("/////////////////////////  " , numberOfRecords*(currentPageNo-1) - numberOfRecords , numberOfRecords*(currentPageNo - 1))
  //   setCompleteFilterData(
  //     props?.data.slice(
  //       numberOfRecords * (currentPageNo - 1) - numberOfRecords,
  //       numberOfRecords * (currentPageNo - 1)
  //     )
  //   );
  // };

  // const takeToNext = () => {
  //   setCurrentPageNo(currentPageNo + 1);
  //   setCompleteFilterData(
  //     props?.data.slice(
  //       numberOfRecords * currentPageNo,
  //       numberOfRecords * currentPageNo + 1 + numberOfRecords
  //     )
  //   );
  // };

  //   const changeRecords = (e) => {
  //     setNumberOfRecords(e.target.value);

  //     if (e.target.value >= props?.data.length) {
  //       // console.log("////////////////////// bigger ", e.target.value, completeFilterData.length)
  //       setTotalPageNo(1);
  //       setCurrentPageNo(1);
  //     } else {
  //       setTotalPageNo(Math.ceil(props?.data.length / e.target.value) || 1);
  //       if (
  //         currentPageNo > Math.ceil(props?.data.length / e.target.value)
  //       ) {
  //         setCurrentPageNo(
  //           Math.ceil(props?.data.length / e.target.value) - 1
  //         );
  //       }
  //     }
  //   };

  const changeRecords = (e) => { 
    setNumberOfRecords(e.target.value)
    setCurrentPageNo(1)

  }

 
  

  const takeToPrevious = () => { 
    setCurrentPageNo(currentPageNo - 1)
   
  }

  const takeToNext = () => { 
    setCurrentPageNo(currentPageNo + 1)
  }


 
  const getTitle = (data) => { 
    let result = ""

    if (data?.polite === 1) {
      result = result + "Polite, ";
    }
    if (data?.responsive === 1) {
      result = result + "Responsive, ";
    }
    if (data?.availability === 1) {
      result = result + "Availability, ";
    }
    if (data?.clarity_work === 1) {
      result = result + "Clarity of Work";
    }

    if (result.charAt(result.length - 2) === ",") { 
      result = result.substring(0, result.length - 2)
      
    }

    
    return(<strong>{result}</strong>)
  }
  
  const gettitleSp = (data) => {
    let result = "";

    if (parseInt(data?.sp_arrived_on_time) === 1) {
      result = result + "Arrived on Time, ";
    }
    if (parseInt(data?.sp_cleanliness) === 1) {
      result = result + "Cleanliness, ";
    }
    if (parseInt(data?.sp_quality_of_work) === 1) {
      result = result + "Quality of Work, ";
    }
    if (parseInt(data?.sp_professionalism) === 1) {
      result = result + "Professionalism";
    }

    if (result.charAt(result.length - 2) === ",") { 
      result = result.substring(0, result.length - 2)
      
    }

    return <strong>{result}</strong>;
  };


  // console.log("............./////////////////////", completeFilterData);
  
  return (
    <>
      <div className="container">
        <div className="row">
          {completeFilterData.map((item, index) => (
            <div className="col-12 mt-1">
              <div className="myFeedback-list">
                <div className="myFeedback-item">
                  <div className="mfi-top">
                    <div className="mfiLeft">
                      <div className="mfiL-img">
                        <img
                          src={
                            config.endpoints.image_path +
                            item?.user?.profile_photo
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="mfiL-content">
                        <h3>
                          {" "}
                          {item?.user?.first_name} {item?.user?.last_name}{" "}
                        </h3>
                        <div className="rating">
                          {/* <i className="fas fa-star star-fill"></i>
                            <i className="fas fa-star star-fill"></i>
                            <i className="fas fa-star star-fill"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i> */}
                          {/* {ratingStar(item?.rate_service_provider)} */}
                          <Rating rating={item?.rating} />
                          <span className="rating-count">
                            {" "}
                            {item?.rating}{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mfiRight">
                      <span className="font-weight-bold"> {item?.submittedOrRecieved || ""} for </span>
                      <span className="font-weight-bold"> SR ID: </span>
                      <span className="font-weight-bold" style={{color:"#ff8805"}}>{item?.request_id} </span>
                      <br />
                      <span className="font-weight-bold"> Date and Time: </span>
                      <span className="font-weight-bold" style={{color:"#ff8805"}}> {dateWithFormate(item?.created_at.split(" ")[0].split("-").reverse().join())} &nbsp; {item?.created_at.split(" ")[1]} </span>
                    </div>
                    
                  </div>

                  <div className="mfi-bottom">
                    {/* <strong> Arrived on Time, Cleanliness </strong> */}
                    {item?.submittedOrRecieved === "Received" ? getTitle(item) : gettitleSp(item)}
                    <div className="mfi-des">
                      <p>
                        {/* {" "}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed eget mollis elit non posuere libero nulla
                          quis posuere nulla maecenas a diam nibh class aptent
                          taciti sociosqu ad litora torquent per conubia nostra,
                          per inceptos himenaeos.{" "} */}


                        {/* {item?.feedback_comments || "--"} */}

                        {item?.submittedOrRecieved === "Received" ? item?.feedback_comments || "" : item?.sp_feedback_comments || ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-12 mt-5">
            <div className="e4-pagination">
              <div className="e4p-left">
                <ul className="pagination">
                  <li>
                    <a href="#" className="prev disable">
                      <i className="fal fa-chevron-left"></i> Prev
                    </a>
                  </li>
                  <li className="pageNumber active">
                    <a href="#">1</a>
                  </li>
                  <li className="pageNumber">
                    <a href="#">2</a>
                  </li>
                  <li className="pageNumber">
                    <a href="#">3</a>
                  </li>
                  <li className="pageNumber">
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#" className="next">
                      Next <i className="fal fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="e4p-right">
                <div className="e4p-group">
                  <span> Number of Records </span>
                  <select className="form-control">
                    <option selected> 21 </option>
                    <option> 30 </option>
                    <option> 39 </option>
                    <option> 48 </option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <div className="e4-pagination">
              <div className="e4p-left">
                <ul className="pagination">
                  <li>
                    <div
                      href="#"
                      className={`prev ${
                        currentPageNo === 1 ? "disabled" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (currentPageNo !== 1) {
                          takeToPrevious();
                        }
                      }}
                    >
                      <i className="fal fa-chevron-left"></i> Prev
                    </div>
                  </li>
                  <div>
                    {currentPageNo} of {totalPageNo}
                  </div>

                  <li>
                    <div
                      href="#"
                      className={`next ${
                        totalPageNo === currentPageNo ? "disabled" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (totalPageNo !== currentPageNo) {
                          takeToNext();
                        }
                      }}
                    >
                      Next <i className="fal fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="e4p-right">
                <div className="e4p-group">
                  <span> Number of Records </span>
                  <select
                    className="form-control"
                    name="numberOfRecords"
                    value={numberOfRecords}
                    onChange={(e) => {
                      changeRecords(e);
                    }}
                  >
                    <option value="21"> 21 </option>
                    <option value="30"> 30 </option>
                    <option value="39"> 39 </option>
                    <option value="48"> 48 </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRequest;
