import { useState } from 'react';
import { APIService } from "../Apis/APIServices";

const ClosedWarrantyModal = (props) => {

	const [input, setInput] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault()
	
		if(input) {
			// let data = {input, request_id : props.requestData.id}
		    APIService.closeWarrantyService(props?.requestData?.warranty[props?.requestData?.warranty.length - 1].id, {closure_comments : input, request_id:props?.requestData?.id})
		      .then((res) => {
		        if (res.status === 200) {
		        	setInput("")
		        	document.getElementById("cancleButton").click();
		        	// props._fetchClosedService();
					window.location.reload()
		        }
		      })
		      .catch((e) => console.log("error", e));
		}
	}

	return (
	     <div
	        className="scale_cus_modal warrantyRequest-wrap"
	        id="warranty-close-modal"
	      >
	        <div className="scale_modal_body">
	          <div className="modal_inner">
	            <div className="scm_header">
	              <h2 className="scm_head-title"> Close Warranty </h2>
	            </div>
	            <div className="scm_body">
	              <form onSubmit={(e)=>handleSubmit(e)}>
	                <div className="form-group">
	                  <label> Are you sure you want to close warranty request? </label>
	                  <div className="textarea-group">
	                    <textarea
	                    	className="form-control resize-none"
	                    	rows="6"
	                    	placeholder="Provide the Warranty Closure Comments *"
	                    	maxLength={200}
	                    	onChange={(e)=>setInput(e.target.value)}
	                    	required
	                    ></textarea>
									<span className="textarea-limit"> {200 - input.length} Characters Limit </span>
	                  </div>
	                </div>

	                <div className="scm_footer">
	                  <button
	                    type="button"
	                    className="btn btn-black mr-3"
	                    data-dismiss="modal"
	                    id="cancleButton"
	                  >
	                    Cancel
	                  </button>
	                  <button
	                    type="submit"
	                    className="btn btn-red"
	                    // data-dismiss="modal"
	                  >
	                    Submit
	                  </button>
	                </div>
	              </form>
	            </div>
	          </div>
	        </div>
	      </div>
	)
}

export default ClosedWarrantyModal;