import React, { useState, useEffect,useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import images from "../Utility/Images";
import { APIService } from "../Components/Apis/APIServices";
import { dateWithFormate } from '../Utility/common';
import config from "../Components/Apis/config";
import Rating from "./Rating";
import GetInTouch from "../Components/Common/GetInTouch";

const AllRequest = (props) => {
  const [currentRequest, setCurrentRequest] = useState({});
  const [err, setErr] = useState({});
  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [serviceCat, setServiceCat] = useState("");
  const [serviceSubCat, setServiceSubCat] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [allRequestFilter, setAllRequestFilter] = useState(false);

  // 
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [requestId, setRequestId] = useState("");
  const [category, setCategory] = useState([]);
  const [cat, setCat] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCat, setSubCat] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [duration, setDuration] = useState("");
  // 

  const [originalData, setOriginalData] = useState([])
const [completeFilterData, setCompleteFilterData] = useState([])
  const [numberOfRecords, setNumberOfRecords] = useState(21)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  const [totalPageNo, setTotalPageNo ] = useState(1)

  const [loading, setLoading] = useState(false)

  
  const [totalCount, setTotalCount] = useState(0)
  
  const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

  useEffect(()=>{
    // if (props.location.state !== undefined && serviceCats.length !== 0) {
    if (props.location.state !== undefined && props.location.state !== null) { 
      setServiceCat(props.location.state.serviceCat)
      
      setServiceSubCats(
        serviceCats.filter((sc) => sc.id == props.location.state.serviceCat)[0]?.subcategories || []
      );
      
      setServiceSubCat(props.location.state.serviceSubCat)
      
      APIService.fetchAllServiceType({ sub_category_id: props.location.state.serviceSubCat })
        .then((res) => setServiceTypes(res.data.data))
        .catch((err) => setErr(err));
  
  
      setServiceType(props.location.state.serviceType)
     
      // setTimeout(()=>{
      //   handleSearch()
      // },4000)

      // console.log("rrrrrrrrrrrrrrrrrreee",props.location.state)
      
      // console.log("yyyyyyyyyyyyyyyyytttttttttt 1")
      setLoading(false)
      APIService.fetchAllServiceRequests(
        {
          limit: numberOfRecords,
          page: currentPageNo
        },
        {
          category:(props && props.location && props.location.state && props.location.state.serviceCat),
        subCategory:(props && props.location && props.location.state && props.location.state.serviceSubCat),
        servicetype: (props && props.location && props.location.state && props.location.state.serviceType)
        // category:serviceCat || "",
        // subCategory: serviceSubCat || "",
        // servicetype: serviceType || ""
         
       }, 
        {
          country,
          city,
          state,
          duration,
          requestId,
          requestStatus,
          submittedDate,
          serviceDate
 
        })
      .then((res) => {
        setSrs(res.data.data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3));
        // setOriginalData(res.data.data)
        // setCompleteFilterData(res.data.data)
        // setFilterSrs(res.data.data.slice(0, numberOfRecords));
        // setTotalPageNo(Math.ceil(res.data.data.length/numberOfRecords))
  
        setTotalCount(res.data.total)
  
        setAllRequestFilter(false)
  
        
        setTotalPageNo(Math.ceil(res.data.total/numberOfRecords) || 1)
        setLoading(true)
        
      })
      .catch((e) => setLoading(true));
    

    }else{
      getData()
    }
    
   
    
   },[props.location.state])
  //  console.log("rrrrrrrrrrrrrrrrrreee",serviceCats)

  //////////////////// 2 of 1 issue

  const [pageChangeDueToFilter, setPageChangeDueToFilter] = useState(false)

  useEffect(()=>{
    if(currentPageNo > totalPageNo)
      setCurrentPageNo(totalPageNo)
      setPageChangeDueToFilter(true)
  },[totalPageNo])
    
   
   const getData = (filterValues)=>{
    //  console.log("yyyyyyyyyyyyyyyyytttttttttt 2",serviceCat, serviceSubCat, serviceType, pageChangeDueToFilter)
    setLoading(false)
     APIService.fetchAllServiceRequests(
       {
         limit: (filterValues === "pageChange" ? numberOfRecords : 21),
         page: (filterValues === "pageChange" ? currentPageNo : 1)
       },
       {
      //    category:serviceCat || (props && props.location && props.location.state && props.location.state.serviceCat) || "",
      //  subCategory: serviceSubCat || (props && props.location && props.location.state && props.location.state.serviceSubCat) || "",
      //  servicetype: serviceType || (props && props.location && props.location.state && props.location.state.serviceType) || ""
       category:(serviceCat) || "",
       subCategory: (serviceSubCat) || "",
       servicetype: (serviceType) || ""
        
      }, 
       {
         country,
         city,
         state,
         duration,
         requestId,
         requestStatus,
         submittedDate,
         serviceDate

       })
     .then((res) => {
       setSrs(res.data.data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3));
       // setOriginalData(res.data.data)
       // setCompleteFilterData(res.data.data)
       // setFilterSrs(res.data.data.slice(0, numberOfRecords));
       // setTotalPageNo(Math.ceil(res.data.data.length/numberOfRecords))
 
       setTotalCount(res.data.total)
 
       setAllRequestFilter(false)
 
       
       setTotalPageNo(Math.ceil(res.data.total/numberOfRecords) || 1)
      //  if(filterValues !== "pageChange" || filterValues !== "filterValues"){
      //   setCurrentPageNo(1) 
      //  } 
       setLoading(true)
       
     })
     .catch((e) => setLoading(true));
   }
  

   const getData2 = (filterValues)=>{
    console.log("yyyyyyyyyyyyyyyyytttttttttt 2",serviceCat, serviceSubCat, serviceType, pageChangeDueToFilter)
   setLoading(false)
    APIService.fetchAllServiceRequests(
      {
        limit: (filterValues === "pageChange" ? numberOfRecords : 21),
        page: (filterValues === "pageChange" ? currentPageNo : 1)
      },
      {
     //    category:serviceCat || (props && props.location && props.location.state && props.location.state.serviceCat) || "",
     //  subCategory: serviceSubCat || (props && props.location && props.location.state && props.location.state.serviceSubCat) || "",
     //  servicetype: serviceType || (props && props.location && props.location.state && props.location.state.serviceType) || ""
      category:(cat) || "",
      subCategory: (subCat) || "",
      servicetype: ("") || ""
       
     }, 
      {
        country,
        city,
        state,
        duration,
        requestId,
        requestStatus,
        submittedDate,
        serviceDate

      })
    .then((res) => {
      setSrs(res.data.data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3));
      // setOriginalData(res.data.data)
      // setCompleteFilterData(res.data.data)
      // setFilterSrs(res.data.data.slice(0, numberOfRecords));
      // setTotalPageNo(Math.ceil(res.data.data.length/numberOfRecords))

      setTotalCount(res.data.total)

      setAllRequestFilter(false)

      
      setTotalPageNo(Math.ceil(res.data.total/numberOfRecords) || 1)
     //  if(filterValues !== "pageChange" || filterValues !== "filterValues"){
     //   setCurrentPageNo(1) 
     //  } 
      setLoading(true)
      
    })
    .catch((e) => setLoading(true));
  }
 
 
  const handleServiceCat = (e) => {
    // console.log("called in handle seach servicecat", e.target.value)
    setServiceCat(e.target.value);
    setServiceSubCat("")
    setServiceType("")
    
    if (e.target.value === "") {
      // setCompleteFilterData(originalData)
      // setFilterSrs(originalData)

    } else { 
    setServiceSubCats(
      serviceCats.filter((sc) => sc.id == e.target.value)[0]?.subcategories || []
    );
    }
   
    //APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
  };

  useEffect(()=>{
    setServiceSubCats(
      serviceCats.filter((sc) => sc.id == serviceCat)[0]?.subcategories || []
    )
  },[serviceCats])

  
  

  const handleServiceSubCat = ({ target: { value } }) => {
    setServiceSubCat(value);
    setServiceType("")
    APIService.fetchAllServiceType({ sub_category_id: value })
      .then((res) => setServiceTypes(res.data.data))
      .catch((err) => setErr(err));
  };
  const [srs, setSrs] = useState([]);
  const [filterSrs, setFilterSrs] = useState([]);

  // console.log("////////////////////////aaaaaaaaaaaaa", completeFilterData, filterSrs, serviceCat)

  // const handleSearch = async () => {
  //   ///// need to filter here srs replace with original data and 
  //  console.log("called in handle search", serviceCat,serviceSubCat, serviceType, props.location.state)
  //   if (serviceCat === "") {
  //     console.log("if wala", originalData)
  //     setCompleteFilterData(originalData)
  //     setFilterSrs(originalData.slice(0, numberOfRecords))
      

  //          changeRecords({
  //       target: {
  //         value: numberOfRecords
  //       }
  //     })
     
     
      
  //   } else {
  //     console.log("elsewala", serviceType,serviceSubCat)
    
  //     setFilterSrs(
  //       originalData.filter(
  //         (sr) =>
  //           // sr.category.id === serviceCat ||
  //           (sr.service_type_id == serviceType ||
  //             sr.sub_category_id == serviceSubCat ||
  //             sr.category_id == serviceCat)
  //       )
  //     );
  //     setCompleteFilterData(
  //       originalData.filter(
  //         (sr) =>
  //           (sr.service_type_id == serviceType ||
  //             sr.sub_category_id == serviceSubCat ||
  //             sr.category_id == serviceCat)
  //       )
  //     )

  //     changeRecords({
  //       target: {
  //         value: numberOfRecords
  //       }
  //     })
  //   }
  // };

  useEffect(() => { 
changeRecords({
        target: {
          value: numberOfRecords
        }
      })

    
  },[completeFilterData, filterSrs])




	



  useEffect(() => {
    APIService.fetchAllCategory()
      .then((res) => {
        setServiceCats(res.data.data)
        setCategory(res.data.data)

        if (props.location.state !== undefined && props.location.state !== null) {
          // console.log("asdsadasdasdsa")
        setServiceSubCats(
          res.data.data.filter((sc) => sc.id == props.location.state.serviceCat)[0]?.subcategories || []
        );
        }
      })
      .catch((err) => setErr(err));
    // APIService.fetchAllServiceRequests().then((res) => {
    //   setSrs(res.data.data);
    //   setTotalPageNo(Math.ceil(res.data.total/numberOfRecords))
      
    // })
    // .catch((err)=>{})

    APIService.fetchCountry()
      .then((res) => setCountries(res.data.data))
      .catch((err) => setErr(err));
    
    

    // 
    // APIService.fetchCity({ state_id: e.target.value })
    // .then((res) => setCities(res.data.data))
    // .catch((err) => setErr(err));
    // 

  }, []);

  // const allRequestFilterHideShow = () => {
  //   setAllRequestFilter(!allRequestFilter)
  // }

  const handleCategory = (e) => {
    console.log("yogesh",e.target.value)
    setCat(e.target.value);
    setSubCategory(category.filter((sc) => sc.id == e.target.value)[0].subcategories);
  };

  const handleSubCategory = ({ target: { value } }) => {
    setSubCat(value);
    // APIService.fetchAllServiceType({ sub_category_id: value })
    //   .then((res) => setServiceTypes(res.data.data))
    //   .catch((err) => setErr(err));
  };

  // console.log("yote",srs)


const handleDuration=(val) =>{
  setDuration(val)
  }

  // const handleCountry = (e) => {
  //   setCountry(e.target.value);
  //   APIService.fetchState({ country_id: e.target.value })
  //     .then((res) => setStates(res.data.data))
  //     .catch((err) => setErr(err));
  // };

  useEffect(()=>{
    APIService.fetchState({ country_id: "233" })
    .then((res) => setStates(res.data.data.sort((a, b) => a.name.localeCompare(b.name))))
    .catch((err) => console.log(err));
  
  },[])
  const handleState = (e) => {
    setState(e.target.value);
    APIService.fetchCity({ state_id: e.target.value })
      .then((res) => setCities(res.data.data))
      .catch((err) => setErr(err));
  };

  const changeRecords = (e) => { 
    setNumberOfRecords(e.target.value)
    setCurrentPageNo(1)

  } 

  const takeToPrevious = () => { 
    setCurrentPageNo(currentPageNo - 1)
   
  }

  const takeToNext = () => { 
    setCurrentPageNo(currentPageNo + 1)
  }
  
  useDidMountEffect(()=>{
    getData("pageChange")
  },[currentPageNo, numberOfRecords])

  // console.log("ssssssssssssssdddddd", srs)
  
  return (
    <>
      <Header />
      <section className="inner-banner-section serviceCat-banner-wrap">
        <div className="circle-box slideInUp"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1>
                  {" "}
                  All <span> Requests </span>{" "}
                </h1>
                <p> Explore what others are getting done. You can create your requests now or submit your competitive quote(s) on the various requests and start earning right away. </p>
              </div>
            </div>
          </div>

          <div className='d-flex w-100 justify-content-center mt-3 mb-3' style={{zIndex:"2", position: "relative"}}>
                
                  <div>
                    <Link
                      to={{
                        pathname: "/create-my-request",
                        // state: { ...data },
                      }}
                      className="btn btn-red btn-md ardCat-submitQ-btn2"
                    >
                      Create My Request
                    </Link>
                  </div>

                  </div>

        </div>
      </section>

      <section className="service-filter-section">
        <div className="wp-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <form className="row align-items-end">
            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label> Service Categories </label>
                <select
                  value={serviceCat}
                  className="form-control"
                  onChange={handleServiceCat}
                >
                  <option value="">Select the service category</option>
                  {serviceCats.map((sc) => (
                    <option value={sc.id}>{sc.category_name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label> Service Sub-Categories </label>
                <select
                  value={serviceSubCat}
                  className="form-control"
                  onChange={handleServiceSubCat}
                >
                  <option value="">Select the service sub-category</option>
                  {serviceSubCats.map((sc) => (
                    <option value={sc.id}>{sc.sub_category_name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label> Service Type </label>
                <select
                  value={serviceType}
                  className="form-control"
                  onChange={(e) => setServiceType(e.target.value)}
                >
                  <option value="">Select the service type</option>
                  {serviceTypes.map((st) => (
                    <option value={st.id}>{st.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="searchAction-block form-group">
                <button
                  type="button"
                  className="btn btn-sm btn-red"
                  onClick={(e)=>getData()}
                >
                  {" "}
                  Search{" "}
                </button>
                <button
                  type="button"
                  className="search-filter-btn btn btn-orange"
                  id="catFilter-btn"
                  onClick={() => setAllRequestFilter(!allRequestFilter)}
                >
                  {" "}
                  <img
                    src={images.filterIcon}
                    className="img-fluid"
                    alt=""
                  />{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {allRequestFilter && allRequestFilter ? (
        <section className="catOpen-filter-section open">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cat-open-filter">
                  <div className="cof-inner">
                    <div className="cof-close-block">
                      <button
                        className="cofClose-btn"
                        type="button"
                        onClick={() => setAllRequestFilter(!allRequestFilter)}
                      >
                        {" "}
                        <i className="fal fa-times"></i>
                      </button>
                    </div>

                    <form>
                      <div className="row align-items-end">
                        <div className="form-group col-xl-4 col-md-6">
                          <label> Country </label>
                          <select
                            // value={country}
                            className="form-control"
                            // onChange={handleCountry}
                            value="233"
                            disabled
                          >
                            <option value="">Country</option>
                            {countries.map((country) => (
                              <option value={country.id}>{country.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-xl-4 col-md-6">
                          <label> State </label>
                          <select
                            value={state}
                            className="form-control"
                            onChange={handleState}
                          >
                            <option value="">State</option>
                            {states.map((state) => (
                              <option value={state.id}>{state.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-xl-4 col-md-6">
                          <label> City </label>
                          <select
                            className="form-control"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option value="">City</option>
                            {cities.map((city) => (
                              <option value={city.id}>{city.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request ID </label>
                          <input
                            type="text"
                            onChange={(e) => setRequestId(e.target.value)}
                            className="form-control"
                            placeholder="Enter the Request ID "
                            value={requestId}
                          />
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Category </label>
                          <select
                            value={cat}
                            className="form-control"
                            onChange={handleCategory}
                          >
                            <option value="">
                              Select the service category
                            </option>
                            {category.map((sc) => (
                              <option value={sc.id}>{sc.category_name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                        <label> Sub Category </label>
                          <select
                            value={subCat}
                            className="form-control"
                            onChange={handleSubCategory}
                          >
                            <option value="">
                              Select the service sub-category
                            </option>
                            {subCategory.map((sc) => (
                              <option value={sc.id}>
                                {sc.sub_category_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request Status </label>
                          <select
                            className="form-control"
                            onChange={(e) => setRequestStatus(e.target.value)}
                            value={requestStatus}
                          >
                            <option selected value="">
                              {" "}
                              Select the request status{" "}
                            </option>
                            <option value="1"> Submitted </option>
                            {/* <option value="2"> Cancelled </option> */}
                            <option value="3"> Quote Received </option>
                            {/* <option value="4"> Confirmed </option> */}
                            {/* <option value="5"> Started </option> */}
                            {/* <option value="6"> Completed </option> */}
                            {/* <option value="7"> Pending </option> */}
                          </select>
                        </div>

                        {/* <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker00"
                          data-target-input="nearest"
                        >
                          <label for=""> Submitted Date </label>
                          <input
                            type="date"
                            onChange={(e) => setSubmittedDate(e.target.value)}
                            className="form-control"
                            data-target="#datepicker00"
                            data-toggle="datetimepicker"
                            placeholder="Submitted Date"
                            value={submittedDate}
                          />
                        </div> */}

                        <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker1300"
                          data-target-input="nearest"
                        >
                          <label for=""> Service Date </label>
                          <input
                            type="date"
                            onChange={(e) => setServiceDate(e.target.value)}
                            className="form-control"
                            data-target="#datepicker1300"
                            data-toggle="datetimepicker"
                            placeholder="Service Date"
                            value={serviceDate}
                          />
                        </div>

                        <div className="form-group col-xl-4 col-12 mb-0 mb-md-2">
                          <label> Duration </label>
                          <div className="radio-block">
                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration30"
                                value="0-30"
                                // checked={e.target.checked}
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "0-30"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration30"
                              >
                                {" "}
                                0-30 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                value="31-90"
                                id="duration90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "31-90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90"
                              >
                                {" "}
                                31-90 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration90above"
                                value="90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90above"
                              >
                                {
                                `> 90 Days`}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="form-group col-12 mt-3 mb-0">
                          <div className="cof-submit-group text-center">
                          <button
                            type="button"
                            className="btn btn-black btn-sm"
                            onClick={() => {
                              setCountry("")
                              setState("")
                              setCity("")
                              setRequestId("")
                              setCat("")
                              setSubCat("")
                              setRequestStatus("")
                              setSubmittedDate("")
                              setServiceDate("")
                              setDuration("")
                              setSubCategory([])
                              setCities([])
                            }}
                          >
                            {" "}
                            Clear{" "}
                          </button>
                            <button
                              type="button"
                              className="btn btn-black btn-sm ml-3"
                              onClick={()=>setAllRequestFilter(false)}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-red btn-sm ml-3 mt-2 mt-md-0"
                              onClick={(e) => getData2("filterValues")}
                            >
                              {" "}
                              Apply{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <section className="service-categories-section pad-t-100 pad-b-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
            {    !loading ? 
              <div className='d-flex justify-content-center mb-3'>
                <i className="fa fa-spinner" aria-hidden="true"></i>
              </div>

              :
              srs.length <= 0 ? 
            <div className="d-flex justify-content-center mb-5">
              No matching records found
            </div> 
            :
              <div className="service-cat-list">
              
                {srs.length > 0 &&
                  srs.map((sr) => (
                    <div className="service-cat-item wow fadeInUp delay-2s">
                      <Link
                        to={{
                          pathname: "/request-detail/" + sr.id,
                          state: {
                            // filterSrs: filterSrs,
                            // completeFilterData: completeFilterData
                            serviceType: serviceType,
                            serviceSubCat: serviceSubCat,
                            serviceCat: serviceCat,
                            country,
         city,
         state,
         duration,
         requestId,
         requestStatus,
         submittedDate,
         serviceDate,

         numberOfRecords,
         currentPageNo
                          },
                        }}
                        // className="service-cat-inner"
                        data-rel="grd-1"
                      >
                        <div className="service-cat-inner h-100">
                        <div className="sc-head">
                          <div className="left">
                            <img
                              src={
                                config.endpoints.image_path +
                                sr?.user?.profile_photo
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="right">
                            <h3>
                              {" "}
                              {sr.user &&
                                sr.user.first_name +
                                  " " +
                                  sr.user.last_name}{" "}
                            </h3>
                            <div className="rating">
                            <Rating rating={sr?.user?.rating} />
                                        <span className="rating-count">
                                        {sr?.user?.rating}
                                        </span>
                            </div>
                          </div>
                        </div>

                        <div className="sc-body">
                          <div className="scb-top">
                            <span className="service-type">
                              {/* {sr.sr_status === 3 && sr?.quote?.length !== 0
                                ? sr?.quote?.length + " " + sr?.srstatus?.name
                                : sr?.srstatus?.name}{" "} */}
                                  {sr.sr_status === 3 
                                ? sr?.quote_count + " " + sr?.srstatus?.name
                                : sr?.srstatus?.name}{" "}
                            </span>
                            <span className="text-dark">{sr?.id ? `SR ID: ${sr?.id}`: null}</span>
                          </div>
                          <div className="scb-bottom">
                            <span className="sc-name">
                              {sr?.serviceType?.name || "-"}
                            </span>
                            <span className="sc-name">
                              {sr?.title || "-"}
                            </span>

                            <span className="text-ellipsis">
                              {sr.cityGet ? sr.cityGet.name + ", " : "-"}
                              {sr.cityGet.states
                                ? sr.cityGet.states.name 
                                // + ", "
                                : "-"}
                              {/* {sr?.cityGet?.countries?.iso3 || "-"} -{" "}
                              {sr?.zip_code || "-"} */}
                              {/* <br className="d-none d-md-block" />{" "} */}
                              <br />
                              {dateWithFormate(sr?.service_date)}&nbsp; (
                              {dateWithFormate(
                                sr?.timeGet?.start_time,
                                "hA",
                                "HH:mm:ss"
                              )}{" "}
                              -{" "}
                              {dateWithFormate(
                                sr?.timeGet?.end_time,
                                "hA",
                                "HH:mm:ss"
                              )}
                              )
                              {/*                              {sr.service_date +
                                `(${
                                  sr.timeGet &&
                                  sr.timeGet.start_time.split(":")[0] +
                                    "-" +
                                    sr.timeGet.end_time.split(":")[0]
                                })`}{" "}*/}
                            </span>
                          </div>

                          <Link
                                to={{
                                  pathname: "/quotes-submit",
                                  state: { pageData: sr },
                                }}
                                className="btn btn-red btn-md ardCat-submitQ-btn"
                              >
                            {" "}
                            Submit Quote{" "}
                          </Link>
                        </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  
              </div>
}
            </div>

            <div className="col-12">
              <div className="e4-pagination">
                <div className="e4p-left">
                  <ul className="pagination">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo !== 1) {
                            takeToPrevious();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo} of {totalPageNo}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo === currentPageNo ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo !== currentPageNo) {
                            takeToNext();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="e4p-right">
                  <div className="e4p-group">
                    <span> Number of Records </span>
                    <select
                      className="form-control"
                      name="numberOfRecords"
                      value={numberOfRecords}
                      onChange={(e) => {
                        changeRecords(e);
                      }}
                    >
                      <option> 21 </option>
                      <option> 30 </option>
                      <option> 39 </option>
                      <option> 48 </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex w-100 justify-content-center mt-3 mb-3'>
                 
               
                 <div>
                   <Link
                     to={{
                       pathname: "/create-my-request",
                       // state: { ...data },
                     }}
                     className="btn btn-red btn-md ardCat-submitQ-btn2 ml-3"
                   >
                     Create My Request
                   </Link>
                 </div>
                 </div>


        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </>
  );
};

export default AllRequest;
