import React from 'react';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';

const TermsConditions = () => {
    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> Terms and <span> Conditions </span> </h1>
                                <p> Last updated [Jan 31, 2021] </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="getContent-section">
                <div className="wp-clip-bg">
                    <img src="images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
                </div>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="getContent-item m-0">
                                <p> The eZunction website and the mobile application (“eZunction Platform”) offers the “services” to you, conditioned on your acceptance without modification, of the following terms, conditions, and notices as included in this agreement (referred as "Terms and Conditions"). These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and eZunction LLC (“we,” “us” or “our”), concerning your access to and use of the eZunction platform as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “eZunction Platform”).  </p>
                                <p>You agree that by accessing the eZunction Platform, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately. </p>
                                <p> Supplemental terms and conditions or documents that may be posted on the eZunction Platform from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change. </p>
                                <p> It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the eZunction Platform after the date such revised Terms and Conditions are posted.  </p>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="getContent-img">
                                <img src="images/content/term-conditions.svg" className="m-auto d-block img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="getContent-item">
                                <p> The information provided on the eZunction Platform is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.  </p>

                                <p> Accordingly, those persons who choose to access the eZunction Platform from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.  </p>

                                <p> The eZunction Platform is intended for users who are at least 18 years old. </p>

                                <p> Your use of this platform, to create your service requests or submit your quotes (via website or the mobile applications using your mobile/hand-held device) in response to the posted service requests constitutes your agreement to all such terms, conditions, and notices that are in effect at such time. These Terms and Conditions are applicable to any service/feature/functionality that you use on this platform (including but not limited to eZunction.com).These terms and conditions are accessible at all times on the eZunction platform.  If you have any questions regarding these Terms & Conditions, you may contact us at www.ezunction.com. eZunction preserves the right to amend these Terms & Conditions at any time by posting a revised version of these Terms and Conditions. These Terms & Conditions were last updated on January 31, 2021. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> USER REPRESENTATIONS  </h2>
                                </div>
                                <p> Personal and other information you may provide when entering contests or giveaways and/or responding to surveys. </p>
                                <ul>
                                    <li> All registration information you submit will be true, accurate, current, and complete; </li>
                                    <li>You will maintain the accuracy of such information and promptly update such registration information as necessary; </li>
                                    <li> You have the legal capacity and you agree to comply with these Terms and Conditions; </li>
                                    <li> You are not under the age of 13;</li>
                                    <li> Not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site; </li>

                                    <li> You will not access the eZunction platform through automated or non-human means, whether through a bot, script, or otherwise; </li>
                                    <li> You will not use the eZunction platform for any illegal or unauthorized purpose; </li>
                                    <li> Your use of the eZunction platform will not violate any applicable law or regulation.</li>
                                </ul>
                                <p> If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the eZunction platform (or any portion thereof).</p>
                            </div>


                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 1. eZunction Services</h3>
                                </div>
                                <p> eZunction’s objective is to simplify the process of posting service requests and simplifying the process to select the most appropriate service provider to complete the work. eZunction’s goal is to make it simple and hassle free experience for its users as the requests get completed. eZunction provides the following key functionalities to it’s users:  </p>

                                <div className="gc-left">
                                    <div className="gcl-item">
                                        <h4> a. User Registration: </h4>
                                        <p> You are required to register with the eZunction platform. You agree to keep your password confidential and will be responsible for all use of your account and password. This password is used to authenticate you during the sign-in process, on the eZunction platform to confirm your identity. You have the option to change your password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4 className="t-upper"> PROHIBITED ACTIVITIES </h4>
                                        <p> You may not access or use the eZunction platform for any purpose other than that for which we make the eZunction platform available. The eZunction platform may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.  </p>

                                        <p> As a user of the eZunction platform, you agree not to:</p>

                                        <ul>
                                            <li> systematically retrieve data or other content from the eZunction platform to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us. </li>

                                            <li> make any unauthorized use of the eZunction platform, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                                            <li> use a buying agent or purchasing agent to make purchases on the eZunction platform. </li>
                                            <li> use the eZunction platform to advertise or offer to sell goods and services.</li>
                                            <li> circumvent, disable, or otherwise interfere with security-related features of the eZunction platform, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the eZunction platform and/or the Content contained therein.</li>
                                            <li> engage in unauthorized framing of or linking to the eZunction platform.</li>
                                            <li> trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                                            <li> make improper use of our support services or submit false reports of abuse or misconduct.</li>
                                            <li> engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                                            <li> interfere with, disrupt, or create an undue burden on the eZunction platform or the networks or services connected to the eZunction platform.</li>
                                            <li> attempt to impersonate another user or person or use the username of another user.</li>
                                            <li> sell or otherwise transfer your profile.</li>

                                            <li> use any information obtained from the eZunction platform in order to harass, abuse, or harm another person.</li>
                                            <li> use the eZunction platform as part of any effort to compete with us or otherwise use the eZunction platform and/or the eZunction Content for any revenue-generating endeavor or commercial enterprise.</li>
                                            <li> decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the eZunction platform.</li>
                                            <li> attempt to bypass any measures of the eZunction platform designed to prevent or restrict access to the Site, or any portion of the eZunction platform. </li>
                                            <li> harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the eZunction platform to you.</li>
                                            <li> delete the copyright or other proprietary rights notice from any Content.</li>

                                            <li> copy or adapt the eZunction platform’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                                            <li> upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the eZunction platform or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the eZunction platform.</li>
                                            <li> upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                                            <li> except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the eZunction platform, or using or launching any unauthorized script or other software. </li>
                                            <li> disparage, tarnish, or otherwise harm, in our opinion, us and/or the eZunction platform.</li>
                                            <li> use the eZunction platform in a manner inconsistent with any applicable laws or regulations. </li>
                                        </ul>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> b. Search for a service/service provider: </h4>
                                        <p> eZunction supports the ability to search for a particular service category and provide a list of the matching service providers based on the filter criteria provided by the user. This indicates the user that there are service providers in a particular area who are available to work on your requests. This list of service providers include their profile descriptions, overall ratings and the feedback from other customers. However, we do not guarantee that we will be able to provide an exact match for your service need with a Service Professional or that there is always an available Service Professionals in your area that is either capable or willing to complete your service needs.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> c. Quick Comments:  </h4>
                                        {/* <p> eZunction offers the Chat functionality to the service providers and the customers to be able to connect quickly with each other, once a quote has been submitted for a service request. This chat feature is provided to the customers and the service providers to exchange messages to get the needed clarity about the request and make changes to the quote if needed. eZunction prohibits the use/exchange of any sort of contact information such as phone number, email address, address information etc. during the chat sessions (or when providing any additional details/comments on the service requests or the quotes). In the event, eZunction discovers that such information is shared, eZunction reserves the right to disable/cancel the service request and take any other needed actions found appropriate to safeguard the interest of the eZunction platform and the business it supports.</p> */}
                                        <p>eZunction offers the Comment functionality to the service providers and the customers to be able to connect quickly with each other, once a quote has been submitted for a service request. This Comment feature is provided to the customers and the service providers to exchange messages to get the needed clarity about the request and make changes to the quote if needed. eZunction prohibits the use/exchange of any sort of contact information such as phone number, email address, address information etc. in the comments (or when providing any additional details/information on the service requests or the quotes). In the event, eZunction discovers that such information is shared, eZunction reserves the right to disable/cancel the service request and/or quote and take any other needed actions found appropriate to safeguard the interest of the eZunction platform and the business it supports. Any such information may be masked as much as possible. Further, this will be considered as the violation of the eZunction platforms Terms and Conditions and such violations may be subject to a violation charge as defined by the eZunction platform.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> d. Quick and easy confirmation:  </h4>
                                        <p> Customers of the eZunction platform are able to review the competitive quotes that they receive, on their service requests from the different service providers. Customers will choose the best quote as per their choice and after reviewing all the quote details and possible chatting with the service providers to provide any additional details on their service request or get any clarity from the service provider on their submitted quotes. The customers and service providers will mutually agree to a schedule, on the eZunction platform itself, during which the work will need to be completed.  eZunction does not guarantee that the service providers will arrive on time, or show up for, any such confirmed requests, as the Service providers are not the employees, contractors or agents of eZunction. eZunction is solely providing the platform to facilitate scheduling of service requests between the customer and the service provider. However, eZunction does not promote the no-show culture on behalf of either the customer or the service provider and provides the ability to mark the service provider or the customer as no-show. Such users can also provide feedback to the customer or the service provider which gets updated in their respective profiles. eZunction promotes the culture of professionalism and respect the importance of time for everyone.   </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> e. No Guarantees or Recommendations:  </h4>
                                        <p>  eZunction screens the details provided by the service providers (who the customers engage for the completion of their requests) and the customers during the registration process. However, eZunction does not claim/make any guarantees, warranties, representations, recommendations or endorsements regarding the service provider profiles (including their skills and the stated experience levels) or the quality of the work performed by them, as the customer choose to engage them to complete their work. eZunction does not in any way or form endorse/recommend any specific service provider. eZunction does not independently verify any representations from the service providers about any of the stated details representing their services, experience or any other profile credentials. The customers are responsible in entirety to make the needed assessments of the service provider’s credential and experiences and agree directly with the service providers regarding their expectations of their requests. </p>

                                        <p> eZunction does not make any guarantees/warranties or holds itself responsible for the performance of the work and/or the quality of the work performed by the service provider eZunction is neither an employer nor an agent of the service  </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4>  f. No contractual service agreements via eZunction platform </h4>
                                        <p> All the quotes provided by the service providers in response to the customer’s service requests does not imply any services agreement between the customers and the service providers. They are indicative quotes provided based on the information given in the service request or otherwise. The service providers may change the quotes depending on the actual work to be performed as they visit the location where the work need to be performed. eZunction will not be a party to any contractual or service agreements that the customers and service providers may for the service request and the submitted quote, coordinated by the eZunction platform, and hence will not be held responsible for any obligations/claims that may arise out of such contracts. </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> g. Ownership of damages/disputes or warranty claims:  </h4>
                                        <p> Any damages, disputes and/or warranty claims between the customer and the service provider need to be mutually agreed and sorted. eZunction platform does not hold any accountability of the damages/ disputes/ completion of warranty claims as they may arise. However, eZunction platform provides features to submit warranty and dispute request via the platform for the specific service provider to facilitate the communication and track resolution of such events between the customer and the service providers. eZunction may extend help to facilitate and resolve dispute events based on the information available on the eZunction platform.  CUSTOMERS/SERVICE PROVIDERS/GUEST USERS, HEREBY AGREE TO RELEASE eZUNCTION IT’S ASSOCIATES, PARTNERS, DIRECTORS, MANAGING STAFF, ANY OTHER STAFF, STAKEHOLDERS OR ANY DIRECT/INDIRECT REPRESENTATIVES) FROM ANY DAMAGES OR CLAIMS (INCLUDING CONSEQUENTIAL AND/OR INCIDENTAL DAMAGES) OF ANY KIND OR NATURE, KNOWN AND UNKNOWN, AND DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES AND YOUR DEALINGS BETWEEN THE USERS OF THE EZUNCTION PLATFORM.  </p>
                                    </div>
                                </div>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 2. Restrictions on use of eZunction platform</h3>
                                </div>
                                <p>You hereby acknowledge and confirm that your intent to use the eZunction platform is purely for your personal use to be able to complete the service requests and get your work completed by your chosen service provider. You do not intend or will make any effort to bypass or make direct arrangements with the service providers to skip the eZunction platform and to avoid the eZunction charges that are liable to be paid by you, for the use of the eZunction platform. You also further agree and confirm that you will not copy/collect/recreate any content/material from the eZunction platform via any modes (manual/automated using any computer software/tools etc.). Further, you acknowledge and understand that any such violation could result in disciplinary/legal actions to be taken by eZunction.  . You also acknowledge that any details of the service providers or the customers shared on the eZunction platform is merely for the informational purposes and to enable a more informed decision while selecting a particular quote or a service provider choosing to work on a particular service request. You will not share any such information or service provider details outside the eZunction platform by any mode or form and that you will respect the confidentiality and privacy rights of the users of the eZunction platform.  </p>

                                <p> eZunction, reserves the right to disable your account/access to the eZunction platform, with or without notice and take the needed legal actions as found appropriate to recover any damages, lost revenue, setback to customer experiences, legal fees and any associated costs and expenses. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 3. Payments and Charges</h3>
                                </div>
                                <p>  eZunction Platform will collect the payments for the services requested and completed, using the eZunction platform. Such payments are collected via the eZunction platform using a secured 3rd party payment gateway. All the payment and financial transactions are ensured to be secured and encrypted, as per the security and encryption policies of such 3rd party payment gateways. Any disputes/questions related to the charges for a particular service request shall be made within 2 (two) days after the charge has been made on the customer’s payment method or paid to a service provider. Any charges collected by eZunction will be final and will not be refunded, except as limited by the applicable federal and state laws. However, in case the user reports a dispute around the payments made and the charges collected by the eZunction platform, eZunction reserves the right to review such requests and may decide to decline or process partial/full refunds or extend any discount coupons to the reporting users to ensure user satisfaction </p>

                                <p> You promise to provide a valid and unexpired payment method such as a credit/debit card and/or a bank account for direct debits/payments and agree that eZunction platform may store such payment method information in case of any future payments.  </p>

                                <p> You acknowledge and agree that eZunction platform is authorized to charge your provided payment method as per the amount shown on your service request, in addition to any other applicable eZunction such as but not limited to labor charges, material charges, applicable service taxes, service charge, cancellation charges, past due charges or no-show charges, as applicable. You further, acknowledge and agree that you fully understand the applicable eZunction charges that may be applicable to you based on the actions that you take or the expected actions that you do not perform for your service requests or quotes, as needed to complete the normal course of a service request.</p>

                                <p> You authorize eZunction platform to ensure the correctness of your provided payment method by, issuing a request for a payment authorization and/or a low dollar credit and/or debit to the method of payment as per the relevant financial institutions rules. eZunction platform has the sole discretion to accept/deny any of your provided payment methods, with or without notice, excluding notices as required by the applicable laws and not waived. All payment transactions made through the eZunction Platform are also governed by the applicable terms and conditions including but not limited to any late fees, penalties, monthly charges, interest charges from your issuing financial institution/bank. You will be responsible for any/all of such charges. </p>

                                <p> You also acknowledge and confirm that in case of a charge dispute (also known as a chargeback, inquiry, or retrieval), that occurs when you questions the charges made by eZunction, with your payment institution (card issuer/banks). Your charge dispute may be escalated to a chargeback by your payment institution. To process such a chargeback, the payment institution creates a formal dispute which immediately reverses the payment. In such cases, you may be refunded the payment amount, minus the dispute fee on actuals (for users in the United States). </p>

                                <p> You acknowledge and confirm that you will use the eZunction platform to ask for services which are legally allowed as per the applicable jurisdiction and will not create service request to perform any activity which is found inappropriate or illegal by the federal and/or the state laws, and use eZunction platform to make any payments for the completion of such services. If you fail to comply with these terms, it may lead to termination of your eZunction account and may lead to legal actions from the eZunction platform. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 4. Data provided by you to eZunction platform:</h3>
                                </div>
                                <p> Any information that you provide to the eZunction platform, regarding your profile, location details etc. will be stored in the eZunction platform. Some of this information including but not limited to your name, service request address, your feedback ratings, your service request details etc. will be shared with the service providers for them to gather the needed information and submit a suitable quote to complete your request. You consent to provide such information with the service providers and agree to be contacted via the eZunction platform via email, mobile text and PUSH notifications that the eZunction app will generate during the lifecycle of your service request, to notify you appropriately about your service requests and any other promotional coupons/discounts/safety regulations related messages that eZunction may communicate from time to time. You hereby promise that all information you provide (including but not limited to your contact information, and any Ratings and Reviews of Service providers and/or customers that you provide) will be accurate, current and truthful to the best of your knowledge and that you will not use such features to defame/bring a bad name to any individual/company or organization. You will not include any personal comments including but not limited to personal disrespect, racial discrimination, religious comments or any such sort of un-acceptable comments regarding a customer or a service provider while providing feedback or otherwise. In the event, such comments are noticed, your account may be deactivated without notice and appropriate legal actions may be taken, by eZunction.  </p>

                                <p> You further acknowledge and agree that any details that you provide regarding your credentials such as name, address, email, phone number etc. and including the details provided in your service requests/quotes (such as your service address, name, email/phone number etc.) are true and correct to the best of your knowledge. Any false information, provided may lead to appropriate legal actions under the pretext of wrong/manipulated information as fraudulent event. You also agree to keep your information correct at all times and update it on the eZunction platform to be able to communicate and notify you with any important information details from time to time. Any fraudulent information provided may lead to a loss of time, revenue, and could result in legal implications and fines. You agree and consent that in the event of providing any such false and fraudulent information, you are liable to eZunction and each associated impacted person for a minimum amount of USD $10000.00 (Ten Thousand Dollars) per entity. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 5. Dispute Resolution</h3>
                                </div>
                                <p> eZunction platform provides you the ability to submit a dispute request, to report any issues related to the work performed by the service provider that will be engaged via the eZunction platform to complete your request. eZunction does not provide any guarantee/warranty and/or the quality of the work performed by such service providers. eZunction provides a facility to create a dispute request in an attempt to support and assist you in reporting and resolution of the disputes with the service providers.  While all the other terms and conditions of this agreement are unaltered, eZunction on your request may provide limited assistance based on the available data such as chat history, comments provided etc. to resolve the disputes between the customers and service providers (who completed the service requests via the eZunction platform). However, this does not nullify the release and indemnity agreement included in this terms and conditions.   </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 6. Content created by the users on the eZunction platform</h3>
                                </div>
                                <p> You acknowledge and agree that all of the content and information that you/your delegates or any other person/organization/entity, including but not limited to: </p>

                                <ul>
                                    <li>Pictures, graphics or Images in any format,  </li>
                                    <li> Feedback, Comments, Suggestions, Questions and/or Answers,</li>
                                    <li>Your voice messages or any audio and video clippings </li>
                                    <li> Any information included in your service requests or in your quotes </li>
                                    <li> Or any other content</li>
                                </ul>

                                <p> (known collectively as "content"), will be the sole and exclusive property of eZunction. , and that you will have no right to recreate, publish, or otherwise use such information for any other purposes other than your personal use in relation to your service request. You also acknowledge and confirm that you have the needed rights to use/share and publish the content to a platform such as eZunction, where the content may be available for public assess and viewing. In addition, if you post or otherwise provide any Content that is protected by copyright, you agree to obtain any necessary permissions or releases from the applicable party/entity who owns the copyright, </p>

                                <div className="gc-left">
                                    <div className="gcl-item">
                                        <h4> a. eZunction can use your content. </h4>
                                        <p> You acknowledge and agree that the content you post/upload/create on the eZunction platform may be accessible to the general public and will not be treated as your private, proprietary or confidential content. You authorize us and our affiliates, licensees and sub-licensees, royalty free, without any monetary or non-monetary compensation to you/your delegates or any other person/organization/entity, to be used by eZunction with or without change in any geographical location/country in any of the existing media formats or any future media formats, and that your consent is perpetual and cannot be revoked under any circumstances. You also agree to waive and release and covenant not to assert any moral rights that you may have in any Content posted or provided by you, to the extent of the applicable law. </p>

                                        <p>  Content may be viewable by other users of the eZunction Platform and through third-party websites. As such, any Content you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Content, you thereby represent and warrant that:</p>

                                        <ul>
                                            <li> the creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your content do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party. </li>

                                            <li> you are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the eZunction Platform, and other users of the eZunction Platform to use your content in any manner contemplated by the eZunction Platform and these Terms and Conditions. </li>
                                            <li> you have the written consent, release, and/or permission of each and every identifiable individual person in your Content to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Content in any manner contemplated by the eZunction Platform and these Terms and Conditions.</li>
                                            <li> your Content is not false, inaccurate, or misleading. </li>
                                            <li> your Content is not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation. </li>
                                            <li> your Content is not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us). </li>
                                            <li> your Content do not ridicule, mock, disparage, intimidate, or abuse anyone. </li>
                                            <li> your Content do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another. </li>

                                            <li>your Content do not violate any applicable law, regulation, or rule.</li>
                                            <li> your Content do not violate the privacy or publicity rights of any third party.</li>
                                            <li>your Content do not contain any material that solicits personal information from anyone under the age of 18 or exploits people in a sexual or violent manner. </li>
                                            <li> your Content do not violate any federal or state law concerning child pornography, or otherwise intended to protect the health or well-being of minors;</li>
                                            <li> your Content do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                                            <li>your Content do not otherwise violate, or link to material that violates, any provision of these Terms and Conditions, or any applicable law or regulation. </li>
                                        </ul>
                                    </div>

                                    <div className="gcl-item">
                                        <p> <strong> b.</strong>  Any use of the eZunction Platform in violation of the foregoing violates these Terms and Conditions and may result in, among other things, termination or suspension of your rights to use the Site or appropriate legal action.   </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> c. Guidelines to create your content:</h4>
                                        <p> You acknowledge and agree that the content created by you will follow the below stated guidelines and that eZunction has the right, but not a responsibility, to edit, deny the post or remove any content that you/your delegates or any other person/organization/entity posts on the eZunction platform, if eZunction considers any such content to be violating the below guidelines. Any content posted by you on the eZunction platform, shall NOT:  </p>

                                        <ul>
                                            <li> Indicate any sort of racism/discrimination for any community, people, religion, country or people’s beliefs </li>
                                            <li> Have any offensive, abusive or harmful language, including but not limited to harassment, vulgarity, sexually oriented language, hateful, racist, discriminatory messages </li>
                                            <li> Include any false/fraudulent information</li>
                                            <li> Support any activity which is not legal as per the law of the land/country/location </li>
                                            <li> Include any content that impacts the impression and image of the eZunction platform to the general public and the users of the eZunction platform</li>
                                            <li> Include personal disrespect, hatred and all the feedback comments/messages/content (if any) etc. about the customer who requested the work or the service provider who performed the work, and shall be related to the work performed only</li>
                                        </ul>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> d. Content License:</h4>
                                        <p> By posting your content to any part of the eZunction platform (or making creating content accessible to the eZunction platform by linking your account from the eZunction platform to any of your social networking accounts), you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Content (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Content, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels. </p>

                                        <p> This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Content, and you warrant that moral rights have not otherwise been asserted in your Content. </p>

                                        <p> We do not assert any ownership over your Content. You retain full ownership of all of your Content and any intellectual property rights or other proprietary rights associated with your Content. We are not liable for any statements or representations in your Content provided by you in any area on the eZunction platform.  </p>

                                        <p> You are solely responsible for your Content to the eZunction platform and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Content. </p>

                                        <p> We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Content; (2) to re-categorize any Content to place them in more appropriate locations on the Site; and (3) to pre-screen or delete any Content at any time and for any reason, without notice. We have no obligation to monitor your Content. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 7. Social Media</h3>
                                </div>
                                <p>  As part of the functionality of the eZunction Platform, you may link your account with online accounts you have with third-party service providers (each such account, a “Third-Party Account”) by either: (1) providing your Third-Party Account login information through the Site; or (2) allowing us to access your Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account. </p>

                                <p> You represent and warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant us access to your Third-Party Account, without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account, and without obligating us to pay any fees or making us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account. </p>

                                <p> By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content that you have provided to and stored in your Third-Party Account (the “Social Network Content”) so that it is available on and through the eZunction Platform via your account, including without limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional information to the extent you are notified when you link your account with the Third-Party Account. </p>

                                <p> Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be available on and through your account on the eZunction Platform.  </p>

                                <p> Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be available on and through the eZunction Platform. You will have the ability to disable the connection between your account on the eZunction Platform and your Third-Party Accounts at any time.  </p>

                                <p> PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.  </p>

                                <p> We make no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content.  </p>

                                <p> You acknowledge and agree that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the eZunction Platform.  </p>

                                <p> You can deactivate the connection between the eZunction Platform and your Third-Party Account by contacting us. We will attempt to delete any information stored on our servers that was obtained through such Third-Party Account, except the username and profile picture that become associated with your account. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3>8. Advertising</h3>
                                </div>
                                <p> We allow advertisers to display their advertisements and other information in certain areas of the eZunction Platform, such as sidebar advertisements or banner advertisements. If you are an advertiser, you shall take full responsibility for any advertisements you place on the eZunction Platform and any services provided on the eZunction Platform or products sold through those advertisements.  </p>

                                <p> Further, as an advertiser, you warrant and represent that you possess all rights and authority to place advertisements on the eZunction Platform, including, but not limited to, intellectual property rights, publicity rights, and contractual rights. As an advertiser, you agree that such advertisements are subject to our Digital Millennium Copyright Act (“DMCA”) Notice and Policy provisions as described below, and you understand and agree there will be no refund or other compensation for DMCA takedown-related issues. We simply provide the space to place such advertisements, with or without monetary compensation for eZunction Platform to host the advertisements, and we have no other relationship with advertisers.</p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3>9. eZUNCTION Platform Management</h3>
                                </div>
                                <p> We reserve the right, but not the obligation, to:  </p>
                                <ul>
                                    <li> monitor the eZunction Platform for violations of these Terms and Conditions; </li>
                                    <li> take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;  </li>
                                    <li> in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your contributions or any portion thereof; </li>
                                    <li> otherwise manage the eZunction Platform in a manner designed to protect our rights and property and to facilitate the proper functioning of the eZunction Platform. </li>
                                </ul>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 10. Privacy Policy</h3>
                                </div>
                                <p> We care about data privacy and security. Please review our Privacy Policy posted on the eZunction Platform. By using the eZunction Platform, you agree to be bound by our Privacy Policy, which is incorporated into these Terms and Conditions. Please be advised the eZunction Platform is hosted in the United States.   </p>

                                <p> If you access the eZunction Platform from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the eZunction Platform, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States. </p>

                                <p> Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the eZunction Platform as quickly as is reasonably practical.</p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 11. Reviews, Ratings and Feedback</h3>
                                </div>
                                <p> All the ratings, feedbacks, comments as provided by the customer to a service provider or vice versa, indicates the opinions of the users of the eZunction platform and they are in no manner/method or mode represented and/or endorsed by the eZunction platform and hence eZunction cannot be held responsible/liable for such feedback/comments or for any warranties or for any claims/damages or any sort of losses hereof on the use of the eZunction platform. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 12. External links and content</h3>
                                </div>
                                <p> The eZunction Platform may contain (or you may be sent via the Site) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content").  </p>

                                <p> Any references to the Third Party Content that is available for access to the general public  external/3rd party sites, that is provided on the eZunction platform, is just for information purposes and is included verbatim, without any explicit or implicit representations/endorsements/recommendations made by the eZunction platform. Such content/information is not owned/managed/maintained by the eZunction platform. The users of the eZunction platform will use their discretion to follow any instructions/guidelines/steps on those sites and takes all the liability and responsibility of using such/content/material/articles/blogs/videos etc. and not hold eZunction responsible or accountable for any damages or losses. </p>

                                <p> The Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.  </p>

                                <p> Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms and Conditions no longer govern.  </p>

                                <p> You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Site or relating to any applications you use or install from the Site. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.  </p>

                                <p> You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 13. Copyright infringements, Digital Millennium Copyright Act (DMCA) Notice and Policy:   </h3>
                                </div>
                                <p> We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any copyright you own or control, please immediately notify us using the contact information provided on the eZunction platform (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an attorney.] </p>

                                <div className="gc-left">
                                    <div className="gcl-item">
                                        <h4> a. Notifications: </h4>
                                        <p> All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include the following information: </p>

                                        <ul>
                                            <li> A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; </li>

                                            <li>identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Site are covered by the Notification, a representative list of such works on the Site;</li>
                                            <li>identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; </li>
                                            <li>information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted;</li>
                                            <li>a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; </li>
                                            <li>a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.</li>
                                        </ul>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> b. Counter Notification: </h4>
                                        <p> If you believe your own copyrighted material has been removed from the Site as a result of a mistake or misidentification, you may submit a written counter notification to [us/our Designated Copyright Agent] using the contact information provided below (a “Counter Notification”).  </p>

                                        <p> To be an effective Counter Notification under the DMCA, your Counter Notification must include substantially the following: </p>

                                        <ul>
                                            <li> identification of the material that has been removed or disabled and the location at which the material appeared before it was removed or disabled;  </li>

                                            <li> a statement that you consent to the jurisdiction of the Federal District Court in which your address is located, or if your address is outside the United States, for any judicial district in which we are located;  </li>

                                            <li> a statement that you will accept service of process from the party that filed the Notification or the party's agent;  </li>

                                            <li>your name, address, and telephone numbe </li>
                                            <li> a statement under penalty of perjury that you have a good faith belief that the material in question was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; </li>
                                            <li> your physical or electronic signature.</li>
                                        </ul>

                                        <p> If you send us a valid, written Counter Notification meeting the requirements described above, we will restore your removed or disabled material, unless we first receive notice from the party filing the Notification informing us that such party has filed a court action to restrain you from engaging in infringing activity related to the material in question. </p>

                                        <p> Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable for damages, including costs and attorney's fees. Filing a false Counter Notification constitutes perjury. </p>

                                        <div className="format-box">
                                            <p>Designated Copyright Agent</p>

                                            <p>[Name]</p>

                                            <p>Attn: Copyright Agent</p>

                                            <p>[Address]</p>

                                            <p>[City, State Zip]</p>

                                            <p>[email]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 14. eZUNCTION Copyright </h3>
                                </div>
                                <p> The eZunction platform and the information, design, layout and eZunction proprietary content are protected by eZunction trademark, unfair competition, and other laws and shall not be reproduced/recreated/copied or imitated in whole or in part, unless mutually agreed in writing with eZunction LLC. This includes but not limited to logo, pictures/graphics, videos, animations, sound or image on the eZunction Platform. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 15. Indemnification</h3>
                                </div>
                                <p> You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Content; (2) use of the Site; (3) breach of these Terms and Conditions; (4) any breach of your representations and warranties set forth in these Terms and Conditions; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with whom you connected via the Site.  </p>


                                <p> Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 16. Arbitration and Dispute Resolution</h3>
                                </div>
                                <p> Resolution methods of any dispute between you and the eZunction platform or any claim or controversy arising out of or relating to use of the eZunction platform (including any alleged breach of these Terms and Conditions) shall be, exclusively the binding arbitration administered by the American Arbitration Association, except as explicitly stated by the applicable federal or state law. You will not under any circumstances commence, participate in or maintain against eZunction any class action, class arbitration, or other such proceedings or actions. </p>

                                <p> Notification of Rights: By using the eZunction platform in any manner, you agree to the above arbitration agreement. In doing so, you relinquish your rights to go to court to assert or defend any claims between you and eZunction. You hereby also relinquish your rights to participate in a class action or other class proceedings. An assigned arbitrator (not a judge or jury) will determine your. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can. Arbitrations are typically simpler and more streamlined than court proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event that this arbitration agreement is for any reason held to be unenforceable, any litigation against eZunction may be commenced only in the applicable federal or state courts. You hereby irrevocably consent to the jurisdiction of those courts for such purposes.</p>

                                <p> These Terms and Conditions, and any dispute between you and eZunction, shall be governed by the applicable laws of the state without regard to principles of conflicts of law, provided that this arbitration agreement shall be governed by the Federal Arbitration Act.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 17. Term and Termination</h3>
                                </div>
                                <p>  These Terms and Conditions shall remain in full force and effect while you use the eZunction Platform. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE eZunction Platform (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.  </p>

                                <p> If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.  </p>

                                <p> In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 18. Modifications and Interruptions </h3>
                                </div>
                                <p> We reserve the right to change, modify, or remove the contents of the eZunction Platform at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the eZunction Platform without notice at any time.  </p>
                                <p> We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the eZunction Platform.  </p>

                                <p> We cannot guarantee the eZunction Platform will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the eZunction Platform, resulting in interruptions, delays, or errors.  </p>

                                <p> We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the eZunction Platform at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the eZunction Platform during any downtime or discontinuance of the Site.  </p>

                                <p> Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the eZunction Platform or to supply any corrections, updates, or releases in connection therewith. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 19. Governing Law</h3>
                                </div>
                                <p> These Terms and Conditions and your use of the eZunction Platform are governed by and construed in accordance with the laws of the applicable state jurisdiction and applicable to agreements made and to be entirely performed within the applicable State, without regard to its conflict of law principles.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 20. Corrections</h3>
                                </div>
                                <p> There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Site, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the eZunction Platform at any time, without prior notice. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 21. Disclaimer</h3>
                                </div>
                                <p> THE eZUNCTION PLATFORM IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE eZUNCTION PLATFORM AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE eZUNCTION PLATFORM AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE eZUNCTION PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE eZUNCTION PLATFORM AND WE WILL NOT HAVE ANY SORT OF LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE eZUNCTION PLATFORM, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE eZUNCTION PLATFORM, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE eZUNCTION PLATFORM BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE eZUNCTION PLATFORM. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE eZUNCTION PLATFORM, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.  </p>

                                <p> AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 22. Limitations of Liability</h3>
                                </div>
                                <p> IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OR BREACH OF ELECTRONIC DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE eZUNCTION PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 23. Electronic Communications, Transactions and Signatures</h3>
                                </div>
                                <p>  Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the eZunction Platform, satisfy any legal requirement that such communication be in writing. </p>

                                <p> YOU HEREBY AGREE TO THE USE OF ELECTRONIC CONSENT/SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE eZUNCTION PLATFORM.  </p>

                                <p> You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.  </p>

                                <p> Further, you agree that eZunction platform may send you informational text (SMS), email and eZunction platform related push notifications/messages as part of the normal operational process of eZunction platform. You may contact eZunction support team, to opt you out of such messaging activities. However, by opting out you acknowledge that your use of eZunction platform services may be impacted. eZunction will have full access to view, store, share such messages generated from the eZunction platform.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 24. California Users and Residents</h3>
                                </div>
                                <p> You, as a service provider are liable to ensure that you have a valid operating business license as per the California licensing rules.  </p>

                                <p> If any complaint for service providers is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at <a href="tel:(800) 952-5210" className="orange-text"> (800) 952-5210 </a> or <a href="tel:(916) 445-1254" className="orange-text"> (916) 445-1254 </a>. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> 25. MISCELLANEOUS</h3>
                                </div>
                                <p> These Terms and Conditions and any policies or operating rules posted by us on the eZunction platform constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.  </p>

                                <p> These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.  </p>

                                <p> If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.  </p>

                                <p> There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site. You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.  </p>

                                <p> You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the parties hereto to execute these Terms and Conditions. </p>

                                <p> © 2020 eZunction LLC. All rights reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default TermsConditions;
