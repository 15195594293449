import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Connection from '../../../Utility/Socket'
import { APIService } from "../../../Components/Apis/APIServices";
import { dateWithFormate } from '../../../Utility/common';
import Dialogue from '../../../Components/Common/Dialogue';

import { useHistory } from 'react-router-dom';


let subscription;

const Notification = () => {
  const history = useHistory();
    const [notifications, setNotifications] = useState([]);
     ////////////////////////red icon show
     const [showRedIcon, setShowRedIcon] = useState(localStorage.getItem("_bi") === "false" ? false : true)


     const [dialogueState,setDialogueState] = useState({
      type : '',
      show : false,
      message : '',
      // link:""
  })


    

    useEffect(()=>{

        if(localStorage.getItem('_tk') || localStorage.getItem('_otp_tk'))
        Connection.connect();
        subscription = Connection.subscribe(`notification`, (message)=>{
          // console.log("notification ////////", message)
          // setNotifications(notifications.push(message?.data))
          setDialogueState({
            // type : getNotificationHeading(message?.data) || "Alert",
            type:"Alert",
            show:true,
            message : message.data.description,
           
          })
          
          localStorage.setItem("_bi", true)

          setShowRedIcon(true)
        
          setNotifications((prev) => { 
            return [...prev, message?.data];
          })

          // sessionStorage.setItem("all_not", JSON.stringify([...notifications, message?.data]))

          
        });

    }, [])

    // console.log("notification //////// first")

    const getNotificationHeading = (message)=>{
      console.log("dsadasdasdasdasda",message)
      if(message?.description.includes("Congratulations!")){
        return "Congratulations"
      }
      // else if(message?.description.includes("Congratulations!"))
    }
    
    
    useEffect(() => {
     getNotifications()
    }, []);

    const getNotifications = ()=>{

      // function isJsonString(str) {
      //   try {
      //     JSON.parse(str);
      //   } catch (e) {
      //     return false;
      //   }
      //   return true;
      // }

     

  //     if(sessionStorage.getItem("all_not") !== null && sessionStorage.getItem("all_not") !== undefined){

      
  
  // if(isJsonString(sessionStorage.getItem("all_not"))){
  //   setNotifications(JSON.parse(sessionStorage.getItem("all_not")) || [])
  // }else{
    
    APIService.notifications()
    .then((res) => {
      if(res.status === 200){
        setNotifications(res.data);
        //   setSelectedNotification(res.data[0] || []);
        sessionStorage.setItem("all_not", JSON.stringify(res.data))
      }
     
    })
    .catch((err) => {});
//   }
// }else{

//       APIService.notifications()
//       .then((res) => {
//         setNotifications(res.data);
//       //   setSelectedNotification(res.data[0] || []);
//       sessionStorage.setItem("all_not", JSON.stringify(res.data))
//       })
//       .catch((err) => {});
//     }
    }

    const deleteNotification = (e,id) =>{
      e.stopPropagation();
      // console.log("wwwwwwwwwwwwwww", id)
      APIService.notificationsDelete({id: id})
      .then((res) => {
        getNotifications()
        // APIService.notifications()
        // .then((res) => {
        //   setNotifications(res.data);
        // //   setSelectedNotification(res.data[0] || []);
        // sessionStorage.setItem("all_not", JSON.stringify(res.data))
        // })
        // .catch((err) => {});
      }
          )
      .catch((err) => { });

     }


     const navigateTo = (id) =>{
      history.push({
        pathname:'/notifications',
        state: { notificationId: id, },
      })
     }



    

// console.log("lllllllllllllllllllllleeeeee", localStorage.getItem("_bi"), showRedIcon)
    return (
      <>
      <Dialogue state={dialogueState} setState={setDialogueState} />
        <li className="bell-btn d-xl-inline-block mr-3 mr-md-0">
          <button
            className="dropdown-toggle btn bell-nav-toggle"
            type="button"
            id="notification-menu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={()=>{
              localStorage.setItem("_bi", false)
              setShowRedIcon(false)
            }}
          >
            <i className="fas fa-bell"></i>{" "}
            {showRedIcon ? <span className="notif-circle"></span> : null}
          </button>
          <div className="dropdown-menu" aria-labelledby="notification-menu">
            <div className="dropdown-menu-inner">
              <div className="notify-drop-head">
                <h4> Notifications </h4>
                <Link className="notify-markRead-btn" to="/notifications">
                  {" "}
                  See all{" "}
                </Link>
              </div>

              <div className="notify-list">
                {notifications &&
                  notifications
                    .slice(notifications.length - 4, notifications.length)
                    .reverse()
                    .map((item, index) => (
                      <div
                        // to={{
                        //   pathname: "/notifications",
                        //   state: {
                           
                        //     notificationId: item?.id,
                        //   },
                        // }}
                        onClick={(e)=> navigateTo(item?.id)}
                        key={item?.description}
                      >
                        <div
                          className="notify-item unread-notify"
                          // data-toggle="modal"
                          // data-target="#notification-modal"
                        >
                          <div className="ni-icon">
                            <span>
                              {" "}
                              <i className="fas fa-bell"></i>{" "}
                            </span>
                          </div>
                          <div className="ni-content">
                          <p>
                                {" "}
                                {item?.description.includes("Congratulations!") ? 
                                <>
                                <strong>
                                  {" "}
                                  {item?.description?.split("!")[0]}!{" "}
                                </strong>{" "}
                                {item?.description
                                  ?.split("!")[1]
                                  ?.substring(0, 45)}
                                ...
                                </>
                                :
                                <>
                                {item?.description?.substring(0, 70)}
                                ...
                                </>

}
                              </p>
                            {/* <span className="np-time">
                              {" "}
                              {item?.updated_at}{" "}
                            </span> */}
                            <span className="np-time">
                                {" "}
                                {dateWithFormate(item?.updated_at && item?.updated_at.split(" ")[0].split("-").reverse().join("-"))}{" "}
                                &nbsp;
                                {dateWithFormate(item?.updated_at && item?.updated_at.split(" ")[1],
                                  "h:mm A",
                                  "HH:mm:ss")
                                }    
                              </span>
                            <button type="button" className="notify-delete" onClick={(e)=>deleteNotification(e,item?.id)}>
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                {/* <div className="notify-item unread-notify" data-toggle="modal" data-target="#notification-modal">
                                <div className="ni-icon">
                                    <span> <i className="fas fa-bell"></i> </span>
                                </div>
                                <div className="ni-content">
                                    <p> You have accepted the Quote <strong> ID 63587 </strong> for your Service Request...  </p>
                                    <span className="ni-time"> 3 Days Ago </span>
                                    <button type="button" className="notify-delete"><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </li>
      </>
    );
}

export default Notification;
