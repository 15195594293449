import React from "react";
import { Redirect } from "react-router-dom";
class Auth {
  constructor() {
    const Token = localStorage.getItem("_tk");
    Token ? (this.authenticated = true) : (this.authenticated = false);
    this.getAuthUserData = [];
  }
  RedirectToLogin() {
    return <Redirect to="/login" />;
  }
  RedirectToRegister() {
    return <Redirect to="/register" />;
  }
  getAccessToken() {
    return localStorage.getItem("_tk");
  }
  isAuthLogIn() {
    let user = localStorage.getItem("_cu");
    let token = localStorage.getItem("_tk");
    console.log("token", token);
    if (user && token) {
      return true;
    } else {
      return false;
    }
  }
  getUserDetails() {
    return JSON.parse(localStorage.getItem("_cu"));
  }
}

export default new Auth();
