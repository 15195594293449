import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import images from '../../Utility/Images';
import { APIService } from '../../Components/Apis/APIServices';

const PeopleVoiceSlider = () => {

    const [data, setData] = useState([])

    useEffect(()=>{
        APIService.peopleVoice()
        .then((res) => {
            // console.log("kkkkkkkkkkkkklllllllllllllll", res.data.data)
            setData((res && res.data && res.data.data) || [])
        
        })
        .catch((err) => {});
  
    },[])



const PreviewsArrow=(props)=>{
    const { className, style, onClick } = props;
    return (
        <>
            <button type='button' class='prev-control slick-arrow-control mr-5' onClick={onClick} style={{zIndex:"2"}}><i class='far fa-chevron-left'></i></button>
        </>
    );
}
const NextArrow=(props)=>{
    const { className, style, onClick } = props;
    return (
        <>
            <button type='button' class='next-control slick-arrow-control' onClick={onClick}><i class='far fa-chevron-right'></i></button>
        </>
    );
}


    const settings = {
         //dots: true,
         infinite: true,
         speed: 500,
         slidesToShow: 2,
         slidesToScroll: 1,
        //  autoplay: true,
        //  autoplaySpeed: 2000,
         arrows: true,
         prevArrow:<PreviewsArrow/>,
         nextArrow:<NextArrow/>,
         responsive: [
             {
               breakpoint: 1024,
               settings: {
                 slidesToShow: 1,
                 arrows: true,
               }
             },
         ]
    };
    return (
        <>
            <Slider {...settings} style={{position: "inherit"}}>

                {data.map((item, index)=>(
                    <div className="voice-item" key={item.id}>
                    <div className="voice-img">
                        <img src={item.image_thumbnail} className="img-fluid" alt="" />
                        <div className="voice-videoPlay-block">
                            <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(item.image_src)}><i className="fas fa-play"></i></a>
                            {/* <video className="" width="100%" height="100%" loop muted autoPlay controls style={{objectFit:"cover"}}>
                    <source src={images.startEarningNow} type="video/mp4"/>
                  
                    Your browser does not support HTML5 videos
                </video> */}
                        </div>
                    </div>
                </div>
                ))}

                

           

            </Slider>
        </>
    );
}

export default PeopleVoiceSlider;
