import { useEffect } from "react";
import { useState } from "react";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import { Link, useParams, useHistory } from "react-router-dom";
import Auth from "../Auth/Auth";
import { APIService } from "../Components/Apis/APIServices";
import ConfirmQuoteSPModal from "./ConfirmQuoteSPModal";
import AcceptQuoteModal from "../Components/Modals/AcceptQuoteModal";
import CommentModel from "../Components/Modals/CommentModel";
import WithdrawModal from "../Components/Modals/WithdrawModal";
import Dialogue from "../Components/Common/Dialogue";
import { dateWithFormate } from "../Utility/common";

import MyAccountMenu from "../Components/Common/MyAccountMenu";
import { Scrollbars } from "react-custom-scrollbars";
import config from "../Components/Apis/config";

import Rating from './Rating'
import GetInTouch from "../Components/Common/GetInTouch";


const QuotesDetailsSP = (props) => {
  const requestData = props?.location?.state?.pageData

  const [pageData, setpageData] = useState([]);
  const [quoteData, setQuoteData] = useState({});

  const [withdrawalReason, setWithdrawalReason] = useState([]);

  const userDetail = Auth.getUserDetails();
  const { id: requestId } = useParams();

  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });

  const [ongoingCardShow, setOngoingCardShow] = useState(false)

  const [indexs, setIndexs] = useState("")


  const history = useHistory();

  useEffect(() => {
    if (requestId) {
      fetchQuotes()

    }
  }, [requestId, APIService]);

  const fetchQuotes = ()=>{
    APIService.fetchQuoteData(requestId, "2")
    .then((res) => {
      if (res.status === 200) {
        let quotes = res.data
          ? res.data.quote
            ? res.data.quote.length
              ? res.data.quote
              : []
            : []
          : [];
        // let filterData = quotes.filter(
        //   (val) => val.user_id === userDetail.id
        // );
        setpageData(quotes);
        setQuoteData(quotes.length ? indexs ? quotes[indexs] : quotes[0] : {});
      }
    })
    .catch((e) => console.log("close error", e));
  } 
  useEffect(()=>{
    const interval = setInterval(() => {
      fetchQuotes()
      // console.log("rrrrrrrrrrrrrrrtttttttttttttt", activeTab)
    }, 30 * 1000);

    return () =>{
      clearInterval(interval)
    }
  },[indexs])


  useEffect(() => { 
    APIService.srWithdrawReason()
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReason(res.data);
        }
      })
      .catch((e) => console.log("error", e));
  },[])

  const handleAcceptQuote = () => {
    APIService.fetchAcceptQuote(quoteData.id)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      })
      .catch((e) => console.log("close error", e));
  };

  const handleRejetQuote = () => {
    APIService.fetchRejectQuote(quoteData.id)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      })
      .catch((e) => console.log("close error", e));
  };

  const [loader, setLoader] = useState(false)

  const confirmAPI = (e, account_number, bankAcs) => {
    e.preventDefault()
    document.getElementById("confirm-quote-modal").click()
    setShowConfirmModal(false)
    
    // console.log("rrrrrrrrrrrrrrr", account_number, bankAcs)
    if (quoteData.id && requestId) {
      let data = {
        // account_holder_name: (bankAcs.filter((i)=> i.account_number === account_number)[0] && bankAcs.filter((i)=> i.account_number === account_number)[0].account_name) || "",
        //     routing_number: (bankAcs.filter((i)=> i.account_number === account_number)[0] && bankAcs.filter((i)=> i.account_number === account_number)[0].routing_number) || "",
        //     account_number: (bankAcs.filter((i)=> i.account_number === account_number)[0] && bankAcs.filter((i)=> i.account_number === account_number)[0].account_number) || "",
        //     payment_id: (bankAcs.filter((i)=> i.account_number === account_number)[0] && bankAcs.filter((i)=> i.account_number === account_number)[0].id) || "",
        payment_id: account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? "" : 1,
          bank_token: account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? account_number : ""
          
          
      }

      setLoader(true)
      APIService.confirmAPIcall(quoteData.id, account_number, data)
        .then((res) => {
          setLoader(false)
          if (res.status === 200) {
            // document.getElementById("notifiaction-request-modal").click()
            // console.log("eeeeeeeeeeeeeeeee res",res)
            // window.location.reload();
            setDialogueState({
              type: "Success",
              show: true,
              // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
              message:`Congratulations! You have successfully confirmed the quote ${quoteData.id} for the service request ${requestId}. Please complete the work as scheduled.`,
              back : history.goBack
            });
            
    //         APIService.addBankAccount({
    //           bank_name: (bankAcs.filter((i)=> i.account_nick === account_nick)[0] && bankAcs.filter((i)=> i.account_nick === account_nick)[0].bank_name) || "", 
    //           account_type: (bankAcs.filter((i)=> i.account_nick === account_nick)[0] && bankAcs.filter((i)=> i.account_nick === account_nick)[0].account_type) || 2,
    //           account_nick: (bankAcs.filter((i)=> i.account_nick === account_nick)[0] && bankAcs.filter((i)=> i.account_nick === account_nick)[0].account_nick) || "",
    //           isprimary: (bankAcs.filter((i)=> i.account_nick === account_nick)[0] && bankAcs.filter((i)=> i.account_nick === account_nick)[0].isprimary) || 0,            
    //         })
    // .then(res=>{
			
		// })
    // .catch(err=>{})
          }else{
            // console.log("eeeeeeeeeeeeeeeee res else",res)
            setDialogueState({
              type: "Failed",
              show: true,
              // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
             message:(res && res.response && res.response.data && res.response.data.message) || null,
            });
          }
        })
        .catch((e) => { 
          setLoader(false)
          // console.log("eeeeeeeeeeeeeeeee catch",e)
          // document.getElementById("notifiaction-request-modal").click()
           setDialogueState({
             type: "Failed",
             show: true,
             message: (e && e.response && e.response.data && e.response.data.message) || null,
           });
        });
    } else {
      alert("No quote availble.");
    }
  };

  // console.log("props . location", requestData.sr_status)

  const renderButtons = (data) => {
    if (userDetail.role_type === 2 || userDetail.role_type === 3) {
      switch (data.quote_status) {
        case 1:
          return (
            <div>
               
              <div
                className="btn btn-red btn-md ardCat-submitQ-btn"
                data-toggle="modal"
                data-target="#withdraw-qoute-modal"
              >
                Withdraw Quote
              </div>
              
              <Link
                to={{
                  pathname: "/update-quote",
                  state: { quoteData: quoteData },
                }}
                // className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
              >
                <div
                  className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
                  // data-toggle="modal"
                  // data-target="#notifiaction-request-modal"
                >
                  Update Quote
                </div>
              </Link>
            </div>
          );
        case 2:
          return (
            <div>
              <div
                className="btn btn-red btn-md ardCat-submitQ-btn"
                data-toggle="modal"
                data-target="#withdraw-qoute-modal"
              >
                Withdraw Quote
              </div>
              
              <div
                className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
                data-toggle="modal"
                data-target="#confirm-quote-modal"
              >
                Confirm Quote
              </div>
              <Link
                to={{
                  pathname: "/update-quote",
                  state: { quoteData: quoteData },
                }}
                // className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
              >
                <div
                  className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
                  // data-toggle="modal"
                  // data-target="#notifiaction-request-modal"
                >
                  Update Quote
                </div>
              </Link>
            </div>
          );
        case 3:
          return (
            [5,10,7,8,6,9,11,12,13,14,2,15].includes(requestData.sr_status) ? null :
            <div
              className="btn btn-red btn-md ardCat-submitQ-btn"
              data-toggle="modal"
              data-target="#withdraw-qoute-modal"
            >
              Withdraw Quote
            </div>
            
          );
        case 5:
          return (
            <Link
              to={{
                pathname: "/update-quote",
                state: { quoteData: quoteData },
              }}
              // className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
            >
              <div
                className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
                // data-toggle="modal"
                // data-target="#notifiaction-request-modal"
              >
                Update Quote
              </div>
            </Link>
          );
        default:
          return null
      }
    }
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#FF8805",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(()=>{
    if(localStorage.getItem("CMO")){
      if(localStorage.getItem("CMO") === "true"){
        setShowConfirmModal(true)
        localStorage.removeItem("CMO")
      }else{
        setShowConfirmModal(false)
        localStorage.removeItem("CMO")
      } 
    }
       
  },[])

  useEffect(()=>{
    return() =>{
      localStorage.removeItem("CMO")
      localStorage.removeItem("bankToken")
    }
    

    
  },[])


  // const [loader, setLoader] = useState(false)

  return (
    <>
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Header />
      <ConfirmQuoteSPModal
        title={"Confirm Quote"}
        body={"Are you sure you want to confirm this quote?"}
        secondaryBtnText="No"
        primaryBtnText="Yes"
        handleClick={confirmAPI}
        quoteData={quoteData}
        showHide = {showConfirmModal}
        eleId="confirm-quote-modal"
        loader={loader}
      />

      {/* <AcceptQuoteModal
        title="Confirm Quote"
        // body={<EnterCardDetails /> }
        secondaryBtnText="Cancel"
        primaryBtnText="Submit"
        handleClick={handleAcceptQuote}
        show={true}
      /> */}
      <WithdrawModal quoteData={quoteData} />
      <CommentModel requestData={quoteData} />

      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src="../images/clip-bg.svg"
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  Quotes <span> Details </span>
                </h1>
                <div className="req-text-gh">
                  {" "}
                  Request ID :{" "}
                  <Link
                    // to="/my-request"
                    to={{
                      pathname: "/my-request",
                      state: {
                        requestId: requestId,
                        activeTab: (props && props?.location && props?.location?.state && props?.location?.state?.activeTab) || "ongoing",
                        requestDetails: (props && props?.location && props?.location?.state && props?.location?.state?.pageData) || {},
                        // activeTab: "ongoing"
                      },
                    }}
                    className="orange-text"
                  >
                    {requestId || ""}
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>

      <section className="requestDetail-section">
        <div className="container">
          <div className="row">
            <div className={`col-xl-4 ${ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div className="ardCat-list wow fadeInUp">
                <Scrollbars renderThumbVertical={renderThumb}>
                  {pageData.length
                    ? pageData.map((data, index) => {
                        return (
                          <div
                            className="ard-cat-item hnc-item service-cat-item"
                            style={{ width: "96%" }}
                            onClick={() => {
                              setQuoteData(data)
                              setOngoingCardShow(true)
                              setIndexs(index)
                            }}
                          >
                            <div
                              className={`service-cat-inner myRq-link ${
                                data.id === quoteData.id ? "open-quote" : ""
                              }`}
                            >
                              <div className="sc-head">
                                <div className="left">
                                  <img
                                    src={
                                      config.endpoints.image_path +
                                      data?.user?.profile_photo
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="right">
                                  <h3>
                                    {" "}
                                    {data?.user?.first_name}{" "}
                                    {data?.user?.last_name}{" "}
                                  </h3>
                                  <div className="rating">
                                  <Rating rating={data?.user?.rating} />
                                  <span className="rating-count"> {data?.user?.rating}{" "}
                                  </span>
                                  </div>
                                </div>
                              </div>

                              <div className="sc-body">
                                <div className="scb-top">
                                  <span className="service-type">
                                    {data?.quotestatus?.name}
                                  </span>
                                </div>
                                <div className="scb-bottom">
                                  <span className="sc-name">
                                    {" "}
                                    {/* ${data.labor_quote_amount}{" "} */}
                                    $
                                    {data ? (((parseFloat(data.labor_quote_amount) || 0) + (parseFloat(data.material_quote_amount) || 0) )).toFixed(2) : "--"}
                                  </span>
                                  <span>
                                    {data?.quote_service_date
                                      ? dateWithFormate(
                                          data?.quote_service_date
                                            .split("T")[0]
                                            .split("-")
                                            .reverse()
                                            .join()
                                        )
                                      : null}{" "}
                                    &nbsp;
                                    {dateWithFormate(
                                      quoteData?.timeGet?.start_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}{" "}
                                    -{" "}
                                    {dateWithFormate(
                                      quoteData?.timeGet?.end_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "No Data"}
                </Scrollbars>
              </div>
            </div>

            <div className={`col-xl-8 ${!ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div
                id="quoteDetailsTab-1"
                className="myRq-tab-content current-request"
              >
                <div className="ardCat-detail-right">
                  <div className="ardCatD-head">
                  <div className="ardCat-back-block d-block d-xl-none">
                              <button type="button" className="ardCat-back-btn" 
                               onClick={()=>{setOngoingCardShow(false) 
                               }} >
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
                            </div>
                    <div className="left">
                      <h2> Quote ID : {quoteData?.id} </h2>
                      <span> {quoteData?.quotestatus?.name} </span>
                    </div>
                    <div className="right">
                          <Link
                            to={{
                              pathname: "/customer-details/" + quoteData?.user?.id ,
                              //   state:{
                              //       // filterSrs: filterSrs,
                              //       // completeFilterData: completeFilterData
                              //       serviceType:serviceType,
                              //       serviceSubCat:serviceSubCat,
                              //       serviceCat:serviceCat
                              //     }
                            }}
                            // className="service-cat-inner"
                            data-rel="grd-1"
                          >
                      <div className="img">
                        <img
                          src={
                            config.endpoints.image_path +
                            quoteData?.user?.profile_photo
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="ardCatuser-d">
                        <h3>
                          {" "}
                          {quoteData?.user?.first_name}{" "}
                          {quoteData?.user?.last_name}{" "}
                        </h3>
                        <div className="rating">
                        <Rating rating={quoteData?.user?.rating} />
                                  <span className="rating-count"> {quoteData?.user?.rating}{" "}
                                  </span>
                        </div>
                      </div>
                      </Link>
                        </div>
                  </div>

                  

                  <div className="ardCatD-body">
                    <div className="ardCatD-inner-body">
                      <div className="ardCatD-location-time">
                        <div className="ardCatD-lt-inner">
                          <div className="catD-lt-item">
                            <div className="icon">
                              <img
                                src="/images/quote-icon.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="it-content">
                              <span className="itl"> Quote Amount </span>
                              <span className="itv">
                                {" "}
                                ${quoteData?.labor_quote_amount}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="catD-lt-item">
                            <div className="icon">
                              <img
                                src="/images/calendar-big-icon.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="it-content">
                              <span className="itl">
                                {" "}
                                Service Date and Time{" "}
                              </span>
                              <span className="itv white-space-nowrap">
                                {" "}
                                {/* {quoteData?.quote_service_date} */}
                                {quoteData?.quote_service_date
                                  ? dateWithFormate(
                                      quoteData?.quote_service_date
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join()
                                    )
                                  : null}
                                &nbsp;(
                                {dateWithFormate(
                                  quoteData?.timeGet?.start_time,
                                  "hA",
                                  "HH:mm:ss"
                                )}{" "}
                                -{" "}
                                {dateWithFormate(
                                  quoteData?.timeGet?.end_time,
                                  "hA",
                                  "HH:mm:ss"
                                )}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="ardCatD-it-action text-right">
                          {quoteData ? 
                          ((props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 11 && (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 15 && (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 2 && (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 9) ?
                           renderButtons(quoteData) : null : null}
                        </div>
                      </div>
                      <div className="ardCatD-right-space">
                        <div className="ardCatD-content">
                          <h3>  {quoteData.material_included_flag ? "Material Amount" : "Material Included"} </h3>
                          <p>
                            {" "}
                            {quoteData.material_included_flag
                              ? `$ ${quoteData?.material_quote_amount || 0}`
                              : "No"}{" "}
                          </p>
                        </div>

                        {/* <div className="ardCatD-content">
                          <h3> Material Amount </h3>
                          <p>
                            {" "}
                            {quoteData.material_included_flag
                              ? `$${quoteData?.material_quote_amount}`
                              : "No"}{" "}
                          </p>
                        </div> */}

                        <div className="ardCatD-content">
                          <h3> Services Included </h3>
                          <p>{quoteData?.services_included}</p>
                        </div>
                        {quoteData?.warranty_days ? 
                        <div className="ardCatD-content">
                          <h3> Warranty Days Provided </h3>
                          <p>{quoteData?.warranty_days}</p>
                        </div>
                        :
                        null}
                        {quoteData?.additional_comments ? 
                        <div className="ardCatD-content">
                          <h3> Additional Comments </h3>
                          <p>{quoteData?.additional_comments}</p>
                        </div>
                        :
                        null}

                        <div className="ardCatD-content">
                          <h3> Comments </h3>
                          {quoteData.quotecomment
                            ? quoteData.quotecomment.length
                              ? quoteData.quotecomment.map((comment, index) => {
                                  return (
                                    <div className="ardComment-item mt-2" key ={index}>
                                      <p>{comment.comment}</p>
                                      <div className="ardc-bottom mt-0">
                                        <span>
                                          {" "}
                                          <i className="far fa-user"></i>
                                          {/* {"John"}{" "} */}
                                          {comment?.user}
                                        </span>
                                        <span>
                                          {" "}
                                          <i className="far fa-clock"></i>{" "}
                                          {dateWithFormate(
                                            comment.created_at.split(" ")[1],
                                            "hh:mm A",
                                            "HH:mm:ss"
                                          )}
                                          ,
                                          {dateWithFormate(
                                            comment.created_at.split(" ")[0].split("-").reverse().join()
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })
                              : null
                            : null}
                        </div>

                        {quoteData?.quotestatus?.id === 6 ?
                        <div className="ardCatD-content">
                          <h3> Disable Comments </h3>
                          <p>{(quoteData && quoteData?.spnoshow && quoteData?.spnoshow.no_show_reason) || ""}</p>
                        </div>
                        :
                        null
}

{quoteData?.quotestatus?.id === 4 ? (
                     <>
                     <div className="ardCatD-content">
                         <h3> Withdrawal Reason </h3>
                         <p>
                         {withdrawalReason.length > 0 && quoteData?.quote_withdrawal_reason_id && (withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)] !== undefined || withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)] !== null) ? 
                           withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)].name || "--"
                         
                         :
                         null
                         }
                           {/* {quoteData?.quote_withdrawal_reason_id || "--"} */}
                         </p>
                       
                     </div>
                     <div className="ardCatD-content">
                         <h3> Withdrawal Comment</h3>
                         <p>
                           {quoteData?.quote_withdrawal_comments || "--"}
                         </p>
                       </div>
                   
                     </>
                 ) : null}

{quoteData?.quotestatus?.id === 5 ?
                          <>
                          <div className="ardCatD-content">
                            <h3> Rejection Reason </h3>
                            <p>{(quoteData && quoteData?.rejection && quoteData?.rejection.name) || ""}</p>
                          </div>
                           <div className="ardCatD-content">
                           <h3> Rejection Comments </h3>
                           <p>{(quoteData && quoteData?.quote_rejection_comments) || ""}</p>
                         </div>
                        </>
                          :
                          null
                        }


                      </div>

                      {userDetail.role_type === 2 ||
                      userDetail.role_type === 3 ? (
                        [1, 2, 3, 5].includes(quoteData.quote_status) && ![15,2,9].includes(props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) ? (
                          <div class="ardCat-widget">
                            <a
                              href=""
                              data-toggle="modal"
                              data-target="#addComment-modal"
                            >
                              <img
                                src="/images/comment-icon.svg"
                                class="img-fluid"
                                alt=""
                              />
                              <span> comment </span>
                            </a>
                            {/* <a href="my-chat.html">
                              <img
                                src="/images/chat-icon.svg"
                                class="img-fluid"
                                alt=""
                              />
                              <span> chat </span>
                            </a> */}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </>
  );
};

export default QuotesDetailsSP;
