import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { APIService } from '../Components/Apis/APIServices';
import Header from '../Components/Common/Header';

import images from '../Utility/Images';

export default function ActivateAccount() {
    const {
        iv,
        ed
    } = useParams()
    const [loading, setLoading] = useState(true)
    const [msg,setMsg] = useState("Activating your account,Please wait...");
    useEffect(()=>{
        APIService.activateAccount({
            iv,ed
        }).then(response=>{
            console.log(response)
            setLoading(false)
        }).catch(err=>{
            setMsg("Sorry, your activation link expired. We have sent you a new activation link. Please check your email.")
            //setLoading(false)
        })
    },[iv])
    return (
        <div>
            <Header/>
            	<section className="auth-success-section">
		<div className="container h-100">
			<div className="row h-100">
				<div className="col-12 col-lg-6 mx-auto">
					{
                        !loading ? <div className="success-inner">
						<div className="success-content">
							<h1> Congratulations! </h1>
							{/* <p> Your account has been successfully activated on the <span> eZunction </span> <span classNameName="d-none d-md-block" /> network. </p> */}
                            <p> Your account has been successfully activated on the <span> eZunction </span> network. </p> 
							<div className="d-flex justify-content-center"><Link className="btn btn-red btn-sm" to="/login"> OK</Link></div> 
						</div>
						<div className="success-img">
							{/* <img src="../assets/images/thank-character.svg" className="img-fluid m-auto d-block" alt="" /> */}
                            <img src={images.thankCharacter} className="img-fluid m-auto d-block" alt="" />
						
                        </div>
					</div> 
                    :                     
<div className="success-inner">
						<div className="success-content">
							<h1 className='d-flex justify-content-center'> Alert! </h1>
							{/* <p> Your account has been successfully activated on the <span> eZunction </span> <span classNameName="d-none d-md-block" /> network. </p> */}
                            <p> {msg} </p> 
							 
						</div>
						
					</div> 
                    }
				</div>
			</div>
		</div>
	</section>
        </div>
    )
}
