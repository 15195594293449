import React from 'react';
import { Link } from 'react-router-dom';

const LoginRegister = () => {
    return (
        <>
            <li><Link to="/login" className={window.location.pathname === "/login" ? "active" : "" }> Sign In </Link></li>
            <li className="pr-0"><Link to="/register" className="cus_btn btn btn-red btn-sm"> Register </Link></li>
        </>
    );
}

export default LoginRegister;
