import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';
import MyAccountMenu from '../Components/Common/MyAccountMenu';
import images from '../Utility/Images';

const MyChat = () => {
    return (
        <>
            <Header />
            <section class="gray-hero-section db-gh-section">
                <div class="gh-clip-bg">
                    <img src={images.clipBg} class="img-fluid d-block m-auto" alt="" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="inner-caption wow fadeInUp text-center">
                                <h1 class="mb-0"> My <span> Chats </span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="db-top-menu">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <MyAccountMenu />
                        </div>
                    </div>
                </div>
            </section>

            <section class="requestDetail-section chatMain-tab-wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 messenger-tab-col">
                            <div class="messenger-list">
                                <ul class="mCustomScrollbar">
                                    <li class="selected">
                                        <div class="user-img"><img src={images.user2} alt="user" /></div>
                                        <div class="user-details">
                                            <div class="detail-left">
                                                <h5 class="user-name"> John Doe </h5>
                                                <span class="last-message-date"> 15min ago </span>
                                            </div>
                                            <div class="detail-right">
                                                <span class="last-message"> Hello </span>
                                                <span class="unread-count"> 5 </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="user-img"><img src={images.user1} alt="user" /></div>
                                        <div class="user-details">
                                            <div class="detail-left">
                                                <h5 class="user-name"> Katie </h5>
                                                <span class="last-message-date"> 15min ago </span>
                                            </div>
                                            <div class="detail-right">
                                                <span class="last-message"> Okay I will! </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="user-img"><img src={images.user3} alt="user" /></div>
                                        <div class="user-details">
                                            <div class="detail-left">
                                                <h5 class="user-name"> Dora Hines </h5>
                                                <span class="last-message-date"> 15min ago </span>
                                            </div>
                                            <div class="detail-right">
                                                <span class="last-message"> How are you </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="user-img"><img src={images.user4} alt="user" /></div>
                                        <div class="user-details">
                                            <div class="detail-left">
                                                <h5 class="user-name"> Robert Smith </h5>
                                                <span class="last-message-date"> 15min ago </span>
                                            </div>
                                            <div class="detail-right">
                                                <span class="last-message"> When you able </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="user-img"><img src={images.user5} alt="user" /></div>
                                        <div class="user-details">
                                            <div class="detail-left">
                                                <h5 class="user-name"> Dora Hines </h5>
                                                <span class="last-message-date"> 15min ago </span>
                                            </div>
                                            <div class="detail-right">
                                                <span class="last-message"> Okay please ping me </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <div class="moreChat-show">
                                    <button type="button" class="btn btn-red btn-md"> Load More </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-8 messanger-msg-col">
                            <div class="messanger-message">
                                <div class="chat-head">
                                    <div class="chatUser-left-head">
                                        <div class="ardCat-back-block d-block d-xl-none">
                                            <button type="button" class="ardCat-back-btn"> <i class="fal fa-long-arrow-left"></i> </button>
                                        </div>
                                        <div class="chatUser-img"> <img src={images.user2} alt="img" /> </div>
                                        <div class="chatHead-user-details">
                                            <h5> John Doe </h5>
                                        </div>
                                    </div>
                                    <div class="chatUser-right-head">
                                        <span>Request ID : <Link to="/my-request">25632156 </Link></span>
                                        <span> Quote ID : <Link to="/quotes-details-confirmed">25632HL156 </Link></span>
                                    </div>
                                </div>

                                <div class="chat-block mCustomScrollbar">
                                    <div class="chat-date-time">
                                        <span> Today, 11:30am </span>
                                    </div>
                                    <div class="recived-block">
                                        <div class="sent-img"> <img src={images.user3} alt="img" class="" /> </div>
                                        <div class="sent-content-block">
                                            <div class="sent-message">
                                                <p> Hello! how are you? </p>
                                            </div>
                                            <span class="sent-time"> 15min ago </span>
                                        </div>
                                    </div>

                                    <div class="sent-block">
                                        <div class="sent-img"> <img src={images.user2} alt="img" class="" /> </div>
                                        <div class="sent-content-block">
                                            <div class="sent-message">
                                                <p> I am good ! How about you?</p>
                                            </div>
                                            <span class="sent-time"> 13min ago </span>
                                        </div>
                                    </div>

                                    <div class="recived-block">
                                        <div class="sent-img"> <img src={images.user3} alt="img" class="" /> </div>
                                        <div class="sent-content-block">
                                            <div class="sent-message">
                                                <p> I am good! </p>
                                            </div>
                                            <span class="sent-time"> 10min ago </span>
                                        </div>
                                    </div>
                                </div>


                                <form class="message-typing">
                                    <input type="text" placeholder="Type here..." class="form-control" />
                                    <button class="send-btn hoverBlue-btn"><img src={images.sendIcon} class="img-fluid" alt="" /></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="getInTouch-section">
                <div class="container">
                    <div class="col-12">
                        <div class="getInTouch-box">
                            <div class="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div class="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i class="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i class="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i class="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i class="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i class="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default MyChat;
