import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import images from '../../Utility/Images';



const AboutUsSection = () => {

    // useEffect(() => {
    //     let texwrite
    //     const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    //     async function writingAll(stringTarget, container) {
    //         texwrite = document.querySelector('[' + container + ']')
    //         const stringsContainer = document.getElementsByClassName(stringTarget)

    //         while (texwrite) {
    //             for (let i = 0; i < stringsContainer.length; i++) {
    //                 const string = stringsContainer[i].textContent
    //                 await write(string)
    //                 await sleep(1000)
    //                 await erase()
    //                 await sleep(1000)
    //             };
    //         }
    //     };

    //     async function write(text) {
    //         let index = 0
    //         while (index < text.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             index++
    //             texwrite.innerHTML = text.substring(0, index)
    //         }
    //     };


    //     async function erase() {
    //         while (texwrite.textContent.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             texwrite.textContent = texwrite.textContent.substring(0, texwrite.textContent.length - 2)
    //         }
    //     };
    //     writingAll('item', 'data-text');
    // }, [])

    

    const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")
    const [animated2Name, setAnimated2Name] = useState("none")

	useEffect(()=>{
		var target = document.querySelector('.ha-content-img');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target.getBoundingClientRect().bottom) {
			// console.log('I have been reached');
			setHideOrShow("visible")
			setAnimatedName("slideInLeft")
            setAnimated2Name("slideInRight")
		  }
		})
	},[])

    // useEffect(()=>{
    //     if (window.location.pathname === '/') { 
    //         let texwrite
    //     const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    //     const writingAll = async (stringTarget, container)=> {
    //         texwrite = document.querySelector('[' + container + ']')
    //         const stringsContainer = document.getElementsByClassName(stringTarget)

    //         while (texwrite) {
    //             for (let i = 0; i < stringsContainer.length; i++) {
    //                 const string = stringsContainer[i].textContent
    //                 await write(string)
    //                 await sleep(1000)
    //                 await erase()
    //                 await sleep(1000)
    //             };
    //         }
    //     };

    //     const write = async (text)=> {
    //         let index = 0
    //         while (index < text.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             index++
    //             texwrite.innerHTML = text.substring(0, index)
    //         }
    //     };


    //     const erase = async ()=> {
    //         while (texwrite.textContent.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             texwrite.textContent = texwrite.textContent.substring(0, texwrite.textContent.length - 2)
    //         }
    //     };
    //     writingAll('item', 'data-text');
    //     }
    // },[])

    return (
        <>
            <section className="home-about-section pad-t-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="ha-content-img wow slideInRight animated hinge" style={{visibility: hideOrShow, animationName: animated2Name}}>
                                <svg viewBox="0 0 670.387 670.109">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <path id="surprised-happy-couple-looking-laptop" d="M978.781,546.351c18.945,46.39,8.131,99.518,18.682,148.5,22.052,102.39,134.569,179.493,122.39,283.516-6.893,58.865-55.643,106.68-111.334,126.922s-117.171,17.2-175.666,7.674c-136.674-22.26-276.179-86.653-343.3-207.778C442.69,820.62,437.525,714.2,475.966,625.5c38.925-89.8,119.5-164.314,218.384-171.624C789.638,446.833,934.893,438.918,978.781,546.351Z" transform="translate(-173.556 2354.756)" fill="#0974d8" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_10027" data-name="Group 10027" transform="translate(-277 -3257)">
                                        <g id="Group_10018" data-name="Group 10018" transform="translate(164.171 -643)">
                                            <path id="surprised-happy-couple-looking-laptop-2" data-name="surprised-happy-couple-looking-laptop" d="M978.781,546.351c18.945,46.39,8.131,99.518,18.682,148.5,22.052,102.39,134.569,179.493,122.39,283.516-6.893,58.865-55.643,106.68-111.334,126.922s-117.171,17.2-175.666,7.674c-136.674-22.26-276.179-86.653-343.3-207.778C442.69,820.62,437.525,714.2,475.966,625.5c38.925-89.8,119.5-164.314,218.384-171.624C789.638,446.833,934.893,438.918,978.781,546.351Z" transform="translate(-337.556 3449.756)" fill="#0779e4" />
                                            <g id="Mask_Group_10" data-name="Mask Group 10" transform="translate(-164 1095)" clip-path="url(#clip-path)">
                                                <g id="Group_897" data-name="Group 897" transform="translate(132.306 2779.871) rotate(8)">
                                                    <path id="Path_1" data-name="Path 1" d="M528.4,574c18.945-46.39,8.131-99.518,18.682-148.5,22.052-102.39,134.569-179.493,122.39-283.516C662.575,83.125,613.825,35.31,558.135,15.068S440.963-2.135,382.468,7.394C245.794,29.654,106.29,94.047,39.166,215.172-7.694,299.734-12.859,406.158,25.582,494.856c38.925,89.8,119.5,164.314,218.384,171.624C339.253,673.521,484.508,681.436,528.4,574Z" transform="translate(0)" fill="#0974d8" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_10019" data-name="Group 10019" transform="translate(0 -29.031)">
                                            <g id="Group_873" data-name="Group 873" transform="translate(301.669 3587.086)">
                                                <path id="Path_2" data-name="Path 2" d="M2238.168,1748.709h11.163l3.016-29.5h-17.2Z" transform="translate(-1760.742 -1410.788)" fill="#9ba4b4" />
                                                <path id="Path_3" data-name="Path 3" d="M703.745,1748.709h11.163l3.016-29.5h-17.2Z" transform="translate(-661.306 -1410.788)" fill="#9ba4b4" />
                                                <path id="Rectangle_4" data-name="Rectangle 4" d="M0,0H446.666a0,0,0,0,1,0,0V102.99a20,20,0,0,1-20,20H20a20,20,0,0,1-20-20V0A0,0,0,0,1,0,0Z" transform="translate(498.79 234.382) rotate(180)" fill="#aab3c4" />
                                                <path id="Path_4" data-name="Path 4" d="M664.138,1435.142l484.338-.008.032,84.992H664.138Z" transform="translate(-635.088 -1207.243)" fill="#aab3c4" />
                                                <rect id="Rectangle_5" data-name="Rectangle 5" width="64.273" height="129.698" rx="30" transform="translate(546.672 279.588) rotate(180)" fill="#9ba4b4" />
                                                <rect id="Rectangle_6" data-name="Rectangle 6" width="64.274" height="129.327" rx="30" transform="translate(64.274 279.217) rotate(180)" fill="#9ba4b4" />
                                                <rect id="Rectangle_7" data-name="Rectangle 7" width="517.713" height="53.193" rx="26.597" transform="translate(529.81 279.201) rotate(180)" fill="#9ba4b4" />
                                                <path id="Path_70" data-name="Path 70" d="M833.679,878.9s8.2-.222,15.74-8.646,25.494-13.079,40.125-12.192,29.262,11.084,34.139,22.833,4.877,24.939,4.877,24.939H806.16a2.1,2.1,0,0,1-1.988-2.791c.645-1.763,2.242-3.639,6.156-3.231C817.422,900.554,833.679,878.9,833.679,878.9Z" transform="translate(-735.334 -793.687)" fill="#8bcdcd" />
                                                <path id="Path_71" data-name="Path 71" d="M1069.789,910.226s-10.641-13.523-20.542-12.636-11.38,18.991-5.1,25.42c6.135,6.28,7.168,5.1,7.168,5.1s-9.9,0-13.153,1.478a3.751,3.751,0,0,0-2.194,4.618l47.713-.037S1079.247,920.867,1069.789,910.226Z" transform="translate(-901.392 -822.054)" fill="#70b5b5" />
                                                <path id="Path_72" data-name="Path 72" d="M916.005,972.231l6.761-4.905a45.533,45.533,0,0,1,11.86,4.905c6.872,3.879,7.316,5.209,7.87,8.313s-.069,3.657-.069,3.657H931.4Z" transform="translate(-815.555 -872.051)" fill="#70b5b5" />
                                                <path id="Path_73" data-name="Path 73" d="M843.495,967.742s9.755,5.32,13.154,7.833,1.616,7.939,1.616,7.939l-54.811.018a2,2,0,0,1-2-2c.005-1.933,1.295-4.505,8.348-4.035,11.084.739,18.917-3.251,18.474-5.616S837.436,963.751,843.495,967.742Z" transform="translate(-733.475 -871.367)" fill="#8bcdcd" />
                                                <g id="Group_873" data-name="Group 854" className="tail-home"><path id="Path_74" data-name="Path 74" d="M1077,1020.006a13.04,13.04,0,0,1-5.432,10.53c-5.875,4.544-21.725,3.88-30.925,4.544s-13.855,3.1-15.407,10.2-.111,17.735,7.648,19.4.333,14.853-10.64,6.651-14.078-20.4-7.87-31.036,13.412-15.074,28.708-14.63,29.041.443,29.484-5.654S1077,1020.006,1077,1020.006Z" transform="translate(-883.772 -907.856)" fill="#8bcdcd" /></g>
                                                <path id="Path_75" data-name="Path 75" d="M1082.242,909.836s-13.873-13.781-23.776-12.894-7.7,21.762-.609,26.935,9.163,4.581,9.163,4.581-13.153-.739-16.4.739a3.716,3.716,0,0,0-2.229,4.552l47.733-.008S1091.7,920.476,1082.242,909.836Z" transform="translate(-910.299 -821.59)" fill="#8bcdcd" />
                                                <path id="Path_76" data-name="Path 76" d="M838.243,948.563c7.879-3.983,19.938-20.045,19.938-20.045a17.85,17.85,0,0,0,5.442-1.315c.308,4.191-.519,11.191-6.172,20.881C857.451,948.083,846.411,950.5,838.243,948.563Z" transform="translate(-759.837 -843.302)" fill="#70b5b5" />
                                                <path id="Path_77" data-name="Path 77" d="M803.315,860.681s7.833,5.025,7.685,9.311c0,0,7.242,3.4,7.833,4.877s3.4,9.9-4.877,24.828c0,0-17.292,3.991-23.646-2.512l-3.1-8.867s-6.355-6.8-6.355-11.38l10.493,1.921s7.537-7.242,10.641-7.242Z" transform="translate(-718.715 -795.638)" fill="#8bcdcd" />
                                                <path id="Path_81" data-name="Path 81" d="M1668.307,885.915l-9.861-2.595,6.683,89.131,11.829.395-5.967-83.693A3.614,3.614,0,0,0,1668.307,885.915Z" transform="translate(-1347.524 -811.859)" fill="#e5eff0" />
                                                <g id="Group_47" data-name="Group 47" transform="translate(306.966 71.429)">
                                                    <path id="Path_82" data-name="Path 82" d="M1654.248,974.913a3.232,3.232,0,0,0,.676-.01,3.953,3.953,0,0,0,3.237-4.351l-6.562-83.81a3.6,3.6,0,0,0-3.854-3.513,3.954,3.954,0,0,0-3.238,4.351l6.562,83.81A3.681,3.681,0,0,0,1654.248,974.913Z" transform="translate(-1644.494 -883.207)" fill="#e8f044" />
                                                    <path id="Path_83" data-name="Path 83" d="M1761.436,1200.581c0,.062-.007.123-.012.185h0c-.309,3.409-2.951,5.96-5.9,5.7l-83.268-7.453c-2.95-.264-5.091-3.242-4.782-6.652.013-.144.032-.286.053-.427Z" transform="translate(-1660.938 -1104.412)" fill="#e8f044" />
                                                </g>
                                                <path id="Path_84" data-name="Path 84" d="M1377.781,1278.525c-3.09,5.415,2.005,22.145,2.484,22.151-4.558.183-49.363,18.056-49.363,18.056l-13.715-.184s-1.342-37.981,2.005-37.394,1.14,2.6,7.383,10.252c.554.679,2.891,11.341,9.219,8.542C1348.54,1294.31,1369.822,1282.264,1377.781,1278.525Z" transform="translate(-1102.817 -1095.03)" fill="#ffe4e4" />
                                                <path id="Path_85" data-name="Path 85" d="M1316.075,1315.185l.5-41.551c.171-1.5,1.395-1.834,2.13-1.295,1.514,1.11,5.017,9.117,7.34,14.5-1.2,9.311,2.179,25.745,4.287,27.271C1325,1317.991,1316.237,1316.024,1316.075,1315.185Z" transform="translate(-1102.21 -1090.425)" fill="#ef4339" />
                                                <path id="Path_86" data-name="Path 86" d="M1526.26,1254.976c-3.918-1.626-69.848,17.277-69.848,17.277l-6.059-26.39s69.326-22.068,81.578-20.072,127.034,12.5,116,41.007S1584.652,1279.206,1526.26,1254.976Z" transform="translate(-1198.423 -1057.153)" fill="#0779e4" />
                                                <path id="Path_87" data-name="Path 87" d="M1696.719,1563.227a137.489,137.489,0,0,1,20.45-1.545c-.75,5.667-4.393,34.727-3.727,36.512a21.811,21.811,0,0,0,8.681,10.62s-28.217,25.459-29.921,22.518,1.258-2.547,3.1-12.248c.164-.861,4.243-14.4,4.5-21.7C1700.282,1583.452,1696.929,1570.961,1696.719,1563.227Z" transform="translate(-1371.346 -1297.916)" fill="#ffe4e4" />
                                                <path id="Path_88" data-name="Path 88" d="M1715.384,1227.066c3.936-2.1,14.156-2.173,25.392-1.445a257.847,257.847,0,0,1,64.372,12.634l13.946,4.619-.046-14.3s44.363-7.423,45.906,3.572c2.324,16.558-1.721,50.061-20.161,51.949-81.667,8.36-105.431-31.4-105.664-23.494-.312,10.618-9.265,50.131-11.553,67.431-6.365.11-17.3.105-22.043-.983C1704.611,1293.162,1704.178,1233.045,1715.384,1227.066Z" transform="translate(-1380.958 -1056.824)" fill="#0869c5" />
                                                <path id="Path_89" data-name="Path 89" d="M1715.923,1701.517l-31.719,25.338c-1.257.832-2.3.112-2.361-.8-.124-1.874,3.753-9.707,6.38-14.947,7.9-5.067,18.325-18.283,18.139-20.877C1709.7,1693.056,1716.461,1700.853,1715.923,1701.517Z" transform="translate(-1364.286 -1390.024)" fill="#ef4339" />
                                                <path id="Path_90" data-name="Path 90" d="M2055.072,663.284c1.066-9.74,2.187-19.516,10.262-26.655,6.534-5.777,14.842-6.756,23.056-3.665,23.276,8.758,11.283,29.429,40.962,50.27,9.221,6.476,19.386,10.909,26.329,19.629,9.719,12.206,6.049,26.888-2.808,36.691-42.38,46.9-89.694-16.44-93.091-31.646C2057.254,696.6,2054.386,669.554,2055.072,663.284Z" transform="translate(-1631.638 -631.241)" fill="#1b262c" />
                                                <path id="Path_91" data-name="Path 91" d="M2069.849,884.255a129.264,129.264,0,0,1,7.028-11.446c3.59-6.383,12.867-9.612,12.867-9.612s3.1.063,6.667.238a2.82,2.82,0,0,0,.672,1.183c1.339,1,9.013-.292,13.97,3.073,16.614,14.242,12.019,83.272,10.712,105.075l-50.6.479s6.379-46.913-3.9-58.966C2060.1,905.886,2064.713,893.722,2069.849,884.255Z" transform="translate(-1637.809 -797.441)" fill="#ff8805" />
                                                <path id="Path_92" data-name="Path 92" d="M2114.5,774.651s2.781,19.863,4.723,24.691-10.681,15.15-21.185,18.283,3.21-10.662,2.775-15.823-2.344-29.591-2.344-29.591Z" transform="translate(-1659.657 -732.248)" fill="#ffe4e4" />
                                                <path id="Path_93" data-name="Path 93" d="M2110.125,779.217c-.307-4.042-.53-7.006-.53-7.006l16.03,2.44s.4,2.334.851,5.634C2119.977,784.055,2114.134,782.865,2110.125,779.217Z" transform="translate(-1670.779 -732.248)" fill="#f1d0d0" />
                                                <path id="Path_94" data-name="Path 94" d="M2100.216,652.382a18.21,18.21,0,0,1,10.22,7.378c3.9,5.954,6.579,23.482-6.272,31.663s-22.843-3.392-23.009-14.679S2083.408,650.392,2100.216,652.382Z" transform="translate(-1650.396 -646.261)" fill="#ffe4e4" />
                                                <path id="Path_95" data-name="Path 95" d="M2069.234,710.579c1.1,2.724,3.272,4.414,4.858,3.776s1.984-3.365.887-6.089-3.271-4.414-4.857-3.776S2068.139,707.856,2069.234,710.579Z" transform="translate(-1641.417 -683.629)" fill="#ffe4e4" />
                                                <path id="Path_96" data-name="Path 96" d="M2141.723,651.524s8.056,18.069,15.133,19.682,6.974,10.705,6.974,10.705-.159-21-6.791-25.811S2141.723,651.524,2141.723,651.524Z" transform="translate(-1693.799 -645.773)" fill="#1b262c" />
                                                <path id="Path_97" data-name="Path 97" d="M2184.231,723.351c-.932,2.934-.079,5.824,1.9,6.453s4.346-1.238,5.278-4.173.079-5.824-1.9-6.453S2185.163,720.416,2184.231,723.351Z" transform="translate(-1723.986 -694.162)" fill="#ffe4e4" />
                                                <path id="Path_98" data-name="Path 98" d="M1853.152,1053.361c3.036-1.1,7.555-1.234,7.479-1.635-.305-1.622-13.716,3.581-11.9,1.214,1.008-1.317,11.045-5.986,13.486-5.215,2.367.748,17.911,5.11,17.911,5.11l42.481-9.956s10.723-24.366,11.769-45.3a58.387,58.387,0,0,1,22.6,7.665c-3.715,20.02-12.745,52.47-22.727,58.56-4.2,2.562-54.336-3.066-54.336-3.066s-11.049-.781-12.855-1.475c-1.369-.527-4.895-2.512-6.812-3.056-4.359-1.234-12.125,1.865-12.15,1.011C1848.079,1056.595,1850.05,1054.482,1853.152,1053.361Z" transform="translate(-1483.412 -893.726)" fill="#ffe4e4" />
                                                <path id="Path_99" data-name="Path 99" d="M2148.5,880.373c.409-6.442,2.849-12.661,9.7-14.664a13.877,13.877,0,0,1,12.408,2.719c4.038,3.3,5.166,8.59,5.147,13.6a194.36,194.36,0,0,1-2.895,34.356,73.516,73.516,0,0,0-25.559-8.044C2147.307,908.271,2147.96,888.886,2148.5,880.373Z" transform="translate(-1697.798 -798.889)" fill="#ef4339" />
                                            </g>
                                            <g id="Group_9626" data-name="Group 9626" transform="translate(461.296 3334.031)">
                                                <rect id="Rectangle_2" data-name="Rectangle 2" width="199.434" height="58.455" rx="5" transform="translate(199.434 58.455) rotate(180)" fill="#ffe4e4" />
                                                <rect id="Rectangle_14" data-name="Rectangle 14" width="12.993" height="2.675" transform="translate(31.313 8.693) rotate(180)" fill="#ffa5a5" />
                                                <rect id="Rectangle_15" data-name="Rectangle 15" width="158.793" height="7.387" transform="translate(181.69 49.157) rotate(180)" fill="#ffa5a5" />
                                            </g>
                                            <path id="Path_63" data-name="Path 63" d="M986,660.381h7.977l-5.641-5.641a.6.6,0,0,1,.851-.851l5.641,5.641v-7.977a.6.6,0,1,1,1.2,0v7.977l5.641-5.641a.6.6,0,0,1,.851.851l-5.641,5.641h7.977a.6.6,0,0,1,0,1.2h-7.977l5.641,5.64a.6.6,0,1,1-.851.851l-5.641-5.64v7.977a.6.6,0,0,1-1.2,0v-7.977l-5.641,5.64a.6.6,0,0,1-.851-.851l5.641-5.64H986a.6.6,0,1,1,0-1.2Z" transform="translate(-201.004 2857.568)" fill="#e8f044" />
                                            <path id="Path_64" data-name="Path 64" d="M1320.543,786.294l6.581-2.558-6.462-2.844a.533.533,0,1,1,.429-.975l6.462,2.844-2.558-6.581a.533.533,0,1,1,.993-.386l2.559,6.581,2.844-6.462a.533.533,0,0,1,.975.429l-2.844,6.463,6.58-2.559a.533.533,0,1,1,.386.993l-6.581,2.559,6.462,2.844a.533.533,0,1,1-.429.975l-6.462-2.844,2.558,6.581a.533.533,0,1,1-.993.386l-2.559-6.581-2.844,6.463a.533.533,0,0,1-.975-.429l2.844-6.463-6.581,2.559a.533.533,0,0,1-.386-.993Z" transform="translate(-580.893 2661.819)" fill="#e8f044" />
                                            <path id="Path_65" data-name="Path 65" d="M513.971,430.255l5.1,2.661-1.723-5.485a.434.434,0,0,1,.828-.26l1.723,5.485,2.66-5.1a.434.434,0,0,1,.769.4l-2.661,5.1,5.485-1.722a.434.434,0,0,1,.26.828l-5.485,1.722,5.1,2.661a.434.434,0,0,1-.4.769l-5.1-2.661,1.722,5.485a.434.434,0,0,1-.828.26l-1.722-5.485-2.66,5.1a.434.434,0,1,1-.769-.4l2.66-5.1-5.485,1.722a.434.434,0,0,1-.26-.828l5.485-1.722-5.1-2.661a.434.434,0,1,1,.4-.769Z" transform="translate(-145.19 3009.915)" fill="#e8f044" />
                                            <g id="Group_992" data-name="Group 992" transform="translate(413.891 3397.844)">
                                                <path id="Path_78" data-name="Path 78" d="M1441.672,614.691c-4.345-4.533-4.06-4.818-5.846-9.628-1.043-2.971-4.493-6.94-4.475-6.919a19.674,19.674,0,0,0-2.995-2.634c-10.869-7.295-12.653-8.449-16.078-18.539-4.3-12.672-6.8-11.315-13.814-18.847-6.9-7.469-4.539-14.824-7.778-22.719l.034-.015c2.545,5.54,2.335,11.988,4.952,17.323,3.094,6.075,10.3,9.433,13.627,13.934,2.409,3.268,3.221,5.333,4.882,9.633,4.119,10.7,6.184,10.382,15.583,16.529l1.3.89a18.01,18.01,0,0,1,2.657,2.281l.4.435c8.055,9.531,3.988,9.249,9.214,14.355l1.038.953Z" transform="translate(-1169.717 -528.983)" fill="#ffe4e4" />
                                                <path id="Path_79" data-name="Path 79" d="M617.887,617.373c.52.356,14.171-9.887,19.5-27.312,1.177-3.849,1.042-8.061,2.173-11.819,3.513-11.667,15.119-13.292,15.381-32.657.281-18.538,1.981-15.825,8.069-29.175a28.454,28.454,0,0,0,2.285-7.246l.046.006c-.584,5.1-2.993,9.656-5.2,14.337-9.666,20.475,3.636,23.89-10.683,43.958-2.223,3.1-5.584,6.963-6.844,10.649-1.6,4.675-1,10.332-2.447,15.228a53,53,0,0,1-18.19,26.831Z" transform="translate(-585.738 -509.164)" fill="#ffe4e4" />
                                                <path id="Path_80" data-name="Path 80" d="M486.309,601.859c5.744-3.571,12.555-4.371,17.239-9.347,3.341-3.55,5.288-12.085,12.5-16.976,3.725-2.525,7.891-4.479,10.944-7.881,3.963-4.345,4.262-12.542,8.534-18.124,3.583-4.57,9.065-7.468,11.873-12.653l.033.017c-3.576,7.341-10.424,8.788-13.774,18.177-1.457,4.14-2.01,7.885-4.107,11.735a17.207,17.207,0,0,1-3.351,4.116c-3.908,3.577-7.81,5.01-11.637,8.926-5.471,5.6-5.674,16.145-16.464,21.028-.494.223-9.824,3.716-9.448,4.236C488.654,605.114,486.306,601.861,486.309,601.859Z" transform="translate(-486.309 -530.107)" fill="#ffe4e4" />
                                            </g>
                                            <g id="Group_874" data-name="Group 874" transform="translate(789.484 3717.44)">
                                                <path id="Path_6" data-name="Path 6" d="M2469.255,1769.387h5.313l1.436-13.52h-8.184Z" transform="translate(-2440.561 -1561.751)" fill="#9ba4b4" />
                                                <path id="Path_7" data-name="Path 7" d="M2611.577,1769.351h5.313l1.435-13.529h-8.184Z" transform="translate(-2553.87 -1561.715)" fill="#9ba4b4" />
                                                <path id="Path_8" data-name="Path 8" d="M2353.52,1769.341h5.313l1.436-13.52h-8.185Z" transform="translate(-2348.419 -1561.715)" fill="#8bcdcd" />
                                                <rect id="Rectangle_8" data-name="Rectangle 8" width="91.183" height="92.463" transform="translate(0 101.967)" fill="#e5eff0" />
                                                <rect id="Rectangle_9" data-name="Rectangle 9" width="26.442" height="92.463" transform="translate(0 101.967)" fill="#8bcdcd" />
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="62.254" height="1.594" transform="translate(88.609 133.85) rotate(180)" fill="#9ba4b4" />
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="62.254" height="1.594" transform="translate(88.609 159.433) rotate(180)" fill="#9ba4b4" />
                                                <g id="Group_30" data-name="Group 30" transform="translate(68.088 113.465)">
                                                    <path id="Path_51" data-name="Path 51" d="M2699.747,1429.919s-24.221-17.979-27.541-25.727,3.525-6.874,3.525-6.874-9.781-13.857-7.184-18.436,8,1.269,8,1.269-4.536-18.747-.591-19.79c7.348-1.942,13.377,18.233,13.377,18.233s-.542-8.377,4.058-7.248c6.937,1.7,3.267,19.735,3.267,19.735s2.21-3.2,6.015-.156C2709.493,1396.384,2699.747,1429.919,2699.747,1429.919Z" transform="translate(-2668.108 -1360.229)" fill="#8bcdcd" />
                                                </g>
                                                <path id="Path_52" data-name="Path 52" d="M2734.326,1391.392a5.5,5.5,0,0,0-7.041-.311s3.669-18.034-3.267-19.735c-4.6-1.129-4.059,7.248-4.059,7.248s-6.03-20.175-13.377-18.233l23.976,69.5C2731.631,1425.522,2740.7,1396.491,2734.326,1391.392Z" transform="translate(-2630.652 -1246.765)" fill="#79b9b9" />
                                                <g id="Group_32" data-name="Group 32" transform="translate(56.672 147.218)">
                                                    <path id="Path_53" data-name="Path 53" d="M2655.129,1561.117s-25.256.226-30.38-4.075,1.6-4.889,1.6-4.889-11.862-6.3-11.689-10.5,4.195-3.353,4.195-3.353-8.2-7.345-6.517-10.9c2.587-5.471,14.314,4.905,14.314,4.905s-.9-4.161,2.739-5.031c5.485-1.311,11.049,14.283,11.049,14.283s-2.623-7.025,1.145-6.244C2648.344,1536.712,2655.129,1561.117,2655.129,1561.117Z" transform="translate(-2612.108 -1525.805)" fill="#ffa5a5" />
                                                </g>
                                                <g id="Group_33" data-name="Group 33" transform="translate(93.72 115.991)">
                                                    <path id="Path_54" data-name="Path 54" d="M2800.854,1440.545s22.031-18.8,24.719-26.374-3.77-6.326-3.77-6.326,8.465-13.777,5.717-17.977-7.532,1.693-7.532,1.693,3.181-18.112-.637-18.865c-7.109-1.4-11.622,18.159-11.622,18.159s.007-8-4.3-6.65c-6.5,2.04-1.912,18.975-1.912,18.975s-2.4-6.331-5.832-3.206C2789.523,1405.581,2800.854,1440.545,2800.854,1440.545Z" transform="translate(-2793.85 -1372.624)" fill="#ffa5a5" />
                                                </g>
                                                <path id="Path_55" data-name="Path 55" d="M2790.722,1398.817c3.507-2.551,6.476.777,6.476.777s-3.735-13.353,2.761-15.393c4.307-1.352,4.332,6.406,4.332,6.406s4.481-19.318,11.591-17.915l-18.6,67.58C2795.265,1435.641,2786.249,1402.07,2790.722,1398.817Z" transform="translate(-2696.66 -1256.629)" fill="#f19696" />
                                                <g id="Group_36" data-name="Group 36" transform="translate(102.943 162.734)">
                                                    <g id="Group_35" data-name="Group 35">
                                                        <path id="Path_56" data-name="Path 56" d="M2839.092,1620.4s24.216.111,27.567-.382-.353-3.4-.353-3.4,7.53.274,8.678-1.557-1.928-2.553-1.928-2.553,7.5-2.173,7.141-3.768c-.667-2.97-8.949-1.034-8.949-1.034s3.076-1.494,1.826-2.882c-1.885-2.094-9.937,3.6-9.937,3.6s3.022-5.719,1.239-6.443C2861.175,1600.685,2839.092,1620.4,2839.092,1620.4Z" transform="translate(-2839.092 -1601.923)" fill="#8bcdcd" />
                                                    </g>
                                                </g>
                                                <path id="Path_57" data-name="Path 57" d="M2735.676,1716.964H2695.4a24.108,24.108,0,0,1-8.141-18.218,21.812,21.812,0,0,1,2.328-9.752l51.931.584a23.108,23.108,0,0,1,2.148,10.1A23.96,23.96,0,0,1,2735.676,1716.964Z" transform="translate(-2615.263 -1508.51)" fill="#ff8805" />
                                                <path id="Path_58" data-name="Path 58" d="M2679.688,1265.3h-26.75a4.472,4.472,0,0,1-4.295-4.631h0a4.472,4.472,0,0,1,4.295-4.632h26.75a7.534,7.534,0,0,0-.191,9Z" transform="translate(-2584.523 -1163.811)" fill="#fae0b2" />
                                                <path id="Path_59" data-name="Path 59" d="M2587.162,1258.792h0a4.5,4.5,0,0,1,4.345-4.631h26.835a.233.233,0,0,0,.225-.242h0a.233.233,0,0,0-.225-.238h-42.569a4.961,4.961,0,0,0-4.8,5.112h0a4.961,4.961,0,0,0,4.8,5.112h42.569a.233.233,0,0,0,.225-.238h0a.233.233,0,0,0-.225-.242h-26.834A4.5,4.5,0,0,1,2587.162,1258.792Z" transform="translate(-2522.689 -1161.936)" fill="#ff8805" />
                                                <g id="Group_41" data-name="Group 41" transform="translate(51.678 28.656)">
                                                    <rect id="Rectangle_16" data-name="Rectangle 16" width="0.85" height="34.734" fill="#1b262c" />
                                                </g>
                                                <g id="Group_43" data-name="Group 43" transform="translate(42.235 33.616)">
                                                    <g id="Group_42" data-name="Group 42" transform="translate(14.636)">
                                                        <rect id="Rectangle_17" data-name="Rectangle 17" width="2.066" height="66.945" fill="#1b262c" />
                                                    </g>
                                                    <path id="Path_67" data-name="Path 67" d="M2541.608,1285.855c7.334,0,13.28-1.313,13.28-2.933a1.139,1.139,0,0,0-.581-.859l.064-.019h5.338l.017,0a1.154,1.154,0,0,0-.6.873c0,2.074,8.324,2.947,13.449,2.932h0v.635h-31.294v-.635l.006,0Z" transform="translate(-2541.285 -1218.134)" fill="#1b262c" />
                                                </g>
                                                <path id="Rectangle_18" data-name="Rectangle 18" d="M2.5,0H14.455a2.5,2.5,0,0,1,2.5,2.5v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A2.5,2.5,0,0,1,2.5,0Z" transform="translate(65.656 34.138) rotate(180)" fill="#1b262c" />
                                                <path id="Path_68" data-name="Path 68" d="M2503.447,833.214c0,.684,2.881,1.3,7.519,1.739,15.511,1.464,41.509.4,41.509-1.739,0-.085-16.016-29.588-16.016-29.588h-17S2503.447,833.129,2503.447,833.214Z" transform="translate(-2468.926 -803.626)" fill="#ff8805" />
                                                <path id="Path_69" data-name="Path 69" d="M2582.511,1109.372a1.441,1.441,0,1,0,1.436-1.32A1.381,1.381,0,0,0,2582.511,1109.372Z" transform="translate(-2531.872 -1045.994)" fill="#1b262c" />
                                            </g>
                                            <g id="Group_10443" className="texwrite" data-name="Group 10443">
                                                <rect id="Rectangle_994" data-name="Rectangle 994" width="277" height="38" rx="2" transform="translate(397 3560)" fill="#fff" />
                                                <text className="write" data-text="" transform="translate(407 3586)" fill="#646464" fontSize="18" fontFamily="SF UI Display"></text>
                                                <text className="item"> <tspan x="0" y="0"> Shadow Boxing </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0"> Residential </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Outdoor Work </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Lights/Recess Lights </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0"> Outdoor Post Lights </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Buy Property </tspan> </text>
                                                <g id="Group_9627" data-name="Group 9627" transform="translate(640.73 3567.747)">
                                                    <g id="Ellipse_14" data-name="Ellipse 14" fill="#fff" stroke="#0779e4" stroke-width="1.5">
                                                        <circle cx="10.06" cy="10.06" r="10.06" stroke="none" />
                                                        <circle cx="10.06" cy="10.06" r="9.31" fill="none" />
                                                    </g>
                                                    <g id="Rectangle_40" data-name="Rectangle 40" transform="translate(17.253 14.089) rotate(45)" fill="#0779e4" stroke="#0779e4" stroke-width="1">
                                                        <rect width="8.263" height="3.305" rx="0.5" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="7.263" height="2.305" fill="none" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>

                                </svg>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="ha-content-box wow slideInLeft animated hinge" style={{visibility: hideOrShow, animationName: animatedName}}>
                                <div className="section-title">
                                    <h2> About <span className="text-orange font-weight-bold"> eZunction </span></h2>
                                </div>
                                <h5> eZunction offers a reliable, easy, secure and trusted platform to establish the connection between the requestor and the service provider community.</h5>
                                <p> This is your go-to platform for all your service needs that offers a hastle free environment and makes life easy to get your request completed with full satisfaction. </p>
                                <ul>
                                    <li>
                                        <img src={images.checkIcon} className="img-fluid" alt="" />
                                        <span> <b>10,000+</b> Service Professionals</span>
                                    </li>

                                    <li>
                                        <img src={images.checkIcon} className="img-fluid" alt="" />
                                        <span> <b>50,000+</b> Satisfied Happy Customers </span>
                                    </li>

                                    <li>
                                        <img src={images.checkIcon} className="img-fluid" alt="" />
                                        <span> <b> 25 </b> Major Cities </span>
                                    </li>

                                    <li>
                                        <img src={images.checkIcon} className="img-fluid" alt="" />
                                        <span> <b> 5+ </b> States </span>
                                    </li>
                                </ul>

                                <Link to="/about-us" className="btn btn-red btn-sm"> Read More </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsSection;
