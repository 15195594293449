import React, { useContext, useReducer,useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Auth from '../Auth/Auth';
import { APIService } from '../Components/Apis/APIServices';
import Dialogue from '../Components/Common/Dialogue';
import Header from '../Components/Common/Header';
import images from '../Utility/Images';
import LoginReducer, { initialState } from './Login/LoginReducer';
import { LoginTypes } from './Login/LoginType';
import formValidation from './Registration/FormValidation/FormValidation';

import ReCAPTCHA from "react-google-recaptcha";

document.body.classList.add('dark-header');
const Login = () => {
   
    const [state, dispatch] = useReducer(LoginReducer, initialState);
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
    const [ip, setIP] = useState('');
    const [validCap, setValidCap] = useState(true)
    useEffect(()=>{
        fetch("https://api.db-ip.com/v2/free/self")
        .then((res) => res.json())
        .then(
            (result) =>{
                // console.log("ttttttttttttttttttt", result.ipAddress)
                setIP(result.ipAddress)
            },
            (error) =>{}
        )
    },[])

const [pos, setPos] = useState({
    lat:"",
    long:""
})

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(locSuccess, locError)
        function locSuccess (position){
            setPos({
                  lat: position.coords.latitude,
                  long: position.coords.longitude,
                })

                
            }

            function locError (error){
                // handleLocationError(true, infoWindow, map.getCenter());
              }

              
    },[])

    // console.log("asdsadasdasdsad", pos)

    useEffect(()=>{
        if(localStorage.getItem("remember_email")){
            dispatch({ type: LoginTypes.LoginEmail, payload: localStorage.getItem("remember_email") })
        }
    },[])

    const handleChange = (e) => {

        let name = e.target.name;
        let value = e.target.value;
        if (name == "email")
            return dispatch({ type: LoginTypes.LoginEmail, payload: value })
        if (name == "password")
            return dispatch({ type: LoginTypes.LoginPassword, payload: value })
        if (name == "remember")
            return dispatch({ type: LoginTypes.LoginRememberMe, payload:state.rememberMe });  
    }
    const loginSubmit = (e) => {
        e.preventDefault();
        let data={
            email:state.email,
            password:state.password,
            user_ip: ip
        }
        if(!formValidation().Email(state.email)){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Email Id not valid'
            })
            return;
        }
        APIService.login(data).then((response)=>{
            if (response && response.data && response.data.status==200) {
                    dispatch({ type:LoginTypes.LoginInitialState})
                    dispatch({ type:LoginTypes.IsLogin,payload:true})
                    console.log("remmeber",state.rememberMe)
                    state.rememberMe && localStorage.setItem("remember_email",state.email)


                    APIService.sendLatLong(pos)
                    .then((res)=>{})
                    .catch((err)=>{})



            }else{
                // console.log("errsaddasd", response)
                let message = (response && response.response ?  response.response.data && response.response.data.message : response.data.message) || "";
                // if(response.data.message.includes('E_USER_NOT_FOUND'))
                //     message = " Incorrect email id "+state.email;
                // else if(response.data.message.includes('E_PASSWORD_MISMATCH'))
                //     message = "Incorrect password."
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message
                })
                
            }
        })
        .catch((e)=>{
            setDialogueState({
                type : 'Error',
                show:true,
                message : (e && e.response && e.response.data && e.response.data.message) || "",
                // link : '/register'
              })
        })
    }
    const showHidePassword=()=>{
       return dispatch({ type: LoginTypes.LoginShowPassword, payload:state.showPassword })
    }
    console.log(state)
    if(Auth.isAuthLogIn() || state.isLogin){
        return <Redirect to="/"/>
    }

    // console.log("////////////////aaaaaaaaaa", state)

   

    const handleCaptchaChange = (value)=>{
        console.log("Captcha value:", value);
        if(value)
            setValidCap(false)
    }

    return (
        <>
            <Header />
            <section className="auth-wrapper auth-login-box">
                <div className="auth-marquee-tag auth-right-blue">
                    <div className="auth-bg-slideshow">
                        <div className="mover"></div>
                    </div>
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12">
                            <div className="auth-white-box auth-block">
                                <div className="m_header">
                                    <h2> Sign In </h2>
                                    <p> Enter your details below to continue </p>
                                </div>
                                <div className="m_body">
                                    <form className="auth-form" onSubmit={loginSubmit}>
                                        <div className="form-group">
                                            <input type="email" placeholder="E-mail ID" name="email" onChange={handleChange} className="form-control " value={state.email} />
                                        </div>
                                        <div className="form-group">
                                            <div className="password-group">
                                                <input type={state.showPassword?"text":"password"} id="password-field" placeholder="Password" name="password" onChange={handleChange} value={state.password} className="form-control" />
                                                <button type="button" toggle="#password-field" className="toggle-password password-eye">
                                                   
                                                   {state.showPassword?
                                                     <i className="far fa-eye-slash" onClick={showHidePassword}></i>:
                                                     <i className="far fa-eye eye-btn" onClick={showHidePassword}></i> 
                                                    }
                                                </button>
                                            </div>
                                        </div>

                                        <div className="forgot-link">
                                            <div className="form-checkbox">
                                                <div className="custom-check-block">
                                                    <input type="checkbox" className="d-none" id="remember" name="remember" onClick={handleChange} value={state.rememberMe} checked={state.rememberMe} />
                                                    <label className="custom-check-label" for="remember"> Remember me </label>
                                                </div>
                                            </div>

                                            <Link to="/reset-password"> Forgot Password? </Link>
                                        </div>
                                        <div className="form-group">
                                        <ReCAPTCHA
    sitekey="6LeGaDAfAAAAACcH7UFxzT4wrt4zoKQYqGCsRMz1"
    onChange={handleCaptchaChange}
  />
  </div>

                                        <div className="form-submit text-center">
                                            <button type="submit" className="btn btn-md btn-red w-100" disabled={state.email && state.password && !validCap ? false : true}> Sign In </button>
                                        </div>

                                        <div className="middleLine-sep"> <span> OR LOGIN WITH </span></div>

                                        <div className="social-login">
                                            <a href="#" className="social-log-btn fb-login"> <img src={images.facebookIcon} className="img-fluid" alt="" /> Facebook</a>
                                            <a href="#" className="social-log-btn google-login"> <img src={images.googleIcon} className="img-fluid" alt="" /> Google</a>
                                            <a href="#" className="social-log-btn apple-login"><img src={images.appleIcon} className="img-fluid" alt="" /> Apple</a>
                                        </div>

                                        <div className="col-sm-12 bottom-info">
                                            <p> Don't have any account? <Link to="/register"> Register </Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialogue state={dialogueState} setState={setDialogueState}/>
            </section>
        </>
    );
}

export default Login;
