import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIService } from "../../Components/Apis/APIServices";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import MyAccountMenu from "../../Components/Common/MyAccountMenu";
import images from "../../Utility/Images";
import Auth from "../../Auth/Auth";
import Info from '../../Components/Common/InfoPage';

import MyFeedbackCustomer from './MyFeedbackCustomer'
import MyFeedbackSP from "./MyFeedbackSP";
import GetInTouch from "../../Components/Common/GetInTouch";

const MyFeedback = () => {
  const userDetail = Auth.getUserDetails();

  
  
  // console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", customerFeedback, spFeedback)



  return (
    <>
      <Header />
      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  My <span> Feedbacks </span>{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>

      {/* <div className="grayHero-tabnav db-gh-tabNav">
        <ul className="nav nav-tabs" role="tablist">
          <li>
            <a
              className="active"
              data-toggle="tab"
              href="#gh-customer-tab"
              role="tab"
            >
              Customer
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#gh-serviceP-tab" role="tab">
              Service Provider
            </a>
          </li>
        </ul>
      </div> */}
      <div className="grayHero-tabnav db-gh-tabNav">
        <ul className="nav nav-tabs" role="tablist">
          <li>
            <a
              className={
                userDetail.role_type === 1 || userDetail.role_type === 3
                  ? "active"
                  : ""
              }
              data-toggle="tab"
              href="#gh-customer-tab"
              role="tab"
            >
              Customer
            </a>
          </li>
          <li>
            <a
              className={
                userDetail.role_type === 2 
                  ? "active"
                  : null
              }
              data-toggle="tab"
              href="#gh-serviceP-tab"
              role="tab"
            >
              Service Provider
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content transaction-tab-content">
        <div
          className={`tab-pane ${
            userDetail.role_type === 1 || userDetail.role_type === 3
              ? "active"
              : null
          }`}
          id="gh-customer-tab"
          role="tabpanel"
        >
          {userDetail.role_type === 1 || userDetail.role_type === 3 ? (
            <MyFeedbackCustomer />
          ) : (
            <Info
              infoText='You need to log in as "Both" or "Customer" user type to view this page.'
              btnTitle="Sign up as Customer / Both"
              btnLink="/register"
              bodyMessage="Please logout and register/login as customer or both user"
            />
          )}
        </div>

        <div
          className={`tab-pane ${
            userDetail.role_type === 2 
              ? "active"
              : null
          }`}
          id="gh-serviceP-tab"
          role="tabpanel"
        >
          <div className="container">
            {userDetail.role_type === 2 || userDetail.role_type === 3 ? (
              <MyFeedbackSP />
            ) : (
              <Info
                infoText='You need to log in as "Both" or "Service Provider" user type to view this page.'
                btnTitle="Sign up as Service Provider / Both"
                btnLink="/register"
                bodyMessage="Please logout and register/login as service provider or both user"
              />
            )}
          </div>
        </div>
      </div>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </>
  );
};

export default MyFeedback;
