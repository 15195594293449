import React, { useEffect, useState } from 'react';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';
import images from '../Utility/Images';

import {Link} from 'react-router-dom'

import { APIService } from "../Components/Apis/APIServices";
import config from "../Components/Apis/config";

const AboutUs = () => {
    // useEffect(() => {
    //     let texwrite
    //     const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    //     async function writingAll(stringTarget, container) {
    //         texwrite = document.querySelector('[' + container + ']')
    //         const stringsContainer = document.getElementsByClassName(stringTarget)

    //         while (texwrite) {
    //             for (let i = 0; i < stringsContainer.length; i++) {
    //                 const string = stringsContainer[i].textContent
    //                 await write(string)
    //                 await sleep(1000)
    //                 await erase()
    //                 await sleep(1000)
    //             };
    //         }
    //     };

    //     async function write(text) {
    //         let index = 0
    //         while (index < text.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             index++
    //             texwrite.innerHTML = text.substring(0, index)
    //         }
    //     };


    //     async function erase() {
    //         while (texwrite.textContent.length) {
    //             const timeout = 100
    //             await sleep(timeout)
    //             texwrite.textContent = texwrite.textContent.substring(0, texwrite.textContent.length - 2)
    //         }
    //     };
    //     writingAll('item', 'data-text');
    // }, [])

    const [aboutUsVideo, setAboutUsVideo] = useState("")

    useEffect(()=>{
        APIService.aboutUsVideo()
        .then((response) => {
          if (response.status === 200) {
            // console.log("rerwerewqqweqweqwe", response)
            setAboutUsVideo(response.data.contents[0] || "")
          }
        })
        .catch((error) => console.log(error));
    },[])
    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> About <span> eZunction </span> </h1>

                                <p> The Services Marketplace. Making life easy. </p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex w-100 justify-content-center mt-3 mt-xl-5 mb-3'>
                  {/* <div className="hb-down-arrow"></div> */}
                  <div>
                    <Link
                      to={{
                        pathname: "/create-my-request",
                        // state: { ...data },
                      }}
                      className="btn btn-red btn-md ardCat-submitQ-btn2"
                    >
                      Create My Request
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/all-requests",
                        // state: { ...data },
                      }}
                      className="btn btn-red btn-md ardCat-submitQ-btn2 ml-3"
                    >
                      Submit My Quote
                    </Link>
                  </div>
                  </div>
                </div>
            </section>

            <section className="aboutUs-section pad-b-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="au-content-box wow slideInLeft">
                                <div className="section-title">
                                    <h2> Welcome To <span className="text-orange font-weight-bold"> eZunction </span></h2>
                                </div>
                                <h5> eZunction offers a reliable, easy, secure and trusted platform to establish the connection between the requestor and the service provider community. </h5>
                                <div className="au-des-content">
                                    <p> This is your go-to platform for all your service needs that offers a hastle free environment and makes life easy to get your request completed with full satisfaction. </p>

                                    <p> There is a full range of services that you need to be done on a day to day basis, and saves a lot on your efforts and time spent on searching, making several phone calls, estimate visits, and finding the best schedule to get your request done. </p>

                                    <p> eZunction provides an extensive network of service providers across different types of requests such as all House Repair and Improvement, Beauty and Personal Care, Legal, Moving, Cleaning, Automobile, Food, Real Estate, IT and many other general services such as photography, baby sitting, priest services, general helpers, home cooking help etc.  </p>

                                    <p> You can access this platform online (www.ezunction.com) or you can download this app from here:  </p>
                                </div>

                                <div className="au-app-link">
                                    <a href=""> <img src={images.PlayStore} className="img-fluid" alt="" /></a>
                                    <a href=""> <img src={images.AppleStore} className="img-fluid" alt="" /></a>
                                </div>

                                
                                <h4 className="mt-3">
                                    Our mobile App is coming soon ...
                                </h4>

                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="au-content-img wow slideInRight">
                                <svg viewBox="0 0 670.387 673.862">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <path id="surprised-happy-couple-looking-laptop" d="M592.375,546.351c-18.945,46.39-8.131,99.518-18.682,148.5C551.642,797.237,439.124,874.34,451.3,978.364c6.893,58.865,55.643,106.68,111.334,126.922s117.171,17.2,175.666,7.674c136.674-22.26,276.179-86.653,343.3-207.778,46.86-84.562,52.025-190.986,13.585-279.683-38.925-89.8-119.5-164.314-218.384-171.624C781.518,446.833,636.263,438.918,592.375,546.351Z" transform="translate(-450.384 -450.244)" fill="#0974d8" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_10162" data-name="Group 10162" transform="translate(-971 -704)">
                                        <g id="Group_10018" data-name="Group 10018" transform="translate(971 704)">
                                            <path id="surprised-happy-couple-looking-laptop-2" data-name="surprised-happy-couple-looking-laptop" d="M592.375,546.351c-18.945,46.39-8.131,99.518-18.682,148.5C551.642,797.237,439.124,874.34,451.3,978.364c6.893,58.865,55.643,106.68,111.334,126.922s117.171,17.2,175.666,7.674c136.674-22.26,276.179-86.653,343.3-207.778,46.86-84.562,52.025-190.986,13.585-279.683-38.925-89.8-119.5-164.314-218.384-171.624C781.518,446.833,636.263,438.918,592.375,546.351Z" transform="translate(-450.384 -450.244)" fill="#0779e4" />
                                            <g id="Mask_Group_10" data-name="Mask Group 10" transform="translate(0)" clip-path="url(#clip-path)">
                                                <g id="Group_897" data-name="Group 897" transform="translate(908.171 638.459) rotate(172)">
                                                    <path id="Path_1" data-name="Path 1" d="M528.4,96.107c18.945,46.39,8.131,99.518,18.682,148.5C569.13,346.993,681.647,424.1,669.468,528.12c-6.893,58.865-55.643,106.68-111.334,126.922s-117.171,17.2-175.666,7.674c-136.674-22.26-276.179-86.653-343.3-207.778C-7.694,370.376-12.859,263.952,25.582,175.254,64.507,85.454,145.08,10.941,243.965,3.63,339.253-3.411,484.508-11.326,528.4,96.107Z" transform="translate(0)" fill="#0974d8" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_10019" data-name="Group 10019" transform="translate(984.842 786)">
                                            <g id="Group_873" data-name="Group 873" transform="translate(85.204 253.055)">
                                                <path id="Path_2" data-name="Path 2" d="M2249.33,1748.709h-11.163l-3.016-29.5h17.2Z" transform="translate(-2180.084 -1410.788)" fill="#9ba4b4" />
                                                <path id="Path_3" data-name="Path 3" d="M714.908,1748.709H703.745l-3.016-29.5h17.2Z" transform="translate(-210.675 -1410.788)" fill="#9ba4b4" />
                                                <path id="Rectangle_4" data-name="Rectangle 4" d="M20,0H426.666a20,20,0,0,1,20,20V122.99a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" transform="translate(47.882 111.392)" fill="#aab3c4" />
                                                <path id="Path_4" data-name="Path 4" d="M1148.507,1435.142l-484.338-.008-.032,84.992h484.37Z" transform="translate(-630.885 -1207.243)" fill="#aab3c4" />
                                                <rect id="Rectangle_5" data-name="Rectangle 5" width="64.273" height="129.698" rx="30" transform="translate(0 149.89)" fill="#9ba4b4" />
                                                <rect id="Rectangle_6" data-name="Rectangle 6" width="64.274" height="129.327" rx="30" transform="translate(482.398 149.89)" fill="#9ba4b4" />
                                                <rect id="Rectangle_7" data-name="Rectangle 7" width="517.712" height="53.193" rx="26.597" transform="translate(16.862 226.008)" fill="#9ba4b4" />
                                                <path id="Path_70" data-name="Path 70" d="M898.927,878.9s-8.2-.222-15.74-8.646-25.494-13.079-40.125-12.192S813.8,869.149,808.923,880.9s-4.877,24.939-4.877,24.939h122.4a2.1,2.1,0,0,0,1.988-2.791c-.645-1.763-2.242-3.639-6.156-3.231C915.183,900.554,898.927,878.9,898.927,878.9Z" transform="translate(-450.599 -793.687)" fill="#8bcdcd" />
                                                <path id="Path_71" data-name="Path 71" d="M1049.7,910.226s10.641-13.523,20.542-12.636,11.38,18.991,5.1,25.42c-6.135,6.28-7.168,5.1-7.168,5.1s9.9,0,13.153,1.478a3.751,3.751,0,0,1,2.194,4.618l-47.713-.037S1040.237,920.867,1049.7,910.226Z" transform="translate(-671.42 -822.054)" fill="#70b5b5" />
                                                <path id="Path_72" data-name="Path 72" d="M942.726,972.231l-6.761-4.905a45.533,45.533,0,0,0-11.86,4.905c-6.872,3.879-7.316,5.209-7.87,8.313s.069,3.657.069,3.657h11.029Z" transform="translate(-496.504 -872.051)" fill="#70b5b5" />
                                                <path id="Path_73" data-name="Path 73" d="M816.688,967.742s-9.755,5.32-13.154,7.833-1.616,7.939-1.616,7.939l54.811.018a2,2,0,0,0,2-2c-.005-1.933-1.295-4.505-8.348-4.035-11.084.739-18.917-3.251-18.474-5.616S822.747,963.751,816.688,967.742Z" transform="translate(-380.036 -871.367)" fill="#8bcdcd" />

                                                <g id="Group_873" data-name="Group 854" className="tail"><path id="Path_74" data-name="Path 74" d="M1011.219,1020.006a13.04,13.04,0,0,0,5.432,10.53c5.875,4.544,21.725,3.88,30.925,4.544s13.855,3.1,15.407,10.2.111,17.735-7.648,19.4-.333,14.853,10.64,6.651,14.078-20.4,7.87-31.036-13.412-15.074-28.708-14.63-29.041.443-29.484-5.654S1011.219,1020.006,1011.219,1020.006Z" transform="translate(-657.773 -907.856)" fill="#8bcdcd" /></g>
                                                <path id="Path_75" data-name="Path 75" d="M1062.111,909.836s13.873-13.781,23.776-12.894,7.7,21.762.609,26.935-9.163,4.581-9.163,4.581,13.153-.739,16.4.739a3.716,3.716,0,0,1,2.229,4.552l-47.733-.008S1052.652,920.476,1062.111,909.836Z" transform="translate(-687.383 -821.59)" fill="#8bcdcd" />
                                                <path id="Path_76" data-name="Path 76" d="M863.679,948.563c-7.879-3.983-19.938-20.045-19.938-20.045A17.85,17.85,0,0,1,838.3,927.2c-.308,4.191.519,11.191,6.172,20.881C844.47,948.083,855.51,950.5,863.679,948.563Z" transform="translate(-395.413 -843.302)" fill="#70b5b5" />
                                                <path id="Path_77" data-name="Path 77" d="M797.3,860.681s-7.833,5.025-7.685,9.311c0,0-7.242,3.4-7.833,4.877s-3.4,9.9,4.877,24.828c0,0,17.292,3.991,23.646-2.512l3.1-8.867s6.355-6.8,6.355-11.38l-10.493,1.921s-7.537-7.242-10.641-7.242Z" transform="translate(-335.233 -795.638)" fill="#8bcdcd" />
                                                <path id="Path_81" data-name="Path 81" d="M1667.1,885.915l9.861-2.595-6.683,89.131-11.829.395,5.967-83.693A3.614,3.614,0,0,1,1667.1,885.915Z" transform="translate(-1441.208 -811.859)" fill="#e5eff0" />
                                                <g id="Group_47" data-name="Group 47" transform="translate(139.208 71.429)">
                                                    <path id="Path_82" data-name="Path 82" d="M1648.421,974.913a3.232,3.232,0,0,1-.676-.01,3.953,3.953,0,0,1-3.237-4.351l6.562-83.81a3.6,3.6,0,0,1,3.854-3.513,3.954,3.954,0,0,1,3.238,4.351l-6.562,83.81A3.681,3.681,0,0,1,1648.421,974.913Z" transform="translate(-1557.677 -883.207)" fill="#e8f044" />
                                                    <path id="Path_83" data-name="Path 83" d="M1667.443,1200.581c0,.062.007.123.012.185h0c.309,3.409,2.951,5.96,5.9,5.7l83.268-7.453c2.95-.264,5.091-3.242,4.782-6.652-.013-.144-.032-.286-.053-.427Z" transform="translate(-1667.443 -1104.412)" fill="#e8f044" />
                                                </g>
                                                <path id="Path_84" data-name="Path 84" d="M1319.405,1278.525c3.09,5.415-2.005,22.145-2.484,22.151,4.558.183,49.363,18.056,49.363,18.056l13.715-.184s1.342-37.981-2.005-37.394-1.14,2.6-7.383,10.252c-.554.679-2.891,11.341-9.219,8.542C1348.646,1294.31,1327.365,1282.264,1319.405,1278.525Z" transform="translate(-1047.698 -1095.03)" fill="#ffe4e4" />
                                                <path id="Path_85" data-name="Path 85" d="M1330.333,1315.185l-.5-41.551c-.171-1.5-1.395-1.834-2.13-1.295-1.514,1.11-5.017,9.117-7.34,14.5,1.2,9.311-2.179,25.745-4.287,27.271C1321.41,1317.991,1330.17,1316.024,1330.333,1315.185Z" transform="translate(-997.526 -1090.425)" fill="#ef4339" />
                                                <path id="Path_86" data-name="Path 86" d="M1572.768,1254.976c3.918-1.626,69.848,17.277,69.848,17.277l6.059-26.39s-69.326-22.068-81.578-20.072-127.034,12.5-116,41.007S1514.377,1279.206,1572.768,1254.976Z" transform="translate(-1353.934 -1057.153)" fill="#0779e4" />
                                                <path id="Path_87" data-name="Path 87" d="M1717.1,1563.227a137.489,137.489,0,0,0-20.45-1.545c.75,5.667,4.393,34.727,3.727,36.512a21.811,21.811,0,0,1-8.681,10.62s28.217,25.459,29.921,22.518-1.258-2.547-3.1-12.248c-.164-.861-4.243-14.4-4.5-21.7C1713.534,1583.452,1716.887,1570.961,1717.1,1563.227Z" transform="translate(-1495.798 -1297.916)" fill="#ffe4e4" />
                                                <path id="Path_88" data-name="Path 88" d="M1855.273,1227.066c-3.936-2.1-14.156-2.173-25.392-1.445a257.847,257.847,0,0,0-64.372,12.634l-13.946,4.619.046-14.3s-44.363-7.423-45.906,3.572c-2.324,16.558,1.721,50.061,20.161,51.949,81.667,8.36,105.431-31.4,105.664-23.494.312,10.618,9.265,50.131,11.553,67.431,6.365.11,17.3.105,22.043-.983C1866.046,1293.162,1866.478,1233.045,1855.273,1227.066Z" transform="translate(-1643.027 -1056.824)" fill="#0869c5" />
                                                <path id="Path_89" data-name="Path 89" d="M1681.87,1701.517l31.719,25.338c1.257.832,2.3.112,2.361-.8.124-1.874-3.753-9.707-6.38-14.947-7.9-5.067-18.325-18.283-18.139-20.877C1688.087,1693.056,1681.332,1700.853,1681.87,1701.517Z" transform="translate(-1486.834 -1390.024)" fill="#ef4339" />
                                                <path id="Path_90" data-name="Path 90" d="M2161.345,663.284c-1.066-9.74-2.187-19.516-10.262-26.655-6.534-5.777-14.842-6.756-23.056-3.665-23.276,8.758-11.283,29.429-40.962,50.27-9.221,6.476-19.386,10.909-26.329,19.629-9.719,12.206-6.049,26.888,2.808,36.691,42.38,46.9,89.694-16.44,93.091-31.646C2159.163,696.6,2162.031,669.554,2161.345,663.284Z" transform="translate(-2038.107 -631.241)" fill="#1b262c" />
                                                <path id="Path_91" data-name="Path 91" d="M2117.076,884.255a129.264,129.264,0,0,0-7.028-11.446c-3.59-6.383-12.867-9.612-12.867-9.612s-3.1.063-6.667.238a2.82,2.82,0,0,1-.672,1.183c-1.339,1-9.013-.292-13.97,3.073-16.614,14.242-12.019,83.272-10.712,105.075l50.6.479s-6.379-46.913,3.9-58.966C2126.82,905.886,2122.211,893.722,2117.076,884.255Z" transform="translate(-2002.443 -797.441)" fill="#ff8805" />
                                                <path id="Path_92" data-name="Path 92" d="M2098.995,774.651s-2.781,19.863-4.723,24.691,10.681,15.15,21.185,18.283-3.21-10.662-2.775-15.823,2.344-29.591,2.344-29.591Z" transform="translate(-2007.17 -732.248)" fill="#ffe4e4" />
                                                <path id="Path_93" data-name="Path 93" d="M2125.946,779.217c.307-4.042.53-7.006.53-7.006l-16.03,2.44s-.4,2.334-.851,5.634C2116.094,784.055,2121.938,782.865,2125.946,779.217Z" transform="translate(-2018.62 -732.248)" fill="#f1d0d0" />
                                                <path id="Path_94" data-name="Path 94" d="M2094.589,652.382a18.21,18.21,0,0,0-10.22,7.378c-3.9,5.954-6.579,23.482,6.272,31.663s22.843-3.392,23.009-14.679S2111.4,650.392,2094.589,652.382Z" transform="translate(-1997.737 -646.261)" fill="#ffe4e4" />
                                                <path id="Path_95" data-name="Path 95" d="M2074.98,710.579c-1.1,2.724-3.272,4.414-4.858,3.776s-1.984-3.365-.887-6.089,3.271-4.414,4.857-3.776S2076.076,707.856,2074.98,710.579Z" transform="translate(-1956.126 -683.629)" fill="#ffe4e4" />
                                                <path id="Path_96" data-name="Path 96" d="M2163.831,651.524s-8.056,18.069-15.133,19.682-6.974,10.705-6.974,10.705.159-21,6.791-25.811S2163.831,651.524,2163.831,651.524Z" transform="translate(-2065.082 -645.773)" fill="#1b262c" />
                                                <path id="Path_97" data-name="Path 97" d="M2191.413,723.351c.932,2.934.079,5.824-1.9,6.453s-4.346-1.238-5.278-4.173-.079-5.824,1.9-6.453S2190.481,720.416,2191.413,723.351Z" transform="translate(-2104.986 -694.162)" fill="#ffe4e4" />
                                                <path id="Path_98" data-name="Path 98" d="M1951.922,1053.361c-3.036-1.1-7.555-1.234-7.479-1.635.305-1.622,13.716,3.581,11.9,1.214-1.008-1.317-11.045-5.986-13.486-5.215-2.367.748-17.911,5.11-17.911,5.11l-42.481-9.956s-10.723-24.366-11.769-45.3a58.387,58.387,0,0,0-22.6,7.665c3.715,20.02,12.745,52.47,22.727,58.56,4.2,2.562,54.336-3.066,54.336-3.066s11.049-.781,12.854-1.475c1.369-.527,4.895-2.512,6.813-3.056,4.359-1.234,12.125,1.865,12.15,1.011C1956.994,1056.595,1955.023,1054.482,1951.922,1053.361Z" transform="translate(-1774.99 -893.726)" fill="#ffe4e4" />
                                                <path id="Path_99" data-name="Path 99" d="M2174.563,880.373c-.409-6.442-2.849-12.661-9.7-14.664a13.877,13.877,0,0,0-12.408,2.719c-4.038,3.3-5.166,8.59-5.147,13.6a194.36,194.36,0,0,0,2.895,34.356,73.516,73.516,0,0,1,25.559-8.044C2175.755,908.271,2175.1,888.886,2174.563,880.373Z" transform="translate(-2078.593 -798.889)" fill="#ef4339" />
                                            </g>
                                            <g id="Group_9626" data-name="Group 9626" transform="translate(272.815)">
                                                <rect id="Rectangle_2" data-name="Rectangle 2" width="199.434" height="58.455" rx="5" transform="translate(0 0)" fill="#ffe4e4" />
                                                <rect id="Rectangle_14" data-name="Rectangle 14" width="12.993" height="2.675" transform="translate(168.122 6.018)" fill="#ffa5a5" />
                                                <rect id="Rectangle_15" data-name="Rectangle 15" width="158.793" height="7.387" transform="translate(17.744 41.77)" fill="#ffa5a5" />
                                            </g>
                                            <path id="Path_63" data-name="Path 63" d="M1004.859,660.381h-7.977l5.641-5.641a.6.6,0,0,0-.851-.851l-5.641,5.641v-7.977a.6.6,0,1,0-1.2,0v7.977l-5.641-5.641a.6.6,0,0,0-.851.851l5.641,5.641H986a.6.6,0,0,0,0,1.2h7.977l-5.641,5.64a.6.6,0,1,0,.851.851l5.641-5.64v7.977a.6.6,0,0,0,1.2,0v-7.977l5.641,5.64a.6.6,0,1,0,.851-.851l-5.641-5.64h7.977a.6.6,0,1,0,0-1.2Z" transform="translate(-856.31 -476.463)" fill="#e8f044" />
                                            <path id="Path_64" data-name="Path 64" d="M1336.489,786.294l-6.581-2.558,6.462-2.844a.533.533,0,1,0-.429-.975l-6.462,2.844,2.558-6.581a.533.533,0,1,0-.993-.386l-2.559,6.581-2.844-6.462a.533.533,0,0,0-.975.429l2.844,6.463-6.58-2.559a.533.533,0,1,0-.386.993l6.581,2.559-6.462,2.844a.533.533,0,1,0,.429.975l6.462-2.844L1325,791.353a.533.533,0,1,0,.993.386l2.559-6.581,2.844,6.463a.533.533,0,0,0,.975-.429l-2.844-6.463,6.581,2.559a.533.533,0,0,0,.386-.993Z" transform="translate(-1142.595 -672.211)" fill="#e8f044" />
                                            <path id="Path_65" data-name="Path 65" d="M525.617,430.255l-5.1,2.661,1.723-5.485a.434.434,0,0,0-.828-.26l-1.723,5.485-2.66-5.1a.434.434,0,0,0-.769.4l2.661,5.1-5.485-1.722a.434.434,0,0,0-.26.828l5.485,1.722-5.1,2.661a.434.434,0,0,0,.4.769l5.1-2.661-1.722,5.485a.434.434,0,0,0,.828.26l1.722-5.485,2.66,5.1a.434.434,0,1,0,.769-.4l-2.66-5.1,5.485,1.722a.434.434,0,0,0,.26-.828l-5.485-1.722,5.1-2.661a.434.434,0,1,0-.4-.769Z" transform="translate(39.146 -324.116)" fill="#e8f044" />
                                            <g id="Group_992" data-name="Group 992" transform="translate(244.997 63.813)">
                                                <path id="Path_78" data-name="Path 78" d="M1393.389,614.691c4.345-4.533,4.06-4.818,5.846-9.628,1.043-2.971,4.493-6.94,4.475-6.919a19.674,19.674,0,0,1,2.995-2.634c10.869-7.295,12.653-8.449,16.078-18.539,4.3-12.672,6.8-11.315,13.814-18.847,6.9-7.469,4.539-14.824,7.778-22.719l-.034-.015c-2.545,5.54-2.335,11.988-4.952,17.323-3.094,6.075-10.3,9.433-13.627,13.934-2.409,3.268-3.221,5.333-4.882,9.633-4.119,10.7-6.184,10.382-15.583,16.529l-1.3.89a18.01,18.01,0,0,0-2.657,2.281l-.4.435c-8.055,9.531-3.988,9.249-9.214,14.355l-1.038.953Z" transform="translate(-1390.687 -528.983)" fill="#ffe4e4" />
                                                <path id="Path_79" data-name="Path 79" d="M665.339,617.373c-.52.356-14.171-9.887-19.5-27.312-1.177-3.849-1.042-8.061-2.173-11.819-3.513-11.667-15.119-13.292-15.381-32.657-.281-18.538-1.981-15.825-8.069-29.175a28.454,28.454,0,0,1-2.285-7.246l-.046.006c.584,5.1,2.993,9.656,5.2,14.337,9.666,20.475-3.636,23.89,10.683,43.958,2.223,3.1,5.584,6.963,6.844,10.649,1.6,4.675,1,10.332,2.447,15.228a53,53,0,0,0,18.19,26.831Z" transform="translate(-422.83 -509.164)" fill="#ffe4e4" />
                                                <path id="Path_80" data-name="Path 80" d="M547.435,601.859c-5.744-3.571-12.555-4.371-17.239-9.347-3.341-3.55-5.288-12.085-12.5-16.976-3.725-2.525-7.891-4.479-10.944-7.881-3.963-4.345-4.262-12.542-8.534-18.124-3.583-4.57-9.065-7.468-11.873-12.653l-.033.017c3.576,7.341,10.424,8.788,13.774,18.177,1.457,4.14,2.01,7.885,4.107,11.735a17.207,17.207,0,0,0,3.351,4.116c3.908,3.577,7.81,5.01,11.637,8.926,5.471,5.6,5.674,16.145,16.464,21.028.494.223,9.824,3.716,9.448,4.236C545.09,605.114,547.439,601.861,547.435,601.859Z" transform="translate(-272.778 -530.107)" fill="#ffe4e4" />
                                            </g>
                                            <g id="Group_874" data-name="Group 874" transform="translate(0 383.409)">
                                                <path id="Path_6" data-name="Path 6" d="M2474.568,1769.387h-5.313l-1.436-13.52H2476Z" transform="translate(-2359.202 -1561.751)" fill="#9ba4b4" />
                                                <path id="Path_7" data-name="Path 7" d="M2616.89,1769.351h-5.313l-1.435-13.529h8.184Z" transform="translate(-2530.536 -1561.715)" fill="#9ba4b4" />
                                                <path id="Path_8" data-name="Path 8" d="M2358.834,1769.341h-5.313l-1.436-13.52h8.185Z" transform="translate(-2219.874 -1561.715)" fill="#8bcdcd" />
                                                <rect id="Rectangle_8" data-name="Rectangle 8" width="91.183" height="92.463" transform="translate(52.878 101.967)" fill="#e5eff0" />
                                                <rect id="Rectangle_9" data-name="Rectangle 9" width="26.442" height="92.463" transform="translate(117.618 101.967)" fill="#8bcdcd" />
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="62.254" height="1.594" transform="translate(55.451 132.255)" fill="#9ba4b4" />
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="62.254" height="1.594" transform="translate(55.451 157.839)" fill="#9ba4b4" />
                                                <g id="Group_30" data-name="Group 30" transform="translate(38.988 113.465)">
                                                    <path id="Path_51" data-name="Path 51" d="M2673.455,1429.919s24.221-17.979,27.541-25.727-3.525-6.874-3.525-6.874,9.781-13.857,7.184-18.436-8,1.269-8,1.269,4.536-18.747.591-19.79c-7.348-1.942-13.377,18.233-13.377,18.233s.542-8.377-4.058-7.248c-6.937,1.7-3.267,19.735-3.267,19.735s-2.21-3.2-6.015-.156C2663.708,1396.384,2673.455,1429.919,2673.455,1429.919Z" transform="translate(-2668.108 -1360.229)" fill="#8bcdcd" />
                                                </g>
                                                <path id="Path_52" data-name="Path 52" d="M2708.835,1391.392a5.5,5.5,0,0,1,7.041-.311s-3.669-18.034,3.267-19.735c4.6-1.129,4.059,7.248,4.059,7.248s6.03-20.175,13.377-18.233l-23.976,69.5C2711.531,1425.522,2702.461,1396.491,2708.835,1391.392Z" transform="translate(-2668.449 -1246.765)" fill="#79b9b9" />
                                                <g id="Group_32" data-name="Group 32" transform="translate(44.367 147.218)">
                                                    <path id="Path_53" data-name="Path 53" d="M2612.108,1561.117s25.256.226,30.38-4.075-1.6-4.889-1.6-4.889,11.862-6.3,11.689-10.5-4.195-3.353-4.195-3.353,8.2-7.345,6.517-10.9c-2.587-5.471-14.314,4.905-14.314,4.905s.9-4.161-2.739-5.031c-5.485-1.311-11.049,14.283-11.049,14.283s2.623-7.025-1.145-6.244C2618.894,1536.712,2612.108,1561.117,2612.108,1561.117Z" transform="translate(-2612.108 -1525.805)" fill="#ffa5a5" />
                                                </g>
                                                <g id="Group_33" data-name="Group 33" transform="translate(16.121 115.991)">
                                                    <path id="Path_54" data-name="Path 54" d="M2821.064,1440.545s-22.031-18.8-24.719-26.374,3.77-6.326,3.77-6.326-8.465-13.777-5.717-17.977,7.532,1.693,7.532,1.693-3.181-18.112.637-18.865c7.109-1.4,11.622,18.159,11.622,18.159s-.007-8,4.3-6.65c6.5,2.04,1.912,18.975,1.912,18.975s2.4-6.331,5.832-3.206C2832.4,1405.581,2821.064,1440.545,2821.064,1440.545Z" transform="translate(-2793.85 -1372.624)" fill="#ffa5a5" />
                                                </g>
                                                <path id="Path_55" data-name="Path 55" d="M2814.652,1398.817c-3.507-2.551-6.476.777-6.476.777s3.735-13.353-2.761-15.393c-4.307-1.352-4.332,6.406-4.332,6.406s-4.481-19.318-11.591-17.915l18.6,67.58C2810.109,1435.641,2819.125,1402.07,2814.652,1398.817Z" transform="translate(-2764.653 -1256.629)" fill="#f19696" />
                                                <g id="Group_36" data-name="Group 36" transform="translate(0 162.734)">
                                                    <g id="Group_35" data-name="Group 35">
                                                        <path id="Path_56" data-name="Path 56" d="M2880.21,1620.4s-24.216.111-27.567-.382.353-3.4.353-3.4-7.53.274-8.678-1.557,1.928-2.553,1.928-2.553-7.5-2.173-7.141-3.768c.667-2.97,8.949-1.034,8.949-1.034s-3.076-1.494-1.826-2.882c1.885-2.094,9.938,3.6,9.938,3.6s-3.022-5.719-1.239-6.443C2858.127,1600.685,2880.21,1620.4,2880.21,1620.4Z" transform="translate(-2839.092 -1601.923)" fill="#8bcdcd" />
                                                    </g>
                                                </g>
                                                <path id="Path_57" data-name="Path 57" d="M2695.245,1716.964h40.278a24.108,24.108,0,0,0,8.141-18.218,21.812,21.812,0,0,0-2.328-9.752l-51.931.584a23.108,23.108,0,0,0-2.148,10.1A23.96,23.96,0,0,0,2695.245,1716.964Z" transform="translate(-2671.597 -1508.51)" fill="#ff8805" />
                                                <path id="Path_58" data-name="Path 58" d="M2648.644,1265.3h26.75a4.472,4.472,0,0,0,4.295-4.631h0a4.472,4.472,0,0,0-4.295-4.632h-26.75a7.534,7.534,0,0,1,.191,9Z" transform="translate(-2599.748 -1163.811)" fill="#fae0b2" />
                                                <path id="Path_59" data-name="Path 59" d="M2602.381,1258.792h0a4.5,4.5,0,0,0-4.345-4.631H2571.2a.233.233,0,0,1-.225-.242h0a.233.233,0,0,1,.225-.238h42.569a4.961,4.961,0,0,1,4.8,5.112h0a4.961,4.961,0,0,1-4.8,5.112H2571.2a.233.233,0,0,1-.225-.238h0a.233.233,0,0,1,.225-.242h26.834A4.5,4.5,0,0,0,2602.381,1258.792Z" transform="translate(-2522.793 -1161.936)" fill="#ff8805" />
                                                <g id="Group_41" data-name="Group 41" transform="translate(91.533 28.656)">
                                                    <rect id="Rectangle_16" data-name="Rectangle 16" width="0.85" height="34.734" transform="translate(0)" fill="#1b262c" />
                                                </g>
                                                <g id="Group_43" data-name="Group 43" transform="translate(70.532 33.616)">
                                                    <g id="Group_42" data-name="Group 42" transform="translate(14.592)">
                                                        <rect id="Rectangle_17" data-name="Rectangle 17" width="2.066" height="66.945" transform="translate(0)" fill="#1b262c" />
                                                    </g>
                                                    <path id="Path_67" data-name="Path 67" d="M2572.256,1285.855c-7.334,0-13.28-1.313-13.28-2.933a1.139,1.139,0,0,1,.581-.859l-.064-.019h-5.338l-.017,0a1.154,1.154,0,0,1,.6.873c0,2.074-8.324,2.947-13.449,2.932h0v.635h31.294v-.635l-.006,0Z" transform="translate(-2541.285 -1218.134)" fill="#1b262c" />
                                                </g>
                                                <path id="Rectangle_18" data-name="Rectangle 18" d="M0,0H16.955a0,0,0,0,1,0,0V0a2.5,2.5,0,0,1-2.5,2.5H2.5A2.5,2.5,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(78.405 31.639)" fill="#1b262c" />
                                                <path id="Path_68" data-name="Path 68" d="M2552.476,833.214c0,.684-2.881,1.3-7.519,1.739-15.511,1.464-41.509.4-41.509-1.739,0-.085,16.016-29.588,16.016-29.588h17S2552.476,833.129,2552.476,833.214Z" transform="translate(-2442.936 -803.626)" fill="#ff8805" />
                                                <path id="Path_69" data-name="Path 69" d="M2585.382,1109.372a1.441,1.441,0,1,1-1.436-1.32A1.381,1.381,0,0,1,2585.382,1109.372Z" transform="translate(-2491.96 -1045.994)" fill="#1b262c" />
                                            </g>
                                            <g id="Group_10443" className="texwrite" data-name="Group 10443" transform="translate(259.545 225.969)">
                                                <rect id="Rectangle_994" data-name="Rectangle 994" width="277" height="38" rx="2" transform="translate(0)" fill="#fff" />
                                                <text className="write" data-text="" transform="translate(10 26)" fill="#646464" font-size="18" font-family="SF UI Display"></text>
                                                <text className="item"> <tspan x="0" y="0"> Shadow Boxing </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0"> Residential </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Outdoor Work </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Lights/Recess Lights </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0"> Outdoor Post Lights </tspan> </text>
                                                <text className="item"> <tspan x="0" y="0">  Buy Property </tspan> </text>
                                                <g id="Group_9627" data-name="Group 9627" transform="translate(243.731 7.747)">
                                                    <g id="Ellipse_14" data-name="Ellipse 14" transform="translate(0)" fill="#fff" stroke="#0779e4" stroke-width="1.5">
                                                        <ellipse cx="10.06" cy="10.06" rx="10.06" ry="10.06" stroke="none" />
                                                        <ellipse cx="10.06" cy="10.06" rx="9.31" ry="9.31" fill="none" />
                                                    </g>
                                                    <g id="Rectangle_40" data-name="Rectangle 40" transform="translate(17.253 14.089) rotate(45)" fill="#0779e4" stroke="#0779e4" stroke-width="1">
                                                        <rect width="8.263" height="3.305" rx="0.5" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="7.263" height="2.305" fill="none" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>



                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video-list-section aboutVideoLIst-section pad-b-100 pad-t-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="video-block">
                                <div className="video-img">
                                    {/* <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div> */}
                                    {/* <img src={images.adv3} className="img-fluid h-100" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" onClick={()=>window.open("https://youtu.be/TZHTX_UeQKc")}><i className="fas fa-play text-warning"></i></a>
                                    </div> */}

<img 
                                            // src={images.adv2} 
                                            src={config.endpoints.image_path + aboutUsVideo?.image}
                                            className="img-fluid h-100 w-100" alt="" 
                                            // onClick={()=>window.open(images.adv2)}
                                            onClick={()=>window.open(config.endpoints.image_path + aboutUsVideo?.image)}
                                            />

{
                    aboutUsVideo?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(aboutUsVideo?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about3col-section">
                <div className="container">
                     <div className="row">
                        <div className="col-12">
                            <div className="hiw-list aboutUs-col-list">
                    {/*            <div className="hiw-item wow fadeInUp delay-1s">
                                    <div className="hiw-icon">
                                        <img src={images.about1} className="m-auto d-block img-fluid" alt="" />
                                    </div>
                                    <div className="hiw-content">
                                        <div className="hiw-title">
                                            <h3> Lorem ipsum dolor sit </h3>
                                        </div>
                                        <div className="hiw-inner-content">
                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla tempor, ante at faucibus ultrices sapien ex auctor lectus. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="hiw-item wow fadeInUp delay-4s">
                                    <div className="hiw-icon">
                                        <img src={images.about2} className="m-auto d-block img-fluid" alt="" />
                                    </div>
                                    <div className="hiw-content">
                                        <div className="hiw-title">
                                            <h3> Lorem ipsum dolor sit </h3>
                                        </div>
                                        <div className="hiw-inner-content">
                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla tempor, ante at faucibus ultrices sapien ex auctor lectus. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="hiw-item wow fadeInUp delay-7s">
                                    <div className="hiw-icon">
                                        <img src={images.about3} className="m-auto d-block img-fluid" alt="" />
                                    </div>
                                    <div className="hiw-content">
                                        <div className="hiw-title">
                                            <h3> Submit your request </h3>
                                        </div>
                                        <div className="hiw-inner-content">
                                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla tempor, ante at faucibus ultrices sapien ex auctor lectus. </p>
                                        </div>
                                    </div>
                                </div>*/}
                            </div> 
                        </div>
                    </div> 
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default AboutUs;
