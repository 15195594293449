import React, { useEffect, useState } from 'react';
import { APIService } from './Apis/APIServices';
import Dialogue from './Common/Dialogue';
import Footer from './Common/Footer';
import Header from './Common/Header';

const CreateNewPassword = (props) => {
    
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
    const [
        state,
        setState
    ] = useState({
        password : "",
        confirm_password : ""
    })
    const [viewPass, setViewPass] = useState(false)
    const [viewConfPass, setViewConfPass] = useState(false)
    // useEffect(()=>{
    //     if(props.location.state.email){
    //         props.history.replace('', null);
    //     }else{
    //         window.location.href = '/login'
    //     }
    // },[])
    const handleReset = ()=>{
        if(state.password !== state.confirm_password){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Password does not match.'
            })
            return;
        }
        APIService.updatePassword({password:state.password})
        .then(resp=>{
            if(resp.status === 200){
                localStorage.removeItem("_otp_tk")
                APIService.logout()
                window.location.href='/password-updated'
            }
        })
        .catch(err=>console.log(err))
    }

    const handleChange = (e)=>{
        setState(prevState=>({
            ...prevState,
            [e.target.name] : e.target.value
        }))
    }

    return (
        <>
            <Header />
            <section className="auth-wrapper auth-login-box createNewPass-box">
                <div className="auth-right-blue">
                    <img src="images/reset-password-graphic.svg" className="img-fluid" alt="" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12">
                            <div className="auth-white-box auth-block">
                                <div className="m_header">
                                    <h2> Create New Password </h2>
                                    <p> Enter and confirm a new password <br className="d-none d-xl-block" /> for the account </p>
                                </div>
                                <div className="m_body">
                                    <div className="auth-form">
                                        <div className="form-group">
                                            <div className="password-group">
                                                <input type={viewPass?"text":"password"} onChange={handleChange} id="password-field" name="password" placeholder="Password" className="form-control" />
                                                <button type="button" onClick={()=>setViewPass(prevState=>(!prevState))} className="toggle-password password-eye"> <i className="far fa-eye-slash eye-btn"></i> </button>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="password-group">
                                                <input type={viewConfPass?"text":"password"} onChange={handleChange} id="confirm-password-field" name="confirm_password" placeholder="Confirm Password" className="form-control" />
                                                <button type="button" onClick={()=>setViewConfPass(prevState=>(!prevState))} className="toggle-password password-eye"> <i className="far fa-eye-slash eye-btn"></i> </button>
                                            </div>
                                        </div>

                                        <div className="form-submit text-center">
                                            <button disabled={!state.password || !state.confirm_password} type="button" className="btn btn-md btn-red w-100" onClick={handleReset}> Reset Password </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialogue state={dialogueState} setState={setDialogueState}/>
            </section>
        </>
    );
}

export default CreateNewPassword;
