import React, { useEffect, useState } from 'react';
import images from '../../Utility/Images';

const OurFeatureSection = () => {

    const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")
    const [animated2Name, setAnimated2Name] = useState("none")
    const [animated3Name, setAnimated3Name] = useState("none")


    useEffect(()=>{
		var target = document.querySelector('.ourFeature-section');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target.getBoundingClientRect().bottom) {
			// console.log('I have been reached');
			setHideOrShow("visible")
			setAnimatedName("fadeInUP")
            setAnimated2Name("slideInLeft")
            setAnimated3Name("slideInRight")
		  }
		})
	},[])

    return (
        <>
            <section className="ourFeature-section pad-b-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title wow fadeInUp animated hinge" style={{visibility: hideOrShow, animationName: animatedName}}>
                                <h2> Our <span className="text-orange font-weight-bold"> Features </span></h2>
                            </div>
                            <div className="section-des">
                                <p> eZunction offers a reliable, secure and easy to use platform <br className="d-none d-lg-block" /> with a wide range of trusted professionals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="ourFeature-list wow slideInLeft animated hinge" style={{visibility: hideOrShow, animationName: animated2Name}}>
                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="60" height="59.993" viewBox="0 0 60 59.993">
                                            <g id="Group_10626" data-name="Group 10626" transform="translate(-5299 -6651)">
                                                <path id="Path_41768" data-name="Path 41768" d="M105.938,134.727v.849c0,.811,0,12.867,0,13.69,0,1.048.375,1.377,1.417,1.291,2.26-.187,4.522-.364,6.783-.548.266-.022.53-.06.815-.093-.227-4.122,1.313-7.272,4.985-9.2a8.7,8.7,0,0,1,8.47.3l-.978,1.686a6.983,6.983,0,0,0-5.881-.505,6.828,6.828,0,0,0-3.628,3,6.986,6.986,0,0,0,10,9.3c2.527-1.769,3.708-5.28,2.593-7.882l-6.481,6.484-3.707-3.7,1.389-1.288,2.136,2.25,7.186-7.2a10.186,10.186,0,0,1,1.9,5.255c1.352-.105,2.672-.2,3.992-.312.643-.052,1.286-.107,1.927-.18a1.018,1.018,0,0,0,1.063-1.185c.006-2.29,0-4.58,0-6.871q0-8.245,0-16.49c0-1.171-.337-1.472-1.514-1.374-2.135.178-4.27.351-6.467.531v16.264h-1.981V122.711l-9,.744v12.578l-9.982.833V124.279c-1.442.135-2.91.225-4.362.434-.565.081-.678.644-.676,1.191m7.027,8.791,5.938-.5V123.632l-5.938.493Z" transform="translate(5206.067 6545.225)" fill="#ff8805" />
                                                <g id="Group_10095" data-name="Group 10095" transform="translate(5299 6651)">
                                                    <path id="Path_41768-2" className="hover-fill-white" data-name="Path 41768" d="M104.267,133.033h-2v.014c0-3.1,0-5.763,0-8.865a2.98,2.98,0,0,1,2.917-3.236c4.934-.424,9.871-.823,14.806-1.238q7.839-.658,15.677-1.325c.539-.045,1.08-.122,1.618-.114a3,3,0,0,1,2.957,3.137q.01,8.682,0,17.364,0,3.092,0,6.184a3,3,0,0,1-2.944,3.277q-2.828.266-5.661.473a.615.615,0,0,0-.671.547,8.964,8.964,0,0,1-16.957,1.371.58.58,0,0,0-.709-.392c-2.591.231-5.184.446-7.775.667a2.967,2.967,0,0,1-3.227-2.641c-.087-1.028-.021-14.185-.021-15.254h1.982c0,.811,0,13.748.005,14.571,0,1.048.375,1.377,1.417,1.291,2.26-.187,4.522-.364,6.783-.548.266-.022.53-.06.815-.093-.227-4.122,1.313-7.272,4.985-9.2a8.7,8.7,0,0,1,8.47.3l-.978,1.686a6.983,6.983,0,0,0-5.881-.505,6.828,6.828,0,0,0-3.628,3,6.986,6.986,0,0,0,10,9.3c2.527-1.769,3.708-5.28,2.593-7.882l-6.481,6.484-3.707-3.7,1.389-1.288,2.136,2.25,7.186-7.2a10.186,10.186,0,0,1,1.9,5.255c1.352-.105,2.672-.2,3.992-.312.643-.052,1.286-.107,1.927-.18a1.018,1.018,0,0,0,1.063-1.185c.006-2.29,0-4.58,0-6.871q0-8.245,0-16.49c0-1.171-.337-1.472-1.514-1.374-2.135.178-4.27.351-6.467.531v16.264h-1.981V121.018l-9,.744V134.34l-9.982.833V122.585c-1.442.135-2.91.225-4.362.434-.565.081-.678.644-.676,1.191M111.289,133l5.938-.5V121.938l-5.938.493Z" transform="translate(-91.261 -104.081)" fill="#ef4339" />
                                                    <path id="Path_41769" className="hover-fill-white" data-name="Path 41769" d="M50.716,91.894l1.57-1.129a28.223,28.223,0,0,0,21.7,11.564V99.37h2v2.878c5.3.38,14.189-3.3,18.061-7.485l-1.86-1.592L93.824,91.7l1.5,1.712c3.872-3,8.037-13.025,7.556-18.063h-1.913v-2h1.979a27.991,27.991,0,0,0-7.5-18.1l-1.713,1.85L92.38,55.642l1.8-1.671a28.047,28.047,0,0,0-18.2-7.608v2.958H73.994V46.428a27.371,27.371,0,0,0-18.121,7.515L57.818,55.5,56.27,57.034l-1.691-1.852A28.006,28.006,0,0,0,47.01,73.32h2.944v2H47.029a28.131,28.131,0,0,0,2.046,9.628l-1.781.735a29.856,29.856,0,1,1,3.422,6.208Z" transform="translate(-44.986 -44.331)" fill="#ef4339" />
                                                    <path id="Path_41770" className="hover-fill-white" data-name="Path 41770" d="M53.168,267.5l-7.043,1.369a.988.988,0,0,1-1.158-.78l0-.019a.989.989,0,0,1,.781-1.16l7.041-1.374a.989.989,0,0,1,1.16.779l0,.024A.988.988,0,0,1,53.168,267.5Z" transform="translate(-44.944 -223.078)" fill="#ef4339" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> Quick and Easy </h3>
                                        </div>
                                        <p> Getting your work done is simple, quick and straight-forward with eZunction. Getting competitive quotes is quick, easy and hassle free experience - no phone-calls, follow-ups, asking a friend or a social community group.  </p>
                                    </div>
                                </div>

                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="57.086" height="60" viewBox="0 0 57.086 60">
                                            <g id="Group_10624" data-name="Group 10624" transform="translate(-4433 -6105.117)">
                                                <path id="Path_41763" data-name="Path 41763" d="M71.365,56.276c1.154,2.449,2.5,3.2,4.424,2.825a42.706,42.706,0,0,1,13.85,0c1.924.377,3.27-.377,4.425-2.825-.192-2.26-.192-4.9-.385-7.534-.577-4.709-6.156-6.78-10.965-6.78s-10.2,2.072-10.773,6.592c-.192,2.637-.192,5.273-.577,7.722Z" transform="translate(4378.545 6064.689)" fill="#ff8805" />
                                                <path id="Path_7" className="hover-fill-white" data-name="Path 7" d="M96.133,98.535a1.23,1.23,0,0,1-.926-.74L93.17,81.5a4.611,4.611,0,0,0-3.148-3.889L77.244,72.98,68.17,82.239,59.1,72.794,46.319,77.609A4.61,4.61,0,0,0,43.17,81.5l-2.037,16.3a.891.891,0,0,1-1.111.37c-.185-.185-.37-.37-.37-.556l2.222-16.3a6.2,6.2,0,0,1,4.259-5.185l13.148-5a7.763,7.763,0,0,0,1.667-2.592,13.336,13.336,0,0,1-2.778-5l-.185-.37-.926-.555-.185-.37a15.18,15.18,0,0,1-1.667-6.3v-.556l.37-.185h0v-.555c0-2.222.185-5,.185-7.592h0c.556-4.074,4.63-8.148,12.407-8.148h0c7.778,0,11.667,4.074,12.222,8.148h0c.185,2.777.185,5.555.37,7.777v.556h0l.37.185v.555a19.237,19.237,0,0,1-1.3,6.481l-.185.37-.926.555-.185.371a14.224,14.224,0,0,1-2.592,4.629v.185A5.094,5.094,0,0,0,77.429,71.5l12.963,5a6.774,6.774,0,0,1,4.259,5.185l2.037,16.3c.185,0-.185.555-.555.555ZM60.578,71.869l7.592,7.963,7.593-7.778v-.185a5.8,5.8,0,0,1-.926-1.852,14.827,14.827,0,0,1-3.889,2.777h0a22.533,22.533,0,0,1-5,0h0A11.025,11.025,0,0,1,62.059,70.2a6.649,6.649,0,0,1-1.3,1.852v.185Zm5.37-.741a16.389,16.389,0,0,0,4.259,0,13.786,13.786,0,0,0,6.667-7.778l.37-1.111,1.111-.556a16.689,16.689,0,0,0,1.111-4.074,4.717,4.717,0,0,1-4.815,1.667,35.943,35.943,0,0,0-12.592,0,4.717,4.717,0,0,1-4.815-1.667,17.573,17.573,0,0,0,.926,4.259l1.111.555.371,1.111a16.419,16.419,0,0,0,2.592,4.629c.185,0,.185.185.37.37a12.254,12.254,0,0,0,3.333,2.592ZM57.059,54.647c1.111,2.407,2.407,3.148,4.259,2.778a40.263,40.263,0,0,1,13.333,0c1.852.37,3.148-.37,4.259-2.778-.185-2.222-.185-4.815-.37-7.407-.555-4.63-5.926-6.667-10.555-6.667s-9.815,2.037-10.37,6.482c-.185,2.592-.185,5.185-.555,7.592Zm1.852.741a.838.838,0,1,1-.185-1.667h0a39.567,39.567,0,0,1,9.259-1.111,36.915,36.915,0,0,1,9.074,1.111c.37,0,.741.556.741.926s-.555.741-1.111.741h0a34.648,34.648,0,0,0-17.407,0Z" transform="translate(4393.348 6066.211)" fill="#ef4339" />
                                                <path id="Path_8" className="hover-fill-white" data-name="Path 8" d="M147.409,176.98a.847.847,0,0,1-.926-.741c0-.371.37-.926.741-.926h0l10.37-2.037-2.037-5.556a.878.878,0,1,1,1.666-.555l2.593,7.222-12.407,2.593Z" transform="translate(4313.739 5971.1)" fill="#ef4339" />
                                                <path id="Path_9" className="hover-fill-white" data-name="Path 9" d="M116.739,177.581h-.185l-12.222-2.407,2.592-7.222c.185-.37.741-.741,1.111-.556s.741.741.555,1.111h0l-2.037,5.556,10.37,2.037c.556,0,.741.37.741.926a.648.648,0,0,1-.926.556Z" transform="translate(4345.149 5970.5)" fill="#ef4339" />
                                                <path id="Path_10" className="hover-fill-white" data-name="Path 10" d="M140.739,65.208a3.7,3.7,0,1,1,3.7-3.7h0A3.715,3.715,0,0,1,140.739,65.208Zm0-5.741a2.037,2.037,0,1,0,2.037,2.037h0a2.055,2.055,0,0,0-2.037-2.037Z" transform="translate(4320.779 6052.131)" fill="#ef4339" />
                                                <path id="Path_11" className="hover-fill-white" data-name="Path 11" d="M148.862,221.3a.875.875,0,0,1-.926-.926v-16.3a.926.926,0,1,1,1.852,0h0v16.3C149.6,220.927,149.232,221.3,148.862,221.3Z" transform="translate(4312.657 5943.819)" fill="#ef4339" />
                                                <path id="Path_12" className="hover-fill-white" data-name="Path 12" d="M191.807,233.942H177.733v-4.629h14.074ZM179.4,232.276H190.14v-1.3H179.4Z" transform="translate(4290.452 5924.322)" fill="#ef4339" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> Skilled & Trusted Professionals </h3>
                                        </div>
                                        <p> Service providers on our platform are pre-screened and trusted with their credentials, ratings & feedbacks, experience and skills to complete the requests.  </p>
                                    </div>
                                </div>

                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="59.99" height="60" viewBox="0 0 59.99 60">
                                            <g id="Group_10625" data-name="Group 10625" transform="translate(0)">
                                                <path id="Shape" d="M485.1,122.619a15.5,15.5,0,1,1,15.5-15.5A15.5,15.5,0,0,1,485.1,122.619Z" transform="translate(-442.106 -64.117)" fill="#ff8805" />
                                                <path id="Shape-2" className="hover-fill-white" data-name="Shape" d="M469.087,69.217V46.108a4,4,0,0,0-4-4H424.1a4,4,0,0,0-4,4V78.1a4,4,0,0,0,4,4h3V89a2.095,2.095,0,0,0,2.1,2.1,2.124,2.124,0,0,0,1.143-.334L443.807,82.1h2.566a16.99,16.99,0,1,0,22.714-12.883ZM444.093,80.1h-7a1,1,0,0,0,0,2h3.014L429.26,89.075c-.016.009-.061.035-.164-.076V82.1h4a1,1,0,1,0,0-2h-9a2,2,0,0,1-2-2V46.108a2,2,0,0,1,2-2h40.991a2,2,0,0,1,2,2V68.6a16.91,16.91,0,0,0-20.243,11.5Zm19,20a15,15,0,1,1,15-15A15,15,0,0,1,463.089,100.1Z" transform="translate(-420.098 -42.109)" fill="#ef4339" />
                                                <path id="Shape-3" className="hover-fill-white" data-name="Shape" d="M448.916,77.491h36.992a1,1,0,1,0,0-2H448.916a1,1,0,1,0,0,2Z" transform="translate(-442.917 -69.492)" fill="#ef4339" />
                                                <path id="Shape-4" className="hover-fill-white" data-name="Shape" d="M513.291,103.309H482.3a1,1,0,1,0,0,2h30.993a1,1,0,0,0,0-2Z" transform="translate(-470.3 -92.312)" fill="#ef4339" />
                                                <path id="Shape-5" className="hover-fill-white" data-name="Shape" d="M448.916,105.309h2a1,1,0,1,0,0-2h-2a1,1,0,0,0,0,2Z" transform="translate(-442.917 -92.312)" fill="#ef4339" />
                                                <path id="Shape-6" className="hover-fill-white" data-name="Shape" d="M636.516,131.128h-4a1,1,0,1,0,0,2h4a1,1,0,1,0,0-2Z" transform="translate(-593.525 -115.131)" fill="#ef4339" />
                                                <path id="Shape-7" className="hover-fill-white" data-name="Shape" d="M448.916,133.128H477.91a1,1,0,1,0,0-2H448.916a1,1,0,1,0,0,2Z" transform="translate(-442.917 -115.131)" fill="#ef4339" />
                                                <path id="Shape-8" className="hover-fill-white" data-name="Shape" d="M536.11,158.946H510.116a1,1,0,1,0,0,2H536.11a1,1,0,1,0,0-2Z" transform="translate(-493.119 -137.95)" fill="#ef4339" />
                                                <path id="Shape-9" className="hover-fill-white" data-name="Shape" d="M448.916,160.946h7a1,1,0,1,0,0-2h-7a1,1,0,1,0,0,2Z" transform="translate(-442.917 -137.95)" fill="#ef4339" />
                                                <path id="Shape-10" className="hover-fill-white" data-name="Shape" d="M559.625,186.764h-5a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z" transform="translate(-529.631 -160.77)" fill="#ef4339" />
                                                <path id="Shape-11" className="hover-fill-white" data-name="Shape" d="M448.916,188.764h14a1,1,0,1,0,0-2h-14a1,1,0,0,0,0,2Z" transform="translate(-442.917 -160.77)" fill="#ef4339" />
                                                <path id="Shape-12" className="hover-fill-white" data-name="Shape" d="M465.912,214.583h-17a1,1,0,1,0,0,2h17a1,1,0,1,0,0-2Z" transform="translate(-442.917 -183.589)" fill="#ef4339" />
                                                <g id="Group_10117" data-name="Group 10117" transform="translate(41.669 31.428)">
                                                    <path id="Shape-13" className="hover-fill-white" data-name="Shape" d="M653.3,335.208a1.322,1.322,0,1,1,1.322-1.322A1.322,1.322,0,0,1,653.3,335.208Z" transform="translate(-651.979 -311.797)" fill="#ef4339" />
                                                    <path id="Shape-14" className="hover-fill-white" data-name="Shape" d="M654.623,234.68a1.322,1.322,0,1,1-2.644,0V218.322a1.322,1.322,0,0,1,2.644,0Z" transform="translate(-651.979 -217)" fill="#ef4339" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> Every Job is Important </h3>
                                        </div>
                                        <p> All requests are monitored and tracked for successful completion, with the same level of rigor and accountability.  </p>
                                    </div>
                                </div>

                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="61.902" height="57.971" viewBox="0 0 61.902 57.971">
                                            <g id="Group_10627" data-name="Group 10627" transform="translate(0.952 1.057)">
                                                <path id="Path_41767" className="hover-fill-white" data-name="Path 41767" d="M518.6,115.121l-17.725,15.133.473-3.659,17.495-14.677" transform="translate(-487.425 -111.803)" fill="#ff8805" />
                                                <path id="Path_41765" data-name="Path 41765" d="M-17.5,11.483-3.3-.02V6.494L-16.947,27.213Z" transform="translate(34.354 3.289)" fill="#ff8805" />
                                                <path id="Path_41764" data-name="Path 41764" d="M477.345,117.989h27.926l.416-15.314L491.758,90.618" transform="translate(-460.631 -87.626)" fill="#ff8805" />
                                                <path id="Path_41766" className="hover-fill-white" data-name="Path 41766" d="M501.03,115.064l17.127,15.076-.473-3.659-16.811-14.793" transform="translate(-469.762 -111.689)" fill="#ff8805" />
                                                <path id="Path_22" className="hover-stroke-white" data-name="Path 22" d="M477.624,113.93v15.593h27.741V113.93" transform="translate(-460.769 -99.173)" fill="none" stroke="#ef4339" stroke-linecap="round" strokeMiterlimit="10" stroke-width="2" />
                                                <path id="Path_23" className="hover-stroke-white" data-name="Path 23" d="M497.172,73.559,479.947,58.691,461.635,73.559l.544-3.264,17.769-14.5,16.681,14.5Z" transform="translate(-448.769 -55.539)" fill="none" stroke="#ef4339" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                                <path id="Path_24" className="hover-stroke-white" data-name="Path 24" d="M520.313,122.651H521.4a3.25,3.25,0,0,1,3.264,3.264v9.972h-7.8v-9.972A3.686,3.686,0,0,1,520.313,122.651Z" transform="translate(-490.222 -105.719)" fill="none" stroke="#ef4339" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                                <path id="Path_25" className="hover-stroke-white" data-name="Path 25" d="M423.476,140.058s-7.8-7.8-5.621-9.973,6.709,2.176,11.06,6.709c4.533,4.533,4.533,6.708,4.533,6.708v11.06h-8.884a7.618,7.618,0,0,0-2.176-4.533s-6.709-5.621-9.972-8.884-2.176-9.972-2.176-18.857,2.176-8.884,2.176-8.884c3.264-1.088,4.533,3.264,4.533,3.264v8.884" transform="translate(-410.066 -98.649)" fill="none" stroke="#ef4339" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                                <path id="Path_28" className="hover-stroke-white" data-name="Path 28" d="M566.811,140.058s7.8-7.8,5.621-9.973-6.709,2.176-11.06,6.709c-4.533,4.533-4.533,6.708-4.533,6.708v11.06h8.884a7.618,7.618,0,0,1,2.176-4.533s6.709-5.621,9.972-8.884,2.176-9.972,2.176-18.857-2.176-8.884-2.176-8.884c-3.263-1.088-4.532,3.264-4.532,3.264v8.884" transform="translate(-520.221 -98.649)" fill="none" stroke="#ef4339" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> We Value You</h3>
                                        </div>
                                        <p> eZunction takes utmost care of it's users and ensures a satisying experience. We value your feedback to continue to improve.</p>
                                    </div>
                                </div>

                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="60" height="58" viewBox="0 0 60 48.274">
                                            <g id="Group_10629" data-name="Group 10629" transform="translate(-5417 -7113)">
                                                <path id="Path_41773" data-name="Path 41773" d="M1249.722,84.562v.7c0,3.942.042,7.886-.019,11.827a6.961,6.961,0,0,0,1.638,4.689,26.558,26.558,0,0,0,8.959,6.634.755.755,0,0,0,.58-.076c.914-.481,1.831-.959,2.714-1.494a22.908,22.908,0,0,0,5.892-4.76,7.065,7.065,0,0,0,1.838-4.738c-.042-4.1-.012-8.2-.014-12.3a1.6,1.6,0,0,0-.025-.262c-2-.185-3.952-.241-5.858-.578a8,8,0,0,1-4.912-2.983,9.4,9.4,0,0,1-6.963,3.322C1252.312,84.633,1251.061,84.562,1249.722,84.562Z" transform="translate(4186.484 7034.473)" fill="#ff8805" />
                                                <g id="Group_10101" data-name="Group 10101" transform="translate(5417 7113)">
                                                    <path id="Path_41773-2" className="hover-fill-white" data-name="Path 41773" d="M1228.435,84.244c0-1.046.012-2.018,0-2.989-.014-.861.272-1.138,1.137-1.161,1.87-.051,3.754-.015,5.606-.235a6.2,6.2,0,0,0,4.554-2.681c.186-.272.309-.585.474-.872a.868.868,0,0,1,1.565-.054c1.485,3.037,4.2,3.73,7.216,3.836,1.19.042,2.382.007,3.573.011.714,0,1.031.321,1.032,1.038,0,1.011,0,2.022,0,3.107h.731q6.5,0,13.007,0c2.408,0,3.683,1.278,3.683,3.687q0,16.259,0,32.517a3.274,3.274,0,0,1-3.636,3.613h-52.73a3.284,3.284,0,0,1-3.582-2.685,4.538,4.538,0,0,1-.049-.816q0-16.405,0-32.81a3.249,3.249,0,0,1,3.509-3.505q6.562,0,13.124,0Zm-15.653,12.529v.719q0,11.482,0,22.965c0,1.287.524,1.815,1.811,1.818,1.757,0,3.515,0,5.273,0q23.785,0,47.57,0c1.283,0,1.807-.534,1.807-1.822q0-11.512,0-23.024v-.594a1.144,1.144,0,0,0-.2-.054q-7.674,0-15.349.008a.583.583,0,0,0-.429.32,10.522,10.522,0,0,1-2.185,3.639,29.584,29.584,0,0,1-9.656,6.785,1.169,1.169,0,0,1-.884-.02,32.211,32.211,0,0,1-8.386-5.5,11.61,11.61,0,0,1-3.323-4.746.64.64,0,0,0-.744-.506q-7.118.02-14.236.009Zm17.434-14.954v.7c0,3.942.042,7.886-.019,11.827a6.961,6.961,0,0,0,1.638,4.689,26.556,26.556,0,0,0,8.959,6.634.755.755,0,0,0,.58-.076c.914-.481,1.832-.959,2.715-1.494a22.908,22.908,0,0,0,5.891-4.76,7.064,7.064,0,0,0,1.838-4.738c-.042-4.1-.012-8.2-.014-12.3a1.6,1.6,0,0,0-.025-.262c-2-.185-3.952-.241-5.858-.578a8,8,0,0,1-4.912-2.983,9.4,9.4,0,0,1-6.963,3.322C1232.807,81.889,1231.555,81.818,1230.216,81.818Zm-1.815,4.227c-.166-.013-.263-.028-.359-.028q-6.791,0-13.582,0a1.506,1.506,0,0,0-1.678,1.715q-.007,3.366,0,6.733c0,.168.023.336.035.51H1228.4Zm40.841,8.935c0-2.408,0-4.748,0-7.088,0-1.356-.515-1.875-1.873-1.875q-6.586,0-13.171,0c-.188,0-.375.019-.57.029V94.98Z" transform="translate(-1211.011 -75.784)" fill="#ef4339" />
                                                    <path id="Path_41774" className="hover-fill-white" data-name="Path 41774" d="M1305.357,232.143c0-.638-.012-1.182,0-1.726.018-.646.353-1.016.89-1.017s.865.365.881,1.016c.014.543,0,1.086,0,1.724.6-.19,1.131-.362,1.663-.522a.892.892,0,0,1,1.237.524c.171.516-.073.938-.685,1.156-.526.188-1.058.36-1.646.559.372.519.708.97,1.024,1.434a.888.888,0,1,1-1.437,1.022c-.341-.445-.665-.9-1.037-1.412-.341.463-.637.867-.935,1.27-.469.634-.956.777-1.421.422-.444-.339-.455-.846-.028-1.445.294-.412.591-.821.934-1.3-.562-.188-1.074-.354-1.583-.53-.653-.225-.929-.658-.756-1.169.18-.53.641-.72,1.29-.522C1304.255,231.787,1304.752,231.95,1305.357,232.143Z" transform="translate(-1281.423 -194.123)" fill="#ef4339" />
                                                    <path id="Path_41775" className="hover-fill-white" data-name="Path 41775" d="M1349.863,233.776c-.557-.181-1.017-.328-1.475-.479-.755-.249-1.043-.651-.869-1.206s.659-.732,1.394-.5c.463.147.924.3,1.52.491,0-.668-.026-1.278.007-1.884a.825.825,0,0,1,1.27-.741,1.513,1.513,0,0,1,.473.918,10.372,10.372,0,0,1,.034,1.517c0,.036.029.071.065.152.529-.167,1.061-.343,1.6-.5a.888.888,0,1,1,.564,1.673c-.528.19-1.064.358-1.683.564.318.449.6.852.892,1.254.455.635.457,1.148,0,1.491s-.954.205-1.419-.425l-.932-1.264-.863,1.176c-.526.718-1,.883-1.491.515s-.463-.845.07-1.582C1349.282,234.582,1349.542,234.22,1349.863,233.776Z" transform="translate(-1316.133 -194.052)" fill="#ef4339" />
                                                    <path id="Path_41776" className="hover-fill-white" data-name="Path 41776" d="M1262.084,232.143c.636-.2,1.169-.378,1.707-.537a.888.888,0,1,1,.528,1.684c-.528.189-1.063.359-1.662.56.359.5.69.947,1.005,1.4a.893.893,0,1,1-1.429,1.042c-.343-.447-.669-.907-1.029-1.4-.361.491-.686.95-1.029,1.4a.891.891,0,0,1-1.313.3.907.907,0,0,1-.12-1.344c.313-.446.641-.883,1.017-1.4-.606-.2-1.169-.381-1.723-.585a.887.887,0,0,1-.617-1.151.877.877,0,0,1,1.089-.544c.578.161,1.147.356,1.809.564,0-.6,0-1.135,0-1.671.008-.685.333-1.073.886-1.073s.871.386.879,1.075C1262.088,231,1262.084,231.522,1262.084,232.143Z" transform="translate(-1246.72 -194.12)" fill="#ef4339" />
                                                    <path id="Path_41777" className="hover-fill-white" data-name="Path 41777" d="M1396.327,234.943c-.33.45-.655.911-1,1.357a.895.895,0,1,1-1.434-1.043c.153-.225.32-.442.48-.663s.318-.442.533-.74c-.6-.2-1.161-.379-1.714-.582a.884.884,0,0,1-.619-1.149.874.874,0,0,1,1.086-.547c.578.161,1.147.355,1.818.567,0-.618-.006-1.171,0-1.724.009-.642.337-1.019.872-1.022s.88.378.892,1.009c.01.545,0,1.09,0,1.738.562-.181,1.066-.348,1.573-.507.655-.2,1.135-.031,1.319.471.193.529-.089.99-.762,1.221-.494.17-.992.329-1.566.519.345.484.659.918.966,1.356.389.555.358,1.072-.078,1.393s-.925.207-1.347-.347c-.319-.418-.63-.842-.946-1.263C1396.395,234.973,1396.374,234.969,1396.327,234.943Z" transform="translate(-1350.84 -194.12)" fill="#ef4339" />
                                                    <path id="Path_41778" className="hover-fill-white" data-name="Path 41778" d="M1322.671,123c-1.484,0-2.967,0-4.45,0-.919,0-1.2-.278-1.2-1.179,0-2.674,0-5.348,0-8.022,0-.57.151-.985.78-1.125.111-.025.235-.275.244-.428.036-.584,0-1.172.04-1.755a4.61,4.61,0,0,1,9.183-.128c.058.62.017,1.249.064,1.87a.6.6,0,0,0,.286.451.952.952,0,0,1,.719,1.059c0,2.733,0,5.465-.006,8.2,0,.749-.315,1.052-1.086,1.057C1325.716,123.012,1324.193,123,1322.671,123Zm-3.844-1.829h7.7v-6.666h-7.733c0,2.155,0,4.259,0,6.363A2.729,2.729,0,0,0,1318.827,121.175Zm6.673-8.53c0-.715.032-1.379-.007-2.038a2.832,2.832,0,0,0-5.6-.326,17.112,17.112,0,0,0-.019,2.364Z" transform="translate(-1292.671 -99.237)" fill="#ef4339" />
                                                    <path id="Path_41779" className="hover-fill-white" data-name="Path 41779" d="M1337.717,147.777a7.66,7.66,0,0,1,.048-.875.794.794,0,0,1,.847-.727.764.764,0,0,1,.854.711,10.829,10.829,0,0,1,.006,1.809.782.782,0,0,1-.844.736.817.817,0,0,1-.872-.777,7.189,7.189,0,0,1-.007-.877Z" transform="translate(-1308.62 -130.01)" fill="#ef4339" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> Highly Secured Payments </h3>
                                        </div>
                                        <p> All payments are managed by a highly secure and trusted payment gateway, and completed as soon as a request is completed.  </p>
                                    </div>
                                </div>

                                <div className="ourFeature-item">
                                    <div className="icon">
                                        <svg width="60" height="58" viewBox="0 0 60 58">
                                            <g id="Group_10628" data-name="Group 10628" transform="translate(-5304 -6960)">
                                                <g id="Group_10099" data-name="Group 10099" transform="translate(5304 6960)">
                                                    <path id="Path_41771" className="hover-fill-white" data-name="Path 41771" d="M852,35.8H837v-3a5.006,5.006,0,0,0-5-5H818a5.006,5.006,0,0,0-5,5v3H798a3,3,0,0,0-3,3v34a3,3,0,0,0,3,3h36v6a4,4,0,0,0,8,0v-6h10a3,3,0,0,0,3-3v-34A3,3,0,0,0,852,35.8Zm-37-3a3,3,0,0,1,3-3h14a3,3,0,0,1,3,3v3H815Zm-17,41a1,1,0,0,1-1-1V67.88a7,7,0,0,1,5.92,5.92Zm37-3a1,1,0,0,0-1,1v2H804.941A9.011,9.011,0,0,0,797,65.859V53.682a5.432,5.432,0,0,0,3.3,1.118H805v6a1,1,0,0,0,.4.8l4,3a1,1,0,0,0,1.2,0l4-3a1,1,0,0,0,.4-.8v-6h9.051c-.024.332-.051.662-.051,1a14.017,14.017,0,0,0,11,13.668ZM807,60.3V50.8h6v9.5l-3,2.25Zm33,21.5a2,2,0,0,1-4,0v-9h4Zm-3-11V69.749c.332.024.662.051,1,.051s.668-.027,1-.051V70.8Zm1-3a12,12,0,1,1,12-12A12,12,0,0,1,838,67.8Zm15,5a1,1,0,0,1-1,1h-4.92A7,7,0,0,1,853,67.88Zm0-6.941a9.01,9.01,0,0,0-7.941,7.941H842v-2a1,1,0,0,0-1-1V69.468A14.017,14.017,0,0,0,852,55.8a13.865,13.865,0,0,0-.078-1.464A5.322,5.322,0,0,0,853,53.691ZM853,49.8a2.945,2.945,0,0,1-1.464,2.489,13.985,13.985,0,0,0-27.2.511H815v-3a1,1,0,0,0-1-1h-8a1,1,0,0,0-1,1v3h-4.7a3.162,3.162,0,0,1-3.3-3v-11a1,1,0,0,1,1-1h54a1,1,0,0,1,1,1Z" transform="translate(-795 -27.8)" fill="#ef4339" />
                                                    <path id="Path_41772" className="hover-fill-white" data-name="Path 41772" d="M861.4,164.2a2,2,0,1,1-2-2A2,2,0,0,1,861.4,164.2Z" transform="translate(-844.4 -134.2)" fill="#ef4339" />
                                                </g>
                                                <path id="Path_41771-2" className="hover-stroke-white" data-name="Path 41771" d="M867.793,96.145a2,2,0,0,1-4,0v-9h4Zm-3-11V84.094c.332.024.662.051,1,.051s.668-.027,1-.051v1.051Zm1-3a12,12,0,1,1,12-12A12,12,0,0,1,865.793,82.145Z" transform="translate(4481.207 6917.855)" fill="#ff8805" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h3> Professionalism </h3>
                                        </div>
                                        <p> Service providers on our platform are highly professional and motivated to provide high degree of customer satisfaction. </p>
                                    </div>
                                </div>




                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="ourFeature-img wow slideInRight animated hinge" style={{visibility: hideOrShow, animationName: animated3Name}}>
                                <img src={images.ourFeatures} className="img-fluid" alt="Our Fetures" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurFeatureSection;
