import ourFeatures from '../assets/images/our-features.svg';
import servicesMarketplace from '../assets/images/services-marketplace.svg';
import thankCharacter from '../assets/images/thank-character.svg';
import circle1 from '../assets/images/category/circle-1.svg';
import circle2 from '../assets/images/category/circle-2.svg';
import circle3 from '../assets/images/category/circle-3.svg';
import circle4 from '../assets/images/category/circle-4.svg';
import circle5 from '../assets/images/category/circle-5.svg';
import circle6 from '../assets/images/category/circle-6.svg';
import circle7 from '../assets/images/category/circle-7.svg';
import circle8 from '../assets/images/category/circle-8.svg';
import circle9 from '../assets/images/category/circle-9.svg';
import legal from '../assets/images/category/legal.svg';
import cleaning from '../assets/images/category/cleaning.svg';
import moving from '../assets/images/category/moving.svg';
import other from '../assets/images/category/other.svg';
import it from '../assets/images/category/it.svg';
import auto from '../assets/images/category/auto.svg';
import food from '../assets/images/category/food.svg';
import houseRepair from '../assets/images/category/house-repair.svg';
import beauty from '../assets/images/category/beauty.svg';
import ProfilePicture from '../assets/images/user/user-3.jpg';
import user1 from '../assets/images/user/user-1.jpg';
import user2 from '../assets/images/user/user-2.jpg';
import user3 from '../assets/images/user/user-3.jpg';
import user4 from '../assets/images/user/user-4.jpg';
import user5 from '../assets/images/user/user-5.jpg';
import user6 from '../assets/images/user/user-6.jpg';
import user7 from '../assets/images/user/user-7.jpg';
import user8 from '../assets/images/user/user-8.jpg';
import logo from '../assets/images/logo.svg'
import adv1 from '../assets/images/adv1.jpg'
import adv2 from '../assets/images/adv2.jpg'
import adv3 from '../assets/images/adv-3.jpg'
import adv4 from '../assets/images/adv-4.jpg'
import checkIcon from '../assets/images/check-icon.svg';
import voice1 from '../assets/images/voice-1.jpg';
import voice2 from '../assets/images/voice-2.jpg';
import PlayStore from '../assets/images/play-store.svg';
import AppleStore from '../assets/images/app-store.svg';
import googleIcon from '../assets/images/google-icon.svg';
import facebookIcon from '../assets/images/facebook.svg';
import appleIcon from '../assets/images/apple-icon.svg';
import clipBg from '../assets/images/clip-bg.svg';
import filterIcon from '../assets/images/filter-icon.svg';
import locationBigIcon from '../assets/images/location-big-icon.svg';
import calendarBigIcon from '../assets/images/calendar-big-icon.svg';
import gallery1 from '../assets/images/gallery/1.jpg'
import gallery2 from '../assets/images/gallery/2.jpg'
import gallery3 from '../assets/images/gallery/3.jpg'
import gallery4 from '../assets/images/gallery/4.jpg'
import gallery5 from '../assets/images/gallery/5.jpg'
import videoBg from '../assets/images/video-bg.jpg'
import about1 from '../assets/images/about-1.png'
import about2 from '../assets/images/about-2.png'
import about3 from '../assets/images/about-3.png'
import imagesIcon from '../assets/images/image-icon.svg'
import contactUs from '../assets/images/content/contact-us.svg'
import contactIcon from '../assets/images/content/email-icon.svg'
import requestIcon from '../assets/images/dashboard/request-icon.svg'
import requestMenuIcon from  '../assets/images/menu/request-icon.svg'
import myProfile from '../assets/images/dashboard/my-profile.svg'
import changeProfileIcon from '../assets/images/dashboard/change-profile-icon.svg'
import chatIcon from '../assets/images/dashboard/chat-icon.svg'
import transactionIcon from '../assets/images/dashboard/transaction-icon.svg'
import feedbackIcon from '../assets/images/dashboard/feedback-icon.svg'
import check from '../assets/images/content/check.svg'
import topLine from '../assets/images/top-line.png'
import browseReqGrap from '../assets/images/browse-req-grap.svg'
import rightLine from '../assets/images/right-line.png'
import leftLine from '../assets/images/left-line.png'
import submitQuoteGrap from '../assets/images/submit-quote-grap.svg'
import completeReqGrap from '../assets/images/complete-req-grap.svg'
import waveGrap from '../assets/images/wave-grap.svg'
import starearningGrap from '../assets/images/starearning-grap.svg'
import  signoutIcon from '../assets/images/menu/signout-icon.svg';
import  notificationsIcon from '../assets/images/menu/notifications-icon.svg';
import  menuTransactionIcon from '../assets/images/menu/transaction-icon.svg';
import  menuFeedbackIcon from '../assets/images/menu/feedback-icon.svg';
import menuChatIcon from '../assets/images/menu/chat-icon.svg'
import menuChangeProfileIcon from '../assets/images/menu/change-profile-icon.svg'

import  profileIcon from '../assets/images/menu/profile-icon.svg';
import dashboard from '../assets/images/menu/dashboard.svg'
import menuRequestIcon from '../assets/images/menu/request-icon.svg'
import sendIcon from '../assets/images/send-icon.svg'
import pdfIcon from '../assets/images/pdf-icon.svg'
import reviewTheCompetitive from '../assets/images/review-the-competitive.svg';
import considerItCompleted from '../assets/images/consider-it-completed.svg';
import resetPasswordGraphic from '../assets/images/reset-password-graphic.svg'
import realState from "../assets/images/category/small/real-state.svg";
import locationBgWhiteIcon from '../assets/images/location-big-white-icon.svg'
import movingSmallIcon from '../assets/images/category/small/moving.svg';
import cleaningSmallIcon from '../assets/images/category/small/cleaning.svg';
import foodSmallIcon from '../assets/images/category/small/food.svg';
import itSmallIcon from '../assets/images/category/small/it.svg';
import autoSmallIcon from '../assets/images/category/small/auto.svg';
import otherSmallIcon from '../assets/images/category/small/other.svg';
import legalSmallIcon from '../assets/images/category/small/legal.svg';
import beautySmallIcon from '../assets/images/category/small/beauty.svg';
import startEarningNow from "../assets/images/StartEarningNow.mp4"
const images= {
    startEarningNow,
    beautySmallIcon,
    legalSmallIcon,
    cleaningSmallIcon,
    foodSmallIcon,
    itSmallIcon,
    autoSmallIcon,
    otherSmallIcon,
    movingSmallIcon,
    menuChangeProfileIcon,
    menuChatIcon,
    menuFeedbackIcon,
    menuTransactionIcon,
    menuRequestIcon,
    locationBgWhiteIcon,
    realState,
    resetPasswordGraphic,
    reviewTheCompetitive,
    considerItCompleted,
    pdfIcon,
    sendIcon,
    dashboard,
    requestMenuIcon,
    profileIcon,
    notificationsIcon,
    signoutIcon,
    starearningGrap,
    waveGrap,
    completeReqGrap,
    leftLine,
    submitQuoteGrap,
    rightLine,
    browseReqGrap,
    check,
    topLine,
    feedbackIcon,
    transactionIcon,
    chatIcon,
    changeProfileIcon,
    myProfile,
    requestIcon,
    contactIcon,
    contactUs,
    imagesIcon,
    about1,
    about2,
    about3,
    videoBg,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    calendarBigIcon,
    locationBigIcon,
    filterIcon,
    clipBg,
    googleIcon,
    facebookIcon,
    appleIcon,
    ourFeatures,
    servicesMarketplace,
    thankCharacter,
    circle1,
    circle2,
    circle3,
    circle4,
    circle5,
    circle6,
    circle7,
    circle8,
    circle9,
    it,
    other,
    moving,
    auto,
    food,
    cleaning,
    houseRepair,
    beauty,
    legal,
    ProfilePicture,
    logo,
    adv1,
    adv2,
    adv3,
    adv4,
    user1,
    user2,
    user3,
    user4,
    user5,
    user6,
    user7,
    user8,
    checkIcon,
    voice1,
    voice2,
    PlayStore,
    AppleStore,
}
export default images;