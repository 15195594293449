const config = {
  url: "baseurl", // modify this setting only if explicit pointing is required.
  baseurl: "https://api.ezunction.com",
  //baseurl: "http://18.219.186.222:3334",
  // baseurl: "http://3.137.89.95:3334",
  //baseurl: "http://3.17.110.157:3334",
  //baseurl: "http://3.143.17.181:3334",
  //baseurl: "http://3.135.192.120:3334",
  // baseurl: "https://0689-2405-201-6810-7019-a902-af95-3e47-3176.ngrok.io",
  localurl: "http://localhost:3334",
   
  socket_url: "https://api.ezunction.com",
  //socket_url: "http://18.219.186.222:3334",
  //socket_url: "3.137.89.95:3334",
  //socket_url: "3.17.110.157:3334",
  //socket_url: "3.143.17.181:3334",
  contentType: "application/json",
  accept: "application/json",
  endpoints: {
    login: "/api/v1/users/login",
    userRegister: "/api/v1/users/register",
    updateUser: "/api/v1/update_user",
    userAddress: "/api/v1/user-address",
    providerRegister: "/api/v1/users/provider-register",
    forgotPasswordChange: "/api/v1/users/forgot-password-change",
    forgotPassword: "/api/v1/users/forgot-password",
    passwordChanged: "/api/v1/users/password-change",
    accountActivate: "/api/v1/users/account_activate",
    otpLogin: "/api/v1/users/otp-login",
    updatePassword: "/api/v1/update_password",
    sendOtp: "/api/v1/users/otp-check",
    otpCheck: "/api/v1/users/otp-check",
    authUser: "/api/v1/user",
    createUserServiceRequest: "/api/v1/service_request",
    deleteRequestFile: "/api/v1/service_request/file/",
    getallserviceRequests: "/api/v1/serviceRequests",
    serviceType: "/api/v1/service-type",
    service_type: "/api/v1/subcategories/service-type",
    categories: "/api/v1/categories",
    timeSlots: "/api/v1/users/timeslots",
    dashboard: "/api/v1/users/dashboard",
    subCategories: "/api/v1/sub-categories",
    fetchCountry: "/api/v1/location/country",
    fetchState: "/api/v1/location/state",
    fetchCity: "/api/v1/location/city",

    image_path: "https://ezunction-prod.s3.amazonaws.com/",
    //image_path: "https://ezunction-dev.s3.amazonaws.com/",

    cms_image_path: "/api/v1/download/",
    add_other_address: "/api/v1/add-address",
    fetch_other_address: "/api/v1/user-address",
    createRequestQuote: "/api/v1/quoteService",
    addBankAccount: "/api/v1/add_bank",
    getBankAccounts: "/api/v1/user-bank",
    getOnGoing: "/api/v1/serviceRequest/1/status",
    getActive: "/api/v1/serviceRequest/2/status",
    getClose: "/api/v1/serviceRequest/3/status",
    changeProfileType: "/api/v1/change_profile_type",
    editRequest: "/api/v1/serviceRequest/",
    cancelRequest: "/api/v1/serviceRequest/cancel",
    cancelRequestStatus: "/api/v1/serviceRequest/cancel_status",
    onMyWay: "/api/v1/serviceRequest/onmyway/",
    getQuoteData: "/api/v1/quoteService/sr/",
    acceptQuote: "/api/v1/quoteService/accept",
    rejectQUote: "/api/v1/quoteService/Rejected_by_Customer/",
    anotherVisit: "/api/v1/AnotherVisit",
    markCompletion: "/api/v1/MarkCompletion",
    acceptCompletion: "/api/v1/AcceptCompletion",
    closeWarrantyService: "/api/v1/WarrantyService/close/",
    addDisputeRequest: "/api/v1/DisputeService",
    addWarrantyRequest: "/api/v1/WarrantyService",
    noCustomerShow: "/api/v1/CustomerNoShow",
    postNoSPShow: "/api/v1/SpNoShow",
    addWithdrawnQuote: "/api/v1/quoteService/Withdrawn_by_SP/",
    withdawStatus: "/api/v1/quoteService/Withdrawn_status/",
    confirmQoute: "/api/v1/quoteService/confirm/",
    updateQoute: "/api/v1/quoteService/",
    disputeReason: "/api/v1/disputeReason",
    addDisputeRequest: "/api/v1/DisputeService/",
    addWarrantyRequest: "/api/v1/WarrantyService",
    confirmAPICall: "/api/v1/quoteService/confirm",
    commentQuote: "/api/v1/quoteComments",
    getAdditionalDetails: "/api/v1/ServicerequestAdditionalDetail",
    getUserCard: "/api/v1/user-card",
    addCard: "/api/v1/add_card",
    updateUserBank: "/api/v1/user-bank-update/",
    updateUserCard: "/api/v1/user-card-update/",
    getCustomerFeedback: "/api/v1/GetCustomerFeedback",
    getSPFeedback: "/api/v1/GetProviderFeedback",
    getOnGoingSP: "/api/v1/sp_serviceRequest/1/status",
    getActiveSP: "/api/v1/sp_serviceRequest/2/status",
    getCloseSP: "/api/v1/sp_serviceRequest/3/status",
    couponService: "/api/v1/coupons",
    contactUs: "/api/v1/ContactUs",

    //my profile additional information get apis,
    getUserdrivingLicense: "/api/v1/user-dl",
    getProfileInformation: "/api/v1/sp-profile",
    getBusinessLicense: "/api/v1/sp-bl",
    getCompanyInformation: "/api/v1/sp-company-info",

    //my profile additional information post apis,
    updateUserDrivingLicense: "/api/v1/user-dl-update",
    updateProfileInformation: "/api/v1/sp-profile-update",
    updateBusinessLicense: "/api/v1/sp-bl-update",
    updateComapnyInformation: "/api/v1/sp-company-info-update",

    srCancelReason: "/api/v1/srCancelReason",
    quoteWithdrawReason: "/api/v1/withdrawalReason",

    // my transaction apis
    transaction_list: "/api/v1/transaction_list",
    transactionCust: "/api/v1/Transaction_cust/sr/",
    transactionSP: "/api/v1/Transaction_sp/sr/",
    transactionInvoice: "/api/v1/CustomerInvoice/",
    transactionPayout: "/api/v1/SpPayout/",

    //notifications
    notifications: "/api/v1/users/notifications",

    /// find now api
    fetchSPList: "/api/v1/searchByServiceZipCity",
    allCategories: "/api/v1/categories/types",
    findNow:"/api/v1/spFindNow",

    ////tracking apis
    trackingLocations: "/api/v1/serviceAddressCoordinates/",
    sendSpLocation: "/api/v1/serviceAddressCoordinates",

    ////sp details screen
    spDetails:"/api/v1/spProfileDetails/",
    custDetails:"/api/v1/customerProfileDetails/",

    /// reject quote apis
    srRejectReason: "/api/v1/rejectionReason",
    submitRejectQuote: "/api/v1/quoteService/Rejected_by_Customer/",

    //// token for stripe
    tokenLink: "/api/v1/linkToken",
    sendTokenLink: "/api/v1/getBankToken",

    /////accept completion
    getAcceptCompletionData: "/api/v1/finalAmounts",

    ///// users feedback
    getUserFeedBacks: "/api/v1/ezFeedback",
    submitUserFeedBacks: "/api/v1/ezFeedback",

    ////// send lat and log
    sendLatLong: "/api/v1/saveUserLatLong",

    /////people voice
    peopleVoice: "/api/v1/peopleVoices",

    ///// images/banners URL
    herography: "/api/v1/cms/home-page/herography",
    advBanner: "/api/v1/cms/home-page/adv-banner",
    howItWorksVideo: "/api/v1/cms/how-it-works/videos",
    startEarningNowVideo: "/api/v1/cms/start-earning-now/videos",
    aboutUsVideo: "/api/v1/cms/about-ezunction/videos",
    socialLinks: "/api/v1/cms/get-in-touch/social-media-links",
  },
};

export default config;
