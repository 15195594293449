import React, { useState, useEffect} from 'react'
import logo from '../images/logo123.svg'
import mail from "../images/web.png";
import { APIService } from "../Components/Apis/APIServices";
import { dateWithFormate } from "../Utility/common";
import './invoiceStyle.css'
import { useHistory } from 'react-router-dom';


const Invoice = (props) => { 
  const history = useHistory();

  const [data, setData] = useState({
    // user: [],
    // service_request: [],
    invoice: {},
    payment_method_bank: {},
    payment_calculation: {},
    quote_service: {},
    account_balance: {},
  });

  useEffect(() => {
    // console.log("//////////////////1", props)
    if (props.location.state !== undefined) {
      APIService.transactionPayout(props.location.state?.srId)
        .then((res) => {
          // console.log("//////////////", res?.data);
          setData(res?.data);
        })
        .catch((err) => {});
    }
  }, []);

    return (
      <>
        <table
          style={{ width: "100%", height: "100vh", backgroundColor: "#e1e1e1" }}
        >
          <tbody>
          <button type="button" className="ardCat-back-btn d-block d-xl-none btn-orange btn-lg" 
                               onClick={()=>{ history.goBack() 
                               }} 
                               >
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
      
            <table
              className="fullTable"
              style={{
                width: "100%",
                border: "0",
                cellpadding: "0",
                cellspacing: "0",
                align: "center",
                backgroundColor: "#e1e1e1",
              }}
            >
              <tr>
                <td height="20"></td>
              </tr>
              <tr>
                <td>
                  <table
                    width="600"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    class="fullTable"
                    bgcolor="#ffffff"
                    style={{ borderRadius: "10px 10px 0 0" }}
                  >
                    <tr>
                      <td>
                        <table
                          width="560"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                        >
                          <tr class="hiddenMobile">
                            <td colspan="2" height="20"></td>
                          </tr>
                          <tr>
                            <td align="left">
                              {" "}
                              <img
                                src={logo}
                                width="160"
                                alt="logo"
                                border="0"
                              />
                            </td>
                            <td align="right">
                              <table>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#0779E4",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    Payout # :{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#5F6063",
                                    }}
                                  >
                                    {" "}
                                    {data?.sp_payout?.id}{" "}
                                    {/* {props.location.state?.transId} */}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#0779E4",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    Payout Date :{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#5F6063",
                                    }}
                                  >
                                    {" "}
                                    {dateWithFormate(
                                      data?.sp_payout?.created_at &&
                                        data?.sp_payout?.created_at
                                          .split(" ")[0]
                                          .split("-")
                                          .reverse()
                                          .join("-")
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#0779E4",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    Service Date :{" "}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: "Open Sans",
                                      color: "#5F6063",
                                    }}
                                  >
                                    {" "}
                                    {dateWithFormate(
                                      data?.service_request &&
                                        data?.service_request?.service_date
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "13px",
                                color: "#5F6063",
                                letterSpacing: "2px",
                                fontFamily: "Open Sans",
                              }}
                            >
                              {" "}
                              Making Life Easy{" "}
                            </td>
                          </tr>
                          <tr>
                            <td height="10"></td>
                          </tr>
                          <tr className="hiddenMobile">
                            <td
                              colspan="2"
                              style={{
                                height: "2px",
                                background: "#707070",
                                width: "100%",
                              }}
                            ></td>
                          </tr>
                          <tr>
                            <td height="10"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <table
                          width="560"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          className="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="left"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "13px",
                                          fontFamily: '"Open Sans", sans-serif',
                                          color: "#0779E4",
                                          fontWeight: "bold",
                                          paddingBottom: "2px",
                                        }}
                                      >
                                        {" "}
                                        {data && data.user &&  data.user ?
                                        `${data?.user?.first_name || ""} ${data?.user?.last_name || ""}`
                                        :
                                        ""
}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: '"Open Sans", sans-serif',
                                          color: "#5F6063",
                                        }}
                                      >
                                        {" "}
                                        {data && data.user &&  data.user ?
                                        <>
                                       {/* {`${data?.user?.address_1}, `}
                                       {`${data?.user?.address_2}, `}
                                        {`${data?.user?.cities?.name}, `}
                                        {`${data?.user?.states?.name}, `}
                                        <br />
                                        {`${data?.user?.countries?.name}, `}
                                        {`${data?.user?.zip_code}`} */}
                                        {`${data?.user?.address_1 ? data?.user?.address_1 : ""}`}
                                        {`${data?.user?.address_2 ? ", " + data?.user?.address_2 : ""}`}
                                        <br />
                                        {`${data?.user?.cities?.name ? "" + data?.user?.cities?.name : ""}`}                                        
                                        {`${data?.user?.states?.name ? ", " + data?.user?.states?.name + ""  : ""}`}                                        
                                        <br />
                                        {`${data?.user?.countries?.name ?  data?.user?.countries?.name : ""}`}
                                        {`${data?.user?.zip_code ? ", " + data?.user?.zip_code : ""}`}
                                        
                                        </>
                                        :
                                        ""
}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="right"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "13px",
                                          fontFamily: '"Open Sans", sans-serif',
                                          color: "#0779E4",
                                          fontWeight: "bold",
                                          paddingBottom: "2px",
                                        }}
                                      >
                                        {" "}
                                        Service Address:{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: '"Open Sans", sans-serif',
                                          color: "#5F6063",
                                        }}
                                      >
                                        {" "}
                                        {data && data.service_request &&  data.service_request ?
                                        <>
                                        {/* {`${data?.service_request?.address_1}, `}
                                        {`${data?.service_request?.cities?.name}, `}
                                        {`${data?.service_request?.states?.name}, `}
                                        <br />
                                        {`${data?.service_request?.countries?.name}, `}
                                        {data?.service_request?.zip_code} */}
                                        {`${data?.service_request?.address_1 ? data?.service_request?.address_1 : ""}`}
                                        {`${data?.service_request?.address_2 ? ", " + data?.service_request?.address_2 : ""}`}
                                        <br />
                                        {`${data?.service_request?.cities?.name ? "" + data?.service_request?.cities?.name : ""}`}                                        
                                        {`${data?.service_request?.states?.name ? ", " + data?.service_request?.states?.name + ""  : ""}`}                                        
                                        <br />
                                        {`${data?.service_request?.countries?.name ? data?.service_request?.countries?.name : ""}`}
                                        {`${data?.service_request?.zip_code ? ", " + data?.service_request?.zip_code : ""}`}
                                        
                                        </>
                                        :
                                        ""
                                      }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <table
                          width="560"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          className="fullPadding"
                        >
                          <col width="20px" />
                          <col width="400px" />
                          <tr>
                            {" "}
                            <td height="30"></td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#0779E4",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              Request ID :{" "}
                            </td>
                            <td
                              style={{
                                paddingLeft: "3px",
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#5F6063",
                                lineHeight: "1.8",
                              }}
                            >
                              {" "}
                              {(data && data?.sp_payout && data?.sp_payout?.request_id ) || ""}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#0779E4",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              Quote ID :{" "}
                            </td>
                            <td
                              style={{
                                paddingLeft: "3px",
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#5F6063",
                                lineHeight: "1.8",
                              }}
                            >
                              {" "}
                              {(data && data?.sp_payout && data?.sp_payout?.quote_id) || ""}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#0779E4",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              Category :{" "}
                            </td>
                            <td
                              style={{
                                paddingLeft: "3px",
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#5F6063",
                                lineHeight: "1.8",
                              }}
                            >
                              {(data && data?.service_request && data?.service_request && data?.service_request?.category?.category_name) ||
                                ""}
                            </td>
                          </tr>

                          <tr style={{ verticalAlign: "top" }}>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#0779E4",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              Service Description :{" "}
                            </td>
                            <td
                              style={{
                                paddingLeft: "3px",
                                fontSize: "12px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#5F6063",
                                lineHeight: "1.8",
                              }}
                            >
                              {(data && data?.service_request && data?.service_request && data?.service_request?.description) ||
                                ""}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            {/* middle tabel   */}

            <table
              className="fullTable"
              style={{
                width: "100%",
                border: "0",
                cellpadding: "0",
                cellspacing: "0",
                align: "center",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="600"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      class="fullTable"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <table
                          width="560"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                        >
                          <tr className="hiddenMobile">
                            <td height="15"></td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                className="w-100"
                                style={{
                                  border: "0",
                                  cellpadding: "0",
                                  cellspacing: "0",
                                  align: "center",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <thead>
                                  <tr
                                    style={{
                                      borderTop: "2px solid #707070",
                                      borderBottom: "2px solid #707070",
                                    }}
                                  >
                                    <th
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        fontWeight: "bold",
                                        padding: "6px 10px",
                                        align: "left",
                                      }}
                                    >
                                      {" "}
                                      Description of Payout
                                    </th>

                                    <th
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        fontWeight: "bold",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                        width: "100",
                                      }}
                                    >
                                      {" "}
                                      Amount
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                {data && data.sp_payout && data.sp_payout.reason_code
                                  ?
                                  (data.sp_payout.reason_code === "FCSP" || data.sp_payout.reason_code === "FCC")
                                  ?
                                  <>
                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                      }}
                                    >
                                      Labor Amount
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      $
                                      {(data &&
                                        data?.quote_service?.labor_quote_amount &&
                                        parseFloat(data?.quote_service?.labor_quote_amount).toFixed(
                                          2
                                        )) ||
                                       "0.00"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                      }}
                                    >
                                      Material Amount
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      $
                                      {data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount === null
                                            ||
                                            data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount === undefined
                                            ?
                                            "0.00"
                                            :
                                           
                                      (parseFloat(data &&
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount)
                                      .toFixed(2)) || "0.00"
                                    }
                                      {/* {(data && 
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount) ? 
                                            
                                            (data && 
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount).toFixed(2) : "0.00"} */}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                      }}
                                    >
                                      Additional Labor Cost
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      $
                                      {data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_labor_amount === null
                                            ||
                                            data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_labor_amount === undefined
                                            ?
                                            "0.00"
                                            :
                                           
                                      (parseFloat(data &&
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_labor_amount)
                                      .toFixed(2)) || "0.00"
                                    }
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                      }}
                                    >
                                      Additional Material Cost{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      $
                                      {data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount === null
                                            ||
                                            data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount === undefined
                                            ?
                                            "0.00"
                                            :
                                           
                                      (parseFloat(data &&
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount)
                                      .toFixed(2)) || "0.00"
                                    }
                                    </td>
                                  </tr>
                                  </>
                                  :
                                  <>
                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                      }}
                                    >
                                     {data && data.sp_payout && data.sp_payout.reason && data.sp_payout.reason.desc_of_charges || null}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        verticalAlign: "middle",
                                        padding: "7px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {/* $200.00 */}
                                      {/* {(parseInt(
                                        data?.payment_calculation
                                          ?.uplifted_labor_quote_amount
                                      ) || 0) +
                                        (parseInt(
                                          data?.payment_calculation
                                            ?.uplifted_material_quote_amount
                                        ) || 0) || 0} */}
                                      {data && data?.sp_payout &&
                                        data?.sp_payout
                                          ?.payout_amount ? 
                                          parseFloat(data?.sp_payout &&
                                        data?.sp_payout
                                          ?.payout_amount).toFixed(2) ||
                                        "0.00"
                                      :
                                      "0.00"
                                      }
                                    </td>
                                  </tr>
                                
                                  </>
                                :
                                null}
                                  

                                </tbody>

                                <tfoot
                                  style={{ borderTop: "2px solid #707070" }}
                                >
                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        fontWeight: "bold",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      Subtotal{" "}
                                    </td>
                                    {data && data.sp_payout && data.sp_payout.reason_code
                                  ?
                                  (data.sp_payout.reason_code === "FCSP" || data.sp_payout.reason_code === "FCC")
                                  ?
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        fontWeight: "bold",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      {/* $250.00{" "} */}
                                      $
                                      {
                                      (
                                        (parseFloat(data &&
                                        data?.quote_service?.labor_quote_amount &&
                                        data?.quote_service?.labor_quote_amount)
                                         ||
                                       0.00)
                                       +
                                       (parseFloat(data &&
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.material_quote_amount
                                      ) || 0.00)
                                      +
                                      (data?.quote_service &&
                                        data?.quote_service
                                          ?.additional_labor_amount === null
                                          ||
                                          data?.quote_service &&
                                        data?.quote_service
                                          ?.additional_labor_amount === undefined
                                          ?
                                          0
                                          :
                                         
                                          parseFloat(
                                      data?.quote_service &&
                                        data?.quote_service
                                          ?.additional_labor_amount
                                    ) || 0.00
                                      )

                                     
                                      +
                                      (data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount === null
                                            ||
                                            data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount === undefined
                                            ?
                                            0.00
                                            :
                                           
                                            parseFloat(
                                        data?.quote_service &&
                                          data?.quote_service
                                            ?.additional_material_amount
                                      ) || 0.00)
                                  
                                      ).toFixed(2)
                                      
                                   
                                    
                                  }
                                    </td>
                                    :
                                    
                                    <td
                                    style={{
                                      fontSize: "13px",
                                      fontFamily: '"Open Sans", sans-serif',
                                      color: "#5F6063",
                                      fontWeight: "bold",
                                      padding: "6px 10px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {" "}
                                    $
                                   
                                    {data && data?.sp_payout &&
                                        data?.sp_payout
                                          ?.payout_amount ? 
                                          parseFloat(data?.sp_payout &&
                                        data?.sp_payout
                                          ?.payout_amount).toFixed(2) ||
                                        "0.00"
                                      :
                                      "0.00"
                                      }
                                  </td>
                                  :
                                  null}

                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        align: "left",
                                      }}
                                    >
                                      {" "}
                                      {/* Payment Method{" "} */}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      Service Charges{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      $
                                      {(data && data?.payment_calculation && data?.payment_calculation
                                                  ?.sp_service_charge)
                                                  ?
                                                  parseFloat(
                                                    data?.payment_calculation &&
                                                      data?.payment_calculation
                                                        ?.sp_service_charge
                                                  ).toFixed(2) || "0.00"
                                                  :
                                                  "0.00"
                                                  }
                                      {/* {((data?.payment_calculation &&
                                                data?.payment_calculation
                                                  ?.sp_service_charge) === null
                                            ||
                                            (data?.payment_calculation &&
                                                data?.payment_calculation
                                                  ?.sp_service_charge === undefined))
                                            ?
                                          "0.00"
                                            :
                                           
                                            parseInt(
                                              data?.payment_calculation &&
                                                data?.payment_calculation
                                                  ?.sp_service_charge
                                            ).toFixed(2) || "0.00"
                                    } */}
                                     
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        align: "left",
                                      }}
                                    >
                                      {" "}
                                      {/* Last 4 digits: 4567{" "} */}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      Tips{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      $

                                      {(data && data?.payment_calculation && data?.payment_calculation
                                                  ?.tip_amount)
                                                  ?
                                                  parseFloat(
                                                    data?.payment_calculation &&
                                                      data?.payment_calculation
                                                        ?.tip_amount
                                                  ).toFixed(2) || "0.00"
                                                  :
                                                  "0.00"
                                                  }
                                      {/* { data?.payment_calculation &&
                                                data?.payment_calculation?.tip_amount === null
                                            ||
                                            data?.payment_calculation &&
                                                data?.payment_calculation?.tip_amount === undefined
                                            ?
                                          0.00
                                            :
                                           
                                            parseInt(
                                              data?.payment_calculation &&
                                                data?.payment_calculation?.tip_amount
                                            ).toFixed(2) || 0.0
                                    } */}
                                      
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#0779E4",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      Balance Adjustment{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      $0.00{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="2"
                                      style={{
                                        fontSize: "13px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#FF8805",
                                        fontWeight: "bold",
                                        padding: "6px 10px",
                                        textTransform: "uppercase",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      NET PAYOUT AMOUNT{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: '"Open Sans", sans-serif',
                                        color: "#5F6063",
                                        padding: "6px 10px",
                                        fontWeight: "bold",
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      $
                                      {(data && data?.sp_payout && data?.sp_payout
                                                  ?.payout_amount)
                                                  ?
                                                  parseFloat(
                                                    data?.sp_payout &&
                                                      data?.sp_payout
                                                        ?.payout_amount
                                                  ).toFixed(2) || "0.00"
                                                  :
                                                  "0.00"
                                                  }
                                      {/* {parseInt(data?.sp_payout?.payout_amount).toFixed(2) || 0}{" "} */}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* footer */}

            <table
              className="fullTable"
              style={{
                width: "100%",
                border: "0",
                cellpadding: "0",
                cellspacing: "0",
                align: "center",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="600"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      class="fullTable"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <table
                          width="560"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                        >
                          <tr class="hiddenMobile">
                            <td height="15"></td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                width="560"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                align="center"
                                class="fullPadding"
                              >
                                <tbody>
                                  <tr class="hiddenMobile">
                                    <td
                                      colspan="3"
                                      height="2"
                                      style={{
                                        background: "#707070",
                                        width: "100%",
                                      }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td height="10"></td>
                                  </tr>
                                  <tr style={{ verticalAlign: "middle" }}>
                                    <td
                                      align="left"
                                      style={{
                                        fontFamily: '"Open Sans", sans-serif',
                                        fontSize: "12px",
                                        color: "#53514E",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {" "}
                                      <img src="./images/email.png" />{" "}
                                      support@ezunction.com{" "}
                                    </td>
                                    <td
                                      style={{
                                        fontFamily: '"Open Sans", sans-serif',
                                        fontSize: "11px",
                                        color: "#0779E4",
                                        lineHeight: "1.5",
                                        verticalAlign: "middle",
                                        justifyContent: "center",
                                        display: "flex",
                                      }}
                                    >
                                      {" "}
                                      {/* 1800 Alexander Bell Dr #400 <br /> Reston,
                                      VA 20191 */}
                                    </td>
                                    <td
                                      align="right"
                                      style={{
                                        fontFamily: '"Open Sans", sans-serif',
                                        fontSize: "12px",
                                        color: "#53514E",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <img src={mail} /> www.ezunction.com{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="5"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td height="10"></td>
                          </tr>
                        </table>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </tbody>
        </table>
      </>
    );
}

export default Invoice