import React, { useContext, useState, useRef } from 'react';
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import Auth from '../../Auth/Auth';
import AuthUserContext from '../../Auth/AuthUser/AuthUserContext';
import images from '../../Utility/Images';
import { APIService } from '../Apis/APIServices';
import config from '../Apis/config';
import Dialogue from './Dialogue';
import LoginRegister from './Header/LoginRegister';
import Notification from './Header/Notification';
import UserProfile from './Header/UserProfile';

import IdleTimer from 'react-idle-timer'
import { useHistory } from 'react-router-dom';

// import HomeContext from '../../Pages/Home/HomeContext/HomeContext';

const Header = () => {
	const [isPageScrolled, setIsPageScrolled] = useState(false);
	
	// const [state, dispatch] = useContext(HomeContext);

	const authUser = useContext(AuthUserContext) || {};
  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });

  const [menuToggleActive, setMenuToggleActive] = useState(false)
  
  const checkPopUp = ({id, role_type, user_bank, PaymentMethodCard,DriverLicense, SpProfile}) =>{
	
	if(!!!localStorage.getItem("profileCompletePopUp")){
		// if(user_bank && !user_bank.length || PaymentMethodCard && !PaymentMethodCard.length){
		// 	setDialogueState({
		// 		type: "Warning",
		// 		show: true,
		// 		message:
		// 		  'Your profile is incomplete. Please complete it in "My Profile" section of your dashboard.',
		// 		link: "/my-profile",
		// 	  });
		// 	  localStorage.setItem("profileCompletePopUp", true);
		// }else 
		if(role_type == 2 || role_type == 3){
			if(!DriverLicense.id || !SpProfile.id){
				setDialogueState({
					type: "Warning",
					show: true,
					message:
					  'Your profile is incomplete. Please complete it in "My Profile" section of your dashboard.',
					link: "/my-profile",
				  });
				  localStorage.setItem("profileCompletePopUp", true);
			}
			
		}
	  }
  }

  useEffect(() => {
	

	APIService.authUser().then((response)=>{
        if(response.data.status==200){
            if(response.data.data[0]){
				checkPopUp(response.data.data[0])
			}
        }      
    }).catch((error)=>{
    
    })
  
	
  }, []);

  const [allCategories,setAllCategories] = useState([])
	
	useEffect(() => {


		function isJsonString(str) {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		}

		if(sessionStorage.getItem("all_cat") !== null && sessionStorage.getItem("all_cat") !== undefined){

if(isJsonString(sessionStorage.getItem("all_cat"))){
	setAllCategories(JSON.parse(sessionStorage.getItem("all_cat")) || [])
}else{

    APIService.allCategories()
      .then((response) => {
		  if (response.status === 200) {
			//   console.log("////////////////////dddddddddd", response?.data?.data)
          
			setAllCategories(response?.data?.data || [])
			sessionStorage.setItem("all_cat", JSON.stringify(response?.data?.data))
		  }
      })
      .catch((error) => console.log(error));
	}
}else{

    APIService.allCategories()
      .then((response) => {
		  if (response.status === 200) {
			//   console.log("////////////////////dddddddddd", response?.data?.data)
          
			setAllCategories(response?.data?.data || [])
			sessionStorage.setItem("all_cat", JSON.stringify(response?.data?.data))
		  }
      })
      .catch((error) => console.log(error));
	}
  }, []);

//   console.log("kkkkkkkkkkkkkkkklllllllllllllllll",window.location.pathname)




  useEffect(() => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth'
	  });
  }, [window.location.pathname]);

  const changeNavbarColor = () => {
    if (window.scrollY >= 400) {
      setIsPageScrolled(true);
    } else {
      setIsPageScrolled(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const getNavbarStyle = () => {
    let navbarCSS;
    if (isPageScrolled) {
      navbarCSS = 'sticky-1 sticky';
	} else if (dropDown === "dropiMenu active") { 
		navbarCSS = "menu--opend"
	}
	else {
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/how-it-work" ||
        window.location.pathname === "/start-earning" ||
        window.location.pathname === "/contact-us" ||
        window.location.pathname === "/about-us" ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/all-requests" ||
        window.location.pathname === "/service-categories" ||
        window.location.pathname === "/faq" ||
		window.location.pathname === "/term-conditions" || 
		window.location.pathname === "/ezunction-feedback" ||
		window.location.pathname === "/Service_Provider_Guide" ||
		window.location.pathname === "/insurance-coverage" ||
		window.location.pathname === "/peoples-voice"
      ) {
        navbarCSS = "navbar-top-transparent";
      } else {
        navbarCSS = "navbar-top-dark";
      }
    }
    return navbarCSS;
  };
  
//   console.log(window.location.pathname );
	
	const [dropDown, setDropDown] = useState("d-none d-xl-block")
	
	const setDropDownActive = () => { 
		if (dropDown === "dropiMenu active") {
			setDropDown("")
		} else if(allCategories.length <= 0 || allCategories.length === undefined || allCategories.length === null) { 
			setDropDown("")
		}else if(window.location.pathname === "/all-requests" && dropDown === "dropiMenu active"){
			setDropDown("")
		}else{
			setDropDown("dropiMenu active")
		}
		
	}

	const [selectedSubMenu, setSelectedSubMenu] = useState(0)
	const [selectedCategory, setSelectedCategory] = useState(0)

	const setSubMenu =(toogleSubMenu, toggleCategory)=>{
		// console.log("yes we can toggle")
		setSelectedSubMenu(prev => {
			if(prev === toogleSubMenu){
				return("")
			}else{
				return toogleSubMenu
			}
		})

		setSelectedCategory(prev => {
			if(prev === toggleCategory && window.screen.width >= 1200){
				return("")
			}else{
				return toggleCategory
			}
		
		})
	}

const handleMenuToggle = ()=>{
	if(window.screen.width <= 1200){
	document.body.classList = []
	// console.log("qqqqqqqqqqq",[...document.body.classList] )
	if(menuToggleActive){
		document.body.classList.add('dark-header');
		setMenuToggleActive(!menuToggleActive)
	}else{
		document.body.classList.add('responsive-menu--opened');
		setMenuToggleActive(!menuToggleActive)
	}
}else{
	return null
}
	

}

useEffect(()=>{
	if(window.screen.width <= 1200){
		document.body.classList = []

			document.body.classList.add('dark-header');
	
	}
},[window.location.pathname])
	
	// console.log("eeeeeeeeeeeee", document.body.classList.length > 0 && document.body.classList[0]=== "dark-header")
	const history = useHistory();
	const idleTimerRef = useRef(null)
	const reloadWindow = () =>{
		// window.location.reload()
		history.push("/")
	}

  return (
    <>
<IdleTimer
			ref={idleTimerRef}
			timeout={300*1000}
			// timeout={500}
			onIdle={()=>reloadWindow()}
		></IdleTimer>

      <header className={`main-header ${getNavbarStyle()}`}>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-1 col-md-2 col-sm-3 col-3">
							<div className="brand-name">
								<Link to="/">
									<Image src={images.logo} className="img-fluid" title="eZunction" alt="eZunction" />
								</Link>
							</div>
						</div>
						<div className="col-lg-11 col-md-10 col-sm-9 col-9">
							<nav className="header-right">
							  <ul id="main-menu" className="main-menu list-inline" style={{zIndex:"10"}}>
								  <li><Link to="/" className={window.location.pathname === "/" ? "font-weight-bold" : "" }>Home</Link></li>
									<li><Link to="/how-it-work" className={window.location.pathname === "/how-it-work" ? "active font-weight-bold" : "active" } onClick={handleMenuToggle}>How it Works</Link></li>
									
								  

								  <li className={`${document.body.classList.length > 0 && document.body.classList[0] === "dark-header" ? window.screen.width <= 1200 ? "d-none" : "" : ""}`}><a href="javascript:void(0)" className={dropDown} onClick={() => { setDropDownActive() }} style={{zIndex:"100"}}> Service Categories </a>
	                            <div class="menuDropdown">
	                                <div class="container">
	                                    <div class="row">
	                                        
	                                            
												{allCategories.map((item,index)=>(

													<div className={`col-lg-2-5 col-md-12 menu-block menu-block-${item?.id || (index + 1)}`}>
													<a href="javascript:void(0);" class="menu-heading" onClick={()=>setSelectedCategory(item?.id)}>
	                                                <div class="icon"><img src={ config.baseurl+
                  config.endpoints.cms_image_path +
                  item?.category_image} class="img-fluid d-block m-auto" alt={`Service Category No.${index + 1}`} /></div>
	                                                {item?.category_name || ""}
	                                            </a>
	                                            <ul class={`sub-menu ${selectedCategory === item?.id ? "subMenu-open" : ""}`}>
	                                                
													{item?.subcategories && item?.subcategories.map((item2, index2)=>(

													<li className={`menu-has-children ${selectedSubMenu === item2?.id ? "subchild-open" : "" }`}><a href="javascript:void(0);" className={`toogleLink  ${selectedSubMenu === item2?.id  ? "active" : ""}`} onClick={()=> setSubMenu(item2?.id, item?.id)}> {item2?.sub_category_name || "" }<span class="arrow-icon"></span> </a>
	                                                    <ul className={`sub-menu-child mCustomScrollbar ${selectedSubMenu === item2?.id ? "_mCS_3 subchild-show" : ""}`}>
														{item2?.service_types && item2?.service_types.map((item3, index3)=>(
															
	                                                        <li>
																<Link 
															to={{
																pathname: "/all-requests",
																state: {
																 
																  serviceType: item3?.id,
																  serviceSubCat: item2?.id,
																  serviceCat: item?.id,
																},
															  }}
															  onClick={()=>{
																  handleMenuToggle();
																  setDropDownActive()
																}}
															  > {item3?.name || ""}  </Link></li>
	                                                        
														))}
														 </ul>
															  </li>

													)) }
													
															 


	                                               

	                                              
														  </ul>
														  </div>
												))}

													
											  </div>
										  </div>
									  </div>
									  </li>
										  

									<li><Link to="/start-earning" className={window.location.pathname === "/start-earning" ? "active font-weight-bold" : "active" } onClick={handleMenuToggle}> Start Earning Now </Link></li>
									<li><Link to="/create-my-request" className={window.location.pathname === "/create-my-request" ? "font-weight-bold" : "" } onClick={handleMenuToggle}> Create My Request </Link></li>
									<li><Link to="/all-requests" className={window.location.pathname === "/all-requests" ? "active font-weight-bold" : "active" } onClick={handleMenuToggle}> All Requests </Link></li>
									
									{Auth.isAuthLogIn()?
									<>
									{window.screen.width >= 1200 ? <Notification /> : null}
									<UserProfile/>
									</>
									:
									window.screen.width >= 1200 ? <LoginRegister/> : null
									
									}
								</ul>

								<ul className="list-inline d-xl-none">
									{/* <li className="bell-btn">
										<button className="dropdown-toggle btn bell-nav-toggle" type="button" id="notification-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<i className="fas fa-bell"></i> <span className="notif-circle"></span>
										</button>

										<div className="dropdown-menu" aria-labelledby="notification-menu">
											<div className="dropdown-menu-inner">
												<div className="notify-drop-head">
													<h4> Notifications </h4>
													<button type="button" className="notify-markRead-btn" onClick={()=><Redirect to='notifications'/>}> See all </button>
												</div>

												<div className="notify-list">
													<div className="notify-item unread-notify" data-toggle="modal" data-target="#notification-modal">
														<div className="ni-icon">
															<span> <i className="fas fa-bell"></i> </span>
														</div>
														<div className="ni-content">
															<p> <strong> Congratulations! </strong> The service provider has completed your service request.</p>
															<span className="ni-time"> Just Now </span>

															<button type="button" className="notify-delete"><i className="fal fa-trash-alt"></i></button>
														</div>
													</div>

													<div className="notify-item unread-notify" data-toggle="modal" data-target="#notification-modal">
														<div className="ni-icon">
															<span> <i className="fas fa-bell"></i> </span>
														</div>
														<div className="ni-content">
															<p> You have accepted the Quote <strong> ID 63587 </strong> for your Service Request...  </p>
															<span className="ni-time"> 3 Days Ago </span>
															<button type="button" className="notify-delete"><i className="fal fa-trash-alt"></i></button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</li> */}
									{Auth.isAuthLogIn()?
									<>
									
									<li className="nav-item user-btn-item d-inline-block">
										<div className="user-menu dropdown">
											<button className="dropdown-toggle btn user-btn" type="button" id="userMenuButton"
												data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<div className="userBtn-icon"> 
												{
													authUser ? <Image src={config.endpoints.image_path+authUser?.profile_photo||images.ProfilePicture} className="img-fluid"
													alt="" />  : null
												}
													
												</div>
												<span className="user-name"> Welcome,{authUser && authUser.first_name}  <i className="fal fa-chevron-down"></i> </span>
											</button>

											<div className="dropdown-menu" aria-labelledby="userMenuButton">
												<Link className="dropdown-item" to="/my-request"> My Requests  <Image src={images.requestMenuIcon} className="img-fluid" alt="" /></Link>
												<Link className="dropdown-item" to="/my-profile"> My Profile <Image src={images.profileIcon} className="img-fluid" alt="" /></Link>
												<Link className="dropdown-item" to="/change-profile-type"> Change Profile Type  <Image src={images.menuChangeProfileIcon} className="img-fluid" alt="" /></Link>
												<Link className="dropdown-item" to="/notifications"> My Notifications <Image src={images.menuChatIcon} className="img-fluid" alt="" /> </Link>
												<Link className="dropdown-item" to="/my-transactions"> My Transactions <Image src={images.menuTransactionIcon} className="img-fluid" alt="" /> </Link>
												<Link className="dropdown-item" to="/my-feedbacks"> My Feedbacks <Image src={images.menuFeedbackIcon} className="img-fluid" alt="" /> </Link>
												{/* <Link className="dropdown-item" to="/notifications"> My Notifications <Image src={images.notificationsIcon} className="img-fluid" alt="" /> </Link> */}
												<Link className="dropdown-item" to="/login" onClick={APIService.logout}> Sign Out <Image src={images.signoutIcon} className="img-fluid" alt="" /> </Link>
											</div>
										</div>
									</li>
									
									<Notification />
									{/* {window.screen.width <= 1200 ? <Notification /> : null} */}
									
									</>
									:
									null
									}
									
									<li className="menuToggle" onClick={handleMenuToggle}>
										<a href="javascript:void(0);" className={`cd-nav-trigger ${menuToggleActive ? "active": ""}`}>
											<span className="cd-nav-icon"></span>
										</a>
									</li>
									{Auth.isAuthLogIn() ? null : <LoginRegister/>}
								</ul>
							</nav>
						</div>
					</div>
				</div>
				<Dialogue state={dialogueState} setState={setDialogueState}/>
			</header>
		</>
	);
}

export default Header;

//dummy comment for new merge
