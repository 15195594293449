import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// https://console.cloud.google.com/apis/credentials/key/c5318f6b-3c37-452a-90b3-b74613dbab64?project=ezunction

// eZunction@gmail.com
// Password2020

// MAPS API Key: AIzaSyAWH-ohRkp9LQbMZrCiYPgUTz8JlaEKn7U
