import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import images from '../../Utility/Images';
// import Modals from '../Modals/Modals';
import Auth from "../../Auth/Auth";

const Footer = () => {

const [color, setColor] = useState({
	contactUs:"",
	aboutUs:'',
	privacyPolicy:''
})

const authData = Auth.getUserDetails();

// console.log("rrrrrrrrrrvvvvvvvvvvvv", authData?.role_type)

useEffect(()=>{
	
	if (window.location.pathname === '/contact-us'){
		setColor({
			...color,
			contactUs:"footer-active"
		})
	  }else if(window.location.pathname === '/about-us'){
		setColor({
			...color,
			aboutUs:"footer-active"
		})
	  }else if(window.location.pathname === '/privacy-policy'){
		setColor({
			...color,
			privacyPolicy:"footer-active"
		})
	  }
},[window.location.pathname])

    return (
        <>
        <footer>
		<div className="footer-circle-box"></div>
		<div className="container">
			<div className="row footer-top-row">
				<div className="col-xl-5">
					<div className="footer-app-link">
						{/* <a to="https://play.google.com/store" target="_blank"> <img src={images.PlayStore} className="img-fluid" alt="" /></a> */}
						{/* <a to="https://www.apple.com/in/app-store/" target="_blank"> <img src={images.AppleStore} className="img-fluid" alt="" /></a> */}
						
                                    <a href=""> <img src={images.PlayStore} className="img-fluid" alt="" /></a>
                                    <a href=""> <img src={images.AppleStore} className="img-fluid" alt="" /></a>
                                
									</div>
                                
                                <h5 className="mt-3 text-white mb-3 mb-md-0">
                                    Our mobile App is coming soon ...
                                </h5>

					
				</div>
				
				<div className="col-xl-7">
					<div className="footer-link-block">
						<div className="footer-link">
							<div className="footer-title">
								<h4> E-mail </h4>
							</div>
							<div className="footer-email">
								<a href="mailto:support@ezunction.com"> support@ezunction.com </a>
							</div>
						</div>

						<div className="footer-link">
							<div className="footer-title">
								<h4> Quick Links </h4>
							</div>
							<ul>
								<li> <Link to="/all-requests"> All Requests </Link> </li>
								<li> <Link to="/start-earning"> Start Earning Now </Link> </li>
								<li> <Link to="/create-my-request"> Create My Request </Link> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="footer-bottom">
						<div className="footer-bl order-2 order-xl-1">
							<span> © 2020 eZunction All rights reserved. </span>
						</div>

						<div className="footer-br order-1 order-xl-2">
							<ul>
								<li> <Link className={color.contactUs} to="/contact-us"> Contact Us </Link> </li>
								<li> <Link className={color.aboutUs} to="/about-us" > About eZunction </Link> </li>
								{/* <li> <Link className={window.location.pathname === '/ezunction-feedback' ? "footer-active" : "" } to="/ezunction-feedback"> eZunction Feedback </Link> </li> */}
								<li> <Link to={`${authData && authData?.role_type ? "/ezunction-feedback" : "/login" }`}> eZunction Feedback </Link> </li>
								<li> <Link className={color.privacyPolicy} to="/privacy-policy"> Privacy Policy </Link> </li>
								{/* <li> <Link className={window.location.pathname === '/term-conditions' ? "footer-active" : "" } to="/term-conditions"> Terms and Conditions </Link> </li> */}
								<li> <Link to="/term-conditions"> Terms and Conditions </Link> </li>
								{/* <li> <Link to={`${authData && authData?.role_type ? "/insurance-coverage" : "/login" }`}> Insurance Coverage </Link> </li> */}
								<li> <Link to={`${(authData && authData?.role_type) === 2 || (authData && authData?.role_type) === 3  ? "/Service_Provider_Guide" : "/login" }`}> Service Provider Guide </Link> </li>
								<li> <Link className={window.location.pathname === '/faq'?"footer-active" : null} to={`${authData && authData?.role_type ? "/faq" : "/login" }`}> FAQs </Link> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
	{/* <Modals/> */}
        </>
    );
}

export default Footer;
