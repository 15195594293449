import { usePlaidLink } from 'react-plaid-link';
import React, {useState, useCallback} from 'react';

import { APIService } from '../Apis/APIServices';
  
const Link = (props) => {

    const [bankToken, setBankToken] = useState("")

    console.log("tttttttttaaaaaaaaaaaaa")
  
    const onSuccess = useCallback((public_token, metadata) => {
  
      // send public_token to server

      // console.log("asdksahdsad", metadata)

      // let el = document.getElementsByTagName("BODY")[0]
      //   console.log("exit has",el.style.overflow)
      //   el.style.overflow = "visible"

      const data = {
        public_token: public_token,
        account_id: metadata.accounts[0].id
      }

  
      APIService.sendTokenLink(data)
      .then(res=>{
        if(res.status === 200){
            setBankToken(res.data.token)
            props.sendBankToken(res.data.token)

            localStorage.setItem("bankToken", JSON.stringify({id: res.data.token, account_number: `Bank: ${metadata.institution.name}`}))

            if(props.confirmModal) 
             localStorage.setItem("CMO", "true")
           
            
            window.location.reload()
        }
      })
      .catch((err)=>{
        window.location.reload()
        
        if(props.confirmModal) 
          localStorage.setItem("CMO", "true")
      })
  
      // Handle response ...
  
    }, []);

    const onEvent = useCallback((eventName, metadata) => {
     
      // console.log("event on load", eventName, metadata);

      if(eventName === "EXIT"){
        document.body.style.overflow = null
      }
    }, []);

    const onExit = useCallback((error, metadata) => {
        // log onExit callbacks from Link, handle errors
        // https://plaid.com/docs/link/web/#onexit
        // console.log("fffffffffffffdddddddd", error, metadata);
        if(props.confirmModal) 
             localStorage.setItem("CMO", "true")

        window.location.reload()
        // console.log("exit has",error , metadata)
        // let el = document.getElementsByTagName("BODY")[0]
        // let el = document.querySelector("dark-header")
        // console.log("exit has",document.body.style.overflow)
        // el.style.removeAttribute("style")
        // document.body.style.overflow = null
        // document.body.style.backgroundColor = "red"
        // document.getElementsByTagName("body")[0].style.overflow = "visible";
        // props.sendBankToken("")
        // props.onCancel()
      }, []);
  
    const config= {
  
      token: props.linkToken,
  
    //   receivedRedirectUri: window.location.href,
  
      onSuccess,
      onEvent,
      onExit,
  
    };
  
    const { open, ready } = usePlaidLink(config);
  
    return (
  
    //   <button onClick={() => open()} disabled={!ready}>
  
    //     Link account
  
    //   </button>

    // <div><button onClick={()=>open()}>Click me</button></div>
    <>
    { props.linkToken ? open() : null}
    </>
  
    );
  
  };

  export default Link