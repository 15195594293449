import { useState } from "react";
import { APIService } from "../Apis/APIServices";

const CommentModel = (props) => {
  const [input, setInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input) {
      // let data = {input, request_id : props.requestData.id}
      let data = {
        quote_id: props.requestData.id,
        comment: input,
      };
      APIService.postComment(data)
        .then((res) => {
          // console.log("res-----", res);
          if (res.status === 200) {
            setInput("");
            document.getElementById("cancleButton").click();
            window.location.reload();
          }
        })
        .catch((e) => console.log("error", e));
    }
  };

  return (
    <div class="scale_cus_modal warrantyRequest-wrap" id="addComment-modal">
      <div class="scale_modal_body">
        <div class="modal_inner">
          <div class="scm_header">
            <h2 class="scm_head-title"> Add Comment </h2>
          </div>
          <div class="scm_body">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div class="form-group">
                <div class="textarea-group">
                  <textarea
                    class="form-control resize-none"
                    rows="6"
                    placeholder="Type here..."
                    maxLength={50}
                    onChange={(e) => setInput(e.target.value)}
                    required
                    value={input}
                  ></textarea>
                  <span class="textarea-limit"> {50 - input.length } Characters Limit </span>
                </div>
                <span class="textNote">
                  {" "}
                  “Please do not share your personal/contact details such as
                  email id, contact number, address etc.”{" "}
                </span>
              </div>

              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                  id="cancleButton"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-red"
                  // data-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentModel;
