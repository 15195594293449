import { useState } from "react";
import { APIService } from "../Apis/APIServices";

const NoShowModal = (props) => {
  const [input, setInput] = useState("");

  const [loader, setLoader] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input && props.title === "Customer No Show") {
      setLoader(true)
      APIService.postNoCustomerShow({
        no_show_reason: input,
        request_id: props.requestData.id,
        disable_comments:"Customer No Show or Disabled by Admin"
      })
        .then((res) => {
          setLoader(false)
          if (res.status === 200) {
            // console.log("res---->>", res.data.message);
            setInput("");
            document.getElementById("cancleButtonNoShow").click();
            // window.location.reload()
            props.setDialogueState({
              type : 'Success',
              show:true,
              // message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. We have issued you a courtesy credit of $${res.data.spNoShowCharge}. Please review your transaction history. `+ 
              //            `You may also look for other available service requests in the area.`,
              // message : `Sorry, for the inconvenience. We have notified the customer and have disabled the service request. `+ 
              //            `Please confirm or submit your quotes on other service requests.`,
              message: res.data.message || "",
              refresh:true,
            })
          }else{
            // console.log("eeeeeeeeeeeeeeeee res else",res)
            props.setDialogueState({
              type : 'Error',
              show:true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
          }
        })
        .catch((e) => {
          setLoader(false)
          props.setDialogueState({
            type : 'Alert',
            show:true,
            // message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. We have issued you a courtesy credit of $${res.data.spNoShowCharge}. Please review your transaction history. `+ 
            //            `You may also look for other available service requests in the area.`,
            message : (e && e.response && e.response.data && e.response.data.message) || "",
            // refresh:true,
          })
          setInput("")
          document.getElementById("cancleButtonNoShow").click();
        });
    } else if (input && props.title === "Service Provider No Show") {
      setLoader(true)
      APIService.postNoSPShow({
        no_show_reason: input,
        quote_id: props.requestData.id,
      })
        .then((res) => {
          setLoader(false)
          if (res.status === 200) {
            // console.log("res---->>", res);
            setInput("");
            document.getElementById("cancleButtonNoShow").click();
            props.setDialogueState({
              type : 'Success',
              show:true,
              // message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. We have issued you a courtesy credit of $${res.data.spNoShowCharge}. Please review your transaction history. `+ 
              //            `You may also look for other available service requests in the area.`,
              message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. `+ 
                         `Please review and accept any of the other quotes for your service request.`,
              // message: res.data.message || "",
              refresh:true,
            })
            
          }else{
            // console.log("eeeeeeeeeeeeeeeee res else",res)
            props.setDialogueState({
              type : 'Error',
              show:true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
          }
        })
        .catch((e) => {
          setLoader(false)
          props.setDialogueState({
            type : 'Alert',
            show:true,
            // message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. We have issued you a courtesy credit of $${res.data.spNoShowCharge}. Please review your transaction history. `+ 
            //            `You may also look for other available service requests in the area.`,
            message : (e && e.response && e.response.data && e.response.data.message) || "",
            // refresh:true,
          })
          setInput("")
          document.getElementById("cancleButtonNoShow").click();
        });
    }
  };


  return (
    <div
      class="scale_cus_modal warrantyRequest-wrap"
      id="customerNoShow-modal-wrapper"
    >
      <div class="scale_modal_body">
        <div class="modal_inner">
          <div class="scm_header">
            <h2 class="scm_head-title"> {props.title} </h2>
          </div>
          <div class="scm_body">
            <form onSubmit={handleSubmit}>
              <div class="form-group">
                <label> Provide the No Show Reason </label>
                <div class="textarea-group">
                  <textarea
                    class="form-control resize-none"
                    rows="6"
                    placeholder="Provide detailed desciption explaining why you are marking the costumer as No Show *"
                    maxLength={100}
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                    required
                  ></textarea>
                  <span class="textarea-limit"> {100 - input.length} Characters Limit </span>
                </div>
              </div>

              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                  id="cancleButtonNoShow"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-red" disabled={loader}>
                {loader ? "Processing ..." : "Submit" }{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoShowModal;
