import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
export default function RegisterSuccessful() {
    return (
        <div>
            <Header/>
            	<section className="auth-success-section">
		<div className="container h-100">
			<div className="row h-100">
				<div className="col-12 col-lg-6 mx-auto">
					<div className="success-inner">
						<div className="success-content">
							<h1> Congratulations! </h1>
							<p> You have successfully registered to the <span> eZunction </span> network. </p>
							<p>Please activate your account using the link sent to your email address. </p>
							<div className="d-flex justify-content-center"><Link className="btn btn-red btn-sm" to="/login"> OK</Link></div> 
						</div>
						<div className="success-img">
							<img src="../images/thank-character.svg" className="img-fluid m-auto d-block" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
        </div>
    )
}
