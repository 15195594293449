// import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import config from "../../Components/Apis/config";
// import images from "../../Utility/Images";
// import HomeContext from "./HomeContext/HomeContext";

// const ServiceCategoriesSlider = () => {
//   const [state, dispatch] = useContext(HomeContext);
//   // const [icon, setIcon] = useState({})

// //  console.log("state is ", state)
//   const PreviewsArrow = () => {
//     return (
//       <>
//         <button
//           type="button"
//           onClick={previews}
//           class="prev-control slick-arrow-control"
//         >
//           <i class="far fa-chevron-left"></i>
//         </button>
//       </>
//     );
//   };
  
//   const NextArrow = () => {
//     return (
//       <>
//         <button
//           type="button"
//           onClick={next}
//           class="next-control slick-arrow-control"
//         >
//           <i class="far fa-chevron-right"></i>
//         </button>
//       </>
//     );
//   };

//   var slider = this;

//   const next = () => {
//     slider.slickNext();
//   };

//   const previews = () => {
//     slider.slickPrev();
//   };

//   const settings = {
//     infinite: true,
//     speed: 1000,
//     autoplay: true,
    
//     autoplaySpeed: 1500,
//     easing: 'linear',
//     slidesToScroll: 1,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//   };

//   // const getCategoryImage = (i) => {
//   //   setIcon((prevState) => (
//   //     {
//   //       ...prevState,
//   //       [i]: Math.ceil((Math.random()*(9-1)) + 1)
//   //     }
//   //   ))
//   //   // return  i <= 8 ? i+1 : 8;
//   //   // return  Math.ceil((Math.random()*(9-1)) + 1)
//   // }

//   // for (let i = 1; i < state.categories.length; i++) { 
//   //   setIcon((prevState) => (
//   //     {
//   //       ...prevState,
//   //       [i]: Math.ceil((Math.random()*(9-1)) + 1)
//   //     }
//   //   ))
//   //   console.log("state is ", icon[i])

//   // }
  

//   return (
//     <>
//       <PreviewsArrow />
//       <NextArrow />
//       <Slider {...settings} ref={(c) => (slider = c)}>
//         {state.categories.map((res, i) => (
//         <>
          
//           {/* <Link
//             key={i}
//             to="/service-categories"
//             className={`sc-item cat-color-${i+1 > 8 ? state.categories.length - 9 : i + 1} wow fadeInUp`}
//           >
//             <div className="sc-circle-img">
             
//               <img
//                 src={`/images/category/circle-${i+1 > 8 ? state.categories.length - 9 : i + 1}.svg`}
//                 className="img-fluid"
//                 alt=""
//               />
//             </div>
//             <div className="sc-icon">
//               <img
//                 src={
//                 config.baseurl+
//                   config.endpoints.cms_image_path +
//                   res.category_image
//                 }
//                 className="img-fluid"
//                 alt=""
//               />
//             </div>
//             <div className="sc-title">
//               <span>{res.category_name} </span>
//             </div>
//             </Link> */}

//             <Link
//             key={i}
//             to="/service-categories"
//             className={`sc-item cat-color-${i} wow fadeInUp`}
//           >
//             <div className="sc-circle-img">
//               {/* <img src={images.circle1} className="img-fluid" alt="" /> */}
//               <img
//                 src={`/images/category/circle-${i}.svg`}
//                 className="img-fluid"
//                 alt=""
//               />
//             </div>
//             <div className="sc-icon">
//               <img
//                 src={
//                 config.baseurl+
//                   config.endpoints.cms_image_path +
//                   res.category_image
//                 }
//                 className="img-fluid"
//                 alt=""
//               />
//             </div>
//             <div className="sc-title">
//               <span>{res.category_name} </span>
//             </div>
//             </Link>
//             </>
//         ))}
//       </Slider>
//     </>
//   );
// };

// export default ServiceCategoriesSlider;



import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import config from "../../Components/Apis/config";
import images from "../../Utility/Images";
import HomeContext from "./HomeContext/HomeContext";
const ServiceCategoriesSlider = () => {
  const [state, dispatch] = useContext(HomeContext);

  const [rtl, setRtl] = useState(true)

  const PreviewsArrow = () => {
    return (
      <>
        <button
          type="button"
          onClick={()=>setRtl(true)}
          className="d-none d-xl-block prev-control slick-arrow-control"
        >
          <i className="far fa-chevron-left"></i>
        </button>
      </>
    );
  };
  const NextArrow = () => {
    return (
      <>
        <button
          type="button"
          onClick={()=>setRtl(false)}
          className="d-none d-xl-block next-control slick-arrow-control"
        >
          <i className="far fa-chevron-right"></i>
        </button>
      </>
    );
  };
 
  var slider = this;
  // const next = () => {
  //   slider.slickNext();
  // };
  // const previews = () => {
  //   slider.slickPrev();
  // };
  const settings = {
    arrows:false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    // swipeToSlide: true,
    // onSwipe: "right",
    // swipe: true,
    rtl: rtl,
    slidesToShow: 6,
    slidesToScroll: -1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: 5,  autoplay: false, scroll: true, speed: 30,} },
      { breakpoint: 1100, settings: { slidesToShow: 4, slidesToScroll: 4, autoplay: false, scroll: true, speed: 30,} },
      { breakpoint: 900, settings: { slidesToShow: 3, slidesToScroll: 3, autoplay: false, scroll: true, speed: 30,} },
      { breakpoint: 760, settings: { slidesToShow: 2, slidesToScroll: 2, autoplay: false, scroll: true, speed: 30,} },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, autoplay: false, scroll: true, speed: 30,} },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1, autoplay: false, scroll: true, speed: 30,} },
    ],
    variableWidth: true
  };
  return (
    <>
      <PreviewsArrow />
      <NextArrow />
      <Slider {...settings} ref={(c) => (slider = c)}>
        {state.categories.map((res, i) => (
          <Link
            key={i}
            // to="/all-requests"
            to={{
              pathname: "/all-requests",
              state: {
               
                serviceType: "",
                serviceSubCat: "",
                serviceCat: res.id,
              },
              }}
            className={`sc-item cat-color-${i === 0 ? 1 : i > 8 ?  1 : i + 1} wow fadeInUp`}
          >
            <div className="sc-circle-img">
              {/* <img src={images.circle1} className="img-fluid" alt="" /> */}
              <img
                src={`/images/category/circle-${i === 0 ? 1 : i > 8 ?  1 : i + 1}.svg`}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="sc-icon">
              <img
                src={
                  // config.baseurl +
                  // config.endpoints.image_path +
                  // res.category_image
                  config.baseurl+
                  config.endpoints.cms_image_path +
                  res.category_image
                }
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="sc-title">
              <span>{res.category_name} </span>
            </div>
          </Link>
        ))}
      </Slider>
    </>
  );
};

export default ServiceCategoriesSlider;
