import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import images from '../../Utility/Images';

const MyAccountMenu = () => {

    const history= useHistory();
    return (
        <>
            <div className="db-menu-block">
                <ul>
                    <li>
                        <Link to="/my-request" className={history.location.pathname=="/my-request"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.requestIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Requests </h4>
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-profile" className={history.location.pathname=="/my-profile"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.myProfile} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Profile </h4>
                        </Link>
                    </li>
                    <li>
                        <Link to="/change-profile-type" className={history.location.pathname=="/change-profile-type" ?'active-db':''}>
                            <div className="dbm-icon" >
                                <img src={images.changeProfileIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> Change Profile Type </h4>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/my-chat" className={history.location.pathname=="/my-chat"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.chatIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Chats </h4>
                        </Link>
                    </li> */}
                      <li>
                        <Link to="/notifications" className={history.location.pathname=="/notifications"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.chatIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Notifications </h4>
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-transactions" className={history.location.pathname=="/my-transactions"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.transactionIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Transactions </h4>
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-feedbacks" className={history.location.pathname=="/my-feedbacks"?'active-db':''}>
                            <div className="dbm-icon">
                                <img src={images.feedbackIcon} className="img-fluid" title="" alt="" width= "70px" height= "50px" />
                            </div>
                            <h4> My Feedbacks </h4>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default MyAccountMenu;
