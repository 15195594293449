// Do Not Remove this comment // 
/* global google */

 

import React, { useEffect, useState } from 'react';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import MyAccountMenu from "../Components/Common/MyAccountMenu";
import { APIService } from '../Components/Apis/APIServices';

import Rating from "./Rating";
import config from "../Components/Apis/config";
import GetInTouch from '../Components/Common/GetInTouch';
import {Link} from 'react-router-dom'


const TrackServiceProvider = (props) => {
 

    let map : google.maps.Map, infoWindow : google.maps.InfoWindow;

    if(google !== undefined){
      infoWindow = new google.maps.InfoWindow();
    }
      

    const trackLocation = ({ onSuccess, onError = () => { } }) => {
      if ('geolocation' in navigator === false) {
        return onError(new Error('Geolocation is not supported by your browser.'));
      }

      // Use watchPosition instead.
      return navigator.geolocation.watchPosition(onSuccess, onError);
    };

    const getPositionErrorMessage = code => {
      switch (code) {
        case 1:
          return 'Permission denied.';
        case 2:
          return 'Position unavailable.';
        case 3:
          return 'Timeout reached.';
        default:
          return null;
      }
    }

    const [data, setData] = useState({})
    const [userType ,setUserType] = useState("")
    const [location, setLocation] = useState({
      user:{},
      sp:{}
    })

    const [currentLocation, setCurrentLocation] = useState(false)

    const [eta, setEta] = useState("NA")

    useEffect(() => {
	
      if(data.id){ 
     
       getData()
    }
      
      
      }, [data]);

      const getData = ()=>{
        APIService.trackingLocations(data.id)
        .then((response)=>{
            //  console.log("tttttttttttttttt", response)
            if(response.data.location){
              setLocation(response.data.location)
              setEta(response.data.eta)
            }
          })
          .catch((error)=>{
            console.log(error)
           // window.location.href = '/login'
          })
      }


    // const [current, setData] = useEffect()


    const createMap = (latlng) => {
      return new google.maps.Map(document.getElementById('google-map-display'), {
        center: latlng,
        zoom: 6
      });
    };

    const createMarker = ({ map, position }) => {
      return new google.maps.Marker({ map, position });
    };


    const getLocation = async(map) => {

      // let place = "Flex apartment sector 62 noida"
      // console.log("rrrrrrrrrrrrrrrrr", place.location.lat())
      // var autoComplete = new google.maps.Autocomplete("flex apartment sector 62 noida")
      // new google.maps.event.addListener(autoComplete, "", ()=>{
      //   var places = autoComplete.getplace()
      //   console.log("rrrrrrrrrrrr", places.geometry.location.lat())
      // })
      
console.log("SP LOCATION ASK")

        // if (navigator.geolocation) {
          // navigator.geolocation.getCurrentPosition(
          //   (position) => {
          //     const pos = {
          //       lat: position.coords.latitude,
          //       lng: position.coords.longitude,
          //     };

          //     // console.log("qqqqqqqqqqqqq",position.coords.latitude,position.coords.longitude)

          //     let latlng = new google.maps.LatLng(pos.lat, pos.lng)
          //     map.setCenter(latlng);
          //     // const marker = new google.maps.Marker({ map, position: latlng });

          //     // setLocation((prev) =>{
          //     //   return({
          //     //     ...prev,
          //     //     sp:{
          //     //       lat: pos.lat,
          //     //       lng: pos.lng
          //     //     }
          //     //   })
          //     // })

          //     const spLocation = {
          //       requestId: data.id || "",
          //       lat: pos.lat,
          //       long: pos.lng
          //     }

          //     APIService.sendSpLocation(spLocation)
          //     .then((res) => {
          //       if (res.status === 200) {
          //         getData()
          //         setCurrentLocation(true)
          //       }
          //     })
          //     .catch((e) => console.log("close error", e));
          


          //   },
          //   () => {
          //     handleLocationError(true, infoWindow, map.getCenter());
          //   }
          // );
        // } else {
        //   // Browser doesn't support Geolocation
        //   handleLocationError(false, infoWindow, map.getCenter());
        // }

        navigator.geolocation.getCurrentPosition(locSuccess, locError)

        function locSuccess (position){
          const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              // console.log("qqqqqqqqqqqqq",position.coords.latitude,position.coords.longitude)

              let latlng = new google.maps.LatLng(pos.lat, pos.lng)
              map.setCenter(latlng);
              // const marker = new google.maps.Marker({ map, position: latlng });

              // setLocation((prev) =>{
              //   return({
              //     ...prev,
              //     sp:{
              //       lat: pos.lat,
              //       lng: pos.lng
              //     }
              //   })
              // })

              const spLocation = {
                requestId: data.id || "",
                lat: pos.lat,
                long: pos.lng
              }

              APIService.sendSpLocation(spLocation)
              .then((res) => {
                if (res.status === 200) {
                  getData()
                  setCurrentLocation(true)
                }
              })
              .catch((e) => console.log("close error", e));
          


            }
    
    
    function locError (error){
      handleLocationError(true, infoWindow, map.getCenter());
    }
    

    }

    
    const initMap = async () => {

        // let initialPosition = new google.maps.LatLng(28.6195712, 77.3783552);
        // let destinationPosition = new google.maps.LatLng(28.6203361, 77.352197);

        let initialPosition = new google.maps.LatLng(location.sp.lat, location.sp.lng);
        let destinationPosition = new google.maps.LatLng(location.user.lat, location.user.lng);

        const map = createMap(initialPosition);

        if(userType === "service_provider" && !currentLocation){
          getLocation(map);
        }
       
        
        
        // const marker = createMarker({ map, position: initialPosition });

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();

        directionsRenderer.setMap(map);

        calculateAndDisplayRoute(directionsService, directionsRenderer, initialPosition, destinationPosition);

       

        // trackLocation({
        //     onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
        //       marker.setPosition({ lat, lng });
        //       map.panTo({ lat, lng });
        //     },
        //     onError: err =>alert(`Error: ${getPositionErrorMessage(err.code) || err.message}`)
        // });

    }

    function calculateAndDisplayRoute(directionsService, directionsRenderer, originLatLng, destinationLatLng) {

      directionsService
        .route({
          origin: originLatLng,
          destination: destinationLatLng,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
            console.log("directionsRenderer", response)
          directionsRenderer.setDirections(response);
        })
        .catch((e) => console.log("Google Error:-  ", e)); //window.alert("Directions request failed due to " + status));
    }



    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    }

    useEffect(() => {

        initMap()

    }, [ initMap ]);

    //////////////////////////////////////////////////////

   

    useEffect(()=>{
      if (props.location.state !== undefined && props.location.state !== null ) { 
        setData((props.location.state && props.location.state.activeData) || {} )
        setUserType(props.location.state && props.location.state.user)
      }
    },[props.location.state])

    // console.log("tttttttttt", location)
    return (
      <>
        <Header />
        <section className="gray-hero-section db-gh-section">
          <div className="gh-clip-bg">
            <img
              src="../images/clip-bg.svg"
              className="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="inner-caption wow fadeInUp text-center">
                  
                    {" "}
                    {userType === "service_provider" ?
                    <h1 className="mb-0"> Route <span> Direction </span> </h1>
                    : 
                    <h1 className="mb-0"> Track Service <span> Provider </span> </h1>
                    }
                  
                  <div className="req-text-gh">
                    {" "}
                    Request ID :{" "}
                     <Link
                    // to="/my-request"
                    to={{
                      pathname: "/my-request",
                      state: {
                        requestId: data.id,
                        activeTab:  "active",
                        requestDetails: data || {},
                        // activeTab: "ongoing"
                      },
                    }}
                    className="orange-text"
                  >
                      {/* {" "}
                      25632156{" "} */}
                      {data.id ? data.id : "N/A"}
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="db-top-menu pb-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <MyAccountMenu />
              </div>
            </div>
          </div>
        </section>
        <div className="container">
        {userType === "service_provider" ?
                    <h6 className="mb-3 text-danger d-flex justify-content-center">ETA is tentative. Please click on the route direction to get the exact route to the service location.</h6>
                    : 
                    <h6 className="mb-3 text-danger d-flex justify-content-center">ETA is tentative. It just provides you an estimate of the Service Provider's arrival time.</h6>
                    }
        </div>
        <section className="requestDetail-section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="wow fadeInUp">
                  <div className="ard-cat-item hnc-item w-100 service-cat-item">
                    <a
                      href="javascript:void(0)"
                      className="service-cat-inner myRq-link open-quote"
                      data-rel="quoteDetailsTab-1"
                    >
                      <div className="sc-head">
                        <div className="left">
                        <img
                          src={ userType === "customer" ?
                            config.endpoints.image_path +
                            data?.provider?.profile_photo
                            :
                            config.endpoints.image_path +
                            data?.user?.profile_photo
                          }
                          className="img-fluid"
                          alt=""
                        />
                        </div>
                        <div className="right">
                          {userType === "customer" ? <h3> {data?.provider?.first_name} {data?.provider?.last_name} </h3> : <h3> {data?.user?.first_name} {data?.user?.last_name} </h3> } 
                          <div className="rating">
                                      <Rating rating={userType === "customer" ? data?.provider?.rating : data?.user?.rating} />
                                        <span className="rating-count">
                                        {userType === "customer" ? data?.provider?.rating : data?.user?.rating} 
                                        </span>
                                      </div>
                        </div>
                      </div>

                      <div className="sc-body">
                        <div className="scb-top">
                          <span className="service-type"> On My Way </span>
                        </div>
                        <div className="scb-bottom d-flex justify-content-between">
                          <span className="sc-name"> ETA : {eta || "NA"} </span>
                          {userType !== "customer" ?
                            <button
                           className='btn-red'
                           style={{borderRadius: "25px"}}
                          >
                            <a 
                              className="sc-name text-white"
                              target="_blank" 
                              href={`https://www.google.com/maps/dir/?api=1&origin=${location.sp.lat},${location.sp.lng}&destination=${location.user.lat},${location.user.lng}`}>
                                Route Direction
                            </a>
                            </button>
                          :
                          null}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-8" style={{ height: "500px", padding:"0px" }}>
                <div className="trackService-proovider-block">
                  <div
                    id="google-map-display"
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>

                  {/*<img src="../images/track-map.jpg" className="img-fluid" title="" />*/}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="getInTouch-section">
          <div className="container">
            <div className="col-12">
              <div className="getInTouch-box">
                <div className="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div className="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i className="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i className="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />
        <Footer />
      </>
    );
}

export default TrackServiceProvider;

//tracking dummy comment
