import { useState } from 'react';
import { APIService } from "../Apis/APIServices";

import Dialogue from "../../Components/Common/Dialogue";

const WarrantyModal = (props) => {
    console.log("yoge",props)
    const [warrantyDetails, setWarrantyDetails] = useState("");
    // const [closure_comments, setclosure_comments] = useState("");
    const closeData=props && props.closeData?props.closeData:"";

	
    const [dialogueState, setDialogueState] = useState({
      type: "",
      show: false,
      message: "",
    });

	const handleSubmit = async() => {
    
        var data={
            request_id:closeData.id,
            quote_id: closeData.quote_id,
            closure_comments:"",
            details:warrantyDetails ? warrantyDetails : "",
            warranty_creation_date:"",
            warranty_closure_date:"",
            warranty_expiration_date:"",
            sr_status:closeData.sr_status,
            provider_id: closeData.provider_id
        }
	    
        await APIService.addWarrantyRequest(data)
        .then((res) => {
            // console.log("close res", res);
            // window.location.reload()
            if(res.status === 200){
              setDialogueState({
                type: "Success",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
                // message: res.data.message || "",
                message: "Warranty request has been successfully created.",
                refresh: true,
                // back : history.goBack
              });
            }else{
              setDialogueState({
                type: "Error",
                show: true,
                message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
            }
        })
        .catch((e) => {
          setDialogueState({
            type: "Alert",
            show: true,
            // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
            message : (e && e.response && e.response.data && e.response.data.message) || "",
            // refresh: true,
            // back : history.goBack
          });
        });
  }

    // console.log("rrrrrrrr",closeData)

	return (
    <>
    <div
      class="scale_cus_modal warrantyRequest-wrap"
      id="warranty-request-modal"
    >
      <div class="scale_modal_body">
        <div class="modal_inner">
          <div class="scm_header">
            <h2 class="scm_head-title"> Warranty Request </h2>
          </div>
          <div class="scm_body">
            <form>
              <div class="form-group">
                <label> Provide the Warranty Details </label>
                <div class="textarea-group">
                  <textarea
                    class="form-control resize-none"
                    rows="6"
                    onChange={(e) => setWarrantyDetails(e.target.value)}
                    placeholder="Provide the Warranty Reason with Issue Details *"
                    maxLength="100"
                  ></textarea>
                  <span class="textarea-limit">
                    {" "}
                    {100 - warrantyDetails.length} Characters Limit{" "}
                  </span>
                </div>
              </div>

              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-red"
                  data-dismiss="modal"
                  onClick={() => handleSubmit()}
                >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  <Dialogue state={dialogueState} setState={setDialogueState} />
  </>
  );
} 

export default WarrantyModal;

