import React, { useState, useEffect } from "react";
import $ from "jquery";
import { APIService } from "../../Components/Apis/APIServices";
import AddNewCard from "../../Pages/AddNewCard";
import Dialogue from "../Common/Dialogue";

const AcceptQuoteModal = (props) => {
    const eleId = props.eleId || "notifiaction-request-modal";
    
    const [bankDetailsCheck, setBankDetailsCheck] = useState(1);
  const [cdCard, setCdCard] = useState("");
  const [bankAccount, setBankAccount] = useState("");
    const [bankAccountShow, setBankAccountShow]= useState(false);
    const [show, setShow] = useState(false);
    const [Countries, setCountries] = useState([]);
  const [tick, setTick] = useState(0)
  
   const [dialogueState, setDialogueState] = useState({
     type: "",
     show: false,
     message: "",
   });

    	const [bankState, setBankState] = useState({
        bank_name: "",
        account_name: "",
        account_number: "",
        routing_number: "",
        account_type: 1,
        account_nick: "",
        isprimary: 0,
        });
    const [bankAcs, setBankAcs] = useState([]);
  const [err, setErr] = useState({});
  
  const [userCardList, setUserCardList] = useState([]);

    useEffect(() => { 

         APIService.fetchCountry()
           .then(async (res) => {
             if (res.data.status == 200) {
               setCountries(res.data.data);
             }
           })
             .catch((error) => { });
        
        // APIService.fetchBankAccount()
        //   .then((res) => setBankAcs(res.data.data))
        //   .catch((err) => setErr(err));
      
      fetchCards();
       
    }, [])
  
  const fetchCards = () => { 
 APIService.getUserCard()
   .then((res) => {
     res.data &&
       res.data.cards &&
       setUserCardList(res.data.cards.length ? res.data.cards : []);
   })
   .catch((err) => console.log(err));
  }
  
//  console.log("////////////////.............", show);
    
    const handleBankChange = ({ target: { value, name } }) => {
    //   console.log(value, name);
      setBankState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
  const handleBankChangeRadio = (name, value) => { 
      setBankState((prevState) => ({
      ...prevState,
      [name]: value,
      }));
  }

    const handleBankSubmit = (e) => {
      e.preventDefault();
      let filterNickList = bankAcs.filter(
        (data) => data.account_nick === bankState.account_nick
      );
      let filterList = bankAcs.filter((data) => data.isprimary === 1);

      if (filterNickList.length) {
        setDialogueState({
          type: "Error",
          show: true,
          message: "Account nick name should be unique.",
        });
      } else if (bankState.isprimary === 1 && filterList.length) {
        setDialogueState({
          type: "Error",
          show: true,
          message: "Can't have multiple primary bank account.",
        });
      } else {
        APIService.addBankAccount(bankState)
          .then((res) => {
            APIService.fetchBankAccount()
              .then((res) => {
                setBankAcs(res.data.data);

                setBankState({
                  bank_name: "",
                  account_name: "",
                  account_number: "",
                  routing_number: "",
                  account_type: 1,
                  account_nick: "",
                  isprimary: 0,
                });

                setBankAccountShow(false);
                // window.location.href = '/quotes-submit'
                //<Redirect to="quotes-submit"/>
                // window.$("#addNewBank-modal").toggler("modal");
                // data-dismiss="modal"

                // $("#btnSave").click(function () {
                //   //   $("#addNewBank-modal").modal("hide");
                //   $('button[type="submit"]').attr("data-dismiss", "modal");
                // });
              })
              .catch((err) => setErr(err));
          })
          .catch((err) => setErr(err));
      }
    };


    const handleRadio = (value) => {
      // dispatch({ type: name, payload: { [name]: value, key: name } });
      setBankDetailsCheck(value);
    };
  
  const handleTick = (val) => { 
    setTick(val)
  }


  return (
    <>
      <div class="scale_cus_modal" id={eleId}>
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> {props.title}</h2>
            </div>

            <div class="scm_body">
              <div class="notify-modal-body">
                <div className="form-group group-12 primary-radio-group">
                  <label> Select Credit / Debit Card </label>
                  {/* <div className="radio-block">
                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        // name="bondedIns-type"
                        id="bai-yes"
                        value={1}
                        name="bankDetailsCheck"
                        checked={bankDetailsCheck === 1 ? true : false}
                        // onChange={
                        //   handleProfileInformationChange
                        // }
                        // checked
                      />
                      <label
                        className="radio-label-control"
                        for="bai-yes"
                        onClick={() => handleRadio(1)}
                      >
                        {" "}
                        Credit / Debit Card{" "}
                      </label>
                    </div>

                  </div> */}
                </div>
              </div>
              
                <form
                  onSubmit={(e) =>
                    props.handleClick(e, bankDetailsCheck, cdCard)
                  }
                >
                  <div className="form-group group-xs-12">
                    <select
                      className="form-control"
                      // name="country"
                      value={cdCard}
                      onChange={(e) => setCdCard(e.target.value)}
                      name="cdCard"
                      required
                    >
                      <option disabled={true} value="">
                        {" "}
                        Select Credit Card / Debit Card *
                      </option>
                      {userCardList.map((item, index) => (
                        <option value={item.id} key={item + index}>
                          {item.card_nick}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="form-group group-12">
                    <div class="addNewAddress-request">
                      <button
                        type="button"
                        class="btn text-btn"
                        data-toggle="modal"
                        data-target="#addNewCard-modal"
                        onClick={() => setShow(true)}
                      >
                        {" "}
                        <i class="fal fa-plus-circle"></i> Add New Card{" "}
                      </button>
                    </div>
                  </div>

                  {/* <div className="form-group form-checkbox  mt-3">
                    <div className="custom-check-block">
                      <input
                        type="checkbox"
                        className="d-none"
                        id="tick"
                        name="tick"
                        onClick={() => handleTick(tick === 1 ? 0 : 1)}
                        checked={tick === 1 ? true : false}
                      />
                      <label className="custom-check-label" for="tick">
                        {" "}
                        <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                      </label>
                    </div>
                  </div> */}

<div className='d-flex mt-5 mb-5'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                        </div>

                  <div className="scm_footer justify-content-center full-scm_footer">
                    <button
                      type="button"
                      className="btn btn-black mr-3"
                      data-dismiss="modal"
                    >
                      {props.secondaryBtnText}
                    </button>
                    <button
                      type="button"
                      className="btn btn-red"
                      disabled={tick === 0 || cdCard === ""}
                      data-dismiss="modal"
                      data-toggle="modal"
                      // data-target="#confirm-cancel-modal"
                      onClick={(e) =>
                        props.handleClick(e, bankDetailsCheck, cdCard)
                      }
                    >
                      {props.primaryBtnText}
                    </button>
                  </div>
                </form>
              
            </div>
          </div>
        </div>
      </div>

      <AddNewCard
        // dialogueState={dialogueState}
        setDialogueState={setDialogueState}
        userCardList={userCardList}
        Countries={Countries}
        handleHide={() => setShow(false)}
        fetchCards={() => fetchCards()}
        show={show}
      />
      <Dialogue state={dialogueState} setState={setDialogueState} />
    </>
  );
};

export default AcceptQuoteModal;
