import config from "../Apis/config";
// import queryString from 'query-string';
import axios from "axios";
import { Redirect } from "react-router";
import { authHeader } from "./authHeader";
import Auth from "../../Auth/Auth";
import Connection from "../../Utility/Socket";


axios.interceptors.request.use((config) => {
  config.headers.current_role = localStorage.getItem("tabIndex");
  return config;
}, (error) => {
  return Promise.reject(error);
});


export const APIService = {
  login,
  logout,
  registerUser,
  registerProvider,
  authUser,
  fetchAllCategory,
  fetchCountry,
  fetchCity,
  fetchState,
  fetchAllTimeSlot,
  fetchAllServiceType,
  createRequest,
  fetchAllServiceRequests,
  addOtherAddress,
  fetchOtherAddress,
  createRequestQuote,
  addBankAccount,
  fetchBankAccount,
  fetchOnGoing,
  fetchActive,
  fetchClose,
  updateProfileType,
  updateUser,
  editRequest,
  cancelRequest,
  onMyWay,
  fetchQuoteData,
  fetchAcceptQuote,
  fetchRejectQuote,
  fetchAnotherVisit,
  addMarkCompletion,
  acceptCompletion,
  closeWarrantyService,
  disputeReason,
  addDisputeRequest,
  addWarrantyRequest,
  postNoCustomerShow,
  postNoSPShow,
  addWithdrawnQuote,
  withdawStatus,
  confirmQoute,
  updateQoute,
  confirmAPIcall,
  postComment,
  getFeedback,
  getAdditionalDetails,
  getUserCard,
  updateUserBank,
  updateUserCard,
  getSPFeedback,
  getCustomerFeedback,
  fetchOnGoingSP,
  fetchActiveSP,
  fetchCloseSP,
  validateCoupon,
  activateAccount,
  forgetPassword,
  otpLogin,
  updatePassword,
  contactUs,

  // my profile additional information api get apis,
  getUserdrivingLicense,
  getProfileInformation,
  getBusinessLicense,
  getCompanyInformation,

  // my profile additional information api post apis,
  updateUserDrivingLicense,
  updateProfileInformation,
  updateBusinessLicense,
  updateComapnyInformation,

  addUserCard,

  srCancelReason,
  srWithdrawReason,

  // my transaction
  transactionList,
  transactionCustomer,
  transactionSP,
  cancelRequestStatus,
  deleteRequestFile,

  transactionInvoice,
  transactionPayout,

  //notifications
  notifications,
  notificationsDelete,

  /// find now api
  fetchSPList,

  allCategories,
  findNow,

  //// tracking apis
  trackingLocations,
  sendSpLocation,

  ///// sp details screen apis
  spDetails,
  custDetails,

  // reject quote apis
  srRejectReason,
  submitRejectQuote,


  /////token generation for stripe
  tokenLink,
  sendTokenLink,


  //////////accept competion
  getAcceptCompletionData,


  //////// users feedback
  getUserFeedBacks,
  submitUserFeedBacks,

  //////send latitude and lon
  sendLatLong,

  //////people voice
  peopleVoice,

  ///// images/banners URL
  herography,
  advBanner,
  howItWorksVideo,
  startEarningNowVideo,
  aboutUsVideo,
  socialLinks
};

const baseUrl =
  config.url === "baseurl"
    ? config.baseurl
    : config.url === "localurl"
    ? config.localurl
    : config.url === "ngrokurl"
    ? config.ngrokurl
    : config.url === "sandbox"
    ? config.sandbox
    : window.location.hostname;

function login(request) {
  return axios
    .post(baseUrl + config.endpoints.login, request)
    .then(async (user) => {
      console.log("user",user)
      if (user.data?.status == 200) {
        await localStorage.setItem("_tk", user.data.data.token);
        await localStorage.setItem("_cu", JSON.stringify(user.data.data));
        Connection.connect();
      }
      return user;
    })
    .catch((err)=>{return err})
}
function registerUser(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  console.log(form);

  return axios
    .post(baseUrl + config.endpoints.userRegister, form, {
      headers: authHeader(),
      "Content-Type": "multipart/form-data",
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function registerProvider(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    if (Array.isArray(request[key])) {
      form.append(key, JSON.stringify(request[key]));
    } else {
      form.append(key, request[key]);
    }
  });
  return axios
    .post(baseUrl + config.endpoints.providerRegister, form, {
      headers: authHeader(),
      "Content-Type": "multipart/form-data",
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function createRequest(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    if(key=="images"){
      request[key].forEach(image=>form.append('images[]',image))
    }else{
      if (request[key]) form.append(key, request[key]);
    }
    
  });

  
  
  return axios
    .post(baseUrl + config.endpoints.createUserServiceRequest, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
// fetch city state country
function fetchCountry(request) {
  return axios
    .get(baseUrl + config.endpoints.fetchCountry, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function fetchState(request) {
  return axios
    .post(baseUrl + config.endpoints.fetchState, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function fetchCity(request) {
  return axios
    .post(baseUrl + config.endpoints.fetchCity, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
//

function authUser(request) {
  return axios
    .get(baseUrl + config.endpoints.authUser, {
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${Auth.getAccessToken()}`,
      },
    })
    .then(async (response) => {
      if (response.data?.status == 200) {
      } else {
        alert(response.data.message);
      }
      return response;
    }).catch(err=>{
      if(err.response.status === 401 && err.response.data.message.includes("E_INVALID_API_TOKEN")){
        console.log("err",err)
        logout()
      }
      
    });
}
// Categories
function fetchAllCategory() {
  return axios
    .get(baseUrl + config.endpoints.categories, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function fetchAllServiceType(request) {
  return axios
    .post(baseUrl + config.endpoints.serviceType, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchAllServiceRequests(pages, search, filterSearch) {
  if(pages === undefined || search === undefined || filterSearch === undefined ){
    return axios
    .get(`${baseUrl}${config.endpoints.getallserviceRequests}`, {
      headers: authHeader(),
      })
      .then(async (response) => {
        return response;
      })
      .catch((err)=>{
        return err
      })
  }else{
    return axios
    .get(`${baseUrl}${config.endpoints.getallserviceRequests}?city_id=${filterSearch.city}&state_id=${filterSearch.state}&duration=${filterSearch?.duration}&request_id=${filterSearch?.requestId}&category_id=${search?.category}&sub_category_id=${search?.subCategory}&request_status=${filterSearch?.requestStatus}&submitted_date=${filterSearch?.submittedDate}&service_date=${filterSearch?.serviceDate}&country_id=${filterSearch?.country}&service_type_id=${search?.servicetype}&limit=${pages.limit}&page=${pages?.page} `, {
      headers: authHeader(),
      })
      .then(async (response) => {
        return response;
      })
      .catch((err)=>{
        return err
      })
  }
 
}

//
function fetchAllTimeSlot() {
  return axios
    .get(baseUrl + config.endpoints.timeSlots, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}
function logout() {
  // localStorage.clear();
  // sessionStorage.clear();
  //Connection.close()
  localStorage.removeItem("_tk")
  localStorage.removeItem("_cu")
  localStorage.removeItem("profileCompletePopUp")
  // localStorage.removeItem("_otp_tk")
  return <Redirect to="/login" />;
}
function _handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    data.statusText = response.statusText;
    if (!response.ok) {
      if (response.status === 401) {
        <Redirect to="/login" />;
      }
      return Promise.reject(data);
    }

    return data;
  });
}

function addOtherAddress(request) {
  return axios
    .post(baseUrl + config.endpoints.add_other_address, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchOtherAddress(request) {
  return axios
    .get(baseUrl + config.endpoints.fetch_other_address, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function createRequestQuote(request) {
  const form = new FormData();

  Object.keys(request).forEach((key) => {
    if (key === "images") {
      Object.keys(request.images).forEach((img) => {
        console.log(request.images[img]);
        form.append("images[]", request.images[img]);
      });
    } else {
      form.append(key, request[key]);
    }
  });

  return axios
    .post(baseUrl + config.endpoints.createRequestQuote, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function addBankAccount(request) {
  return axios
    .post(baseUrl + config.endpoints.addBankAccount, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchBankAccount() {
  return axios
    .get(baseUrl + config.endpoints.getBankAccounts, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchOnGoing(data) {
  return axios
    // .get(baseUrl + config.endpoints.getOnGoing, {
    .get(`${baseUrl}${config.endpoints.getOnGoing}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo1}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchActive(data) {
  return axios
    // .get(baseUrl + config.endpoints.getActive, {
    .get(`${baseUrl}${config.endpoints.getActive}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo2}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchClose(data) {
  return axios
    // .get(baseUrl + config.endpoints.getClose, {
    .get(`${baseUrl}${config.endpoints.getClose}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo3}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function updateProfileType(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  return axios
    .post(baseUrl + config.endpoints.changeProfileType, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function updateUser(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    if(request[key]){
      form.append(key, request[key]);
    }else{
      form.append(key, "");
    }
   
  });

  return axios
    .post(baseUrl + config.endpoints.updateUser, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function editRequest(request, id) {
  // const form = new FormData();
  // Object.keys(request).forEach((key) => {
  //   form.append(key, request[key]);
  // });

   const form = new FormData();
   Object.keys(request).forEach((key) => {
     if (key == "images") {
       request[key].forEach((image) => form.append("images[]", image));
     } else {
       if (request[key]) form.append(key, request[key]);
     }
   });
  
  return axios
    .put(baseUrl + config.endpoints.editRequest + id, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function cancelRequest(request, id, cancelRequestId) {
  // console.log("//////////////////////////",request, id);
  return axios
    .post(
      baseUrl +
        config.endpoints.cancelRequest ,
      {
        
          request_id: id,
          sr_cancellation_reason_id: cancelRequestId,
          sr_cancellation_comments: request,
      
      },
      {
        headers: authHeader(),
       
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function cancelRequestStatus(id) {
  return axios
    .get(
      baseUrl +
        config.endpoints.cancelRequestStatus+'/'+id ,
      {
        headers: authHeader(),
       
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function onMyWay(requestId) {
  return axios
    .post(
      baseUrl + config.endpoints.onMyWay + requestId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchQuoteData(requestId) {
  return axios
    .get(baseUrl + config.endpoints.getQuoteData + requestId, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchAcceptQuote(quoteId, payment_type, payment_id) {
  return axios
    .post(
      baseUrl + config.endpoints.acceptQuote,
      {
        quote_id:quoteId,
        payment_type,
        payment_id,
      },
      {
        headers: authHeader(),
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchRejectQuote(requestId) {
  return axios
    .post(
      baseUrl + config.endpoints.rejectQUote + requestId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function fetchAnotherVisit(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  return axios
    .post(baseUrl + config.endpoints.anotherVisit, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
    // .catch((err)=>{
    //   return err;
    // })
}

function addMarkCompletion(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  return axios
    .post(baseUrl + config.endpoints.markCompletion+"/"+request.request_id, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function acceptCompletion(request) {
  
  return axios
    .post(baseUrl + config.endpoints.acceptCompletion, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => { return err })
}

function closeWarrantyService(request, input) {
  return axios
    .post(
      baseUrl + config.endpoints.closeWarrantyService + request,
      input,
      {
        headers: authHeader(),
      }
    )
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function disputeReason() {
  return axios
    .get(baseUrl + config.endpoints.disputeReason , {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function addDisputeRequest(id,request) {
  return axios
    .post(baseUrl + config.endpoints.addDisputeRequest + id, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function addWarrantyRequest(request) {
  return axios
    .post(baseUrl + config.endpoints.addWarrantyRequest, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function postNoCustomerShow(request) {
  // const form = new FormData();
  // Object.keys(request).forEach((key) => {
  //   form.append(key, request[key]);
  // });

  return axios
    .post(baseUrl + config.endpoints.noCustomerShow, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function postNoSPShow(request) {
  // const form = new FormData();
  // Object.keys(request).forEach((key) => {
  //   form.append(key, request[key]);
  // });

  return axios
    .post(baseUrl + config.endpoints.postNoSPShow, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function addWithdrawnQuote(request) {
  return axios
    .post(baseUrl + config.endpoints.addWithdrawnQuote, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function withdawStatus(id) {
  return axios
    .get(baseUrl + config.endpoints.withdawStatus+id, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function confirmQoute(id) {
  return axios
    .post(baseUrl + config.endpoints.confirmQoute + id, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function updateQoute(request, id) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    if (Array.isArray(request[key])) {
      form.append(key, JSON.stringify(request[key]));
    } else {
      form.append(key, request[key]);
    }
  });
  return axios
    .put(baseUrl + config.endpoints.updateQoute + id, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function confirmAPIcall(quoteId, account_nick, data) {
  return axios
    .post(baseUrl + config.endpoints.confirmAPICall,
      { 
        quote_id:quoteId,
        // payment_id,
        ...data
      },
      {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
    // .catch(async (err)=>{
    //   return err
    // })
}

function postComment(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });
  return axios
    .post(baseUrl + config.endpoints.commentQuote, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function getFeedback() {
  return axios
    .get(baseUrl + config.endpoints.markCompletion, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}


function getAdditionalDetails() {
  return axios
    .get(baseUrl + config.endpoints.getAdditionalDetails, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function getUserCard() {
  return axios
    .get(baseUrl + config.endpoints.getUserCard, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function updateUserBank(request) {
  return axios
    .post(baseUrl + config.endpoints.updateUserBank, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function updateUserCard(request) {
  
  return axios
    .post(baseUrl + config.endpoints.updateUserCard, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function addUserCard(request) {
  
  return axios
    .post(baseUrl + config.endpoints.addCard, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function getSPFeedback(data) {
  return axios
    // .get(baseUrl + config.endpoints.getSPFeedback, {
      .get(`${baseUrl}${config.endpoints.getSPFeedback}?limit=${data.offset ? data.offset : ""}&page=${data?.limit ? data.limit : ""}`, {
    
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function getCustomerFeedback(data) {
  return axios
    .get(`${baseUrl}${config.endpoints.getCustomerFeedback}?limit=${data.offset ? data.offset : ""}&page=${data?.limit ? data.limit : ""}`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}


function fetchOnGoingSP(data) {
  return axios
    // .get(baseUrl + config.endpoints.getOnGoingSP, {
    .get(`${baseUrl}${config.endpoints.getOnGoingSP}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo1}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}


function fetchActiveSP(data) {
  return axios
    // .get(baseUrl + config.endpoints.getActiveSP, {
      .get(`${baseUrl}${config.endpoints.getActiveSP}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo2}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}


function fetchCloseSP(data) {
  return axios
    // .get(baseUrl + config.endpoints.getCloseSP, {
      .get(`${baseUrl}${config.endpoints.getCloseSP}?city_id=${data.city}&state_id=${data.state}&duration=${data?.duration}&request_id=${data?.filterRequestID}&category_id=${data?.cat}&sub_category_id=${data?.subCat}&request_status=${data?.filterStatus}&start_date=${data?.submittedDate}&end_date=${data?.serviceDate}&page=${data?.currentPageNo3}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function validateCoupon(coupon_code){
	
  return axios
    .post(baseUrl + config.endpoints.couponService+'/validate', {code:coupon_code},{
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function activateAccount(request) {
  return axios
    .post(baseUrl + config.endpoints.accountActivate, request, {
      headers: {'Accept':'application/json',authorization:`Bearer dc4f1dd289fa15289f060ba7a5924161GijhVjmptJM0+qjGwFeqzJtuWl0ws2sbRmSdsJxO1WBPyj9ldZ2q0GWvONQ9mG9F`},
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function forgetPassword(request){
  return axios
  .post(baseUrl + config.endpoints.forgotPassword, request, {
    headers: authHeader(),
  })
  .then(async (response) => {
    return response;
  }).catch(err=>err);
}

function otpLogin(request){
  return axios
  .post(baseUrl + config.endpoints.otpLogin, request, {
    headers: authHeader(),
  })
  .then(async (user) => {
    if (user.data?.status == 200) {
      localStorage.setItem("_otp_tk", user.data.data.token);
      Connection.connect();
    }
    return user;
  })
  .catch((err)=>{
    return err
  })
}

function updatePassword(request){
  return axios
  .post(baseUrl + config.endpoints.updatePassword, request, {
    headers: {'Accept':'application/json',authorization:`Bearer ${localStorage.getItem("_otp_tk")}`},
  })
  .then(async (response) => {
    return response;
  })
  .catch((err)=>{
    return err
  })
}

function contactUs(request){
  return axios
  .post(baseUrl + config.endpoints.contactUs, request, {
    headers: authHeader(),
  })
  .then(async (response) => {
    return response;
  })
  .catch((err)=>{
    return err
  })
}

/// profile page additional information get api 


function getUserdrivingLicense() {
  return axios
    .get(baseUrl + config.endpoints.getUserdrivingLicense, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => { })
}

function getProfileInformation() {
  return axios
    .get(baseUrl + config.endpoints.getProfileInformation, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}

function getBusinessLicense() {
  return axios
    .get(baseUrl + config.endpoints.getBusinessLicense, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}

function getCompanyInformation() {
  return axios
    .get(baseUrl + config.endpoints.getCompanyInformation, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}




// profile page additional information post api

function updateUserDrivingLicense(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  return axios
    .post(baseUrl + config.endpoints.updateUserDrivingLicense, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {
      return err
    });
}

function updateProfileInformation(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    if (Array.isArray(request[key])) {
      form.append(key, JSON.stringify(request[key]));
    } else {
      if(request[key]==0 ||  request[key])
      form.append(key, request[key]);
    }
  });

  return axios
    .post(baseUrl + config.endpoints.updateProfileInformation, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}

function updateBusinessLicense(request) {
  const form = new FormData();
  Object.keys(request).forEach((key) => {
    form.append(key, request[key]);
  });

  return axios
    .post(baseUrl + config.endpoints.updateBusinessLicense, form, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}

function updateComapnyInformation(request) {
  // const form = new FormData();
  // Object.keys(request).forEach((key) => {
  //   form.append(key, request[key]);
  // });

  return axios
    .post(baseUrl + config.endpoints.updateComapnyInformation, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch(async (err) => {});
}

function srCancelReason() {
  return axios
    .get(baseUrl + config.endpoints.srCancelReason, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{})
}

function srWithdrawReason() {
  return axios
    .get(baseUrl + config.endpoints.quoteWithdrawReason, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{})
}

//my transaction apis

function transactionList(filterSearch, pageNo) {
  return axios
    // .get(baseUrl + config.endpoints.transaction_list , {
    .get(`${baseUrl}${config.endpoints.transaction_list}?request_id=${filterSearch.requestId}&service_date_start=${filterSearch.serviceDateStart}&service_date_end=${filterSearch?.serviceDateEnd}&transaction_date_start=${filterSearch?.transactionDateStart}&transaction_date_end=${filterSearch?.transactionDateEnd}&amount_min=${filterSearch?.minPrice}&amount_max=${filterSearch?.maxPrice}&quote_id=${filterSearch?.quoteId}&category_id=${filterSearch?.cat}&page=${pageNo}&limit=10`, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
    // .catch(async (err) => {
    //   // return err;
    // });
}


function transactionCustomer(request_id,quote_id) {
  return axios
    .get(baseUrl + config.endpoints.transactionCust + request_id + "/" + quote_id, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function transactionSP(request_id,quote_id) {
  return axios
    .get(baseUrl + config.endpoints.transactionSP + request_id + "/" + quote_id, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function deleteRequestFile(id){
  return axios
  .delete(baseUrl + config.endpoints.deleteRequestFile + id, { headers: authHeader() })
  .then(async (response) => {
    return response;
  })
  .catch((err) => {});
}

//invoice

function transactionInvoice(id, quoteId) {
  return axios
    .get(baseUrl + config.endpoints.transactionInvoice + id + "/" + quoteId, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => err);
}

function transactionPayout(id) {
  return axios
    .get(baseUrl + config.endpoints.transactionPayout + id, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => err);
}


/// notification

function notifications() {
  return axios
    .get(baseUrl + config.endpoints.notifications, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function notificationsDelete(id) {
  // console.log("rrrrrrrrrrrrrrrrr", id)
  return axios
    .delete(baseUrl + config.endpoints.notifications + "/" + id.id, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}


/// find now api
function fetchSPList(request) {
  return axios
    .post(baseUrl + config.endpoints.fetchSPList, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => { })
}

function allCategories() {
  return axios
    .get(baseUrl + config.endpoints.allCategories, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function findNow(data, pageLimit, serviceSearch, filterSearch){
  return axios
  .get(`${baseUrl}${config.endpoints.findNow}?service=${data.service}&cityOrState=${data.cityState}&limit=${pageLimit?.limit}&page=${pageLimit?.page}&categoryId=${serviceSearch?.category}&subCategoryId=${serviceSearch?.subCategory}&serviceTypeId=${serviceSearch?.servicetype}&cityId=${filterSearch?.city}&stateId=${filterSearch?.state}&rating=${filterSearch?.rating}&zipCode=${filterSearch?.zipCode} `, {
    headers: authHeader(),
  })
  .then(async (response) => {
    return response;
  })
  .catch((err) => {});
}


//// tracking APIs 

function trackingLocations(requestId) {
  return axios
    .get(baseUrl + config.endpoints.trackingLocations + requestId, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function sendSpLocation(request) {
  return axios
    .post(baseUrl + config.endpoints.sendSpLocation, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => { })
}


///// service details screen


function spDetails(requestId) {
  return axios
    .get(baseUrl + config.endpoints.spDetails + requestId, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

function custDetails(requestId) {
  return axios
    .get(baseUrl + config.endpoints.custDetails + requestId, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
}

///reject quote apis

function srRejectReason() {
  return axios
    .get(baseUrl + config.endpoints.srRejectReason, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{})
}

function submitRejectQuote(quoteId,request) {
  return axios
    .post(baseUrl + config.endpoints.submitRejectQuote + quoteId, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

//////////token generation for stripe

function tokenLink() {
  return axios
    .get(baseUrl + config.endpoints.tokenLink , {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}

function sendTokenLink(request) {
  return axios
    .post(baseUrl + config.endpoints.sendTokenLink, request , {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}



/////////////////////////accept competion screen apis

function getAcceptCompletionData(id) {
  return axios
    .get(baseUrl + config.endpoints.getAcceptCompletionData + `/${id}`, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}



///////////feedback apis

function getUserFeedBacks() {
  return axios
    .get(baseUrl + config.endpoints.getUserFeedBacks, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}



function submitUserFeedBacks(request) {
  return axios
    .post(baseUrl + config.endpoints.submitUserFeedBacks, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      return err 
    })
}



/////////////////send lat and long

function sendLatLong(request) {
  
  return axios
    .put(baseUrl + config.endpoints.sendLatLong, request, {
      headers: authHeader(),
    })
    .then(async (response) => {
      return response;
    })
    .catch((err)=>{
      return err
    })
}


///////////people voice apis

function peopleVoice() {
  return axios
    .get(baseUrl + config.endpoints.peopleVoice, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}


 ///// images/banners URL
 function herography() {
  return axios
    .get(baseUrl + config.endpoints.herography, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}

function advBanner() {
  return axios
    .get(baseUrl + config.endpoints.advBanner, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}


function howItWorksVideo() {
  return axios
    .get(baseUrl + config.endpoints.howItWorksVideo, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}

function startEarningNowVideo() {
  return axios
    .get(baseUrl + config.endpoints.startEarningNowVideo, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}

function aboutUsVideo() {
  return axios
    .get(baseUrl + config.endpoints.aboutUsVideo, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}

function socialLinks() {
  return axios
    .get(baseUrl + config.endpoints.socialLinks, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
  .catch((err)=>{
    return err
  })
}






