import React, { useState, useReducer } from 'react';
import images from '../Utility/Images';
import Header from './Common/Header';
import { useHistory } from 'react-router-dom';

import { APIService } from './Apis/APIServices';
import Dialogue from './Common/Dialogue';
import formValidation from '../Pages/Registration/FormValidation/FormValidation';

const ResetPassword = (props) => {
    const [
        email,
        setEmail
    ] = useState(null)
    const history = useHistory();
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })

    const handleSendOtp = ()=>{
        console.log(email)
        if(!formValidation().Email(email)){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Email Id Not Valid'
            })
            return;
        }
        
        APIService.forgetPassword({email})
        .then(resp=>{
            console.log(resp)
            if(resp.status <400){
                history.push({
                    pathname: '/otp-sent',
                    state: { email }
                })
            }else{
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message : 'Email Id Not Found'
                })
            }
            
        }).catch(err=>{
            setDialogueState({
                type: "Failed",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
               message:(err && err.response && err.response.data && err.response.data.message) || null,
              });
        })
       
    }

    return (
        <>
            <Header />
            <section className="auth-wrapper auth-login-box resetPassword-box">
                <div className="auth-right-blue">
                    <img src={images.resetPasswordGraphic} className="img-fluid" alt="" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12">
                            <div className="auth-white-box auth-block">
                                <div className="m_header">
                                    <h2> Reset Your Password </h2>
                                    <p> Enter your email address associated with your eZunction account </p>
                                </div>
                                <div className="m_body">
                                    <div className="auth-form">
                                        <div className="form-group">
                                            <input type="email" placeholder="E-mail ID" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                                        </div>

                                        <div className="form-submit text-center">
                                            <button type="button" onClick={handleSendOtp} className="btn btn-md btn-red w-100"> Send </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialogue state={dialogueState} setState={setDialogueState}/>
            </section>
        </>
    );
}

export default ResetPassword;
