import React, { useContext, useEffect, useState } from 'react';
import images from '../../Utility/Images';
import RegistrationContext from './Context/RegistrationContext';
import FormValidation from './FormValidation/FormValidation';
import $ from 'jquery';
import { formatSizeUnits } from '../../Utility/Utility';
import { APIService } from '../../Components/Apis/APIServices';

const uploadObj={};

const AdditionalInformation = ({active}) => {
    // $(function () {
    //     $('.datepicker-calendar').datetimepicker({
    //         keepOpen: true,
    //         inline: true,
    //         format: 'L',
    //     });
    // });

    const [countries,setCountries] = useState([])
    const [location,setLocation] = useState('')
    const [states,setStates] = useState([])
    const [cities,setCities] = useState([])
    const [serviceCats,setServiceCats] = useState([])
    const [serviceSubCats,setServiceSubCats] = useState([])
    const [serviceSubCatsSelected,setServiceSubCatsSelected] = useState([])
    
    const [err,setErr] = useState({})

    const [serviceCat,setServiceCat]= useState("")
    const [serviceSubCat,setServiceSubCat] = useState('')
    const [country,setCountry]= useState("")
    const [cState,setCState] = useState('')
    const [city,setCity] = useState('')
    
    useEffect(()=>{
        APIService.fetchAllCategory().then(res=>setServiceCats(res.data.data)).catch(err=>setErr(err))
        APIService.fetchCountry().then(res=>setCountries(res.data.data)).catch(err=>setErr(err))
    },[])
    
    const [state, dispatch] = useContext(RegistrationContext);
    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.files) {
            uploadImg(e);
        } else {
            dispatch({ type: name, payload: { [name]: value, key: name } })
        }
        FormValidation(name,value)
    }
    
    const uploadImg = (e) => {
        let name = e.target.name
        if(e.target.files[0].size>5242880){
            alert("5 MB Max size of the file")
        }else{
            let imgObj={
                name:e.target.files[0].name,
                size:e.target.files[0].size
            }
            uploadObj[name] = imgObj;
            dispatch({ type: name, payload: { [name]: e.target.files[0], key: name } })
        }

        console.log(e.target.files[0]);

    }
    const handleServiceCat = ({target:{name,value}})=>{
        if(state.category.indexOf(value)==-1)
        dispatch({ type: 'category', payload: { 'category': [...state.category,value], key: 'category' } })
        setServiceCat(value)
        setServiceSubCatsSelected(prevState=>([...prevState,...(serviceCats.filter(sc=>sc.id == value)[0] ?serviceCats.filter(sc=>sc.id == value)[0].subcategories:[])]))
        setServiceSubCats(serviceCats.filter(sc=>sc.id == value)[0].subcategories)
        //APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
    }
    const handleLocation = ({target:{name,value}})=>{
        setLocation(value)
        if(state.location.indexOf(value)==-1)
        dispatch({ type: 'location', payload: { 'location': [...state.location,value], key: 'location' } })
    }
    const handleState = (e)=>{
        handleChange(e)
        setCState(e.target.value)
        APIService.fetchCity({state_id:e.target.value}).then(res=>setCities(res.data.data)).catch(err=>setErr(err))
    }
    const handleServiceSubCat = ({target:{name,value}})=>{
        setServiceSubCat(value)
        if(state.subcategory.indexOf(value)==-1)
        dispatch({ type: 'subcategory', payload: { 'subcategory': [...state.subcategory,value], key: 'subcategory' } })
    }
    const handleCountry = (e)=>{
        handleChange(e)
        setCountry(e.target.value)
        APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
    }
    const  handleRadio=(name,value)=>{
        dispatch({ type: name, payload: { [name]: value, key: name } })
    }
    return (
        <>
            <div className="reg-form-tab" style={active?{display:'flex'}:{display:'none'}}>
                <div className="auth-accordion w-100" id="additional-accordion">
                    <div className="card">
                        <div className="card-head" id="driverLicense-card">
                            <h3 className="mb-0" data-toggle="collapse" data-target="#driverLicense-collapse" aria-expanded="true" aria-controls="driverLicense-collapse"> Driver’s License </h3>
                        </div>

                        <div id="driverLicense-collapse" className="collapse show" aria-labelledby="driverLicense-card" data-parent="#additional-accordion">
                            <div className="auth-card-body">
                                <div className="form-group fg-place group-6 group-xs-12">
                                    <div className={`placeholder ${state.dl_id ? 'd-none' : 'd-block'}`}>
                                        <label for=""> Driver’s License ID </label>
                                        <span className="star text-danger">*</span>
                                    </div>
                                    <input type="text"  name="dl_id" className="holer-control form-control" onChange={handleChange} value={state.dl_id}/>
                                </div>

                                <div className="form-group fg-place group-6 group-xs-12">
                                    <div className={`placeholder ${state.dl_full_name ? 'd-none' : 'd-block'}`}>
                                        <label for=""> Full Name </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" className="holer-control form-control"  name="dl_full_name"  onChange={handleChange} value={state.dl_full_name} />
                                </div>

                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <div className={`placeholder ${state.dl_state ? 'd-none' : 'd-block'}`}>
                                        <label for=""> Issuing State </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" className="holer-control form-control" name="dl_state"  onChange={handleChange} value={state.dl_state} />
                                </div>

                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker3" data-target-input="nearest">
                                    {/* <div className={`placeholder ${state.dl_issue_date ? 'd-none' : 'd-block'}`}>
                                        <label for="date"> Issuing Date </label>
                                        <span className="star">*</span>
                                    </div> */}
                                    
                                    <input type="date"  className="form-control " placeholder="Issue Date" onChange={handleChange} name="dl_issue_date" value={state.dl_issue_date}/>
                                    <span >Issuing Date *</span>
                                </div>

                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker4" data-target-input="nearest">
                                    {/* <div className={`placeholder ${state.dl_expire_date ? 'd-none' : 'd-block'}`}>
                                        <label for="date"> Expire Date </label>
                                        <span className="star">*</span>
                                    </div> */}
                                    <input type="date"  className="form-control " placeholder="Expire Date" onChange={handleChange} name="dl_expiry_date" value={state.dl_expiry_date}/>
                                    <span >Expire Date *</span>
                                </div>

                                <div className="cus-fileUpload-box form-group group-12">
                                    <div className="cus-fileUpload-group">
                                        <input type="file" name="dl_image" className="cus-fileUpload-control d-none" onChange={handleChange} id="driverDL-input" />
                                        <label for="driverDL-input" className="mb-0 cus-fileUpload-label">
                                            <div className="profileUp-inner">
                                                <div className="upload-here">
                                                    <img src={images.imagesIcon} className="img-fluid" alt="" />
                                                </div>
                                                <div className="upload-content">
                                                    <h5> Upload DL Image <span className="asterisk">*</span></h5>
                                                    <span> {uploadObj['dl_image'] && uploadObj['dl_image'].name?uploadObj['dl_image'].name:'No file selected yet!'} </span>
                                                    <span>{uploadObj['dl_image'] && uploadObj['dl_image'].size?formatSizeUnits(uploadObj['dl_image'].size):0}  -  5 MB Max Size </span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-head" id="profileInfo-id">
                            <h3 className="mb-0 collapsed" data-toggle="collapse" data-target="#profileInfo-collapse" aria-expanded="false" aria-controls="profileInfo-collapse"> Profile Information </h3>
                        </div>

                        <div id="profileInfo-collapse" className="collapse" aria-labelledby="profileInfo-id" data-parent="#additional-accordion">
                            <div className="auth-card-body">
                                <div className="form-group group-6 group-xs-12">
                                    <select value={serviceCat} className="form-control" onChange={handleServiceCat}>
                                        {/* <option> Select all service categories that apply <span style={{ color: 'red' }}>*</span></option> */}
                                        <option> Select all service categories that apply *</option>
                                        {
                                            serviceCats.map(sc=><option value={sc.id}>{sc.category_name}</option>)
                                        }
                                    </select>
                                    <div className="form-group group-6 group-xs-12">
                                        <ul>
                                            {
                                                state.category.map(c=><li>{serviceCats.find(sc=>sc.id == c) && serviceCats.find(sc=>sc.id == c).category_name}</li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group group-6 group-xs-12">
                                    <select value={serviceSubCat} className="form-control" onChange={handleServiceSubCat}>
                                        {/* <option> Select all service sub-categories that apply  <span style={{ color: 'red' }}>*</span></option> */}
                                        <option> Select all service sub-categories that apply *</option>
                                        {
                                            serviceSubCats.map(sc=><option value={sc.id}>{sc.sub_category_name}</option>)
                                        }
                                    </select>
                                    <div className="form-group group-6 group-xs-12">
                                        <ul>
                                            {
                                                state.subcategory && state.subcategory.map(c=><li>{serviceSubCatsSelected.find(sc=>sc.id == c) && serviceSubCatsSelected.find(sc=>sc.id == c).sub_category_name}</li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group group-6 group-xs-12">
                                    <select value={state.year_experience} className="form-control" name="year_experience" onChange={handleChange}>
                                        <option> Years of experience </option>
                                        <option value={1}> 1 </option>
                                        <option value={2}> 2 </option>
                                        <option value={3}> 3 </option>
                                        <option value={4}> 4 </option>
                                        <option value={5}> 5+ </option>
                                    </select>
                                </div>
                                <div className="form-group group-6 group-xs-12">
                                    <select value={location} name="location" className="form-control" onChange={handleLocation}>
                                        {/* <option value=""><span>Country <span style={{color: 'red'}}>*</span></span></option> */}
                                        <option value=""> Select all service locations that apply </option>
                                        {
                                            countries.map(country=><option value={country.id}>{country.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group group-6 group-xs-12">
                                    <select value={state.warranty} className="form-control" name="warranty" onChange={handleChange}> 
                                        <option> Labor Warranty Provided (Days) </option>
                                        <option value={1}> 30 </option>
                                        <option value={2}> 45 </option>
                                        <option value={3}> 60 </option>
                                        <option value={4}> 100 </option>
                                    </select>
                                </div>
                                <div className="form-group group-12 primary-radio-group">
                                    <label> Can you provide professional phone consultation?ssssss </label>
                                    <div className="radio-block" >
                                        <div className="radio-group">
                                            <input type="radio" className="radio-input d-none" value={1} name="phone_consultation"  checked={state.phone_consultation === 1 ? true : false} id="ppc-yes" />
                                            <label className="radio-label-control" onClick={()=>handleRadio("phone_consultation",1)} for="ppc-yes"> Yes </label>
                                        </div>

                                        <div className="radio-group">
                                            <input type="radio" className="radio-input d-none" value={0} name="phone_consultation"  checked={state.phone_consultation === 0 ? true : false}  id="ppc-no" />
                                            <label className="radio-label-control" onClick={()=>handleRadio("phone_consultation",0)} for="ppc-no"> No </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group group-12 primary-radio-group">
                                    <label> Are you Bonded and Insured? </label>
                                    <div className="radio-block" name="bonded_insured" >
                                        <div className="radio-group">
                                            <input type="radio" value={1} checked={state.bonded_insured === 1 ? true : false} className="radio-input d-none" name="bonded_insured" id="bai-yes" />
                                            <label className="radio-label-control" onClick={(()=>handleRadio("bonded_insured",1))} for="bai-yes"> Yes </label>
                                        </div>
                                        <div className="radio-group">
                                            <input type="radio" value={0} checked={state.bonded_insured === 0 ? true : false} className="radio-input d-none" name="bonded_insured" id="bai-no" />
                                            <label className="radio-label-control" onClick={(()=>handleRadio("bonded_insured",0))} for="bai-no"> No </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <div className={`placeholder ${state.insurance_provider_name ? 'd-none' : 'd-block'}`} >
                                        <label for=""> Provider Name </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" name="insurance_provider_name" value={state.insurance_provider_name} onChange={handleChange}  className="holer-control form-control" />
                                </div>

                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker5" data-target-input="nearest">
                                    <input type="date"  className="form-control " placeholder="Expire Date" onChange={handleChange} name="insurance_expiry_date" value={state.insurance_expiry_date}/>
                                    <span >Expire Date *</span>
                                </div>

                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                <div className={`placeholder ${state.insurance_policy ? 'd-none' : 'd-block'}`} >
                                        <label for=""> Policy </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" name="insurance_policy" value={state.insurance_policy} onChange={handleChange}  className="holer-control form-control" />
                                </div>

                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <div className={`placeholder ${state.insured_name_address ? 'd-none' : 'd-block'}`} >
                                        <label for=""> Insured Name and Address </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" name="insured_name_address" value={state.insured_name_address} onChange={handleChange} className="holer-control form-control" />
                                </div>

                                <div className="form-group group-4 group-md-6 group-xs-12">
                                    {/* <select className="form-control"> */}
                                        {/* <option> Country <span style={{ color: 'red' }}>*</span></option> */}
                                        <select value={state.insured_country} name="insured_country" className="form-control" onChange={handleChange}>
                                            {/* <option value=""><span>Country <span style={{color: 'red'}}>*</span></span></option> */}
                                            <option >Country *</option>
                                            {
                                                countries.map(country=><option value={country.id}>{country.name}</option>)
                                            }
                                        </select>
                                    {/* </select> */}
                                </div>

                                <div className="form-group group-4 group-md-6 group-xs-12">
                                    <div className="phoneNumber-group">
                                        <div className="phoneNo-code-block">
                                            <select value={state.insured_country_code} className="form-control" name="insured_country_code" onChange={handleChange}>
                                                <option value={1}>+1</option>
                                                <option value={2}>+2</option>
                                                <option value={3}>+3</option>
                                                <option value={4}>+4</option>
                                                <option value={5}>+5</option>
                                            </select>
                                        </div>
                                        <div className="fg-place w-100">
                                            <div className={`placeholder ${state.insured_mobile_number ? 'd-none' : 'd-block'}`} >
                                                <label for=""> Mobile Number </label>
                                                <span className="star">*</span>
                                            </div>
                                            <input type="text" name="insured_mobile_number" value={state.insured_mobile_number} onChange={handleChange} className="mobileNumber-control holer-control form-control" maxlength="10" />
                                        </div>
                                    </div>
                                </div>

                                <div className="cus-fileUpload-box form-group group-12">
                                    <div className="cus-fileUpload-group">
                                        <input type="file" name="insurance_image" onChange={handleChange} className="cus-fileUpload-control d-none" id="insuranceImg-input" />
                                        <label for="insuranceImg-input" className="mb-0 cus-fileUpload-label">
                                            <div className="profileUp-inner">
                                                <div className="upload-here">
                                                    <img src={images.imagesIcon} className="img-fluid" alt="" />
                                                </div>
                                                <div className="upload-content">
                                                    <h5>Uploaded Insurance Image <span className="asterisk">*</span></h5>
                                                    <span> {uploadObj['insurance_image'] && uploadObj['insurance_image'].name?uploadObj['insurance_image'].name:'No file selected yet!'} </span>
                                                    <span>{uploadObj['insurance_image'] && uploadObj['insurance_image'].size?formatSizeUnits(uploadObj['insurance_image'].size):0}  -  5 MB Max Size </span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-head" id="businessLicense-id">
                            <h3 className="mb-0 collapsed" data-toggle="collapse" data-target="#busLicense-collapse" aria-expanded="false" aria-controls="busLicense-collapse"> Business License </h3>
                        </div>

                        <div id="busLicense-collapse" className="collapse" aria-labelledby="businessLicense-id" data-parent="#additional-accordion">
                            <div className="auth-card-body">
                                <div className="cus-fileUpload-box form-group group-12">
                                    <div className="cus-fileUpload-group">
                                        <input type="file" name="bl_image" onChange={handleChange} className="cus-fileUpload-control d-none" id="busLicImg-input" />
                                        <label for="busLicImg-input" className="mb-0 cus-fileUpload-label">
                                            <div className="profileUp-inner">
                                                <div className="upload-here">
                                                    <img src={images.imagesIcon} className="img-fluid" alt="" />
                                                </div>
                                                <div className="upload-content">
                                                    <h5>Uploaded Business License Image <span className="asterisk">*</span></h5>
                                                    <span> {uploadObj['bl_image'] && uploadObj['bl_image'].name?uploadObj['bl_image'].name:'No file selected yet!'} </span>
                                                    <span>{uploadObj['bl_image'] && uploadObj['bl_image'].size?formatSizeUnits(uploadObj['bl_image'].size):0}  -  5 MB Max Size </span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <div className={`placeholder ${state.bl_id ? 'd-none' : 'd-block'}`} >
                                        <label for=""> License</label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" value={state.bl_id} onChange={handleChange} name="bl_id" className="form-control" />
                                </div>

                                {/* <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker6" data-target-input="nearest">
                                    <input type="text" className="form-control datepicker datetimepicker-input" placeholder="Issue Date" data-target="#datepicker6" data-toggle="datetimepicker" />
                                </div>

                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker7" data-target-input="nearest">
                                    <input type="text" className="form-control datepicker datetimepicker-input" placeholder="Expiry Date" data-target="#datepicker7" data-toggle="datetimepicker" />
                                </div> */}
                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker3" data-target-input="nearest">
                                    {/* <div className={`placeholder ${state.dl_issue_date ? 'd-none' : 'd-block'}`}>
                                        <label for="date"> Issuing Date </label>
                                        <span className="star">*</span>
                                    </div> */}
                                    
                                    <input type="date"  className="form-control " placeholder="Issue Date" onChange={handleChange} name="bl_issue_date" value={state.bl_issue_date}/>
                                    <span >Issuing Date *</span>
                                </div>

                                <div className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar" id="datepicker4" data-target-input="nearest">
                                    {/* <div className={`placeholder ${state.dl_expire_date ? 'd-none' : 'd-block'}`}>
                                        <label for="date"> Expire Date </label>
                                        <span className="star">*</span>
                                    </div> */}
                                    <input type="date"  className="form-control " placeholder="Expire Date" onChange={handleChange} name="bl_expire_date" value={state.bl_expire_date}/>
                                    <span >Expire Date *</span>
                                </div>
                                
                                <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <div className={`placeholder ${state.bl_issue_authority ? 'd-none' : 'd-block'}`} >
                                        <label for=""> Issuing Authority </label>
                                        <span className="star">*</span>
                                    </div>
                                    <input type="text" value={state.bl_issue_authority}  name="bl_issue_authority" onChange={handleChange} className="form-control" />
                                </div>

                                <div className="form-group group-6 group-xs-12">
                                    <select className="form-control" value={state.bl_issue_state} onChange={handleChange} name="bl_issue_state">
                                        <option> Issuing State </option>
                                        <option value="1"> California </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-head" id="companyInfo-id">
                            <h3 className="mb-0 collapsed" data-toggle="collapse" data-target="#companyInfo-collapse" aria-expanded="false" aria-controls="companyInfo-collapse"> Company Information </h3>
                        </div>

                        <div id="companyInfo-collapse" className="collapse" aria-labelledby="companyInfo-id" data-parent="#additional-accordion">
                            <div className="auth-card-body">

                                <div className="form-group group-4 group-md-6 group-xs-12">
                                    <input type="text" name="company_name" value={state.company_name} onChange={handleChange} placeholder="Company Name" className="form-control" />
                                </div>
                                <div className="form-group group-4 group-md-6 group-xs-12">
                                    <input type="text" name="c_addressline_1" value={state.c_addressline_1} onChange={handleChange} placeholder="Address Line1" className="form-control" />
                                </div>
                                <div className="form-group group-4 group-md-6 group-xs-12">
                                    <input type="text" name="c_addressline_2" value={state.c_addressline_2} onChange={handleChange} placeholder="Address Line2" className="form-control" />
                                </div>

                                <div className="form-group group-3 group-md-6 group-xs-12">
                                    <select className="form-control" name="c_country" value={state.c_country} onChange={handleCountry} >
                                        <option> Country </option>
                                        {
                                            countries.map(country=><option value={country.id}>{country.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group group-3 group-md-6 group-xs-12">
                                    <select className="form-control" name="c_state" value={state.c_state} onChange={handleState}>
                                        <option> State </option>
                                        {
                                            states.map(state=><option value={state.id}>{state.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group group-3 group-md-6 group-xs-12">
                                    <select className="form-control" name="c_city" value={state.c_city} onChange={handleChange}>
                                        <option> City </option>
                                        {
                                            cities.map(city=><option value={city.id}>{city.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group fg-place group-6 group-xs-12">
                                    <div className={`placeholder ${state.c_emailid ? 'd-none' : 'd-block'}`} >
                                            <label for=""> E-mail ID </label>
                                            <span className="star">*</span>
                                    </div>
                                    <input type="text" name="c_emailid" onChange={handleChange} value={state.c_emailid} className="holer-control form-control" />
                                </div>

                                <div className="form-group group-6 group-xs-12">
                                    <div className="phoneNumber-group">
                                        <div className="phoneNo-code-block">
                                            <select className="form-control">
                                                <option>+1</option>
                                                <option>+2</option>
                                                <option>+3</option>
                                                <option>+4</option>
                                                <option>+5</option>
                                            </select>
                                        </div>

                                        <div className="fg-place w-100">
                                            <div className={`placeholder ${state.c_phone_number ? 'd-none' : 'd-block'}`} >
                                                <label for=""> Phone Number </label>
                                                <span className="star">*</span>
                                            </div>
                                            <input type="text" name="c_phone_number" onChange={handleChange} value={state.c_phone_number} className="mobileNumber-control holer-control form-control" maxlength="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group form-checkbox  mt-3">
                    <div className="custom-check-block">
                        <input type="checkbox" className="d-none" id="iagree" name="iagree" onChange={()=>handleRadio('iagree',state.iagree==1?0:1)}  checked={state.iagree ===1?true:false} />
                        <label className="custom-check-label" for="iagree"> I agree to the <a href="/term-conditions" target="_blank"> Terms and Conditions </a> and <a href="/privacy-policy" target="_blank"> Privacy Policy* </a> </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdditionalInformation;
