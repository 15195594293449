import React from 'react';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';
import Auth from "../Auth/Auth";

const Faq = () => {

    // const authData = Auth.getUserDetails();

    // console.log("vccccccccccccccvcccccc", authData.role_type)


    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> FAQs  </h1>
                                <p> What can we help you with? </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="getContent-section faq-section">
                <div className="wp-clip-bg">
                    <img src="images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="faq-title">
                                <h2> Frequently Asked Questions (FAQs) </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row faq-accordion-wrap" id="faq-accordion">
                        <div className="col-xl-6 col-12">
                            <div className="faqCard">
                                <div className="faq-card-header" id="faq1-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq1-collapse" aria-expanded="false" aria-controls="faq1-collapse">
                                            <span className="faqTab-title"> How can I edit my profile? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq1-collapse" className="collapse" aria-labelledby="faq1-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You can edit your profile by accessing My Dashboard -> My Profile -> Personal Information / Additional Information sections. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq2-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq2-collapse" aria-expanded="false" aria-controls="faq2-collapse">
                                            <span className="faqTab-title"> How can I add or update my payment method? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq2-collapse" className="collapse" aria-labelledby="faq2-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        
                                        {/* <p> You can add or update your payment method by accessing ‘My Account’ page following ‘Payment Information’ section. </p> */}
                                    
                                        <p>If you are a Customer:</p> 
<p>We ask your payment information when you a) Accept Quote and b) Accept Completion. You can select an existing payment method from the drop down if any, or add a new payment method by clicking on "Add New Card". </p>
<br />
<p>If you are a Service Provider:</p> 
<p>We ask your bank information when you a) Submit Quote  b) Confirm Quote and c) Mark Completion. You can select an existing payment method from the drop down if any or add a new payment method by clicking on "Add New Bank". </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq3-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq3-collapse" aria-expanded="false" aria-controls="faq3-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, how can I update my skills? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq3-collapse" className="collapse" aria-labelledby="faq3-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can update your skills by accessing ‘My Account’ page following ‘Addition Information’ section. </p> */}
                                        <p>You can update your skills/profile by accessing My Dashboard -> My Profile -> Additional Information --> Profile Information section</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq4-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq4-collapse" aria-expanded="false" aria-controls="faq4-collapse">
                                            <span className="faqTab-title"> Can I switch my accounts?</span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq4-collapse" className="collapse" aria-labelledby="faq4-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> Yes, you can switch your accounts by accessing ‘My Account’ page following ‘Switch Accounts’ section </p> */}
                                        <p>Yes, you can switch your accounts by accessing My Dashboard -> Change Profile Type section.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq5-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq5-collapse" aria-expanded="false" aria-controls="faq5-collapse">
                                            <span className="faqTab-title"> I am a Customer, where can I find my invoice? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq5-collapse" className="collapse" aria-labelledby="faq5-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can find your invoice in ‘My Requests’ tab by locating the particular service request. You may click on the ‘Invoice’ button. </p> */}
                                        {/* <p>Yes, you can switch your accounts by accessing My Dashboard -> Change Profile Type section.</p> */}
                                        <p>You can find your invoice in ‘My Dashboard’ --> 'My Transactions' tab by locating the particular transaction for your service request and clicking on the Invoice link/button on the transaction details section. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq7-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq7-collapse" aria-expanded="false" aria-controls="faq7-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, where can I find my payment advice? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq7-collapse" className="collapse" aria-labelledby="faq7-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> I am a Service Provider, where can I find my payment advice? </p> */}
                                        {/* <p>You can find your invoice in ‘My Dashboard’ --> 'My Transactions' tab by locating the particular transaction for your service request and clicking on the Payout advice link/button on the transaction details section.</p> */}
                                        <p>You can find your payout in ‘My Dashboard’ --> 'My Transactions' tab by locating the particular transaction for your service request and clicking on the Payout advice link/button on the transaction details section.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq8-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq8-collapse" aria-expanded="false" aria-controls="faq8-collapse">
                                            <span className="faqTab-title"> When can I communicate with Customer or Service Provider? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq8-collapse" className="collapse" aria-labelledby="faq8-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> Chat service is available when a service request received a quote. Service Provider who submitted a quote and Customer who submitted a service request can chat with each other. </p> */}
                                        <p>Service Provider who submitted a quote and Customer who submitted the service request can communicate with each other on the respective quote, using comments.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq9-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq9-collapse" aria-expanded="false" aria-controls="faq9-collapse">
                                            <span className="faqTab-title"> How can I create a new service request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq9-collapse" className="collapse" aria-labelledby="faq9-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You can create a new service request, as a Customer, by following the below simple and easy steps: </p>
                                        <ul>
                                            <li> Click on the ‘Create My Request’ button</li>
                                            <li> Provide the Service address where the work need to be performed. You can provide your home address from your profile or any other address where the work need to be done. </li>
                                            <li> Choose Service Category, Sub Category and Service Type</li>
                                            <li> Choose the date from calendar when you need the service to be done</li>
                                            <li> Choose the timeslot from drop down list that suits your availability </li>
                                            <li> Provide detail description around your request, your expectations and requirements to get the most accurate quote and any other needed details for your request</li>
                                            <li> Upload photos that describe your requirements which may be helpful for your service provider to provide most accurate quote on your request</li>
                                            <li> Click ‘Submit’ button</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq10-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq10-collapse" aria-expanded="false" aria-controls="faq10-collapse">
                                            <span className="faqTab-title"> As a Customer what actions can I do on my service requests? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq10-collapse" className="collapse" aria-labelledby="faq10-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> As a Customer you can do following actions on your service requests:  </p>
                                        <ul>
                                            <li> Create Service Request </li>
                                            <li>  Edit Service Request</li>
                                            <li> Accept Work Completion </li>
                                            <li> Cancel  Service Request</li>

                                            <li>Track Service Provider </li>
                                            <li>View Quotes</li>
                                            <li>Warranty Requests</li>
                                            <li>Dispute Request</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq6-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq6-collapse" aria-expanded="false" aria-controls="faq6-collapse">
                                            <span className="faqTab-title">As a Service Provider what actions can I do on my service requests? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq6-collapse" className="collapse" aria-labelledby="faq6-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> As a Service Provider you can do following actions on the service requests:  </p>
                                        <ul>
                                            <li> Submit Quote </li>
                                            <li> View My Quote </li>
                                            <li> Mark ‘On My Way’ </li>

                                            <li> Mark as ‘Completed’ </li>
                                            <li> Mark as ‘Customer No Show’ </li>
                                            <li> Another Visit </li>
                                            <li> Close Warranty </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq11-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq11-collapse" aria-expanded="false" aria-controls="faq11-collapse">
                                            <span className="faqTab-title"> As a Customer what actions can I do on the received quotes? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq11-collapse" className="collapse" aria-labelledby="faq11-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> As a Customer you can do following actions on the received quotes for your service requests: </p>
                                        <ul>
                                            <li> Accept or Reject Quote </li>
                                            <li> Provide your comments </li>
                                            <li>Mark as ‘Service Provider No Show’  </li>
                                            {/* <li> Provide your comments  </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq12-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq12-collapse" aria-expanded="false" aria-controls="faq12-collapse">
                                            <span className="faqTab-title"> As a Service Provider what actions can I do on my quotes? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq12-collapse" className="collapse" aria-labelledby="faq12-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p>As a Service Provider you can do following actions on your quotes: </p>
                                        <ul>
                                            <li> Submit quote </li>
                                            <li> Update quote </li>
                                            <li> Confirm quote </li>
                                            <li> Withdraw quote </li>
                                            <li> Provide your comments  </li>
                                            {/* <li> Accept and Confirm quote </li> */}
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq13-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq13-collapse" aria-expanded="false" aria-controls="faq13-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, what will happen if I do not take any action on the confirmed service request, past the scheduled date and time slot? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq13-collapse" className="collapse" aria-labelledby="faq13-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You may be charged with the applicable past due charges. You may exchange comments with the customer and reschedule as agreed. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq14-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq14-collapse" aria-expanded="false" aria-controls="faq14-collapse">
                                            <span className="faqTab-title"> I am a Customer, what will happen if I do not take any action on the confirmed service request, past the scheduled date and time slot? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq14-collapse" className="collapse" aria-labelledby="faq14-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You may be charged with the applicable past due charges. You may exchange comments with your service provider and reschedule as agreed. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq15-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq15-collapse" aria-expanded="false" aria-controls="faq15-collapse">
                                            <span className="faqTab-title"> As a service provider, what do I need to do once I complete the work?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq15-collapse" className="collapse" aria-labelledby="faq15-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You shall mark the Service Request as ‘Completed’, once you complete the work as scheduled.  </p>
                                        {/* <p> While marking the service request as ‘Completed’, you may provide the Warranty on the labor (if any), provide ratings and feedback to Customer.  </p>
                                        <p> You shall request the Customer to accept completion of the work and request for ratings and feedback for you. </p> */}
                                        <p>While marking the service request as ‘Completed’, you may add the additional labor, additional material costs and additional comments along with providing ratings and feedback to Customer.</p>
                                        <p>You shall request the Customer to accept completion of the work and request for ratings and feedback for you.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq16-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq16-collapse" aria-expanded="false" aria-controls="faq16-collapse">
                                            <span className="faqTab-title"> What will happen if Customer does not accept the work completion after the work is completed? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq16-collapse" className="collapse" aria-labelledby="faq16-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> Customer will receive 2 notification request to accept the completion, if Customer still does not accept, Customer will be still charged with full invoice amount </p> */}
                                        <p>Customer will receive appropriate notifications request to accept the completion, if Customer still does not accept, Customer will be still charged with full invoice amount and you will be paid accordingly as per the work completed.</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="faqCard">
                                <div className="faq-card-header" id="faq17-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq17-collapse" aria-expanded="false" aria-controls="faq17-collapse">
                                            <span className="faqTab-title"> I am a Service provider, what will happen if Customer does not accept the work completion after the work is completed? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq17-collapse" className="collapse" aria-labelledby="faq17-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You will receive 2 notification reminders to work with customer and for ‘Accept Completion’, If Customer still does not accept the completion and does not submit any dispute request within 2 days of work completion, you will get the payment after 2 days.  </p>
                                        <p> But if Customer submits the dispute request, your payment will be on hold by eZunction until the dispute is resolved.  </p>
                                    </div>
                                </div>
                            </div> 

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq18-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq18-collapse" aria-expanded="false" aria-controls="faq18-collapse">
                                            <span className="faqTab-title"> I am a Customer, what will happen if I do not accept the work completion after the work is completed? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq18-collapse" className="collapse" aria-labelledby="faq18-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p>You will receive 2 notification reminders to accept the completion, if you still do not accept the completion, you will be still charged with the full invoice amount as the work is completed. </p>
                                        <p> You may submit a dispute request within 2 days of work completion if you are not satisfied or have issues with the completed work </p>
                                    </div>
                                </div>
                            </div> 



                            <div className="faqCard">
                                <div className="faq-card-header" id="faq20-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq20-collapse" aria-expanded="false" aria-controls="faq20-collapse">
                                            <span className="faqTab-title"> I am a Customer, can I cancel my service request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq20-collapse" className="collapse" aria-labelledby="faq20-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. Click here to read more on service request cancellation  </p>
                                    </div>
                                </div>
                            </div> */}

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq21-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq21-collapse" aria-expanded="false" aria-controls="faq21-collapse">
                                            <span className="faqTab-title"> I am a Customer, do I need to accept work completion?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq21-collapse" className="collapse" aria-labelledby="faq21-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. As a customer, you must accept work completion of your service request, when the service provider completes the work. You can provide tip amount to the service provider. Your provided tip amount goes to your service provider directly.  </p>
                                        <p> You can also provide the review, comments and ratings for the service provider while accepting the work completion. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq22-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq22-collapse" aria-expanded="false" aria-controls="faq22-collapse">
                                            <span className="faqTab-title"> I am a Customer, How can I edit/update my service Request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq22-collapse" className="collapse" aria-labelledby="faq22-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> As a customer, once you have submitted your service request and have received quotes from one or more service providers, it is still possible to make changes to your service requests by following some simple and easy steps:   </p>

                                        <ul>
                                            <li> Locate your service request in the ‘My Requests’ tab, click on the ‘Edit Request’ button </li>
                                            <li> Fill up the available editable information related to your service request and click save.  (The professionals who submitted the quote, will be notified of the changes.) </li>
                                            <li> The professionals will review the changes to see if any changes are needed to be made to the quote. If yes, they will revise the quote for you to review and accept</li>
                                            <li> Once you accept, the service provider will confirm and your work gets scheduled and completed as per your revised request. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq23-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq23-collapse" aria-expanded="false" aria-controls="faq23-collapse">
                                            <span className="faqTab-title"> I am a Customer, how can I cancel my service request?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq23-collapse" className="collapse" aria-labelledby="faq23-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> As a customer, once you have submitted your service requests, you may cancel it before the request is ‘Confirmed’, by following some simple and easy steps:   </p>
                                        <ul>
                                            <li> Locate your service request in the ‘My Requests’ tab, click on ‘Cancel Request’ button </li>
                                            <li> Choose the suitable reason for cancellation from the drop down list. This is a mandatory detail. Click on ‘Confirm Cancellation’ button.  </li>
                                            <li> You can cancel your service request any time before it is in ‘Confirmed’ status, without any cancellation charges.  </li>
                                            <li> If your service request is in Completed, Closed, Past Due or Pending status, you will not be able to cancel or make any changes to your service request.  </li>
                                            <li> All service requests that are in ‘Confirmed’ status, can be cancelled before 48 hours from the start time of the scheduled time slot. Cancellation charges will be applied.  </li>
                                            <li> The cancellation window will be closed for all service requests that are in ‘Confirmed’ status, with less than 48 hours remaining from the start time of the scheduled time slot. </li>
                                            <li> You cannot reject the confirmed quote. </li>
                                            <li> You may update your service request details before it is in ‘Confirmed’ status. After your service request is confirmed you cannot make changes to service request except cancellation. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq19-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq19-collapse" aria-expanded="false" aria-controls="faq19-collapse">
                                            <span className="faqTab-title"> What shall I do if there is an issue or I am not satisfied with the work completed by service provider? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq19-collapse" className="collapse" aria-labelledby="faq19-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You may check on the service request completion notes, if your service provider has provided any warranty and it’s not expired, you may submit the warranty request from ‘My Requests’ tab. You may check the service request and if it is not more than 2 days after completion of your service request you may submit a dispute. </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-12">

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq29-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq29-collapse" aria-expanded="false" aria-controls="faq29-collapse">
                                            <span className="faqTab-title"> I am a Customer, can I reject a confirmed quote? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq29-collapse" className="collapse" aria-labelledby="faq29-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> No. You cannot reject a confirmed quote. You may exchange comments with your service provider to reschedule, if needed. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq30-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq30-collapse" aria-expanded="false" aria-controls="faq30-collapse">
                                            <span className="faqTab-title"> I am a Customer, can I accept or reject a quote? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq30-collapse" className="collapse" aria-labelledby="faq30-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. You can accept or reject as quote before your service request is confirmed. One service request can have only one confirmed quote at a time. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq31-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq31-collapse" aria-expanded="false" aria-controls="faq31-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, how can I submit a Quote? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq31-collapse" className="collapse" aria-labelledby="faq31-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You can submit a quote following below steps: </p>
                                        <ul>
                                            <li> Select the service request you want to submit a quote for </li>
                                            <li> Click on ‘Submit Quote’ </li>
                                            <li> Fill out the information required in the form. Provide the cost of the labor and the material separately as indicated on the form and click on submit </li>
                                            <li> Click on ‘Submit’ </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq32-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq32-collapse" aria-expanded="false" aria-controls="faq32-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, can I update my Quote? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq32-collapse" className="collapse" aria-labelledby="faq32-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. Once you have submitted your quote for the service request, you can update it until the service request is completed </p>
                                        <ul>
                                            <li> Locate your quote in the ‘My Requests’ tab  </li>
                                            <li> Click on ‘Update’ </li>
                                            <li> Make the necessary updates such as amount, service date and time slot, services included, photos and add any comments and click on ‘Save and Submit’ </li>
                                            <li> You can reschedule the Service request by updating the service date and time slot </li>
                                            <li> You can also update the quote amount. Note: you cannot reduce the quote amount, only increment is allowed.  </li>
                                            <li> You can provide comments for the Customer for specific service request that you have submitted the quote for </li>
                                            <li> You cannot update your confirmed quote if the Service Request is ‘Completed’ </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq33-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq33-collapse" aria-expanded="false" aria-controls="faq33-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, do I need to accept or confirm my quote?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq33-collapse" className="collapse" aria-labelledby="faq33-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. Locate your quote in the ‘My requests’ tab. You can review customer’s comments in your quote and accept and confirm the quote if you agree.  </p>
                                        {/* <p> Once you accept and confirm the quote the service request will be scheduled and confirmed.If you want to make any changes you can always chat with customer and update as agreed. </p> */}
                                        <p>Once you accept and confirm the quote the service request will be scheduled and confirmed. You cannot udpate  confirmed quote. However, you can still exhange comments with customer and mutually agree. You may also add additional labor and material cost at the time of marking the service request as complete. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq34-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq34-collapse" aria-expanded="false" aria-controls="faq34-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, can I withdraw my Quote? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq34-collapse" className="collapse" aria-labelledby="faq34-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. Once your have submitted your quote you can withdraw it before the service request is Completed by providing suitable withdrawal reason from the drop down list. This is mandatory detail. Click on ‘Confirm’ button. </p>
                                        <ul>
                                            <li> Locate your quote in the ‘My requests’ Tab.  </li>
                                            <li> You can withdraw your submitted quote any time before it is confirmed status, without any withdrawal charges. </li>
                                            <li> You can withdraw your confirmed quote  </li>
                                            <li> Once you accept and confirm the quote the service request will be scheduled and confirmed  </li>
                                            <li> If the service request is in ‘Confirmed’ status for your quote, you can still withdraw the quote before 48 hours from the start time of the scheduled time slot. Withdrawal charges will be applied. </li>
                                            <li> The withdrawal window will be closed for ‘Confirmed’ quotes, with less than 48 hours remaining from the start time of the scheduled time slot. </li>
                                            <li> Your confirmed quote cannot be rejected by customer </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq35-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq35-collapse" aria-expanded="false" aria-controls="faq35-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, can I edit service request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq35-collapse" className="collapse" aria-labelledby="faq35-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> No, you cannot edit service request. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq36-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq36-collapse" aria-expanded="false" aria-controls="faq36-collapse">
                                            <span className="faqTab-title"> How can I reset my password? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq36-collapse" className="collapse" aria-labelledby="faq36-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can reset your password by clicking on the ‘Forgot Password’ link in the sign in page or you can use ‘My Account’ tab for resetting the password.  </p> */}
                                        <p>You can reset your password by clicking on the ‘Forgot Password’ link in the sign in page or you can navigate to 'My Dashboard' --> ‘My Profile' --> "Personal Information' section  for resetting the password.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq37-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq37-collapse" aria-expanded="false" aria-controls="faq37-collapse">
                                            <span className="faqTab-title"> How can I update my Profile? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq37-collapse" className="collapse" aria-labelledby="faq37-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can update your profile on ‘My Account’ tab by clicking on the edit button for each section as applicable. </p> */}
                                        <p>You can update your profile on the 'My Dashboard' --> ‘My Profile' --> "Personal Information' / 'Additional Information' sections.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq38-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq38-collapse" aria-expanded="false" aria-controls="faq38-collapse">
                                            <span className="faqTab-title"> Can I submit multiple quotes for one service request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq38-collapse" className="collapse" aria-labelledby="faq38-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> No </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq39-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq39-collapse" aria-expanded="false" aria-controls="faq39-collapse">
                                            <span className="faqTab-title"> Can I submit quotes for multiple service request? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq39-collapse" className="collapse" aria-labelledby="faq39-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq40-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq40-collapse" aria-expanded="false" aria-controls="faq40-collapse">
                                            <span className="faqTab-title"> How can I register myself as a Service Provider? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq40-collapse" className="collapse" aria-labelledby="faq40-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can register yourself ‘As a Service Provider’ with eZunction by following 3 steps below: </p> */}
                                        <p>You can register yourself as a Service Provider with eZunction by following steps:</p>
                                        <ul>
                                            {/* <li> Click on ‘Register’ button in the ‘Sign In’ page   </li>
                                            <li> Select the ‘Service Provider’ user type </li>
                                            <li> Fill out Step 01 ‘Personal Information’ section in the form </li>
                                            <li> Provide your name, address, phone #, email,  and password for your eZunction account </li>
                                            <li> Upload your profile picture </li>
                                            <li> Click on ‘Continue’ </li>
                                            <li> Fill out the Step 02 ‘Payment Information’ section in the form. </li>
                                            <li> Provide Credit/Debit card or Bank Account information </li>
                                            <li> Click ‘Continue’. </li>
                                            <li> Fill out the step 03 ‘Additional Information’ section in the form </li>
                                            <li> Provide the details for Driver’s License, Business license, Company and other information sections  </li>
                                            <li> Click ‘Submit’ </li> */}

                                            <li> Click on ‘Register’ button in the ‘Sign In’ page or on the home page.   </li>
                                            <li> Select the ‘Service Provider’ user type </li>
                                            <li> Provide your name, address, phone #, email, and password for your eZunction account along with the other required details, </li>
                                            {/* <li> Provide your name, address, phone #, email,  and password for your eZunction account </li> */}
                                            <li> Upload your profile picture </li>
                                            {/* <li> Click on ‘Continue’ </li>
                                            <li> Fill out the Step 02 ‘Payment Information’ section in the form. </li>
                                            <li> Provide Credit/Debit card or Bank Account information </li>
                                            <li> Click ‘Continue’. </li>
                                            <li> Fill out the step 03 ‘Additional Information’ section in the form </li>
                                            <li> Provide the details for Driver’s License, Business license, Company and other information sections  </li> */}
                                            <li> Click ‘Submit’ </li>
                                        </ul>
                                        <p>You will receive an activation link to your registered email. Go to your email and activate the account.</p><br />
                                        <p>You will have to complete your profile on the  'My Dashboard' --> ‘My Profile' --> 'Additional Information' sections before you can submit your quotes on the available service requests.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq24-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq24-collapse" aria-expanded="false" aria-controls="faq24-collapse">
                                            <span className="faqTab-title"> What shall I do if Service Provider does not show up at the service address as scheduled? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq24-collapse" className="collapse" aria-labelledby="faq24-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can chat with your service provider and try to reschedule if agreed, if still does not work you can mark the Confirmed quote as ‘Service Provider No Show’. When you do so the quote will be automatically disabled and you can review and confirm the other quote if you have received other quotes from other service providers.  If you don’t have any other quotes you may receive a new quote as your service request will be broadcasted again</p> */}
                                        <p>You can exchange comments on the quote of your service provider and to understand the reason for delay. If your service provider still does not agree to come, you can mark the quote as ‘Service Provider No Show’. When you do so the respective quote will be automatically disabled and you can review and confirm the other quote(s) that you may have received from other service providers. If you don’t have any other quotes you may still receive a new quote as your service request will be broadcasted again.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq25-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq25-collapse" aria-expanded="false" aria-controls="faq25-collapse">
                                            <span className="faqTab-title"> What shall I do if Customer does not show up or let me in to complete the work at the service address as scheduled?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq25-collapse" className="collapse" aria-labelledby="faq25-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can chat with the customer and try to reschedule if agreed, if it still does not work you can mark the confirmed service request as ‘Customer No Show’. The service request will be disabled and marked as ‘Customer No Show’. ‘No Show’ charges may apply to customer.  </p> */}
                                        <p>You can exchange comments on the quote to interact with the customer. If it still does not work you can mark the confirmed service request as ‘Customer No Show’. The service request will be disabled and marked as ‘Customer No Show’. ‘No Show’ charges may apply to customer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq41-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq41-collapse" aria-expanded="false" aria-controls="faq41-collapse">
                                            <span className="faqTab-title"> How can I register myself as a Customer?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq41-collapse" className="collapse" aria-labelledby="faq41-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        {/* <p> You can register yourself ‘As a Customer’ with eZunction by following 2 steps below: </p> */}
                                        <p>You can register yourself as a Customer with eZunction by following steps:</p>
                                        <ul>
                                            <li> Click on Register button in the ‘Sign In’ page   </li>
                                            <li> Select the ‘Customer’ user type </li>
                                            {/* <li> Fill out Step 01 ‘Personal Information’ section in the form </li> */}
                                            <li> Provide your name, address, phone #, email, and password for your eZunction account along with the other required details. </li>
                                            <li> Upload your profile picture </li>
                                            {/* <li> Click on ‘Continue’ </li>
                                            <li> Fill out the Step 02 ‘Payment Information’ section in the form. </li>
                                            <li> Provide Credit/Debit card or Bank Account information </li> */}
                                            <li> Click ‘Submit’. </li>
                                        </ul><br />
                                        <p>You will receive an activation link to your registered email. Go to your email and activate the account.</p><br />
                                        <p>You are now ready to submit your service requests.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq26-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq26-collapse" aria-expanded="false" aria-controls="faq26-collapse">
                                            <span className="faqTab-title"> I am a Customer, what shall I do when Service Provider marked my service request as “Customer No Show’?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq26-collapse" className="collapse" aria-labelledby="faq26-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Your service request will be disabled and will be charged with ‘Customer No Show’ charge as applicable. You can create a new service request. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq27-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq27-collapse" aria-expanded="false" aria-controls="faq27-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, when and why shall I mark ‘On My Way’ for a service request?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq27-collapse" className="collapse" aria-labelledby="faq27-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You shall mark ‘On My Way’ for a service request when you start for that service address. System will calculate the distance from your current location to service address location and send your customer the ETA notification that you are on your way. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq28-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq28-collapse" aria-expanded="false" aria-controls="faq28-collapse">
                                            <span className="faqTab-title"> I am a Service Provider, what shall I do when Customer marked my quote as “Service Provider No Show’?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq28-collapse" className="collapse" aria-labelledby="faq28-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Your quote will be disabled and your will be charged with ‘Service Provider No Show’ charge as applicable. You can submit a new quote. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq42-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq42-collapse" aria-expanded="false" aria-controls="faq42-collapse">
                                            <span className="faqTab-title"> How can I register myself both as Customer and Service Provider? </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq42-collapse" className="collapse" aria-labelledby="faq42-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> You can register yourself ‘As a Service Provider and Customer Both’ with eZunction by following the steps below: </p>
                                        <ul>
                                            <li> Click on Register button in the ‘Sign In’ page or on the home page.  </li>
                                            <li> Select the ‘Both’ user type </li>
                                            <li> Fill out Step ‘Personal Information’ section in the form. </li>
                                            <li> Provide your full name, address, phone #, email, and password for your eZunction account </li>
                                            <li> Upload your profile picture </li>
                                            {/* <li> Click on ‘Continue’ </li>
                                            <li> Fill out the Step 02 ‘Payment Information’ section in the form. </li>
                                            <li> Provide Credit/Debit card or Bank Account information </li>
                                            <li> Click ‘Continue’. </li>
                                            <li> Fill out the step 03 ‘Additional Information’ section in the form </li>
                                            <li> Provide the details for Driver’s License, Business license, Company and other information sections  </li> */}
                                            <li> Click ‘Submit’ </li>
                                        </ul><br />
                                        <p>You will receive an activation link to your registered email. Go to your email and activate the account.</p><br />
                                        <p>You are now ready to submit your service requests.</p><br />
                                        <p>You can also submit a quote on other service requests. However, you need to complete your profile at "My Dashboard" -> My Profile" -> ‘Additional Information’ section and save the needed details for Driver’s License, Profile Information, Business license and Company information sections.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq43-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq43-collapse" aria-expanded="false" aria-controls="faq43-collapse">
                                            <span className="faqTab-title"> Can I provide a Tip amount to my service provider?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq43-collapse" className="collapse" aria-labelledby="faq43-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. You can provide tip amount to your service provider while accepting the work completion. Your provided tip amount goes to your service provider directly. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq44-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq44-collapse" aria-expanded="false" aria-controls="faq44-collapse">
                                            <span className="faqTab-title"> Can I provide feedback to my service provider?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq44-collapse" className="collapse" aria-labelledby="faq44-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. When you accept the completion of work, you can provide feedback to your service provider. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="faqCard">
                                <div className="faq-card-header" id="faq45-tab">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#faq45-collapse" aria-expanded="false" aria-controls="faq45-collapse">
                                            <span className="faqTab-title"> Can I provide feedback to my Customer?  </span>
                                            <span className="accordion-upDown-icon">
                                                <i className="fal fa-chevron-down"></i>
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div id="faq45-collapse" className="collapse" aria-labelledby="faq45-tab" data-parent="#faq-accordion">
                                    <div className="faq-card-body">
                                        <p> Yes. When you mark the work completion, you can provide feedback to your customer.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default Faq;
