import React, { useState, useRef } from 'react';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import images from '../Utility/Images';
import MyAccountMenu from '../Components/Common/MyAccountMenu';
import Auth from '../Auth/Auth';
import { APIService } from '../Components/Apis/APIServices';

import Dialogue from "../Components/Common/Dialogue";
import GetInTouch from '../Components/Common/GetInTouch';

const ChangeProfileType = () => {

    const userDetail = Auth.getUserDetails();
    // const customerRef = useRef();
    // const bothRef = useRef();

    const [customerRef, setCustomerRef] = useState(0)
    const [bothRef, setBothRef] = useState(0)

    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
      })

    const handleSubmit = (e) => {
        e.preventDefault();

        let roleId;
        if(bothRef) {
            if(userDetail.role_type === 3){
                roleId = 2
            }else{
                roleId = 3
            }
            
        }else if(customerRef) {
            if(userDetail.role_type === 2 || userDetail.role_type === 3){
                roleId = 1
            }else{
                roleId = 2
            }
          
        }

        

        if (roleId) {
            APIService.updateProfileType({role_type:roleId}).then(async (res) => {
                if (res.status == 200) {
                    // alert("Profile Type has been updated.");    
                   
                      APIService.authUser()
                      .then((response)=>{
                        // console.log("reponse is rrrrrrrr", response.data.data[0])
                        localStorage.setItem("_cu", JSON.stringify(response.data.data && response.data.data[0]));
                        // window.location.reload()
                        setDialogueState({
                            type : 'Success',
                            show:true,
                            message : res.data.message,
                            refresh: true
                            // link : 'my-request'
                          })
                      })
                      .catch(()=>{

                      })

                }else{
                    setDialogueState({
                        type: "Failed",
                        show: true,
                        message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
                        });
                }
            }).catch((error) => {
                console.log("asdasdeqw", error)
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message : (error && error.response && error.response.data && error.response.data.message) || "",
                    // link : 'my-request'
                  })        
            })       
        }else {
            setDialogueState({
                type : 'Error',
                show:true,
                message : "Please select any profile type to change",
                // link : 'my-request'
              })    
            
        }
    }

    const changeTogglerValue = (e) => { 
        userDetail[e.target.name] = e.target.value
    }


    return (
        <>
            <Header />
            <section className="gray-hero-section db-gh-section">
                <div className="gh-clip-bg">
                    <img src={images.clipBg} className="img-fluid d-block m-auto" alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1 className="mb-0"> My <span> Profile </span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="db-top-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <MyAccountMenu/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="createMyRequest-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <form onSubmit={(e)=>handleSubmit(e)}>
                            <div className="cmr-form-block mt-0">
                                <div className="form-group group-12">
                                    <label> Current Profile Type </label>
                                    <div className="radio-block">
                                        <div className="radio-group">
                                            <input 
                                                type="radio" 
                                                className="radio-input d-none" 
                                                name="user-type" 
                                                id="service-provider" 
                                                checked={userDetail.role_type === 2 ? true : false}
                                                disabled 
                                                />
                                            <label className="radio-label-control" for="service-provider"> Service Provider </label>
                                        </div>

                                        <div className="radio-group">
                                            <input 
                                                type="radio" 
                                                className="radio-input d-none" 
                                                name="user-type" 
                                                id="customer" 
                                                checked={userDetail.role_type === 1 ? true : false}
                                                disabled 
                                                />
                                            <label className="radio-label-control" for="customer"> Customer </label>
                                        </div>

                                        <div className="radio-group">
                                            <input 
                                                type="radio" 
                                                className="radio-input d-none" 
                                                name="user-type" 
                                                id="both" 
                                                disabled 
                                                checked={userDetail.role_type === 3 ? true : false}
                                                />
                                            <label className="radio-label-control" for="both"> Both </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group group-12">
                                    <label> Change Profile Type </label>
                                    <div className="changeProfile-type-group">
                                        <div className="cptg-item">
                                            <span className="cptg-title"> 
                                                {userDetail.role_type === 1 ? "Service Provider" : "Customer"}
                                            </span>
                                            <label className="e-switch">
                                                <input 
                                                // ref={customerRef} 
                                                type="checkbox" 
                                                // value={userDetail.role_type===1 ? 2 : 1} 
                                                checked={customerRef}
                                                name="customerRef"
                                                onChange={(e)=>setCustomerRef(customerRef === 0 ? 1 : 0)}
                                                disabled={bothRef === 1 ? true : false}
                                                />
                                                <span className="e-slider round"></span>
                                            </label>
                                        </div>
                                        <div className="cptg-item">
                                            <span className="cptg-title"> {userDetail.role_type === 3 ? "Service Provider":"Both"} </span>
                                            <label className="e-switch">
                                                <input 
                                                // ref={bothRef} 
                                                type="checkbox" checked={bothRef} 
                                                name="bothRef"
                                                onChange={(e)=>setBothRef(bothRef === 0 ? 1 : 0)}
                                                disabled={customerRef === 1 ? true : false}
                                                />
                                                <span className="e-slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group group-6 group-xs-12">
                                    <label> Deactivate </label>
                                    <div className="changeProfile-type-group">
                                        <div className="cptg-item">
                                            <span className="cptg-title"> 
                                                Deactivate your account
                                            </span>
                                            <label className="e-switch">
                                                    <input type="checkbox" name="deactivate" value={userDetail.deactivate === 0 ? 1 : 0}
                                                        // onChange={(e) => { 
                                                        //     changeTogglerValue(e)
                                                        // }}
                                                        disabled={true}
                                                    />
                                                <span className="e-slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-submit text-center group-12 pt-1">
                                    <button type="submit" className="btn btn-sm btn-red"> Save </button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
            <Dialogue state={dialogueState} setState={setDialogueState} />
        </>
    );
}

export default ChangeProfileType;
