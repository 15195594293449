import React, { useState,useEffect, useContext, useCallback } from 'react'
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import { APIService } from '../Components/Apis/APIServices'
import { Link, Redirect, useHistory } from 'react-router-dom';
import { formatSizeUnits } from '../Utility/Utility';
import Auth from '../Auth/Auth';
import { dateWithFormate } from "../Utility/common";
import AuthUserContext from '../Auth/AuthUser/AuthUserContext';
import Dialogue from '../Components/Common/Dialogue';
// import $ from 'jquery'

import Plaid from '../Components/Common/Plaid'
import GetInTouch from '../Components/Common/GetInTouch';



export default function QuotesSubmit(props) {

	const userData = useContext(AuthUserContext) || {}
	const requestData = props?.location?.state?.pageData;
	
	const history = useHistory();

	const [quoteState,setQuoteState] = useState({
		material_included_flag : 1,
    warranty_days: "0",
    services_included:"",
    additional_comments: ""
  });
  const [bankAccountShow, setBankAccountShow] = useState(false);
	const[uploadObj,setUploadObj]=useState({});

////////////////////////////   plaid object
  const [token, setToken] = useState(null);
  const [bankToken, setBankToken] = useState("")
  const [plaidShow, setPlaidShow] = useState(false)

/////////////////////////////////////////


	const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
		if (e.target.files) {
            uploadImg(e);
        }else{
			setQuoteState(prevState=>({
				...prevState,
				[name]:value
			}))
		}
    
  //  console.log("qqqqqqqqqqqqqqqqq quoteState", e.target)
   }

  //  console.log("qqqqqqqqqqqqqqqqq quoteState", quoteState)

    const uploadImg = (e) => {
        let name = e.target.name
		let value = e.target.value
        if(e.target.files[0].size>5242880){
            alert("5 MB Max size of the file")
        }else{

			let reader = new FileReader();
			reader.onload = (ele) => {
				setUploadObj({
					name:e.target.files[0].name,
					size:e.target.files[0].size,
					data:ele.target.result
				})
			};
			reader.readAsDataURL(e.target.files[0]);

	        setUploadObj({
	            name:e.target.files[0].name,
	            size:e.target.files[0].size
	        })
			setQuoteState(prevState=>({
				...prevState,
				images:e.target.files
			}))
			
        }
    }

	const [bankState,setBankState] = useState({
		bank_name: "",
		account_name: "",
		account_number: "",
		routing_number: "",
		account_type: 2,
		account_nick: "",
		isprimary: 0
	})
	const handleBankChange = ({target:{value,name}})=>{
		console.log(value,name)
		setBankState(prevState=>({
			...prevState,
			[name]:value
		}))
	}

	const handleBankRadio = (name,value)=>{
		setBankState(prevState=>({
			...prevState,
			[name]:value
		}))
	}

	const handleBankSubmit = (e)=>{
e.preventDefault()
if(userData.role_type == 1 ||
  userData.role_type == null ||
  userData.role_type == undefined ){
    document.getElementById("addNewBank-modal").click()
    setDialogueState({
      type : 'Warning',
      show:true,
      message : 'You need to register or sign-in as a service provider or both to be able to add a bank account.',
      // link : '/my-profile'
    })
  }else{
    // let filterNickList = bankAcs.filter( (data) => data.account_nick === bankState.account_nick);
    // let filterList = bankAcs.filter( (data) => data.isprimary === 1);

    // if (filterNickList.length) {
    //   alert("Account nick name should be unique.")
    // } else if (bankState.isprimary === 1 && filterList.length) {
    //   alert("Can't have multiple primary bank account.");
    // } else {

      // setDialogueState({
      //   type : 'Alert',
      //   show:true,
      //   message : 'This bank will now be your default bank and will be used for this and your all other open service request(s).',
      //   // link : '/register'
      //   yesButton: true
      // })

      setBankAcs((prev)=>{
        return([...prev, {
          account_name: bankState.account_name,
          account_number: bankState.account_number,
          routing_number: bankState.routing_number,
          account_nick: bankState.account_nick,
          account_type: 2,
          isprimary: bankState.isprimary,
          bank_name: bankState.bank_name,
        }])
      })

          setBankAccountShow(false);
        document.getElementById("addNewBank-modal").click()
            setBankState({
          bank_name: "",
          account_name: "",
          account_number: "",
          routing_number: "",
          account_type: 2,
          account_nick: "",
          isprimary: 0,
        });

		
		// APIService.addBankAccount(bankState)
    // .then(res=>{
		// 	APIService.fetchBankAccount()
    //   .then(res=>{
    //     setBankAcs(res.data.data);

    //     setBankState({
    //       bank_name: "",
    //       account_name: "",
    //       account_number: "",
    //       routing_number: "",
    //       account_type: 1,
    //       account_nick: "",
    //       isprimary: 0,
    //     });

    //     setBankAccountShow(false);
    //     document.getElementById("addNewBank-modal").click()
    //     // window.location.href = '/quotes-submit'
    //     //<Redirect to="quotes-submit"/>
    //     // window.$("#addNewBank-modal").toggler("modal");
		// // data-dismiss="modal"
				
		// // 		$("#btnSave").click(function () {
		// // //   $("#addNewBank-modal").modal("hide");
		// // 			$('button[type="submit"]').attr("data-dismiss", "modal");
    // //     });
    //   }).catch(err=>setErr(err))
		// }).catch(err=>setErr(err))
	// }
  }	
}

	const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : '',
        link:""
    })

	const handleSubmit = (e)=>{
		e.preventDefault();
    if(userData.role_type == 1 ||
      userData.role_type == null ||
      userData.role_type == undefined ){
       
        setDialogueState({
          type : 'Warning',
          show:true,
          message : 'You need to register or sign-in as a service provider or both to be able to submit your quote.',
          link : '/register'
        })
        return
      }
      
		if (userData ) {
			if(!userData.DriverLicense || !userData.SpProfile){
				setDialogueState({
					type : 'Warning',
					show:true,
					message : 'Your profile is incomplete. Please complete it in "My Profile" section of your dashboard.',
					link : '/my-profile'
				})
				return;
			}
			if (userData.role_type === 1) {
				setDialogueState({
					type : 'Warning',
					show:true,
					message : 'Please login/register as service provider to submit a quote.',
				})
				return;
			}else {

				if (userData.id === requestData.user_id) {
					setDialogueState({
						type : 'Error',
						show:true,
						message : 'You can\'t submit quote for your own request.',
					})
					return;
				}else if(parseFloat(quoteState.labor_quote_amount) <= 10 ){
          setDialogueState({
						type : 'Error',
						show:true,
						message : 'Quote amount must be greater than $10.',
					})
					return;
        }else if(parseInt(quoteState.material_included_flag) === 1 && parseInt(quoteState.material_quote_amount) <= -1 ){
          setDialogueState({
						type : 'Error',
						show:true,
						message : 'Material Quote amount must be greater than $0.',
					})
					return;
        }
        else{
					let data = {
            ...quoteState, 
            user_id : requestData.user_id, 
            request_id : requestData.id, 
            provider_id : userData.id,
            // account_holder_name: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].account_name) || "",
            // routing_number: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].routing_number) || "",
            // account_number: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].account_number) || "",
            // payment_id: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].id) || "",
            payment_id: quoteState.account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? "" : 1,
            bank_token: quoteState.account_number === (localStorage.getItem("bankToken") && JSON.parse(localStorage.getItem("bankToken")).id) ? quoteState.account_number : ""
          }
					// console.table("QuoteData---",data, bankAcs.filter((i)=> i.account_number === quoteState.account_number));
					setLoader(true)
          APIService.createRequestQuote(data)
          .then(res=>{
            setLoader(false)
            // history.goBack()

    //         APIService.addBankAccount({
    //           bank_name: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].bank_name) || "", 
    //           account_type: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].account_type) || 2,
    //           account_number: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].account_number) || "",
    //           isprimary: (bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0] && bankAcs.filter((i)=> i.account_number === quoteState.account_number)[0].isprimary) || 0,            
    //         })
    // .then(res=>{
			
		// })
    // .catch(err=>setErr(err))

    // if(res.status === 200){}

    if (res.status === 200) {
      // console.log("res---->>", res.data.message);
     
      setDialogueState({
        type : 'Success',
        show:true,
        // message : `Sorry, for the inconvenience. We have notified the service provider and have disabled the quote. We have issued you a courtesy credit of $${res.data.spNoShowCharge}. Please review your transaction history. `+ 
        //            `You may also look for other available service requests in the area.`,
        // message : `Sorry, for the inconvenience. We have notified the customer and have disabled the service request. `+ 
        //            `Please confirm or submit your quotes on other service requests.`,
        message: res.data.message || "",
        back : history.goBack
        // refresh:true,
      })
    }else{
      // console.log("eeeeeeeeeeeeeeeee res else",res)
      setDialogueState({
        type : 'Warning',
        show:true,
        message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
      })
    }

            // setDialogueState({
            //   type : res.data.error?'Warning':'Success',
            //   show:true,
            //   message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || "",
            //   back : history.goBack
            // })
          })
          .catch(err=>{
            setLoader(false)
            setDialogueState({
              type :"Error",
              show:true,
              message : (e.response && e.response.data && e.response.data.message) || ""
              // back : history.goBack
            })
          })
          //   // setErr(err)
          //   )
				}

			}
		}else {
			setDialogueState({
				type : 'Error',
				show:true,
				message : 'Login First.',
			})
		}

	}

	const [slots,setSlots] = useState([])
	const [bankAcs,setBankAcs] = useState([])

    const [err,setErr] = useState({})

    const [loader, setLoader] = useState(false)

	useEffect(()=>{
        APIService.fetchAllTimeSlot().then(res=>setSlots((res && res.data && res.data.data) || [])).catch(err=>setErr(err))
		    APIService.fetchBankAccount()
        .then(res=>{
          const bankToken = JSON.parse(localStorage.getItem("bankToken"))

          if(bankToken){

            
            setBankAcs((prev)=>{
              return [...res.data.data, bankToken]
            })
          }else{
            setBankAcs(res.data.data)
          }
          

        })
        .catch(err=>setErr(err))
    },[])
	const  handleRadio=(name,value)=>{
        setQuoteState(prevState=>({
			...prevState,
			[name]:value
		}))
    }




    ///////////////////////////////// react plaid links function from git hub

   
    

  
  
  const createLinkToken = () => {
     
    APIService.tokenLink()
    .then((res)=>{
      if(res.status === 200){
        // console.log("token is ",res)
        setToken(res.data.token)
        localStorage.setItem("quoteState", JSON.stringify({...quoteState, request_id: requestData.id}))
        
      }
    })
    .catch((err) =>{})

  };

  useEffect(()=>{
    const quoteState = JSON.parse(localStorage.getItem("quoteState"))
    
    if(quoteState && quoteState.request_id === requestData.id)
      setQuoteState(quoteState)
  },[])

  console.log("gfsdfdsf", bankAcs)

  useEffect(()=>{
    return() =>{
      localStorage.removeItem("quoteState")
      localStorage.removeItem("bankToken")
    }
    

    
  },[])

  useEffect(()=>{
    if(token)
      setPlaidShow(true)
  },[token])

  // console.log("token is here", token)

  const [tick, setTick] = useState(0);
  const handleTick = (val) => {
    setTick(val);
  };

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }

    return (
      <div>
        {plaidShow ? <Plaid linkToken={token} 
        sendBankToken={(token)=> {
          // console.log("aasdkasdkasdjasndja",token)
          setToken("")
          setBankToken(token)
          }}
          onCancel = {()=>{
            setPlaidShow(false)
            setToken("")
            // document.getElementsByTagName("body")[0].style.backgroundColor = "red";
            // document.getElementsByTagName("body")[0].style.overflow = "scroll";
            // document.getElementsByTagName("body")[0].style.overflowX = "scroll";
            // document.getElementsByTagName("body")[0].style.overflowY = "scroll";
          }}
          /> : null}
        <Header />
        <section class="gray-hero-section banner-b-50">
          <div class="gh-clip-bg">
            <img
              src="../images/clip-bg.svg"
              class="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="inner-caption wow fadeInUp text-center">
                  <h1 class="mb-0">
                    {" "}
                    Submit <span> Quote </span>{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="createMyRequest-section">
          <div class="container">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div class="row">
                <div class="col-12">
                  <div class="cmr-form-block">
                  <div className="ardCat-back-block d-block d-xl-none">
                          <button 
                          onClick={()=>history.goBack()} 
                          type="button" className="ardCat-back-btn">
                            {" "}
                            <i className="fal fa-long-arrow-left"></i>{" "}
                          </button>
                        </div>
                  <div class="form-group group-title group-12 mb-0">
                      <p className="text-danger">
                        {userData.role_type == 1 ||
                          userData.role_type == null ||
                          userData.role_type == undefined 
                          // ? "*You need to register as a service provider or both to be able to submit quote."
                          ? <><div>*You need to register as a 'Service Provider' or 'Both' user type to be able to submit quote.</div>
                          <div> Please ensure your profile is complete in 'My Profile -> Additional Information' tab. 'Driver's License' and 'Profile Information' tabs are mandatory."
                          </div>
                          </>
                          : "*Please ensure your profile is complete in 'My Profile -> Additional Information' tab. 'Driver's License' and 'Profile Information' tabs are mandatory."}{" "}
                      </p>
                     
                    </div>
                  
                    <div
                      className="form-group group-title group-6 group-xs-12 fg-place"
                      
                    >
                        <h4> Selected Service Request ID </h4>
                     <span className="itv">
                                 {requestData?.id}
                                  </span>
                    </div>
                  
                    <div
                      className="form-group group-title group-6 group-xs-12 fg-place"
                      
                    >
                        <h4> Requested Service Date and Time </h4>
                     <span className="itv">
                                    {dateWithFormate(
                                      requestData?.service_date
                                    )}
                                    &nbsp; (
                                    {dateWithFormate(
                                      requestData?.timeGet?.start_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}{" "}
                                    -{" "}
                                    {dateWithFormate(
                                      requestData?.timeGet?.end_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}
                                    )
                                  </span>
                    </div>

                   

                    <div class="form-group group-title group-12 mb-0">
                      {/* <p className="text-danger">
                        {userData.role_type == 1 ||
                          userData.role_type == null ||
                          userData.role_type == undefined 
                          ? "*You need to register as a service provider or both to be able to submit quote."
                          : null}{" "}
                      </p> */}
                      <h4> Available Service Date and Time </h4>
                    </div>

                    {/* 	<div class="form-group date1 group-6 group-xs-12 datepicker-calendar fg-place" id="datepicker10"
							data-target-input="nearest">
							<div class="placeholder">
								<label for=""> Date </label>
								<span class="star">*</span>
							</div>
							<input type="text" class="holer-control form-control datepicker datetimepicker-input"
								data-target="#datepicker10" data-toggle="datetimepicker"  onChange={handleChange} name="date"   value={quoteState.date} />
						</div> */}
                    <div
                      className="form-group date1 group-6 group-xs-12 datepicker-calendar fg-place"
                      id="datepicker10"
                      data-target-input="nearest"
                    >
                       <div className="placeholder">
                          <label for=""> Select service date </label>
                          <span className="star">*</span>
                        </div>
                      <input
                        type="date"
                        onChange={handleChange}
                        name="quote_service_date"
                        value={quoteState.quote_service_date}
                        className="holer-control form-control"
                        data-target="#datepicker10"
                        data-toggle="datetimepicker"
                        required
                        min={getTodaysDate()}
                        placeholder="Select service date *"
                      />
                    </div>

                    <div className="form-group group-6 group-xs-12">
                      <select
                        className="form-control"
                        value={quoteState.time_slot}
                        name="time_slot"
                        onChange={handleChange}
                        required
                      >
                        {/* <option> Select Time <span style={{color: 'red'}}>*</span></option> */}
                        <option value="">Select Time *</option>
                        {slots.map((slot) => (
                          <option value={slot.id}>
                            {dateWithFormate(slot.start_time, "hA", "HH:mm:ss")}{" "}
                            - {dateWithFormate(slot.end_time, "hA", "HH:mm:ss")}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="form-group group-title group-12 mb-0">
                      <h4> Labor Quote Amount </h4>
                    </div>
                    <div class="form-group fg-place group-6 group-xs-12">
                      {/* <div
                        className={`placeholder ${
                          quoteState.labor_quote_amount ? "d-none" : "d-block"
                        }`}
                      >
                        <label for="labor_quote_amount">
                          {" "}
                          Labor Quote Amount ${" "}
                        </label>
                        <span className="star text-danger">*</span>
                      </div> */}

                      <input
                        type="text"
                        required
                        class="holer-control form-control"
                        onChange={handleChange}
                        name="labor_quote_amount"
                        value={quoteState.labor_quote_amount}
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                        title="Please enter numeric value"
                        maxLength="7"
                        placeholder='$0.00'
                          
                      />
                    </div>

                    <div class="form-group group-title group-12 mb-0">
                      <h4> Services Included </h4>
                    </div>

                    <div class="form-group group-12">
                      <div class="textarea-group">
                        <textarea
                          class="form-control resize-none"
                          required
                          placeholder="Provide a brief description of the services that you offer in this quote*"
                          rows="4"
                          onChange={handleChange}
                          value={quoteState.services_included}
                          name="services_included"
                          maxLength="300"
                        ></textarea>
                        <span class="textarea-limit"> {300 - quoteState.services_included.length} Characters Limit </span>
                
                      </div>
                      <span class="textNote">
                        {" "}
                        “Please do not share your personal/contact details such
                        as email id, contact number, address etc.”{" "}
                      </span>
                    </div>

                    <div class="form-group group-12">
                      <label> Warranty </label>
                      <div className="group-6 group-xs-12">
                        <div>
                        <select
                        className="form-control"
                        value={quoteState.warranty_days}
                        name="warranty_days"
                        onChange={handleChange}
                        required
                      >
                        {/* <option> Select Time <span style={{color: 'red'}}>*</span></option> */}
                        <option value="" disabled>Select Warranty Days *</option>
                        
                          <option value="0">
                           0
                          </option>
                          <option value="30">
                           30
                          </option>
                          <option value="45">
                           45
                          </option>
                          <option value="60">
                           60
                          </option>
                          <option value="90">
                           90
                          </option>
                       
                      </select>
                        </div>
                      </div>
                    </div>

                    

                    <div class="form-group group-12">
                      <label> Material Included </label>
                      <div class="radio-block">
                        <div className="radio-group">
                          <input
                            type="radio"
                            className="radio-input d-none"
                            name="material_included_flag"
                            checked={
                              quoteState.material_included_flag === 1
                                ? true
                                : false
                            }
                            id="ppc-yes"
                          />
                          <label
                            className="radio-label-control"
                            onClick={() =>
                              handleRadio("material_included_flag", 1)
                            }
                            for="ppc-yes"
                          >
                            {" "}
                            Yes{" "}
                          </label>
                        </div>

                        <div className="radio-group">
                          <input
                            type="radio"
                            className="radio-input d-none"
                            name="material_included_flag"
                            checked={
                              quoteState.material_included_flag === 0
                                ? true
                                : false
                            }
                            id="ppc-yes"
                          />
                          <label
                            className="radio-label-control"
                            onClick={() => {
                              handleRadio("material_included_flag", 0);
                              setQuoteState((prev) => {
                                return {
                                  ...prev,
                                  material_quote_amount: 0,
                                };
                              });
                            }}
                            for="ppc-yes"
                          >
                            {" "}
                            No{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group fg-place group-6 group-xs-12">
                      {/* <div class="placeholder">
								<label for=""> Material Quote Amount $ </label>
								<span class="star">*</span>
							</div> */}
                      <div
                        // className={`placeholder ${
                        //   quoteState.material_quote_amount
                        //     ? "d-none"
                        //     : "d-block"
                        // }`}
                      >
                        {/* <label for="material_quote_amount">
                          {" "}
                          Material Quote Amount ${" "}
                        </label> */}
                        {/* <span className="star text-danger">*</span> */}
                      </div>
                      <input
                        type="text"
                        class="holer-control form-control"
                        onChange={handleChange}
                        name="material_quote_amount"
                        value={quoteState.material_quote_amount}
                        disabled={quoteState.material_included_flag === 0}
                        // placeholder="Material Quote Amount $"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                        title="Please enter numeric value"
                        maxLength="7"
                        placeholder='$0.00'
                        
                      />
                    </div>

                    <div class="form-group group-title group-12 mb-0">
                      <h4> Additional Comments </h4>
                    </div>
                    <div class="form-group group-12">
                      <div class="textarea-group">
                        <textarea
                          class="form-control resize-none"
                          placeholder="Provide additional comments, if any"
                          rows="4"
                          maxLength="100"
                          onChange={handleChange}
                          name="additional_comments"
                          value={quoteState.additional_comments}
                        ></textarea>
                        <span class="textarea-limit"> {100 - quoteState.additional_comments.length} Characters Limit </span>
                
                      </div>
                    </div>

                    <div class="form-group group-title group-12 mb-0">
                      <h4> Payout Method <small><i>( *Bank account MUST be a checking account. )</i></small> </h4>
                    </div>

                    <div class="otherAddress-option mb-3">
                      <div class="form-group fg-place group-6 group-xs-12">
                        <select
                          class="form-control"
                          name="account_number"
                          required
                          onChange={handleChange}
                          
                        >
                          <option value=""> Select Checking Bank Account*  </option>
                          {bankAcs.map((ba) => (
                            <option value={ba.id}> {ba.account_number} </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group group-6 group-xs-12">
                        <div class="addNewAddress-request">
                          <button
                            type="button"
                            className="btn text-btn d-flex justify-content-center w-100"
                            // data-toggle="modal"
                            // data-target="#addNewBank-modal"
                            // onClick={() => setBankAccountShow(true)}
                            onClick={() => createLinkToken()}
                            disabled={userData.role_type == 1 ||
                              userData.role_type == null ||
                              userData.role_type == undefined }
                          >
                            {" "}
                            <i class="fal fa-plus-circle"></i> Add New Bank
                            Account{" "}
                          </button>
                          {/* <div className="custom-check-block">
                          <label className="custom-check-label"> */}
                          <i 
                          className="w-100 d-flex justify-content-center"
                          style={{
                            fontSize: "16px",
                            // paddingLeft: "35px",
                            // margin: "0",
                            color: "#9BA4B4",
                            textAlign: "left"
                          }}>* New bank will be your default bank and will be used <br className="d-none d-md-block"/> for this and all your other open service request(s).</i>
                          {/* </label>
                          </div> */}
                        </div>
                      </div>

                      {/* <button type="button" onClick={() => createLinkToken()}>
      Connect a bank account
    </button> */}
                    </div>

                    {/* <div class="cus-fileUpload-box form-group group-12">
							<div class="cus-fileUpload-group">
								<input required type="file" class="cus-fileUpload-control d-none" id="driverDL-input" onChange={handleChange} name="images" multiple/>
								<label for="driverDL-input" class="mb-0 cus-fileUpload-label" >
									<div class="profileUp-inner">
										<div class="upload-here">
											<img src={uploadObj.data ? uploadObj.data : "../images/image-icon.svg"} class="img-fluid" alt=""/>
										</div>
										<div className="upload-content">
											<h5> Upload Photos/Videos </h5>
											<span> {uploadObj && uploadObj.name?uploadObj.name:'No file selected yet!'} </span>
											<span>{uploadObj && uploadObj.size?formatSizeUnits(uploadObj.size):0}  -  5 MB Max Size </span>
                                        </div>
									</div>
									<p className="text-danger"> {uploadObj.data ? "":"*This field is required"}</p> 
								</label>

							</div>
						</div> */}

{/* <div className="form-group form-checkbox">
                      <div className="custom-check-block"> */}
                      <div className='d-flex mt-2 mt-md-0'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                        </div>
                        {/* </div> */}
                      {/* </div>
                    </div> */}

                    <div class="form-submit text-center group-12">
                      <button class="btn btn-md btn-red" type="submit" disabled={tick === 0 || loader}>
                        {" "}
                        {loader ? "Processing ..." : "Submit Quote" }{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Dialogue state={dialogueState} setState={setDialogueState} />
        </section>

        {/* <section class="getInTouch-section">
          <div class="container">
            <div class="col-12">
              <div class="getInTouch-box">
                <div class="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div class="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i class="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i class="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i class="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i class="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i class="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <GetInTouch />

        <Footer />
        <div class="to-top-container">
          <a href="javascript:" id="return-to-top">
            <i class="fal fa-chevron-up"></i>
          </a>
        </div>

        <div
          class="scale_cus_modal notifyCongrats-wrap"
          id="notification-modal"
        >
          <div class="scale_modal_body">
            <div class="modal_inner">
              {/* <!-- <div class="scale_modal-close">
	        		<button type="button" class="slide-close" data-dismiss="modal" aria-label="Close"> <i class="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
              <div class="scm_header">
                <div class="notifiy-check">
                  <img
                    src="../images/content/check.svg"
                    class="d-block m-auto img-fluid"
                    alt=""
                  />
                </div>
                <h2 class="scm_head-title"> Congratulations! </h2>
              </div>
              <div class="scm_body">
                <div class="notify-modal-body">
                  <p>
                    {" "}
                    You have successfully marked the Service Request ID 25632153
                    as complete.{" "}
                  </p>
                  <p>
                    {" "}
                    Please request the customer to accept completion for the
                    payments to be released.{" "}
                  </p>
                </div>

                <div class="scm_footer justify-content-center">
                  <a href="mark-completion.html" class="btn btn-red">
                    {" "}
                    OK{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class={`scale_cus_modal addnewaddress-wrap ${
            bankAccountShow ? "show" : ""
          }`}
          id="addNewBank-modal"
          area-modal={bankAccountShow}
          style={{display: `${bankAccountShow ? "" : "none"}`}}
        >
          <div class="scale_modal_body scale_modal_lg">
            <div class="modal_inner">
              {/* <!-- <div class="scale_modal-close">
	        		<button type="button" class="slide-close" data-dismiss="modal" aria-label="Close"> <i class="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
              <div class="scm_header">
                <h2 class="scm_head-title"> Add Bank Account </h2>
              </div>
              <form onSubmit={(e) => handleBankSubmit(e)}>
                <div class="scm_body">
                  <div>
                    <div class="row">
                      {/* <div class="form-group fg-place group-6 group-xs-12">
                        <div
                          className={`placeholder ${
                            bankState.bank_name ? "d-none" : "d-block"
                          }`}
                        >
                          <label for="labor_quote_amount">
                            {" "}
                            Name of the Bank{" "}
                          </label>
                          <span className="star text-danger">*</span>
                        </div>

                        <input
                          type="text"
                          class="holer-control form-control"
                          value={bankState.bank_name}
                          name="bank_name"
                          onChange={handleBankChange}
                          required
                        />
                      </div> */}

                      <div class="form-group group-12 group-xs-12 fg-place">
                        <div
                          className={`placeholder ${
                            bankState.account_name ? "d-none" : "d-block"
                          }`}
                        >
                          <label for=""> Name on the Account </label>
                          <span class="star">*</span>
                        </div>
                        <input
                          type="text"
                          class="holer-control form-control"
                          value={bankState.account_name}
                          name="account_name"
                          onChange={handleBankChange}
                          required
                        />
                      </div>

                      <div class="form-group group-12 group-xs-12 fg-place">
                        <div
                          className={`placeholder ${
                            bankState.account_number ? "d-none" : "d-block"
                          }`}
                        >
                          <label for=""> Account Number </label>
                          <span class="star">*</span>
                        </div>
                        <input
                          type="text"
                          class="holer-control form-control"
                          value={bankState.account_number}
                          name="account_number"
                          onChange={handleBankChange}
                          maxlength="19"
                          required
                          pattern="[0-9 ]*"
                          title="Account Number should only contain numbers"
                        />
                      </div>
                      <div class="form-group group-12 group-xs-12 fg-place">
                        <div
                          className={`placeholder ${
                            bankState.routing_number ? "d-none" : "d-block"
                          }`}
                        >
                          <label for=""> Routing Number </label>
                          <span class="star">*</span>
                        </div>
                        <input
                          type="text"
                          class="holer-control form-control"
                          value={bankState.routing_number}
                          name="routing_number"
                          onChange={handleBankChange}
                          required
                          pattern="[0-9]*"
                          title="Routing Number should only contain numbers"
                        />
                      </div>

                      {/* <div class="form-group group-12 mb-2 primary-radio-group">
                        <label> Account Type </label>
                        <div class="radio-group">
                          <input
                            type="radio"
                            class="radio-input d-none"
                            name="account-type"
                            id="saving"
                            checked={bankState.account_type == 1 ? true : false}
                          />
                          <label
                            class="radio-label-control"
                            for="saving"
                            onClick={() => handleBankRadio("account_type", 1)}
                          >
                            {" "}
                            Savings{" "}
                          </label>
                        </div>
                        <div class="radio-block">
                          <div class="radio-group">
                            <input
                              type="radio"
                              class="radio-input d-none"
                              value={bankState.account_type}
                              name="account-type"
                              id="checking"
                              checked={
                                bankState.account_type == 2 ? true : false
                              }
                              disabled
                            />
                            <label
                              class="radio-label-control"
                              for="checking"
                              // onClick={() => handleBankRadio("account_type", 2)}
                            >
                              {" "}
                              Checking 
                             
                              </label>
                              <i className='text-danger'>Your bank account must be a checking account.</i>{" "}
                            
                          </div>
                        </div>
                      </div> 

                      <div class="form-group group-12 fg-place">
                        <div
                          className={`placeholder ${
                            bankState.account_nick ? "d-none" : "d-block"
                          }`}
                        >
                          <label for=""> Account Nick Name </label>
                          <span class="star">*</span>
                        </div>
                        <input
                          type="text"
                          class="holer-control form-control"
                          value={bankState.account_nick}
                          name="account_nick"
                          onChange={handleBankChange}
                          required
                        />
                      </div>
                      <div class="form-group group-12 primary-radio-group">
                        <label> Primary </label>
                        <div class="radio-block">
                          <div class="radio-group">
                            <input
                              type="radio"
                              class="radio-input d-none"
                              name="bank-primary"
                              id="bank-primary-yes"
                              checked={bankState.isprimary == 1 ? true : false}
                            />
                            <label
                              class="radio-label-control"
                              for="bank-primary-yes"
                              onClick={() => handleBankRadio("isprimary", 1)}
                            >
                              {" "}
                              Yes{" "}
                            </label>
                          </div>

                          <div class="radio-group">
                            <input
                              type="radio"
                              class="radio-input d-none"
                              name="bank-primary"
                              id="bank-primary-no"
                              checked={bankState.isprimary == 0 ? true : false}
                            />
                            <label
                              class="radio-label-control"
                              for="bank-primary-no"
                              onClick={() => handleBankRadio("isprimary", 0)}
                            >
                              {" "}
                              No{" "}
                            </label>
                          </div>
                        </div>
                      </div>  */}
                    </div>
                    {/* <div>
                    This bank will now be your default bank and will be used for this and your all other open service request(s). Do you wish to continue?
                    </div> */}
                    <i className='text-danger'>Your bank account must be a checking account.</i>{" "}
                    
                    <div class="form-group group-12 primary-radio-group mt-1">
                            
                        <h6 style={{textAlign:"center"}}> This bank will now be your default bank and will be used for this and your all other open service request(s). Do you wish to continue?
                     </h6>
                    </div>
                    <div class="scm_footer justify-content-center">
                    <button
                        type="button"
                        class="btn btn-black btn-md mr-3"
                       onClick={()=>{
                        setBankAccountShow(false);
                        document.getElementById("addNewBank-modal").click()
                       }}
                        id="btnCancel"
                        // data-dismiss="modal"
                      >
                        {" "}
                        No{" "}
                      </button>
                      <button
                        type="submit"
                        class="btn btn-red btn-md"
                        disabled={
                          // !bankState.bank_name ||
                          !bankState.account_name ||
                          !bankState.account_number ||
                          !bankState.routing_number 
                          // !bankState.account_nick
                        }
                        id="btnSave"
                        
                        // data-dismiss="modal"
                      >
                        {" "}
                        Yes{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}
