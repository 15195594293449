import { useEffect, useState } from 'react';
import { APIService } from "../../Components/Apis/APIServices";
import Dialogue from '../../Components/Common/Dialogue';

const DisputeModal = (props) => {
    // console.log("yoge",props)
    const [dispute_reason, setdispute_reason] = useState("");
    const [closure_comments, setclosure_comments] = useState("");
	const closeData=props && props.closeData?props.closeData:"";

  const [disputeReason,setDisputeReason] = useState([])

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : '',
    link:""
})

  useEffect(() =>{
    
    APIService.disputeReason()
        .then((res) => {
            // console.log("close resssssssssss", res.data);
            if(res.status === 200){
              setDisputeReason(res.data || [])
            }else{
              
            }

            
           
        })
        .catch((e) => console.log("error", e));
  },[])

	

	const handleSubmit = async() => {
        var data={
            quote_id: (closeData && closeData?.quote_id) || "",
            provider_id:(closeData && closeData?.provider_id) || "",
            // dispute_details:"",
            request_id:closeData.id,
            dispute_creation_date:"",
            dispute_closure_date:"",
            dispute_reason:dispute_reason?dispute_reason:"",
            dispute_details :closure_comments?closure_comments:"",
            // sr_status:"",
        }
	    
        await APIService.addDisputeRequest(closeData?.id, data)
        .then((res) => {
            // console.log("close res", res);
            // window.location.reload()
            if(res.status === 200){
              setDialogueState({
                type : 'Success',
                show:true,
                message : `A dispute request has been created for your service request ${closeData.id}. The eZunction team will be looking into it to resolve the dispute. Please look out for communication that you may receive from the eZunction team in this context.`,
                // back : history.goBack
                refresh: true
              })
          }else{
            setDialogueState({
              type: "Failed",
              show: true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
              });
          }

        })
        .catch((e) =>{
          setdispute_reason("")
          setclosure_comments("")
        setDialogueState({
          type : 'Error',
          show:true,
          message :(e && e.response && e.response.data && e.response.data.message) || ""
        })
       

      }

        
        
        );

	    // if (response.status === 200) {
	    //   window.location.reload();
	    // }    
	}

    // console.log("rrr",dispute_reason,closure_comments)

	return (
    <>
    <Dialogue state={dialogueState} setState={setDialogueState} />

        <div
        class="scale_cus_modal disputeRequest-wrap"
        id="dispute-request-modal"
      >
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Dispute Request </h2>
            </div>
            <div class="scm_body">
              <form>
                <div class="form-group">
                  <label> Select Reason for Dispute Request </label>
                  <select class="form-control" onChange={(e)=>setdispute_reason(e.target.value)} value={dispute_reason}>
                    <option disabled selected value=""> Select a Dispute Reason *</option>
                     {/*<option value="Select a Dispute Reason 1"> Select a Dispute Reason 1</option>
                    <option value="Select a Dispute Reason 2"> Select a Dispute Reason 2</option> */}
                  {disputeReason.map((item,index)=>(
                     <option value={item.id} key={item.id}> {item?.name}</option>
                    
                  ))}
                  </select>
                </div>

                <div class="form-group">
                  <div class="textarea-group">
                    <textarea
                      class="form-control resize-none"
                      rows="6"
                      onChange={(e)=>setclosure_comments(e.target.value)}
                      placeholder="Provide Dispute Details *"
                      maxLength={100}
                      value={closure_comments}
                    ></textarea>
                    <span class="textarea-limit"> {100 - closure_comments.length} Characters Limit </span>
                  </div>
                </div>

                <div class="scm_footer">
                  <button
                    type="button"
                    class="btn btn-black"
                    data-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button
                    type="button"
                    class="btn btn-red"
                    data-dismiss="modal"
                    onClick={()=>handleSubmit()}
                    disabled={dispute_reason === "" || closure_comments=== ""}
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
</>
     );
} 

export default DisputeModal;

