import moment from 'moment';

export const getLocalStorage = (key) => JSON.parse(localStorage.getItem(key))

export const renderStatus = (status) => {
    switch (status) {
      case 1:
        return "Submitted";
      case 2:
        return "Cancelled";
      case 3:
        return "Quote Received";
      case 4:
        return "Confirmed";
      case 5:
        return "Started";
      case 6:
        return "Completed";
      case 7:
        return "Pending";
      case 8:
        return "Past Due";
      case 9:
        return "Closed";
      case 10:
        return "Revisit";
      case 11:
        return "Warranty";
      case 12:
        return "Dispute";
      case 13:
        return "Warranty Closed";
      case 14:
        return "Dispute Closed";
      case 15:
        return "Disabled";
      default:
        return "N/A";
    }
  };


/**
 * Name: dateWithFormate
 * Desc: Return Date According To Format
 * @param {date} _date_ 
 * @param {string} _formate_
 */

export function dateWithFormate (_date_, _formate_="ddd, MMMM DD, YYYY", parse="DD:MM:YYYY") {
  try{
    console.log("_date_", _date_, _formate_)
    if (_date_) return moment(_date_, parse).format(_formate_);
    else return "";   
  } catch (e) {
    console.log("_date_Err", e)
    return "";
  }
}
