export const LoginTypes={
    LoginAttempt:'LOGIN_ATTEMPT',
    LoginResponse:'LOGIN_RESPONSE',
    LoginValidation:'LOGIN_VALIDATION',
    LoginPassword:'LOGIN_PASSWORD',
    LoginEmail:'LOGIN_EMAIL',
    LoginRememberMe:'LOGIN_REMEMBER',
    LoginShowPassword:'SHOW_PASSWORD',
    LoginInitialState:'INITIAL_STATE',
    IsLogin:"IS_LOGIN",
}