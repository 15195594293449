import React, { useState, useEffect } from "react";
import { APIService } from "../Components/Apis/APIServices";
import Footer from "../Components/Common/Footer";
import GetInTouch from "../Components/Common/GetInTouch";
import Header from "../Components/Common/Header";
import images from "../Utility/Images";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [state, setState] = useState({
    full_name: "",
    email_id: "",
    subject: "",
    message: "",
    user_ip:""
  });

  const [status, setStatus] = useState(true);

  const [pos, setPos] = useState({
    lat:"",
    long:""
})

const [validCap, setValidCap] = useState(true)

useEffect(()=>{
  fetch("https://api.db-ip.com/v2/free/self")
  .then((res) => res.json())
  .then(
      (result) =>{
          // console.log("ttttttttttttttttttt", result.ipAddress)
          // setIP(result.ipAddress)
          setState((prev) => {
            return({...prev, user_ip: result.ipAddress })
          })
      },
      (error) =>{}
  )
},[])

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(locSuccess, locError)
        function locSuccess (position){
            setPos({
                  lat: position.coords.latitude,
                  long: position.coords.longitude,
                })

                
            }

            function locError (error){
                // handleLocationError(true, infoWindow, map.getCenter());
              }

              
    },[])


  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

    const handleSubmit = (e) => {
       e.preventDefault();
    //   console.log("i reached")
    APIService.contactUs(state)
      .then((resp) => {
        // console.log("/////////////////////////////////",resp);
        setStatus(false);

        APIService.sendLatLong(pos)
        .then((res)=>{})
        .catch((err)=>{})
      })
      .catch((err) => console.log(err));
  };

  const handleCaptchaChange = (value)=>{
    console.log("Captcha value:", value);
    if(value)
        setValidCap(false)
}

  return (
    <div style={{minHeight: "100vh"}}>
      <Header />
      <section className="inner-banner-section">
        <div className="circle-box slideInUp"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1>
                  {" "}
                  Contact <span> Us </span>{" "}
                </h1>
                <p>
                  {" "}
                  {/* Want to discuss something with us or have a question for the{" "}
                  <br className="d-none d-md-block" /> eZunction team? Please
                  reach out to us.{" "} */}
                  Do you have any questions or want to connect with us? 
                  <br className="d-none d-md-block" />
                  Reach our eZunction support team here!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="getContent-section contactUs-section">
        <div className="wp-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="feedback-detail-head">
                <h2>
                  {" "}
                  Reach Us<span> Here! </span>
                </h2>
                {/* <!-- <p>Looking for discuss something with us? Or, <br className="d-none d-md-block" /> can't find what you are looking fir? </p> --> */}
              </div>
              {status ? (
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="contactUs-form">
                    <div>
                      <div className="form-group fg-place group-12">
                        <div className="placeholder">
                          <label for=""> Full Name </label>
                          <span className="star">*</span>
                        </div>
                        <input
                          type="text"
                          name="full_name"
                          value={state.full_name}
                          onChange={handleChange}
                          className="holer-control form-control"
                          placeholder="First Name*"
                          required
                        />
                      </div>

                      <div className="form-group fg-place group-12">
                        <div className="placeholder">
                          <label for=""> Email ID </label>
                          <span className="star">*</span>
                        </div>
                        <input
                          type="email"
                          name="email_id"
                          value={state.email_id}
                          onChange={handleChange}
                          className="holer-control form-control"
                          placeholder="Email*"
                          required
                        />
                      </div>

                      <div className="form-group fg-place group-12">
                        <div className="placeholder">
                          <label for=""> Subject </label>
                          <span className="star">*</span>
                        </div>
                        <input
                          type="text"
                          name="subject"
                          value={state.subject}
                          onChange={handleChange}
                          className="holer-control form-control"
                          placeholder="Subject*"
                          required
                        />
                      </div>

                      <div className="form-group group-12">
                        <div class="textarea-group">
                        <textarea
                          name="message"
                          className="form-control"
                          value={state.message}
                          onChange={handleChange}
                          rows="3"
                          placeholder="Your Message*"
                          maxLength="100"
                          required
                        ></textarea>
                        <span class="textarea-limit"> {100 - state.message.length} Characters Limit </span>
                        </div>
                      </div>
                      <div className="form-group group-12">
                      <ReCAPTCHA
    sitekey="6LeGaDAfAAAAACcH7UFxzT4wrt4zoKQYqGCsRMz1"
    onChange={handleCaptchaChange}
  />
  </div>

                      <div className="form-submit-action">
                        {/* <button
                          type="submit"
                          //   onClick={handleSubmit}
                          className="btn btn-red btn-sm"
                        > */}
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-red btn-sm"
                          disabled={validCap}
                        />{" "}
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <p>Your enquiry submitted sucessfully</p>
              )}
            </div>

            <div className="col-lg-6">
              <div className="contactUs-img">
                <img
                  src={images.contactUs}
                  className="m-auto d-block img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${window.screen.width >=1200 ? "video-list-section" : ""} emailUs-section`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="emailUs-block text-center">
                <div className="emailUs-icon">
                  <img src={images.contactIcon} className="img-fluid" alt="" />
                </div>
                <h3>
                  {" "}
                  Email Us{" "}
                  <a
                    href="mailto:support@ezunction.com"
                    className="orange-text"
                  >
                    {" "}
                    Here!
                  </a>{" "}
                </h3>
                <a href="mailto:support@ezunction.com">
                  {" "}
                  support@ezunction.com{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default ContactUs;
