import React from 'react';
import { useHistory } from 'react-router-dom';


function Dialogue(props){
  const history = useHistory();
    const ok = ()=>{
        if(props.state.link){
          // console.log("tttttttttttttttt", props.state)
          props.setState({
            show:false
        })
        // history.push(props.state.link)
        if(props.state.state === undefined || props.state.state === null){
          history.push({
            pathname: props.state.link,
            state: {}
          })
        }
        else{
          history.push({
            pathname: props.state.link,
            state: { activeData: props.state.state.activeData, user: "service_provider" },
          })
        }
        }else if(props.state.back){
          props.setState({
            show:false
          })
          props.state.back()
        }else if(props.state.refresh){
          props.setState({
            show:false
          })
          window.location.reload()
        }else{
          props.setState({
            show:false
        })
        // window.location.reload()
        }
        // window.location.reload()
    }
    return (
        <div
          class={`scale_cus_modal addnewaddress-wrap ${props.state.show ? "show" : ""}`}
          area-modal={props.state.show}
          role="dialog"
        >
          <div class="scale_modal_body scale_modal_md">
            <div class="modal_inner">
              {/* <!-- <div class="scale_modal-close">
                        <button type="button" class="slide-close" data-dismiss="modal" aria-label="Close"> <i class="fal fa-arrow-left"></i> </button>
                    </div> --> */}
              <div class="scm_header">
                <h2 class="scm_head-title"> {props.state.type} </h2>
              </div>
              <div class="scm_body">
                <div>
                  <div class="row">
                 
                  <div class="form-group fg-place group-10 group-xs-10 text-center w-100">
                  <p> {props.state.message} </p>
                </div>

                  </div>
                  <div class="scm_footer justify-content-center">
                    <button 
                    style={{display:props?.state.yesButton ? "block" :"none"}}  
                    data-dismiss="modal"
                    className="btn btn-black btn-md mr-3"
                    onClick={()=>{
                      props.setState({
                        show:false
                    })
                    }}
                    >No</button>
                    <button
                      type="submit"
                      class="btn btn-red btn-md"
                      onClick={ok}
                    >
                      {" "}
                      {props?.state.yesButton ? "Yes" :"OK"}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default Dialogue