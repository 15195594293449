import React, { useEffect, useState } from "react";
import Dialogue from "../../Components/Common/Dialogue";

// import { Link } from "react-router-dom";

const Info = ({infoText, btnLink, btnTitle, bodyMessage}) => {

	const [dialogueState,setDialogueState] = useState({
		type : '',
		show : false,
		message : ''
	  })

	return (
<>
<Dialogue state={dialogueState} setState={setDialogueState} />
			<div className="tab-pane" id="gh-servicePro-tab" role="tabpanel">
	          <div className="container">
	            <div className="row">
	              <div className="col-12">
	                <div className="blankService-tab-go d-flex flex-column">
	                  <p>
	                    {infoText}
	                  </p>

	                  <button className="btn btn-red mt-3" onClick={()=>{
						  setDialogueState({
							type : 'Alert',
							show:true,
							message : bodyMessage,
							})
					  }}>
	                    {btnTitle}
	                  </button>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
			</>
		);
}

export default Info;