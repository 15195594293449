import React from "react";


const Rating = (props) => {
  
  const ratingArr = [1, 2, 3, 4, 5];
  

  return (
    <>
     
          {ratingArr.map((rate) => {
              if (props?.rating === undefined || props?.rating === null) {

              } else {
                  if (rate <= Math.round(parseFloat(props.rating))) {
                      return (
                          <i
                              className="fas fa-star star-fill"
                  
                          ></i>
                      );
                  } else {
                      return (
                          <i className="fas fa-star"></i>
                      );
                  }
              }
          })}
          
     
        {/* <span className="orsp-text"> {props?.rating || ""}</span> */}
   
    </>
  );
};

export default Rating;
