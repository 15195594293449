import React, { useState, useEffect } from "react";
import { APIService } from "../Components/Apis/APIServices";
import { Link, Redirect, useHistory } from "react-router-dom";
import { formatSizeUnits } from "../Utility/Utility";
import Auth from "../Auth/Auth";
import Dialogue from '../Components/Common/Dialogue';


export default function AddNewCard(props) {
  const Countries = props.Countries || [];
  const initialState = {
    card_number : "",
    card_name :"",
    expiration_date : "",
    cvv_code : "",
    billing_address_1 : "",
    billing_address_2 :"",
    billing_country: "233",
    billing_state:"",
    billing_city:"",
    billing_zip_code:"",
    card_nick:"",
    isprimary : 0,
};
  const [userCard, setUserCard] = useState(initialState);

  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
  
  const history = useHistory();

  const [err, setErr] = useState({});
  
  useEffect(() => {
       fetchState(233) 
    }, []);
    
 const fetchState = (countryId) => {
        let data = {
            keyword: '',
            country_id: countryId,
        }
        APIService.fetchState(data).then(async (res) => {
            if (res.data.status == 200) {
                setState(res.data.data.sort((a, b) => a.name.localeCompare(b.name)));
            }
        }).catch((error) => {

        })
    }
    const fetchCity = (stateId) => {
        let data = {
            keyword: '',
            state_id: stateId,
        }
        APIService.fetchCity(data).then(async (res) => {
            if (res.data.status == 200) {
                setCity(res.data.data);
            }
        }).catch((error) => {

        })
    }

  const handlePrimaryRadio = (name, value) => {
    setUserCard((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        // console.log("asdasdsaddsfdsf", value)
        if (name == "billing_country") {
            fetchState(value);
        } else if (name == "billing_state") {
            fetchCity(value)
        }else if(name === "billing_zip_code"){
            if(userCard.billing_zip_code.length === 4 && value.length === 5)
                value+='-'
            else if(value.length === 6)
                value = value.replace('-','')
        }else if(name === "expiration_date"){
            if(userCard.expiration_date.length === 1 && value.length === 2)
                value+='/'
            else if(value.length === 3)
                value = value.replace('/','')
        }
        setUserCard((prevState) => {
            return { ...prevState, [name]: value };
        });
        
    }
  const handleCardSubmit = (e) => {
e.preventDefault()
    let isPrimary = false,isNickNameExists = false;

    if(!userCard.card_nick){
        setDialogueState({
            type : 'Error',
            show:true,
            message : 'Nick Name Not Valid'
        })
        return;
    }

    props.userCardList.forEach(card=>{
        if(card.card_nick == userCard.card_nick)
          isNickNameExists = true;
        if(userCard.isprimary && card.isprimary == userCard.isprimary)
          isPrimary = true;
    })
    let todayDate = new Date();
    let [month,year] = userCard.expiration_date.split("/") || [0,0];
    if(isPrimary){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Primary Card Exists'
            })
            return;
    }else if(isNickNameExists){
        setDialogueState({
            type : 'Error',
            show:true,
            message : 'Account nick name should be unique.'
        })
        return;
    }else if(userCard.expiration_date.length<5){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Expiry not valid'
            })
            return;
    }else if(userCard.billing_zip_code.length<5 || userCard.billing_zip_code.length>10){
        setDialogueState({
            type : 'Error',
            show:true,
            message : 'Zip Code not valid'
        })
        return;
    }else if((todayDate.getFullYear()%2000) > parseInt(year) || ((todayDate.getFullYear()%2000) == parseInt(year) && todayDate.getMonth()+1) >= parseInt(month)){
       setDialogueState({
                type : 'Error',
                show:true,
                message : 'Card Expired'
            })
            return;
    }
    
    APIService.addUserCard({...userCard,billing_zip_code:userCard.billing_zip_code.replace('-','')})
      .then((res) => {
        // console.log("aaya tha", res);
        if (res && res.status == 200) {
          //  console.log("aaya tha");
          props.fetchCards()
          handleClose()
          // props.handleHide();
          setUserCard({...initialState})
          props.setDialogueState({
            type : 'Success',
            show:true,
            message : 'Card added successfully.'
        })
        // document.getElementById("addNewCard-modal").click()
        }else{
          handleClose()
          props.setDialogueState({
            type : 'Error',
            show:true,
            message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
        // document.getElementById("addNewCard-modal").click()
      }
      })
      .catch((e) => {
        props.setDialogueState({
          type : 'Error',
          show:true,
          message : e.response.data.message ? e.response.data.message : ""
      })
      });
};

const handleClose = ()=>{
  setUserCard(initialState)
  // props.handleHide()
  document.getElementById("addNewCard-modal").click()
  return null
}

  return (
    <div
      className={`scale_cus_modal addnewaddress-wrap ${props.show ? "show" : ""}`}
      id="addNewCard-modal"
      area-modal={props.show}
      role="dialog"
    >
      <div className="scale_modal_body scale_modal_lg">
        <div className="modal_inner">
          {/* <!-- <div className="scale_modal-close">
	        		<button type="button" className="slide-close" data-dismiss="modal" aria-label="Close"> <i className="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
          <div className="scm_header">
            <h2 className="scm_head-title"> Add New Card </h2>
          </div>
          <div className="scm_body">
            <form onSubmit={(e)=>handleCardSubmit(e)}>
            <div>
              <div className="row">
                 <div className="form-group fg-place group-6 group-xs-12">
                    <div className={`placeholder ${userCard.card_number ? 'd-none' : 'd-block'}`}>
                      <label for=""> Card Number </label>
                      <span className="star">*</span>
                    </div>
                    
                    <input
                      type="text"
                      onChange={handleChange}
                      className="holer-control form-control"
                      value={userCard.card_number}
                      name="card_number"
                      maxlength="19"
                      inputmode="numeric" 
                      // pattern="[0-9\s]{13,19}" 
                      pattern="^\d+$"
                      title="Please enter numeric value"
                      autocomplete="cc-number" 
                      data-inputmask="'mask': '9999 9999 9999 9999'"
                      required
                    />
                  </div>

                <div className="form-group fg-place group-6 group-xs-12">
                  <div className={`placeholder ${userCard.card_name ? 'd-none' : 'd-block'}`}>
                    <label for=""> Card holder name </label>
                    <span className="star">*</span>
                  </div>
                  <input
                    type="text"
                    className="holer-control form-control"
                    value={userCard.card_name}
                    name="card_name"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group fg-place group-6 group-xs-12">
                  <div className={`placeholder ${userCard.expiration_date ? 'd-none' : 'd-block'}`}>
                    <label for=""> Expiry MM/YY </label>
                    <span className="star">*</span>
                  </div>
                  <input
                    type="text"
                    className="holer-control form-control"
                    maxlength="5"
                    value={userCard.expiration_date}
                    name="expiration_date"
                    onChange={handleChange}
                    pattern="(?:0[1-9]|1[0-2])\/[0-9]{2}" 
                    title="Must have valid month and year"
                    required
                  />
                </div>

                <div className="form-group fg-place group-6 group-xs-12">
                  <div className={`placeholder ${userCard.cvv_code ? 'd-none' : 'd-block'}`}>
                    <label for=""> CVV Code </label>
                    <span className="star">*</span>
                  </div>
                  <input
                    type="text"
                    className="holer-control form-control"
                    value={userCard.cvv_code}
                    name="cvv_code"
                    onChange={handleChange}
                    pattern="^[0-9]*$"
                    title="Only numbers are allowed"
                    maxLength="4"                 
                    required
                  />
                </div>

                           <div className="form-group group-title group-12 mb-0">
                                <h4> Billing Address </h4>
                              </div>

                              <div className="form-group fg-place group-6 group-xs-12">
                                <div className={`placeholder ${userCard.billing_address_1 ? 'd-none' : 'd-block'}`}>
                                  <label for="">Address Line 1 </label>
                                  <span className="star">*</span>
                                </div>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userCard.billing_address_1}
                                  name="billing_address_1"
                                  onChange={handleChange}
                                  required
                                />
                              </div>

                              <div className="form-group fg-place group-6 group-xs-12">
                                <div className={`placeholder ${userCard.billing_address_2 ? 'd-none' : 'd-block'}`}>
                                  <label for="">Address Line 2 </label>
                                </div>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userCard.billing_address_2}
                                  name="billing_address_2"
                                  onChange={handleChange}
                                />
                              </div>

                                                       <div className="form-group group-4 group-md-6 group-xs-12">
                                <select
                                  className="form-control"
                                  // name="country"
                                  value={userCard.billing_country}
                                  name="billing_country"
                                  onChange={handleChange}
                                  required
                                >
                                  <option disabled={true} value="">
                                    Select Country *
                                  </option>
                                  {Countries.length
                                    ? Countries.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                              <div className="form-group group-4 group-md-6 group-xs-12">
                                <select
                                  className="form-control"
                                  // name="state"
                                  value={userCard.billing_state}
                                  name="billing_state"
                                  onChange={handleChange}
                                  required
                                >
                                  <option disabled={true} value="">
                                    Select State *
                                  </option>
                                  {State.length
                                    ? State.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                              <div className="form-group group-4 group-md-6 group-xs-12">
                                <select
                                  className="form-control"
                                  name="city"
                                  value={userCard.billing_city}
                                  name="billing_city"
                                  onChange={handleChange}
                                  required
                                >
                                  <option disabled={true} value="">
                                    Select City *
                                  </option>
                                  {City.length
                                    ? City.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                            <div className="form-group fg-place  group-md-6 group-xs-12">
                                <div className={`placeholder ${userCard.billing_zip_code ? 'd-none' : 'd-block'}`}>
                                  <label for=""> Zip Code </label>
                                  <span className="star">*</span>
                                </div>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userCard.billing_zip_code}
                                  name="billing_zip_code"
                                  onChange={handleChange}
                                  minLength="5"
                                  maxLength="10"
                                  pattern="[0-9-]*"
                                  required
                                />
                              </div>
                                <div className="form-group fg-place group-6 group-xs-12">
                                  <div className={`placeholder ${userCard.card_nick ? 'd-none' : 'd-block'}`}>
                                    <label for=""> Account Nick Name </label>
                                    <span className="star">*</span>
                                  </div>
                                  <input
                                    type="text"
                                    className="holer-control form-control"
                                    value={userCard.card_nick}
                                    name="card_nick"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>





                
                <div className="form-group group-12 primary-radio-group">
                  <label> Primary </label>
                  <div className="radio-block">
                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        name="bank-primary"
                        id="bank-primary-yes"
                        checked={userCard.isprimary == 1 ? true : false}
                      />
                      <label
                        className="radio-label-control"
                        for="bank-primary-yes"
                        onClick={() => handlePrimaryRadio("isprimary", 1)}
                      >
                        {" "}
                        Yes{" "}
                      </label>
                    </div>

                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        name="bank-primary"
                        id="bank-primary-no"
                        checked={userCard.isprimary == 0 ? true : false}
                      />
                      <label
                        className="radio-label-control"
                        for="bank-primary-no"
                        onClick={() => handlePrimaryRadio("isprimary", 0)}
                      >
                        {" "}
                        No{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scm_footer justify-content-center">
                <button style={{display:"none"}} id="cancelButtobBank" data-dismiss="modal"></button>
                <button
                  type="button"
                  className="btn btn-black btn-md mr-3"
                  // onClick={props.handleHide}
                  onClick={handleClose}
                >
                  {" "}
                  Cancel{" "}
                </button><button
                  type="submit"
                  className="btn btn-red btn-md"
                  // onClick={handleCardSubmit}
                  disabled={
                    !userCard.card_number ||
                    !userCard.card_name ||
                    !userCard.expiration_date ||
                    !userCard.cvv_code ||
                    !userCard.billing_address_1 ||
                    !userCard.billing_country ||
                    !userCard.billing_state ||
                    !userCard.billing_city ||
                    !userCard.billing_zip_code ||
                    !userCard.card_nick
                  }
                >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <Dialogue state={dialogueState} setState={setDialogueState}/>
    </div>
  );
}
