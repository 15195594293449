import React, { useEffect, useState } from 'react';
import { APIService } from './Apis/APIServices';
import Dialogue from './Common/Dialogue';
import Header from './Common/Header';
import { useHistory } from 'react-router-dom';

const Otp = (props) => {
    const [otp,setOtp] = useState({
        0:"",
        1:"",
        2: "",
        3: "",
        4: "",
        5:""
    })
    const [msg,setMsg] = useState(null)
    const {email} = props.location.state || {email:null};
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
    const history = useHistory();

    useEffect(()=>{
        if(!email){ 
            window.location.href = '/login'
        }
    },[])

    const handleSubmit = ()=>{
        
        APIService.otpLogin({
            email,
            otp:`${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`
        })
        .then(resp=>{
            if(resp.data.status === 401){
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message : 'Invalid OTP.'
                })

            }else{
                history.push("/create-new-password")
            }
            
        })
        .catch(err=>{
            // console.log(err)
            setDialogueState({
                type: "Failed",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
               message:(err && err.response && err.response.data && err.response.data.message) || null,
              });
        })
        
    }

    const handleOtp = (e,index)=>{
        setOtp(prevState=>{
            prevState[index] = e.target.value
            return prevState
        })
        const nextElement = document.getElementById(e.target.id.split('-')[0]+'-'+(parseInt(e.target.id.split('-')[1])+1))
        nextElement && nextElement.focus();
    }

    const handleResendOtp = ()=>{
        APIService.forgetPassword({email})
        .then(resp=>{
            // console.log(resp)
            if(resp.status < 400 )
                setMsg("otp sent successfully to your email")
        }).catch(err=>console.log(err))
    }
    return (
        <>
            <Header />
            <section className="auth-wrapper auth-login-box otp-box">
                <div className="auth-right-blue">
                    <img src="images/reset-password-graphic.svg" className="img-fluid" alt="" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12">
                            <div className="auth-white-box auth-block">
                                <div className="m_header">
                                    <h2> OTP </h2>
                                    <p> A 6 - Digit PIN has been sent to your email address, enter it below to continue </p>
                                </div>
                                <div className="m_body">
                                    <div className="auth-form" >
                                        <div className="form-group otp-form-group otpInput">
                                            <input type="text" onChange={(e)=>handleOtp(e, 0)} className="form-control otp-control" id="digit-1" name="digit-1" data-next="digit-2" maxLength="1" />

                                            <input type="text" onChange={(e)=>handleOtp(e, 1)} className="form-control otp-control" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" maxLength="1" />

                                            <input type="text" onChange={(e)=>handleOtp(e, 2)} className="form-control otp-control" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" maxLength="1" />

                                            <input type="text" onChange={(e)=>handleOtp(e, 3)} className="form-control otp-control" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" maxLength="1" />

                                            <input type="text" onChange={(e)=>handleOtp(e, 4)} className="form-control otp-control" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" maxLength="1" />

                                            <input type="text" onChange={(e)=>handleOtp(e, 5)} className="form-control otp-control" id="digit-6" name="digit-6" data-next="digit-6" data-previous="digit-4" maxLength="1" />
                                        </div>
                                        <div className="otp-resend">
                                            <button type="button" className="btn btn-text" onClick={handleResendOtp}> Resend OTP <img src="images/resend-icon.svg" className="img-fluid" /></button>
                                        </div>
                                        {
                                            msg ? <div>
                                            <p className="bottom-success d-flex justify-content-center mb-2">{msg}</p>
                                        </div> : null
                                        }
                                        
                                        <div className="form-submit text-center">
                                            <button type="button" onClick={handleSubmit} className="btn btn-md btn-red w-100"> Submit </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialogue state={dialogueState} setState={setDialogueState}/>
            </section>
        </>
    );
}

export default Otp;
