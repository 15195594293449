import { useEffect } from "react";
import { useState } from "react";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import { Link, useParams } from "react-router-dom";
import Auth from "../Auth/Auth";
import { APIService } from "../Components/Apis/APIServices";
import NoShowModal from "../Components/Modals/NoShowModal";
import CommentModel from "../Components/Modals/CommentModel";
import NotificationModal from "../Components/Modals/NotificationModal";

import MyAccountMenu from "../Components/Common/MyAccountMenu";
import { dateWithFormate } from "../Utility/common";
// import EnterCardDetails from './AcceptQuoteBankDetails'
import AcceptQuoteModal from "../Components/Modals/AcceptQuoteModal";
import Dialogue from '../Components/Common/Dialogue';

import { Scrollbars } from "react-custom-scrollbars";

import config from "../Components/Apis/config";
import Rating from './Rating'

import RejectModal from "../Components/Modals/RejectModal";
import GetInTouch from "../Components/Common/GetInTouch";

const QuotesDetailsClose = (props) => {
  // const requestData = props?.location?.state?.pageData

  const [pageData, setpageData] = useState([]);
  const [quoteData, setQuoteData] = useState({});

  const [indexs, setIndexs] = useState("")

  
  const [withdrawalReason, setWithdrawalReason] = useState([]);

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
})
const [ongoingCardShow, setOngoingCardShow] = useState(false)

  const userDetail = Auth.getUserDetails();
  const { id: requestId } = useParams();

  useEffect(() => {
    if (requestId) {
      fetchQuotes()
    }
  }, [requestId]);

  useEffect(() => { 
    APIService.srWithdrawReason()
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReason(res.data);
        }
      })
      .catch((e) => console.log("error", e));
  },[])

  const fetchQuotes = ()=>{
    APIService.fetchQuoteData(requestId)
        .then((res) => {
          if (res.status === 200) {
            setpageData(
              res.data
                ? res.data.quote
                  ? res.data.quote.length
                    ? res.data.quote
                    : []
                  : []
                : []
            );
            setQuoteData(
              res.data
                ? res.data.quote
                  ? res.data.quote.length
                    ? indexs 
                      ? res.data.quote[indexs]
                      :
                      res.data.quote[0]
                    : {}
                  : {}
                : {}
            );
          }
        })
        .catch((e) => console.log("close error", e));
  }

  // console.log("fdfdfdfdfdfdfdf", indexs)

  useEffect(()=>{
    const interval = setInterval(() => {
      fetchQuotes()
      // console.log("rrrrrrrrrrrrrrrtttttttttttttt", activeTab)
    }, 30 * 1000);

    return () =>{
      clearInterval(interval)
    }
  },[indexs])

  // console.log("qweqweqweqwe", props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData)

  const handleAcceptQuote = (e, payment_type, payment_id) => {
    e.preventDefault()
    APIService.fetchAcceptQuote(quoteData.id, payment_type, payment_id)
      .then((res) => {
        if (res.status === 200) {
          // window.location.reload();
          setDialogueState({
						type : 'Success',
						show:true,
						message : `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
					})
          fetchQuotes()
        }else{
          setDialogueState({
						type : 'Error',
						show:true,
						message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
        }
      })
      .catch((e) => {
        setDialogueState({
          type : 'Error',
          show:true,
          message : (e.response && e.response.data && e.response.data.message) || ""
        })
      });
  };

  const handleRejetQuote = () => {
    APIService.fetchRejectQuote(quoteData.id)
      .then((res) => {
        if (res.status === 200) {
          setDialogueState({
						type : 'Success',
						show:true,
						message : `You have successfully rejected the quote ${quoteData.id} for the service request ${requestId}.`,
					})
          fetchQuotes()
        }
      })
      .catch((e) => console.log("close error", e));
  };

  const renderButtons = (data) => {
    if (userDetail.role_type === 1 || userDetail.role_type === 3) {
      switch (data.quote_status) {
        case 1:        
          return (
            <div>
              <div
                className="btn btn-red btn-md ardCat-submitQ-btn"
                data-toggle="modal"
                data-target="#notifiaction-request-modal"
              >
                Accept Quote
              </div>
              <div
                className="mt-4 btn btn-black btn-md ardCat-submitQ-btn"
                data-toggle="modal"
                data-target="#notification-modal-reject"
              >
                Reject Quote
              </div>
            </div>
          );
        case 2:
          return (
            <div 
              className="btn btn-red btn-md ardCat-submitQ-btn"
              data-toggle="modal"
              data-target="#notification-modal-reject"
            >
              Reject Quote
            </div>
          );
        case 3 :
          return (
            (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.srstatus?.id) === 8 || 
            // (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.srstatus?.id) === 4 || 
            (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.srstatus?.id) === 5 || 
            (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.srstatus?.id) === 10 ?
              
            <button
              type="button"
              className="btn btn-red btn-md ardCat-submitQ-btn"
              data-toggle="modal"
              data-target="#customerNoShow-modal-wrapper"
            >
                Service Provider No Show
            </button>
              :
              null
          );
          case 5:
            return (
              <div>
                <div
                  className="btn btn-red btn-md ardCat-submitQ-btn"
                  data-toggle="modal"
                  data-target="#notifiaction-request-modal"
                >
                  Accept Quote
                </div>

              </div>
            );
        default:
          return null
      }
    }
  };

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "#FF8805",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
  
  // console.log("..........///////////", quoteData?.quote_withdrawal_reason_id);

  return (
    <>
      <Header />
      <NoShowModal requestData={quoteData} title="Service Provider No Show" setDialogueState={setDialogueState} />
      <CommentModel requestData={quoteData} />
      <RejectModal quoteData={quoteData} />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <AcceptQuoteModal
        title="Accept Quote"
        // body={<EnterCardDetails /> }
        secondaryBtnText="Cancel"
        primaryBtnText="Submit"
        handleClick={handleAcceptQuote}
        show={true}
      />
      {/* <NotificationModal
        title="Confirm"
        body="Are you sure you want to Reject this quote?"
        secondaryBtnText="Cancel"
        primaryBtnText="Submit"
        handleClick={handleRejetQuote}
        show={true}
        eleId="notification-modal-reject"
      /> */}

      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src="../images/clip-bg.svg"
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  Quotes <span> Details </span>
                </h1>
                <div className="req-text-gh">
                  {" "}
                  Request ID :{" "}
                  <Link
                    // to="/my-request"
                    to={{
                      pathname: "/my-request",
                      state: {
                        requestId: requestId,
                        activeTab: (props && props?.location && props?.location?.state && props?.location?.state?.activeTab) || "ongoing",
                        requestDetails: (props && props?.location && props?.location?.state && props?.location?.state?.pageData) || {},
                        // activeTab: "ongoing"
                      },
                    }}
                    className="orange-text"
                  >
                    {requestId || ""}
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>

      <section className="requestDetail-section">
        <div className="container">
          <div className="row">
          <div className={`col-xl-4 ${ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div className="ardCat-list wow fadeInUp">
                <Scrollbars renderThumbVertical={renderThumb}>
                  {pageData.length
                    ? pageData.map((data, index) => {
                        return (
                          <div
                            className="ard-cat-item hnc-item service-cat-item"
                            style={{ width: "96%" }}
                            onClick={() => {
                              setQuoteData(data)
                              setOngoingCardShow(true)
                              setIndexs(index)
                            }}
                          >
                            <div
                              className={`service-cat-inner myRq-link ${
                                data.id === quoteData.id ? "open-quote" : ""
                              }`}
                            >
                              <div className="sc-head">
                                <div className="left">
                                  <img
                                    // src="../images/user/user-3.jpg"
                                    src={
                                      config.endpoints.image_path +
                                      data?.provider?.profile_photo
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="right">
                                  <h3>
                                    {" "}
                                    {data?.provider?.first_name}{" "}
                                    {data?.provider?.last_name}{" "}
                                  </h3>
                                  <div className="rating">
                                  <Rating rating={data?.provider?.rating} />
                                  <span className="rating-count"> {data?.provider?.rating}{" "}
                                  </span>
                                  </div>
                                </div>
                              </div>

                              <div className="sc-body">
                                <div className="scb-top">
                                  <span className="service-type">
                                    {data?.quotestatus?.name}
                                  </span>
                                </div>
                                <div className="scb-bottom">
                                  <span className="sc-name">
                                    $
                                    {/* {(data?.uplifted_labor_quote_amount ||
                                      0) +
                                      (data?.uplifted_material_quote_amount ||
                                        0)} */}
                                        {data?.cust_total_quote_amount || 0}
                                  </span>
                                  {/* <span> {data.created_at} </span> */}
                                  <span>
                                    {data?.quote_service_date
                                      ? dateWithFormate(
                                          data?.quote_service_date
                                            .split("T")[0]
                                            .split("-")
                                            .reverse()
                                            .join()
                                        )
                                      : null}{" "}
                                    &nbsp;
                                    {dateWithFormate(
                                      quoteData?.timeGet?.start_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}{" "}
                                    -{" "}
                                    {dateWithFormate(
                                      quoteData?.timeGet?.end_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "No Data"}
                </Scrollbars>
              </div>
            </div>

            <div className={`col-xl-8 ${!ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div
                id="quoteDetailsTab-1"
                className="myRq-tab-content current-request"
              >
                <div className="ardCat-detail-right">
                  <div className="ardCatD-head">
                  <div className="ardCat-back-block d-block d-xl-none">
                              <button type="button" className="ardCat-back-btn" 
                               onClick={()=>{setOngoingCardShow(false) 
                               }} >
                                {" "}
                                <i className="fal fa-long-arrow-left"></i>{" "}
                              </button>
                            </div>
                  
                    <div className="left">
                      <h2> Quote ID : {quoteData?.id} </h2>
                      <span> {quoteData?.quotestatus?.name} </span>
                    </div>
                    <div className="right">
                    <Link
                            to={{
                              pathname: "/service-provider-details/" + quoteData?.provider?.id ,
                              //   state:{
                              //       // filterSrs: filterSrs,
                              //       // completeFilterData: completeFilterData
                              //       serviceType:serviceType,
                              //       serviceSubCat:serviceSubCat,
                              //       serviceCat:serviceCat
                              //     }
                            }}
                            // className="service-cat-inner"
                            data-rel="grd-1"
                          >
                      <div className="img">
                        <img
                          src={
                            config.endpoints.image_path +
                            quoteData?.provider?.profile_photo
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="ardCatuser-d">
                        <h3>
                          {" "}
                          {quoteData?.provider?.first_name}{" "}
                          {quoteData?.provider?.last_name}{" "}
                        </h3>
                        <div className="rating">
                                  <Rating rating={quoteData?.provider?.rating} />
                                  <span className="rating-count">{quoteData?.provider?.rating}{" "} </span>

                                  </div>
                      </div>
                      </Link>
                        </div>
                  </div>

                 
                  <div className="ardCatD-body">
                    <div className="ardCatD-inner-body">
                      <div className="ardCatD-location-time">
                        <div className="ardCatD-lt-inner">
                          {/* {quoteData?.quotestatus?.id === 4 ?
                            <>
                              <div className="catD-lt-item">
                                <div className="it-content">
                                  <span className="itl"> Withdrawal Reason </span>
                                  <span className="itv">
                                    {" "}
                                    {quoteData?.quote_withdrawal_reason_id}{" "}
                                  </span>
                                </div>
                              </div>

                              <div className="catD-lt-item">
                                <div className="it-content">
                                  <span className="itl"> Withdrawal Comment </span>
                                  <div className="">
                                    {" "}
                                    {quoteData?.quote_withdrawal_comments}{" "}
                                  </div>
                                </div>
                              </div>
                            </> :
                            null
                          } */}
                          <div className="catD-lt-item">
                            <div className="icon">
                              <img
                                src="../images/quote-icon.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="it-content">
                              <span className="itl"> Quote Amount </span>
                              <span className="itv">
                                $
                                {/* {(quoteData?.uplifted_labor_quote_amount || 0) +
                                  (quoteData?.uplifted_material_quote_amount ||
                                    0)} */}
                                    {quoteData?.cust_total_quote_amount || 0}
                              </span>
                            </div>
                          </div>
                          <div className="catD-lt-item">
                            <div className="icon">
                              <img
                                src="../images/calendar-big-icon.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="it-content">
                              <span className="itl">
                                {" "}
                                Service Date and Time{" "}
                              </span>
                              <span className="itv white-space-nowrap">
                                {" "}
                                {/* {quoteData?.created_at} */}
                                {quoteData?.quote_service_date
                                  ? dateWithFormate(
                                      quoteData?.quote_service_date
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join()
                                    )
                                  : null}
                                &nbsp;(
                                {dateWithFormate(
                                  quoteData?.timeGet?.start_time,
                                  "hA",
                                  "HH:mm:ss"
                                )}{" "}
                                -{" "}
                                {dateWithFormate(
                                  quoteData?.timeGet?.end_time,
                                  "hA",
                                  "HH:mm:ss"
                                )}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="ardCatD-it-action text-right">
                          {quoteData ?  
                          ((props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 15 && (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 2 && (props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) !== 9) ?
                           renderButtons(quoteData) : null : null}
                        </div>
                      </div>
                      <div className="ardCatD-right-space">
                        <div className="ardCatD-content">
                          <h3> Material Included </h3>
                          <p>
                            {" "}
                            {quoteData.material_included_flag
                              // ? `$${quoteData?.material_quote_amount}`
                          ? "Yes"
                              : "No"}{" "}
                          </p>
                        </div>

                        <div className="ardCatD-content">
                          <h3> Services Included </h3>
                          <p>{quoteData?.services_included}</p>
                        </div>
                        {quoteData?.warranty_days ? 
                        <div className="ardCatD-content">
                          <h3> Warranty Days Provided </h3>
                          <p>{quoteData?.warranty_days}</p>
                        </div>
                        :
                        null}
                        {quoteData?.additional_comments ? 
                        <div className="ardCatD-content">
                          <h3> Additional Comments </h3>
                          <p>{quoteData?.additional_comments}</p>
                        </div>
                        :
                        null}
                       

                        <div className="ardCatD-content">
                          <h3> Comments </h3>

                          {quoteData.quotecomment
                            ? quoteData.quotecomment.length
                              ? quoteData.quotecomment.map((comment, index) => {
                                  return (
                                    <div className="ardComment-item mt-2" key={index}>
                                      <p>{comment.comment}</p>
                                      <div className="ardc-bottom mt-0">
                                        <span>
                                          {" "}
                                          <i className="far fa-user"></i>
                                          {/* {"John"}{" "} */}
                                          {comment?.user}
                                        </span>
                                        <span>
                                          {" "}
                                          <i className="far fa-clock"></i>{" "}
                                          {/* {comment.created_at} */}
                                          {dateWithFormate(
                                            comment.created_at.split(" ")[1],
                                            "hh:mm A",
                                            "HH:mm:ss"
                                          )}
                                          ,
                                          {dateWithFormate(
                                            comment.created_at.split(" ")[0].split("-").reverse().join()
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })
                              : null
                            : null}
                        </div>

                        {quoteData?.quotestatus?.id === 4 ? (
                        <>
                          <div className="ardCatD-content">
                              <h3> Withdrawal Reason </h3>
                              <p>
                              {withdrawalReason.length > 0 && quoteData?.quote_withdrawal_reason_id && (withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)] !== undefined || withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)] !== null) ? 
                                withdrawalReason[(parseInt(quoteData?.quote_withdrawal_reason_id) - 1)].name || "--"
                              
                              :
                              null
                              }
                                {/* {quoteData?.quote_withdrawal_reason_id || "--"} */}
                              </p>
                            
                          </div>
                          <div className="ardCatD-content">
                              <h3> Withdrawal Comment</h3>
                              <p>
                                {quoteData?.quote_withdrawal_comments || "--"}
                              </p>
                            </div>
                        
                          </>
                      ) : null}

                        {quoteData?.quotestatus?.id === 6 ?
                          <div className="ardCatD-content">
                            <h3> Disable Comments </h3>
                            <p>{(quoteData && quoteData?.spnoshow && quoteData?.spnoshow.no_show_reason) || ""}</p>
                          </div>
                          :
                          null
                        }

                        {quoteData?.quotestatus?.id === 5 ?
                          <>
                          <div className="ardCatD-content">
                            <h3> Rejection Reason </h3>
                            <p>{(quoteData && quoteData?.rejection && quoteData?.rejection.name) || ""}</p>
                          </div>
                           <div className="ardCatD-content">
                           <h3> Rejection Comments </h3>
                           <p>{(quoteData && quoteData?.quote_rejection_comments) || ""}</p>
                         </div>
                        </>
                          :
                          null
                        }

                      </div>

                      {userDetail.role_type === 1 ||
                      userDetail.role_type === 3 ? (
                        [1, 2, 3, 5].includes(quoteData.quote_status) && ![15,2,9].includes(props && props?.location && props?.location?.state && props?.location?.state?.pageData && props?.location?.state?.pageData?.sr_status) ? (
                          <div class="ardCat-widget">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#addComment-modal"
                            >
                              <img
                                src="../images/comment-icon.svg"
                                class="img-fluid"
                                alt=""
                              />
                              <span> comment </span>
                            </a>
                            {/* <a href="my-chat.html">
                              <img
                                src="../images/chat-icon.svg"
                                class="img-fluid"
                                alt=""
                              />
                              <span> chat </span>
                            </a> */}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </>
  );
};

export default QuotesDetailsClose;
