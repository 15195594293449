import React from 'react';
import { useEffect } from 'react';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import MyAccountMenu from '../Components/Common/MyAccountMenu';
import images from '../Utility/Images';
import { APIService } from "../Components/Apis/APIServices";
import { useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars";
import { dateWithFormate } from '../Utility/common';
import GetInTouch from '../Components/Common/GetInTouch';

const Notifications = (props) => {

    const [notifications, setNotifications] = useState([])
    const [selectedNotification, setSelectedNotification] = useState([])
    
    useEffect(() => {
     getNotifications()
    }, []);

    const getNotifications = ()=>{
      APIService.notifications()
      .then((res) => {
        if(res.status === 200){
          setNotifications(res.data.reverse())
          setSelectedNotification(res.data[0] || []);
        }
         
          // console.log(
          //   "eeeeeeeeeeeeeeeeee  ",
          //   res.data.filter(
          //     (ele) => ele.id === props.location.state?.notificationId
          //   ), selectedNotification
          // );
        
      }
          )
      .catch((err) => { });

    }
  
  useEffect(() => { 
if (
  props.location.state?.notificationId !== undefined &&
  props.location.state?.notificationId !== null
) {
  setSelectedNotification(
    notifications.filter((ele) => ele.id === props.location.state?.notificationId)[0] ||
      []
  );
  // setOngoingCardShow(true)


  // setNotifications((prev) => {
  //   return [
  //     selectedNotification,
  //     ...prev.filter((a)=> a !== selectedNotification)
  //   ]
  // })
} else {
  setSelectedNotification(notifications[0] || []);
}
  },[props.location.state, notifications])

  useEffect(()=>{
    if (
      props.location.state?.notificationId !== undefined &&
      props.location.state?.notificationId !== null
    ) {
    setOngoingCardShow(true)
    }else{
      setOngoingCardShow(false)
    }
  },[props.location.state])

    document.body.classList.add('dark-header');



     const renderThumb = ({ style, ...props }) => {
       const thumbStyle = {
         borderRadius: 6,
         backgroundColor: "#FF8805",
       };
       return <div style={{ ...style, ...thumbStyle }} {...props} />;
     };

     const deleteNotification = (e, id) =>{
      e.stopPropagation();
      // console.log("wwwwwwwwwwwwwww", id)
      setOngoingCardShow(false)
      APIService.notificationsDelete({id: id})
      .then((res) => {
        getNotifications()
      }
          )
      .catch((err) => { });

     }

    //  console.log("wwwwwwwwwwwwwwwww", notifications.filter((a)=> a !== selectedNotification), selectedNotification)

    ////responsive

    const [ongoingCardShow, setOngoingCardShow] = useState(false)


    return (
      <>
        <Header />
        <section className="gray-hero-section db-gh-section">
          <div className="gh-clip-bg">
            <img
              src={images.clipBg}
              className="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="inner-caption wow fadeInUp text-center">
                  <h1 className="mb-0"> Notifications </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="db-top-menu">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <MyAccountMenu />
              </div>
            </div>
          </div>
        </section>

        <section className={`notifyPage-section ardCat-tab-wrap ${ongoingCardShow ? "ardCat-show": ""}`}>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 ardCat-col-box">
                <div className="notifyPage-list mCustomScrollbar">
                  {/* <div className="notifyPage-item"> */}
                  {/* <a href={void(0)} className="myRq-link active-np">
                                        <div className="np-icon">
                                            <span> <i className="fas fa-bell"></i> </span>
                                        </div>
                                        <div className="np-content">
                                            <p> <strong> Congratulations! </strong> The service provider has completed your service request.</p>
                                            <span className="np-time"> Just Now </span>

                                            <button type="button" className="np-delete"><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </a> */}

                  <Scrollbars renderThumbVertical={renderThumb}>
                    {notifications.length > 0 &&
                      // [selectedNotification ,...notifications.filter((a)=> a !== selectedNotification)].map((sr) => (
                        notifications.map((sr) => (
                        <div
                          className="notifyPage-item"
                          style={{ width: "96%", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedNotification(sr);
                            setOngoingCardShow(true)
                          }}
                        >
                          <a
                            href={void 0}
                            className={`myRq-link ${
                              sr.id === selectedNotification.id
                                ? "active-np"
                                : null
                            }`}
                          >
                            <div className="np-icon">
                              <span>
                                {" "}
                                <i className="fas fa-bell"></i>{" "}
                              </span>
                            </div>
                            <div className="np-content">
                              <p>
                                {" "}
                                {sr?.description && sr?.description.includes("Congratulations!") ? 
                                <>
                                <strong>
                                  {" "}
                                  {sr?.description?.split("!")[0]}!{" "}
                                </strong>{" "}
                                {sr?.description
                                  ?.split("!")[1]
                                  ?.substring(0, 45)}
                                ...
                                </>
                                :
                                <>
                                {sr?.description?.substring(0, 70)}
                                ...
                                </>

}
                              </p>
                              <span className="np-time">
                                {" "}
                                {dateWithFormate(sr?.updated_at && sr?.updated_at.split(" ")[0].split("-").reverse().join("-"))}{" "}
                                &nbsp;
                                {dateWithFormate(sr?.updated_at && sr?.updated_at.split(" ")[1],
                                  "h:mm A",
                                  "HH:mm:ss")
                                }    
                              </span>

                              <button type="button" className="np-delete" onClick={(e)=>{
                                // setOngoingCardShow(false) 
                                deleteNotification(e, sr?.id)
                              }}>
                                <i className="fal fa-trash-alt" 
                                // onClick={()=>setOngoingCardShow(false)}
                                ></i>
                              </button>
                            </div>
                          </a>
                        </div>
                      ))}
                  </Scrollbars>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-xl-8 ardCat-content-wrap">
                <div className="cmr-form-block mt-0">
                  <div className="ardCat-back-block d-block d-xl-none">
                    <button type="button" className="ardCat-back-btn"
                    onClick={()=>{setOngoingCardShow(false) 
                    }}>
                      {" "}
                      <i className="fal fa-long-arrow-left"></i>{" "}
                    </button>
                  </div>

                  <div className="np-right-content text-center w-100">
                    <div className="npr-icon">
                      <img src={images.check} className="img-fluid" alt="" />
                    </div>
                    <div className="npr-bottom">
                      <h2>
                      {selectedNotification?.description && selectedNotification?.description.includes("Congratulations!") ?
                        <>Congratulations!</>
                        :
                        selectedNotification?.description && selectedNotification?.description.includes("Unfortunately,") ?
                        <>Sorry!</>
                        :

                        <>Alert!</>}
                      </h2>
                                        <p> {selectedNotification?.description} </p>
                                        {/* <p> {selectedNotification?.description.charAt(0).toUpperCase() + selectedNotification?.description.slice(1)} </p> */}

                      {/* <a
                        href="accept-completion.html"
                        className="btn btn-red notify-ac-btn"
                      >
                        {" "}
                        Accept Completion{" "}
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="getInTouch-section">
          <div className="container">
            <div className="col-12">
              <div className="getInTouch-box">
                <div className="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div className="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i className="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i className="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />
        <Footer />
      </>
    );
}

export default Notifications;
