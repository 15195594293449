import React, { useState, useEffect } from 'react';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import images from "../Utility/Images";
import { APIService } from "../Components/Apis/APIServices";
import { Link } from 'react-router-dom'

import Rating from "./Rating";
import config from "../Components/Apis/config";
import GetInTouch from '../Components/Common/GetInTouch';

const ServiceCategories = (props) => {
    const [filterModal, setFilterModal] = useState(false);
    

    const [currentRequest, setCurrentRequest] = useState({});
  const [err, setErr] = useState({});
  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [serviceCat, setServiceCat] = useState("");
  const [serviceSubCat, setServiceSubCat] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [allRequestFilter, setAllRequestFilter] = useState(false);

  // 
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("233");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("")
  const [requestId, setRequestId] = useState("");
  const [category, setCategory] = useState([]);
  const [cat, setCat] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCat, setSubCat] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [duration, setDuration] = useState("");
  const [rating, setRating] = useState("");
  // 

  const [originalData, setOriginalData] = useState([])
const [completeFilterData, setCompleteFilterData] = useState([])
  const [numberOfRecords, setNumberOfRecords] = useState(21)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  const [totalPageNo, setTotalPageNo] = useState(1)


 

  const [totalCount, setTotalCount] = useState(0)
  const [loader, setLoader] = useState(false)

  // console.log("rrrrrrrrrrrrrr", props.location.state)

  useEffect(()=>{
   getData()
  },[props.location.state])
  
  const getData = (e)=>{
    if(e ===undefined || e=== null){

    }else{
      e.preventDefault()
    }

    setLoader(true)
    
    APIService.findNow((props.location.state && props.location.state.searchItem) ||
    {
      service:"",
      cityState:""
    }
    ,
      {
        limit: numberOfRecords,
        page: currentPageNo
      },
      {
        category:serviceCat,
      subCategory: serviceSubCat,
      servicetype: serviceType
      }, 
      {
        country,
        city,
        state,
        rating,
        zipCode,
      })
    .then((res) => {
      setSrs(res.data.data);
      // setOriginalData(res.data.data)
      // setCompleteFilterData(res.data.data)
      // setFilterSrs(res.data.data.slice(0, numberOfRecords));
      // setTotalPageNo(Math.ceil(res.data.data.length/numberOfRecords))

      setTotalCount(res.data.total)

      setFilterModal(false)

      
      setTotalPageNo(Math.ceil(res.data.total/numberOfRecords) || 1)
      setLoader(false)
      
    })
    .catch((e) => {
      console.log("close error", e)
      setLoader(false)
    });
  }
 
  const handleServiceCat = (e) => {
    setServiceCat(e.target.value);
    setServiceSubCat("")
    setServiceType("")
    
    if (e.target.value === "") {
      // setCompleteFilterData(originalData)
      // setFilterSrs(originalData)

    } else { 
    setServiceSubCats(
      serviceCats.filter((sc) => sc.id == e.target.value)[0].subcategories
    );
    }
   
    //APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
  };

  

  const handleServiceSubCat = ({ target: { value } }) => {
    setServiceSubCat(value);
    setServiceType("")
    APIService.fetchAllServiceType({ sub_category_id: value })
      .then((res) => setServiceTypes(res.data.data))
      .catch((err) => setErr(err));
  };
  const [srs, setSrs] = useState([]);
  const [filterSrs, setFilterSrs] = useState([]);


  useEffect(() => {
    APIService.fetchAllCategory()
      .then((res) => {
        setServiceCats(res.data.data)
        setCategory(res.data.data)
      })
      .catch((err) => setErr(err));
  

    APIService.fetchCountry()
      .then((res) => setCountries(res.data.data))
      .catch((err) => setErr(err));
    
    
     
  

  }, []);

  const handleCountry = (e) => {
    setCountry(e.target.value);
    APIService.fetchState({ country_id: e.target.value })
      .then((res) => setStates(res.data.data))
      .catch((err) => setErr(err));
  };
  const handleState = (e) => {
    setState(e.target.value);
    APIService.fetchCity({ state_id: e.target.value })
      .then((res) => setCities(res.data.data))
      .catch((err) => setErr(err));
  };

  const handleZipCode = (e) =>{
    let name = e.target.name
    let value = e.target.value
    // if(name === "zipCode" && (zipCode.length === 4 && value.length === 5)){
    //     value+='-'
    // }else if(name === "zip_code" && value.length === 6){
    //     value = value.replace('-','')
    // }

    setZipCode(value)
    // if(value.length <= 10){
    //     console.log(value)
    // dispatch({ type: name, payload: { [name]: value, key: name } })
    // }
  }

  const changeRecords = (e) => { 
    setNumberOfRecords(e.target.value)
    setCurrentPageNo(1)

  }

 
  

  const takeToPrevious = () => { 
    setCurrentPageNo(currentPageNo - 1)
   
  }

  const takeToNext = () => { 
    setCurrentPageNo(currentPageNo + 1)
  }
  
  useEffect(()=>{
    getData()
  },[currentPageNo, totalPageNo, numberOfRecords])

  useEffect(()=>{
    APIService.fetchState({ country_id: "233" })
    .then((res) => setStates(res.data.data.sort((a, b) => a.name.localeCompare(b.name))))
    .catch((err) => console.log(err));
  
  },[])

    return (
      <>
        <Header />
        <section className="inner-banner-section serviceCat-banner-wrap">
          <div className="circle-box slideInUp"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="inner-caption wow fadeInUp text-center">
                  <h1>
                    {" "}
                    Service <span> Professionals </span>{" "}
                  </h1>
                  <p>
                    {" "}
                    A wide range of skilled and experienced service professionals are available in your area and willing to work on your requests.{" "}
                    <br className="d-none d-md-block" /> Let's explore their profiles.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-filter-section">
          <div className="wp-clip-bg">
            <img
              src={images.clipBg}
              className="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div className="container">
            <form className="row align-items-end">
              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Categories </label>
                  <select
                    value={serviceCat}
                    className="form-control"
                    onChange={handleServiceCat}
                  >
                    <option value="">Select the service category</option>
                    {serviceCats.map((sc) => (
                      <option value={sc.id}>{sc.category_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Sub-Categories </label>
                  <select
                    value={serviceSubCat}
                    className="form-control"
                    onChange={handleServiceSubCat}
                  >
                    <option value="">Select the service sub-category</option>
                    {serviceSubCats.map((sc) => (
                      <option value={sc.id}>{sc.sub_category_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Type </label>
                  <select
                    value={serviceType}
                    className="form-control"
                    onChange={(e) => setServiceType(e.target.value)}
                  >
                    <option value="">Select the service type</option>
                    {serviceTypes.map((st) => (
                      <option value={st.id}>{st.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="searchAction-block form-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-red"
                    onClick={getData}
                  >
                    {" "}
                    Search{" "}
                  </button>
                  <button
                    type="button"
                    className="search-filter-btn btn btn-orange"
                    id="catFilter-btn"
                    onClick={() => setFilterModal(!filterModal)}
                  >
                    {" "}
                    <img
                      src={images.filterIcon}
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>

        <section
          className={`catOpen-filter-section serviceCat-filter-block ${
            filterModal ? "open" : ""
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cat-open-filter">
                  <div className="cof-inner">
                    <div className="cof-close-block">
                      <button
                        className="cofClose-btn"
                        onClick={() => setFilterModal(false)}
                        type="button"
                      >
                        {" "}
                        <i className="fal fa-times"></i>
                      </button>
                    </div>

                    <form className="row align-items-end" onSubmit={(e)=>getData(e)}>
                      <div className="form-group col-md-4">
                        <label> Country </label>
                        <select className="form-control" 
                        name='country'
                        // onChange={handleCountry}
                        value={country}
                        // value="233"
                        disabled
                        >
                        <option disabled={true} selected={true} value="">  Country </option>
                        {countries.map((res) =>
                            <option key={res.id} value={res.id} selected={state.country == res.id ? true : false}> {res.name} </option>
                        )}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> State </label>
                        <select className="form-control"
                        name="state" 
                        onChange={handleState}
                        value={state}
                        >
                        <option value=""> State </option>
                        {states.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> City </label>
                        <select className="form-control"
                        name="city"
                        onChange={(e)=>{
                          setCity(e.target.value)
                        }}
                        value={city}
                        >
                        <option value=""> City </option>
                        {cities.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> Zip Code </label>
                        <input type="text" 
                          className="holer-control form-control" 
                          onChange={handleZipCode} 
                          name="zipCode" 
                          value={zipCode}  
                          pattern="[0-9]*"
                          title="Zip code must contain numbers only"
                          maxLength="5"
                          placeholder='Zip-Code'
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label> Rating </label>
                        <select 
                        className="form-control"
                        name="rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=""> Rating </option>
                          <option value="1"> 1 &#9733; & above </option>
                          <option value="2"> 2 &#9733; & above </option>
                          <option value="3"> 3 &#9733; & above </option>
                          <option value="4"> 4 &#9733; & above </option>
                          <option value="5"> 5 &#9733; </option>
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label> Distance </label>
                        <select className="form-control" disabled={true}>
                          <option> All </option>
                          {/* <option> 10 Miles </option>
                          <option> 20 Miles </option>
                          <option> 30 Miles </option>
                          <option> 50 Miles </option> */}
                        </select>
                      </div>

                      <div className="form-group col-12 mt-3 mb-0">
                        <div className="cof-submit-group text-center">
                        <button
                            type="button"
                            className="btn btn-black btn-sm"
                            onClick={() => {
                              setCountry("")
                              setState("")
                              setCity("")
                              setZipCode("")
                              setRating("")
                            }}
                          >
                            {" "}
                            Clear{" "}
                          </button>
                          <button
                            type="button"
                            className="btn btn-black btn-sm ml-3"
                            onClick={() => setFilterModal(false)}
                          >
                            {" "}
                            Cancel{" "}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-red btn-sm ml-3 mt-2 mt-md-0"
                            // onClick={getData}
                          >
                            {" "}
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-categories-section pad-t-100 pad-b-50">
          <div className="container">
            <div className="row">
              <div className="col-12">
              {
              loader ? 
              <div className='d-flex justify-content-center mb-3'>
                <i className="fa fa-spinner" aria-hidden="true"></i>
              </div>

              :
              srs.length <= 0 ? 
            <div className="d-flex justify-content-center mb-5">
              No matching records found
            </div> 
            :
                <div className="service-cat-list">
                  {srs.length > 0 &&
                    srs.map((sr)=>(
                      <div className="service-cat-item wow fadeInUp delay-2s">
                    <Link
                      to={{
                        pathname: "/service-provider-details/" + sr?.id,
                        //   state:{
                        //       // filterSrs: filterSrs,
                        //       // completeFilterData: completeFilterData
                        //       serviceType:serviceType,
                        //       serviceSubCat:serviceSubCat,
                        //       serviceCat:serviceCat
                        //     }
                      }}
                      // className="service-cat-inner"
                      data-rel="grd-1"
                    >
                      <div className="service-cat-inner h-100">
                        <div className="sc-head">
                          <div className="left">
                            <a href="/service-provider-details">
                              <img
                                src={
                                  config.endpoints.image_path +
                                  sr?.profile_photo
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="right">
                            <h3>
                              {" "}
                              <a href="/service-provider-details">
                                {" "}
                                {sr?.first_name || ""}  {sr?.last_name || ""}{" "}
                              </a>{" "}
                            </h3>
                            <div className="rating">
                            <Rating rating={sr?.rating} />
                                        <span className="rating-count">
                                        {sr?.rating}
                                        </span>
                            </div>
                            <span className="scr-location">
                              {" "}
                              {`${sr?.city || ""}, ${sr?.state || ""}`.substring(0, 25)}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="sc-body">
                          <div className="scb-top">
                            <span className="service-type">
                              {" "}
                             {sr?.service || ""}{" "}
                            </span>
                            <span className="experience"> {sr?.year_experience || ""} Year </span>
                          </div>
                          <div className="scb-bottom">
                            <span> {sr?.warranty || ""} Days Labor Warranty </span>
                          </div>
                          <a
                            href="/service-provider-details"
                            className="btn btn-red btn-md"
                          >
                            {" "}
                            View Profile{" "}
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                    )) 
                  }
                  
                </div>
}
              </div>

              <div className="col-12">
              <div className="e4-pagination">
                <div className="e4p-left">
                  <ul className="pagination">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo !== 1) {
                            takeToPrevious();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo} of {totalPageNo}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo === currentPageNo ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo !== currentPageNo) {
                            takeToNext();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="e4p-right">
                  <div className="e4p-group">
                    <span> Number of Records </span>
                    <select
                      className="form-control"
                      name="numberOfRecords"
                      value={numberOfRecords}
                      onChange={(e) => {
                        changeRecords(e);
                      }}
                    >
                      <option> 21 </option>
                      <option> 30 </option>
                      <option> 39 </option>
                      <option> 48 </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
        </section>

        {/* <section className="getInTouch-section">
          <div className="container">
            <div className="col-12">
              <div className="getInTouch-box">
                <div className="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div className="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i className="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i className="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />
        <Footer />
      </>
    );
}

export default ServiceCategories;
