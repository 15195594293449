import React, {useState, useEffect} from 'react';
import { APIService } from '../Components/Apis/APIServices';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import Dialogue from "../Components/Common/Dialogue";
import GetInTouch from '../Components/Common/GetInTouch';

const Feedback = () => {

    const [ratingData, setRatingData] = useState({
        "over_all_rating": 0,
    "ease_of_use": 0,
    "performance": 0,
    "app_experience": 0,
    "look_feel_experience": 0,
    "overall_service_provider_experience": 0,
    "overall_customer_experience": 0,
    "comment": ""
    })

    const ratingArr = [1,2,3,4,5]

    useEffect(()=>{
        APIService.getUserFeedBacks()
        .then((res)=>{
            // console.log("tttttttsssssss", res.data.feedback)
            setRatingData((prev) =>{
                return({...prev, ...res.data.feedback})
            })
        })
        .catch((err)=>{})
    },[])

    const setRating = (rating, name)=>{
        setRatingData((prev) =>{
            return ({
                ...prev,
                [name]: rating
            })
        })
    }
    const [dialogueState, setDialogueState] = useState({
        type: "",
        show: false,
        message: "",
      });
    
    const sendFeedbackData = (  )=>{
       
      
        APIService.submitUserFeedBacks({ratingData})
        .then((res)=>{
          console.log("asdasdsad", res)
          if((res && res.status === 200) || (res && res.status === 201)){
               setDialogueState({
                type: "Success",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
                message:`Thanks for submitting your feedback. The eZunction team will review and consider your feedback for enhancing the platform.`,
                link : 'my-request'
              });
            }else{
              setDialogueState({
                type: "Error",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
                // message:`Congratulations! You have successfully Submitted your feedback.`,
                message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
             
              });
            }
        })
        .catch((err)=>{
          // console.log("sadasdasdasd", err)
               setDialogueState({
                type: "Error",
                show: true,
                // message: `Thank you for accepting the quote ${quoteData.id} for your service request ${requestId}. Please wait for your service provider to confirm.`,
                // message:`Some error occurred`,
                message : (err && err.response && err.response.data && err.response.data.message) || ""
               
              });
        })
    }


    return (
        <>
         <Dialogue state={dialogueState} setState={setDialogueState} />
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> eZunction <span> Feedback </span> </h1>

                                <p> Your feedback is important to drive a  <br className="d-none d-md-block" /> high-performance platform. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="acceptCompletion-section ezuntionFeed-section">
                <div className="wp-clip-bg">
                    <img src="../images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="feedback-detail-head">
                                <h2> How can we <span> improve </span></h2>
                                <p> Your feedback is important for us to help us improve and provide exemplary services to our platform users and help enhance the overall <span> eZunction </span> experience. </p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="acs-left-block">
                                <form className="row" onSubmit={(e)=> sendFeedbackData(e)}>
                                    <div className="form-group col-lg-6">
                                        <label> Overall Rating </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                                {/* <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star"></i> */}

{ratingArr.map((rate) => {
                          if (rate <= ratingData.over_all_rating) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "over_all_rating")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "over_all_rating")}
                              ></i>
                            );
                          }
                        })}
                                            </div>

                                            <span className="orsp-text"> {ratingData.over_all_rating}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> Ease of Use </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                                {/* <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star star-fill"></i>
                                                <i className="fas fa-star"></i> */}
                                                {ratingArr.map((rate) => {
                          if (rate <= ratingData.ease_of_use) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "ease_of_use")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "ease_of_use")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.ease_of_use}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> Performance </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                            {ratingArr.map((rate) => {
                          if (rate <= ratingData.performance) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "performance")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "performance")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.performance}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> User Experience While Using the App </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                            {ratingArr.map((rate) => {
                          if (rate <= ratingData.app_experience) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "app_experience")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "app_experience")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.app_experience}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> Look and Feel </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                            {ratingArr.map((rate) => {
                          if (rate <= ratingData.look_feel_experience) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "look_feel_experience")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "look_feel_experience")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.look_feel_experience}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> Overall Service Provider Experience </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                            {ratingArr.map((rate) => {
                          if (rate <= ratingData.overall_service_provider_experience) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "overall_service_provider_experience")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "overall_service_provider_experience")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.overall_service_provider_experience}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label> Overall Customer Experience </label>
                                        <div className="rsp-star-block">
                                            <div className="star-box">
                                            {ratingArr.map((rate) => {
                          if (rate <= ratingData.overall_customer_experience) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate, "overall_customer_experience")}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate, "overall_customer_experience")}
                              ></i>
                            );
                          }
                        })}

                                            </div>

                                            <span className="orsp-text"> {ratingData.overall_customer_experience}/5 </span>
                                        </div>
                                    </div>

                                    <div className="form-group col-12">
                                        <label> Comments </label>
                                        <textarea 
                                        className="form-control resize-none" 
                                        rows="6" 
                                        placeholder="Provide any additional feedback/features that you would love to see on this app." 
                                        value={ratingData.comment} 
                                        maxLength={100}
                                        onChange={(e)=> setRatingData((prev)=>{
                                            return({...prev, comment: e.target.value})
                                        })}
                                        />
 <span class="textarea-limit">
                      {" "}
                      {100 - (ratingData && ratingData.comment && ratingData.comment.length) || 0} Characters Limit{" "}
                    </span>
                                        
                                    </div>

                                    <div className="formSubmit-ac col-12">
                                        <button type="button" className="btn btn-red btn-md" onClick={()=>{sendFeedbackData()}}> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default Feedback;
