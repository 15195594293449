import React, {useState, useEffect} from 'react';
import { APIService } from "../../Components/Apis/APIServices";

const GetInTouch = () => {

    const [links, setLinks] = useState([])

    useEffect(()=>{
        APIService.socialLinks()
        .then((response) => {
          if (response.status === 200) {
            // console.log("rerwerewqqweqweqwe", response)
            setLinks(response.data.contents || [])
          }
        })
        .catch((error) => console.log(error));
    
    },[])

    
    return (
        <>
        {links.length > 0 ? 
            <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                
                            {links.map((item, index)=>(
                                <a key={index} href={item.video_src} target="_blank"> 
                                    <i className={`fab fa-${item.label.charAt(0).toLowerCase() + item.label.slice(1)}`}></i> 
                                </a>
                                
                            ))}


                                
                                {/* <a href="https://www.facebook.com/ezunction/" target="_blank"><i className={`fab fa-facebook`}></i> </a>
                                <a href="https://twitter.com/ezunction" target="_blank"> <i className={`fab fa-twitter`}></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className={`fab fa-instagram`}></i> </a>
                                <a href="https://www.youtube.com/channel/UCjnyaCCh7RY6CUCnW0AZXTg" target="_blank"> <i className={`fab fa-youtube`}></i> </a> */}
                                
                                
                                
                                {/* <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a> */}
                                {/* <a href="#foo"> <i className="fab fa-flickr"></i> </a> */}
                                {/* <a href="https://www.flickr.com/" target="_blank" > <i className="fab fa-flickr"></i> </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            :
            null}
        </>
    );
}

export default GetInTouch;
