// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import images from "../../Utility/Images";
// import { Scrollbars } from "react-custom-scrollbars";
// import { APIService } from "../../Components/Apis/APIServices";



// import { dateWithFormate } from '../../Utility/common';

// const CustomerRequest = (props) => {


//   const [transDetails, setTransDetails] = useState({});
//   const [filterUI, setFilterUI] = useState(false);
  
  
//   const [pageData, setPageData] = useState("");


//   const [filterData, setFilterData] = useState({
//     cat:"",
//     minPrice:"",
//     maxPrice:"",
//     serviceDateStart:"",
//     serviceDateEnd:"",
//     requestId:"",
//     transactionDateStart:"",
//     transactionDateEnd:"",
//     quoteId:""
//   });





//   //////////////////////////////////////////
//   const [transList, setTransList] = useState([])
//   const [category, setCategory] = useState([]);

//   useEffect(() => { 
//     getTransactionData()

//         APIService.fetchAllCategory()
//         .then((res) => {
//           // setServiceCats(res.data.data)
//           setCategory(res.data.data)
//         })
//         .catch((err) => console.log(err));
// },[])

// const getTransactionData = ()=>{
//   APIService.transactionList(filterData)
//   .then((res) => {
//     // console.log(";;;;;;;;;;;;;;;;;;;;;;", res?.data);
//     // setTransList(res?.data.sort((a, b) => b.id - a.id));
//     setTransList(res?.data || []);
//     setFilterUI(false)
//   })
//   .catch((err) => console.log(err));
// }



 

//   useEffect(() => {
//      if (transList.length !== 0) {
//       const result = transList.filter(word => (word.role_type === 1 || word.role_type === 3) && !(word.invoice_id === null || word.invoice_id === undefined));
//       // console.log("tttttttttttttrrrrrrrrrr",result)
//        APIService.transactionCustomer(result && result[0]?.request_id)
//          .then((response) => {
//            //  console.log("hhhhhhhhhhhhhhhhhhh", response.data);
//            setTransDetails(response?.data);
//          })
//          .catch((err) => {});
//      }else{
//       setTransDetails([])
//      }
//   },[transList])

//   document.body.classList.add("dark-header");



//   useEffect(() => { 
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "smooth",
//     });
//   },[pageData])


//   const renderThumb = ({ style, ...props }) => {
//     const thumbStyle = {
//       borderRadius: 6,
//       backgroundColor: "#FF8805",
//     };
//     return <div style={{ ...style, ...thumbStyle }} {...props} />;
//   };

  

//     const getTransDetails = (id) => {
//       APIService.transactionCustomer(id)
//         .then((res) => {
//           // console.log(";;;;;;;;;;;;;;;;;;;;;;", res?.data);
//           setTransDetails(res?.data)
//           // setTransListData(res?.data.sort((a, b) => b.id - a.id));
//         })
//         .catch((err) => console.log(err));
//     };

//     const filterChange = (e)=>{
//       setFilterData({
//         ...filterData,
//         [e.target.name] : e.target.value
//       })
//     }



//   console.log("/////////////////////..............", filterUI);

//   return (
//     <>
//       <div className="container">
//         <div className="row position-relative">
//         <div className={`catOpen-filter-section transaction-filter-section ${filterUI ? "open" : ""}`}>
//               <div className="cat-open-filter">
//                 <div className="cof-inner">
//                   <div className="cof-close-block">
//                     <button className="cofClose-btn" type="button" 
//                     onClick={() => setFilterUI(!filterUI)}>
//                       {" "}
//                       <i className="fal fa-times"></i>
//                     </button>
//                   </div>

//                   <form>
//                     <div className="row align-items-end">
//                       <div
//                         className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
//                         id="datepicker003"
//                         data-target-input="nearest"
//                       >
//                         <label for=""> Service Date Start </label>
//                         <input
//                           type="date"
//                           className="form-control datepicker datetimepicker-input"
//                           data-target="#datepicker003"
//                           data-toggle="datetimepicker"
//                           placeholder="Start Date"
//                           name="serviceDateStart"
//                           value={filterData.serviceDateStart || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       <div
//                         className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
//                         id="datepicker004"
//                         data-target-input="nearest"
//                       >
//                         <label for=""> Service Date End </label>
//                         <input
//                           type="date"
//                           className="form-control datepicker datetimepicker-input"
//                           data-target="#datepicker004"
//                           data-toggle="datetimepicker"
//                           placeholder="End Date"
//                           name="serviceDateEnd"
//                           value={filterData.serviceDateEnd || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       <div className="form-group col-xl-4 col-md-6">
//                         <label> Request ID </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Enter the Request ID "
//                           name="requestId"
//                           value={filterData.requestId || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       <div
//                         className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
//                         id="datepicker005"
//                         data-target-input="nearest"
//                       >
//                         <label for=""> Transaction Date Start </label>
//                         <input
//                           type="date"
//                           className="form-control datepicker datetimepicker-input"
//                           data-target="#datepicker005"
//                           data-toggle="datetimepicker"
//                           placeholder="Start Date"
//                           name="transactionDateStart"
//                           value={filterData.transactionDateStart || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       <div
//                         className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
//                         id="datepicker006"
//                         data-target-input="nearest"
//                       >
//                         <label for=""> Transaction Date End </label>
//                         <input
//                           type="date"
//                           className="form-control datepicker datetimepicker-input"
//                           data-target="#datepicker006"
//                           data-toggle="datetimepicker"
//                           placeholder="End Date"
//                           name="transactionDateEnd"
//                           value={filterData.transactionDateEnd || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       <div className="form-group col-xl-4 col-md-6">
//                         <label> Quote ID </label>
//                         <input
//                           type="text"
//                           placeholder="Enter Quote ID"
//                           className="form-control"
//                           name="quoteId"
//                           value={filterData.quoteId || ""}
//                           onChange={(e)=> filterChange(e)}
//                         />
//                       </div>

//                       {/* <div className="form-group col-xl-4 col-md-6">
//                         <label> Transaction Type </label>
//                         <select className="form-control">
//                           <option> Credit/Debit Card </option>
//                         </select>
//                       </div> */}

                     
//                       <div className="form-group col-xl-4 col-md-6">
//                           <label> Service Category </label>
//                           <select
//                             value={filterData.cat}
//                             className="form-control"
//                             onChange={(e)=>filterChange(e)}
//                             name="cat"
//                           >
//                             <option value="">
//                               Select the service category
//                             </option>
//                             {category.map((sc) => (
//                               <option value={sc.id}>{sc.category_name}</option>
//                             ))}
//                           </select>
//                         </div>

//                       <div className="form-group col-xl-4 col-12">
//                         <label> Payout Amount Range </label>
//                         <div className="slider-range-group">
//                           <div id="slider-range"></div>
//                           <div className="slideRange-dots">
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                           </div>
//                           <div className="slider-drop">
//                             <div className="slideDrop-item">
//                               <select 
//                               className="form-control"
//                               onChange={(e)=>filterChange(e)}
//                               value={filterData.minPrice}
//                               name="minPrice"
//                               >
//                                 <option value=""> MIN </option>
//                                 <option value="0"> $0 </option>
//                                 <option value="100"> $100 </option>
//                                 <option value="200"> $200 </option>
//                                 <option value="300"> $300 </option>
//                                 <option value="400"> $400 </option>
//                                 <option value="500"> $500 </option>
//                               </select>
//                             </div>
//                             <span> to </span>
//                             <div className="slideDrop-item">
//                               <select 
//                               className="form-control"
//                               onChange={(e)=>filterChange(e)}
//                               value={filterData.maxPrice}
//                               name="maxPrice"
//                               >
//                                 <option value=""> MAX </option>
//                                 <option value="5000"> $5000 </option>
//                                 <option value="6000"> $6000 </option>
//                                 <option value="7000"> $7000 </option>
//                                 <option value="8000"> $8000 </option>
//                                 <option value="9000"> $9000 </option>
//                                 <option value="10000"> $10000 </option>
//                               </select>
//                             </div>
//                           </div>
//                           <div className="slider-labels">
//                             <div className="caption">
//                               <strong>Min:</strong>{" "}
//                               <span id="slider-range-value1"></span>
//                             </div>
//                             <div className="caption">
//                               <strong>Max:</strong>{" "}
//                               <span id="slider-range-value2"></span>
//                             </div>
//                           </div>
//                           <input type="hidden" name="min-value" value="" />
//                           <input type="hidden" name="max-value" value="" />
//                         </div>
//                       </div>
//                     </div>

//                     <div className="row ">
//                       <div className="form-group col-12 mt-3 mb-0">
//                         <div className="cof-submit-group text-center">
//                         <button
//                             type="button"
//                             className="btn btn-black btn-sm"
//                             onClick={() => {
//                              setFilterData({
//                               cat:"",
//                               minPrice:"",
//                               maxPrice:"",
//                               serviceDateStart:"",
//                               serviceDateEnd:"",
//                               requestId:"",
//                               transactionDateStart:"",
//                               transactionDateEnd:"",
//                               quoteId:""
//                              })
//                             }}
//                           >
//                             {" "}
//                             Clear{" "}
//                           </button>
//                           <button
//                               type="button"
//                               className="btn btn-black btn-sm ml-3"
//                               onClick={() => setFilterUI(false)}
//                             >
//                               {" "}
//                               Cancel{" "}
//                             </button>
//                             <button
//                               type="button"
//                               className="btn btn-red btn-sm ml-3"
//                               onClick={getTransactionData}
//                             >
//                               {" "}
//                               Apply{" "}
//                             </button>
//                         </div>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           <div className="col-xl-4 ardCat-col-box">
//             <div className="transaction-filter">
//               <span> Filter </span>
//               <button
//                 type="button"
//                 className="moreFilter-btn btn btn-orange"
//                 id="catFilter-btn"
//               >
//                 <img src={images.filterIcon} className="img-fluid" alt="" />
//               </button>
//             </div>

//             <div className="ardCat-list transaction-scroll-box mCustomScrollbar wow fadeInUp">
//               <Scrollbars renderThumbVertical={renderThumb}>
//                 {transList?.map((item, index) =>  {
//                     if((item?.role_type === 1 || item?.role_type === 3) && !(item?.invoice_id === null || item?.invoice_id === undefined)){
//                   return(
//                   <div
//                     className="ard-cat-item transaction-cat-item"
//                     style={{ width: "96%", cursor: "pointer" }}
//                     onClick={() => {
//                       getTransDetails(item?.request_id);
//                     }}
//                   >
//                     <div
//                       // to={"/request-detail/" + sr.id}
//                       className={`service-cat-inner myRq-link ${
//                         item?.request_id ===
//                         transDetails?.transaction?.request_id
//                           ? "open-request"
//                           : ""
//                       }`}
//                       data-rel="grd-1"
//                     >
//                       <div className="sc-body">
//                         <div className="scb-bottom tranCat-detail">
//                           <span className="sc-name f-24">
//                             {item?.request?.title}
//                           </span>
//                           <span> Request ID : {item?.request_id} </span>
//                           <div className="transaction-bottom-block">
//                             <span>
//                               {" "}
//                               {/* Tue, November 28, 2020{" "} */}
//                               {dateWithFormate(
//                                 item?.created_at && item?.created_at
//                                   .split(" ")[0]
//                                   .split("-")
//                                   .reverse()
//                                   .join("-")
//                               )}{" "}
//                             </span>
//                             <span className="price-box">
//                               {" "}
//                               ${item?.invoice_amount}{" "}
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )
//               }else{
//                 return null
//               }
//             }
                
//                 )}
//               </Scrollbars>
//             </div>
//           </div>

//           <div className="col-xl-8 ardCat-content-wrap">
//             <div
//               id="transactionTab-1"
//               className="myRq-tab-content current-request"
//             >
//               <div className="showTransaction-amount">
//                 <div className="showTransaction-left">
//                   <span className="text-red balAmount-text">
//                     {" "}
//                     Balance Amount{" "}
//                   </span>
//                 </div>
//                 <div className="showTransaction-right">
//                   <h5 className="text-red"> $1500.00 </h5>
//                 </div>
//               </div>
//               <div className="ardCat-detail-right">
//                 <div className="ardCatD-head align-items-end">
//                   <div className="ardCat-back-block d-block d-xl-none">
//                     <button type="button" className="ardCat-back-btn">
//                       {" "}
//                       <i className="fal fa-long-arrow-left"></i>{" "}
//                     </button>
//                   </div>
//                   <div className="left">
//                     <h2>
//                       {" "}
//                       Request ID : {transDetails?.transaction?.request_id}{" "}
//                     </h2>
//                     <span> Moving Services </span>
//                   </div>
//                 </div>

//                 <div className="ardCatD-body">
//                   <div className="ardCatD-inner-body">
//                     <div className="ardCatD-point-list">
//                       <div className="catPoint-item mt-0">
//                         <span> Invoice Amount </span>
//                         <span>
//                           {" "}
//                           ${transDetails?.transaction?.invoice_amount}{" "}
//                         </span>
//                       </div>

//                       <div className="catPoint-item mt-md-0">
//                         <span> Service Date </span>
//                         <span>
//                           {" "}
//                           {dateWithFormate(
//                             transDetails?.service_request?.service_date
//                           )}{" "}
//                         </span>
//                       </div>

//                       <div className="catPoint-item mt-md-0">
//                         <span> Transaction Date </span>
//                         <span>
//                           {" "}
//                           {dateWithFormate(
//                             transDetails?.transaction && transDetails?.transaction?.created_at && transDetails?.transaction?.created_at
//                               .split(" ")[0]
//                               .split("-")
//                               .reverse()
//                               .join("-")
//                           )}{" "}
//                         </span>
//                       </div>

//                       {/* <div className="catPoint-item">
//                         <span> Transaction Type </span>
//                         <span> Credit Debit Card </span>
//                       </div> */}

//                       <div className="catPoint-item">
//                         <span> Payment Method </span>
//                         {/* <span> {transDetails?.transaction?.quote_id} </span> */}
//                         <span>Credit card -7887</span>
//                       </div>

//                       <div className="catPoint-item">
//                         <span> Transaction ID </span>
//                         <span> {transDetails?.transaction?.id} </span>
//                       </div>

//                       <div className="catPoint-item">
//                         <span> Quote ID </span>
//                         <span> {transDetails?.transaction?.quote_id} </span>
//                       </div>
//                     </div>

//                     <div className="transaction-invoice-book">
//                       <div className="tib-left">
//                         <span className="tib-label"> Invoice </span>
//                         {/* <a
//                           href={`/customer_invoice/${transDetails?.transaction?.request_id}`}
//                           className="updatePDF-down"
//                         > */}
//                         <Link
//                           to={{
//                             pathname: "/customer_invoice",
//                             state: {
//                               srId: transDetails?.transaction?.request_id,
//                               srInvoiceAmount: transDetails?.transaction?.invoice_amount || 0,
//                             },
//                           }}
//                           className="service-cat-inner"
//                           data-rel="grd-1"
//                         >
//                           <img
//                             src={images.pdfIcon}
//                             className="img-fluid"
//                             alt=""
//                             title=""
//                           />
//                         </Link>
//                         {/* </a> */}
//                       </div>

//                       {/* <div className="tib-right">
//                         <a href="" className="btn btn-red btn-lg">
//                           {" "}
//                           Download Invoice{" "}
//                         </a>
//                       </div> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CustomerRequest;

import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import MyAccountMenu from "../../Components/Common/MyAccountMenu";
import images from "../../Utility/Images";
import { Scrollbars } from "react-custom-scrollbars";
import { APIService } from "../../Components/Apis/APIServices";
import NotificationModal from "../../Components/Modals/NotificationModal";
import AnotherVisitModal from "../../Components/Modals/AnotherVisitModal";
import ClosedWarrantyModal from "../../Components/Modals/ClosedWarrantyModal";
import NoShowModal from "../../Components/Modals/NoShowModal";
import { dateWithFormate } from "../../Utility/common";

import Dialogue from "../../Components/Common/Dialogue";
import MultiRangeSlider from "../../Components/Common/Slider";



export default function CustomerTransaction(props) {

  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });



  const [transDetails, setTransDetails] = useState({})
  

  const [filterUI, setfilterUI] = useState(false);

  const history = useHistory();

  const [filterData, setFilterData] = useState({
    cat:"",
    minPrice:"",
    maxPrice:"",
    serviceDateStart:"",
    serviceDateEnd:"",
    requestId:"",
    transactionDateStart:"",
    transactionDateEnd:"",
    quoteId:""
  });

  
  

    //////////////////////////////////////////
    const [transList, setTransList] = useState([])
    
    const [category, setCategory] = useState([]);

    const [currentPageNo, setCurrentPageNo] = useState(1)
    const [totalPageNo, setTotalPageNo ] = useState(1)

    const [min, setMin] = useState(0)
    const [max, setMax] = useState(10000)

    const [ongoingCardShow, setOngoingCardShow] = useState(false)

    // console.log("asdasdasdasd", min,max)

    const useDidMountEffect = (func, deps) => {
      const didMount = useRef(false);
  
      useEffect(() => {
          if (didMount.current) func();
          else didMount.current = true;
      }, deps);
  }

  useDidMountEffect(()=>{
    setFilterData((prev) =>{
      return ({...prev, minPrice: min, maxPrice:max})
    })
  },[max, min])



    useEffect(() => { 
      getTransactionData()

        APIService.fetchAllCategory()
        .then((res) => {
          // setServiceCats(res.data.data)
          setCategory(res.data.data)
        })
        .catch((err) => console.log(err));
  },[])

  const getTransactionData = (e)=>{
    if(e === undefined || e === null){

    }else{
      e.preventDefault()
    }
    
    APIService.transactionList(filterData, currentPageNo)
    .then((res) => {
      // console.log("tttttttttttrrrrrrrrrrrr res", res)
      if(res.status === 200){
        // setTransList(res?.data.sort((a, b) => b.id - a.id));
        setTransList(res?.data?.data || []);
        setfilterUI(false)

        // setTotalCount(res.data.total)
 
     
       setTotalPageNo(Math.ceil(res.data.total/10) || 1)
      }
      
    })
    .catch((err) => {
      // console.log("tttttttttttrrrrrrrrrrrr err", err)
      setDialogueState({
        type: "Failed",
        show: true,
        message: (err && err.response && err.response.data && err.response.data.message) || "",
      });
    });
  }

  

//   useEffect(() => {
   
//     if (transList?.length !== 0) { 
//  APIService.transactionSP(transList[0]?.id)
//    .then((res) => {
//      console.log(";;;;;;;;;;;;;;;;;;;;;;", res?.data);
//      // setTransListData(res?.data.sort((a, b) => b.id - a.id));
//    })
//    .catch((err) => console.log(err));
//     }
   
//   }, [transList]);

 







  document.body.classList.add("dark-header");

 



   useEffect(() => {
     if (transList.length !== 0) {
      const result = transList.filter(word => (word.role_type === 1 || word.role_type === 3) && !(word.invoice_id === null || word.invoice_id === undefined));
      if(result.length !== 0){ 
        APIService.transactionCustomer(result && result[0]?.request_id, result && result[0]?.quote_id)
          .then((response) => {
            //  console.log("hhhhhhhhhhhhhhhhhhh", response.data);
            setTransDetails(response?.data);
          })
          .catch((err) => {});
        }
     }else{
      setTransDetails([])
     }
   }, [transList]);



   
   const renderThumb = ({ style, ...props }) => {
     const thumbStyle = {
       borderRadius: 6,
       backgroundColor: "#FF8805",
     };
     return <div style={{ ...style, ...thumbStyle }} {...props} />;
   };
  
  const getTransDetails = (request_id, quote_id) => { 
    APIService.transactionCustomer(request_id, quote_id)
      .then((res) => {
        // console.log(";;;;;;;;;;;;;;;;;;;;;;", res?.data);
        // setTransListData(res?.data.sort((a, b) => b.id - a.id));
        setTransDetails(res?.data)
      })
      .catch((err) => console.log(err));
  }

  // console.log("hhhhhhhhhhhhhhhhhhhhhhhhh", transDetails);

  const filterChange = (e)=>{
    setFilterData({
      ...filterData,
      [e.target.name] : e.target.value
    })
  }


  const takeToPrevious = () => { 
    setCurrentPageNo(currentPageNo - 1)
   
  }

  const takeToNext = () => { 
    setCurrentPageNo(currentPageNo + 1)
  }

  useDidMountEffect(()=>{
    getTransactionData()
  },[currentPageNo])
  

  // console.log("rrrtttttttttttttttt",filterData )

  /////////////////////////////// date compare in filter

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }


  const [serviceEndDateMin, setServiceEndDateMin] = useState(getTodaysDate())
  const [serviceStartDateMax, setServiceStartDateMax] = useState(getTodaysDate())

  useDidMountEffect(()=>{
    if(filterData.serviceDateStart === ""){
      setServiceEndDateMin(getTodaysDate())
    }else{
      setServiceEndDateMin(filterData.serviceDateStart)
    }
    
  },[filterData.serviceDateStart])

  useDidMountEffect(()=>{
    if(filterData.serviceDateEnd === ""){
      setServiceStartDateMax(getTodaysDate())
    }else{
      setServiceStartDateMax(filterData.serviceDateEnd)
    }
    
  },[filterData.serviceDateEnd])

  //////////////////////////trans dates

  const [transEndDateMin, setTransEndDateMin] = useState(getTodaysDate())
  const [transStartDateMax, setTransStartDateMax] = useState(getTodaysDate())

  useDidMountEffect(()=>{
    if(filterData.transactionDateStart === ""){
      setTransEndDateMin(getTodaysDate())
    }else{
      setTransEndDateMin(filterData.transactionDateStart)
    }
    
  },[filterData.transactionDateStart])

  useDidMountEffect(()=>{
    if(filterData.transactionDateEnd === ""){
      setTransStartDateMax(getTodaysDate())
    }else{
      setTransStartDateMax(filterData.transactionDateEnd)
    }
    
  },[filterData.transactionDateEnd])


  // console.log("adsasdasdasdasd", serviceEndDateMin, serviceStartDateMax)


  return (
    <>    
<Dialogue state={dialogueState} setState={setDialogueState} />
      
      <div className="tab-pane active" id="gh-customer-tab" role="tabpanel">
        <div className="container">
          <div className="row position-relative">
            <div className={`catOpen-filter-section transaction-filter-section ${filterUI ? "open" : ""}`}>
              <div className="cat-open-filter">
                <div className="cof-inner">
                  <div className="cof-close-block">
                    <button className="cofClose-btn" type="button" 
                    onClick={() => setfilterUI(!filterUI)}>
                      {" "}
                      <i className="fal fa-times"></i>
                    </button>
                  </div>

                  <form onSubmit={(e)=>{getTransactionData(e)}}>
                    <div className="row align-items-end">
                      <div
                        className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                        id="datepicker003"
                        data-target-input="nearest"
                      >
                        <label for=""> Service Date : Start Range </label>
                        <input
                          type="date"
                          className="form-control"
                          data-target="#datepicker003"
                          data-toggle="datetimepicker"
                          placeholder="Start Date"
                          name="serviceDateStart"
                          value={filterData.serviceDateStart || ""}
                          onChange={(e)=> filterChange(e)}
                          max={serviceStartDateMax}
                        />
                      </div>

                      <div
                        className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                        id="datepicker004"
                        data-target-input="nearest"
                      >
                        <label for=""> Service Date : End Range </label>
                        <input
                          type="date"
                          className="form-control"
                          data-target="#datepicker004"
                          data-toggle="datetimepicker"
                          placeholder="End Date"
                          name="serviceDateEnd"
                          value={filterData.serviceDateEnd || ""}
                          onChange={(e)=> filterChange(e)}
                          min={serviceEndDateMin === getTodaysDate() ? "" : serviceEndDateMin}
                          max={getTodaysDate()}
                        />
                      </div>

                      <div className="form-group col-xl-4 col-md-6">
                        <label> Request ID </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the Request ID "
                          name="requestId"
                          value={filterData.requestId || ""}
                          onChange={(e)=> filterChange(e)}
                        />
                      </div>

                      <div
                        className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                        id="datepicker005"
                        data-target-input="nearest"
                      >
                        <label for=""> Transaction Date : Start Range </label>
                        <input
                          type="date"
                          className="form-control"
                          data-target="#datepicker005"
                          data-toggle="datetimepicker"
                          placeholder="Start Date"
                          name="transactionDateStart"
                          value={filterData.transactionDateStart || ""}
                          onChange={(e)=> filterChange(e)}
                          max={transStartDateMax}
                        />
                      </div>

                      <div
                        className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                        id="datepicker006"
                        data-target-input="nearest"
                      >
                        <label for=""> Transaction Date : End Range </label>
                        <input
                          type="date"
                          className="form-control"
                          data-target="#datepicker006"
                          data-toggle="datetimepicker"
                          placeholder="End Date"
                          name="transactionDateEnd"
                          value={filterData.transactionDateEnd || ""}
                          onChange={(e)=> filterChange(e)}
                          min={transEndDateMin === getTodaysDate() ? "" : transEndDateMin}
                          max={getTodaysDate()}
                        />
                      </div>

                      <div className="form-group col-xl-4 col-md-6">
                        <label> Quote ID </label>
                        <input
                          type="text"
                          placeholder="Enter Quote ID"
                          className="form-control"
                          name="quoteId"
                          value={filterData.quoteId || ""}
                          onChange={(e)=> filterChange(e)}
                        />
                      </div>

                      {/* <div className="form-group col-xl-4 col-md-6">
                        <label> Transaction Type </label>
                        <select className="form-control">
                          <option> Credit/Debit Card </option>
                        </select>
                      </div> */}

                     
                      <div className="form-group col-xl-4 col-md-6">
                          <label> Service Category </label>
                          <select
                            value={filterData.cat}
                            className="form-control"
                            onChange={(e)=>filterChange(e)}
                            name="cat"
                          >
                            <option value="">
                              Select the service category
                            </option>
                            {category.map((sc) => (
                              <option value={sc.id}>{sc.category_name}</option>
                            ))}
                          </select>
                        </div>

                      <div className="form-group col-xl-4 col-12">
                        <label> Invoice Amount Range </label>
                        <div className="slider-range-group">
                        <MultiRangeSlider
      min={-5000}
      max={5000}
      
      onChange={({ min, max }) => {
        console.log(`min = ${min}, max = ${max}`)
        setMin(min)
        setMax(max)
      }}
      />
                          <div id="slider-range"></div>
                          <div className="slideRange-dots">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <div className="slider-labels">
                            <div className="caption">
                              <strong>Min: </strong>${min}{" "}
                              <span id="slider-range-value1"></span>
                            </div>
                            <div className="caption">
                              <strong>Max: </strong>${max}{" "}
                              <span id="slider-range-value2"></span>
                            </div>
                          </div>
                          {/* <div className="slider-drop">
                            <div className="slideDrop-item">
                              <select 
                              className="form-control"
                              onChange={(e)=>filterChange(e)}
                              value={filterData.minPrice}
                              name="minPrice"
                              >
                                <option value=""> MIN </option>
                                <option value="0"> $0 </option>
                                <option value="100"> $100 </option>
                                <option value="200"> $200 </option>
                                <option value="300"> $300 </option>
                                <option value="400"> $400 </option>
                                <option value="500"> $500 </option>
                              </select>
                            </div>
                            <span> to </span>
                            <div className="slideDrop-item">
                              <select 
                              className="form-control"
                              onChange={(e)=>filterChange(e)}
                              value={filterData.maxPrice}
                              name="maxPrice"
                              >
                                <option value=""> MAX </option>
                                <option value="5000"> $5000 </option>
                                <option value="6000"> $6000 </option>
                                <option value="7000"> $7000 </option>
                                <option value="8000"> $8000 </option>
                                <option value="9000"> $9000 </option>
                                <option value="10000"> $10000 </option>
                              </select>
                            </div>
                          </div> */}

<div className="slider-drop mt-3">
                          <div className="d-flex align-items-center mr-1">$</div>
                            <div className="slideDrop-item">
                              <input
                              type="text" 
                              className="form-control"
                              onChange={(e)=>filterChange(e)}
                              value={filterData.minPrice}
                              name="minPrice"
                              placeholder="MIN"
                              // min="-100"
                              // max="100"
                              // pattern="[0-9]{10}"
                              pattern="^[\-]?[0-9]{0,9}([\.][0-9][0-9])?$"
                              title="Contains only numeric value upto 2 decimal places"
                              maxLength="10"
                              />
                                {/* <option value=""> MIN </option>
                                <option value="0"> $0 </option>
                                <option value="100"> $100 </option>
                                <option value="200"> $200 </option>
                                <option value="300"> $300 </option>
                                <option value="400"> $400 </option>
                                <option value="500"> $500 </option>
                              </select> */}
                            </div>

                            <span> to </span>
                            <div className="d-flex align-items-center mr-1">$</div>
                            <div className="slideDrop-item">
                              <input 
                              type="text"
                              className="form-control"
                              onChange={(e)=>filterChange(e)}
                              value={filterData.maxPrice}
                              name="maxPrice"
                              placeholder="MAX"
                              // min="-100"
                              // max="100"
                              // pattern="[0-9]{10}"
                              pattern="^[\-]?[0-9]{0,9}([\.][0-9][0-9])?$"
                              title="Contains only numeric value upto 2 decimal places"
                              maxLength="10"
                              />
                                {/* <option value=""> MAX </option>
                                <option value="5000"> $5000 </option>
                                <option value="6000"> $6000 </option>
                                <option value="7000"> $7000 </option>
                                <option value="8000"> $8000 </option>
                                <option value="9000"> $9000 </option>
                                <option value="10000"> $10000 </option>
                              </select> */}
                            </div>
                            
                          </div>
                          {/* <div className="slider-labels">
                            <div className="caption">
                              <strong>Min:</strong>{" "}
                              <span id="slider-range-value1"></span>
                            </div>
                            <div className="caption">
                              <strong>Max:</strong>{" "}
                              <span id="slider-range-value2"></span>
                            </div>
                          </div> */}
                          {/* <input type="hidden" name="min-value" value="" />
                          <input type="hidden" name="max-value" value="" /> */}
                          <div>
                          {/* <MultiRangeSlider
      min={0}
      max={10000}
      // onChange={({ min, max }) => {
      //   setFilterData((prev) =>{
      //     return {...prev, minPrice: min, maxPrice: max}
      //   })
      // }}
      onChange={({ min, max }) => {
        console.log(`min = ${min}, max = ${max}`)
        setMin(min)
        setMax(max)
      }}
    
    /> */}
    </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="form-group col-12 mt-3 mb-0">
                        <div className="cof-submit-group text-center">
                        <button
                            type="button"
                            className="btn btn-black btn-sm"
                            onClick={() => {
                             setFilterData({
                              cat:"",
                              minPrice:"",
                              maxPrice:"",
                              serviceDateStart:"",
                              serviceDateEnd:"",
                              requestId:"",
                              transactionDateStart:"",
                              transactionDateEnd:"",
                              quoteId:""
                             })
                            }}
                          >
                            {" "}
                            Clear{" "}
                          </button>
                          <button
                              type="button"
                              className="btn btn-black btn-sm ml-3"
                              onClick={() => setfilterUI(false)}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-red btn-sm ml-3 mt-2 mt-md-0"
                              // onClick={getTransactionData}
                            >
                              {" "}
                              Apply{" "}
                            </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={`col-xl-4 ardCat-col-box ${ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div className={`transaction-filter`}>
                <span> Filter </span>
                <button
                  type="button"
                  className="moreFilter-btn btn btn-orange"
                  id="catFilter-btn"
                  onClick={() => setfilterUI(!filterUI)}
                >
                  <img
                    src="../images/filter-icon.svg"
                    className="img-fluid"
                    alt=""
                  />
                </button>
              </div>

              <div className="ardCat-list transaction-scroll-box custom-scroll wow fadeInUp mCustomScrollbar">
                <Scrollbars renderThumbVertical={renderThumb}>
                  {transList.length > 0 && transList?.map((item, index) => {
                    if((item?.role_type === 1 || item?.role_type === 3) && !(item?.invoice_id === null || item?.invoice_id === undefined)){
                  return (
                    <div
                      className="ard-cat-item transaction-cat-item"
                      style={{ width: "96%", cursor: "pointer" }}
                      onClick={() => {
                        getTransDetails(item?.request_id, item?.quote_id);
                        setOngoingCardShow(true)
                      }}
                    >
                      <div
                        // to={"/request-detail/" + sr.id}
                        className={`service-cat-inner myRq-link ${
                          String(item.request_id) ===
                          String(transDetails?.transaction?.request_id)
                            ? "open-request"
                            : ""
                        }`}
                        data-rel="grd-1"
                      >
                        <div className="sc-body">
                          <div className="scb-bottom tranCat-detail">
                            <span className="sc-name f-24">
                              {item?.request?.title}
                            </span>
                            {/* <span> Transaction ID : {item?.id} </span> */}
                            <span> SR ID : {item?.request_id} </span>
                            <div className="transaction-bottom-block">
                              <span>
                                {" "}
                                {/* Tue, November 28, 2020{" "} */}
                                {dateWithFormate(
                                  item?.created_at && item?.created_at
                                    .split(" ")[0]
                                    .split("-")
                                    .reverse()
                                    .join("-")
                                )}{" "}
                              </span>
                              <span className="price-box">
                                {" "}
                                ${item?.invoice_amount}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                                }
                                else{
                                  return null
                                }
                                })}

                                {transList.length === 0 ? "No records found" : null}
                </Scrollbars>

                <div className="e4p-left">
                  <ul className="pagination d-flex justify-content-center">
                    <li>
                      <div
                        href="#"
                        className={`prev ${
                          currentPageNo === 1 ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (currentPageNo !== 1) {
                            takeToPrevious();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left"></i> Prev
                      </div>
                    </li>
                    <div>
                      {currentPageNo} of {totalPageNo}
                    </div>

                    <li>
                      <div
                        href="#"
                        className={`next ${
                          totalPageNo === currentPageNo ? "disabled" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (totalPageNo !== currentPageNo) {
                            takeToNext();
                          }
                        }}
                      >
                        Next <i className="fal fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={`col-xl-8 ardCat-content-wrap ${!ongoingCardShow && window.screen.width <= 1200 ? "d-none": ""}`}>
              <div
                id="transactionTab-1"
                className="myRq-tab-content current-request"
              >
                <div className="showTransaction-amount">
                  <div className="showTransaction-left">
                    <span className="text-red balAmount-text">
                      {" "}
                      Balance Amount{" "}
                    </span>
                  </div>
                  <div className="showTransaction-right">
                    <h5 className="text-red"> $ {props.accountBalance || "0.00"} </h5>
                  </div>
                </div>
                <div className="ardCat-detail-right">
                  <div className="ardCatD-head align-items-end">
                    <div className="ardCat-back-block d-block d-xl-none">
                      <button type="button" className="ardCat-back-btn"
                      onClick={()=>{setOngoingCardShow(false) 
                      }} >
                        {" "}
                        <i className="fal fa-long-arrow-left"></i>{" "}
                      </button>
                    </div>
                    <div className="left">
                      <h2>
                        {" "}
                        Request ID : {
                          transDetails?.transaction?.request_id
                        }{" "}
                      </h2>
                      <span> {transDetails?.service_request?.category_id ? category.filter((sr) => sr.id === transDetails?.service_request?.category_id)[0] && category.filter((sr) => sr.id === transDetails?.service_request?.category_id)[0].category_name : ""} Services </span>
                    </div>
                  </div>

                  <div className="ardCatD-body">
                    <div className="ardCatD-inner-body">
                      <div className="ardCatD-point-list">
                        <div className="catPoint-item mt-0">
                          <span> Invoice Amount </span>
                          <span>
                            {" "}
                            ${transDetails?.transaction?.invoice_amount}{" "}
                          </span>
                        </div>

                        <div className="catPoint-item mt-md-0">
                          <span> Service Date </span>
                          <span>
                            {" "}
                            {dateWithFormate(
                              transDetails?.service_request?.service_date
                            )}{" "}
                          </span>
                        </div>

                        <div className="catPoint-item mt-md-0">
                          <span> Transaction Date </span>
                          <span>
                            {" "}
                            {dateWithFormate(
                              transDetails?.transaction && transDetails?.transaction?.created_at && transDetails?.transaction?.created_at
                                .split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("-")
                            )}{" "}
                          </span>
                        </div>

                        {/* <div className="catPoint-item">
                          <span> Transaction Type </span>
                          <span> Credit Debit Card </span>
                        </div> */}

                        {/* <div className="catPoint-item">
                          <span> Payment Method </span>
                         
                          <span>Credit card -7887</span>
                        </div> */}

                        <div className="catPoint-item">
                          <span> Transaction ID </span>
                          <span> {transDetails?.transaction?.id} </span>
                        </div>

                        <div className="catPoint-item">
                          <span> Quote ID </span>
                          <span> {transDetails?.transaction?.quote_id} </span>
                        </div>
                      </div>

                      <div className="transaction-invoice-book">
                        <div className="tib-left">
                          <span className="tib-label"> Invoice </span>
                          {/* <a
                            href="/sp_invoice"
                            className="updatePDF-down"
                            target="_blank"
                          > */}
                          <Link
                            to={{
                              pathname: "/customer_invoice",
                              state: {
                                srId: (transDetails?.transaction && transDetails?.transaction?.request_id) || "",
                                srInvoiceAmount: transDetails?.transaction?.invoice_amount || 0,
                                srQuoteId: (transDetails?.transaction && transDetails?.transaction?.quote_id) || ""
                                // srInvoiceAmount: transDetails?.sp_payout?.payout_amount || 0,
                              },
                            }}
                            className="service-cat-inner"
                            data-rel="grd-1"
                          >
                            <img
                              src="../images/pdf-icon.svg"
                              className="img-fluid"
                              alt=""
                              title=""
                            />
                          </Link>
                          {/* </a> */}
                        </div>

                        {/* <div className="tib-right">
                          <a
                            // href="../invoice-pdf/customer-invoice.html"
                            target="_blank"
                            className="btn btn-red btn-lg"
                            onClick={() => {
                              getInvoice();
                            }}
                          >
                            {" "}
                            Download Payout Advice{" "}
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
