const initialState={
    categories:[],
    serviceRequests:[]
}
const  HomeReducer =(state, { type, payload }) => {
    switch (type) {
    case "FETCH_CATEGORY":
        return { ...state, categories:payload }
    case "FETCH_SERVICE_REQUEST":
        return { ...state, serviceRequests:payload }
    default:
        return state
    }
}
export default HomeReducer;
export {initialState};