import React, { useEffect, useReducer } from 'react';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import images from '../Utility/Images';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import $ from 'jquery';
import ServiceCategoriesSlider from './Home/ServiceCategoriesSlider';
import PeopleVoiceSlider from './Home/PeopleVoiceSlider';
import AdvertiseSection from './Home/AdvertiseSection';
import PeopleVoiceSection from './Home/PeopleVoiceSection';
import OurFeatureSection from './Home/OurFeatureSection';
import GetInTouch from '../Components/Common/GetInTouch';
import WhatHappeningSection from './Home/WhatHappeningSection';
import ServiceCategorySection from './Home/ServiceCategorySection';
import MarketPlaceSection from './Home/MarketPlaceSection';
import HowItWorkSection from './Home/HowItWorkSection';
import AboutUsSection from './Home/AboutUsSection';
import HomeContext from './Home/HomeContext/HomeContext';
import HomeReducer, { initialState } from './Home/HomeReducer/HomeReducer';
import { APIService } from '../Components/Apis/APIServices';
const Home = () => {
const [state, dispatch] = useReducer(HomeReducer, initialState);
	useEffect(() => {
		fetchCategories();
		fetchServiceRequests()
	}, [])

const fetchCategories=()=>{
	APIService.fetchAllCategory().then((response)=>{
		// console.log(response);
		if(response && response.data && response.data.status==200){
			dispatch({type:'FETCH_CATEGORY',payload:response.data.data})
		}else{
			//alert(response.data.message);
		}
	})
	.catch((err)=>{})
}
const fetchServiceRequests=()=>{
	APIService.fetchAllServiceRequests()
	.then((response)=>{
		// console.log(response);
		if(response.status==200){
			// console.log(response.data)
			dispatch({type:'FETCH_SERVICE_REQUEST',payload:response.data.data})
		}else{
			//alert(response.data.message);
		}
	})
	.catch((err)=>{})
}


	let texwrite;
	const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

	async function writingAll(stringTarget, container) {
		texwrite = document.querySelector('[' + container + ']');
		const stringsContainer = document.getElementsByClassName(stringTarget)

		while (texwrite) {
			for (let i = 0; i < stringsContainer.length; i++) {
				const string = stringsContainer[i].textContent
				await write(string)
				await sleep(1000)
				await erase()
				await sleep(1000)
			};
		}
	};

	async function write(text) {
		let index = 0
		while (index < text.length) {
			const timeout = 100
			await sleep(timeout)
			index++
			texwrite.innerHTML = text.substring(0, index)
		}
	};


	async function erase() {
		while (texwrite.textContent.length) {
			const timeout = 100
			await sleep(timeout)
			texwrite.textContent = texwrite.textContent.substring(0, texwrite.textContent.length - 2)
		}
	};

	//writingAll('item', 'data-text');
	const data=[state,dispatch];
	return (
		<>
		<HomeContext.Provider value={data}>
			<Header />
			<div className="body-container">
				<MarketPlaceSection />
				<ServiceCategorySection />
				<AdvertiseSection />
				<WhatHappeningSection />
				<HowItWorkSection />
				<AboutUsSection/>
				<PeopleVoiceSection />
				<OurFeatureSection />
				<GetInTouch />
			</div>
			<Footer />
		</HomeContext.Provider>
		</>
	);
}

export default Home;