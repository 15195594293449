import React, { useRef, useEffect, useState,useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Routes from './Routes/Routes';
import './assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/fontawesome.min.css';  
import './assets/css/style.css';  
import './assets/css/auth.css';  
import './assets/css/animate.css';  
import './assets/css/head.css';   
import './assets/css/responsive.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './assets/css/slick.css';
// import './assets/css/jquery.mCustomScrollbar.css';
import { ProtectedRoute } from './Routes/Protected/ProtectedRoutes';
import HomeContext from './Pages/Home/HomeContext/HomeContext'
import HomeReducer,{initialState} from './Pages/Home/HomeReducer/HomeReducer'
// import $ from 'jquery';
// import IdleTimer from 'react-idle-timer'


document.title="eZunction";
 const App=(props) =>{ 
	const [state, dispatch] = useReducer(HomeReducer, initialState);
	const data = [state, dispatch]
	// const history = useHistory();

	console.log("weqeqweqweqwe", props)
	
	 useEffect(() => {
		// console.log("/////////////////////////////  ")
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		  }
	},[window.location.path])

	// const idleTimerRef = useRef(null)
	// const reloadWindow = () =>{
	// 	// window.location.reload()
	// 	// history.push("/")
	// }
	return(
		<>
		{/* <IdleTimer
			ref={idleTimerRef}
			// timeout={180*1000}
			timeout={500}
			onIdle={()=>reloadWindow()}
		></IdleTimer> */}
	<Router basename={process.env.REACT_APP_BASENAME || ''}>
			<Switch>
				{Routes.map((route, index) => {
					return (
						<ProtectedRoute
							key={index}
							path={route.path}
							exact={route.exact}
							component={route.component}
						/>
						// <Route exact path="/" component={Menu1} />
						
					);
				})}
			</Switch>
	</Router>
	</>
);
}
export default App;