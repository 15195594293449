import React, { useEffect, useState } from 'react';
import ServiceCategoriesSlider from './ServiceCategoriesSlider';

const ServiceCategorySection = () => {

	// const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")

	useEffect(()=>{
		var target = document.querySelector('.service-cat-section');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target.getBoundingClientRect().top) {
			// console.log('I have been reached');
			// setHideOrShow("visible")
			setAnimatedName("fadeInUp")
		  }
		})
	},[])

    return (
        <>
          <section className="service-cat-section pad-b-50">
		<div className="container">
			<div className="row">
				<div className="col-12 wow fadeInUp animated" style={{ animationName: animatedName}}>
					<div className="section-title">
						<h2> Service <span className="text-orange font-weight-bold"> Categories </span></h2>
					</div>
					<div className="section-des">
						<p> Select your need from our wide range of services </p>
					</div>
				</div>

				<div className="col-12 wow fadeInUp animated" style={{ animationName: animatedName}}>
					<div className="serviceCat-slider">
						<ServiceCategoriesSlider/>
					</div>
				</div>
			</div>
		</div>
	</section>  
        </>
    );
}

export default ServiceCategorySection;