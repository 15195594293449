import React, { useEffect, useState } from 'react';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import images from '../Utility/Images';
import { Link } from "react-router-dom";
import GetInTouch from '../Components/Common/GetInTouch';

import { APIService } from "../Components/Apis/APIServices";
import config from "../Components/Apis/config";

const StartEarning = () => {

    // const [hideOrShow1, setHideOrShow1] = useState("hidden")
    const [hideOrShow2, setHideOrShow2] = useState("hidden")
    const [hideOrShow3, setHideOrShow3] = useState("hidden")
    const [hideOrShow4, setHideOrShow4] = useState("hidden")
	const [animatedName1, setAnimatedName1] = useState("none")
    const [animatedName2, setAnimatedName2] = useState("none")
    
    const [animatedName3, setAnimatedName3] = useState("none")
    const [animatedName4, setAnimatedName4] = useState("none")

    const [animatedName5, setAnimatedName5] = useState("none")
    const [animatedName6, setAnimatedName6] = useState("none")
    
    const [animatedName7, setAnimatedName7] = useState("none")

    const [startEarningNowVideo, setStartEarningNowVideo] = useState("")

	useEffect(()=>{
        var target1 = document.querySelector('.first-block');
        var target2 = document.querySelector('.second-block');
        var target3 = document.querySelector('.third-block');
        var target4 = document.querySelector('.video-list-section');

		document.addEventListener('scroll', () => {
		  if (window.scrollY >= target1.getBoundingClientRect().top) {
			// console.log('I have been reached');
			// setHideOrShow1("visible")
              setAnimatedName1("slideInLeft")
              setAnimatedName2("slideInRight")
          }
            
            if (window.scrollY >= target2.getBoundingClientRect().top) {
			// console.log('I have been reached');
			setHideOrShow2("visible")
              setAnimatedName3("slideInLeft")
              setAnimatedName4("slideInRight")
            }
            
            if (window.scrollY >= target3.getBoundingClientRect().top) {
			// console.log('I have been reached');
			setHideOrShow3("visible")
              setAnimatedName5("slideInLeft")
              setAnimatedName6("slideInRight")
            }
            
            if (window.scrollY >= target4.getBoundingClientRect().top) {
			// console.log('I have been reached');
			setHideOrShow4("visible")
              setAnimatedName7("zoomIn")
            //   setAnimatedName8("slideInRight")
		  }
		})

        
        APIService.startEarningNowVideo()
        .then((response) => {
          if (response.status === 200) {
            // console.log("rerwerewqqweqweqwe", response)
            setStartEarningNowVideo(response.data.contents[0] || "")
          }
        })
        .catch((error) => console.log(error));
    }, [])
    

    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp wow animated"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center animated">
                                <h1> Start <span> Earning Now </span> </h1>
                                <p> Be your boss. Come, join us and start earning right away!!  <br className="d-none d-md-block" /> It is a simple 3-step process. </p>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex w-100 justify-content-center mt-3 mb-3' style={{zIndex:"2", position: "relative"}}>
                
                <div>
                  <Link
                    to={{
                      pathname: "/create-my-request",
                      // state: { ...data },
                    }}
                    className="btn btn-red btn-md ardCat-submitQ-btn2"
                  >
                    Create My Request
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname: "/all-requests",
                      // state: { ...data },
                    }}
                    className="btn btn-red btn-md ardCat-submitQ-btn2 ml-3"
                  >
                    Submit My Quote
                  </Link>
                </div>
                </div>

                </div>
            </section>


            <section className="startEaring-tour-section">
                <div className="wp-clip-bg">
                    <img src={images.clipBg} className="img-fluid d-block m-auto" alt="" />
                </div>
                <div className="container">
                    <div className="row align-items-center startEaring-row pt-0">
                        {/* <!--<div className="col-12 wow slideInLeft">-->
				<!--	<div className="dotcircle-earn">-->
				<!--		<span></span>-->
				<!--	</div>-->
				<!--</div>--> */}
                        <div className="col-12 wow slideInLeft">
                            <div className="se-line se-top">
                                <img src={images.topLine} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 order-2 order-lg-1 first-block wow slideInLeft animated" style={{animationName: animatedName1}}>
                            <div className="startEaring-img">
                                <img src={images.browseReqGrap} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 order-1 order-lg-2 wow slideInRight animated" style={{animationName: animatedName2}}>
                            <div className="startEaring-content">
                                <div className="title">
                                    <h2> Step 1: Browse Requests  </h2>
                                </div>
                                <p> Browse all the requests to find requests that suit your skillset, experience, location and availability, by using the available filter criterion.  Select the request that you want to take up to submit your quote.  </p>
                            </div>
                        </div>

                        <div className="col-12 order-3 wow slideInRight">
                            <div className="se-line se-right">
                                <img src={images.rightLine} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center startEaring-row">
                        <div className="col-lg-6 wow second-block slideInLeft animated" style={{visibility: hideOrShow2, animationName: animatedName3}}>
                            <div className="startEaring-content">
                                <div className="title">
                                    <h2> Step 2: Submit a Quote </h2>
                                </div>

                                <p> Your quote can include labor and material costs, along with a description of the services that you will perform to complete the request. Ensure that you are providing all the needed details for the customer to evaluate your offer and make it accurate and competitive. The customer will review and accept your offer. </p>
                            </div>
                        </div>

                        <div className="col-lg-6 wow slideInRight animated" style={{visibility: hideOrShow2, animationName: animatedName4}}>
                            <div className="startEaring-img">
                                <img src={images.submitQuoteGrap} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-12 order-3 wow slideInLeft">
                            <div className="se-line se-left">
                                <img src={images.leftLine} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center startEaring-row">
                        <div className="col-lg-6 order-2 order-lg-1 third-block wow slideInLeft animated" style={{visibility: hideOrShow3, animationName: animatedName5}}>
                            <div className="startEaring-img">
                                <img src={images.completeReqGrap} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 order-1 order-lg-2 wow slideInRight animated" style={{visibility: hideOrShow3, animationName: animatedName6}}>
                            <div className="startEaring-content">
                                <div className="title">
                                    <h2> Step 3: Complete Request Nicely and Get Paid </h2>
                                </div>

                                <p> Once your quote is confirmed, complete the work as scheduled, ensuring full satisfaction of your customer. You can also comment on your quote to interact with the customer to update and reschedule your quote. Once completed make sure that you mark the request as complete and ask the customer to accept it’s completion and get paid instantaneously.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="enhanceProfile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="enhanceProfile-inner">
                                <div className="eps-wave">
                                    <img src={images.waveGrap} className="img-fluid" alt="" />
                                </div>
                                <div className="eps-left">
                                    <h2> Enhance Your <span> Profile </span></h2>
                                    <p> Your profile is your impression that the customers get for you. It is important for you as a service provider to maintain and update your profile accurately. Upload your nice professional image, indicate all the service categories and sub-categories, your service locations and maintain your overall ratings and good feedback from the customers. The customers view your profile and ratings before accepting your quotes. A well written profile will help improve the acceptance rate of your quotes. </p>
                                </div>

                                <div className="eps-right">
                                    <div className="enhanceProfile-img">
                                        <img src={images.starearningGrap} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video-list-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="video-block wow zoomIn animated hinge" style={{visibility: hideOrShow4, animationName: animatedName7}}>
                                <div className="video-img">
                                 
 {/* <img src={images.adv3} className="img-fluid h-100" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" onClick={()=>window.open("https://youtu.be/TZHTX_UeQKc")}><i className="fas fa-play text-warning"></i></a>
                                    </div> */}

<img 
                                            // src={images.adv2} 
                                            src={config.endpoints.image_path + startEarningNowVideo?.image}
                                            className="img-fluid h-100 w-100" alt="" 
                                            // onClick={()=>window.open(images.adv2)}
                                            onClick={()=>window.open(config.endpoints.image_path + startEarningNowVideo?.image)}
                                            />

{
                    startEarningNowVideo?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(startEarningNowVideo?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }
                     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex w-100 justify-content-center mt-3 mb-3'>
                
                <div>
                  <Link
                    to={{
                      pathname: "/create-my-request",
                      // state: { ...data },
                    }}
                    className="btn btn-red btn-md ardCat-submitQ-btn2"
                  >
                    Create My Request
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname: "/all-requests",
                      // state: { ...data },
                    }}
                    className="btn btn-red btn-md ardCat-submitQ-btn2 ml-3"
                  >
                    Submit My Quote
                  </Link>
                </div>
                </div>
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default StartEarning;
