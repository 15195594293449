import { useEffect, useState } from 'react';

import Header from "../../Components/Common/Header";
import MyAccountMenu from "../../Components/Common/MyAccountMenu";
import images from "../../Utility/Images";
import { Link } from "react-router-dom";
import Footer from "../../Components/Common/Footer";

import CustomerRequest from './CustomerRequest';
import ServiceProvider from './ServiceProviderRequest';
import {useAuth} from '../../Auth/AuthUser/AuthUserContext';
import Auth from '../../Auth/Auth';
import Info from '../../Components/Common/InfoPage';
import { propTypes } from 'react-bootstrap/esm/Image';
import GetInTouch from '../../Components/Common/GetInTouch';

const MyRequest = (props) => {

	const authData = Auth.getUserDetails();

	const setUserType2 = () => {
		switch (authData?.role_type){
			case 1:
				return "customer";
			case 2:
				return "provider"
			case 3:
				return "both"
			default:
				return null
		}
	}

	const initialUserType = setUserType2()

	const [userType, setUserType ] = useState(setUserType2())
	const [tabIndex, setTabIndex] = useState(userType === "provider" ? 2 : 1);

	useEffect(()=>{
		localStorage.setItem("tabIndex", tabIndex)
	},[tabIndex])

	
    // console.log("asdwdwqeqweqwe",props && props?.location?.state?.activeTab)

	return (
		<>
		      <Header />
		      <section className="gray-hero-section db-gh-section">
		        <div className="gh-clip-bg">
		          <img
		            src={images.clipBg}
		            className="img-fluid d-block m-auto"
		            alt=""
		          />
		        </div>
		        <div className="container">
		          <div className="row">
		            <div className="col-12">
		              <div className="inner-caption wow fadeInUp text-center">
		                <h1 className="mb-0">
		                  {" "}
		                  My <span> Requests </span>{" "}
		                </h1>
		              </div>
		            </div>
		          </div>
		        </div>
		      </section>

		      <section className="db-top-menu">
		        <div className="container">
		          <div className="row">
		            <div className="col-12">
		              <MyAccountMenu />
		            </div>
		          </div>
		        </div>
		      </section>

		      <div className="grayHero-tabnav db-gh-tabNav">
		        <ul className="nav nav-tabs" role="tablist">

		          <li>
		            <a
		              className= {userType === "customer" || userType === "both"?"active":""}
		            //   data-toggle="tab"
		            //   role="tab"
		              onClick={ () => {
						  setTabIndex(1)
						  setUserType("customer")
						} }
						style={{
							cursor: "pointer"
						}}
					
		            >
		              {" "}
		              Customer{" "}
		            </a>
		          </li>

		          <li>
		            <a 
		              	className= {userType === "provider" ?"active":""}
		            	// data-toggle="tab" 
		            	// role="tab" 
		            	onClick={  () => {
							setTabIndex(2)
							setUserType("provider")
						  } }
						  style={{
							cursor: "pointer"
						}}
						>
		              Service Provider
		            </a>
		          </li>

		        </ul>
		      </div>

		      {
		      	tabIndex === 1 ? 
		      		initialUserType === "customer" || initialUserType === "both" 
						? <CustomerRequest userData={authData} requestId={props && props?.location?.state?.requestId} activeTab={props && props?.location?.state?.activeTab} requestDetails={props && props?.location?.state?.requestDetails}/>
		      			: <Info infoText= 'You need to log in as "Both" or "Customer" user type to view this page.' btnTitle="Sign up as Customer / Both" btnLink = "/register" bodyMessage="Please logout and register/login as customer or both user" />

		      		: initialUserType === "provider" || initialUserType === "both" 
		      			? <ServiceProvider userData={authData} requestId={props && props?.location?.state?.requestId} activeTab={props && props?.location?.state?.activeTab} requestDetails={props && props?.location?.state?.requestDetails}/>
		      			: <Info infoText= 'You need to log in as "Both" or "Service Provider" user type to view this page.' btnTitle="Sign up as Service Provider / Both" btnLink = "/register" bodyMessage="Please logout and register/login as service provider or both user" />


		      }
		      


		      {/* <section className="getInTouch-section">
		        <div className="container">
		          <div className="col-12">
		            <div className="getInTouch-box">
		              <div className="title">
		                <h4> Get In Touch </h4>
		                <p> Know more about eZunction platform </p>
		              </div>
		              <div className="social-list">
		                <a href="https://www.facebook.com/" target="_blank">
		                  <i className="fab fa-facebook-f"></i>{" "}
		                </a>
		                <a href="https://twitter.com/" target="_blank">
		                  {" "}
		                  <i className="fab fa-twitter"></i>{" "}
		                </a>
		                <a href="https://www.instagram.com/" target="_blank">
		                  {" "}
		                  <i className="fab fa-instagram"></i>{" "}
		                </a>
		                <a href="https://www.youtube.com/" target="_blank">
		                  {" "}
		                  <i className="fab fa-youtube"></i>{" "}
		                </a>
		                <a href="https://www.instagram.com/" target="_blank">
		                  {" "}
		                  <i className="fab fa-instagram"></i>{" "}
		                </a>
		                <a href="https://www.flickr.com/" target="_blank">
		                  {" "}
		                  <i className="fab fa-flickr"></i>{" "}
		                </a>
		              </div>
		            </div>
		          </div>
		        </div>
		      </section> */}
			  <GetInTouch />

		      <Footer />


		</>
		);
}




export default MyRequest

