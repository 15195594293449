import React, {useEffect, useState} from 'react';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';
import images from '../Utility/Images';

import { APIService } from '../Components/Apis/APIServices';

const PeopleVoice = () => {
    const [data, setData] = useState([])
    useEffect(()=>{
        APIService.peopleVoice()
        .then((res) => {
            // console.log("kkkkkkkkkkkkklllllllllllllll", res.data.data)
            setData((res && res.data && res.data.data) || [])
        
        })
        .catch((err) => {});
  
    },[])

    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> People's  <span> Voice </span> </h1>
                                <p> eZunction platform is trending across the user community! Our highly satisfied <br className="d-none d-lg-block" /> and delighted users are sharing their experiences.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="peopleVoice-page-section">
                <div className="wp-clip-bg">
                    <img src={images.clipBg} className="img-fluid d-block m-auto" alt="" />
                </div>
                <div className="container">
                    <div className="row">

                        {data.map((item,index)=>(
                            <div className="col-md-6 peopleVoice-col" key={item.id}>
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={item.image_thumbnail} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" onClick={()=>window.open(item.image_src)}><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
{/* 
                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 peopleVoice-col">
                            <div className="video-block wow zoomIn peopleVoice-item">
                                <div className="video-img">
                                    <img src={images.videoBg} className="img-fluid" alt="" />
                                    <div className="voice-videoPlay-block">
                                        <a href="javascript:void(0);" className="play-btn" id="voice-1" data-toggle="modal" data-target="#voiceVideo-modal"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {/* <div className="row mt-3">
                        <div className="col-12">
                            <div className="e4-pagination">
                                <div className="e4p-left">
                                    <ul className="pagination">
                                        <li><a href="#" className="prev disable"><i className="fal fa-chevron-left"></i> Prev</a></li>
                                        <li className="pageNumber active"><a href="#">1</a></li>
                                        <li className="pageNumber"><a href="#">2</a></li>
                                        <li className="pageNumber"><a href="#">3</a></li>
                                        <li className="pageNumber"><a href="#">4</a></li>
                                        <li><a href="#" className="next">Next <i className="fal fa-chevron-right"></i></a></li>
                                    </ul>
                                </div>
                                <div className="e4p-right">
                                    <div className="e4p-group">
                                        <span> Number of Records </span>
                                        <select className="form-control">
                                            <option selected> 21 </option>
                                            <option> 30 </option>
                                            <option> 39 </option>
                                            <option> 48 </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default PeopleVoice;
