import React, {useState, useEffect} from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import {useParams} from 'react-router-dom';
import { APIService } from '../Components/Apis/APIServices';
import config from "../Components/Apis/config";
import { Scrollbars } from "react-custom-scrollbars";


import Rating from "./Rating";
import GetInTouch from "../Components/Common/GetInTouch";

const CustomerDetails = () => {
	const params = useParams()

  const [data, setData] = useState({
    categories:[],
    locations:[],
    reviews:{
      list:[]
    },
    rating: 0

  })

  useEffect(()=>{
	APIService.custDetails(params?.id)
.then(res => {
  if(res.status === 200)
		setData(res.data.user || {})	
	})
.catch(err => {})
},[params.id])

const getTitle = (data) => { 
    let result = ""

    if (data?.polite === 1) {
      result = result + "Polite, ";
    }
    if (data?.responsive === 1) {
      result = result + "Responsive, ";
    }
    if (data?.availability === 1) {
      result = result + "Availability, ";
    }
    if (data?.clarity_work === 1) {
      result = result + "Clarity Of Work";
    }

    if (result.charAt(result.length - 2) === ",") { 
      result = result.substring(0, result.length - 2)
      
    }

    
    return(<strong>{result}</strong>)
  } 

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#FF8805",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
	



  return (
    <>
      <Header />
      <section className="spd-hero-section sp-rate-section">
		<div className="spd-clip-bg">
			<img src="images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
		</div>
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="spdh-inner">
						<div className="spdh-left">
							<div className="spdh-user-img">
								<img src={
									 config.endpoints.image_path +
									 data?.profile_photo
								} className="img-fluid" alt="" />
							</div>
						</div>

						<div className="spdh-right">
							<div className="spdh-user-name">
								<h1> {data.first_name || ""} {data.last_name || ""} </h1>
							</div>
							<div className="spdh-other">
								{/* <div className="spdh-star">
									<i className="fas fa-star star-fill"></i>
									<i className="fas fa-star star-fill"></i>
									<i className="fas fa-star star-fill"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<span className="spdh-count"> 5 </span>
								</div> */}
								<div className="spdh-star">
                        <Rating rating={data?.rating} />
                        <span className="rating-count">
                        {data?.rating || ""}
                        </span>
                      </div>

								<div className="spdh-location">
									<span> {data?.city}, {data?.state} </span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


      <div className="review-rating-section pt-180">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="section-title wow fadeInUp">
						<h2> Review & Ratings </h2>
					</div>
				</div>

				<div className="col-12">
                <div className="rating-block wow fadeInUp">
                  <div className="rating-head">
                    <div className="ratingHead-left">
                      <span className="totalRate"> {(data?.rating && parseFloat(data?.rating).toFixed(1)) || "--"} </span>
                      <div className="tutorRating-block">
                        {/* <div className="tutorRating-star">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div> */}
                         <div className="spdh-star">
                        <Rating rating={data?.rating} />
                        
                      </div>
                        <span> {data.reviews.total_count || "0"} Reviews </span>
                      </div>
                    </div>

                    <div className="ratingHead-right">
                      <div className="allover-rating-box">
                        <div className="rating-bars-item">
                          <div className="rating-bars-name">
                            {" "}
                            <span>
                              {" "}
                              5{" "}
                            </span> <i className="fas fa-star"></i>{" "}
                          </div>
                          <div className="rating-bars rating-fill">
                            <span className="rating-stroke-line"></span>
                            <span
                              className="rating-fill-line"
                              style={{ width: `${data.reviews?.rating_percent && data.reviews?.rating_percent["5"]}%` }}
                            ></span>
                          </div>
                          <div className="rating-bars-count">
                            <span> {data.reviews?.rating_percent && data.reviews?.rating_percent["5"]}% </span>
                          </div>
                        </div>

                        <div className="rating-bars-item">
                          <div className="rating-bars-name">
                            {" "}
                            <span>
                              {" "}
                              4{" "}
                            </span> <i className="fas fa-star"></i>{" "}
                          </div>
                          <div className="rating-bars rating-fill">
                            <span className="rating-stroke-line"></span>
                            <span
                              className="rating-fill-line"
                              style={{ width: `${data.reviews?.rating_percent && data.reviews?.rating_percent["4"]}%` }}
                            ></span>
                          </div>
                          <div className="rating-bars-count">
                            <span> {data.reviews?.rating_percent && data.reviews?.rating_percent["4"]}% </span>
                          </div>
                        </div>

                        <div className="rating-bars-item">
                          <div className="rating-bars-name">
                            {" "}
                            <span>
                              {" "}
                              3{" "}
                            </span> <i className="fas fa-star"></i>{" "}
                          </div>
                          <div className="rating-bars rating-fill">
                            <span className="rating-stroke-line"></span>
                            <span
                              className="rating-fill-line"
                              style={{ width: `${data.reviews?.rating_percent && data.reviews?.rating_percent["3"]}%` }}
                            ></span>
                          </div>
                          <div className="rating-bars-count">
                            <span> {data.reviews?.rating_percent && data.reviews?.rating_percent["3"]}% </span>
                          </div>
                        </div>

                        <div className="rating-bars-item">
                          <div className="rating-bars-name">
                            {" "}
                            <span>
                              {" "}
                              2{" "}
                            </span> <i className="fas fa-star"></i>{" "}
                          </div>
                          <div className="rating-bars rating-fill">
                            <span className="rating-stroke-line"></span>
                            <span
                              className="rating-fill-line"
                              style={{ width: `${data.reviews?.rating_percent && data.reviews?.rating_percent["2"]}%` }}
                            ></span>
                          </div>
                          <div className="rating-bars-count">
                            <span> {data.reviews?.rating_percent && data.reviews?.rating_percent["2"]}% </span>
                          </div>
                        </div>

                        <div className="rating-bars-item">
                          <div className="rating-bars-name">
                            {" "}
                            <span>
                              {" "}
                              1{" "}
                            </span> <i className="fas fa-star"></i>{" "}
                          </div>
                          <div className="rating-bars rating-fill">
                            <span className="rating-stroke-line"></span>
                            <span
                              className="rating-fill-line"
                              style={{ width: `${data.reviews?.rating_percent && data.reviews?.rating_percent["1"]}%` }}
                            ></span>
                          </div>
                          <div className="rating-bars-count">
                            <span> {data.reviews?.rating_percent && data.reviews?.rating_percent["1"]}% </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rating-body mCustomScrollbar">
                  <div className="ardCat-list wow fadeInUp">
                  <Scrollbars renderThumbVertical={renderThumb}>
                    {data.reviews.list.map((item, index)=>(
                      <div className="rating-item" key={item.id}>
                      <div className="rating-item-left">
                        <div className="ratingCover" style={{width: "8%", height:"15%"}}>
                          <img
                            src={
                              config.endpoints.image_path +
                              item?.profile_photo
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="rating-name-rate">
                          <h4> {item?.first_name} {item?.last_name} </h4>
                          <div className="spdh-star">
                            {/* <i className="fas fa-star fill-star"></i>
                            <i className="fas fa-star fill-star"></i>
                            <i className="fas fa-star fill-star"></i>
                            <i className="fas fa-star fill-star"></i>
                            <i className="fas fa-star"></i>
                            <span> 5 </span> */}
                            <Rating rating={item?.rating} />
                        <span className="rating-count">
                        {item?.rating || ""}
                        </span>
                          </div>
                        </div>
                      </div>

                      <div className="rating-item-right">
                        <div className="review-content-block">
                          <h5>
                            <strong>{getTitle(item)} </strong>
                          </h5>
                          <p>
                            {item?.feedback_comments}
                          </p>
                        </div>
                      </div>
                    </div>
                    ))}
                    </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>
			</div>
		</div>
	</div>
    
      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Footer />
    </>
  );
};

export default CustomerDetails;
