import Ws from '@adonisjs/websocket-client';


// const WS_URL = `ws://${window.location.hostname}:3334`
// const WS_URL = `ws://3.135.192.120:3334`;
//const WS_URL = `ws://3.137.89.95:3334`;
const WS_URL = `wss://api.ezunction.com`;
//const WS_URL = `ws://18.219.186.222:3334`


export class SocketConnection {
  connect () {
    const Token = localStorage.getItem('_tk')
    this.ws = Ws(WS_URL)
      .withApiToken(Token)
      .connect();

    this.ws.on('open', () => {
      console.log('Connection initialized')
    });

    this.ws.on('close', () => {
      console.log('Connection closed')
    });

    return this
  }

  subscribe (channel, handler) {
    if (!this.ws) {
      setTimeout(() => this.subscribe(channel, handler), 1000)
    } else {
      console.log("channel",channel)
      const result = this.ws.subscribe(channel);

      console.log("socket result is", result)

      result.on('message', message => {
        console.log('Incoming', message);
        handler(message)
      });

      result.on('error', (error) => {
        console.error(error)
      });

      return result
    }
  }

  close(){
    this.ws = Ws(WS_URL).close().close()
  }

}

export default new SocketConnection()
