import { LoginTypes } from "./LoginType"

const initialState = {
    email:'',
    password:'',
    rememberMe:false,
    validation:'',
    responseData:'',
    showPassword:false,
    isLogin:false,
}

export default (state, { type, payload }) => {
    switch (type) {
    case LoginTypes.LoginPassword:
        return { ...state, password:payload}
    case LoginTypes.LoginEmail:
        return { ...state, email:payload}
    case LoginTypes.LoginResponse:
        return { ...state, responseData:payload}
    case LoginTypes.LoginValidation:
        return { ...state, validation:payload}
    case LoginTypes.LoginRememberMe:
        return { ...state, rememberMe:!payload}
    case LoginTypes.LoginShowPassword:
        return { ...state, showPassword:!payload}
    case LoginTypes.IsLogin:
            return { ...state, isLogin:payload}
    case LoginTypes.initialState:
            return { state, initialState}
    default:
        return state
    }
}
export {initialState};
