import React from 'react';


const Input = ({keyValue,type,value,label,state,setState})=>{
    console.log("key",keyValue)
    switch(type){
        case 'dropdown' : return <div className="form-group group-4 group-md-6 group-xs-12">
                                    <select value={state[keyValue]} className="form-control" onChange={(e)=>setState((prevState)=>({...prevState,[keyValue]:e.target.value}))} >
                                    <option value="0"> {label } </option>
                                    {
                    value.map((v, key) => <option key={key} value={key +1}>  {v }</option>)
                                    }
                                </select></div>
    case 'text' : return <div className="form-group group-4 group-md-6 group-xs-12">
                        <input type="text" placeholder={label} value={state[keyValue]} onChange={(e)=>setState((prevState)=>({...prevState,[keyValue]:e.target.value}))} className="holer-control form-control" />
                        </div>

    case 'number' : return <div className="form-group group-4 group-md-6 group-xs-12">
                        <input type="number" placeholder={label} value={state[keyValue]} onChange={(e)=>setState((prevState)=>({...prevState,[keyValue]:e.target.value}))} className="holer-control form-control" max="999999" min="0" title="Please enter 6 digit value" />
                        </div>
    }
    {/* <span style={{color: 'red'}}>*</span> */}

}

export default Input;