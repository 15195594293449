import React, { useState, useEffect } from "react";
import { APIService } from "../Apis/APIServices";

export default function WithdrawModal(props) {

  const [withdrawalReason, setWithdrawalReason] = useState([]);
  const [withdrawalReasonId, setWithdrawalReasonId] = useState("");
  const [withdrawalComment, setWithdrawalComment] = useState("");
  const [withdrawConfirm,setWithdrawConfirm] = useState("");

  const quote_id = props.quoteData.id;

  const handleSubmit = (e) => {
    APIService.withdawStatus(quote_id)
    .then(res=>{
        if(res && res.data && res.data.message){
          setWithdrawConfirm(res.data.message || "")
        }
    }).catch(console.log)
  };

  useEffect(() => { 
    APIService.srWithdrawReason()
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReason(res.data || "");
        }
      })
      .catch((e) => console.log("error", e));
  },[])

  const handConfirmWithdraw = ()=>{
    let data = {
      quote_id,
      withdraw_reason_id: withdrawalReasonId,
      withdraw_comments: withdrawalComment,
    };
    APIService.addWithdrawnQuote(data, quote_id)
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReasonId("");
          setWithdrawalComment("");
          document.getElementById("cancleButtonWithdrawModal").click();
          window.location.reload()
        }
      })
      .catch((e) => console.log("error", e));
  }

  // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjj",withdrawConfirm)

  return (
    <>
    <div class="scale_cus_modal disputeRequest-wrap" id="withdraw-qoute-modal">
      <div class="scale_modal_body">
        <div class="modal_inner">
          <div class="scm_header">
            <h2 class="scm_head-title"> Withdraw Quote </h2>
          </div>
          <div class="scm_body">
            <div >
              <div class="form-group">
                <label> Select Reason for Withdraw Quote </label>
                <select
                  class="form-control"
                  onChange={(e) => setWithdrawalReasonId(e.target.value)}
                >
                  <option value=""> Select Quote Withdrawal Reason *</option>
                  {
                    withdrawalReason.map(wr=><option value={wr.id}>{wr.name}</option>)
                  }
                </select>
              </div>

              <div class="form-group">
                <div class="textarea-group">
                  <textarea
                      class="form-control resize-none"
                      maxLength="100"
                    rows="6"
                    onChange={(e) => setWithdrawalComment(e.target.value)}
                    value={withdrawalComment}
                    placeholder="Please provide details explaining why you are withdrawing the quote. *"
                  ></textarea>
                    <span class="textarea-limit"> {100 - withdrawalComment.length} Characters Limit </span>
                </div>
              </div>

              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                  id="cancleButtonWithdrawModal"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button 
                  type="button" 
                  class="btn btn-red"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#confirm-withdraw-modal"
                  onClick={handleSubmit}
                  disabled={!withdrawalReasonId || !withdrawalComment}
                  >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="scale_cus_modal confirmCancel-wrap" id="confirm-withdraw-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Confirm Withdrawal </h2>
            </div>
            <div class="scm_body">
              <div class="confirmCancel">
                <p>{withdrawConfirm || "checking withdraw status, please wait.." }</p>
              </div>
              {
                withdrawConfirm.includes("Sorry, the withdrawal window for your quote") ?
                <div class="scm_footer d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-red"
                  data-dismiss="modal"
                >
                 OK
                </button>
                
              </div> 
                : 
              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                >
                  {" "}
                  No{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-red"
                  onClick={() => handConfirmWithdraw()}
                  data-dismiss="modal"
                >
                  {" "}
                  Yes{" "}
                </button>
              </div> 
              }
              
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
