
const NotificationModal = (props) => {
	const eleId = props.eleId || "notifiaction-request-modal";

	return (
		<>
	      <div className="scale_cus_modal confirmCancel-wrap" id={eleId}>
	        <div className="scale_modal_body">
	          <div className="modal_inner">
	            <div className="scm_header">
	            {
	            	props.confirm ?
	              <div className="notifiy-check">
	                <img
	                  src="../images/content/check.svg"
	                  className="d-block m-auto img-fluid"
	                  alt=""
	                />
	              </div>
	              : null
	          }
							{props.title ? <h2 className="scm_head-title"> {props.title} </h2> : null}
	            </div>

	            <div className="scm_body">
	              <div className="notify-modal-body">
	                <p>
	                  {props.body}
	                </p>
	                <p>
	                  {props.extraBody}
	                </p>
	              </div>
				  <br />

	              <div className="scm_footer justify-content-center full-scm_footer">
	                  <button
	                  	type="button"
	                  	className="btn btn-black mr-3"
	                  	data-dismiss="modal"
	                  	> 
	                  {props.secondaryBtnText}
	                  </button>
	                  <button
	                    type="button"
	                    className="btn btn-red"
	                    data-dismiss="modal"
	                    // data-toggle="modal"
	                    // data-target="#confirm-cancel-modal"
	                    onClick={()=>props.handleClick()}
	                  >
	                  {props.primaryBtnText}
	                  </button>


	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	  	
	 </>
		);
} 


export default NotificationModal;