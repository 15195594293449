import React, { useEffect, useState } from "react";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Input from "../Components/Inputs";
import images from "../Utility/Images";
import { APIService } from "../Components/Apis/APIServices";
import * as template from "../templates/service.request.json";
import Auth from "../Auth/Auth";
// import $ from "jquery";
import { dateWithFormate } from "../Utility/common";

import { Link, Redirect } from 'react-router-dom';
import Dialogue from "../Components/Common/Dialogue";

import Modals from '../Components/Modals/Modals';
import GetInTouch from "../Components/Common/GetInTouch";

const CreateMyRequest = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [slots, setSlots] = useState([]);

  const [serviceCats, setServiceCats] = useState([]);
  const [serviceSubCats, setServiceSubCats] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [adr1, setAdr1] = useState("");
  const [adr2, setAdr2] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dateTime, setDateTime] = useState("");

  const [serviceCat, setServiceCat] = useState("");
  const [serviceSubCat, setServiceSubCat] = useState("");
  const [serviceType, setServiceType] = useState("");

  const [templateState, setTemplateState] = useState({});
  const [addDetail, setAddDetail] = useState({
    appliance_make :"",
    appliance_model :'',
    appliance_year :"",
    type_of_house_id :"",
    no_of_bed :"",
    no_of_bath :"",
    no_of_level :"",
    sqft :"",
    flooring_type:"", 
    auto_type :"",
    auto_make :"",
    auto_model :"",
    auto_year :"",
    food_delivery_type:"", 
    food_additional_comments:"", 
    phone_consultation:""
  });
  const [homeaddress, setHomeaddress] = useState(true);

  const [slot, setSlot] = useState("");

  const [err, setErr] = useState({});

  const [openAdrs, setOpenAdrs] = useState("");
  const [dropdownStyle, setDropdownStyle] = useState("none");
  const [selectedOtherAd, setSelectedOtherAd] = useState("");

  const [imagesUploaded, setImagesUploaded] = useState([]); 
   const [imagesToSend, setImagesToSend] = useState([]);

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
  })

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("_cu")) {
      let profileObj = JSON.parse(localStorage.getItem("_cu"));
      console.log(profileObj);
      setAdr1(profileObj.address_1);
      setAdr2(profileObj.address_2);

      handleCountry({ target: { value: profileObj.country } });
      handleState({ target: { value: profileObj.state } });
      setCity(profileObj.city);
      if(profileObj.zip_code){
      setZip(profileObj.zip_code.substring(0, 5) + "-" + profileObj.zip_code.substring(5));
      }
    }
    APIService.fetchCountry()
      .then((res) => setCountries(res.data.data))
      .catch((err) => setErr(err));
    APIService.fetchAllTimeSlot()
      .then((res) => setSlots(res.data.data))
      .catch((err) => setErr(err));
    APIService.fetchAllCategory()
      .then((res) => setServiceCats(res.data.data))
      .catch((err) => setErr(err));
  }, []);

  const handleCountry = (e) => {
    setCountry(e.target.value);
    APIService.fetchState({ country_id: e.target.value })
      .then((res) => setStates(res.data.data))
      .catch((err) => setErr(err));
  };
  const handleState = (e) => {
    setState(e.target.value);
    APIService.fetchCity({ state_id: e.target.value })
      .then((res) => setCities(res.data.data))
      .catch((err) => setErr(err));
  };

  const handleServiceCat = (e) => {
    setServiceCat(e.target.value);
    setAddDetail({})
    setServiceType("")
    setServiceTypes([]);
    if (template.additionalDetails[e.target.value]) {
      setTemplateState(template.additionalDetails[e.target.value]);
      // console.log(template.additionalDetails[serviceCat]);
    }else if (template.additionalDetails[e.target.value + ":" + "any"]) {
      setTemplateState(template.additionalDetails[e.target.value+ ":"+ "any"]);
      // setTemplateState({});
      // setAddDetail({})
    } else { 
      setTemplateState({});
      setAddDetail({})
    }
    setServiceSubCats(
      serviceCats.filter((sc) => sc.id == e.target.value)[0].subcategories
    );
    //APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
  };
  const handleServiceSubCat = ({ target: { value } }) => {
    setServiceSubCat(value);
    // setAddDetail({})
    if (template.additionalDetails[serviceCat + ":" + value]) {
      setTemplateState(template.additionalDetails[serviceCat + ":" + value]);
      // console.log(template.additionalDetails[serviceCat + ":" + value]);
    }else if (template.additionalDetails[serviceCat + ":" + "any"]) {
      // setTemplateState(template.additionalDetails[serviceCat+ ":"+ "any"]);
      // setTemplateState({});
      // setAddDetail({})
    }  else { 
      setTemplateState({});
      setAddDetail({})
    }
    // else if (template.additionalDetails[serviceCat]) {
    //   setTemplateState(template.additionalDetails[serviceCat]);
    //   console.log(template.additionalDetails[serviceCat]);
    // }
    APIService.fetchAllServiceType({ sub_category_id: value })
      .then((res) => setServiceTypes(res.data.data))
      .catch((err) => setErr(err));
    //APIService.fetchCity({state_id:e.target.value}).then(res=>setCities(res.data.data)).catch(err=>setErr(err))
  };
  const [otherAddresses, setOtherAddresses] = useState([]);

  const handleAdChange = (e) => {
    console.log(e.target.value);
    setHomeaddress(e.target.value == "other" ? false : true);
    if (e.target.value === "other") {
      APIService.fetchOtherAddress()
      .then((res) =>
        setOtherAddresses((res && res.data && res.data.data) || [])
      )
      .catch((err)=>{})
    }
  };

  const handleSubmit = (e) => {
     e.preventDefault()
    let adrObj = {
      address_1: adr1,
      address_2: adr2,
      city,
      state,
      country,
      zip_code: zip,
    };
    if (!homeaddress) {
      const { address_1, address_2, city, state, country, zip_code } =
        selectedOtherAd;
        adrObj = {
        address_1,
        address_2,
        city,
        state,
        country,
        zip_code,
      };
    }

    // console.log("wwwwwwwwwwwwww", adrObj, cities)
    let selectedTimeSlot = slots.find(sl=>sl.id==slot);
    let request = {
      address_type: homeaddress ? 1 : 0,
      ...adrObj,
      service_date: dateTime,
      time_slot: slot,
      category_id: serviceCat,
      sub_category_id: serviceSubCat,
      service_type_id: serviceType,
      title,
      description,
      images: imagesToSend,
      ...addDetail,
      serviceCategory : serviceCats.find(sc=>sc.id==serviceCat).category_name,
      serviceSubCategory : serviceSubCats.find(sub=>sub.id==serviceSubCat).sub_category_name,
      serviceType : serviceTypes.find(st=>st.id==serviceType).name,
      serviceAddress : `${adrObj.address_1},${adrObj.address_2},`+
                        `${cities.find(c=>c.id==adrObj.city) &&  cities.find(c=>c.id==adrObj.city).name},`+
                        `${states.find(s=>s.id==adrObj.state) && states.find(s=>s.id==adrObj.state).name},`+
                        `${countries.find(c=>c.id==adrObj.country) && countries.find(c=>c.id==adrObj.country).name}-`+
                        `${adrObj.zip_code}`,
      serviceDate : dateTime,
      serviceTime : `${dateWithFormate(
        selectedTimeSlot.start_time,
        "hA",
        "HH:mm:ss"
      )}-${dateWithFormate(selectedTimeSlot.end_time, "hA", "HH:mm:ss")}`
    };
    setLoader(true)
    APIService.createRequest(request)
      .then((res) => {
        // console.log("res create request", res);
        setLoader(false)
        if(res.status === 200){
          setDialogueState({
            type : 'Success',
            show:true,
            message : `Your service request ${res.data.data.id} has been successfully submitted.`,
            link : 'my-request'
          })
        }else{
          setDialogueState({
            type : 'Error',
            show:true,
            message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
        }
       
        
      })
      .catch((err) => {
        // console.log("eeeeeeeeeeeeeerrr", err.response.data.message)
        setLoader(false)
        setDialogueState({
        type : 'Error',
        show:true,
        message : (err && err.response && err.response.data && err.response.data.message) || "",
           
        })
  });
  };

  document.body.classList.add("dark-header");

  const handleOtherAddList = () => {
    setOpenAdrs((prevState) => (prevState ? "" : "open-address"));
    setDropdownStyle((prevState) => (prevState == "none" ? "block" : "none"));
  };
  const [otherAddressSelected, setOtherAddressSelected] = useState("");
  const handleOption = (id, adr) => {
    setSelectedOtherAd(otherAddresses.find((oa) => oa.id == id));
    setOtherAddressSelected(adr);
    setDropdownStyle((prevState) => (prevState == "none" ? "block" : "none"));
  };

  // console.log("//////////////aaaaaaaaaaaaaaa", addDetail)
  const checkAuth = (e) => { 
    if (Auth.isAuthLogIn()) {
      handleSubmit(e)
    } else {
      return null
     }

  }

  const handleImageChange = (ele) => { 
    var _validFileExtensions = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
    // console.log("aaaaaaaaaaaaaaaaaaaaaa", ele.target.files[0].type)
// let value = imagesUploaded
    // let photoObj = {};
// let value = ele.target.value;

// var extension = 

    if (ele.target.files[0].size > 5242880) {
      // console.log("......../////////", ele.target.files[0].size);
setDialogueState({
  type: "Error",
  show: true,
  message: "File size exceeds 5MB",
});
return;
       
    } else if(_validFileExtensions.includes(ele.target.files[0].type)) { 
let reader = new FileReader();
reader.onload = (e) => {
  //setProfilePhoto(e.target.result);
};
reader.readAsDataURL(ele.target.files[0]);
// value = ele.target.files[0];


  setImagesUploaded([
    ...imagesUploaded,
    URL.createObjectURL(ele.target.files[0]),
  ]);

  
      setImagesToSend([...imagesToSend, ele.target.files[0]]);
      
    }else{
      setDialogueState({
        type: "Error",
        show: true,
        message: "Invalid file selected",
      });
      return;
    }
  
  }

  const handleDeleteImage = (index) => { 
    let currentImages = imagesUploaded
    let dataToDelete = imagesToSend;

    currentImages.splice(index, 1)
    dataToDelete.splice(index, 1);
    
    setImagesUploaded([...currentImages])
     setImagesToSend([...dataToDelete]);
  }

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }
  
  return (
    <>
      <Header />
      <section className="gray-hero-section  banner-b-50">
        <div className="gh-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1>
                  Create My <span> Request </span>
                </h1>
                <p>
                  Create your request to get your work completed.
                  <br className="d-none d-md-block" />
                  Please provide as much details as possible to get the most
                  accurate quotes for your request.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="createMyRequest-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cmr-form-block">
                <div className="form-group group-12">
                  {Auth.getUserDetails()?.role_type == 2 ||
                  Auth.getUserDetails() === null ||
                  Auth.getUserDetails() === undefined ? (
                    <p className="text-danger">
                      {" "}
                      * You need to{" "}
                      <a
                        href="/login"
                        className="text-danger"
                        style={{ textDecoration: "underline" }}
                      >
                        Sign-In
                      </a>{" "}
                      as a customer or as both to be able to submit your
                      request.{" "}
                    </p>
                  ) : null}

                  <label> Service Address </label>
                  <fieldset className="radio-block" onChange={handleAdChange}>
                    <div className="radio-group">
                      <input
                        type="radio"
                        value={"home"}
                        className="radio-input d-none"
                        name="serviceAdd"
                        id="homeAddress"
                        checked={homeaddress}
                      />
                      <label className="radio-label-control" for="homeAddress">
                        {" "}
                        Home Address{" "}
                      </label>
                    </div>
                    <div className="radio-group">
                      <input
                        type="radio"
                        value={"other"}
                        className="radio-input d-none"
                        name="serviceAdd"
                        id="otherAddress"
                        checked={!homeaddress}
                      />
                      <label className="radio-label-control" for="otherAddress">
                        {" "}
                        Other Address{" "}
                      </label>
                    </div>
                  </fieldset>
                </div>
                <form
                className="w-100"
                  onSubmit={(e) => {
                    checkAuth(e);
                  }}
                >
                  {homeaddress && (
                    <div className="homeAddress-option">
                      <div className="form-group fg-place group-6 group-xs-12">
                        {/* <div className="placeholder">
                                            <label for="">Address Line 1 </label>
                                            <span className="star">*</span>
                                        </div> */}
                        <div
                          className={`placeholder ${
                            adr1 ? "d-none" : "d-block"
                          }`}
                        >
                          <label for="address_line_1"> Address Line 1 </label>
                          <span className="star text-danger">*</span>
                        </div>
                        <input
                          type="text"
                          value={adr1}
                          onChange={(e) => setAdr1(e.target.value)}
                          className="holer-control form-control"
                          disabled={Auth.isAuthLogIn() ? true : false}
                        />
                      </div>

                      <div className="form-group group-6 fg-place group-xs-12">
                        <div
                          className={`placeholder ${
                            adr2 ? "d-none" : "d-block"
                          }`}
                        >
                          <label for="address_line_2"> Address Line 2 </label>
                          {/* <span className="star text-danger">*</span> */}
                        </div>
                        <input
                          type="text"
                          value={adr2}
                          onChange={(e) => setAdr2(e.target.value)}
                          className="holer-control form-control"
                          disabled={Auth.isAuthLogIn() ? true : false}
                        />
                      </div>

                      <div className="form-group group-3 group-md-6 group-xs-12">
                        <select
                          value={country}
                          className="form-control"
                          onChange={handleCountry}
                          disabled={Auth.isAuthLogIn() ? true : false}
                        >
                          {/* <option value=""><span>Country <span style={{color: 'red'}}>*</span></span></option> */}
                          <option value="">Country *</option>
                          {countries.map((country) => (
                            <option value={country.id}>{country.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group group-3 group-md-6 group-xs-12">
                        <select
                          value={state}
                          className="form-control"
                          onChange={handleState}
                          disabled={Auth.isAuthLogIn() ? true : false}
                        >
                          {/* <option> State <span style={{color: 'red'}}>*</span></option> */}
                          <option value="">State *</option>
                          {states.map((state) => (
                            <option value={state.id}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group group-3 group-md-6 group-xs-12">
                        <select
                          className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          disabled={Auth.isAuthLogIn() ? true : false}
                        >
                          {/* <option> City <span style={{color: 'red'}}>*</span></option> */}
                          <option value="">City *</option>
                          {cities.map((city) => (
                            <option value={city.id}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group fg-place group-3 group-md-6 group-xs-12">
                        {/* <div className="placeholder">
                                            <label for=""> Zip Code </label>
                                            <span className="star">*</span>
                                        </div> */}
                        <div
                          className={`placeholder ${
                            zip ? "d-none" : "d-block"
                          }`}
                        >
                          <label for="zip"> Zip Code </label>
                          <span className="star text-danger">*</span>
                        </div>
                        <input
                          type="text"
                          value={zip}
                          onChange={(e) => {
                            let value = e.target.value;

                            if (zip.length === 4 && value.length === 5) {
                              value += "-";
                            } else if (value.length === 6) {
                              value = value.replace("-", "");
                            }

                            setZip(value);
                          }}
                          className="holer-control form-control"
                          maxLength="10"
                          minLength="5"
                          pattern="[0-9-]*"
                          title="Zip code must contain numbers only"
                          disabled={Auth.isAuthLogIn() ? true : false}
                        />
                      </div>
                    </div>
                  )}
                  {!homeaddress && (
                    <div className="otherAddress-option">
                      <div className="form-group fg-place group-6 group-xs-12">
                        <div className="selectAdd-group">
                          <div
                            className={
                              "selectAdd-input cus-select-box " + openAdrs
                            }
                            onClick={handleOtherAddList}
                          >
                            {!otherAddressSelected ? (
                              <span>
                                {" "}
                                Select Address
                                <span style={{ color: "red" }}>*</span>{" "}
                              </span>
                            ) : (
                              <span class="text-truncate">
                                {otherAddressSelected}
                              </span>
                            )}
                          </div>
                          <div
                            className="selectAdd-option-group"
                            style={{ display: dropdownStyle,
                            height:"300px",
                            overflowY:"scroll"
                            }}
                          >
                            {otherAddresses.map((oa, key) => (
                              <div
                                className="sao"
                                onClick={() =>
                                  handleOption(
                                    oa.id,
                                    (oa.address_1 ? `${oa.address_1}, ` : "") +
                                      // ", " +
                                      (oa.address_2 ? `${oa.address_2}, ` : "") +
                                      // ", " +
                                      ((oa.cities && oa.cities.name) ? `${oa.cities.name}, ` : "") +
                                      // ", " +
                                      ((oa.states && oa.states.name) ? `${oa.states.name}, ` : "") +
                                      // ", " +
                                      ((oa.countries && oa.countries.name) ? `${oa.countries.name}, ` : "") +
                                      // " - " +
                                      oa.zip_code || ""
                                  )
                                }
                              >
                                <strong>Other Address {key + 1}  </strong>
                                <span>
                                  {(oa.address_1 ? `${oa.address_1}, ` : "") +
                                    // ", " +
                                    (oa.address_2 ? `${oa.address_2}, ` : "") +
                                    // ", " +
                                    ((oa.cities && oa.cities.name) ? `${oa.cities.name}, ` : "") +
                                    
                                    // ", " +
                                   
                                    ((oa.states && oa.states.name) ? `${oa.states.name}, ` : "") +
                                    // ", " +
                                    // oa.countries.name +
                                    ((oa.countries && oa.countries.name) ? `${oa.countries.name}, ` : "") +
                                    // " - " +
                                    oa.zip_code || ""}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="form-group group-6 group-xs-12">
                        <div className="addNewAddress-request">
                          <button
                            type="button"
                            className="btn text-btn"
                            data-toggle="modal"
                            data-target="#addNewAddress-modal"
                          >
                            {" "}
                            <i className="fal fa-plus-circle"></i> Add New
                            Address{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Expected Service Date and Time* </h4>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 w-100">
                      <div
                        className="form-group date1 w-100 datepicker-calendar fg-place"
                        id="datepicker10"
                        data-target-input="nearest"
                      >
                        <div className="placeholder">
                          <label for=""> Select service date </label>
                          <span className="star">*</span>
                        </div>
                        <input
                          type="date"
                          onChange={(e) => setDateTime(e.target.value)}
                          placeholder="Select service date *"
                          className="holer-control form-control"
                          data-target="#datepicker10"
                          data-toggle="datetimepicker"
                          min={getTodaysDate()}
                        />
                        {/* <span>Date *</span> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group w-100">
                        <select
                          className="form-control"
                          value={slot}
                          onChange={(e) => setSlot(e.target.value)}
                        >
                          {/* <option> Select Time <span style={{color: 'red'}}>*</span></option> */}
                          <option value="">Select Time *</option>
                          {slots.map((slot) => (
                            <option value={slot.id}>
                              {dateWithFormate(
                                slot.start_time,
                                "hA",
                                "HH:mm:ss"
                              )}{" "}
                              -{" "}
                              {dateWithFormate(slot.end_time, "hA", "HH:mm:ss")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 w-100 mb-3">
                      <select
                        value={serviceCat}
                        className="form-control"
                        onChange={handleServiceCat}
                        required
                      >
                        {/* <option value=""><span>Select the service category <span style={{color: 'red'}}>*</span></span></option> */}
                        <option value="">Select the service category *</option>
                        {serviceCats.map((sc) => (
                          <option value={sc.id}>{sc.category_name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 w-100 mb-3">
                      <select
                        value={serviceSubCat}
                        className="form-control"
                        onChange={handleServiceSubCat}
                        required
                      >
                        {/* <option value=""><span>Select the service category <span style={{color: 'red'}}>*</span></span></option> */}
                        <option value="">
                          Select the service sub-category *
                        </option>
                        {serviceSubCats.map((sc) => (
                          <option value={sc.id}>{sc.sub_category_name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 w-100 mb-3">
                      <select
                        className="form-control"
                        value={serviceType}
                        onChange={(e) => setServiceType(e.target.value)}
                        required
                      >
                        {/* <option> Select the service type <span style={{color: 'red'}}>*</span></option> */}
                        <option value="">Select the service type *</option>
                        {serviceTypes.map((st) => (
                          <option value={st.id}>{st.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Request Title </h4>
                  </div>

                  <div className="form-group group-12">
                    <div className="textarea-group">
                      <textarea
                        className="form-control resize-none"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Provide high level summary of your request *"
                        rows="4"
                        maxLength="100"
                      ></textarea>
                      <span className="textarea-limit">
                        {" "}
                        {100 - title.length} Characters Limit{" "}
                      </span>
                    </div>
                  </div>

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Request Description </h4>
                  </div>

                  <div className="form-group group-12">
                    <div className="textarea-group">
                      <textarea
                        className="form-control resize-none"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        placeholder="Provide a brief description of the request and all the relevant details to get the most accurate quote *"
                        rows="4"
                        maxLength="500"
                      ></textarea>
                      <span className="textarea-limit">
                        {" "}
                        {500 - description.length} Characters Limit{" "}
                      </span>
                    </div>
                    <span className="textNote">
                      {" "}
                      <span style={{ color: "red" }}>*</span> Please do not
                      share your personal/contact details such as email id,
                      contact number, address etc.{" "}
                    </span>
                  </div>

                  <div className="form-group group-title group-12 mb-0">
                    <h4> Additional Details </h4>
                  </div>
                  <div className="d-flex flex-wrap">
                    {Object.keys(templateState).map((k) => (
                      // <div className="col-12">
                      <Input
                        keyValue={k}
                        label={templateState[k].label}
                        type={templateState[k].type}
                        value={templateState[k].value}
                        state={addDetail}
                        setState={setAddDetail}
                      />
                      // </div>
                    ))}
                  </div>
                  <div className="form-group group-title group-12 mb-0">
                    <div
                      className="profileUp-inner d-flex flex-wrap"
                      style={{ overflow: "hidden" }}
                    >
                      {imagesUploaded.length > 0
                        ? imagesUploaded.map((item, index) => (
                            <div
                              className="upload-here"
                              style={{
                                position: "relative",
                                margin: "25px 10px 40px 0px",
                              }}
                              key={index}
                            >
                              <img
                                src={item}
                                className="img-fluid"
                                alt=""
                                style={{ position: "absolute" }}
                              />
                              <i
                                className="fa fa-trash fa-fw text-black"
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  bottom: "0",
                                  right: "0",
                                  color: "orange",
                                }}
                                onClick={() => handleDeleteImage(index)}
                              ></i>
                            </div>
                          ))
                        : null}
                    </div>
                    {imagesUploaded.length < 5 ? (
                      <div className="cus-fileUpload-box form-group group-12 mt-3">
                        <div className="cus-fileUpload-group">
                          <input
                            type="file"
                            className="cus-fileUpload-control d-none"
                            id="driverDL-input"
                            onChange={handleImageChange}
                          />
                          <label
                            for="driverDL-input"
                            className="mb-0 cus-fileUpload-label"
                          >
                            <div className="profileUp-inner">
                              <div className="upload-here">
                                <img
                                  src={images.imagesIcon}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>

                              <div className="upload-content">
                                <h5>
                                  {" "}
                                  Upload Photos{" "}
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                </h5>
                                <span> Images 5MB Max </span>
                                <span> (Max 5 Uploads) </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {Auth.isAuthLogIn() ? (
                    <div className="form-submit text-center group-12">
                      <button
                        type="submit"
                        className="btn btn-md btn-red"
                        disabled={
                          !dateTime ||
                          !slot ||
                          !serviceCat ||
                          !serviceSubCat ||
                          !serviceType ||
                          !title ||
                          !description ||
                          loader
                          // ||
                          // (addDetail?.sqft?.length > 6)
                        }
                        // onClick={handleSubmit}
                      >
                        {" "}
                        {loader ? "Processing ..." : "Submit" }{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="form-submit text-center group-12">
                      <Link className="btn btn-md btn-red" to="/login">
                        {" "}
                        Submit{" "}
                      </Link>
                    </div>
                  )}

                  {/* <div className="form-submit text-center group-12">
                      <button
                        type="submit"
                        className="btn btn-md btn-red"
                        // onClick={handleSubmit}
                      >
                        {" "}
                        Submit{" "}
                      </button>
                    </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Footer />
      {/* <Dialogue state={dialogueState} setState={setDialogueState} /> */}
      <Modals/>
    </>
  );
};

export default CreateMyRequest;
