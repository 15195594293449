import React,{useContext, useEffect, useState} from 'react';
import { Link , useParams} from 'react-router-dom';
import { APIService } from '../Components/Apis/APIServices';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import images from '../Utility/Images';
import HomeContext from './Home/HomeContext/HomeContext';
import { Scrollbars } from "react-custom-scrollbars";
import { dateWithFormate } from '../Utility/common';

import * as template from "../templates/service.request.json";

import config from "../Components/Apis/config";
import Rating from "./Rating";
import GetInTouch from '../Components/Common/GetInTouch';

const RequestDetails = (props) => {
	const params = useParams()
	
	const [currentRequest,setCurrentRequest] = useState({})
	const [err,setErr] = useState({})
	const [serviceCats,setServiceCats] = useState([])
    const [serviceSubCats,setServiceSubCats] = useState([])
    const [serviceTypes,setServiceTypes] = useState([])


	const [serviceCat,setServiceCat]= useState("")
    const [serviceSubCat,setServiceSubCat] = useState('')
	const [serviceType, setServiceType] = useState('')
	
	const [completeFilterData, setCompleteFilterData] = useState([])

  const [detailCardShow, setDetailCardShow] = useState(true)
  const [loader, setLoader] = useState(true)

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [requestId, setRequestId] = useState("");
  const [category, setCategory] = useState([]);
  const [cat, setCat] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCat, setSubCat] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [duration, setDuration] = useState("");
  const [allRequestFilter, setAllRequestFilter] = useState(false);

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
      });
    }, []);

	const handleServiceCat = (e) => {
		if (e.target.value === "") {
setServiceCat(e.target.value)
        // setServiceSubCats(serviceCats.filter(sc=>sc.id == e.target.value)[0].subcategories)
		} else { 
setServiceCat(e.target.value)
        setServiceSubCats(serviceCats.filter(sc=>sc.id == e.target.value)[0].subcategories)
		}
        
        //APIService.fetchState({country_id:e.target.value}).then(res=>setStates(res.data.data)).catch(err=>setErr(err))
    }
	const handleServiceSubCat = ({target:{value}})=>{
		setServiceSubCat(value)
        APIService.fetchAllServiceType({sub_category_id:value}).then(res=>setServiceTypes(res.data.data)).catch(err=>setErr(err))
    }
	const [srs,setSrs] = useState([])
	const [filterSrs,setFilterSrs] = useState([])
	const handleSearch = () => {
		if (serviceCat === "") {
			setFilterSrs(completeFilterData)
		} else { 
			setFilterSrs(completeFilterData.filter(sr => sr.service_type_id == serviceType || sr.sub_category_id == serviceSubCat || sr.category_id == serviceCat))
			
		}
			
	}

	// useEffect(() => {
	// 	console.log("i am called")
	// 	window.scroll({
	// 		top: 0,
	// 		left: 0,
	// 		behavior: 'smooth'
	// 	  });
	//   }, []);

	useEffect(() => { 
		// console.log("////////////////////.................. called",)
		if (props.location.state !== undefined && props.location.state.serviceCat && serviceCats.length !== 0) { 
			setServiceCat(props.location.state.serviceCat)
			setServiceSubCat(props.location.state.serviceSubCat)
			setServiceType(props.location.state.serviceType)

			handleServiceCat({
				target: {
					value:props.location.state.serviceCat
				}
			})

			handleServiceSubCat({
				target: {
					value:props.location.state.serviceSubCat
				}
			})

			
		}
		handleSearch()
	},[serviceCats, completeFilterData])
	
	// useEffect(()=>{
	// 	APIService.fetchAllCategory()
  //   .then(res => {
	// 		setServiceCats(res.data.data)

  //     setLoader(true)
			
	// 		APIService.fetchAllServiceRequests().then(res => {
	// 		setLoader(false)
	// 		// let data = res.data
			

	// 		// var unMatchedIds = data.map((item,index) => { 
	// 			const url = props.location.pathname;
	// 			const id = parseInt(url.substr(url.lastIndexOf('/') + 1))

	// 		const selectedItem = res.data.data.filter(item => item.id === id);
	// 		const nonSelectedItem = res.data.data.filter(item => item.id !== id);
	// 		// console.log("//////////////////////// " ,typeof(res.data[0].id) , typeof(id), selectedItem)

	// 		let data = [...selectedItem, ...nonSelectedItem]
				
	// 		// })


	// 		setSrs(data)
	// 		setCompleteFilterData(data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3))
      
	// 		setFilterSrs(data.filter((sr)=>sr.sr_status ===1 || sr.sr_status ===3));
	// 			setCurrentRequest(data.filter(sr => sr.id == params.id)[0])
				
	// 		})
			
	// 	}).catch(err => setErr(err))
		
	// },[params.id])

  useEffect(()=>{
	APIService.fetchAllCategory()
    .then(res => {
			setServiceCats(res.data.data)
      setCategory(res.data.data)
    })
    .catch((err)=>{})

    APIService.fetchCountry()
      .then((res) => setCountries(res.data.data))
      .catch((err) => setErr(err));

      APIService.fetchState({ country_id: "233" })
    .then((res) => setStates(res.data.data.sort((a, b) => a.name.localeCompare(b.name))))
    .catch((err) => console.log(err));
  
    
    if (props.location.state !== undefined && props.location.state.showDetailCard) { 
      setDetailCardShow(true)
    }

  },[])

  useEffect(()=>{
    // if (props.location.state !== undefined && serviceCats.length !== 0) {
    if (props.location.state !== undefined && props.location.state !== null) { 
      setServiceCat(props.location.state.serviceCat)
      // setServiceSubCats(
      //   serviceCats.filter((sc) => sc.id == props.location.state.serviceCat)[0]?.subcategories || []
      // );
      setServiceSubCat(props.location.state.serviceSubCat)
      APIService.fetchAllServiceType({ sub_category_id: props.location.state.serviceSubCat })
        .then((res) => setServiceTypes(res.data.data))
        .catch((err) => setErr(err));
  
  
      setServiceType(props.location.state.serviceType)
     
      setLoader(true)

      APIService.fetchAllServiceRequests(
        {
          limit: (props && props.location && props.location.state && props.location.state.numberOfRecords) || "",
          page: (props && props.location && props.location.state && props.location.state.currentPageNo) || ""
        },
        {
          category:(props && props.location && props.location.state && props.location.state.serviceCat) || "",
        subCategory:(props && props.location && props.location.state && props.location.state.serviceSubCat) || "",
        servicetype: (props && props.location && props.location.state && props.location.state.serviceType) || ""
         
       }, 
        {
          country: (props && props.location && props.location.state && props.location.state.country) || "",
          city: (props && props.location && props.location.state && props.location.state.city) || "",
          state: (props && props.location && props.location.state && props.location.state.state) || "",
          duration: (props && props.location && props.location.state && props.location.state.duration) || "",
          requestId: (props && props.location && props.location.state && props.location.state.requestId) || "",
          requestStatus: (props && props.location && props.location.state && props.location.state.requestStatus) || "",
          submittedDate: (props && props.location && props.location.state && props.location.state.submittedDate) || "",
          serviceDate:(props && props.location && props.location.state && props.location.state.serviceDate) || "",
 
        })
      .then((res) => {
        setLoader(false)
        setSrs(res.data.data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3));
        
        
        setAllRequestFilter(false)
  
        
        
      })
      .catch((e) => console.log("close error", e));
    

    }else{
      getData()
    }    
   },[props.location.state])

   const getData = (filterValues)=>{
    setLoader(true)
     APIService.fetchAllServiceRequests(
      {
        limit: (props && props.location && props.location.state && props.location.state.numberOfRecords) || "",
        page: (props && props.location && props.location.state && props.location.state.currentPageNo) || ""
      },
       {
      //    category:serviceCat || (props && props.location && props.location.state && props.location.state.serviceCat) || "",
      //  subCategory: serviceSubCat || (props && props.location && props.location.state && props.location.state.serviceSubCat) || "",
      //  servicetype: serviceType || (props && props.location && props.location.state && props.location.state.serviceType) || ""
      //  category:serviceCat || "",
      //  subCategory: serviceSubCat || "",
      //  servicetype: serviceType || ""

      category:(filterValues === "filterValues" ? cat : serviceCat) || "",
       subCategory: (filterValues === "filterValues" ? subCat : serviceSubCat) || "",
       servicetype: (filterValues === "filterValues" ? "" : serviceType) || ""
       
        
      }, 
       {
         country,
         city,
         state,
         duration,
         requestId,
         requestStatus,
         submittedDate,
         serviceDate

       })
     .then((res) => {
      setLoader(false)
       setSrs(res.data.data.filter((sr) => sr.sr_status === 1 || sr.sr_status === 3));
       
       setAllRequestFilter(false)
 
       
       
     })
     .catch((e) => console.log("close error", e));
   }

   useEffect(()=>{
     if(srs.filter(sr => sr.id == params.id)[0] === undefined || srs.filter(sr => sr.id == params.id)[0] === null){
      setCurrentRequest(srs[0] || [])
      
     }else{
      setCurrentRequest(srs.filter(sr => sr.id == params.id)[0] || [])
     }
   },[params.id,srs])
  
	document.body.classList.add('dark-header');


  const additionalDetails = (data) => {
    // console.log(
    //   "///////////////// template",
    //   // template.default.additionalDetails["1:4"]
    //   data
    // );

    if (data?.category_id === 1 && data?.sub_category_id === 4) {
      return (
        <>
          <div className="catPoint-item">
            <span> Make </span>
            <span> {data?.addtionalDetail?.appliance_make || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Model </span>
            <span> {data?.addtionalDetail?.appliance_model || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Year </span>
            {/* <span> {data?.addtionalDetail?.appliance_year || "--"} </span> */}
            {
                template?.default?.additionalDetails["1:4"]
                  ?.appliance_year.value[
                  data?.addtionalDetail?.appliance_year - 1
                ]
                ||
                "--"
              }
          </div>
        </>
      );
    } else if (data?.category_id === 3) {
      return (
        <>
          <div className="catPoint-item">
            <span> Phone Consultation </span>
            <span>
              {" "}
              {/* {data?.addtionalDetail?.phone_consultation || "--"}{" "} */}
              {template?.default?.additionalDetails["3:any"]?.phone_consultation
                .value[data?.addtionalDetail?.phone_consultation - 1] || "--"}
            </span>
          </div>
        </>
      );
    } else if (data?.category_id === 4 || data?.category_id === 5) {
      return (
        <>
          <div className="catPoint-item">
            <span> Type Of House </span>
            <span>
              {/* {data?.addtionalDetail?.type_of_house_id || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.type_of_house_id
                .value[data?.addtionalDetail?.type_of_house_id - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Beds </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_bed || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_bed.value[
                data?.addtionalDetail?.no_of_bed - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Bath </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_bath || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_bath.value[
                data?.addtionalDetail?.no_of_bath - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. Of Level </span>
            <span>
              {/* {data?.addtionalDetail?.no_of_level || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.no_of_level.value[
                data?.addtionalDetail?.no_of_level - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Flooring Type </span>
            <span>
              {/* {data?.addtionalDetail?.flooring_type || "--"} */}
              {template?.default?.additionalDetails["4:any"]?.flooring_type
                .value[data?.addtionalDetail?.flooring_type - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Sqft </span>
            <span> {data?.addtionalDetail?.sqft || "--"} </span>
          </div>
        </>
      );
    } else if (data?.category_id === 6) {
      return (
        <>
          <div className="catPoint-item">
            <span> Auto Type </span>
            <span>
              {/* {data?.addtionalDetail?.auto_type || "--"} */}
              {template?.default?.additionalDetails["6:any"]?.auto_type.value[
                data?.addtionalDetail?.auto_type - 1
              ] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Make </span>
            <span> {data?.addtionalDetail?.auto_make || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Model </span>
            <span> {data?.addtionalDetail?.auto_model || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Auto Year </span>
            <span>
              {/* {data?.addtionalDetail?.auto_year || "--"} */}
              {template?.default?.additionalDetails["6:any"]?.auto_year.value[
                data?.addtionalDetail?.auto_year - 1
              ] || "--"}
            </span>
          </div>
        </>
      );
    } else if (data?.category_id === 7) {
      return (
        <>
          <div className="catPoint-item">
            <span> Food Delivery Type </span>
            <span>
              {/* {data?.addtionalDetail?.food_delivery_type || "--"} */}
              {template?.default?.additionalDetails["7:any"]?.food_delivery_type
                .value[data?.addtionalDetail?.food_delivery_type - 1] || "--"}
            </span>
          </div>
          <div className="catPoint-item">
            <span> No. of People </span>
            <span> {data?.addtionalDetail?.no_Of_People || "--"} </span>
          </div>
          <div className="catPoint-item">
            <span> Spicy Level </span>
            <span>
              {/* {data?.addtionalDetail?.spicyLevel || "--"} */}
              {template?.default?.additionalDetails["7:any"]?.spicy_Level.value[
                data?.addtionalDetail?.spicy_Level - 1
              ] || "--"}
            </span>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  
   const renderThumb = ({ style, ...props }) => {
     const thumbStyle = {
       borderRadius: 6,
       backgroundColor: "#FF8805",
     };
     return <div style={{ ...style, ...thumbStyle }} {...props} />;
   };

   const handleCategory = (e) => {
   
    setCat(e.target.value);
    setSubCategory(category.filter((sc) => sc.id == e.target.value)[0].subcategories);
  };

  const handleSubCategory = ({ target: { value } }) => {
    setSubCat(value);

  };

   const handleDuration=(val) =>{
    setDuration(val)
    }
  
    const handleCountry = (e) => {
      setCountry(e.target.value);
      APIService.fetchState({ country_id: e.target.value })
        .then((res) => setStates(res.data.data))
        .catch((err) => setErr(err));
    };
    const handleState = (e) => {
      setState(e.target.value);
      APIService.fetchCity({ state_id: e.target.value })
        .then((res) => setCities(res.data.data))
        .catch((err) => setErr(err));
    };
  


	console.log("current request ", currentRequest);

    return (
      <>
        <Header />
        <section className="gray-hero-section requestDetail-hero-section">
          <div className="gh-clip-bg">
            <img
              src={images.clipBg}
              className="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div className="container">
            <form className="row align-items-end">
              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Categories </label>
                  <select
                    value={serviceCat}
                    className="form-control"
                    onChange={handleServiceCat}
                  >
                    <option value="">Select the service category</option>
                    {serviceCats.map((sc) => (
                      <option value={sc.id}>{sc.category_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Sub-Categories </label>
                  <select
                    value={serviceSubCat}
                    className="form-control"
                    onChange={handleServiceSubCat}
                  >
                    <option value="">Select the service sub-category</option>
                    {serviceSubCats.map((sc) => (
                      <option value={sc.id}>{sc.sub_category_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Service Type </label>
                  <select
                    value={serviceType}
                    className="form-control"
                    onChange={(e) => setServiceType(e.target.value)}
                  >
                    <option value="">Select the service type</option>
                    {serviceTypes.map((st) => (
                      <option value={st.id}>{st.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="searchAction-block form-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-red"
                    onClick={()=>getData()}
                  >
                    {" "}
                    Search{" "}
                  </button>
                  <button
                    type="button"
                    className="search-filter-btn btn btn-orange"
                    id="catFilter-btn"
                    onClick={() => setAllRequestFilter(!allRequestFilter)}
                  >
                    {" "}
                    <img
                      src={images.filterIcon}
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
        
        {allRequestFilter && allRequestFilter ? (
        <section className="catOpen-filter-section open">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cat-open-filter">
                  <div className="cof-inner">
                    <div className="cof-close-block">
                      <button
                        className="cofClose-btn"
                        type="button"
                        onClick={() => setAllRequestFilter(!allRequestFilter)}
                      >
                        {" "}
                        <i className="fal fa-times"></i>
                      </button>
                    </div>

                    
                    <form>
                      <div className="row align-items-end">
                        <div className="form-group col-xl-4 col-md-6">
                          <label> Country </label>
                          <select
                            // value={country}
                            className="form-control"
                            // onChange={handleCountry}
                            value="233"
                            disabled
                          >
                            <option value="">Country</option>
                            {countries.map((country) => (
                              <option value={country.id}>{country.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-xl-4 col-md-6">
                          <label> State </label>
                          <select
                            value={state}
                            className="form-control"
                            onChange={handleState}
                          >
                            <option value="">State</option>
                            {states.map((state) => (
                              <option value={state.id}>{state.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-xl-4 col-md-6">
                          <label> City </label>
                          <select
                            className="form-control"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option value="">City</option>
                            {cities.map((city) => (
                              <option value={city.id}>{city.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request ID </label>
                          <input
                            type="text"
                            onChange={(e) => setRequestId(e.target.value)}
                            className="form-control"
                            placeholder="Enter the Request ID "
                            value={requestId}
                          />
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Category </label>
                          <select
                            value={cat}
                            className="form-control"
                            onChange={handleCategory}
                          >
                            <option value="">
                              Select the service category
                            </option>
                            {category.map((sc) => (
                              <option value={sc.id}>{sc.category_name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                        <label> Sub Category </label>
                          <select
                            value={subCat}
                            className="form-control"
                            onChange={handleSubCategory}
                          >
                            <option value="">
                              Select the service sub-category
                            </option>
                            {subCategory.map((sc) => (
                              <option value={sc.id}>
                                {sc.sub_category_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group col-xl-4 col-md-6">
                          <label> Request Status </label>
                          <select
                            className="form-control"
                            onChange={(e) => setRequestStatus(e.target.value)}
                            value={requestStatus}
                          >
                            <option selected value="">
                              {" "}
                              Select the request status{" "}
                            </option>
                            <option value="1"> Submitted </option>
                            {/* <option value="2"> Cancelled </option> */}
                            <option value="3"> Quote Received </option>
                            {/* <option value="4"> Confirmed </option> */}
                            {/* <option value="5"> Started </option> */}
                            {/* <option value="6"> Completed </option> */}
                            {/* <option value="7"> Pending </option> */}
                          </select>
                        </div>

                        {/* <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker00"
                          data-target-input="nearest"
                        >
                          <label for=""> Submitted Date </label>
                          <input
                            type="date"
                            onChange={(e) => setSubmittedDate(e.target.value)}
                            className="form-control"
                            data-target="#datepicker00"
                            data-toggle="datetimepicker"
                            placeholder="Submitted Date"
                            value={submittedDate}
                          />
                        </div> */}

                        <div
                          className="form-group date1 col-xl-4 col-md-6 datepicker-calendar fg-place"
                          id="datepicker1300"
                          data-target-input="nearest"
                        >
                          <label for=""> Service Date </label>
                          <input
                            type="date"
                            onChange={(e) => setServiceDate(e.target.value)}
                            className="form-control"
                            data-target="#datepicker1300"
                            data-toggle="datetimepicker"
                            placeholder="Service Date"
                            value={serviceDate}
                          />
                        </div>

                        <div className="form-group col-xl-4 col-12">
                          <label> Duration </label>
                          <div className="radio-block">
                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration30"
                                value="0-30"
                                // checked={e.target.checked}
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "0-30"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration30"
                              >
                                {" "}
                                0-30 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                value="31-90"
                                id="duration90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "31-90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90"
                              >
                                {" "}
                                31-90 Days{" "}
                              </label>
                            </div>

                            <div className="radio-group">
                              <input
                                type="radio"
                                className="radio-input d-none"
                                name="durationTime"
                                id="duration90above"
                                value="90"
                                onChange={(e) => handleDuration(e.target.value)}
                                checked={duration === "90"}
                              />
                              <label
                                className="radio-label-control"
                                for="duration90above"
                              >
                                {
                                `> 90 Days`}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="form-group col-12 mt-3 mb-0">
                          <div className="cof-submit-group text-center">
                          <button
                            type="button"
                            className="btn btn-black btn-sm"
                            onClick={() => {
                              setCountry("")
                              setState("")
                              setCity("")
                              setRequestId("")
                              setCat("")
                              setSubCat("")
                              setRequestStatus("")
                              setSubmittedDate("")
                              setServiceDate("")
                              setDuration("")
                              setSubCategory([])
                              setCities([])
                            }}
                          >
                            {" "}
                            Clear{" "}
                          </button>
                            <button
                              type="button"
                              className="btn btn-black btn-sm ml-3"
                              onClick={()=>setAllRequestFilter(false)}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="button"
                              className="btn btn-red btn-sm ml-3"
                              onClick={(e) => getData("filterValues")}
                            >
                              {" "}
                              Apply{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}


        <section className={`requestDetail-section guestReqDetail-section ardCat-tab-wrap ${detailCardShow ? "ardCat-show": ""}`}>
          <div className="container">
          {srs.length <= 0 && !loader ? 
            <div className="d-flex justify-content-center mb-5">
              No matching records found
            </div>:
            <div className="row">
              <div className="col-xl-4 ardCat-col-box mCustomScrollbar ">
              
                <div className="ardCat-list wow fadeInUp">
                  <Scrollbars renderThumbVertical={renderThumb}>
                    {srs.length > 0 &&
                      srs.map((sr) => (
                        <div
                          className="ard-cat-item hnc-item service-cat-item"
                          style={{ width: "96%" }}
                          // onClick={()=>setDetailCardShow((prev) => {return !prev})}
                          onClick={()=>setDetailCardShow(true)}
                        >
                          <Link
                            to={"/request-detail/" + sr.id}
                            className={`service-cat-inner myRq-link ${
                              String(sr.id) == String(params.id)
                                ? "open-quote"
                                : ""
                            }`}
                            data-rel="grd-1"
                          >
                            <div className="sc-head">
                              <div className="left">
                                <img
                                  // src={images.user3}
                                  src={
                                    config.endpoints.image_path +
                                    sr?.user?.profile_photo
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="right">
                                <h3>
                                  {" "}
                                  {sr.user &&
                                    sr.user.first_name +
                                      " " +
                                      sr.user.last_name}{" "}
                                </h3>
                                <div className="rating">
                                      <Rating rating={sr?.user?.rating} />
                                        <span className="rating-count">
                                        {sr?.user?.rating}
                                        </span>
                                      </div>
                              </div>
                            </div>

                            <div className="sc-body">
                              <div className="scb-top">
                                <span className="service-type">
                                  {/* {sr.sr_status === 3
                                    ? sr?.quote?.length +
                                      " " +
                                      sr?.srstatus?.name
                                    : sr?.srstatus?.name}{" "} */}
                                     {sr.sr_status === 3
                                    ? sr?.quote_count +
                                      " " +
                                      sr?.srstatus?.name
                                    : sr?.srstatus?.name}{" "}
                                </span>
                                <span className="text-dark">{sr?.id ? `SR ID: ${sr?.id}`: null}</span>
                          
                              </div>
                              <div className="scb-bottom">
                                <span className="sc-name">
                                  {sr?.serviceType?.name || "-"}
                                </span>
                                <span className="sc-name">
                                  {sr?.title || "-"}
                                </span>

                                <span className="text-ellipsis">
                                  {sr.cityGet ? sr.cityGet.name + ", " : "-"}
                                  {sr.cityGet.states
                                    ? sr.cityGet.states.name 
                                    // + ", "
                                    : "-"}
                                  {/* {sr?.cityGet?.countries?.iso3 || "-"} -{" "}
                                  {sr?.zip_code || "-"} */}
                                  {/* <br className="d-none d-md-block" />{" "} */}
                                  <br />
                                  {dateWithFormate(sr?.service_date)}&nbsp; (
                                  {dateWithFormate(
                                    sr?.timeGet?.start_time,
                                    "hA",
                                    "HH:mm:ss"
                                  )}{" "}
                                  -{" "}
                                  {dateWithFormate(
                                    sr?.timeGet?.end_time,
                                    "hA",
                                    "HH:mm:ss"
                                  )}
                                  )
                                  {/*                              {sr.service_date +
                                `(${
                                  sr.timeGet &&
                                  sr.timeGet.start_time.split(":")[0] +
                                    "-" +
                                    sr.timeGet.end_time.split(":")[0]
                                })`}{" "}*/}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </Scrollbars>
                </div>

              </div>

              <div className="col-xl-8 ardCat-content-wrap">
                {!loader ? 
                <div id="grd-1" className="myRq-tab-content current-request">
                  {currentRequest.id ? (
                    <div className="ardCat-detail-right">
                      <div className="ardCatD-head">
                        <div className="ardCat-back-block d-block d-xl-none">
                          <button onClick={()=>{setDetailCardShow(false) 
                            setCurrentRequest({})}} 
                            type="button" className="ardCat-back-btn">
                            {" "}
                            <i className="fal fa-long-arrow-left"></i>{" "}
                          </button>
                        </div>
                        <div className="left">
                          <h2> Request ID : {currentRequest.id} </h2>
                          <span>
                            {" "}
                            {/* {currentRequest.sr_status === 3
                              ? currentRequest?.quote?.length +
                                " " +
                                currentRequest?.srstatus?.name
                              : currentRequest?.srstatus?.name}{" "} */}

                            {currentRequest.sr_status === 3
                              ? currentRequest?.quote_count +
                                " " +
                                currentRequest?.srstatus?.name
                              : currentRequest?.srstatus?.name}{" "}
                          </span>
                        </div>
                        <div className="right">
                          <Link
                            to={{
                              pathname: "/customer-details/" + currentRequest.user_id ,
                              //   state:{
                              //       // filterSrs: filterSrs,
                              //       // completeFilterData: completeFilterData
                              //       serviceType:serviceType,
                              //       serviceSubCat:serviceSubCat,
                              //       serviceCat:serviceCat
                              //     }
                            }}
                            // className="service-cat-inner"
                            data-rel="grd-1"
                          >
                            <div className="img">
                              <img
                                // src={images.user1}
                                src={
                                  config.endpoints.image_path +
                                  currentRequest?.user?.profile_photo
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="ardCatuser-d">
                              <h3>
                                {" "}
                                {currentRequest?.user?.first_name}{" "}
                                {currentRequest?.user?.last_name}
                              </h3>
                              <div className="rating">
                                      <Rating rating={currentRequest?.user?.rating} />
                                        <span className="rating-count">
                                        {currentRequest?.user?.rating}
                                        </span>
                                      </div>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="ardCatD-body">
                        <div className="ardCatD-cat-block">
                          <div className="servicecat-item">
                            {/* <span className="scl"> Service Category </span>
                            <span className="scv">
                              {" "}
                              {(currentRequest &&
                                currentRequest?.category?.category_name) ||
                                "--"}{" "}
                            </span> */}
                            <h5 className="font-weight-bold">
                              Service Category
                            </h5>
                            {/* <span className="scl"> Service Category </span>
                                <span className="scv">
                                  {" "}
                                  {pageData?.category?.category_name}{" "}
                                </span> */}
                            <i>{currentRequest?.category?.category_name}</i>
                          </div>
                          <div className="servicecat-item">
                            {/* <span className="scl"> Service Sub-Category </span>
                            <span className="scv">
                              {" "}
                              {(currentRequest &&
                                currentRequest?.sub_category
                                  ?.sub_category_name) ||
                                "--"}{" "}
                            </span> */}
                            <h5 className="font-weight-bold">
                              Service Sub-Category
                            </h5>
                            <i>
                              {currentRequest?.sub_category?.sub_category_name}
                            </i>
                          </div>
                          <div className="servicecat-item">
                            {/* <span className="scl"> Service Type </span>
                            <span className="scv">
                              {" "}
                              {(currentRequest?.serviceType &&
                                currentRequest?.serviceType?.name) ||
                                "--"}{" "}
                            </span> */}
                            <h5 className="font-weight-bold">Service Type</h5>
                            <i>{currentRequest?.serviceType?.name}</i>
                          </div>
                        </div>

                        <div className="ardCatD-inner-body">
                          <div className="ardCatD-location-time">
                            <div className="ardCatD-lt-inner">
                              <div className="catD-lt-item">
                                <div className="icon">
                                  <img
                                    src={images.locationBigIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="it-content">
                                  <span className="itl">
                                    {" "}
                                    Service Location{" "}
                                  </span>
                                  <span className="itv">
                                    {" "}
                                    {currentRequest.cityGet &&
                                      currentRequest.cityGet.name +
                                        ", " +
                                        currentRequest.cityGet.states.name +
                                        ", " +
                                        currentRequest.cityGet.countries.iso3 +
                                        " - " +
                                        currentRequest.zip_code}
                                  </span>
                                </div>
                              </div>
                              <div className="catD-lt-item">
                                <div className="icon">
                                  <img
                                    src={images.calendarBigIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="it-content">
                                  <span className="itl">
                                    {" "}
                                    Service Date and Time{" "}
                                  </span>
                                  {/* <span className="itv"> {  `(${currentRequest.timeGet && currentRequest.timeGet.start_time.split(':')[0] +'-'+ currentRequest.timeGet.end_time.split(':')[0]})` } </span> */}
                                  <span className="itv">
                                    {dateWithFormate(
                                      currentRequest?.service_date
                                    )}
                                    &nbsp; (
                                    {dateWithFormate(
                                      currentRequest?.timeGet?.start_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}{" "}
                                    -{" "}
                                    {dateWithFormate(
                                      currentRequest?.timeGet?.end_time,
                                      "hA",
                                      "HH:mm:ss"
                                    )}
                                    )
                                  </span>
                                  {/* <span className="itv"> { currentRequest.cityGet && currentRequest.service_date ? new Date(currentRequest.service_date):(new Date()).getDate() + `(${currentRequest.timeGet && currentRequest.timeGet.start_time.split(':')[0] +'-'+ currentRequest.timeGet.end_time.split(':')[0]})` } </span> */}
                                </div>
                              </div>
                            </div>
                            <div className="ardCatD-it-action">
                              <Link
                                to={{
                                  pathname: "/quotes-submit",
                                  state: { pageData: currentRequest },
                                }}
                                className="btn btn-red btn-md ardCat-submitQ-btn"
                              >
                                {" "}
                                Submit Quote{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="ardCatD-content">
                            <h3> Request Title </h3>
                            <p> {currentRequest.title}</p>
                          </div>

                          <div className="ardCatD-content">
                            <h3> Request Description </h3>
                            <p> {currentRequest.description} </p>
                          </div>

                          <div className="ardCatD-point-list">
                            {additionalDetails(currentRequest)}
                          </div>

                          {currentRequest?.files.length > 0 ? (
                            <div className="ardCatD-media-block">
                              <h3> Media </h3>
                              <div className="catD-media-list">
                                {currentRequest?.files?.map((item, index) => (
                                  <div
                                    className="catDM-item"
                                    style={{
                                      position: "relative",
                                      margin: "25px 10px 40px 0px",
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        config?.endpoints?.image_path +
                                        item.file
                                      }
                                      className="img-fluid"
                                      alt=""
                                      onClick={()=>window.open(config.endpoints.image_path +
                                        item?.file)}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ardCat-detail-right"></div>
                  )}
                </div>
:
<div className='w-100 d-flex justify-content-center'><i class="fas fa-spinner fa-spin"></i></div>
}
              </div>
            </div>
}
          </div>
        </section>

        {/* <section className="getInTouch-section">
          <div className="container">
            <div className="col-12">
              <div className="getInTouch-box">
                <div className="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div className="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i className="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i className="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />
        <Footer />
      </>
    );
}

export default RequestDetails;
