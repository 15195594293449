import React, { Component, useEffect, useReducer, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Auth from '../Auth/Auth';
import { APIService } from '../Components/Apis/APIServices';
import Header from '../Components/Common/Header';
import axios from 'axios'
import { authHeader } from "../Components/Apis/authHeader";


import images from '../Utility/Images';

import AdditionalInformation from './Registration/AdditionalInformation';
import RegistrationContext from './Registration/Context/RegistrationContext';
import PaymentInformation from './Registration/PaymentInformation';
import PersonalInformation from './Registration/PersonalInformation';
import reducer, { initialState } from './Registration/Reducer/RegistrationReducer';
import FormValidation from './Registration/FormValidation/FormValidation';
import Dialogue from '../Components/Common/Dialogue';


import ReCAPTCHA from "react-google-recaptcha";

var validation1=false;
var validation2=false;
var validation3=false;
const Registration = () => {
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
    const [validCap, setValidCap] = useState(true)
    const prevPage = () => {
        
        if (state.step > 1) {
            let prev = state.step - 1;
            dispatch({ type: 'CONTINUE', payload: prev });
        } else if (history.goBack()) {
            return <Redirect to={history.goBack()} />
        } else {
            return <Redirect to="/register-successful" />
        }

    }
    const formValidation=()=>{
        validation1 = state.first_name && state.last_name 
                        // && state.profile_photo 
                        && state.address_1 && state.country && state.state && state.city
                        && state.zip_code && state.email && state.phone &&
                        state.password && state.confirm_password
                        && (state.iagree === 1) && !validCap;

        return validation1;
    }
    const ChoseType = (type) => {
        dispatch({ type: 'USER_TYPE', payload: type });
    }

    const [ip, setIP] = useState('');

    const [processing, setProcessing] = useState(false)

    
    const handleCustomer = (e) => {
        e.preventDefault();

        if(!FormValidation().Email(state.email)){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Please enter a valid email address.'
            })
            return;
        }else if(state.phone.length<10){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Mobile not valid'
            })
            return;
        }else if(state.zip_code.length<5 || state.zip_code.length>10){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Zip Code not valid'
            })
            return;
        }else if(state.password!==state.confirm_password){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Password does not match.'
            })
            return;
        }else if(processing){
            return null
        }
    
          setProcessing(true)

        let data={
            first_name:state.first_name,
            last_name:state.last_name,
            middle_name:state.middle_name,
            role_type:state.type,
            profile_photo:state.profile_photo,
            address_1:state.address_1,
            address_2:state.address_2,
            country:state.country,
            state:state.state,
            city:state.city,
            zip_code:state.zip_code.replace('-',''),
            email:state.email,
            phone:state.phone,
            country_code:state.country_code,
            password:state.password,
            device_token:state.device_token,
            device_type:state.device_type,
            user_ip: ip
        };


        APIService.registerUser(data)
        .then((response)=>{
            if(response.data.message == "unique validation failed on email"){
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message : 'Email Id Already Exists'
                })
                setProcessing(false)
            }else if(response.status<400){
                window.location.href = "/register-successful"
                setProcessing(false)
            }
        }).catch((error)=>{
            console.log(error);
            setProcessing(false)
        })
    }     

    // const getIPData = async () => {
    //     await axios.get('https://checkip.amazonaws.com/')
    //     .then((res)=>{
    //         console.log("tttttttttttttttttt",res.data.IPv4)
    //     })
    //     .catch((err) =>{
    //         return err
    //     })
    //     // console.log(res.data);
        
    //   }

    

    // const getData = async () => {
    //     const res = await axios.get('https://geolocation-db.com/json/', { headers: {
    //         jsonpCallback: "callback",
	// 	dataType: "jsonp",
    //     } })
    //     console.log(res.data);
    //     setIP(res.data.IPv4)
    //   }
      
    //   useEffect( () => {
    //     //passing getData method to the lifecycle method
    //     getData()
    
    //   }, [])
    

    //   console.log("tttttttttttttttttt", ip)

    useEffect(()=>{
        fetch("https://api.db-ip.com/v2/free/self")
        .then((res) => res.json())
        .then(
            (result) =>{
                // console.log("ttttttttttttttttttt", result.ipAddress)
                setIP(result.ipAddress)
            },
            (error) =>{}
        )
    },[])

    const handleServiceProvider = (e) => {
        e.preventDefault();
        if(!FormValidation().Email(state.email)){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Please enter a valid email address.'
            })
            return;
        }else if(state.phone.length<10){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Mobile not valid'
            })
            return;
        }else if(state.zip_code.length<5 || state.zip_code.length>10){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Zip Code not valid'
            })
            return;
        }else if(state.password!==state.confirm_password){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'Password does not match.'
            })
            return;
        }else if(processing){
            return null
        }

        setProcessing(true)
        // dispatch({ type: 'PRELOADER',payload:true})
        let data={
            first_name:state.first_name,
            last_name:state.last_name,
            middle_name:state.middle_name,
            role_type:state.type,
            profile_photo:state.profile_photo,
            address_1:state.address_1,
            address_2:state.address_2,
            country:state.country,
            state:state.state,
            city:state.city,
            zip_code:state.zip_code.replace('-',''),
            email:state.email,
            phone:state.phone,
            country_code:state.country_code,
            password:state.password,
            device_token:state.device_token,
            device_type:state.device_type,
            user_ip: ip
            
        };
        APIService.registerProvider(data)
        .then((response)=>{
            if(response.data.message == "unique validation failed on email"){
                setDialogueState({
                    type : 'Error',
                    show:true,
                    message : 'Email Id Already Exists'
                })
                setProcessing(false)
            }else if(response.status<400){
                window.location.href = "/register-successful"
                setProcessing(false)
            }
            console.log(response,'provider');
        }).catch((error)=>{
            console.log(error);
            setProcessing(false)
        })
    }

    if(Auth.getAccessToken()){
        return  <Redirect to="/"/>
     }

     const handleCaptchaChange = (value)=>{
        console.log("Captcha value:", value);
        if(value)
            setValidCap(false)
    }

    return (
        <>
            <RegistrationContext.Provider value={[state, dispatch]}>
                <Header />
                <section className="auth-wrapper auth-register-box">
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="auth-white-box auth-block">
                                    <div className="auth-back">
                                        <Link to='#' onClick={prevPage}><i className="far fa-chevron-left"></i> Back </Link>
                                    </div>
                                    <div className="m_header text-center">
                                        <h2> Register </h2>
                                        <p> Enter your details below to continue </p>
                                    </div>
                                    <div className="m_body">
                                        <div className="auth-form" id="auth-register-form">
                                            <div className="form-group group-12 text-md-center mt-4 ml-0">
                                                <label> Select User Type </label>
                                                <div className="radio-block justify-content-center">
                                                    <div className="radio-group">
                                                        <input type="radio" className="radio-input d-none" onChange={() => ChoseType(2)} name="user-type" id="service-provider" checked={state.type === 2 ? true : false} />
                                                        <label className="radio-label-control" onClick={() => ChoseType(2)} for="service-provider"> Service Provider </label>
                                                    </div>
                                                    <div className="radio-group">
                                                        <input type="radio" className="radio-input d-none" onChange={() => ChoseType(1)} name="user-type" id="customer" checked={state.type === 1 ? true : false} />
                                                        <label className="radio-label-control" onClick={() => ChoseType(1)} for="customer"> Customer </label>
                                                    </div>

                                                    <div className="radio-group">
                                                        <input type="radio" className="radio-input d-none" onChange={() => ChoseType(3)} name="user-type" id="both" checked={state.type === 3 ? true : false} />
                                                        <label className="radio-label-control mr-0" onChange={() => ChoseType(3)} for="both"> Both </label>
                                                    </div>
                                                </div>
                                            </div>
                                        <form onSubmit={(e)=>(
                                            state.type===1 ? handleCustomer(e) : handleServiceProvider(e)
                                        )}>
                                            <div className="reg-tabNav">
                                                <span className={`step step-item active`}> <div className="step-name"> Personal Information</div> </span>
                                            </div>
                                            <PersonalInformation active={state.step === 1 ? true : false} />
                                            <div className="form-group group-6 group-xs-12">
                                            <ReCAPTCHA
    sitekey="6LeGaDAfAAAAACcH7UFxzT4wrt4zoKQYqGCsRMz1"
    onChange={handleCaptchaChange}
  />
  </div>
                                      
                                            <div className="form-submit text-center group-12">
                                                {
                                                    state.step>1? <button type="button" className="btn btn-md btn-red continue-reg-btn" id="nextBtn" onClick={prevPage}> Back </button> : null
                                                }
                                                {/* {state.type===1?
                                                <button disabled={!formValidation()} type="button" onClick={handleCustomer} className="btn btn-md btn-red continue-reg-btn" id="nextBtn">  Submit  </button>
                                                :<button type="button" disabled={!formValidation()} onClick={handleServiceProvider} className="btn btn-md btn-red continue-reg-btn" id="nextBtn"> Submit </button>

                                                } */}
                                                <button disabled={!formValidation() || processing} type="submit" className="btn btn-md btn-red continue-reg-btn" id="nextBtn" > {processing ? "Processing..." : "Submit"}  </button>
                                                
                                            </div>
                                            </form>
        
                                            <div className="col-sm-12 bottom-info">
                                                <p> Already have any account? <Link to="/login"> Sign In </Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Dialogue state={dialogueState} setState={setDialogueState}/>
            </RegistrationContext.Provider>
        </>
    );
}

export default Registration;
