
import { useState, useEffect } from 'react';
import { APIService } from "../Components/Apis/APIServices";
import Auth from '../Auth/Auth';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import MyAccountMenu from "../Components/Common/MyAccountMenu";
import images from "../Utility/Images";
import Dialogue from '../Components/Common/Dialogue';
import config from "../Components/Apis/config";
import { dateWithFormate } from '../Utility/common';
import Rating from "./Rating";
import AddNewCard from "../Pages/AddNewCard";

const AcceptCompletion = (props) => {

	const userDetail = Auth.getUserDetails();
	const [couponError,setCouponError] = useState(null)
	const [couponValue,setCouponValue] = useState(false)
	const requestData = props ? props.location ? props.location.state && props.location.state.id ? props.location.state : {} : {} : {} ; 
	const ratingArr = [1,2,3,4,5]
	const [rating,setRating] = useState(3)
	let totalAmount = 0;

  // console.log("ggggggggggggggg", props)

  // let  additional_material_amount= 0
  // let  additional_labor_amount= 0

  var additional_cost = 0

  // let  uplifted_labor_quote_amount= 0
  // let  uplifted_material_quote_amount= 0

  var quote_price = 0

  var service_charge = 0
  var tax_amount = 0

  const [quoteState,setQuoteState] = useState({
		...requestData.accepted_quote,
		total_amount:totalAmount,
		tip_amount : '',

    /////////////////// new values
    additional_cost, 
    quote_price, 
    service_charge, 
    tax_amount

	})

  const [loader, setLoader] = useState(false)

  const [spData, setSpData] = useState({})

  useEffect(()=>{
    APIService.getAcceptCompletionData(requestData.id)
    .then((res)=>{
      // console.log("res is ", res.data)
      additional_cost = res.data.additional_cost
      quote_price = res.data.quote_price
      service_charge = res.data.service_charge
      tax_amount = res.data.tax_amount
      

      setSpData((res.data && res.data.sp) || {})

      setQuoteState((prev) =>{
        return ({...prev, 
          additional_cost, 
          quote_price, 
          service_charge, 
          tax_amount,
          total_amount : res.data.additional_cost + res.data.quote_price + res.data.service_charge + res.data.tax_amount
        })
      })

    })
    .catch((err)=>{

    })
  },[])
 

	// const {
	// 	accepted_quote:{
	// 		additional_material_amount,
	// 		additional_labor_amount,
	// 		uplifted_labor_quote_amount,
	// 		uplifted_material_quote_amount
	// 	} = {
	// 		additional_material_amount: 0,
	// 		additional_labor_amount :0,
	// 		uplifted_labor_quote_amount : 0,
	// 		uplifted_material_quote_amount : 0
	// 	}
		

	// } = requestData;

  
  // totalAmount = additional_cost + quote_price + service_charge + tax_amount 

	// if(additional_labor_amount){
	// 	totalAmount = additional_labor_amount + additional_material_amount + uplifted_labor_quote_amount + uplifted_material_quote_amount;
	// }else{
	// 	totalAmount = uplifted_labor_quote_amount + uplifted_material_quote_amount;
	// }

	const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })

	
	const [formData, setFormData] = useState({
		sp_quality_of_work:0,
		sp_cleanliness : 0,
		sp_professionalism : 0,
		sp_arrived_on_time : 0,
		rating : 0,
		coupon_code : "",
		tip_percentage : '',
		discount_amount : 0,
    sp_feedback_comments:""
	})
	const handleChange = (ele) => {
		let {name,value,checked} = ele.target;
		if(name=='tip_percentage' || name=='tip_amount'){
			// console.log(value)
			// let tipAmount = name=='tip_amount'?parseInt(value): ((
			// 	quoteState.uplifted_labor_quote_amount+
			// 	quoteState.uplifted_material_quote_amount)*parseFloat(value)).toFixed(2);

      let tipAmount = name=='tip_amount'?parseInt(value): ((
				quoteState.quote_price)*parseFloat(value)).toFixed(2);

			setQuoteState(prevState=>({
				...prevState,
				total_amount : (parseFloat(prevState.total_amount-(formData.tip_amount || 0))+ parseFloat(tipAmount || 0)).toFixed(2),
				tip_amount : tipAmount || 0
			}))
			setFormData( prev => {
				return { ...prev, tip_amount:tipAmount,tip_percentage:name=='tip_amount'?'':value}
			});	
			
		}else if(ele.target.checked){
			setFormData( prev => {
				return { ...prev, [name]:checked?1:0}
			});
		}
		else{
			setFormData( prev => {
				return { ...prev, [name]:value}
			});	
		}

	}

	const hanldeSubmit = (e) => {
		e.preventDefault();
		if (requestData && userDetail.id ) {
			if((userDetail.role_type === 1 || userDetail.role_type === 3) && requestData.id) {
				setLoader(true)
        APIService.acceptCompletion({
          ...formData, 
          "request_id" : requestData.id, 
          provider_id: spData.id,
          user_id:userDetail.id,
          rating:rating,
          // payment_type: bankDetailsCheck === 1 ? "card" : "bank" ,
          payment_type: bankDetailsCheck,
          payment_id:  bankDetailsCheck === 1 ? cdCard : bankAccount
        })
					.then((res) => {
            setLoader(false)
						if (res.status === 200){ 
              setDialogueState({
                type: "Success",
                show: true,
                message: `Thank you for accepting the completion of your service request ${requestData.id}. Please refer your transaction history for your invoice.`,
                link: "/my-request",
              });
            }else{
              setLoader(false)
              setDialogueState({
                type: 'Error',
                show: true,
                message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })
            }
					})
          .catch((e) => {
            // console.log("  catch wala", e.response)
            setDialogueState({
              type: 'Error',
              show: true,
              message: (e?.response && e?.response?.data && e?.response?.data?.message) || ""
            })
          });

      //   console.log("rrrrrrrrrrrrrrrrrrreeeeeeeeeeeee", formData, 
      //   requestData.id, 
      //   requestData.provider_id,
      //  userDetail.id,
      //   rating,
      //    bankDetailsCheck,
      //   bankDetailsCheck === 1 ? cdCard : bankAccount )

			}else 
			setDialogueState({
				type : 'Warning',
				show:true,
				message :`Can't Accept completion. Please login as a Customer.`
			  })
		}else 
		setDialogueState({
			type : 'Warning',
			show:true,
			message :`Please login first.`
		  })

	}

	const handleCoupon = (e)=>{
		e.preventDefault();
		if(!couponValue){
			
			let {coupon_code} = formData;
			APIService.validateCoupon(coupon_code).then(res=>{
        let discountAmount = 0
          setCouponValue(parseInt(res.data.value));
        if (!res.data.discount) {
          if (res.data.value) {
            discountAmount = parseFloat(res.data.value)
          }
        } else {
          discountAmount =
            Math.min(
              parseFloat(
                // (quoteState.uplifted_labor_quote_amount || 0) +
                // (quoteState.uplifted_material_quote_amount || 0)
                (quoteState.quote_price || 0)
              ) *
              parseFloat(res.data.discount / 100), parseFloat(res.data.value)
            ).toFixed(2);
         
        }
        
        // console.log("eeeeeeeeeeeeeee", discountAmount)

				setQuoteState((prevState) => ({
          ...prevState,
          total_amount: prevState.total_amount - discountAmount,
          discount_amount: discountAmount,
        }));
				setFormData( prev => {
					return { ...prev, discount_amount:discountAmount}
				});	
			}).catch(err=>{
				// console.log("eeeeeeeeeeeeeeeeeeeeee",err)
				//setCouponError(err.response.message)
        setDialogueState({
          type: 'Error',
              show: true,
              message: "Invalid Coupon"
        })
			})
		}else{
			setCouponValue(false)
			setQuoteState(prevState=>({
				...prevState,
				total_amount : parseFloat(prevState.total_amount + parseInt(prevState.discount_amount || 0)),
				discount_amount : 0
			}))
			setFormData( prev => {
				return { ...prev, discount_amount:0}
			});	
		}
		
	}


  //////////////////////////////////////////////////////////////////////
  const [bankDetailsCheck, setBankDetailsCheck] = useState(1);
  const [cdCard, setCdCard] = useState("");
  const [bankAccount, setBankAccount] = useState("");
    const [bankAccountShow, setBankAccountShow]= useState(false);
    const [show, setShow] = useState(false);
    const [Countries, setCountries] = useState([]);

  const [bankState, setBankState] = useState({
    bank_name: "",
    account_name: "",
    account_number: "",
    routing_number: "",
    account_type: 1,
    account_nick: "",
    isprimary: 0,
    });
const [bankAcs, setBankAcs] = useState([]);
const [err, setErr] = useState({});

const [userCardList, setUserCardList] = useState([]);

useEffect(() => { 

  APIService.fetchCountry()
  .then(async (res) => {
    if (res.data.status == 200) {
      setCountries(res.data.data);
    }
  })
    .catch((error) => { });
 
//  APIService.fetchBankAccount()
//    .then((res) => setBankAcs(res.data.data))
//    .catch((err) => setErr(err));

   fetchCards();

}, [])


const fetchCards = () => { 
  APIService.getUserCard()
    .then((res) => {
      res.data &&
        res.data.cards &&
        setUserCardList(res.data.cards.length ? res.data.cards : []);
    })
    .catch((err) => console.log(err));
   }

  //  useEffect(()=>{
  //   if(props.location.state.payment_type === "card"){
  //     setBankDetailsCheck(1)
  //     setCdCard(props.location.state.payment_method_id)
  //   }else{
  //     setBankDetailsCheck(2)
  //     setBankAccount(props.location.state.payment_method_id)
  //   }
  // },[props])
 

  // const handleRadio = (value) => {
  //   // dispatch({ type: name, payload: { [name]: value, key: name } });
  //   setBankDetailsCheck(value);
  // };

  const handleBankChange = ({ target: { value, name } }) => {
    //   console.log(value, name);
      setBankState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
  const handleBankChangeRadio = (name, value) => { 
      setBankState((prevState) => ({
      ...prevState,
      [name]: value,
      }));
  }

  const handleBankSubmit = (e) => {
    e.preventDefault();
    let filterNickList = bankAcs.filter(
      (data) => data.account_nick === bankState.account_nick
    );
    let filterList = bankAcs.filter((data) => data.isprimary === 1);

    if (filterNickList.length) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Account nick name should be unique.",
      });
    } else if (bankState.isprimary === 1 && filterList.length) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Can't have multiple primary bank account.",
      });
    } else {
      APIService.addBankAccount(bankState)
        .then((res) => {
          APIService.fetchBankAccount()
            .then((res) => {
              setBankAcs(res.data.data);

              setBankState({
                bank_name: "",
                account_name: "",
                account_number: "",
                routing_number: "",
                account_type: 1,
                account_nick: "",
                isprimary: 0,
              });

              setBankAccountShow(false);
              // window.location.href = '/quotes-submit'
              //<Redirect to="quotes-submit"/>
              // window.$("#addNewBank-modal").toggler("modal");
              // data-dismiss="modal"

              // $("#btnSave").click(function () {
              //   //   $("#addNewBank-modal").modal("hide");
              //   $('button[type="submit"]').attr("data-dismiss", "modal");
              // });
            })
            .catch((err) => setErr(err));
        })
        .catch((err) => setErr(err));
    }
  };
  const [tick, setTick] = useState(0);
  const handleTick = (val) => {
    setTick(val);
  };
  // console.log("rrrrrrrrrrreeeeeeeeeee", cdCard, bankAccount, bankDetailsCheck)


	return (
    <>
      <Header />

      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  <h1 class="mb-0">
                    {" "}
                    Accept <span> Completion </span>
                  </h1>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>
      <section className="acceptCompletion-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 order-2 order-xl-1">
              <div className="acs-left-block">
                <form onSubmit={(e) => hanldeSubmit(e)}>
                  <div className="form-group">
                    <label> Tip for the Service Provider </label>
                    <div className="acsOr-group">
                      <select
                        className="form-control"
                        value={formData.tip_percentage}
                        onChange={handleChange}
                        name="tip_percentage"
                      >
                        <option selected disabled value={""}>
                          {" "}
                          Select a Tip %{" "}
                        </option>
                        <option value={0.05}> 5% </option>
                        <option value={0.09}> 9% </option>
                        <option value={0.1}> 10% </option>
                        <option value={0.15}> 15% </option>
                        <option value={0.2}> 20% </option>
                      </select>
                      <span> OR </span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Tip Amount $0.00"
                        onChange={handleChange}
                        name="tip_amount"
                        value={quoteState.tip_amount}
                        // pattern="[+-]?([0-9]*[.])?[0-9]+"
                        pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                        title="Please enter numeric value greater than or equal to $0.00"
                        maxLength="7"
                        // placeholder='$0.00'
                        
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label> Apply Coupons </label>
                    <div className="coupon-fleid">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Coupon code"
                        name="coupon_code"
                        onChange={handleChange}
                        value={formData.coupon_code}
                        disabled={quoteState.discount_amount}
                        
                      />
                      {quoteState.discount_amount ? (
                        <button className="btn text-red" onClick={handleCoupon}>
                          Remove{" "}
                        </button>
                      ) : (
                        <button className="btn text-red" onClick={handleCoupon}>
                          {" "}
                          Apply{" "}
                        </button>
                      )}
                    </div>
                  </div>


           
              
                {/* <div className="form-group group-12 primary-radio-group">
                  <label> Select Payment Method </label> */}
                                    <div className="form-group">
                    <label> Select Payment Method </label>
                  <div className="radio-block">
                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        // name="bondedIns-type"
                        id="bai-yes"
                        value={1}
                        name="bankDetailsCheck"
                        checked={bankDetailsCheck === 1 ? true : false}
                        // onChange={
                        //   handleProfileInformationChange
                        // }
                        // checked
                      />
                      <label
                        className="radio-label-control"
                        for="bai-yes"
                        // onClick={() => handleRadio(1)}
                      >
                        {" "}
                        Credit / Debit Card{" "}
                      </label>
                    </div>
{/* 
                    <div className="radio-group">
                      <input
                        type="radio"
                        className="radio-input d-none"
                        // name="bondedIns-type"
                        id="bai-no"
                        name="bankDetailsCheck"
                        checked={bankDetailsCheck === 2 ? true : false}
                        value={0}
                        // onChange={
                        //   handleProfileInformationChange
                        // }
                      />
                      <label
                        className="radio-label-control"
                        for="bai-no"
                        onClick={() => handleRadio(2)}
                      >
                        {" "}
                        Bank Account{" "}
                      </label>
                    </div> */}
                  </div>
                </div>
              
              {bankDetailsCheck === 1 ? (
                // <form
                //   onSubmit={(e) =>
                //     props.handleClick(e, bankDetailsCheck, cdCard)
                //   }
                // >
                <>
                  <div className="form-group group-xs-12">
                    <select
                      className="form-control"
                      // name="country"
                      value={cdCard}
                      onChange={(e) => setCdCard(e.target.value)}
                      name="cdCard"
                      required
                    >
                      <option disabled={true} value="">
                        {" "}
                        Select Credit Card / Debit Card *
                      </option>
                      {userCardList.map((item, index) => (
                        <option value={item.id} key={item + index}>
                          {item.card_nick}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="form-group group-12 mb-2">
                    <div class="addNewAddress-request">
                      <button
                        type="button"
                        class="btn text-btn"
                        data-toggle="modal"
                        data-target="#addNewCard-modal"
                        onClick={() => setShow(true)}
                      >
                        {" "}
                        <i class="fal fa-plus-circle"></i> Add New Card{" "}
                      </button>
                    </div>
                  </div>
                  </>

                // </form>
              ) : (
                // <form
                //   onSubmit={(e) =>
                //     props.handleClick(e, bankDetailsCheck, bankAccount)
                //   }
                // >
                //   <div className="form-group group-xs-12">
                //     <select
                //       className="form-control"
                //       // name="country"
                //       value={bankAccount}
                //       onChange={(e) => setBankAccount(e.target.value)}
                //       name="bankAccount"
                //       required
                //     >
                //       <option disabled={true} value="">
                //         {" "}
                //         Select Bank Account *
                //       </option>
                //       {bankAcs.map((ba) => (
                //         <option value={ba.id}> {ba.account_nick} </option>
                //       ))}
                //     </select>
                //   </div>

                //   <div class="form-group group-12">
                //     <div class="addNewAddress-request">
                //       <button
                //         type="button"
                //         class="btn text-btn"
                //         // data-toggle="modal"
                //         // data-target="#addNewBank-modal"
                //         onClick={() => setBankAccountShow(true)}
                //       >
                //         {" "}
                //         <i class="fal fa-plus-circle"></i> Add New Bank Account{" "}
                //       </button>
                //     </div>
                //   </div>




                // </form>
                <></>
              )}

{/* <div className="form-group form-checkbox">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                        />
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                      </div>
                    </div> */}

<div className='d-flex mt-5 mb-5'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                        </div>


            

                  <div className="form-group">
                    <label> Rate Service Provider </label>
                    <div className="rsp-star-block">
                      <div className="star-box">
                        {ratingArr.map((rate) => {
                          if (rate <= rating) {
                            return (
                              <i
                                className="fas fa-star star-fill"
                                onClick={() => setRating(rate)}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fas fa-star"
                                onClick={() => setRating(rate)}
                              ></i>
                            );
                          }
                        })}
                      </div>

                      <span className="orsp-text"> {rating}/5 </span>
                    </div>
                  </div>

                  <div className="form-group acc-group-list">
                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="arrivedOnTime"
                          name="sp_arrived_on_time"
                          onChange={handleChange}
                          value={0}
                        />

                        <label
                          className="custom-check-label"
                          for="arrivedOnTime"
                        >
                          {" "}
                          Arrived on Time{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="qualityOfWork"
                          name="sp_quality_of_work"
                          onChange={handleChange}
                          value={0}
                        />
                        <label
                          className="custom-check-label"
                          for="qualityOfWork"
                        >
                          {" "}
                          Quality of Work{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="cleanlines"
                          name="sp_cleanliness"
                          onChange={handleChange}
                          value={0}
                        />
                        <label className="custom-check-label" for="cleanlines">
                          {" "}
                          Cleanliness{" "}
                        </label>
                      </div>
                    </div>

                    <div className="form-checkbox ac-check-group">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="proessionalism"
                          name="sp_professionalism"
                          onChange={handleChange}
                          value={0}
                        />
                        <label
                          className="custom-check-label"
                          for="proessionalism"
                        >
                          {" "}
                          Professionalism{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div class="textarea-group">
                    <textarea
                      className="form-control"
                       maxLength="100"
                      rows="10"
                      placeholder="Enter feedback comments"
                      name="sp_feedback_comments"
                      onChange={handleChange}
                      value={formData.sp_feedback_comments}
                    ></textarea>
                     <span class="textarea-limit"> {100 - formData.sp_feedback_comments.length} Characters Limit </span>
                    </div>
                  </div>

                  <div className="formSubmit-ac">
                    <button type="submit" className="btn btn-red btn-md" disabled={tick === 0 || loader}>
                      {" "}
                      {loader ? "Processing ..." : "Confirm" }{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-xl-5 order-1 order-xl-2">
              <div className="ac-pro-box">
                <div className="accPro-figure">
                  <span className="accPro-gray-box"></span>
                  <img
                    // src="../images/user/user-1.jpg"
                    src={
                      config.endpoints.image_path +
                      spData?.profile_photo
                    }
                    className="img-fluid"
                    alt=""
                    title=""
                  />
                </div>
                <div className="accPro-detail">
                  <h5>
                    {" "}
                    {spData?.first_name}{" "}
                    {spData?.last_name}
                  </h5>
                  {/* <div className="accPro-rate">
                    <i className="fas fa-star star-fill"></i>
                    <i className="fas fa-star star-fill"></i>
                    <i className="fas fa-star star-fill"></i>
                    <i className="fas fa-star star-fill"></i>
                    <i className="fas fa-star star-fill"></i>
                    <span> 5 </span>
                  </div> */}
                   <div className="accPro-rate">
                                      <Rating rating={spData?.rating} />
                                        <span className="rating-count">
                                        {spData?.rating}
                                        </span>
                                      </div>

                  <span className="text-red accPro-req-id">
                    {" "}
                    Request ID : {requestData?.id}{" "}
                  </span>
                </div>

                <div className="accPro-calculation">
                  <div className="accPro-cal-item">
                    <span> Quote Price </span>
                    <div className="bl-separator"></div>
                    <h5>
                      {"  "}
                      $
                      {/* {parseFloat(quoteState.uplifted_labor_quote_amount +
                        quoteState.uplifted_material_quote_amount).toFixed(2)}{" "} */}
                        {parseFloat(quoteState.quote_price).toFixed(2)}{" "}
                    </h5>
                  </div>

                  {quoteState.additional_cost ? (
                    <div className="accPro-cal-item">
                      <span> Additional Cost </span>
                      <div className="bl-separator"></div>
                      <h5>
                        {" "}
                        $
                        {/* {parseFloat(quoteState.additional_labor_amount +
                          quoteState.additional_material_amount).toFixed(2)}{" "} */}
                          {parseFloat(quoteState.additional_cost).toFixed(2)}{" "}
                      </h5>
                    </div>
                  ) : null}

<div className="accPro-cal-item">
                      <span> Service Charge </span>
                      <div className="bl-separator"></div>
                      <h5> ${parseFloat(quoteState.service_charge).toFixed(2)} </h5>
                    </div>

                    <div className="accPro-cal-item">
                      <span> Tax Amount </span>
                      <div className="bl-separator"></div>
                      <h5> ${parseFloat(quoteState.tax_amount).toFixed(2)} </h5>
                    </div>

                  {quoteState.tip_amount ? (
                    <div className="accPro-cal-item">
                      <span> Tip Amount </span>
                      <div className="bl-separator"></div>
                      <h5> ${parseFloat(quoteState.tip_amount).toFixed(2)} </h5>
                    </div>
                  ) : null}

                  {quoteState.discount_amount ? (
                    <div className="accPro-cal-item">
                      <span> Total Discount </span>
                      <div className="bl-separator"></div>
                      <h5> -${parseFloat(quoteState.discount_amount).toFixed(2)} </h5>
                    </div>
                  ) : null}

                  <div className="accPro-cal-total">
                    <span>Total </span>
                    <h5> ${parseFloat(quoteState.total_amount).toFixed(2)} </h5>
                  </div>
                </div>
                <p className="accPro-cal-note d-flex justify-content-center">
                  {" "}
                  {/* This does not include the applicable taxes and service
                  charges. Please refer your invoice for final total amount.{" "} */}
                  Please refer 'My Transactions' tab for detailed invoice.
                </p>

                <div className="accPro-cal-bottom">
                  <div className="ardCatD-lt-inner">
                    <div className="catD-lt-item">
                      <div className="icon">
                        <img
                          src="../images/location-big-icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="it-content">
                        <span className="itl"> Service Location </span>
                        <span className="itv">
                          {" "}
                          {/* {requestData.address_1}, {requestData.address_2},
                          {requestData.cityGet.states.name},{" "}
                          {requestData.cityGet.countries.name} {" "}
                          {requestData.zip_code}{" "} */}
                          {requestData?.address_1 ? `${requestData?.address_1}, ` : ""}
                                        {/* {requestData?.address_2},{" "} */}
                                        {requestData?.address_2 ? `${requestData?.address_2}, ` : ""}
                                        
                                        {requestData?.cityGet?.name
                                          ? `${requestData?.cityGet?.name}, `
                                          : ""}
                                        {requestData?.cityGet?.states?.name
                                          ? `${requestData?.cityGet?.states?.name}, `
                                          : ""}{" "}
                                        
                                        
                                        {requestData?.cityGet?.countries?.name
                                          ? `${requestData?.cityGet?.countries?.name} `
                                          : ""}
                                          {requestData?.zip_code ? `- ${requestData?.zip_code}` : ""}
                        </span>
                      </div>
                    </div>
                    <div className="catD-lt-item">
                      <div className="icon">
                        <img
                          src="../images/calendar-big-icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="it-content">
                        <span className="itl"> Service Date and Time </span>
                        <span className="itv">
                          {" "}
                          {/* {requestData.service_date}, (
                          {requestData.timeGet.start_time.split(":")[0] +
                            "-" +
                            requestData.timeGet.end_time.split(":")[0]}
                          ){" "} */}
                           {dateWithFormate(
                                              requestData?.service_date
                                            )}
                                            &nbsp; (
                                            {dateWithFormate(
                                              requestData?.timeGet?.start_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}{" "}
                                            -{" "}
                                            {dateWithFormate(
                                              requestData?.timeGet?.end_time,
                                              "hA",
                                              "HH:mm:ss"
                                            )}
                                            )
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`scale_cus_modal addnewaddress-wrap ${
          bankAccountShow ? "show" : ""
        }`}
        id="addNewBank-modal"
        area-modal={bankAccountShow}
      >
        <div className="scale_modal_body scale_modal_lg">
          <div className="modal_inner">
            {/* <!-- <div className="scale_modal-close">
	        		<button type="button" className="slide-close" data-dismiss="modal" aria-label="Close"> <i className="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
            <div className="scm_header">
              <h2 className="scm_head-title"> Add Bank Account </h2>
            </div>
            <form onSubmit={(e) => handleBankSubmit(e)}>
              <div className="scm_body">
                <div>
                  <div className="row">
                    <div className="form-group fg-place group-6 group-xs-12">
                      <div
                        className={`placeholder ${
                          bankState.bank_name ? "d-none" : "d-block"
                        }`}
                      >
                        <label for="labor_quote_amount">
                          {" "}
                          Name of the Bank{" "}
                        </label>
                        <span className="star text-danger">*</span>
                      </div>

                      <input
                        type="text"
                        className="holer-control form-control"
                        value={bankState.bank_name}
                        name="bank_name"
                        onChange={handleBankChange}
                        required
                      />
                    </div>

                    <div className="form-group group-6 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_name ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Name on the Account </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="text"
                        className="holer-control form-control"
                        value={bankState.account_name}
                        name="account_name"
                        onChange={handleBankChange}
                        required
                      />
                    </div>

                    <div className="form-group group-6 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Account Number </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="number"
                        className="holer-control form-control"
                        value={bankState.account_number}
                        name="account_number"
                        onChange={handleBankChange}
                        maxlength="19"
                        required
                        pattern="[0-9 ]*"
                        title="Account Number should only contain numbers"
                      />
                    </div>
                    <div className="form-group group-6 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.routing_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Routing Number </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="number"
                        className="holer-control form-control"
                        value={bankState.routing_number}
                        name="routing_number"
                        onChange={handleBankChange}
                        required
                        pattern="[0-9]*"
                        title="Routing Number should only contain numbers"
                      />
                    </div>

                    <div className="form-group group-12 mb-2 primary-radio-group">
                      <label> Account Type </label>
                      <div className="radio-group">
                        <input
                          type="radio"
                          className="radio-input d-none"
                          name="account-type"
                          id="saving"
                          checked={bankState.account_type == 1 ? true : false}
                        />
                        <label
                          className="radio-label-control"
                          for="saving"
                          onClick={() =>
                            handleBankChangeRadio("account_type", 1)
                          }
                        >
                          {" "}
                          Savings{" "}
                        </label>
                      </div>
                      <div className="radio-block">
                        <div className="radio-group">
                          <input
                            type="radio"
                            className="radio-input d-none"
                            value={bankState.account_type}
                            name="account-type"
                            id="checking"
                            checked={bankState.account_type == 2 ? true : false}
                          />
                          <label
                            className="radio-label-control"
                            for="checking"
                            onClick={() =>
                              handleBankChangeRadio("account_type", 2)
                            }
                          >
                            {" "}
                            Checking{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group group-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_nick ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Account Nick Name </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="text"
                        className="holer-control form-control"
                        value={bankState.account_nick}
                        name="account_nick"
                        onChange={handleBankChange}
                        required
                      />
                    </div>
                    <div className="form-group group-12 primary-radio-group">
                      <label> Primary </label>
                      <div className="radio-block">
                        <div className="radio-group">
                          <input
                            type="radio"
                            className="radio-input d-none"
                            name="bank-primary"
                            id="bank-primary-yes"
                            checked={bankState.isprimary == 1 ? true : false}
                          />
                          <label
                            className="radio-label-control"
                            for="bank-primary-yes"
                            onClick={() =>
                              handleBankChangeRadio("isprimary", 1)
                            }
                          >
                            {" "}
                            Yes{" "}
                          </label>
                        </div>

                        <div className="radio-group">
                          <input
                            type="radio"
                            className="radio-input d-none"
                            name="bank-primary"
                            id="bank-primary-no"
                            checked={bankState.isprimary == 0 ? true : false}
                          />
                          <label
                            className="radio-label-control"
                            for="bank-primary-no"
                            onClick={() =>
                              handleBankChangeRadio("isprimary", 0)
                            }
                          >
                            {" "}
                            No{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scm_footer justify-content-center">
                    <button
                      type="button"
                      className="btn btn-black mr-3"
                      // data-dismiss="modal"
                      onClick={() => setBankAccountShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-red btn-md"
                      disabled={
                        !bankState.bank_name ||
                        !bankState.account_name ||
                        !bankState.account_number ||
                        !bankState.routing_number ||
                        !bankState.account_nick
                      }
                      id="btnSave"
                      // data-dismiss="modal"
                    >
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AddNewCard
        dialogueState={dialogueState}
        setDialogueState={setDialogueState}
        userCardList={userCardList}
        Countries={Countries}
        handleHide={() => setShow(false)}
        fetchCards={() => fetchCards()}
        show={show}
      />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Footer />
    </>
  );
} 

export default AcceptCompletion;
