import React, { useContext, useEffect, useState } from 'react';
import { APIService } from '../../Components/Apis/APIServices';
import images from '../../Utility/Images';
import RegistrationContext from './Context/RegistrationContext';
import validator from 'validator';
import FormValidation from './FormValidation/FormValidation';
import { formatSizeUnits } from '../../Utility/Utility';
import Dialogue from '../../Components/Common/Dialogue';
var profileUploadObj={
    size:'',
    name:'',
};
const PersonalInformation = ({ active }) => {
    const [state, dispatch] = useContext(RegistrationContext);
    const [Countries, setCountries] = useState([]);
    const [State, setState] = useState([]);
    const [City, setCity] = useState([]);
    const [viewPass, setViewPass] = useState(false)
    const [viewConfPass, setViewConfPass] = useState(false)
    const [dialogueState,setDialogueState] = useState({
        type : '',
        show : false,
        message : ''
    })
    useEffect(() => {
        fetchCountry();
        if (state.country) {
            fetchState(state.country)
        }
    }, [window.location]);
    const fetchCountry = () => {
        APIService.fetchCountry().then(async (res) => {
            if (res.data.status == 200) {
                setCountries(res.data.data);
            }
        }).catch((error) => {

        })
    }
    const fetchState = (countryId) => {
        let data = {
            keyword: '',
            country_id: countryId,
        }
        APIService.fetchState(data).then(async (res) => {
            if (res.data.status == 200) {
                setState(res.data.data.sort((a,b)=>a.name-b.name));
                console.log(res.data.data.sort((a,b)=>a.name > b.name && 1 || -1))
            }
        }).catch((error) => {

        })
    }
    const fetchCity = (stateId) => {
        let data = {
            keyword: '',
            state_id: stateId,
        }
        APIService.fetchCity(data).then(async (res) => {
            if (res.data.status == 200) {
                setCity(res.data.data);
            }
        }).catch((error) => {

        })
    }
    const fileValidation = (e)=>{
        var filePath = e.target.value;
        // Allowing file type
        var allowedExtensions = 
                /(\.jpg|\.jpeg|\.png|\.gif)$/i;
          
        if (!allowedExtensions.exec(filePath)) {
            return false;
        }
        return true;
    }
    const profileUpload = (e) => {
        
        if(e.target.files[0].size>5242880){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'File size exceeds 5MB'
            })
            return;
        }if(!fileValidation(e)){
            setDialogueState({
                type : 'Error',
                show:true,
                message : 'File format not supported.'
            })
            return;
        }else{
            profileUploadObj={
                name:e.target.files[0].name,
                size:e.target.files[0].size
            }
            dispatch({ type: 'profile_photo', payload: { ['profile_photo']: e.target.files[0], key: 'profile_photo' } })
        }

    }
    
    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name == "country") {
            fetchState(value);
            dispatch({ type: name, payload: { [name]: value, key: name } })
        } else if (name == "state") {
            fetchCity(value)
            dispatch({ type: name, payload: { [name]: value, key: name } })
        } else if (name == "profile_upload") {
            profileUpload(e);
        } else if (name == "mobile" || name=="zip_code") {
            if(name === "zip_code" && (state.zip_code.length === 4 && value.length === 5)){
                value+='-'
            }else if(name === "zip_code" && value.length === 6){
                value = value.replace('-','')
            }
            if(value.length <= 10){
                console.log(value)
            dispatch({ type: name, payload: { [name]: value, key: name } })
            }
        }else {
            dispatch({ type: name, payload: { [name]: value, key: name } })
        }
    }


    const  handleRadio=(name,value)=>{
        dispatch({ type: name, payload: { [name]: value, key: name } })
    }
    return (
        <>
            <div className="reg-form-tab" style={active ? { display: 'flex' } : { display: 'none' }}>

                <div className="cus-fileUpload-box form-group group-12" >
                    <div className="cus-fileUpload-group">
                        <input type="file" className="cus-fileUpload-control" name="profile_upload" onChange={handleChange} id="profileImg-input" style={{ display: 'none' }} />
                        <label for="profileImg-input" className="mb-0 cus-fileUpload-label">
                            <div className="profileUp-inner">
                                <div className="upload-here">
                                    <img src={state.profile_photo && URL.createObjectURL(state.profile_photo)} className="img-fluid" style={{height:"100%"}} alt="" />
                                </div>
                                <div className="upload-content">
                                    <h5> Upload  Your Profile Image </h5>
                                    <span> {profileUploadObj.name?profileUploadObj.name:'No file selected yet!'} </span>
                                    <span>{profileUploadObj.size?formatSizeUnits(profileUploadObj.size):0}  -  5 MB Max Size </span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="form-group fg-place group-4 group-xs-12">
                    <div className={`placeholder ${state.first_name ? 'd-none' : 'd-block'}`} >
                        <label for="firstName"> First Name </label>
                        <span className="star text-danger">*</span>
                    </div>
                    <input type="text" className="holer-control form-control " onChange={handleChange} name="first_name"   value={state.first_name} />
                </div>

                <div className="form-group fg-place group-4 group-xs-12">
                    <div className={`placeholder ${state.middle_name ? 'd-none' : 'd-block'}`} >
                        <label for=""> Middle Name </label>
                    </div>
                    <input type="text" className="holer-control form-control" onChange={handleChange} name="middle_name" value={state.middle_name} />
                </div>

                <div className="form-group fg-place group-4 group-xs-12">
                    <div className={`placeholder ${state.last_name ? 'd-none' : 'd-block'}`} >
                        <label for=""> Last Name </label>
                        <span className="star">*</span>
                    </div>
                    <input type="text" className="holer-control form-control" onChange={handleChange} name="last_name" value={state.last_name}  />
                </div>

                <div className="form-group fg-place group-6 group-xs-12">
                    <div className={`placeholder ${state.address_1 ? 'd-none' : 'd-block'}`} >
                        <label for=""> Address Line 1 </label>
                        <span className="star">*</span>
                    </div>
                    <input type="text" className="holer-control form-control" onChange={handleChange} name="address_1" value={state.address_1}  />
                </div>

                <div className="form-group fg-place group-6 group-xs-12">
                    <div className={`placeholder ${state.address_2 ? 'd-none' : 'd-block'}`} >
                        <label for=""> Address Line 2 </label>
                    </div>
                    <input type="text" className="holer-control form-control" onChange={handleChange} name="address_2" value={state.address_2} />
                </div>


                <div className="form-group group-3 group-md-6 group-xs-12">
                    <select className="form-control" name="country" onChange={handleChange} >
                        <option >  Country *</option>
                        {Countries.map((res) =>
                            <option key={res.id} value={res.id} selected={state.country == res.id ? true : false}> {res.name} </option>
                        )}
                    </select>
                </div>
                <div className="form-group group-3 group-md-6 group-xs-12">
                    <select className="form-control" name="state" onChange={handleChange}>
                        <option disabled={true} selected={true}> State *</option>
                        {State.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                    </select>
                </div>
                <div className="form-group group-3 group-md-6 group-xs-12 ">
                    <select className="form-control" name="city" onChange={handleChange} >
                        <option disabled={true} selected={true}> City *</option>
                        {City.map((res) =>
                            <option key={res.id} value={res.id}> {res.name} </option>
                        )}
                    </select>
                </div>

                <div className="form-group fg-place group-3 group-md-6 group-xs-12">
                    <div className={`placeholder ${state.zip_code ? 'd-none' : 'd-block'}`} >
                        <label for=""> Zip Code </label>
                        <span className="star">*</span>
                    </div>
                    <input type="text" className="holer-control form-control" onChange={handleChange} name="zip_code" value={state.zip_code}  
                     pattern="[0-9-]*"
                     title="Zip code must contain numbers only"
                    />
                </div>



                <div className="form-group fg-place group-6 group-xs-12">
                    <div className={`placeholder ${state.email ? 'd-none' : 'd-block'}`} >
                        <label for=""> Email ID </label>
                        <span className="star">*</span>
                    </div>
                    <input type="text" className="holer-control form-control" name="email" onChange={handleChange} value={state.email}  />
                </div>

                <div className="form-group group-6 group-xs-12">
                    <div className="phoneNumber-group">
                        <div className="phoneNo-code-block">
                            <select className="form-control" name="country_code" onChange={handleChange} value={state.country_code} >
                                <option value="1">+1</option>
                                {/* <option value="91">+91</option>
                                <option value="22">+22</option>
                                <option value="322">+322</option>
                                <option value="412">+412</option>
                                <option value="225">+225</option> */}
                            </select>
                        </div>
                        <div className="fg-place w-100">
                            <div className={`placeholder ${state.phone ? 'd-none' : 'd-block'}`} >
                                <label for=""> Mobile Number </label>
                                <span className="star">*</span>
                            </div>
                            <input type="text" className="mobileNumber-control holer-control form-control" onChange={handleChange} name="phone" value={state.phone} maxlength="10"  />
                        </div>
                    </div>
                </div>


                <div className="form-group group-6 group-xs-12">
                    <div className="password-group">
                        <input type={viewPass?"text":"password"} id="password-field" name="password" value={state.password} onChange={handleChange} placeholder="Password" className="form-control" />
                        <button type="button" onClick={()=>setViewPass(prevState=>(!prevState))} className="toggle-password password-eye"> 
                        {/* <i className="far fa-eye-slash eye-btn"></i>  */}
                        {viewPass?
                                                     <i className="far fa-eye-slash"></i>:
                                                     <i className="far fa-eye eye-btn"></i> 
                                                    }
                        </button>
                    </div>
                </div>

                <div className="form-group group-6 group-xs-12">
                    <div className="password-group">
                        <input type={viewConfPass?"text":"password"} id="confirm-password-field" placeholder="Confirm Password" name="confirm_password" value={state.confirm_password} onChange={handleChange} className="form-control" />
                        <button type="button" onClick={()=>setViewConfPass(prevState=>(!prevState))} className="toggle-password password-eye"> 
                        {/* <i className="far fa-eye-slash eye-btn"></i>  */}
                        {viewConfPass?
                                                     <i className="far fa-eye-slash"></i>:
                                                     <i className="far fa-eye eye-btn"></i> 
                                                    }
                        </button>
                    </div>
                </div>
                {/* <div className="form-group form-checkbox  mt-3">
                    <div className="custom-check-block">
                        <input type="checkbox" className="d-none" id="iagree" name="iagree" onChange={()=>handleRadio('iagree',state.iagree==1?0:1)}  checked={state.iagree ===1?true:false} />
                        <label className="custom-check-label" for="iagree"> I agree to the &nbsp;<a href="/term-conditions" target="_blank"> Terms and Conditions &nbsp;</a> and &nbsp;<a href="/privacy-policy" target="_blank"> Privacy Policy* </a> </label>
                    </div>
                </div> */}
                <div className='d-flex mt-3 mb-3'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="iagree"
                          name="iagree"
                          onClick={()=>handleRadio('iagree',state.iagree==1?0:1)}
                          checked={state.iagree ===1?true:false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <span className="custom-check-label" for="tick">
                          {" "}
                          <i>I agree to the <a href="/term-conditions" target="_blank" className='text-danger'> Terms and Conditions </a> and <a href="/privacy-policy" target="_blank" className='text-danger'> Privacy Policy* </a></i>
                        </span>
                        </div>
            </div>
            <Dialogue state={dialogueState} setState={setDialogueState}/>
        </>
    );
}

export default PersonalInformation;
