import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
// import { APIService } from '../../Components/Apis/APIServices';
// import images from '../../Utility/Images';
import config from "../../Components/Apis/config";
import HomeContext from './HomeContext/HomeContext';
import Slider from 'react-slick';


import Rating from "../Rating";

const WhatHappeningSection = () => {
  const [state, dispatch] = useContext(HomeContext);
  
  const [hideOrShow, setHideOrShow] = useState("hidden")
	const [animatedName, setAnimatedName] = useState("none")
  const [animated2Name, setAnimated2Name] = useState("none")

	useEffect(()=>{
    if(state.serviceRequests.length !== 0){
      var target = document.querySelector('.happening-section');

      document.addEventListener('scroll', () => {
        if (window.scrollY >= target.getBoundingClientRect().top) {
        // console.log('I have been reached 2');
        setHideOrShow("visible")
        setAnimatedName("fadeInUp")
        setAnimated2Name("zoomIn")
        }
      })
    }
		
	},[state])

  let length =
    state.serviceRequests.length % 2 == 0
      ? state.serviceRequests.length + 1
      : state.serviceRequests.length;
  let srsLeft = state.serviceRequests.slice(0, length / 2);
  let srsRight = state.serviceRequests.slice(length / 2, length);

 


  var slider = this;

  const sliderSection = (serviceRequest, position) => {
    const settings = {
      // variableWidth: true,
      arrows:false,

      infinite: true,
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 0,
      easing: 'linear',
      cssEase: 'linear',
      pauseOnHover: false,
      rtl: position === 'right' ? true : false,
      slidesToShow: 6,
      slidesToScroll: -1,
      responsive: [
        { breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: -1 } },
        { breakpoint: 1100, settings: { slidesToShow: 4, slidesToScroll: -1 } },
        { breakpoint: 900, settings: { slidesToShow: 3, slidesToScroll: -1 } },
        { breakpoint: 760, settings: { slidesToShow: 2, slidesToScroll: -1 } },
        { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: -1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: -1 } },
      ],
      // margin: "10px"
    };

    const truncateTitle = (text, truncateLength) => {
      return text && text.length > truncateLength
        ? `${text.substring(0, truncateLength)}...`
        : text;
    };

    const getLocation = (sr) => {
      const location = `${sr.cityGet.name}, ${sr.cityGet.states && sr.cityGet.states.name}`;
      return location;
    };

    // console.log("////////////////", serviceRequest)

    return (
      <Slider {...settings} ref={(c) => (slider = c)}>
        {serviceRequest.length > 0 &&
          serviceRequest.map((sr) => (
            <div>
              <Link 
              to={{
                pathname: "/request-detail/" + sr.id,
                state: {
                 showDetailCard: true
                },
              }}
              className='happening-item'>
                <div className='happening-user-img'>
                  <img src={
                    // config.baseurl +
                    // config.endpoints.cms_image_path +
                    // sr && sr.category && sr.category.category_image
                    config.endpoints.image_path +
                                          sr?.user?.profile_photo
                  } className='img-fluid' alt='' />
                </div>
                <div className='happening-content'>
                  <div className='rating'>
                 
                   <Rating rating={sr?.user?.rating} />
                                        <span className="rating-count">
                                        {sr?.user?.rating}
                                        </span>
                  </div>
                  <div className="d-flex justify-content-end text-dark">{sr?.id ? `SR ID: ${sr?.id}`: null}</div>
                  <div className='happening-inner-content'>
                    <span className='location'>{getLocation(sr)}</span>
                    <span className='service-type'>
                      {truncateTitle(sr?.serviceType?.name, 20)}
                    </span>
                    <span className='service-des'>
                      {truncateTitle(sr.title, 36)}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </Slider>
    );
  };

  return (
    <>
      <section className='happening-section pad-t-50'>
        <div className='container' style={{zIndex: "1", position: "relative"}}>
          <div className='row'>
            <div className='col-12 text-center wow fadeInUp animated' style={{visibility: hideOrShow, animationName: animatedName}}>
              <div className='section-title'>
                <h2>
                  {' '}
                  Explore what's{' '}
                  <span className='text-orange font-weight-bold'>
                    {' '}
                    Happening{' '}
                  </span>
                </h2>
              </div>
              <div className='section-des'>
                <p> Explore what others are getting done. </p>
              </div>
            </div>
          </div>
        </div>
        <div className='home-happening-list container-fluid wow zoomIn animated' style={{visibility: hideOrShow, animationName: animated2Name}}>
          <div className='row' style={{width: "99%"}}>
            <div className='col-12 p-0'>
              {sliderSection(srsLeft, 'left')}
              {sliderSection(srsRight, 'right')}
            </div>
            <div className='col-12'>
              <div className='happening-more text-center'>
                <Link to='/all-requests' className='btn btn-red btn-sm'>
                  {' '}
                  See All{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatHappeningSection;
