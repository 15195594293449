import React, {useState, useEffect} from 'react';
import images from '../../Utility/Images';
import { Link } from "react-router-dom";
import InlineAutocomplete from "react-inline-autocomplete";
import "react-inline-autocomplete/dist/index.css";
import { Hint } from "react-autocomplete-hint";

import { APIService } from "../../Components/Apis/APIServices";
import config from "../../Components/Apis/config";



const MarketPlaceSection = () => {

 
const [autoOptions, setAutoOptions] = useState([])
    // const [formData, setFormData] = useState({
    //   service: "",
    //   zip_code: "",
    // });
  
  // const [serviceId, setServiceId] = useState('')

  const [service , setService] = useState("")
  const [cityState , setCityState] = useState("")
  const [herography, setHerography] = useState(images.servicesMarketplace)
  
  useEffect(() => { 
    APIService.allCategories()
      .then((response) => {
        if (response.status === 200) {
          // console.log("////////////////////dddddddddd", response)
          let options = []
        
          response?.data?.data?.map((item, index) => {
            let category = { id: item?.id, label: item?.category_name }
            options.push(category)

            if (item?.subcategories.length > 0) {
              item?.subcategories?.map((sub, index2) => {
                let subcategories = {
                  id: sub?.id,
                  label: sub?.sub_category_name,
                };

                options.push(subcategories)

                if (sub?.service_types.length > 0) {
                  sub?.service_types?.map((serviceType) => {
                    let services = {
                      id: serviceType?.id,
                      label: serviceType?.name,
                    };

                    options.push(services)
                  })
                }

              })
            }
          })

          setAutoOptions(options)
        }
      })
      .catch((error) => console.log(error));


      APIService.herography()
      .then((response) => {
        if (response.status === 200) {
          // console.log("rerwerewqqweqweqwe", response)
          setHerography(response.data.contents[0] || images.servicesMarketplace)
        }
      })
      .catch((error) => console.log(error));


  },[])

  // const onInputChange = (e) => { 

  //   if (e.target.name === "service") { 
  //     setServiceId(
  //       autoOptions.filter(
  //         (item) => item.label.toUpperCase() === e.target.value.toUpperCase()
  //       )[0]?.id
  //     );
  //   }

    
     
  //       setFormData((prev) => { 
  //           return {
  //               ...prev,
  //               [e.target.name]: e.target.value
  //           }
  //       })
  //   }
  
 
  // const options = [
  //   { id: 1, label: "orange" },
  //   { id: "2", label: "banana" },
  //   { id: 3, label: "apple" },
  // ];

  // console.log("llllllllllllllll", service)

    return (
      <>
        <section className="home-banner-section">
          <div className="circle-box slideInUp"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="hb-caption wow fadeInUp animated">
                  <h1>
                    {" "}
                    The Services Marketplace 
                    <br />
                    <span>
                      {" "}
                      Making life easy.{" "}
                    </span>{" "}
                  </h1>

                  <form className="hb-search-form" action="service-categories">
                    <div className="form-group">
                      <div className="form-group-inner d-flex">
                        <span
                          className="d-none d-xl-flex align-items-center m-2"
                          style={{
                            color: "#0779e4",
                            
                          }}
                        >
                          {" "}
                          <i className="far fa-search"></i>{" "}
                        </span>

                        <Hint options={autoOptions} allowTabFill>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for a Service"
                            name="service"
                            // value={formData?.service}
                            // onChange={(e)=>onInputChange(e)}
                            value={service}
                            onChange={(e)=> setService(e.target.value)}
                          />
                        </Hint>
                      </div>

                      <div className="form-group-inner d-flex flex-row">
                        {/* <Hint options={options} allowTabFill> */}
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`${window.screen.width <= 710 ? "" : "Enter"} City or State`}
                            name="zip_code"
                            // value={formData?.zip_code}
                            // onChange={(e) => onInputChange(e)}
                            value={cityState}
                            onChange={(e)=> setCityState(e.target.value)}
                          />
                        {/* </Hint> */}

                        <span
                          className="d-flex d-md-none align-items-center"
                          style={{
                            backgroundColor: "red",
                            padding:"8px",
                            // borderRadius: "25%"
                            
                          }}
                        >
                          {" "}
                          <Link
                      to={{
                        pathname: "/service-categories",
                        state: {
                          // filterSrs: filterSrs,
                          // completeFilterData: completeFilterData
                          // searchItem: formData,
                          searchItem: {
                            service: service,
                            cityState:cityState
                          }
                        },
                      }}
                      className="d-block d-md-none"
                      data-rel="grd-1"
                    >
                    <i className="far fa-search text-white"></i>{" "}
                    </Link>
                          
                        </span>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "/service-categories",
                        state: {
                          // filterSrs: filterSrs,
                          // completeFilterData: completeFilterData
                          // searchItem: formData,
                          searchItem: {
                            service: service,
                            cityState:cityState
                          }
                        },
                      }}
                      className="d-none d-md-block"
                      data-rel="grd-1"
                    >
                      <button type="submit" className="btn btn-sm btn-red">
                        {" "}
                        Find Now{" "}
                      </button>
                    </Link>
                  </form>

<div className='d-flex w-100 justify-content-center mt-3 mt-xl-5 mb-3'>
                  {/* <div className="hb-down-arrow"></div> */}
                  <div>
                    <Link
                      to={{
                        pathname: "/create-my-request",
                        // state: { ...data },
                      }}
                      className="btn btn-red btn-md ardCat-submitQ-btn2"
                    >
                      Create My Request
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/all-requests",
                        // state: { ...data },
                      }}
                      className="btn btn-red btn-md ardCat-submitQ-btn2 ml-3"
                    >
                      Submit My Quote
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

              <div className="d-none d-xl-block col-lg-5">
                <div className="hb-content-img wow fadeInUp animated">
                  <img
                    // src={images.servicesMarketplace}
                    src={config.endpoints.image_path + herography.image}
                    className="img-fluid"
                    alt=""
                  />
                  {
                    herography?.video_src ?
                    <div className="voice-videoPlay-block">
                    <a href={void (0)} className="play-btn" id="voice-1" onClick={()=>window.open(herography?.video_src)}><i className="fas fa-play"></i></a>
                    </div>    
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default MarketPlaceSection;
