import React, { useState, useEffect, useCallback } from "react";
import { APIService } from "../../../Components/Apis/APIServices";
import config from "../../../Components/Apis/config";
import Dialogue from "../../../Components/Common/Dialogue";

const ComapnyInformation = (props) => {
    const [
      comapnyInformationCountriesData,
      setComapnyInformationCountriesData,
    ] = useState([]);
    const [
      comapnyInformationStateData,
      setComapnyInformationStateData,
    ] = useState([]);
    const [
      comapnyInformationCityData,
      setComapnyInformationCityData,
    ] = useState([]);


  const [companyInfomation, setCompanyInfomation] = useState({});
//   const [businessLicenseImage, setBusinessLicenseImage] = useState("");
  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });

  const [processing, setProcessing] = useState(false)

    useEffect(() => {
      fetchCountry();
    APIService.getCompanyInformation()
      .then((res) => {
        if (res.status === 200) {
            setCompanyInfomation(res?.data?.data[0]);
            if (res?.data?.data[0].c_state !== null || res?.data?.data[0].c_state !== undefined || res?.data?.data[0].c_state !== "") { 
                comapnyInformationFetchCity()
            }
            if (
              res?.data?.data[0].c_country !== null ||
              res?.data?.data[0].c_country !== undefined ||
              res?.data?.data[0].c_country !== ""
            ) {
              comapnyInformationFetchState();
            }
        }
      })
      .catch((err) => {});
  }, []);
    
    const fetchCountry = useCallback(() => {
      APIService.fetchCountry()
        .then(async (res) => {
          if (res.data.status == 200) {
          
            setComapnyInformationCountriesData(
              res.data.data.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        })
        .catch((error) => {});
    }, [companyInfomation]);

  //Comapny information

  const comapnyInformationFetchState = useCallback(
    (countryId) => {
      let data = {
        keyword: "",
        country_id: countryId,
      };
      APIService.fetchState(data)
        .then(async (res) => {
          if (res.data.status == 200) {
            setComapnyInformationStateData(
              res.data.data.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        })
        .catch((error) => {});
    },
    [companyInfomation]
  );

  const comapnyInformationFetchCity = useCallback(
    (stateId) => {
      let data = {
        keyword: "",
        state_id: stateId,
      };
      APIService.fetchCity(data)
        .then(async (res) => {
          if (res.data.status == 200) {
            setComapnyInformationCityData(
              res.data.data.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        })
        .catch((error) => {});
    },
    [companyInfomation]
  );

  const handleComapnyInformationChange = (ele) => {
    let value = ele.target.value;
    let name = ele.target.name;

    if (name === "c_country") {
      comapnyInformationFetchState(value);
    } else if (name === "c_state") {
      comapnyInformationFetchCity(value);
    }

    // if (ele.target.files) {
    //   setBusinessLicense((prevState) => {
    //     return { ...prevState, [name]: ele.target.files[0] };
    //   });
    // } else {
    setCompanyInfomation((prevState) => {
      return { ...prevState, [name]: value };
    });
    // }
  };

  const onCompanyInformationSubmit = (event) => {
      event.preventDefault();
      
    //   console.log("................///////////////////", companyInfomation)

    if(processing){
      return null
    }

    setProcessing(true)

    APIService.updateComapnyInformation(companyInfomation)
      .then((res) => {
        res?.data?.status === 200
          ? setDialogueState({
              type: "Success",
              show: true,
              message: "Company Information updated successfully.",
            })
          : setDialogueState({
              type: "Error",
              show: true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
          setProcessing(false)
      })
      .catch((e) => {
        console.log("error", e)
        setProcessing(false)
      });
  };

  return (
    <>
      <form onSubmit={(e) => onCompanyInformationSubmit(e)}>
      <div className="form-group group-12 mb-3 ml-0">
                      <p className="text-danger">
                      This information is not visible to other users.
                      </p>
                     
                    </div>
        <div className="cpp-form-block">
          <div className="form-group fg-place group-4 group-md-6 group-xs-12">
            <h5>Company Name</h5>
            {/* <div className="placeholder">
                                    <label for=""> Company Name </label>
                                  </div> */}
            <input
              type="text"
              className="holer-control form-control"
              value={companyInfomation?.company_name}
              name="company_name"
              onChange={handleComapnyInformationChange}
              placeholder="Company Name"
            />
          </div>

          <div className="form-group fg-place group-4 group-md-6 group-xs-12">
            <h5>Address 1</h5>
            {/* <div className="placeholder">
                                    <label for=""> Address Line1 </label>
                                  </div> */}
            <input
              type="text"
              className="holer-control form-control"
              value={companyInfomation?.c_addresline_1}
              name="c_addresline_1"
              onChange={handleComapnyInformationChange}
              placeholder="Address Line 1"
            />
          </div>

          <div className="form-group fg-place group-4 group-md-6 group-xs-12">
            <h5>Address 2</h5>
            {/* <div className="placeholder">
                                    <label for=""> Address Line2 </label>
                                  </div> */}
            <input
              type="text"
              className="holer-control form-control"
              value={companyInfomation?.c_addresline_2}
              name="c_addresline_2"
              onChange={handleComapnyInformationChange}
              placeholder="Address Line 2"
            />
          </div>

          <div className="form-group group-3 group-md-6 group-xs-12">
            <h5>Country</h5>
            <select
              className="form-control"
              value={companyInfomation?.c_country}
              name="c_country"
              onChange={handleComapnyInformationChange}
              placeholder="Country"
            >
              <option disabled={true} selected={true}>
                Select Country
              </option>
              {comapnyInformationCountriesData.length
                ? comapnyInformationCountriesData.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="form-group group-3 group-md-6 group-xs-12">
            <h5>State</h5>
            <select
              className="form-control"
              value={companyInfomation?.c_state}
              name="c_state"
              onChange={handleComapnyInformationChange}
              placeholder="State"
            >
              <option disabled={true} selected={true}>
                Select State
              </option>
              {comapnyInformationStateData.length
                ? comapnyInformationStateData.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="form-group group-3 group-md-6 group-xs-12">
            <h5>City</h5>
            <select
              className="form-control"
              value={companyInfomation?.c_city}
              name="c_city"
              onChange={handleComapnyInformationChange}
              placeholder="city"
            >
              <option disabled={true} selected={true}>
                Select City
              </option>
              {comapnyInformationCityData.length
                ? comapnyInformationCityData.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="form-group fg-place group-3 group-md-6 group-xs-12">
            <h5>Zip Code</h5>
            {/* <div className="placeholder">
                                    <label for=""> Zip Code </label>
                                  </div> */}
            {/* <input
              type="number"
              className="holer-control form-control"
              value={companyInfomation?.c_zipcode}
              name="c_zipcode"
              onChange={handleComapnyInformationChange}
              placeholder="Zip Code"
              maxLength={6}
            /> */}
            <input
                          type="text"
                           value={companyInfomation?.c_zipcode}
                          onChange={(e) => {
                            let value = e.target.value;

                            if (companyInfomation?.c_zipcode.length === 4 && value.length === 5) {
                              value += "-";
                            } else if (value.length === 6) {
                              value = value.replace("-", "");
                            }

                            setCompanyInfomation((prev) =>{
                              return({...companyInfomation, c_zipcode:value})
                            });
                          }}
                          className="holer-control form-control"
                          maxLength="10"
                          minLength="5"
                          pattern="[0-9-]*"
                          title="Zip code must contain numbers only"
                          placeholder="Zip Code"
                          // disabled={Auth.isAuthLogIn() ? true : false}
                        />
          </div>

          <div className="form-group fg-place group-6 group-xs-12">
            <h5>Email Address</h5>
            {/* <div className="placeholder">
                                    <label for=""> Email ID </label>
                                  </div> */}
            <input
              type="email"
              className="holer-control form-control"
              value={companyInfomation?.c_emailid}
              name="c_emailid"
              onChange={handleComapnyInformationChange}
              placeholder="Email"
            />
          </div>

          <div className="form-group group-6 group-xs-12">
            <h5>Phone Number</h5>
            <div className="phoneNumber-group">
              <div className="phoneNo-code-block">
                <select
                  className="form-control"
                  value={companyInfomation?.phoneNoCode}
                  name="phoneNoCode"
                  onChange={handleComapnyInformationChange}
                  placeholder="Phone Code"
                >
                  <option>+1</option>
                  <option>+2</option>
                  <option>+3</option>
                  <option>+4</option>
                  <option>+5</option>
                </select>
              </div>

              <div className="fg-place w-100">
                {/* <div className="placeholder">
                                        <label for=""> Phone Number </label>
                                      </div> */}
                <input
                  type="text"
                  className="mobileNumber-control holer-control form-control"
                  value={companyInfomation?.c_phone_number}
                  name="c_phone_number"
                  onChange={handleComapnyInformationChange}
                  placeholder="Phone Number"
                  pattern="[0-9]{10}"
                  title="Phone number should have 10 numeric only"
                  maxLength="10"
                />
              </div>
            </div>
          </div>

          <div className="form-submit text-center group-12">
          <button type="button" class="btn btn-sm btn-black" onClick={()=>{
              setDialogueState({
                type: "Warning",
                show: true,
                message: "All your changes will be lost. Do you want to continue?",
                refresh: true,
                yesButton: true
              });
            }}>
              {" "}
              Cancel{" "}
            </button>
            <button type="submit" className="btn btn-sm btn-red ml-4" disabled={processing}>
            {processing ? "Processing..." : "Save"}{" "}
            </button>
          </div>
        </div>
      </form>
      <Dialogue state={dialogueState} setState={setDialogueState} />
    </>
  );
};

export default ComapnyInformation;
