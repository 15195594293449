import React, { useState, useEffect } from "react";
import { APIService } from "../../../Components/Apis/APIServices";
import config from "../../../Components/Apis/config";
import Dialogue from "../../../Components/Common/Dialogue";

const DriverLicense = (props) => { 
    const [driverLicence, setDriverLicence] = useState({});
    const [driversLicensePhoto, setDriversLicensePhoto] = useState("");
    const [dialogueState, setDialogueState] = useState({
      type: "",
      show: false,
      message: "",
    });
    const [processing, setProcessing] = useState(false)

    useEffect(() => { 

        APIService.getUserdrivingLicense()
      .then((res) => {
        // console.log(
        //   " api testing ,",
        //   res?.data?.data[0].dl_issue_date.split("T")[0]
        // );
        if (res.status === 200) {
          let license = res?.data?.data[0];
          license.dl_issue_date = res?.data?.data[0].dl_issue_date.split(
            "T"
          )[0];
          license.dl_expiry_date = res?.data?.data[0].dl_expiry_date.split(
            "T"
          )[0];

          setDriversLicensePhoto(config.endpoints.image_path + license.image);

          setDriverLicence({
            ...license, 
            user_dob: license.user_dob.split("T")[0]});
        }
      })
      .catch((err) => { });
    }, [])
    

    const handleDriverLicenseChange = (ele) => {
      let value = ele.target.value;
      let name = ele.target.name;

      let photoObj = {};
      if (name === "image") {
        photoObj.image_updated = true;
        photoObj.prev_photo = driverLicence.image;
        let reader = new FileReader();
        reader.onload = (e) => {
          //setProfilePhoto(e.target.result);
        };
        reader.readAsDataURL(ele.target.files[0]);
        value = ele.target.files[0];
        setDriversLicensePhoto(URL.createObjectURL(ele.target.files[0]));
      }

      if (ele.target.files) {
        setDriverLicence((prevState) => {
          return { ...prevState, [name]: value, ...photoObj };
        });
      } else {
        setDriverLicence((prevState) => {
          return { ...prevState, [name]: value };
        });
      }
    };

    const onDriverLicenseSubmit = (event) => {
      event.preventDefault();

      // console.log(".................../////////////////// ", driverLicence)

      if(driverLicence.image === "" || driverLicence.image === undefined || driverLicence.image === null){
        setDialogueState({
          type: "Warning",
          show: true,
          message: "Please upload your driving license image.",
        })
        return null
      }

      if(processing){
        return null
      }

      setProcessing(true)

      APIService.updateUserDrivingLicense(driverLicence)
        .then((res) => {
          // console.log("res is", res.response)
          res?.data?.status === 200
            ? setDialogueState({
                type: "Success",
                show: true,
                message: "Driving License updated successfully.",
              })
            : setDialogueState({
                type: "Error",
                show: true,
                message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
          setProcessing(false)
        })
        .catch((e) => {
          // console.log("eeeeeeeeeeessssssssssssss", res)
        //   setDialogueState({
        //     type: "Error",
        //     show: true,
        //     message: e.response.data.message,
        //   });
        setProcessing(false)
        });
    };

    const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }

    
    return (
        <>
      <form onSubmit={(e) => onDriverLicenseSubmit(e)}>
      <div className="form-group group-12 mb-3 ml-0">
                      <p className="text-danger">
                      This information is not visible to other users.
                      </p>
                     
                    </div>
        <div class="cpp-form-block">
          <div class="form-group fg-place group-4 group-xs-12">
            {/* <div class="placeholder">
                                    <label for=""> Driver’s License ID </label>
                                    <span class="star">*</span>
                                  </div> */}
                                  <h5>Driver's License Number</h5>
            <input
              type="text"
              class="holer-control form-control"
              value={driverLicence?.dl_id}
              name="dl_id"
              onChange={handleDriverLicenseChange}
              placeholder="Driver’s License ID *"
              required
            />
          </div>

          <div
            className="form-group date1 group-4 group-xs-12 datepicker-calendar"
            id="datepicker6"
            data-target-input="nearest"
          >
            <h5>Date of birth</h5>
            <input
              type="date"
              title="Enter Issue Date"
              className="form-control"
              placeholder="Issue Date"
              data-target="#datepicker6"
              data-toggle="datetimepicker"
              value={driverLicence?.user_dob}
              name="user_dob"
              onChange={handleDriverLicenseChange}
              // max="2022-02-20"
              max={getTodaysDate()}
              required
              />
          </div>



          <div class="form-group fg-place group-4 group-xs-12">
            {/* <div class="placeholder">
                                    <label for=""> Full Name </label>
                                    <span class="star">*</span>
                                  </div> */}
                                  <h5>Full Name</h5>
            <input
              type="text"
              class="holer-control form-control"
              value={driverLicence?.dl_full_name}
              name="dl_full_name"
              onChange={handleDriverLicenseChange}
              placeholder="Full Name *"
              required
            />
          </div>

          <div class="form-group fg-place group-4 group-md-6 group-xs-12">
            {/* <div class="placeholder">
                                    <label for=""> Issuing State </label>
                                    <span class="star">*</span>
                                  </div> */}
                                  <h5>Issuing State</h5>
            <select
              // type="text"
              class="holer-control form-control"
              value={driverLicence?.dl_state}
              name="dl_state"
              onChange={handleDriverLicenseChange}
              // placeholder="Issuing State *"
              // required
            >
              <option disabled={true} selected={true}>
                {" "}
                State *
              </option>
              {props.usState.map((res) => (
                <option key={res.id} value={res.id}>
                  {" "}
                  {res.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div
            class="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar"
            id="datepicker3"
            data-target-input="nearest"
          >
            <h5>Issue Date</h5>
            <input
              type="date"
              title="Enter Issue Date"
              class="form-control"
              placeholder="Issue Date *"
              data-target="#datepicker3"
              data-toggle="datetimepicker"
              value={driverLicence?.dl_issue_date}
              name="dl_issue_date"
              onChange={handleDriverLicenseChange}
              // placeholder="Issuing Date *"
              required
              max={getTodaysDate()}
            />
          </div>

          <div
            class="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar"
            id="datepicker4"
            data-target-input="nearest"
          >
            <h5>Expiry Date</h5>
            <input
              type="date"
              title="Enter Expiry Date"
              class="form-control"
              placeholder="Expiry Date *"
              data-target="#datepicker4"
              data-toggle="datetimepicker"
              value={driverLicence?.dl_expiry_date}
              name="dl_expiry_date"
              onChange={handleDriverLicenseChange}
              required
              min={getTodaysDate()}
            />
          </div>

          <div class="form-group fg-place group-4 group-xs-12">
          
                                  <h5>Social Security Number <i>(Last 4 digits)</i> &nbsp;
                                    
                                    {/* <i class="fa fa-info-circle" aria-hidden="true" title="SSN last 4 digits are required for Stripe to complete the KYC validation requirements."></i> */}
                                    { window.screen.width >= 1200 ?<i className="fa fa-info-circle" aria-hidden="true" title="*Required to complete the KYC validation requirements. Please enter valid digits only to avoid delays in your payouts."></i>
                                    :
                                    null
                                }
                                  </h5>
            <input
              type="text"
              class="holer-control form-control"
              value={driverLicence?.ssn_last_four}
              name="ssn_last_four"
              onChange={handleDriverLicenseChange}
              placeholder="Last 4 digits of SSN *"
              required
              pattern="[0-9]{4}"
              title="Last 4 digits of SSN number should have 4 numeric digits only."
              maxLength="4"
            />
             { window.screen.width < 1200 ?<i className="text-red">*Required to complete the KYC validation requirements. Please enter valid digits only to avoid delays in your payouts.</i>
                                    :
                                    null
                                }
          </div>
          
          <div class="form-group fg-place group-4 group-xs-12">
            {/* <div class="placeholder">
                                    <label for=""> Driver’s License ID </label>
                                    <span class="star">*</span>
                                  </div> */}
                                  <h5>Social Security Number <i>(Full)</i> &nbsp; 
                                    { window.screen.width >= 1200 ?<i className="fa fa-info-circle" aria-hidden="true" title="Your full SSN will be required only if validation could not be completed using last 4 digits of SSN. eZunction does not store this information."></i>
                                    :
                                    null
                                }
                                  </h5>
            <input
              type="text"
              className="holer-control form-control"
              value={driverLicence?.ssn_full}
              name="ssn_full"
              onChange={handleDriverLicenseChange}
              placeholder="Full SSN (Optional)"
              // required
              pattern="[0-9]{9}"
              title="SSN number should have 9 numeric only"
                                    maxLength="9"
              // maxLength="4"
              // min="0"
              // max="999999999"
              // maxLength="4"
            />
             { window.screen.width < 1200 ?<i className="text-red">Your full SSN will be required only if validation could not be completed using last 4 digits of SSN. eZunction does not store this information.</i>
                                    :
                                    null
                                }
          </div>
{/* 
          <div class="form-group fg-place group-4 group-xs-12">
           
                                  <h5>All Digit</h5>
            <input
              type="text"
              class="holer-control form-control"
              value={driverLicence?.dl_id}
              name="dl_id"
              onChange={handleDriverLicenseChange}
              placeholder="Driver’s License ID *"
              required
            />
          </div> */}

          <div class="cus-fileUpload-box form-group group-12">
            <div class="cus-fileUpload-group">
              <input
                type="file"
                class="cus-fileUpload-control d-none"
                id="driverDL-input"
                name="image"
                onChange={handleDriverLicenseChange}
                // required
              />
              <label for="driverDL-input" class="mb-0 cus-fileUpload-label">
                <div class="profileUp-inner">
                  <div class="upload-here">
                    <img src={driversLicensePhoto} class="img-fluid h-100" alt=""/>
                  </div>
                  <div class="upload-content">
                    <h5>
                      {" "}
                      Upload DL Image <span class="asterisk">*</span>
                    </h5>
                    <span>{driverLicence?.image?.name}</span>
                    <span> 5 MB Max </span>
                    <span> PDF, Doc, Docx, JPG </span>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="form-submit text-center group-12">
            <button type="button" class="btn btn-sm btn-black" onClick={()=>{
              setDialogueState({
                type: "Warning",
                show: true,
                message: "All your changes will be lost. Do you want to continue?",
                refresh: true,
                yesButton: true
              });
            }}>
              {" "}
              Cancel{" "}
            </button>
            <button type="submit" class="btn btn-sm btn-red ml-4" disabled={processing}>
              {" "}
              {processing ? "Processing..." : "Save"}{" "}
            </button>
          </div>
        </div>
        </form>
        <Dialogue state={dialogueState} setState={setDialogueState} />
        </>
    );
}

export default DriverLicense