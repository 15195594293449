import React from 'react';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import Auth from "../Auth/Auth";
import GetInTouch from '../Components/Common/GetInTouch';

const authData = Auth.getUserDetails();

const ServiceProviderGuide = () => {
    return (
        <>
            <Header />
            {authData && authData?.role_type === 2 || authData && authData?.role_type === 3 ?
            <>
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> Service Provider  <span> Guide </span> </h1>
                                <p> Here is everything that you need to know on the  <br className="d-none d-md-block" /> eZunction platform. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="getContent-section">
                <div className="wp-clip-bg">
                    <img src="images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
                </div>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="getContent-item m-0">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 1. INTRODUCTION </h2>
                                </div>
                                <p> This guide helps you to provide some basic guidelines, tips and suggestions on how to become a successful and most preferred service provider and increase your earnings. Customers will refer to your profile ratings and the feedbacks before they accept your quote. You are in-charge of your profile and the work that you choose to complete.  </p>
                                <p> You are a very important part of this platform and it is equally important to ensure that you are enabled with the right information. </p>
                            </div>

                            <div className="getContent-item ">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 2. Making a good profile </h2>
                                </div>
                                <p> As a Service provider, you shall maintain your profile on the platform. Your profile consists of:  </p>

                                <ul>
                                    <li> Your details such as name and profile picture </li>
                                    <li> The service categories and sub-categories that you can work on </li>
                                    <li> Your preferred service locations </li>
                                    <li> If you are Licensed, bonded and Insured (as applicable) </li>
                                    <li> Your years of experience and warranty information (as applicable) </li>
                                    <li> Your overall ratings and feedback from the customers for the jobs that you completed </li>
                                </ul>

                                <p> Make sure that you include a clear professional looking picture on your profile. A nice profile picture helps to create a good impression on the customers. </p>

                                <p> Customers do refer service providers profile and ratings. Your profile gives them the needed confidence to accept your quotes and choose you to work on their requests.  </p>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="getContent-img">
                                <img src="images/content/guide.svg" className="m-auto d-block img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 3. Browsing the Service Requests </h2>
                                </div>
                                <p> eZunction is the platform where you can browse for all the requests in your area that are looking for quotes. You can browse the service requests in eZunction Home Page and by clicking the “All Requests’ from the footer of the application/website. </p>

                                <p> You can also use the search and filter options to get the filtered requests that matches your search based on the criteria you provided. </p>

                                <p> You may also get the notifications in your app for new requests which matches your skill sets and location preferences. </p>

                                <p> You can expand the request and review the request details, customer ratings and feedback and submit the fair and competitive quotes to get accepted for the request.  </p>
                            </div>


                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 4. Submitting Quotes</h2>
                                </div>
                                <p> As you browse and review the requests that matches your skillsets and interests, you can submit the quote for that particular request by following below simple steps: </p>
                                <ul>
                                    <li> Expand the service request you want to submit a quote for </li>
                                    <li> Click on ‘Submit Quote’ </li>
                                    <li> Fill out the information required in the form </li>
                                    <li>You should provide the most optimum labor and material costs to make your overalll quote competitive. You can still add material and labor costs, at the time of marking the service request as complete, as may be required to complete the work.</li>
                                    <li>You can select warranty days for your work, which will be effective from the date of Completion.</li>
                                    <li> Click on ‘Submit’ </li>
                                </ul>

                                <p> You may want to consider following while submitting your quotes: </p>

                                <ul>
                                    <li> Fair/competitive Price </li>
                                    <li> Service Included details </li>
                                    <li> If your quotes include material or not </li>
                                    <li> Service date and time </li>
                                </ul>

                                <p> While submitting your quotes, ensure that your quotes are customized and align as per customer’s requests. Your quote shall be clear and indicate what all services are included in your quote. If you wish to include material costs as well, make sure to mark it separately on the Quote. </p>

                                <li> Based on your submitted quotes details customer will review and decide whether they want to accept or reject your quote.  </li>

                                <li> You can always submit quotes for multiple requests, but can only submit one quote per requests.  </li>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 5. Managing your Quotes  </h2>
                                </div>
                                <p>  You have flexibility to perform following actions on your submitted quotes:  </p>

                                <div className="gc-left">
                                    <div className="gcl-item">
                                        <h4> a)   Confirm Quote  </h4>
                                        <p> Once your quote is accepted by the customer, you have the flexibility to review latest comments or requirements, schedules and confirm the quote.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4>  b)   Update Quote</h4>
                                        <p>  You can also update your quote such as schedule date time, services included, quote amount etc.  </p>

                                        <p> NOTE: you can only increase the quote amount.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> c)   Withdraw Quote </h4>
                                        <p> Once your have submitted your quote you can withdraw it before the service request is Completed by providing suitable withdrawal reason from the drop down list. This is mandatory detail.  </p>

                                        <p> You can withdraw your submitted quote any time before it is confirmed status, without any withdrawal charges. </p>

                                        <p> If the service request is in ‘Confirmed’ status for your quote, you can still withdraw the quote before 48 hours from the start time of the scheduled time slot. Withdrawal charges will be applied. </p>

                                        <p>The withdrawal window will be closed for ‘Confirmed’ quotes, with less than 48 hours remaining from the start time of the scheduled time slot. You may want to reschedule the service request if agree. </p>

                                        <p> Your confirmed quote cannot be rejected by customer. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 6. Communicate with the customer</h2>
                                </div>
                                <p> You can also communicate with customer using comments once you submitted the quote, and discuss the service date time, service included or if there is any other questions/concerns which will help you understand more about the requirement and convince the customer that you are the good fit for the work. </p>

                                <p> NOTE: While commenting with customer, you are not supposed to share your personal and contact information such as your address, contact number, email etc. for safety reasons. </p>

                                <p> eZunction monitors your comments for data sharing agreement; if found violations your quote will be automatically disabled and you may be charged a policy violation fee.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 7. Mark ‘On My Way’</h2>
                                </div>
                                <p> You are a Service Provider, you must mark ‘On My Way’ button when you start for the work. Once you do so, customer will get notification of your ETA duration so that customer will be available to get you in to perform the work. You will get the ETA duration and also can use the google map service for your route direction to the service location.   </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 8. Customer No Show</h2>
                                </div>
                                <p>  As you started for the work, we expect customer to be available or arrange your get in to the service address to perform the work. In case, customer is not available or have arranged any get in options such as lock box etc….at the service location when you reached or in the scheduled timeslot, you may still try interacting with the customer using Comments and agree on the timing, if still does not work you may mark Customer as No Show (wait period applicable). </p>
                                <p> We strongly suggest our service providers and customers to avoid being No Show as there may be the applicable No Show charge. And also impacts your overall ratings and feedback and eventually have negative impact to your profile. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 9. Mark Completion </h2>
                                </div>
                                <p> You shall mark your service request as Completed upon successful completion of the work. You shall consider being on time, clean work, professional and provide overall great quality of work. You shall try your best to provide great quality of work considering customer satisfaction. </p>
                                <p>You can add Additional Labor Amount, Additional Material Amount and Additional Comments as were required to complete the work.</p>
                                <p> We encourage you to provide feedback for customer that you worked for. That will help customer to build their profile as well. Other service providers can view the customer’s ratings and feedbacks which will be helpful to build trust and confidence.</p>

                                <p> It is your responsibility to request your customer to accept completion of the work to get paid. You shall also request a 5 star ratings and nice words from customer for your work which will help to growth your profile.</p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 10. Understanding your feedback received from the customers</h2>
                                </div>
                                <p>  It will be helpful for you as a service provider to understand the feedback that you have been receiving from your customers for the service requests that you have completed. </p>

                                <p> The customers will rate you on an overall rating based on their experience with you and also provide feedback on some of the specific parameters such as professionalism, cleanliness of work, On-time arrival and overall quality of the work performed. </p>

                                <p> You shall regularly monitor the feedback from the customers and your overall ratings. Try to improve your feedback areas for all your future requests such that your profile improves, and you become the most preferred service provider for your customers. </p>

                                <p> This will eventually help increase quality of your services and hence the acceptance rate of your submitted quotes. </p>

                                <p> You will surely see an increase in your income and business.</p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 11. Key Operating Principles</h2>
                                </div>
                                <div className="gc-left">
                                    <div className="gcl-item">
                                        <h4> a)   Professionalism:</h4>
                                        <p> Demonstrate professionalism behavior at all times, be polite and transparent with the customer and keep them honest. They will trust you more this way.</p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> b)   Cleanliness:</h4>
                                        <p> Follow basic rules of covering the areas/nearby furniture/items etc with a suitable plastic/cloth cover. Ensure that you clean up properly once the work is completed. Customers do not like cleaning-up themselves upon the work completion. </p>

                                        <p> It may be a nice idea to wear shoe covers, if you do not wish or cannot remove your shoes. </p>

                                        <p> Dress appropriately and maintain your appearances well, to create a good lasting impression on your customers. </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> c)   On Time:</h4>
                                        {/* <p> Customers want to have their service requests completed in a timely manner. Once you confirm a service request, honor your commitment, arrive as per the schedules time window and be respectful of your and the customer’s time.   </p> */}
                                        <p>Customers want to have their service requests completed in a timely manner. Once you confirm a service request, honor your commitment, arrive as per the schedules time window and be respectful of your and the customer’s time.</p>
                                        {/* <p> In case you are running late from your previous work, it is good to communicate and agree your new arrival time with the customer by leaving appropriate comments on your qoute.</p> */}
                                        <p>In case you are running late from your previous work, it is good to communicate and agree your new arrival time with the customer by leaving appropriate comments on your quote. </p>
                                        <p> It goes a long way to gain trust from your customers, by arriving on time and communicating with them. </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> d)   Quality:</h4>
                                        <p> Complete your work to the best level of your own satisfaction first. If you are satisfied, with your work, your customers will be as well. Do not rush into completing the work, as it will eventually reflect on the quality. </p>

                                        <p>  Do a great job, take the time it needs to complete the work properly.</p>

                                        <p> Quality work goes a long way in gaining your customers praise and eventually a great feedback rating. </p>
                                    </div>

                                    <div className="gcl-item">
                                        <h4> e)   Responsiveness: </h4>
                                        <p> There is nothing better than being communicative and connecting with your customers. Customers may have questions for you or you may have questions for them to be able to provide the most accurate quotes. You shall leave comments for the customers and ensure that you have fully understood the work involved and that they have fully understood the services that you will provide. </p>

                                        <p> Do NOT share any personal and contact information such as email, phone, address etc. via comments or otherwise, and avoid getting your quotes/profiles flagged to the administrator. </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="getContent-item orange-text">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 12. 3rd Party Insurance</h2>
                                </div>
                                <p> You as a service provider, is an independent contractor, which means that you are primarily liable/accountable for personal injury or damage caused by you to third parties or for any implications that may occur as a result of your activities while performing the activities to complete your service requests. It is recommended that you take additional care and precautions while completing the service requests. </p>

                                <p> The Insurance provide by eZunction covers you as a service provider, for your liability for property damage or injury arising from activities not otherwise excluded, related to the completion of the service request. It does not cover any incorrect/faulty/low-quality/re-work/warranty repairs of the work performed.</p>

                                <p> For any event that need insurance coverage to be activated, please notify the eZunction support team for advice. You are suggested not to admit/accept responsibility of any loss in such cases, while the investigations are being performed by the assigned assessors, who in turn will seek all the needed details of how/when/where the injury or damage happened. Please provide the details as asked to support the investigations. </p>

                                <p> Please note that this is just a summary of our third party liability insurance policy ONLY and shall not be considered as a general advice. All the conditions will be evaluated by the insurance providers and processed accordingly, in combination with the 3rd party liability insurance that you may carry with you, as an independent service provider. </p>
                            </div> */}

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 12. Warranty Requests and Disputes</h2>
                                </div>
                                <p> Customers prefer service providers who provide a great warranty coverage and resolve any warranty requests with a reasonable turn-around. You shall give equal preference to your warranty requests as you do for a new service requests. Please make sure that you communicate with your customers to understand their warranty requests and resolve them with high quality levels.  </p>

                                <p> Timely completion of any warranty requests that you receive, helps you build trust and preference with your customers and eventually help enhance your overall profile ratings and feedbacks.  </p>

                                <p> In case, there is a dispute situation between you and your customer, it is highly advisable to communicate with your customer and understand the reason for the dispute. Make all necessary efforts to a) Avoid unhappy customers leading to a dispute situation, as your first preference b) Communicate, co-ordinate with your customers to understand their concerns and make sure to address them, c) Reach out to eZunction support team to seek guidance/suggestions to resolve the dispute. eZunction will help resolve the dispute based on the available data on the eZunction platform, related to the particular service request/quote. The eZunction’s Terms and Conditions will apply, in such cases.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> 13. On-job injuries</h2>
                                </div>
                                <p> First and foremost, your safety is important. Please take utmost care while you complete the service requests that you have chosen to complete. In an unfortunate event, if an injury occurs while you were completing the work or any other activities not otherwise excluded as part of a service request on the eZunction Platform, please do not delay in calling 911 or an ambulance or an active local emergency line, if you need any immediate medical attention.  </p>

                                <p> © 2020 eZunction LLC. All rights reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            :
            null
                        }



            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default ServiceProviderGuide;
