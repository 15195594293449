import React, { useState, useEffect } from "react";
import { APIService } from "../Apis/APIServices";
import Dialogue from '../../Components/Common/Dialogue';

export default function RejectModal(props) {

  const [withdrawalReason, setWithdrawalReason] = useState([]);
  const [withdrawalReasonId, setWithdrawalReasonId] = useState("");
  const [withdrawalComment, setWithdrawalComment] = useState("");
  const [withdrawConfirm,setWithdrawConfirm] = useState("");

  const [dialogueState,setDialogueState] = useState({
    type : '',
    show : false,
    message : ''
})

  const quote_id = props.quoteData.id;

  const handleSubmit = (e) => {
    APIService.withdawStatus(quote_id)
    .then(res=>{
        if(res && res.data && res.data.message){
          setWithdrawConfirm(res.data.message)
        }
    }).catch(console.log)
  };

  useEffect(() => { 
    APIService.srRejectReason()
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReason(res.data);
        }
      })
      .catch((e) => console.log("error", e));
  },[])

  const handConfirmWithdraw = ()=>{
    // console.log("rrrrrrrrrrrrrrrrr",quote_id,withdrawalReasonId, withdrawalComment  )
    let data = {
      // quote_id,
      rejection_reason_id: withdrawalReasonId,
      rejection_comments: withdrawalComment,
    };
    APIService.submitRejectQuote(quote_id, data)
      .then((res) => {
        if (res.status === 200) {
          setWithdrawalReasonId("");
          setWithdrawalComment("");
          document.getElementById("notification-modal-reject").click();
          document.getElementById("toggleModal").click();
          // window.location.reload()

        }else{
          setDialogueState({
						type : 'Error',
						show:true,
						message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
        }
      })
      .catch((e) => {
        setDialogueState({
          type : 'Error',
          show:true,
          message : (e && e.response && e.response.data && e.response.data.message) || ""
        })
      });
  }

  // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjj",withdrawConfirm)

  return (
    <>
    <Dialogue state={dialogueState} setState={setDialogueState} />
    <div class="scale_cus_modal disputeRequest-wrap" id="notification-modal-reject">
      <div class="scale_modal_body">
        <div class="modal_inner">
          <div class="scm_header">
            <h2 class="scm_head-title"> Reject Quote </h2>
          </div>
          <div class="scm_body">
            <div >
              <div class="form-group">
                <label> Select the Quote Rejection Reason *</label>
                <select
                  class="form-control"
                  onChange={(e) => setWithdrawalReasonId(e.target.value)}
                >
                  <option value=""> Select Quote Reject Reason *</option>
                  {
                    withdrawalReason.map(wr=><option value={wr.id}>{wr.name}</option>)
                  }
                </select>
              </div>

              <div class="form-group">
                <div class="textarea-group">
                <label>Rejection Comments *</label>
                  <textarea
                      class="form-control resize-none"
                      maxLength="50"
                    rows="6"
                    onChange={(e) => setWithdrawalComment(e.target.value)}
                    value={withdrawalComment}
                    placeholder="Please provide details explaining why you are rejecting the quote. *"
                  ></textarea>
                    <span class="textarea-limit"> {50 - withdrawalComment.length} Characters Limit </span>
                </div>
              </div>

              <div class="scm_footer">
                <button
                  type="button"
                  class="btn btn-black"
                  data-dismiss="modal"
                  id="cancleButtonWithdrawModal"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button 
                  type="button" 
                  class="btn btn-red"
                  data-dismiss="modal"
                  // data-toggle="modal"
                  // data-target="#confirm-withdraw-modal"
                  onClick={handConfirmWithdraw}
                  disabled={!withdrawalReasonId || !withdrawalComment}
                  >
                  {" "}
                  Submit{" "}
                </button>
                <button 
                  type="button" 
                  className="d-none"
                  id="toggleModal"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#confirm-withdraw-modal"
                  // onClick={handConfirmWithdraw}
                  // disabled={!withdrawalReasonId || !withdrawalComment}
                  >
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="scale_cus_modal confirmCancel-wrap" id="confirm-withdraw-modal">
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Success </h2>
            </div>
            <div class="scm_body">
              <div class="confirmCancel">
                <p>Quote has been rejected successfully.</p>
              </div>
                <div class="scm_footer d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-red"
                  data-dismiss="modal"
                  onClick={()=> window.location.reload()}
                >
                 OK
                </button>
                
              </div> 
              
              
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
