import React from 'react';
import { useEffect, useState } from 'react';
import { APIService } from '../../Components/Apis/APIServices';
import Footer from '../../Components/Common/Footer';
import Header from '../../Components/Common/Header';
import MyAccountMenu from '../../Components/Common/MyAccountMenu';
import images from '../../Utility/Images';
import Auth from "../../Auth/Auth";

import Info from "../../Components/Common/InfoPage";
import MyTransactionCustomer from "./MyTransactionCustomer";
import MyTransactionSP from "./MyTransactionSP";
import TransactionBoth from './MyTransactionBoth'
import { data } from 'jquery';
import GetInTouch from '../../Components/Common/GetInTouch';

const MyTransactions = () => {
   
  const [transListData, setTransListData] = useState([])
  const [userData, setUserData] = useState({})

    const authData = Auth.getUserDetails();

    const setUserType2 = () => {
      switch (authData.role_type) {
        case 1:
          return "customer";
        case 2:
          return "provider";
        case 3:
          return "both";
        default:
          return null;
      }
    };

    const initialUserType = setUserType2()

	const [userType, setUserType ] = useState(setUserType2())
  const [tabIndex, setTabIndex] = useState(userType === "provider" ? 2 : 1);

useEffect(()=>{
  APIService.authUser()
      .then((res) => {
        setUserData(res?.data && res?.data.data && res?.data.data[0] ? res?.data.data[0] : {})
      })
      .catch((err) => console.log(err));
  
},[])
// console.log("asdadasd", userData)

  useEffect(()=>{
		localStorage.setItem("tabIndex", tabIndex)
	},[tabIndex])


    return (
      <>
        <Header />

        <section className="gray-hero-section db-gh-section">
          <div className="gh-clip-bg">
            <img
              src={images.clipBg}
              className="img-fluid d-block m-auto"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="inner-caption wow fadeInUp text-center">
                  <h1 className="mb-0">
                    {" "}
                    My <span> Transactions </span>{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="db-top-menu">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <MyAccountMenu />
              </div>
            </div>
          </div>
        </section>

        {/* <div className="grayHero-tabnav db-gh-tabNav">
                <ul className="nav nav-tabs" role="tablist">
                    <li> <a className="active" data-toggle="tab" href="#gh-customer-tab" role="tab"> Customer </a></li>
                    <li> <a data-toggle="tab" href="#gh-servicePro-tab" role="tab"> Service Provider </a></li>
                </ul>
            </div>

            <div className="tab-content transaction-tab-content ardCat-tab-wrap">
                <div className="tab-pane active" id="gh-customer-tab" role="tabpanel">
                    {usetType === "customer" || usetType === "both" 
		      			? <MyTransactionCustomer userData={authData} />
		      			: <Info infoText= "This is the text" btnTitle="Sign up as Customer" btnLink = "/register" />}
                </div>

                <div className="tab-pane" id="gh-servicePro-tab" role="tabpanel">
                   
                    {usetType === "provider" || usetType === "both" 
		      			? <MyTransactionSP userData={authData} />
		      			: <Info infoText= "This is the text" btnTitle="Sign up as Provider" btnLink = "/register" />

}

                </div>
            </div> */}
        {/* {authData.role_type !== 3 ? ( */}
          <>
            <div className="grayHero-tabnav db-gh-tabNav">
              <ul className="nav nav-tabs" role="tablist">
                <li>
                  <a
                  className= {userType === "customer" || userType === "both"?"active":""}
		            
                    // className={
                    //   authData.role_type === 1 || authData.role_type === 3
                    //     ? "active"
                    //     : ""
                    // }
                    // data-toggle="tab"
                    // href="#gh-customer-tab"
                    // role="tab"
                    onClick={ () => {
                      setTabIndex(1)
                      setUserType("customer")
                    } }
                  >
                    Customer
                  </a>
                </li>
                <li>
                  <a
                  className= {userType === "provider" ?"active":""}
		            	
                    // className={
                    //   authData.role_type === 2
                    //     ? "active"
                    //     : null
                    // }
                    // data-toggle="tab"
                    // href="#gh-serviceP-tab"
                    // role="tab"
                    onClick={  () => {
                      setTabIndex(2)
                      setUserType("provider")
                      } }
                  >
                    Service Provider
                  </a>
                </li>
              </ul>
            </div>

            <div className="tab-content transaction-tab-content">
              {
                tabIndex === 1 ?
                <div className="container">
                {authData.role_type === 1 || authData.role_type === 3 ? (
                  <MyTransactionCustomer
                    userData={authData}
                    accountBalance= {(userData && userData.account_balance && userData.account_balance.cust_account_balance)  || "0.00"}
                    // transList={transListData}
                  />
                ) : (
                  <Info
                    infoText='You need to log in as "Both" or "Customer" user type to view this page.'
                    btnTitle="Sign up as Customer / Both"
                    btnLink="/register"
                    bodyMessage="Please logout and register/login as customer or both user"
                  />
                )}
              </div>
                  :
              
                <div className="container">
                  {authData.role_type === 2 || authData.role_type === 3 ? (
                    <MyTransactionSP
                      userData={authData}
                      accountBalance= {(userData && userData.account_balance && userData.account_balance.sp_account_balance)  || "0.00"}
                    
                      // transList={transListData}
                    />
                  ) : (
                    <Info
                      infoText='You need to log in as "Both" or "Service Provider" user type to view this page.'
                      btnTitle="Sign up as Service Provider / Both"
                      btnLink="/register"
                      bodyMessage="Please logout and register/login as service provider or both user"
                    />
                  )}
                </div>
              }
            </div>
          </>
        {/* // ) : (
        //   <div className="container">
        //     <TransactionBoth userData={authData} transList={transListData}/>
        //   </div>a
        // )} */}

        {/* <section className="getInTouch-section">
          <div className="container">
            <div className="col-12">
              <div className="getInTouch-box">
                <div className="title">
                  <h4> Get In Touch </h4>
                  <p> Know more about eZunction platform </p>
                </div>
                <div className="social-list">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.youtube.com/" target="_blank">
                    {" "}
                    <i className="fab fa-youtube"></i>{" "}
                  </a>
                  <a href="https://www.instagram.com/" target="_blank">
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                  <a href="https://www.flickr.com/" target="_blank">
                    {" "}
                    <i className="fab fa-flickr"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <GetInTouch />
        <Footer />
      </>
    );
}

export default MyTransactions;
