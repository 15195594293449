
import React, { useState, useEffect } from "react";
import { APIService } from "../Components/Apis/APIServices";
import Dialogue from "../Components/Common/Dialogue";

import Plaid from '../Components/Common/Plaid'

const ConfirmQuoteSPModal = (props) => {
  const eleId = props.eleId || "notifiaction-request-modal";
    const [tick, setTick] = useState(0);
    // const [bankDetailsCheck, setBankDetailsCheck] = useState(1);
    const [bankAccount, setBankAccount] = useState("");
    const [bankAcs, setBankAcs] = useState([]);
  const [bankAccountShow, setBankAccountShow] = useState(false);

    ////////////////////////////   plaid object
    const [token, setToken] = useState(null);
    const [bankToken, setBankToken] = useState("")
    const [plaidShow, setPlaidShow] = useState(false)
  /////////////////////////////////////////
  
  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });

  // useEffect(()=>{
  //   setBankAccount(props && props.quoteData && props?.quoteData?.payout_method_id)
  // },[props])

  const [bankState, setBankState] = useState({
    bank_name: "",
    account_name: "",
    account_number: "",
    routing_number: "",
    account_type: 2,
    account_nick: "",
    isprimary: 0,
  });
const [err, setErr] = useState({});
  useEffect(() => {
   

    APIService.fetchBankAccount()
    .then(res=>{
      const bankToken = JSON.parse(localStorage.getItem("bankToken"))

      if(bankToken){

        
        setBankAcs((prev)=>{
          return [...res.data.data, bankToken]
        })
      }else{
        setBankAcs(res.data.data)
      }
      

    })
    .catch(err=>setErr(err))      

  }, []);
  
    const handleTick = (val) => {
      setTick(val);
    };
  
   const handleBankChange = ({ target: { value, name } }) => {
     //   console.log(value, name);
     setBankState((prevState) => ({
       ...prevState,
       [name]: value,
     }));
   };
  
    const handleBankChangeRadio = (name, value) => {
      setBankState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
  const handleBankSubmit = (e) => {
    e.preventDefault();
    // let filterNickList = bankAcs.filter(
    //   (data) => data.account_nick === bankState.account_nick
    // );
    // let filterList = bankAcs.filter((data) => data.isprimary === 1);

    // if (filterNickList.length) {
    //   setDialogueState({
    //     type: "Error",
    //     show: true,
    //     message: "Account nick name should be unique.",
    //   });
    // } else if (bankState.isprimary === 1 && filterList.length) {
    //   setDialogueState({
    //     type: "Error",
    //     show: true,
    //     message: "Can't have multiple primary bank account.",
    //   });
    // } else {

      setBankAcs((prev)=>{
        return([...prev, {
          account_name: bankState.account_name,
          account_number: bankState.account_number,
          routing_number: bankState.routing_number,
          // account_nick: bankState.account_nick,
          // account_type: 2,
          // isprimary: bankState.isprimary,
          // bank_name: bankState.bank_name,
        }])
      })



      setBankAccountShow(false);
      document.getElementById("addNewBank-modal").click()
          setBankState({
        bank_name: "",
        account_name: "",
        account_number: "",
        routing_number: "",
        account_type: 2,
        account_nick: "",
        isprimary: 0,
      });
      // APIService.addBankAccount(bankState)
      //   .then((res) => {
      //     APIService.fetchBankAccount()
      //       .then((res) => {
      //         setBankAcs(res.data.data);

      //         setBankState({
      //           bank_name: "",
      //           account_name: "",
      //           account_number: "",
      //           routing_number: "",
      //           account_type: 2,
      //           account_nick: "",
      //           isprimary: 0,
      //         });

      //         setBankAccountShow(false);
      //         // window.location.href = '/quotes-submit'
      //         //<Redirect to="quotes-submit"/>
      //         // window.$("#addNewBank-modal").toggler("modal");
      //         // data-dismiss="modal"

      //         // $("#btnSave").click(function () {
      //         //   //   $("#addNewBank-modal").modal("hide");
      //         //   $('button[type="submit"]').attr("data-dismiss", "modal");
      //         // });
      //       })
      //       .catch((err) => setErr(err));
      //   })
      //   .catch((err) => setErr(err));
    // }
  };

  // console.log("rrrrrrrrrrrrrrr", bankAccount)


  const createLinkToken = () => {
     
    APIService.tokenLink()
    .then((res)=>{
      if(res.status === 200){
        // console.log("token is ",res)
        setToken(res.data.token)
        // localStorage.setItem("markState", JSON.stringify({...formData, request_id: requestId, rating}))
        
      }
    })
    .catch((err) =>{})

  };

  useEffect(()=>{
    if(token)
      setPlaidShow(true)
  },[token])

 

// console.log("asdasdasd", bankAccount,  props && props.quoteData && props?.quoteData)

const [noClick, setNoClick] = useState(true)

// console.log("rrrrrrrrrrtttt", eleId)

  return (
    <>
     {plaidShow ? <Plaid linkToken={token} 
        sendBankToken={(token)=> {
         
          setToken("")
          setBankToken(token)
          }}
          onCancel = {()=>{
            setPlaidShow(false)
            setToken("")
           
          }}

          confirmModal={true}
          /> : null}
      <div className={`scale_cus_modal confirmCancel-wrap ${props.showHide && noClick ? "show" : ""}`} 
      id={eleId}
      area-modal={props.showHide}
      >
        <div class="scale_modal_body">
          <div class="modal_inner">
            <div class="scm_header">
              <h2 class="scm_head-title"> Confirm Quote</h2>
            </div>
            <div class="scm_body">
              <div class="notify-modal-body">
                <div className="form-group group-12 primary-radio-group">
                  <label className="mb-1"> Select Payment Method </label>
                  <br />
                  <small><i>( *Bank account MUST be a checking account. )</i></small>
                  <form onSubmit={(e) => props.handleClick(e, bankAccount)}>
                    <div className="form-group group-xs-12 mt-2">
                      <select
                        className="form-control"
                        // name="country"
                        value={bankAccount}
                        onChange={(e) => setBankAccount(e.target.value)}
                        name="account_number"
                        required
                      >
                        <option disabled={true} value="">
                          {" "}
                          Select Checking Bank Account *
                        </option>
                        {bankAcs.map((ba) => (
                          <option value={ba.id}> {ba.account_number} </option>
                        ))}
                      </select>
                    </div>

                    <div class="form-group group-12">
                      <div class="addNewAddress-request">
                        <button
                          type="button"
                          className="btn text-btn d-flex justify-content-center w-100"
                          // data-toggle="modal"
                          // data-target="#addNewBank-modal"
                          // onClick={() => setBankAccountShow(true)}
                          onClick={() => createLinkToken()}
                        >
                          {" "}
                          <i class="fal fa-plus-circle"></i> Add New Bank
                          Account{" "}
                        </button>
                        <i 
                          className="w-100 d-flex justify-content-center"
                          style={{
                            fontSize: "16px",
                            // paddingLeft: "35px",
                            // margin: "0",
                            color: "#9BA4B4",
                            textAlign: "left"
                          }}>* New bank will be your default bank and will be used <br className="d-none d-md-block"/> for this and all your other open service request(s).</i>
                          </div>
                    </div>

                    {/* <div className="form-group form-checkbox  mt-3">
                      <div className="custom-check-block">
                        <input
                          type="checkbox"
                          className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                        />
                        <label className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </label>
                      </div>
                    </div> */}

<div className='d-flex mt-5 mb-5'>
                        <div className='d-flex align-items-center'>
                        <input
                          type="checkbox"
                          // className="d-none"
                          id="tick"
                          name="tick"
                          onClick={() => handleTick(tick === 1 ? 0 : 1)}
                          checked={tick === 1 ? true : false}
                          style={{background: "orange",         
                          width: "50px",
                          height: "25px",
                          border: "solid orange",
                          borderWidth: "0 2px 2px 0",}}
                        />
                        </div>
                        {/* <div className="custom-check-block"> */}
                        <span className="custom-check-label" for="tick">
                          {" "}
                          <i>I authorize eZunction LLC to use my payment method bank or credit card/debit card to electronically credit or debit for any applicable amount related to this request.</i>
                        </span>
                        </div>

                    <div class="scm_footer justify-content-center full-scm_footer">
                      <button
                        type="button"
                        class="btn btn-black mr-3"
                        data-dismiss="modal"
                        data-toggle="confirm-quote-modal"
                        // onClick={()=>{
                        //   // setBankAccountShow(false)
                        // document.getElementById("confirm-quote-modal").click()
                        // setNoClick(false)
                        // }}
                      >
                        {props.secondaryBtnText}
                      </button>
                      <button
                        type="button"
                        class="btn btn-red"
                        disabled={tick === 0 || bankAccount === "" || props.loader}
                        data-dismiss="modal"
                        data-toggle="modal"
                        // data-target="#confirm-cancel-modal"
                        onClick={(e) => props.handleClick(e, bankAccount, bankAcs)}
                        // disabled={props.loader || false}
                      >
                        {props.loader ? "Processing...": props.primaryBtnText}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`scale_cus_modal addnewaddress-wrap ${
          bankAccountShow ? "show" : ""
        }`}
        id="addNewBank-modal"
        area-modal={bankAccountShow}
      >
        <div className="scale_modal_body scale_modal_lg">
          <div className="modal_inner">
            {/* <!-- <div className="scale_modal-close">
	        		<button type="button" className="slide-close" data-dismiss="modal" aria-label="Close"> <i className="fal fa-arrow-left"></i> </button>
	        	</div> --> */}
            <div className="scm_header">
              <h2 className="scm_head-title"> Add Bank Account </h2>
            </div>
            <form onSubmit={(e) => handleBankSubmit(e)}>
              <div className="scm_body">
                {/* <div> */}
                  <div className="row">
                   

                    <div className="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_name ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Name on the Account </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="text"
                        className="holer-control form-control"
                        value={bankState.account_name}
                        name="account_name"
                        onChange={handleBankChange}
                        required
                      />
                    </div>

                    <div className="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.account_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Account Number </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="number"
                        className="holer-control form-control"
                        value={bankState.account_number}
                        name="account_number"
                        onChange={handleBankChange}
                        maxlength="19"
                        required
                        pattern="[0-9 ]*"
                        title="Account Number should only contain numbers"
                      />
                    </div>
                    <div className="form-group group-12 group-xs-12 fg-place">
                      <div
                        className={`placeholder ${
                          bankState.routing_number ? "d-none" : "d-block"
                        }`}
                      >
                        <label for=""> Routing Number </label>
                        <span className="star">*</span>
                      </div>
                      <input
                        type="number"
                        className="holer-control form-control"
                        value={bankState.routing_number}
                        name="routing_number"
                        onChange={handleBankChange}
                        required
                        pattern="[0-9]*"
                        title="Routing Number should only contain numbers"
                      />
                    </div>


                   
                    <i className='text-danger'>Your bank account must be a checking account.</i>{" "}
                    
                  <div class="form-group group-12 primary-radio-group mt-1">
                        <h6 style={{textAlign:"center"}}> This bank will now be your default bank and will be used for this and your all other open service request(s). Do you wish to continue?
                     </h6>
                    </div>
                  <div className="scm_footer justify-content-center w-100">
                    <button
                      type="button"
                      className="btn btn-black mr-3"
                      // data-dismiss="modal"
                      onClick={() => {
                        setBankAccountShow(false)
                        document.getElementById("addNewBank-modal").click()
                      }}
                    >
                      No
                    </button>
                    <button
                      type="submit"
                      className="btn btn-red btn-md"
                      disabled={
                        // !bankState.bank_name ||
                        !bankState.account_name ||
                        !bankState.account_number ||
                        !bankState.routing_number 
                        // !bankState.account_nick
                      }
                      id="btnSave"
                      // data-dismiss="modal"
                    >
                      {" "}
                      Yes{" "}
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Dialogue state={dialogueState} setState={setDialogueState} />
    </>
  );
};

export default ConfirmQuoteSPModal;
