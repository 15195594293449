import React, { useState, useEffect, useCallback } from "react";
import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import MyAccountMenu from "../Components/Common/MyAccountMenu";
import images from "../Utility/Images";
import Auth from "../Auth/Auth";
import { APIService } from "../Components/Apis/APIServices";
import config from "../Components/Apis/config";
import Dialogue from "../Components/Common/Dialogue";
import Select from 'react-select'
import MyProfilePaymentCard from "./MyProfilePaymentCard"
import MyProfileBank from "./MyProfileBank"

import DriverLicense from './myProfile_pages/additional_Information/driverLicense'
import BusinessLicense from './myProfile_pages/additional_Information/businessLicense'
import ComapnyInformation from "./myProfile_pages/additional_Information/companyInformation";
import GetInTouch from "../Components/Common/GetInTouch";

document.body.classList.add("dark-header");

const MyProfile = () => {
  const userDetail = Auth.getUserDetails() || {};
  //personal information
  const [countriesData, setCountries] = useState([]);
  const [stateData, setState] = useState([]);
  const [cityData, setCity] = useState([]);
  const [serviceCity, setServiceCity] = useState([]);
  const [userData, setUserData] = useState({ zip_code: "" });
  const [yearsOfExperience] = useState([
    {
      value :0,
      label:"No Exp"
    },
    {
      value :1,
      label:"1 Year"
    },
    {
      value :2,
      label:"2 Years"
    },
    {
      value :3,
      label:"3 Years"
    },
    {
      value :4,
      label:"4 Years"
    },
    {
      value :5,
      label:"5+ Years"
    }
  ])
  const [warrentyOptions] = useState([
    {
      value :0,
      label:"0"
    },
    {
      value :30,
      label:"30"
    },
    {
      value :45,
      label:"45"
    },
    {
      value :60,
      label:"60"
    },
    {
      value :90,
      label:"90"
    }
  ])

  const [processing, setProcessing] = useState(false)

  // all service locations

  const [cityLocations, setCityLocations] = useState([])

  //all images of the page
  const [profilePhoto, setProfilePhoto] = useState("");
  
   const [profileInformationImage, setProfileInformationImage] = useState("");
  
  const [BankAcs, setBankAcs] = useState({});
  const [bankAcsList, setBankAcsList] = useState([]);
  const [show, setShow] = useState(false);
  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });

  const [profileInformation, setProfileInformation] = useState({
    serviceCategory : [],
    serviceSubCategory : [],
    serviceLocation : [],
    serviceState :[],
    phone_consultation : 0,
    bonded_insured : 0,
    insured_country: 233,
    // insured_country_code:233,
    ////////////////////
    SpCategories: []
  });

  const [viewPass, setViewPass] = useState(false);
  const [viewConfPass, setViewConfPass] = useState(false);
  const [viewNewPass, setViewNewPass] = useState(false);

  const [usState, setUsState] = useState([]);

  const [serviceCats, setServiceCats] = useState([]);
  const [serviceCatsOptions, setServiceCatsOptions] = useState([]);

  const [serviceSubCatsOptions, setServiceSubCatsOptions] = useState([]);

  const [loader, setLoader] = useState(true)


  ////////////////api count for state in myprofile presonal info
// const [apiCount, setApiCount] = useState(0)

  // console.log("asdasdasd", serviceCats);

  useEffect(()=>{
    // console.log("first 1")
    APIService.authUser()
      .then((res) => {
       
        if (res.status === 200) {
          // console.log("first 2",  res && res.data && res.data.data.length && res.data.data[0])
          let profile =
            res && res.data && res.data.data.length && res.data.data[0];
          
       
          setUserData({
            ...profile,

            zip_code:
              profile.zip_code.includes('-') ? profile.zip_code : profile.zip_code.substr(0, 5) + "-" + profile.zip_code.substr(5),
          });
          setProfilePhoto(config.endpoints.image_path + profile.profile_photo);
        }
      })
      .catch((err) => console.log(err));
  },[])

  // console.log("user data is", userData)

  useEffect(() => {
    // APIService.fetchAllCategory()
    //   .then((res) => {
    //     // console.log("asdasdasdasd", res.data.data)
    //     let options = [];
    //     res.data.data.map((item, index) => {
    //       options.push({
    //         id: item.id,
    //         name: item.category_name,
    //       });
    //     });
    //     setServiceCats(res.data.data);
    //     setServiceCatsOptions(options)
    //   })
    //   .catch((err) => {});

    APIService.fetchState({
      keyword: "",
      country_id: 233,
    })
      .then(async (res) => {
        if (res.data.status == 200) {
          setUsState(
            res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      })
      .catch((error) => {});

    fetchCountry();



    // APIService.getAdditionalDetails()
    //   .then((res) => {})
    //   .catch((err) => console.log(err));

    APIService.getProfileInformation()
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)

          APIService.fetchAllCategory()
      .then((res2) => {
        // console.log("asdasdasdasd", res2.data.data)
        let options = [];
        res2.data.data.map((item, index) => {
          options.push({
            id: item.id,
            name: item.category_name,
          });
        });
        setServiceCats(res2.data.data);
        setServiceCatsOptions(options)

        let subCatsList = []

    res.data.data.serviceCategory.map((item, index) => { 
      // setServiceSubCats((prevInfo) => { 
      //   return prevInfo.push(serviceCats.filter((sc) => sc.id == item.value)[0].subcategories)
      // })
      subCatsList.push(
        (res2.data.data.filter((sc) => sc.id == item.id)[0] && res2.data.data.filter((sc) => sc.id == item.id)[0].subcategories) || []
      );
    })

    let allInOne = []

    subCatsList.map((item) => { 
      item.map((item2) => { 
        allInOne.push(item2)
      })
    })

    let subCatsOptions= []

    allInOne.map((item, index) => { 
      subCatsOptions.push({
        label: item.sub_category_name,
        value: item.id
      });
        
      
    })

    setServiceSubCatsOptions(subCatsOptions);

      })
      .catch((err) => {});


          // console.log("eeeeeeeeeeeeeeeeeeeeerrrrrrrrr", res.data.data, serviceCats)
            let profileInfo = res?.data?.data;
            if(profileInfo){
              profileInfo.insurance_expiry_date = profileInfo.insurance_expiry_date && profileInfo.insurance_expiry_date.split(
              "T"
            )[0];

            setProfileInformationImage(
              config.endpoints.image_path + profileInfo.insurance_image
            );

            // profileInfo.serviceCategory =profileInfo.SpCategories && profileInfo.SpCategories.map(spc=>{
            //     return {
            //       value : spc.category_id,
            //       label : serviceCats.find(sc=>sc.id == spc.category_id).category_name
            //     }
            //   }) 

            // let serviceCategorys = res.data.data[0].SpCategories.map(spc=>{
            //   return {
            //     value : spc.category_id,
            //     label : serviceCats.find(sc=>sc.id == spc.category_id).category_name
            //   }
            // })

            // console.log("hhhhhhhhhhhhhhhhhhaaaaaaaaaaa",serviceCategorys)
            setProfileInformation({...profileInfo,
              warranty:warrentyOptions.find(wo=>wo.value==profileInfo.warranty),
              year_experience:yearsOfExperience.find(yoe=>yoe.value==profileInfo.year_experience),
              // serviceCategory:  serviceCategorys
            });
            }


            // setProfileInformation({
            //   ...profileInfo,
            //   warranty:warrentyOptions.find(wo=>wo.value==profileInfo.warranty),
            //   year_experience:yearsOfExperience.find(yoe=>yoe.value==profileInfo.year_experience),
            //   serviceCategory : profileInfo.SpCategories.map(spc=>{
            //     return {
            //       value : spc.category_id,
            //       label : serviceCats.find(sc=>sc.id == spc.category_id).category_name
            //     }
            //   }),
         
          }
      })
      .catch((err) => {
        setLoader(false)
        APIService.fetchAllCategory()
      .then((res) => {
        // console.log("asdasdasdasd", res.data.data)
        let options = [];
        res.data.data.map((item, index) => {
          options.push({
            id: item.id,
            name: item.category_name,
          });
        });
        setServiceCats(res.data.data);
        setServiceCatsOptions(options)
      })
      .catch((err) => {});
      });

    

 
  }, []);

  // console.log("uuuuuuuuuuuuuuyyyyyyyyy", profileInformation, serviceCats)

  /// for fetching state and city on load for userData

  useEffect(() => {
    if (userData.state) {
      fetchCity(userData.state);
    }
    if (userData.country) {
      fetchState(userData.country);
    }
  }, [userData.state, userData.country]);

 

  const _fetchBankAccount = () => {
    APIService.fetchBankAccount()
      .then((res) => {
        res.data && res.data.data && setBankAcs(res.data.data[0] || {});
        res.data &&
          res.data.data &&
          setBankAcsList(res.data.data.length ? res.data.data : []);
      })
      .catch((err) => console.log(err));
  };

  const fetchCountry = useCallback(() => {
    APIService.fetchCountry()
      .then(async (res) => {
        if (res.data.status == 200) {
          setCountries(
            res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          );
          
        }
      })
      .catch((error) => {});
  }, [userData]);

  //personal information

  const fetchState = useCallback(
    (countryId) => {
      let data = {
        keyword: "",
        country_id: countryId,
      };
      APIService.fetchState(data)
        .then(async (res) => {
          if (res.data.status == 200) {
            setState(
              res.data.data.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        })
        .catch((error) => {});
    },
    [userData]
  );

  const fetchCity = useCallback(
    (stateId) => {
      let data = {
        keyword: "",
        state_id: stateId,
      };
      APIService.fetchCity(data)
        .then(async (res) => {
          if (res.data.status == 200) {
            let citylocation = []
            
            setCity(res.data.data.sort((a, b) => a.name.localeCompare(b.name)));
            res.data.data.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => { 
              citylocation.push({
                label: item.name,
                value: item.id
              })
            })
            setCityLocations(citylocation);
          }
        })
        .catch((error) => {});
    },
    [userData]
  );


 
  const handleChange = (ele) => {
    let name = ele.target.name;
    let value = ele.target.value;

    if (name == "country") {
      fetchState(value);
    } else if (name == "state") {
      fetchCity(value);
    }
    if (name == "zip_code") {
      if (
        name === "zip_code" &&
        userData.zip_code.length === 4 &&
        value.length === 5
      ) {
        value += "-";
      } else if (name === "zip_code" && value.length === 6) {
        value = value.replace("-", "");
      }else{
       
        if(value.length >= 10 && value.indexOf("-") !== 5){
          // console.log("else wala exc")
          value = ""
        }
      }
    }
    let photoObj = {};
    if (name === "profile_photo") {
      photoObj.profile_photo_updated = true;
      photoObj.prev_photo = userData.profile_photo;
      let reader = new FileReader();
      reader.onload = (e) => {
        //setProfilePhoto(e.target.result);
      };
      reader.readAsDataURL(ele.target.files[0]);
      value = ele.target.files[0];
      setProfilePhoto(URL.createObjectURL(ele.target.files[0]));
    }

    setUserData((prev) => {
      return { ...prev, [name]: value, ...photoObj };
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // console.log("handleOnSubmit", e, "userData", userData);

    let data = {
      address_1: userData.address_1,
      address_2: userData.address_2,
      city: userData.city,
      country: userData.country,
      email: userData.email,
      first_name: userData.first_name,
      last_name: userData.last_name,
      middle_name: userData.middle_name,
      password: userData.new_password,
      phone: userData.phone,
      profile_photo: userData.profile_photo,
      state: userData.state,
      zip_code: userData.zip_code.replace("-", ""),
      profile_photo_updated: userData.profile_photo_updated,
      prev_photo: userData.prev_photo,
      old_password: userData.old_password,
      new_password: userData.new_password,
    };
    if (
      !data.address_1 ||
      !data.city ||
      !data.country ||
      !data.first_name ||
      !data.last_name ||
      !data.phone ||
      !data.zip_code
    ) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Mandatory fields are missing",
      });
      return;
    }
    if (data.zip_code.length < 5 || data.zip_code.length > 10) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Zip Code Invalid",
      });
      return;
    }
    if (data.new_password !== userData.confirm_new_password) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Password does not match.",
      });
      return;
    }
    if (data.phone.length > 10 || data.phone.length < 10) {
      setDialogueState({
        type: "Error",
        show: true,
        message: "Phone Number Invalid",
      });
      return;
    }

    if(processing){
      return null
    }

    setProcessing(true)

    APIService.updateUser(data)
      .then((res) => {
        if(res?.data?.status === 200){
          APIService.authUser()
          .then((response)=>{
            // console.log("reponse is rrrrrrrr", response.data.data[0])
            localStorage.setItem("_cu", JSON.stringify(response.data.data && response.data.data[0]));
            // window.location.reload()
            setDialogueState({
                type : 'Success',
                show:true,
                message : "Profile updated successfully.",
                refresh: true
                // link : 'my-request'
              })
              setProcessing(false)
          })
          .catch(()=>{
            setProcessing(false)
          })
        }else{
          setDialogueState({
            type: "Error",
            show: true,
            message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })
          setProcessing(false)
        }
         
      })
      .catch((e) => {
        setProcessing(false)
        console.log("error", e)});
  };

  const handleBankChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setBankAcs((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleBankDetail = (e) => {
    e.preventDefault();
    // console.log("preventDefault", e);

    let filterList = bankAcsList.filter(
      (data) => data.isprimary === 1 && data.id !== BankAcs.id
    );
    let filterNickList = bankAcsList.filter(
      (data) =>
        data.account_nick === BankAcs.account_nick && data.id !== BankAcs.id
    );
    console.log(filterList);

    if (filterList.length) {
      alert("Can't have multiple primary bank account.");
    } else if (filterNickList.length) {
      alert("Account nick name should be unique.");
    } else {
      APIService.updateUserBank(BankAcs)
        .then((res) => {
          res && res.data && res.data.status === 200
            ? alert("Bank updated successfully.")
            : alert("Error");
          res && res.data && res.data.status === 200 && _fetchBankAccount();
        })
        .catch((e) => console.log("error", e));
    }
  };

  
  //profile information changes

  const handleProfileInformationChange = (ele) => {
    let value = ele.target.value;
    let name = ele.target.name;

    let photoObj = {};
    if (name === "insurance_image") {
      photoObj.image_updated = true;
      photoObj.prev_photo = profileInformation.insurance_image;
      let reader = new FileReader();
      reader.onload = (e) => {
        //setProfilePhoto(e.target.result);
      };
      reader.readAsDataURL(ele.target.files[0]);
      value = ele.target.files[0];
      setProfileInformationImage(URL.createObjectURL(ele.target.files[0]));
    }

    if (ele.target.files) {
      setProfileInformation((prevState) => {
        return { ...prevState, [name]: value, ...photoObj };
      });
    } else {
      setProfileInformation((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };
  

  

  const onProfileInformationSubmit = (event) => {
    event.preventDefault();

        const isSameUser = (a, b) => a.id == b.id;
    const onlyInLeft = (left, right, compareFunction) => 
    left.filter(leftValue =>
      right.some(rightValue => 
        compareFunction(leftValue, rightValue)));

   
        const result = onlyInLeft(profileInformation.serviceLocation || [], serviceCity, isSameUser);
    

    // console.log("rrrrrrrrrrrrrrr", result, serviceCity)

    let data = {
      id : profileInformation.id,
      category: profileInformation.serviceCategory && profileInformation.serviceCategory.map((item) =>item.id ),
      subcategory: profileInformation.serviceSubCategory && profileInformation.serviceSubCategory.map((item)=>item.id),
      location : profileInformation.serviceLocation && profileInformation.serviceLocation.map(sl=>sl.id),
      serviceState: profileInformation.serviceState && profileInformation.serviceState.map(sl=>sl.id),

      year_experience: profileInformation.year_experience && profileInformation.year_experience.value && profileInformation.year_experience.value,
      warranty: profileInformation.warranty && profileInformation.warranty.value && profileInformation.warranty.value,
      phone_consultation: profileInformation.phone_consultation && profileInformation.phone_consultation,
      bonded_insured: profileInformation.bonded_insured && profileInformation.bonded_insured,
      insurance_provider_name: profileInformation.insurance_provider_name && profileInformation.insurance_provider_name,
      insurance_expiry_date: profileInformation.insurance_expiry_date && profileInformation.insurance_expiry_date,
      insurance_policy: profileInformation.insurance_policy && profileInformation.insurance_policy,
      insured_name_address: profileInformation.insured_name_address && profileInformation.insured_name_address,
      insured_country: profileInformation.insured_country && profileInformation.insured_country,
      insured_country_code: profileInformation.insured_country_code && profileInformation.insured_country_code,
      insured_mobile_number: profileInformation.insured_mobile_number && profileInformation.insured_mobile_number,
      // insurance_image: profileInformation.insurance_image,

      insurance_image: profileInformation.insurance_image && profileInformation.insurance_image,
      image_updated: profileInformation.image_updated && profileInformation.image_updated,
      prev_photo: profileInformation.prev_photo && profileInformation.prev_photo,
    };

    // console.log("data is", data, "/////////////", profileInformation)
    if(data.bonded_insured==1 && !data.insurance_image){
      setDialogueState({
              type: "Error",
              show: true,
              message: "Upload the insurance image",
      });
      return;
    }else if(result.length !== (profileInformation.serviceLocation && profileInformation.serviceLocation.length) || 0){
      setDialogueState({
        type: "Error",
        show: true,
        message: "Selected location(s) does not have the corresponding state(s).",
});
return;
    }

    
    if(processing){
      return null
    }

    setProcessing(true)

    APIService.updateProfileInformation(data)
      .then((res) => {
        res?.data?.status === 200
          ? setDialogueState({
              type: "Success",
              show: true,
              message: "Profile Information updated successfully.",
            })
          : setDialogueState({
              type: "Error",
              show: true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
          })

          setProcessing(false)
      })
      .catch((e) => {
        console.log("error", e)
        setProcessing(false)
      });

  };

 
  

  // const formateDate = (date) =>
  //   new Date(date || new Date.now()).toLocaleString().split(",")[0];

  const handleChange1 = (selectedOption) => {

    const isSameUser = (a, b) => a.id == b.value;
    const onlyInLeft = (left, right, compareFunction) => 
    left.filter(leftValue =>
      right.some(rightValue => 
        compareFunction(leftValue, rightValue)));

   

    // let SpCategories = onlyInLeft(serviceCats, selectedOption || [], isSameUser);

    let serviceCategory = selectedOption.map((item, index) =>{
      return{
        id: item.value,
        category_name: item.label
      }
    })

    // console.log("rrrrrrrrrrreeeeeeeeeeeeesp", profileInformation, selectedOption, serviceCats)

    let subCatsList = []

    selectedOption.map((item, index) => { 
      // setServiceSubCats((prevInfo) => { 
      //   return prevInfo.push(serviceCats.filter((sc) => sc.id == item.value)[0].subcategories)
      // })
      subCatsList.push(
        (serviceCats.filter((sc) => sc.id == item.value)[0] && serviceCats.filter((sc) => sc.id == item.value)[0].subcategories) || []
      );
    })

    let allInOne = []

    subCatsList.map((item) => { 
      item.map((item2) => { 
        allInOne.push(item2)
      })
    })

    let subCatsOptions= []

    allInOne.map((item, index) => { 
      subCatsOptions.push({
        label: item.sub_category_name,
        value: item.id
      });
        
      
    })

    setServiceSubCatsOptions(subCatsOptions);



    
    const serviceSubCategory = onlyInLeft(profileInformation.serviceSubCategory || [], subCatsOptions, isSameUser);

    setProfileInformation((prevInfo) => {
      return { ...prevInfo, serviceCategory, serviceSubCategory };
    });
  };

  console.log("sadasdasdasd", profileInformation.SpCategories)

    const handleChange2 = (selectedOption) => {
      let serviceSubCategory = selectedOption.map((item, index) =>{
        return{
          id: item.value,
          sub_category_name: item.label
        }
      });
      setProfileInformation((prevInfo) => {
        return { ...prevInfo, serviceSubCategory };
      });
    };

    const handleChange4 = (selectedOption) => {
      let serviceLocation = selectedOption.map((item, index) =>{
        return{
          id: item.value,
          name: item.label
        }
      });
      setProfileInformation((prevInfo) => {
        return { ...prevInfo, serviceLocation };
      });
    };

    const handleChange3 = (selectedOption) => {
      if(selectedOption.length){
        let serviceState = selectedOption.map((item, index) =>{
          return{
            id: item.value,
            name: item.label
          }
        });
        setProfileInformation((prevInfo) => {
          return { ...prevInfo, serviceState };
        });
        // let data = {
        //   keyword: "",
        //   state_id: serviceState[serviceState.length-1].value,
        // };
        setServiceCity([])
        // setApiCount(0)
        
      }else{
        let serviceState = selectedOption;
        setProfileInformation((prevInfo) => {
          return { ...prevInfo, serviceState, serviceLocation:[] };
        });
        setServiceCity([]);

      }
    };

    useEffect(()=>{

      if(serviceCity.length === 0){
        // console.log("sssssssssssssssssdddddddddddddddd   use", serviceCity, profileInformation.serviceState)
      
        profileInformation.serviceState && profileInformation.serviceState.map((item,index) =>{
          APIService.fetchCity({
            keyword: "",
            state_id: item.id,
          })
          .then(async (res) => {
            if (res.data.status == 200) {
              setServiceCity((prev) => {
                return [...prev, ...res.data.data.sort((a, b) => a.name.localeCompare(b.name))]
              });

              // setApiCount(index + 1)

            }
          })
          .catch((error) => {});
        })
        

      
      }

    },[profileInformation.serviceState])

    // useEffect(()=>{
    //   console.log("sssssssssssssssssdddddddddddddddd after map done 1", profileInformation.serviceState.length , apiCount)
        
    //   if(profileInformation.serviceState.length === apiCount){
    //   // if(apiCount === 1){
    //     console.log("sssssssssssssssssdddddddddddddddd after map done 2")
        
    //     const isSameUser = (a, b) => a.id == b.id;
    // const onlyInLeft = (left, right, compareFunction) => 
    // left.filter(leftValue =>
    //   right.some(rightValue => 
    //     compareFunction(leftValue, rightValue)));

   
    //     const serviceLocation = onlyInLeft(profileInformation.serviceLocation || [], serviceCity, isSameUser);
    //     // console.log("sssssssssssssssssdddddddddddddddd after map done 3",onlyInLeft(profileInformation.serviceLocation || [], serviceCity, isSameUser))
        
    //     setProfileInformation((prevInfo) => {
    //       return { ...prevInfo,  serviceLocation};
    //     });
    //   }
    // },[apiCount, profileInformation.serviceState])

    // console.log("sssssssssssssssssdddddddddddddddd", serviceCity, apiCount)

    const handleChange5 = (selectedOption,name)=>{
        setProfileInformation((prevInfo) => {
          return { ...prevInfo, [name]:selectedOption };
        });
    }
  
  const handleRadio = (name, value) => {
    // dispatch({ type: name, payload: { [name]: value, key: name } });
    let bonded_insured_updated = false;
    if(name=="bonded_insured"){
      bonded_insured_updated = true
    }
    console.log(
      !profileInformation.serviceCategory || 
        !profileInformation.serviceSubCategory || 
        !profileInformation.serviceLocation ||
         profileInformation.bonded_insured == 1 && 
         profileInformation.bonded_insured_updated && 
         (
          !profileInformation.insurance_provider_name ||
          !profileInformation.insurance_policy ||
          !profileInformation.insured_name_address ||
          !profileInformation.insured_country ||
          !profileInformation.insured_mobile_number
        )
    );
    setProfileInformation((prevInfo) => {
      return { ...prevInfo, [name]:value, bonded_insured_updated};
    });
    
  };

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }


  // console.log("//////////////............", profileInformation.phone_consultation);

  return (
    <>
      <Header />
      <section className="gray-hero-section db-gh-section">
        <div className="gh-clip-bg">
          <img
            src={images.clipBg}
            className="img-fluid d-block m-auto"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-caption wow fadeInUp text-center">
                <h1 className="mb-0">
                  {" "}
                  My <span> Profile </span>{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="db-top-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MyAccountMenu />
            </div>
          </div>
        </div>
      </section>

      <div className="grayHero-tabnav db-gh-tabNav">
        <ul className="nav nav-tabs" role="tablist">
          <li>
            {" "}
            <a
              className="active"
              data-toggle="tab"
              href="#personalInfo-tab"
              role="tab"
            >
              {" "}
              Personal Information{" "}
            </a>
          </li>
          {/* <li>
            {" "}
            <a data-toggle="tab" href="#paymentInfo-tab" role="tab">
              {" "}
              Payment Information{" "}
            </a>
          </li> */}
          
            {" "}
            {userDetail && userDetail.role_type === 2 || userDetail && userDetail.role_type === 3 ? (
              <li>
              <a data-toggle="tab" href="#additionalInfo-tab" role="tab">
                {" "}
                Additional Information{" "}
              </a>
              </li>
            ) : null}
         
        </ul>
      </div>

      <section className="cp-myProfile-tab-content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cpp-tab-inner">
                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="personalInfo-tab"
                    role="tabpanel"
                  >
                    <div className="cpp-white-box p-0">
                      <div className="personalDetail-block">
                        <form onSubmit={(e) => handleOnSubmit(e)}>
                          <div className="pdb-head">
                            <span className="pdb-head-bg"> </span>
                            <div className="pdb-head-img">
                              <img
                                src={profilePhoto}
                                className="img-fluid"
                                alt=""
                                title=""
                              />
                              <div className="camera-row">
                                <input
                                  type="file"
                                  id="uploadProfile-img"
                                  className="d-none"
                                  name="profile_photo"
                                  onChange={(e) => handleChange(e)}
                                />
                                <label for="uploadProfile-img">
                                  <div className="camera-label">
                                    <i className="fal fa-camera-retro"></i>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="pdb-body">
                            <div className="cpp-form-block">
                              <div className="form-group fg-place group-4 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for=""> First Name </label>
                                  <span className="star">*</span>
                                </div> */}
                                <h5>First Name</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.first_name || ""}
                                  onChange={(e) => handleChange(e)}
                                  name="first_name"
                                  placeholder="First Name *"
                                  required
                                />
                              </div>

                              <div className="form-group fg-place group-4 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for=""> Middle Name </label>
                                </div> */}
                                <h5>Middle Name</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.middle_name || ""}
                                  onChange={(e) => handleChange(e)}
                                  name="middle_name"
                                  placeholder="Middle Name"
                                />
                              </div>

                              <div className="form-group fg-place group-4 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for=""> Last Name </label>
                                  <span className="star">*</span>
                                </div> */}
                                <h5>Last Name</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.last_name || ""}
                                  onChange={(e) => handleChange(e)}
                                  name="last_name"
                                  placeholder="Last Name *"
                                  required
                                />
                              </div>

                              <div className="form-group fg-place group-6 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for="">Address Line 1 </label>
                                  <span className="star">*</span>
                                </div> */}
                                <h5>Address 1</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.address_1 || ""}
                                  onChange={(e) => handleChange(e)}
                                  name="address_1"
                                  placeholder="Address Line 1 *"
                                  required
                                />
                              </div>

                              <div className="form-group fg-place group-6 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for=""> Address Line 2 </label>
                                </div> */}
                                <h5>Address 2</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={
                                    userData.address_2 == null
                                      ? ""
                                      : userData.address_2 || ""
                                  }
                                  onChange={(e) => handleChange(e)}
                                  name="address_2"
                                  placeholder="Address Line 2"
                                />
                              </div>

                              <div className="form-group group-3 group-md-6 group-xs-12">
                                <h5>Country</h5>
                                <select
                                  className="form-control"
                                  name="country"
                                  value={userData.country}
                                  onChange={(e) => handleChange(e)}
                                  // name="country"
                                >
                                  <option disabled={true} selected={true}>
                                    {" "}
                                    Select Country *
                                  </option>
                                  {countriesData.length
                                    ? countriesData.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {" "}
                                          {res.name}{" "}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>

                              <div className="form-group group-3 group-md-6 group-xs-12">
                                <h5>State</h5>
                                <select
                                  className="form-control"
                                  name="state"
                                  value={userData.state}
                                  onChange={(e) => handleChange(e)}
                                  // name="state"
                                >
                                  <option disabled={true} selected={true}>
                                    {" "}
                                    Select State *
                                  </option>
                                  {stateData.length
                                    ? stateData.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {" "}
                                          {res.name}{" "}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>

                              <div className="form-group group-3 group-md-6 group-xs-12">
                                <h5>City</h5>
                                <select
                                  className="form-control"
                                  name="city"
                                  value={userData.city}
                                  onChange={(e) => handleChange(e)}
                                  // name="city"
                                >
                                  <option disabled={true} selected={true}>
                                    {" "}
                                    Select City *
                                  </option>
                                  {cityData.length
                                    ? cityData.map((res) => (
                                        <option key={res.id} value={res.id}>
                                          {" "}
                                          {res.name}{" "}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>

                              <div className="form-group fg-place group-3 group-md-6 group-xs-12">
                                {/* <div className="placeholder">
                                  <label for=""> Zip Code </label>
                                  <span className="star">*</span>
                                </div> */}
                                <h5>Zip code</h5>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.zip_code}
                                  onChange={(e) => handleChange(e)}
                                  name="zip_code"
                                  placeholder="Zip Code *"
                                  required
                                  pattern="[0-9-]*"
                                  maxLength="10"
                                  minLength="5"
                                  title="Zip code must contain numbers only"
                                />
                              </div>

                              <div className="form-group fg-place group-6 group-xs-12">
                                <h5>Email</h5>
                                <div className="placeholder">
                                  <label for=""> Email Address </label>
                                  <span className="star">*</span>
                                </div>
                                <input
                                  type="text"
                                  className="holer-control form-control"
                                  value={userData.email}
                                  placeholder="Email Address *"
                                  readonly
                                />
                              </div>

                              <div className="form-group group-6 group-xs-12">
                              <h5>Phone Number</h5>

                                <div className="phoneNumber-group">
                                  <div className="phoneNo-code-block">
                                    <select className="form-control">
                                      <option>+1</option>
                                    </select>
                                  </div>
                                  <input
                                    type="text"
                                    className="mobileNumber-control holer-control form-control"
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Mobile Number *"
                                    required
                                    value={userData.phone}
                                    name="phone"
                                    pattern="[0-9]{10}"
                                    title="Phone number should have 10 numeric only"
                                    maxLength="10"
                                    // min="0"
                                  />
                                </div>
                              </div>

                              <div className="form-group group-4 group-md-6 group-xs-12">
                                <div className="password-group">
                                  <h5>Old password</h5>
                                  <input
                                    // type="password"
                                    type={viewPass ? "text" : "password"}
                                    id="old-password-field"
                                    placeholder="Old Password"
                                    className="form-control"
                                    onChange={(e) => handleChange(e)}
                                    name="old_password"
                                    // value={userData.old_password}
                                  />
                                  {/* <button
                                    type="button"
                                    toggle="#old-password-field"
                                    className="toggle-password password-eye"
                                  >
                                    {" "}
                                    <i className="far fa-eye-slash eye-btn"></i>{" "}
                                  </button> */}

                                  <button
                                    type="button"
                                    onClick={() =>
                                      setViewPass((prevState) => !prevState)
                                    }
                                    className="toggle-password password-eye"
                                  >
                                    {" "}
                                    {/* <i className="far fa-eye-slash eye-btn"></i>{" "} */}
                                    {viewPass?
                                                     <i className="far fa-eye-slash"></i>:
                                                     <i className="far fa-eye eye-btn"></i> 
                                                    }
                                  </button>
                                </div>
                              </div>

                              <div className="form-group group-4 group-md-6 group-xs-12">
                                <div className="password-group">
                                  <h5>New password</h5>
                                  <input
                                    // type="password"
                                    type={viewNewPass ? "text" : "password"}
                                    id="new-password-field"
                                    placeholder="New Password"
                                    className="form-control"
                                    onChange={(e) => handleChange(e)}
                                    name="new_password"
                                    value={userData.new_password || ""}
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setViewNewPass((prevState) => !prevState)
                                    }
                                    className="toggle-password password-eye"
                                  >
                                    {" "}
                                    {/* <i className="far fa-eye-slash eye-btn"></i>{" "} */}
                                    {viewNewPass?
                                                     <i className="far fa-eye-slash"></i>:
                                                     <i className="far fa-eye eye-btn"></i> 
                                                    }
                                  </button>
                                </div>
                              </div>

                              <div className="form-group group-4 group-md-6 group-xs-12">
                                <div className="password-group">
                                  <h5>Confirm password</h5>
                                  <input
                                    // type="password"
                                    type={viewConfPass ? "text" : "password"}
                                    id="confirm-password-field"
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    onChange={(e) => handleChange(e)}
                                    name="confirm_new_password"
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setViewConfPass((prevState) => !prevState)
                                    }
                                    className="toggle-password password-eye"
                                  >
                                    {" "}
                                    {/* <i className="far fa-eye-slash eye-btn"></i>{" "} */}
                                    {viewConfPass?
                                                     <i className="far fa-eye-slash"></i>:
                                                     <i className="far fa-eye eye-btn"></i> 
                                                    }
                                  </button>
                                </div>
                              </div>

                              <div className="form-submit text-center group-12 pt-4">
                              <button type="button" class="btn btn-sm btn-black" onClick={()=>{
                                setDialogueState({
                                  type: "Warning",
                                  show: true,
                                  message: "All your changes will be lost. Do you want to continue?",
                                  refresh: true,
                                  yesButton: true
                                });
                              }}>
                                {" "}
                                Cancel{" "}
                              </button>
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-red ml-4"
                                  disabled={processing}
                                >
                                  {" "}
                                  {processing ? "Processing..." : "Submit"}{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
{/* 
                  <div
                    className="tab-pane"
                    id="paymentInfo-tab"
                    role="tabpanel"
                  >
                    <div className="cpp-white-box">
                      <div className="status-tabnav">
                        <ul className="nav nav-tabs" role="tablist">
                          <li>
                            {" "}
                            <a
                              className="active"
                              data-toggle="tab"
                              href="#creditDebit-card-tab"
                              role="tab"
                            >
                              {" "}
                              Credit Card/ Debit Card{" "}
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              data-toggle="tab"
                              href="#bankAccount-tab"
                              role="tab"
                            >
                              {" "}
                              Bank Account
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="creditDebit-card-tab"
                          role="tabpanel"
                        >
                          <MyProfilePaymentCard />
                        </div>

                        <div
                          className="tab-pane"
                          id="bankAccount-tab"
                          role="tabpanel"
                        >
                          <MyProfileBank />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {userDetail && userDetail.role_type === 2 || userDetail && userDetail.role_type === 3 ? (
                    <div
                      class="tab-pane"
                      id="additionalInfo-tab"
                      role="tabpanel"
                    >
                      <div class="cpp-white-box">
                        <div class="status-tabnav">
                          <ul class="nav nav-tabs" role="tablist">
                            <li>
                              {" "}
                              <a
                                class="active"
                                data-toggle="tab"
                                href="#driverInfo-tab"
                                role="tab"
                              >
                                {" "}
                                Driver’s License{" "}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a
                                data-toggle="tab"
                                href="#profileInfo-tab"
                                role="tab"
                              >
                                {" "}
                                Profile Information{" "}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a
                                data-toggle="tab"
                                href="#businessLicInfo-tab"
                                role="tab"
                              >
                                {" "}
                                Business License{" "}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a
                                data-toggle="tab"
                                href="#companyInfo-tab"
                                role="tab"
                              >
                                {" "}
                                Company Information{" "}
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div class="tab-content">
                          <div
                            class="tab-pane active"
                            id="driverInfo-tab"
                            role="tabpanel"
                          >
                            <DriverLicense usState={usState} />
                          </div>

                          <div
                            className="tab-pane"
                            id="profileInfo-tab"
                            role="tabpanel"
                          >
                            {
                              !loader ? 
                            <form
                              onSubmit={(e) => onProfileInformationSubmit(e)}
                            >
                              <div className="cpp-form-block">
                                <div className="form-group group-6 group-xs-12">
                                  <div className="d-flex">
                                  <h5>Select Categories</h5> &nbsp;
                                  ( <i>Multiple categories allowed</i> )
                                  </div>
                                  <Select
                                    // className="h-100"
                                    // closeMenuOnSelect={false}
                                    value={profileInformation.serviceCategory && profileInformation.serviceCategory.map(spc=>{
                                        return {
                                          value : spc.id,
                                          // label : serviceCats.find(sc=>sc.id == spc.category_id) && serviceCats.find(sc=>sc.id == spc.category_id).category_name
                                          label: spc.category_name
                                        }
                                      })
                                    }
                                    onChange={handleChange1}
                                    options={serviceCatsOptions.map(spc=>{
                                      return {
                                        value : spc.id,
                                        // label : serviceCats.find(sc=>sc.id == spc.category_id) && serviceCats.find(sc=>sc.id == spc.category_id).category_name
                                        label: spc.name
                                      }
                                    })
                                  }
                                    isMulti={true}
                                    placeholder="Select all service categories that apply *"
                                  />
                                </div>
                                <div className="form-group group-6 group-xs-12">
                                {/* <h5>Select Sub-Categories</h5> */}
                                <div className="d-flex">
                                  <h5>Select Sub-Categories</h5> &nbsp;
                                  ( <i>Multiple sub-categories allowed</i> )
                                  </div>
                                  <Select
                                    // className="h-100"
                                    // closeMenuOnSelect={false}
                                    value={
                                      profileInformation.serviceSubCategory && profileInformation.serviceSubCategory.map(spc=>{
                                        return {
                                          value : spc.id,
                                          // label : serviceCats.find(sc=>sc.id == spc.category_id) && serviceCats.find(sc=>sc.id == spc.category_id).category_name
                                          label: spc.sub_category_name
                                        }
                                      })
                                    }
                                    onChange={handleChange2}
                                    options={serviceSubCatsOptions}
                                    isMulti={true}
                                    placeholder="Select all service sub categories that apply *"
                                  />
                                </div>

                                <div className="form-group group-6 group-md-6 group-xs-12">
                                  {/* <h5>Select Service State</h5> */}
                                  <div className="d-flex">
                                  <h5>Select Service State</h5> &nbsp;
                                  ( <i>Multiple states allowed</i> )
                                  </div>
                                  <Select
                                    // className="h-100"
                                    value={profileInformation.serviceState && profileInformation.serviceState.map(spc=>{
                                      return {
                                        value : spc.id,
                                        // label : serviceCats.find(sc=>sc.id == spc.category_id) && serviceCats.find(sc=>sc.id == spc.category_id).category_name
                                        label: spc.name
                                      }
                                    })}
                                    onChange={handleChange3}
                                    options={stateData.map((res) => ({
                                      value: res.id,
                                      label: res.name,
                                    }))}
                                    isMulti={true}
                                    placeholder="Select all service state that apply *"
                                  />
                                </div>
                                <div className="form-group group-6 group-md-6 group-xs-12">
                                  {/* <h5>Select Location</h5> */}
                                  <div className="d-flex">
                                  <h5>Select Location</h5> &nbsp;
                                  ( <i>Multiple locations allowed</i> )
                                  </div>
                                  <Select
                                    // className="h-100"
                                    value={profileInformation.serviceLocation && profileInformation.serviceLocation.map(spc=>{
                                      return {
                                        value : spc.id,
                                        // label : serviceCats.find(sc=>sc.id == spc.category_id) && serviceCats.find(sc=>sc.id == spc.category_id).category_name
                                        label: spc.name
                                      }
                                    })}
                                    onChange={handleChange4}
                                    options={serviceCity.map((res) => ({
                                      value: res.id,
                                      label: res.name,
                                    }))}
                                    isMulti={true}
                                    placeholder="Select all service locations that apply"
                                  />
                                </div>
                                <div className="form-group group-4 group-md-6 group-xs-12">
                                  <h5>Select Experience</h5>
                                  <Select
                                    // className="h-100"
                                    value={profileInformation.year_experience}
                                    onChange={(e) =>
                                      handleChange5(e, "year_experience")
                                    }
                                    options={yearsOfExperience}
                                    isMulti={false}
                                    placeholder="Years of experience *"
                                  />
                                </div>
                                <div className="form-group group-4 group-md-6 group-xs-12">
                                  <h5>Select Warranty</h5>
                                  <Select
                                    // className="h-100"
                                    value={profileInformation.warranty}
                                    onChange={(e) =>
                                      handleChange5(e, "warranty")
                                    }
                                    options={warrentyOptions}
                                    isMulti={false}
                                    placeholder="Labor Warranty Provided (Days) *"
                                  />
                                </div>
                                <div className="form-group group-12 primary-radio-group">
                                  <h5>
                                    {" "}
                                    Can you provide professional phone
                                    consultation?{" "}
                                  </h5>
                                  <div className="radio-block">
                                    <div className="radio-group">
                                      <input
                                        type="radio"
                                        className="radio-input d-none"
                                        // name="ppc-type"
                                        id="ppc-yes"
                                        name="phone_consultation"
                                        value={1}
                                        checked={
                                          profileInformation.phone_consultation ===
                                          1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        // checked
                                      />
                                      <label
                                        className="radio-label-control"
                                        for="ppc-yes"
                                        onClick={() =>
                                          handleRadio("phone_consultation", 1)
                                        }
                                      >
                                        {" "}
                                        Yes{" "}
                                      </label>
                                    </div>

                                    <div className="radio-group">
                                      <input
                                        type="radio"
                                        className="radio-input d-none"
                                        // name="ppc-type"
                                        id="ppc-no"
                                        value={0}
                                        name="phone_consultation"
                                        checked={
                                          profileInformation.phone_consultation ===
                                          0
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                      />
                                      <label
                                        className="radio-label-control"
                                        for="ppc-no"
                                        onClick={() =>
                                          handleRadio("phone_consultation", 0)
                                        }
                                      >
                                        {" "}
                                        No{" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group group-12 primary-radio-group">
                                  <h5> Are you Bonded and Insured? </h5>
                                  <div className="radio-block">
                                    <div className="radio-group">
                                      <input
                                        type="radio"
                                        className="radio-input d-none"
                                        // name="bondedIns-type"
                                        id="bai-yes"
                                        value={1}
                                        name="bonded_insured"
                                        checked={
                                          profileInformation.bonded_insured == 1
                                        }
                                        // onChange={
                                        //   handleProfileInformationChange
                                        // }
                                        // checked
                                      />
                                      <label
                                        className="radio-label-control"
                                        for="bai-yes"
                                        onClick={() =>
                                          handleRadio("bonded_insured", 1)
                                        }
                                      >
                                        {" "}
                                        Yes{" "}
                                      </label>
                                    </div>

                                    <div className="radio-group">
                                      <input
                                        type="radio"
                                        className="radio-input d-none"
                                        // name="bondedIns-type"
                                        id="bai-no"
                                        name="bonded_insured"
                                        checked={
                                          profileInformation.bonded_insured == 0
                                        }
                                        value={0}
                                        // onChange={
                                        //   handleProfileInformationChange
                                        // }
                                      />
                                      <label
                                        className="radio-label-control"
                                        for="bai-no"
                                        onClick={() =>
                                          handleRadio("bonded_insured", 0)
                                        }
                                      >
                                        {" "}
                                        No{" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {profileInformation.bonded_insured == 1 ? (
                                  <>
                                        <div className="form-group group-12 mb-3">
                      <p className="text-danger">
                      This information is not visible to other users.
                      </p>
                     
                    </div>
                                    <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                      <h5>Provider Name</h5>
                                      <div
                                        className={`placeholder ${
                                          profileInformation.insurance_provider_name
                                            ? "d-none"
                                            : "d-block"
                                        }`}
                                      >
                                        <label for=""> Provider Name </label>
                                        <span className="star">*</span>
                                      </div>
                                      <input
                                        type="text"
                                        className="holer-control form-control"
                                        name="insurance_provider_name"
                                        value={
                                          profileInformation.insurance_provider_name
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        // placeholder="Provider Name *"
                                        required
                                      />
                                    </div>

                                    <div
                                      className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar"
                                      id="datepicker5"
                                      data-target-input="nearest"
                                    >
                                      <h5>Expiry Date</h5>
                                      <input
                                        type="date"
                                        title="Enter Expiry Date"
                                        className="form-control"
                                        placeholder="Expiry Date"
                                        data-target="#datepicker5"
                                        data-toggle="datetimepicker"
                                        name="insurance_expiry_date"
                                        value={
                                          profileInformation.insurance_expiry_date
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        min={getTodaysDate()}
                                        required
                                      />
                                    </div>

                                    <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                      <h5>Policy Number</h5>
                                      <div
                                        className={`placeholder ${
                                          profileInformation.insurance_policy
                                            ? "d-none"
                                            : "d-block"
                                        }`}
                                      >
                                        <label for=""> Policy Number </label>
                                        <span className="star">*</span>
                                      </div>
                                      <input
                                        type="text"
                                        className="holer-control form-control"
                                        name="insurance_policy"
                                        value={
                                          profileInformation.insurance_policy
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        // placeholder="Policy *"
                                        required
                                      />
                                    </div>

                                    <div className="form-group fg-place group-4 group-md-6 group-xs-12">
                                    <h5>Insurer's Name and Full Address</h5>
                                      <div
                                        className={`placeholder ${
                                          profileInformation.insured_name_address
                                            ? "d-none"
                                            : "d-block"
                                        }`}
                                      >
                                        
                                        <label for="">
                                          {" "}
                                          Insurer's Name and Full Address{" "}
                                        </label>
                                        <span className="star">*</span>
                                      </div>
                                      <input
                                        type="text"
                                        className="holer-control form-control"
                                        name="insured_name_address"
                                        value={
                                          profileInformation.insured_name_address
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        // placeholder="Insured Name and Address *"
                                        required
                                      />
                                    </div>

                                    <div className="form-group group-4 group-md-6 group-xs-12">
                                      <h5>Issuing Country</h5>
                                      <select
                                        className="form-control"
                                        name="insured_country"
                                        value={
                                          profileInformation.insured_country
                                        }
                                        onChange={
                                          handleProfileInformationChange
                                        }
                                        required
                                        disabled
                                      >
                                        <option value={0}> Country </option>
                                        {countriesData.length
                                          ? countriesData.map((res) => (
                                              <option
                                                key={res.id}
                                                value={res.id}
                                                selected={
                                                  res.id === 233 ? true : false
                                                }
                                              >
                                                {" "}
                                                {res.name}{" "}
                                              </option>
                                            ))
                                          : null}
                                      </select>
                                    </div>

                                    <div className="form-group group-4 group-md-6 group-xs-12">
                                      <h5>Phone Number</h5>
                                      <div className="phoneNumber-group">
                                        <div className="phoneNo-code-block">
                                          <select
                                            className="form-control"
                                            name="insured_country_code"
                                            value={
                                              profileInformation.insured_country_code
                                            }
                                            onChange={
                                              handleProfileInformationChange
                                            }
                                            required
                                          >
                                            {/* <option
                                              value=""
                                              selected
                                              disabled={true}
                                            >
                                              Code
                                            </option> */}
                                            <option selected value={1}>
                                              +1
                                            </option>
                                            {/* <option value={2}>+2</option>
                                            <option value={3}>+3</option>
                                            <option value={4}>+4</option>
                                            <option value={5}>+5</option> */}
                                          </select>
                                        </div>
                                        <div className="fg-place w-100">
                                          <div
                                            className={`placeholder ${
                                              profileInformation.insured_mobile_number
                                                ? "d-none"
                                                : "d-block"
                                            }`}
                                          >
                                            <label for=""> Phone Number </label>
                                            <span className="star">*</span>
                                          </div>
                                          <input
                                            type="text"
                                            className="mobileNumber-control holer-control form-control"
                                            name="insured_mobile_number"
                                            value={
                                              profileInformation.insured_mobile_number
                                            }
                                            onChange={
                                              handleProfileInformationChange
                                            }
                                            maxlength="10"
                                            pattern="[0-9]{10}"
                                            title="Phone number should have 10 numeric only"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="cus-fileUpload-box form-group group-12">
                                      <div className="cus-fileUpload-group">
                                        <input
                                          type="file"
                                          className="cus-fileUpload-control d-none"
                                          id="insuranceImg-input"
                                          name="insurance_image"
                                          onChange={
                                            handleProfileInformationChange
                                          }
                                        />
                                        <label
                                          for="insuranceImg-input"
                                          className="mb-0 cus-fileUpload-label"
                                        >
                                          <div className="profileUp-inner">
                                            <div className="upload-here">
                                              <img
                                                src={profileInformationImage}
                                                className="img-fluid h-100"
                                                alt=""
                                                // height="100%"
                                              />
                                            </div>
                                            <div className="upload-content">
                                              <h5>
                                                {" "}
                                                Uploaded Insurance Image{" "}
                                                <span className="asterisk">
                                                  *
                                                </span>
                                              </h5>
                                              <span>
                                                {" "}
                                                No file selected yet!{" "}
                                              </span>
                                              <span> 5 MB Max </span>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                <div className="form-submit text-center group-12">
                                <button type="button" class="btn btn-sm btn-black" onClick={()=>{
                                  setDialogueState({
                                    type: "Warning",
                                    show: true,
                                    message: "All your changes will be lost. Do you want to continue?",
                                    refresh: true,
                                    yesButton: true
                                  });
                                }}>
                                  {" "}
                                  Cancel{" "}
                                </button>
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-red ml-4"
                                    disabled={
                                      (profileInformation.serviceCategory && profileInformation.serviceCategory.length === 0) ||
                                      (profileInformation.serviceSubCategory && profileInformation.serviceSubCategory.length === 0) ||
                                      (profileInformation.serviceLocation && profileInformation.serviceLocation.length === 0) ||
                                      (profileInformation.serviceState && profileInformation.serviceState.length === 0) ||
                                      !(profileInformation.year_experience && profileInformation.year_experience) ||
                                      !(profileInformation.warranty && profileInformation.warranty) ||
                                      !(parseInt(profileInformation.phone_consultation) === 0 || parseInt(profileInformation.phone_consultation) === 1 ) ||
                                      !(parseInt(profileInformation.bonded_insured) === 0 || parseInt(profileInformation.bonded_insured) === 1 ) ||
                                      
                                      (
                                        profileInformation.bonded_insured_updated &&
                                        profileInformation.bonded_insured == 1 &&
                                        (!profileInformation.insurance_provider_name ||
                                          !profileInformation.insurance_expiry_date ||
                                          !profileInformation.insurance_policy ||
                                          !profileInformation.insured_name_address ||
                                          // !profileInformation.insured_country ||
                                          !profileInformation.insured_mobile_number))
                                      
                                          ||  processing
                                    }
                                  >
                                  {processing ? "Processing..." : "Save"}{" "}
                                  </button>
                                </div>
                              </div>
                            </form>
                            :
                            <div style={{color: "red"}}>Loading ....</div>
                                  }
                          </div>

                          <div
                            className="tab-pane"
                            id="businessLicInfo-tab"
                            role="tabpanel"
                          >
                            <BusinessLicense usState={usState} />
                          </div>

                          <div
                            className="tab-pane"
                            id="companyInfo-tab"
                            role="tabpanel"
                          >
                            <ComapnyInformation />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="getInTouch-section">
        <div className="container">
          <div className="col-12">
            <div className="getInTouch-box">
              <div className="title">
                <h4> Get In Touch </h4>
                <p> Know more about eZunction platform </p>
              </div>
              <div className="social-list">
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>
                <a href="https://www.flickr.com/" target="_blank">
                  {" "}
                  <i className="fab fa-flickr"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <GetInTouch />
      <Dialogue state={dialogueState} setState={setDialogueState} />
      <Footer />
    </>
  );
};

export default MyProfile;
