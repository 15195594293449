import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
export default function PasswordUpdated() {
    return (
        <div>
            <Header/>
            	<section class="auth-success-section">
		<div class="container h-100">
			<div class="row h-100">
				<div class="col-12 col-lg-6 mx-auto">
					<div class="success-inner">
						<div class="success-content">
							<p> Password has been succesfully updated on the <span> eZunction </span> network. </p>
							<p>Please sign-in with the new password.</p>
							<div className="d-flex justify-content-center"><Link className="btn btn-red btn-sm" to="/login"> OK</Link></div> 
						</div>
						<div class="success-img">
							<img src="../images/thank-character.svg" class="img-fluid m-auto d-block" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
        </div>
    )
}
