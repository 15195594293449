import React, { useState, useEffect } from "react";
import { APIService } from "../../../Components/Apis/APIServices";
import config from "../../../Components/Apis/config";
import Dialogue from "../../../Components/Common/Dialogue";



const BusinessLicense = (props) => {
   const [businessLicense, setBusinessLicense] = useState({});
  const [businessLicenseImage, setBusinessLicenseImage] = useState("");
  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });
  const [processing, setProcessing] = useState(false)
  useEffect(() => {
   
    APIService.getBusinessLicense()
      .then((res) => {
        // console.log(
        //   " api testing ,",
        //   res?.data?.data[0].dl_issue_date.split("T")[0]
        // );
        if (res.status === 200) {
          let license = res?.data?.data[0];
          license.bl_issue_date = res?.data?.data[0].bl_issue_date.split(
            "T"
          )[0];
          license.bl_expiry_date = res?.data?.data[0].bl_expiry_date.split(
            "T"
          )[0];

          setBusinessLicenseImage(
            config.endpoints.image_path + license.bl_image
          );

          setBusinessLicense(license);
        }
      })
      .catch((err) => {});
  }, []);

    const handleBusinessLicenseChange = (ele) => {
  let value = ele.target.value;
  let name = ele.target.name;

  let photoObj = {};
  if (name === "bl_image") {
    photoObj.image_updated = true;
    photoObj.prev_photo = businessLicense.bl_image;
    let reader = new FileReader();
    reader.onload = (e) => {
      //setProfilePhoto(e.target.result);
    };
    reader.readAsDataURL(ele.target.files[0]);
    value = ele.target.files[0];
    setBusinessLicenseImage(URL.createObjectURL(ele.target.files[0]));
  }

  if (ele.target.files) {
    setBusinessLicense((prevState) => {
      return { ...prevState, [name]: value, ...photoObj };
    });
  } else {
    setBusinessLicense((prevState) => {
      return { ...prevState, [name]: value };
    });
  }
};

  const onBusinessLicSubmit = (event) => {
    event.preventDefault();

    if(processing){
      return null
    }

    setProcessing(true)

    APIService.updateBusinessLicense(businessLicense)
      .then((res) => {
        res?.data?.status === 200
          ? setDialogueState({
              type: "Success",
              show: true,
              message: "Business License updated successfully.",
            })
          : setDialogueState({
              type: "Error",
              show: true,
              message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            })

            setProcessing(false)
      })
      .catch((e) => {
        console.log("error", e)
        setProcessing(false)
      });
  };
  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }

  // console.log("wwwwwwwwwwwwwwwweeeeeeeeeeeee", getTodaysDate())

  return (
    <>
      <form onSubmit={(e) => onBusinessLicSubmit(e)}>
      <div className="form-group group-12 mb-3 ml-0">
                      <p className="text-danger">
                      This information is not visible to other users.
                      </p>
                     
                    </div>
        <div className="cpp-form-block">
          <div className="form-group fg-place group-4 group-md-6 group-xs-12">
            {/* <div className="placeholder">
                                    <label for=""> License </label>
                                  </div> */}
                                  <h5>License Number</h5>
            <input
              type="text"
              name="bl_id"
              className="holer-control form-control"
              value={businessLicense?.bl_id}
              placeholder="License Number"
              onChange={handleBusinessLicenseChange}
            />
          </div>

          <div
            className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar"
            id="datepicker6"
            data-target-input="nearest"
          >
            <h5>Issue Date</h5>
            <input
              type="date"
              title="Enter Issue Date"
              className="form-control"
              placeholder="Issue Date"
              data-target="#datepicker6"
              data-toggle="datetimepicker"
              value={businessLicense?.bl_issue_date}
              name="bl_issue_date"
              onChange={handleBusinessLicenseChange}
              // max="2022-02-20"
              max={getTodaysDate()}
              />
          </div>

          <div
            className="form-group date1 group-4 group-md-6 group-xs-12 datepicker-calendar"
            id="datepicker7"
            data-target-input="nearest"
          >
            <h5>Expiry Date</h5>
            <input
              type="date"
              title="Enter Expiry Date"
              className="form-control"
              placeholder="Expiry Date"
              data-target="#datepicker7"
              data-toggle="datetimepicker"
              value={businessLicense?.bl_expiry_date}
              name="bl_expiry_date"
              onChange={handleBusinessLicenseChange}
              min={getTodaysDate()}
              // min="2022-02-26"
              />
          </div>

          <div className="form-group fg-place group-6 group-xs-12">
            {/* <div className="placeholder">
                                    <label for=""> Issuing Authority </label>
                                  </div> */}
                                  <h5>Issuing Authority Name</h5>
            <input
              type="text"
              className="holer-control form-control"
              // value="Limited Liability Company (LLC)"
              value={businessLicense?.bl_issue_authority}
              name="bl_issue_authority"
              onChange={handleBusinessLicenseChange}
              placeholder="Issuing Authority"
            />
          </div>

          <div className="form-group group-6 group-xs-12">
          <h5>Issuing State</h5>
            <select
              className="form-control"
              value={businessLicense?.bl_issue_state}
              name="bl_issue_state"
              onChange={handleBusinessLicenseChange}
            >
              <option disabled={true} selected={true}>
                {" "}
                Issuing State
              </option>
              {props.usState.map((res) => (
                <option key={res.id} value={res.id}>
                  {" "}
                  {res.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div className="cus-fileUpload-box form-group group-12">
            <div className="cus-fileUpload-group">
              <input
                type="file"
                className="cus-fileUpload-control d-none"
                id="busLicImg-input"
                name="bl_image"
                onChange={handleBusinessLicenseChange}
              />
              <label
                for="busLicImg-input"
                className="mb-0 cus-fileUpload-label"
              >
                <div className="profileUp-inner">
                  <div className="upload-here">
                    <img
                      src={businessLicenseImage}
                      className="img-fluid h-100"
                      alt=""
                    />
                  </div>
                  <div className="upload-content">
                    <h5> Uploaded Business License Image </h5>
                    <span> No file selected yet! </span>
                    <span> 5 MB Max </span>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div className="form-submit text-center group-12">
          <button type="button" class="btn btn-sm btn-black" onClick={()=>{
              setDialogueState({
                type: "Warning",
                show: true,
                message: "All your changes will be lost. Do you want to continue?",
                refresh: true,
                yesButton: true
              });
            }}>
              {" "}
              Cancel{" "}
            </button>
            <button type="submit" className="btn btn-sm btn-red ml-4" disabled={processing}>
            {processing ? "Processing..." : "Save"}{" "}
            </button>
          </div>
        </div>
      </form>
      <Dialogue state={dialogueState} setState={setDialogueState} />
    </>
  );
};

export default BusinessLicense;
