import { useEffect, useState } from 'react';
import { APIService } from "../../Components/Apis/APIServices";
import { dateWithFormate } from "../../Utility/common";
import Dialogue from "../../Components/Common/Dialogue";

const AnotherVisitModal = (props) => {

  const [dialogueState, setDialogueState] = useState({
    type: "",
    show: false,
    message: "",
  });


  const [visitData, setVisitData] = useState({
    // request_id: props.id,
    date: "",
    time_slot:"",
    another_visit_reason:"",
    visit_details:"",
  });
  const [slots, setSlots] = useState([]);
  
  useEffect(() => { 
APIService.fetchAllTimeSlot()
  .then((res) => {
    if(res.status === 200)
      setSlots((res && res.data && res.data.data) || [])
  })
  .catch((err) => { });
  },[])

	const handleChange = (ele) => {
		setVisitData(prev=>{return {...prev, [ele.target.name]: ele.target.value  }});
	}

	// const handleSubmit = async() => {
  const handleSubmit = async() => {
    let data = {...visitData, request_id: props.id}
	    // let response =  await APIService.fetchAnotherVisit(data);
	    // if ((response && response.status) === 200) {
	    //   window.location.reload();
	    // }else{
      //   console.log("eeeeeeeeeeeeeeffffffffffffff", response && response.status)
      // }    
      APIService.fetchAnotherVisit(data)
      .then((res)=>{
        // window.location.reload();
        // console.log("eeeeeeeeeeeeeeffffffffffffff", response.response.status)
        if(res.status === 200){
          window.location.reload();
        }else{
          setDialogueState({
            type: "Failed",
            show: true,
            message : (res.response && res.response.data && res.response.data.message) || (res.data && res.data.message) || ""
            });
        }
         
        
          
        
      })
      .catch((e)=>{
        // console.log("eeeeeeeeeeeeeeffffffffffffff", err.response.data)
        setDialogueState({
          type: "Failed",
          show: true,
          message: (e?.response && e?.response?.data && e?.response?.data?.message) || ""
        });
        setVisitData({
          date: "",
  time_slot:"",
  another_visit_reason:"",
  visit_details:"",
        })
      })
	}

  // console.log("rrrrrrrrrrrrrrrrrr ddddddddddd", props.id)

  const getTodaysDate = () => { 
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    // let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    if (today.getMonth() + 1 < 10 && today.getDate() < 10) {
      month = `0${today.getMonth() + 1}`;
      date = `0${today.getDate()}`;
    } else if (today.getMonth() + 1 < 10 && today.getDate() >= 10) {
      month = `0${today.getMonth() + 1}`;
    } else if (today.getDate() < 10 && today.getMonth() + 1 >= 10) {
      date = `0${today.getDate()}`;
    }

    let todayDate = year + '-' + month + '-' + date
    return todayDate;
  }


	return (
    <>
    <div className="scale_cus_modal confirmCancel-wrap" id="anotherVisit-modal">
      <div className="scale_modal_body">
        <div className="modal_inner">
          <div className="scm_header">
            <h2 className="scm_head-title"> Another Visit </h2>
          </div>
          <div className="scm_body">
            <div className="form-group group-title group-12 mb-0">
              <h4> Select Service Date and Time </h4>
            </div>
            <div
              className="form-group"
              id="datepicker10"
              data-target-input="nearest"
            >
              <input
                type="date"
                onChange={handleChange}
                name="date"
                className="holer-control form-control w-100"
                data-target="#datepicker10"
                data-toggle="datetimepicker"
                value={visitData?.date}
                min={getTodaysDate()}
              />
            </div>

            <div className="form-group">
              <select
                className="form-control"
                name="time_slot"
                onChange={handleChange}
                value={visitData?.time_slot}
              >
                <option value="" disabled selected>
                  Select Time *
                </option>
                {slots.map((slot) => (
                  <option value={slot.id}>
                    {dateWithFormate(slot.start_time, "hA", "HH:mm:ss")} -{" "}
                    {dateWithFormate(slot.end_time, "hA", "HH:mm:ss")}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select
                className="form-control"
                name="another_visit_reason"
                onChange={handleChange}
                value={visitData?.another_visit_reason}
              >
                <option value="" disabled selected>
                  {" "}
                  Select another visit reason*
                </option>
                <option value="1">
                  Need more time to complete the work
                </option>
                <option value="2">
                  Material not available
                </option>
                <option value="3">Need more materials</option>
                <option value="4">Bad weather</option>
                <option value="5">
                  Customer is not ready
                </option>
                <option value="6">Other</option>
              </select>
            </div>

            <div className="form-group">
              <div className="textarea-group">
                <textarea
                  className="form-control resize-none"
                  rows="6"
                  placeholder="Provide Visit Details *"
                  name="visit_details"
                  onChange={handleChange}
                  maxLength="50"
                  value={visitData?.visit_details}
                ></textarea>
                <span className="textarea-limit">
                  {" "}
                  {50 - visitData.visit_details.length} Characters Limit{" "}
                </span>
              </div>
            </div>

            <div className="scm_footer">
              <button type="button" className="btn btn-black mr-3" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-red"
                data-dismiss="modal"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialogue state={dialogueState} setState={setDialogueState} />
    </>
  );
} 

export default AnotherVisitModal;

