const initialState = {
    step:1,
    type:2,
    isRegister:false,
    registerPreloader:false,

    first_name:'',
    last_name:'',
    middle_name:'',
    role_type:2,
    profile_photo:'',
    address_1:'',
    address_2:'',
    country:233,
    state:'',
    city:'',
    zip_code:'',
    email:'',
    phone:'',
    country_code:'',
    password:'',
    confirm_password:'',
    device_token:'',
    bank_name:'',
    account_name:'',
    account_number:'',
    routing_number:'',
    account_type:1,
    account_nick:'',
    isprimary:0,
    card_name:'',
    card_number:'',
    expiration_date:'',
    cvv_code:'',
    billing_address_1:'',
    billing_address_2:'',
    billing_country:'',
    billing_state:'',
    billing_zip_code:'',
    billing_city:'',
    card_nick:'',
    card_isprimary:0,
    dl_id:'',
    dl_full_name:'',
    dl_state:'',
    dl_issue_date:'',
    dl_expiry_date:'',
    dl_image:'',
    category:[],
    subcategory:[],
    location:[],
    year_experience:'',
    warranty:'',
    phone_consultation:1,
    bonded_insured:1,
    insurance_provider_name:'',
    insurance_expiry_date:'',
    insurance_policy:'',
    insured_name_address:'',
    insured_country:233,
    insured_country_code:'',
    insured_mobile_number:'',
    insurance_image:'',
    bl_image:'',
    bl_id:'',
    bl_issue_date:'',
    bl_expire_date:'',
    bl_issue_authority:'',
    bl_issue_state:'1',
    company_name:'',
    c_addressline_1:'',
    c_addressline_2:'',
    c_city:'',
    c_state:'',
    c_country:233,
    c_zipcode:'',
    c_emailid:'',
    c_phone_number:'',
    iagree : 0

}
const reducer =(state, { type, payload }) => {
    switch (type) {
    case "CONTINUE":
        return { ...state, step:payload }
    case "COUNTRY_LIST":
            return { ...state, Countries:payload }
    case "STATE_LIST":
        return { ...state, State:payload }
    case "CITY_LIST":
        return { ...state, City:payload}
    case "USER_TYPE":
        return { ...state, type:payload }
    case 'PRELOADER':
    return {...state,registerPreloader:payload}
    case payload.key:
        console.log(payload.key,payload,'red___')
        return {...state,...payload}
    case "IS_REGISTER":
            return { ...state, isRegister:payload }
    case 'INITIAL_STATE':
        return {state, initialState}
    default:
        return state
    }
}
export default reducer;
export {initialState};