
import React,{useEffect,useState } from 'react';
import { Route, Redirect, useHistory } from "react-router-dom";
import Auth from '../../Auth/Auth';
import AuthUserContext from '../../Auth/AuthUser/AuthUserContext';
import { APIService } from '../../Components/Apis/APIServices';
export const ProtectedRoute = ({
    component: Component,
    ...rest
  }) => {
  const [AuthUserDetails, setAuthUserDetails] = useState();
  const history =useHistory()
  useEffect(() => {
    APIService.authUser().then((response)=>{
        if(response.data.status==200){
            setAuthUserDetails(response.data.data[0]);
        }else{
            window.location.href = '/login'
        }        
    }).catch((error)=>{
      console.log(error)
     // window.location.href = '/login'
    })
  }, []);
    return (
      <Route
        {...rest}
        render={props => {
          let userDetails = AuthUserDetails || {};
            if (Auth.getAccessToken()) {
              return (
                <AuthUserContext.Provider value={userDetails} >
                  <Component {...rest}  />
                </AuthUserContext.Provider>
              ); 
          } else {
            return (
              <Component {...rest}  />
            );
          }
        }}
      />
    );
  };