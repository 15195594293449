import React from 'react';
import Footer from '../Components/Common/Footer';
import GetInTouch from '../Components/Common/GetInTouch';
import Header from '../Components/Common/Header';

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <section className="inner-banner-section">
                <div className="circle-box slideInUp"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-caption wow fadeInUp text-center">
                                <h1> Privacy <span> Policy </span> </h1>
                                <p> Last updated [Jan 31, 2021] </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="getContent-section">
                <div className="wp-clip-bg">
                    <img src="images/clip-bg.svg" className="img-fluid d-block m-auto" alt="" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="getContent-item m-0">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> Introduction </h2>
                                </div>
                                <p> eZunction LLC (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit/use our mobile applications on the iOS/Android platforms or our website, ezunction.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “eZunction Platform”). Please read this privacy policy carefully.  If you do not agree with the terms of this privacy policy, please do not access the eZunction platform.  </p>
                                <p> We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.  Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the eZunction platform, and you waive the right to receive specific notice of each such change or modification. </p>
                                <p> You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the eZunction platform after the date such revised Privacy Policy is posted.   </p>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="getContent-img">
                                <img src="images/content/privacy-policy.svg" className="m-auto d-block img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> COLLECTION OF YOUR INFORMATION </h2>
                                </div>
                                <p className="f-24"> We may collect information about you in a variety of ways. The information we may collect on the eZunction platform includes: </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Personal Data  </h3>
                                </div>
                                <p> Personally identifiable information, such as your name, shipping address, email address, credit card information, telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register and choose to participate in various activities on the eZunction platform, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the eZunction platform. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Derivative Data </h3>
                                </div>
                                <p> Information our servers may automatically collect when you access the eZunction platform, such as your IP address, your browser type, your operating system, your access times, and the content of unexpired/undeleted cookies that you have previously accepted from us, such as the pages you have viewed directly before and after accessing the eZunction platform (website). Use of cookies, allow us to automatically collect information about your online activity on our platform, such as the web pages you visit, the links you click, and the searches you conduct on our platform. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered on our platform. If you are using our mobile application, this information may also include your device name and type, your operating system, your phone number, your country, your likes and replies to a post, and other interactions with the application and other users via server log files, as well as any other information you choose to provide. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Financial Data </h3>
                                </div>
                                {/* <p> Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date, ) that we may collect when you confirm a service request or a quote, order, return, exchange, or request information about our services from the eZunction Platform. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, Stripe (<a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>) and you are encouraged to review their privacy policy and contact them directly for responses to your questions. </p> */}
                                <p>Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date, bank account etc.) that we may collect when you confirm a service request or a quote, or request information about our services from the eZunction Platform. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our highly secured and trusted payment processor, Stripe (<a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>) and Plaid (<a href="https://plaid.com">https://plaid.com</a>) to verify your bank account information. You are encouraged to review their privacy policy and contact them directly for responses to your questions.</p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Facebook Permissions </h3>
                                </div>
                                <p> The eZunction Platform may by default access your Facebook (<a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>) basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, checkins, and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook permissions, refer to the Facebook Permissions Reference (<a href="https://developers.facebook.com/docs/facebook-login/permissions">https://developers.facebook.com/docs/facebook-login/permissions</a>) page. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Data From Social Networks </h3>
                                </div>
                                <p> User information from social networking sites, such as Apple’s Game Center, Facebook, Google+, Instagram, Pinterest, Twitter, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. If you are using our mobile application, this information may also include the contact information of anyone you invite to use and/or join our mobile application. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Mobile Device Data </h3>
                                </div>
                                <p> Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the eZunction Platform from a mobile device. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Third-Party Data </h3>
                                </div>
                                <p> Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the permission to access this information. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Data from Contests, Giveaways, and Surveys </h3>
                                </div>
                                <p> Personal and other information you may provide when entering contests or giveaways and/or responding to surveys. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Mobile Application Information </h3>
                                </div>
                                <p> If you connect using our mobile application: </p>

                                <ul>
                                    <li> <strong> Geo-Location Information: </strong> We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.</li>

                                    <li> <strong> Mobile Device Access: </strong>We may request access or permission to certain features from your mobile device, including your mobile device’s bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts and other features. If you wish to change our access or permissions, you may do so in your device’s settings.</li>

                                    <li> <strong> Mobile Device Data: </strong>  We may collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.</li>

                                    <li> <strong> Push Notifications: </strong>  We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</li>
                                </ul>
                            </div>


                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> USE OF YOUR INFORMATION  </h2>
                                </div>
                                <p className="f-24"> Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the eZunction platform to:  </p>

                                <ul>
                                    <li>Provide the services that you have requested </li>

                                    <li>Provide your account management services </li>

                                    <li>Administer sweepstakes, promotions, and contests </li>

                                    <li>Assist law enforcement and respond to subpoena </li>

                                    <li>Manage and enhance the eZunction Platform </li>

                                    <li>Compile anonymous statistical data and analysis for use internally or with third parties </li>

                                    <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the eZunction platform to you </li>

                                    <li>Email you regarding your account, service request or quote </li>

                                    <li>Enable user-to-user communications </li>

                                    <li>Fulfill and manage your service request transactions/purchases, payments, and other transactions related to the eZunction platform </li>

                                    <li>Generate a personal profile about you to make future visits to the eZunction platform more personalized </li>

                                    <li>Increase the efficiency and operation of the eZunction platform</li>

                                    <li>Monitor and analyze usage and trends to improve your experience with the eZunction platform </li>

                                    <li>Notify you of updates to the eZunction platform</li>

                                    <li>Interact with you regarding any partially completed services</li>

                                    <li>Offer new products, services, mobile applications, and/or recommendations to you</li>

                                    <li>Perform other business activities as needed</li>

                                    <li>Interact with you by e-mail, postal mail, telephone and/or mobile devices or send notifications/newsletters about products or services that may be of interest to you either from us or our affiliates</li>

                                    <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity</li>

                                    <li>Ensure compliance to our terms and conditions</li>

                                    <li>Process payments and refunds</li>

                                    <li>Request feedback and contact you about your use of the eZunction platform</li>

                                    <li>Resolve disputes and troubleshoot problems</li>

                                    <li>Respond to product and customer service requests</li>

                                    <li>Send you a newsletter</li>

                                    <li>Publicly-display comments, ratings and reviews and other content provided by you</li>

                                    <li>Solicit support for the eZunction platform</li>
                                </ul>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> DISCLOSURE OF YOUR INFORMATION  </h2>
                                </div>
                                <p className="f-24">We may share information we have collected about you in certain situations. Your information may be disclosed as follows: </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> By Law or to Protect Rights  </h3>
                                </div>
                                <p> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Third-Party Service Providers </h3>
                                </div>
                                <p> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.   </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Service Providers </h3>
                                </div>
                                <p> The service requests that you create on the eZunction Platform, matches your information and service request details against the registered service providers on the eZunction Platform. When you submit a service request through our eZunction Platform, you are providing your consent to us to share your personal information and details of your service request to the service providers that we share your service request with. Sharing this information with Service Professionals allows them to contact you using chat and comments features and the other details that you have provided on the eZunction Platform.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Affiliates </h3>
                                </div>
                                <p> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Business Partners </h3>
                                </div>
                                <p> We may share your information with our business partners to offer you certain products, services or promotions.  </p>
                            </div>

                            {/* <div className="getContent-item orange-text">
                                <div className="getContent-title">
                                    <h3> Offer Wall </h3>
                                </div>
                                <p> Our mobile application may display a third-party hosted “offer wall.”  Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer.  Such an offer wall may appear in our mobile application and be displayed to you based on certain data, such as your geographic area or demographic information.  When you click on an offer wall, you will leave our mobile application.  A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.]     </p>
                            </div> */}

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Social Media Contacts </h3>
                                </div>
                                <p> If you connect to the eZunction Platform through a social network, your contacts on the social network may be able to see your name, profile photo, and descriptions of your activity. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3>  Other Third Parties</h3>
                                </div>
                                <p> We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Sale or Bankruptcy </h3>
                                </div>
                                <p> If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.  </p>
                                <p> We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations.  If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> TRACKING TECHNOLOGIES </h2>
                                </div>
                                <p> The eZunction Platform may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the eZunction Platform, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the eZunction Platform.  </p>
                            
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3>Cookies and Web Beacons </h3>
                                </div>
                                <p>  We may use cookies, web beacons, tracking pixels, and other tracking technologies on the eZunction Platform to help customize the eZunction Platform and improve your experience. When you access the eZunction Platform, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the eZunction Platform. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Internet-Based Advertising </h3>
                                </div>
                                <p> Additionally, we may use third-party software to serve ads on the eZunction Platform, implement email marketing campaigns, and manage other interactive marketing initiatives.  This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us.  For more information about opting-out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Website Analytics </h3>
                                </div>
                                <p> We may also partner with selected third-party vendors, such as Adobe Analytics, Clicktale, Clicky, Cloudfare, Crazy Egg, Flurry Analytics, Google Analytics, Heap Analytics, Inspectlet, Kissmetrics, Mixpanel, Piwik and others, to allow tracking technologies and remarketing services on the eZunction Platform through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the eZunction Platform, determine the popularity of certain content and better understand online activity. By accessing the eZunction Platform, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.  </p>

                                <p> You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings. </p>
                            </div>

                            {/* <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper">TRACKING TECHNOLOGIES </h2>
                                </div>
                                <p> The eZunction Platform may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the eZunction Platform, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the eZunction Platform.  </p>
                            </div> */}


                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> SECURITY OF YOUR INFORMATION </h2>
                                </div>
                                <p> We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper">  POLICY FOR CHILDREN </h2>
                                </div>
                                <p> We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> CONTROLS FOR DO-NOT-TRACK FEATURES </h2>
                                </div>
                                <p> Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.  No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.  If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. If you set the DNT signal on your browser, we will respond to such DNT browser signals.  </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> OPTIONS REGARDING YOUR INFORMATION </h2>
                                </div>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Account Information </h3>
                                </div>
                                <p> You may at any time review or change the information in your account or terminate your account by: </p>

                                <ul>
                                    <li> Logging into your account settings and updating your account </li>
                                    <li> Contacting us using the contact information provided on the eZunction Platform </li>
                                </ul>

                                <p> Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h3> Emails and Communications </h3>
                                </div>
                                <p> If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by: </p>

                                <ul>
                                    <li> Noting your preferences at the time you register your account with the eZunction Platform  </li>
                                    <li> Logging into your account settings and updating your preferences. </li>
                                    <li> Contacting us using the contact information provided below. </li>
                                </ul>

                                <p> If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.  </p>
                            </div>


                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> CALIFORNIA PRIVACY RIGHTS </h2>
                                </div>
                                <p> California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. </p>

                                <p> If you are under 18 years of age, reside in California, and have a registered account with the eZunction Platform, you have the right to request removal of unwanted data that you publicly post on the eZunction Platform. To request removal of such data, please contact us using the contact information provided on the eZunction Platform, and include the email address associated with your account and a statement that you reside in California.  We will make sure the data is not publicly displayed on the eZunction Platform, but please be aware that the data may not be completely or comprehensively removed from our systems. We do not sell personal information that we collect from you, in accordance with the definition of sell in the California Consumer Act Privacy Notice (“CCPA Notice”) and will treat personal information we collect from you as subject to a do not sell request. However, there is still not a common understanding/consensus regarding the third party cookies and tracking devices associated with our websites and mobile apps may constitute a “sale” of your personal information as defined by the CCPA. You can always control your browser-based cookies by adjusting the settings on your browser. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> THIRD PARTIES RIGHTS </h2>
                                </div>
                                <p> This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of the eZunction Platform. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper">CROSS BORDER ACCESS </h2>
                                </div>
                                <p> This Privacy Policy is defined for the residents of the United States, who access the eZunction Platform within the United States. If you are accessing the eZunction Platform from a location outside the United States, your information may be transferred to, stored, and processed in the United States where our eZunction Platform application and database servers are located. The data protection and other laws of the United States will apply and you are made aware that it might not be as comprehensive as those in your country. By using the eZunction platform, you understand and agree that your information may be transferred to our facilities/third parties/affiliates as indicated in this privacy policy. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> CHANGES TO THIS PRIVACY POLICY </h2>
                                </div>
                                <p> This Privacy Policy may be updated on regular basis to reflect changes in our managing processes, practices and services. The updated privacy policy will, indicate the "last updated" date at the top. Any significant changes to the privacy policy will be updated on our eZunction Platform and will be notified to you. You are recommended to check our eZunction Platform from time to time to inform yourself of any changes in this privacy policy or any of our other policies. You may write to us as support@ezunction.com to request for a prior version of the privacy policy. </p>
                            </div>

                            <div className="getContent-item">
                                <div className="getContent-title">
                                    <h2 className="t-upper"> CONTACT US </h2>
                                </div>
                                <p> If you have questions or comments about this Privacy Policy, please contact us <a href="mailto:support@ezunction.com" className="orange-text">  support@ezunction.com </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* <section className="getInTouch-section">
                <div className="container">
                    <div className="col-12">
                        <div className="getInTouch-box">
                            <div className="title">
                                <h4> Get In Touch </h4>
                                <p> Know more about eZunction platform </p>
                            </div>
                            <div className="social-list">
                                <a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                <a href="https://twitter.com/" target="_blank"> <i className="fab fa-twitter"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"> <i className="fab fa-youtube"></i> </a>
                                <a href="https://www.instagram.com/" target="_blank"> <i className="fab fa-instagram"></i> </a>
                                <a href="https://www.flickr.com/" target="_blank"> <i className="fab fa-flickr"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <GetInTouch />
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
